import { colors } from "&assets/constants/colors";
import { device } from "&assets/constants/responsiveness";
import styled from "styled-components";

export const ApplyForVoucherWrapper = styled.div`
  .apply-voucher-button {
    background: ${colors.white};
    border: 1px solid ${colors.primary};
    margin-top: 10px;
    margin-right: 10px;
    height: 45px;
    justify-content: center;
    .MuiCircularProgress-circle {
      color: ${colors.primary};
    }
  }
  .apply-voucher-button-title {
    font-weight: 800;
    line-height: 160%;
    text-align: center;
    color: ${colors.primary};
  }
  @media ${device.mobile}, ${device.tablet} {
    .apply-voucher-button {
      width: fit-content;
      height: 38px;
      margin: 10px 0;
      margin-right: 10px;
    }
  }
`;
