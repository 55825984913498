import { colors } from "&assets/constants/colors";
import { device } from "&assets/constants/responsiveness";
import styled from "styled-components";

export const SubmitButtonWrapper = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  min-width: 115px;
  height: 40px;
  background: ${colors.linearGradientSeventh};
  border-radius: 30px;
  border: 0;
  ${(props) => (props.disabled ? "opacity: 0.5;" : "")}
  cursor: ${(props) => (props.disabled ? "initial" : "pointer")};
  .title {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 160%;
    text-align: center;
    color: #ffffff;
    white-space: nowrap;
  }

  .icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    ${(props) => (props.theme.dir === "ltr" ? "margin-left: 10px;" : "margin-right: 10px;")}
    ${(props) => (props.theme.dir === "ltr" ? "" : "transform: scaleX(-1);")}
  }
  .MuiCircularProgress-circle {
    color: ${colors.white};
  }

  @media ${device.tablet} {
    min-width: 99px;
  }

  @media ${device.mobile} {
    min-width: 99px;
    .title {
      font-size: 14px;
    }
  }
`;
