import { loginNameSpace } from "&features/login/login.i18n";
import { landingPageNameSpace } from "&features/landingPage/landingPage.i18n";
import { learningPathwayNameSpace } from "&features/learningPathway/learningPathway.i18n";
import { futureCareersNameSpace } from "&features/futureCareers/futureCareers.i18n";
import { aboutSpace } from "&features/about/about.i18n";
import { dashboardNameSpace } from "&features/dashboard/dashboard.i18n";
import { listingPageNameSpace } from "&features/listingPage/listingPage.i18n";
import { assessmentNameSpace } from "&features/assessment/assessment.i18n";
import { careerMatchingNameSpace } from "&features/careerMatching/careerMatching.i18n";
import { privacyPolicyNameSpace } from "&features/privacyPolicy/privacyPolicy.i18n";
import { termsConditionsNameSpace } from "&features/termsConditions/termsConditions.i18n";
import { frequentlyAskedQuestionsNameSpace } from "&features/frequentlyAskedQuestions/frequentlyAskedQuestions.i18n";
import { careerDetailsNameSpace } from "&features/careerDetails/careerDetails.i18n";
import { courseDetailsNameSpace } from "&features/courseDetails/courseDetails.i18n";
import { profileNameSpace } from "&features/dashboard/screens/profile/profile.i18n";
import { opportunitiesListingNameSpace } from "&features/opportunitiesListing/opportunitiesListing.i18n";
import { contactUsNameSpace } from "&features/contactUs/contactUs.i18n";
import { notificationsNameSpace } from "&features/notifications/notifications.i18n";
import { blogsNameSpace } from "&features/blogs/blogs.i18n";
import { bundlesNameSpace } from "&features/bundles/bundles.i18n";
import { examDetailsNameSpace } from "&features/examDetails/examDetails.i18n";
import { certificationsNameSpace } from "&features/certifications/certifications.i18n";

/**
 * Arabic translation resources.
 * Each object correspond to a namespace related to a feature.
 */
let ar = {
  login: loginNameSpace.ar,
  landingPage: landingPageNameSpace.ar,
  learningPathway: learningPathwayNameSpace.ar,
  futureCareers: futureCareersNameSpace.ar,
  about: aboutSpace.ar,
  dashboard: dashboardNameSpace.ar,
  listingPage: listingPageNameSpace.ar,
  assessment: assessmentNameSpace.ar,
  careerMatching: careerMatchingNameSpace.ar,
  privacyPolicy: privacyPolicyNameSpace.ar,
  termsConditions: termsConditionsNameSpace.ar,
  frequentlyAskedQuestions: frequentlyAskedQuestionsNameSpace.ar,
  careerDetails: careerDetailsNameSpace.ar,
  courseDetails: courseDetailsNameSpace.ar,
  profile: profileNameSpace.ar,
  opportunitiesListing: opportunitiesListingNameSpace.ar,
  contactUs: contactUsNameSpace.ar,
  notifications: notificationsNameSpace.ar,
  blogs: blogsNameSpace.ar,
  bundles: bundlesNameSpace.ar,
  examDetails: examDetailsNameSpace.ar,
  certifications: certificationsNameSpace.ar,
};

export default ar;
