import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { CareerMatching } from "./careerMatching.type";
import {
  getCareerLearningPathsApi,
  getMatchingCareersApi,
} from "&features/careerMatching/careerMatching.api";
import { AssessmentRequest } from "&features/assessment/assessment.type";

const initialState: CareerMatching = {
  matchingCareers: {
    career: undefined,
    suggestions: undefined,
  },
  learningPaths: [],
  loadingMatchingCareers: false,
  loadingLearningPaths: false,
  status: "",
};

const getMatchingCareers = createAsyncThunk(
  "careerMatching/getMatchingCareers",
  async (
    body: AssessmentRequest,
    { rejectWithValue, getState, dispatch }: any
  ) => {
    try {
      const matchingCareers = await getMatchingCareersApi(body);
      setTimeout(() => {
        dispatch(
          careerMatchingActions.setCareerMatching({
            status: "GET_MATCHING_CAREERS_SUCCESS",
          })
        );
      }, 500);
      return matchingCareers?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const getCareerLearningPaths = createAsyncThunk(
  "careerMatching/getCareerLearningPaths",
  async (
    body: AssessmentRequest,
    { rejectWithValue, getState, dispatch }: any
  ) => {
    try {
      const learningPaths = await getCareerLearningPathsApi(body);
      return learningPaths?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const careerMatchingSlice = createSlice({
  name: "careerMatching",
  initialState: initialState,
  reducers: {
    setCareerMatching: (state, action) => {
      return { ...state, ...action.payload };
    },
    reset: () => initialState,
  },

  extraReducers: (builder) => {
    builder.addCase(getMatchingCareers.pending, (state, action) => {
      // Write pending logic here
      state.loadingMatchingCareers = true;
    });
    builder.addCase(getMatchingCareers.fulfilled, (state, action) => {
      // Write success logic here
      state.loadingMatchingCareers = false;
      state.matchingCareers = {
        career: action.payload?.career,
        suggestions: action.payload?.suggestions,
      };
    });
    builder.addCase(getMatchingCareers.rejected, (state, action) => {
      // Write failure logic here
      state.loadingMatchingCareers = false;
    });
    builder.addCase(getCareerLearningPaths.pending, (state, action) => {
      // Write pending logic here
      state.loadingLearningPaths = true;
    });
    builder.addCase(getCareerLearningPaths.fulfilled, (state, action) => {
      // Write success logic here
      state.loadingLearningPaths = false;
      state.learningPaths = action.payload;
      state.status = "GET_LEARNING_PATHS_SUCCESS";
    });
    builder.addCase(getCareerLearningPaths.rejected, (state, action) => {
      // Write failure logic here
      state.loadingLearningPaths = false;
    });
  },
});

export const careerMatchingReducer = careerMatchingSlice.reducer;

export const careerMatchingActions = {
  ...careerMatchingSlice.actions,
  getMatchingCareers,
  getCareerLearningPaths,
};
