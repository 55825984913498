import { API } from "aws-amplify";
import { RES_EXAMS, RES_LEARNING_PATHWAY } from "&store/resources";

const getExamByIdApi = async (
  id: string,
  isLoggedIn: boolean,
  learningPathId?: any
) => {
  let URL = isLoggedIn
    ? learningPathId
      ? `/auth${RES_EXAMS}/${id}${RES_LEARNING_PATHWAY}/${learningPathId}`
      : `${RES_EXAMS}/${id}`
    : `${RES_EXAMS}/${id}`;
  return API.get(process.env.REACT_APP_NAME || "", URL, {});
};

const getExamsLearningPathApi = async (examId: string) => {
  let URL = `${RES_EXAMS}/${examId}/learning-paths`;
  return API.get(process.env.REACT_APP_NAME || "", URL, {});
};

export { getExamByIdApi, getExamsLearningPathApi };
