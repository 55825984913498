import { colors } from "&assets/constants/colors";
import styled from "styled-components";

interface Props {
  disabled?: boolean;
}

export const ActionButtonWrapper = styled.button<Props>`
  min-width: 111px;
  height: 40px;
  background: none;
  border: 0;
  cursor: ${(props) => (props.disabled ? "initial" : "pointer")};
  padding: 0;
  .title {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 160%;
    text-align: center;
    color: ${(props) => (props.disabled ? "#a1a09f" : colors.primary)};
    margin: 0;
  }
  .MuiCircularProgress-circle{
    color: ${colors.primary};
  }
`;
