import React, { ImgHTMLAttributes, useState } from "react";

import {
  ImageDisplayWrapper,
  ImageOverlay,
  ImageWrapper,
} from "./imageDisplay.styled";
import getCDNProtectedURL from "&utils/getCDNProtectedImageSource";
import ReactPlayer from "react-player";
import { useMediaQuery } from "react-responsive";
import { size } from "&assets/constants/responsiveness";

interface Props {
  url?: string;
  className?: string;
  disabled?: boolean;
  isFromS3?: boolean;
  userIdentity?: string;
  children?: any;
  isBackgroundImage?: boolean;
  showOverlay?: boolean;
  mediaType?: string;
}

const ImageDisplay = (props: Props & ImgHTMLAttributes<any>) => {
  const {
    className = "",
    disabled = false,
    isFromS3 = false,
    // userIdentity = "",
    children = null,
    isBackgroundImage,
    showOverlay,
    mediaType,
    url,
  } = props;

  const isMobile = useMediaQuery({ maxWidth: size.mobile });
  const isTablet = useMediaQuery({
    minWidth: size.mobile,
    maxWidth: size.tablet,
  });

  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  const imageUrl =
    isFromS3 &&
    !(
      url?.includes("googleusercontent") ||
      url?.includes("media.licdn") ||
      url?.includes("https://")
    )
      ? getCDNProtectedURL(url)
      : url;

  if (mediaType === "video") {
    return (
      <ImageWrapper isBackground={true} className={className}>
        <ReactPlayer
          width={"100%"}
          height={"100%"}
          url={imageUrl}
          controls={true}
          playsinline={isMobile || isTablet}
          muted={true}
          onReady={() => !isMobile && !isTablet && setIsPlaying(true)}
          playing={isPlaying}
        />
      </ImageWrapper>
    );
  }
  if (isBackgroundImage) {
    return (
      <ImageWrapper isBackground={true} className={className}>
        {showOverlay && <ImageOverlay />}
        <ImageDisplayWrapper
          url={imageUrl}
          about={imageUrl ? "true" : "false"}
          className="image-wrapper"
          disabled={disabled}
        >
          {children}
        </ImageDisplayWrapper>
      </ImageWrapper>
    );
  }

  if (showOverlay) {
    return (
      <ImageWrapper className={className}>
        {showOverlay && <ImageOverlay />}
        <img
          src={imageUrl}
          alt="img-wrapper"
          className="image-wrapper"
          {...props}
        />
      </ImageWrapper>
    );
  }
  return (
    <img src={imageUrl} alt="img-wrapper" className={className} {...props} />
  );
};

export default ImageDisplay;
