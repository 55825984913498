import styled from "styled-components";
import { colors } from "&assets/constants/colors";
import { device } from "&assets/constants/responsiveness";

interface Props {
  isWhite?: boolean;
}

export const TextArrowButtonWrapper = styled.button<Props>`
  padding: 0;
  border: 0;
  background: transparent;
  font-weight: 800;
  font-size: 18px;
  line-height: 160%;
  text-align: center;
  color: ${(props) => (props.isWhite ? colors.white : colors.primary)};
  z-index: 1;
  align-items: center;
  display: flex;
  cursor: pointer;
  svg {
    ${(props) =>
            props.theme.dir === "rtl"
                    ? "margin-right: 10px;"
                    : "margin-left: 10px;"}
    ${(props) =>
            props.theme.dir === "rtl"
                    ? "transform: rotate(180deg);"
                    : ""}
  }

  @media ${device.tablet} {
    font-size: 18px;
  }
  @media ${device.mobile} {
    font-size: 18px;
  }
`;
