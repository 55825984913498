import React from "react";
import { DownloadCVWrapper } from "./downloadCV.styled";

import SubmitButton from "&components/buttons/submitButton/submitButton.component";
import ImageDisplay from "&components/imageDisplay/imageDisplay.component";
import { images } from "&assets/constants/images-urls";
import { useTranslation } from "react-i18next";

interface Props {
  title?: string;
  handleClick?: Function;
}

const DownloadCV = (props: Props) => {
  const {
    // title,
    handleClick,
  } = props;
  const { t } = useTranslation(["profile"]);

  return (
    <DownloadCVWrapper>
      <ImageDisplay isFromS3={true} url={images.downloadImg} alt="" />
      <SubmitButton
        id="download-cv-btn"
        title={t("DOWNLOAD_CV")}
        handleClick={() => handleClick && handleClick()}
        className="download-cv"
        titleClassName="download-cv-title"
      />
    </DownloadCVWrapper>
  );
};

export default DownloadCV;
