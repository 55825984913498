import { colors } from "&assets/constants/colors";
import styled from "styled-components";
import { device } from "&assets/constants/responsiveness";

interface BundlesProps {
  showBundlesBanner?: boolean;
}
export const BundlesWrapper = styled.div<BundlesProps>`
  background-color: rgba(16, 140, 210, 0.1);
  .header {
    background: ${colors.linearGradientOne};
    overflow: initial;
    height: fit-content;
    text-align: left;
    padding-bottom: ${(props) => (props.showBundlesBanner ? "0px" : "114px")};
    border-bottom: 0;
    .container {
      padding: 20px;
    }
  }
  .purchase-bundle-button {
    background: ${colors.white};
    border: 1px solid ${colors.primary};
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
  }
  .purchase-bundle-button p {
    color: ${colors.primary};
    font-size: 14px;
    font-weight: 800;
    line-height: 22.4px;
  }
  .title-subtitle-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 50px 0;
    ${(props) => (props.theme.dir === "rtl" ? "text-align: right;" : "text-align: left;")};
  }

  .header-title {
    color: ${colors.white};
    max-width: 664px;
    ${(props) => (props.theme.dir === "rtl" ? "text-align: right;" : "text-align: left;")};
  }
  .informatives {
    background: ${colors.white};
  }
  .container-content {
    padding: 20px;
    display: flex;
    ${(props) => (props.theme.dir === "rtl" ? "text-align: right;" : "text-align: left;")};
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .location-image {
      width: 1090px;
      height: 520px;
      border-radius: 16px;
      object-fit: cover;
      margin-top: -133px;
      margin-bottom: 40px;
      z-index: 1;
      position: relative;
    }
  }
  .empty-bundles {
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
  }
  @media ${device.tablet} {
    .header {
      .container {
        padding-left: 15px;
      }
    }
    .container-content {
      box-sizing: border-box;
      padding: 0 30px;
      .location-image {
        width: 100%;
        height: 400px;
        border-radius: 16px;
        margin-top: -170px;
        margin-bottom: 40px;
        z-index: 1;
        position: relative;
      }
    }
  }

  @media ${device.mobile} {
    .header {
      padding-bottom: 160px;
      .container {
        padding-left: 15px;
      }
    }
    .container-content {
      box-sizing: border-box;
      padding: 0 16px;
      .location-image {
        width: 100%;
        height: 400px;
        border-radius: 16px;
        margin-top: -170px;
        margin-bottom: 40px;
        z-index: 1;
      }
    }
  }
`;
