import { colors } from "&assets/constants/colors";
import styled from "styled-components";

interface Props {
  selectedColor?: string;
  selected?: boolean;
}

export const StepperWrapper = styled.div<Props>`
  display: flex;
  position: relative;
  width: fit-content;
  ${(props) => (props.theme.dir === "rtl" ? "margin: 21px 10px 38px 0;" : "margin: 21px 0 38px 10px;")}
  align-items: center;
  justify-content: center;
  ::-webkit-scrollbar {
    width: 2px;
  }
  .step-separator {
    height: 1px;
    width: 100%;
    position: absolute;
    background-color: ${colors.dark};
    opacity: 0.3;
    top: 50%;
    transform: translateY(-50%);
    z-index: 0;
  }
  .completed,
  .uncompleted,
  .current {
    min-width: 24px;
    min-height: 24px;
    max-width: 24px;
    max-height: 24px;
    border-radius: 50px;
    color: ${colors.white};
    font-weight: 400;
    font-size: 14px;
    line-height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    cursor: pointer;
  }
  .completed {
    background-color: ${colors.green};
  }
  .uncompleted {
    background-color: ${colors.secondaryDark};
  }
  .current {
    background-color: ${colors.green};
    /* box-shadow: 0px 0px 12px 0px #7a7977; */
  }
`;

export const StepWrapper = styled.div<Props>`
  position: relative;
  ${(props) => (props.theme.dir === "rtl" ? "margin-left: 70px;" : "margin-right: 70px;")}
  display: flex;
  justify-content: center;
  align-items: center;
  &:last-child {
    ${(props) => (props.theme.dir === "rtl" ? "margin-left: 0;" : "margin-right: 0;")}
  }
  .arrow {
    width: ${(props) => (props.selected ? "12px" : "0px")};
    height: ${(props) => (props.selected ? "12px" : "0px")};
    border: ${(props) => (props.selected ? "4px" : "0px")} solid ${(props) => props.selectedColor};
    position: absolute;
    bottom: -23px;
    ${(props) => (props.theme.dir === "rtl" ? "right: 50%;" : "left: 50%;")}
    ${(props) =>
      props.theme.dir === "rtl"
        ? "transform: translateX(50%) rotate(-45deg);"
        : "transform: translateX(-50%) rotate(45deg);"}
    ${(props) =>
      props.theme.dir === "rtl" ? "border-right: 1px solid transparent;" : "border-left: 1px solid transparent;"}
    border-top: 1px solid transparent;
    transition: all 0.2s ease-in;
  }
  .border {
    width: ${(props) => (props.selected ? "34px" : 0)};
    height: ${(props) => (props.selected ? "34px" : 0)};
    border: 1px solid ${(props) => props.selectedColor};
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: white;
    z-index: 1;
    transition: all 0.1s ease-in;
    position: absolute;
  }
`;
