import React, { useEffect } from "react";

import { PageSection } from "&features/landingPage/landingPage.type";
import ActivitiesCarousel from "&components/activitiesCarousel/activitiesCarousel.component";
import ImageBackground from "&components/imageBackground/imageBackground.component";
import { useDispatch, useSelector } from "react-redux";
import { listingPageActions } from "&features/listingPage/listingPage.slice";
import { RootState } from "&store/store";
import { Course } from "&features/listingPage/listingPage.type";

interface Props {
  activities?: Course[];
  data?: PageSection;
  isLearningPaths?: boolean;
  title: string;
  allButtonText: string;
  onAllButtonClick?: () => void;
}

const RelatedLearningActivities = (props: Props) => {
  const {
    activities,
    title,
    allButtonText,
    isLearningPaths,
    onAllButtonClick = () => {},
  } = props;
  const dispatch = useDispatch();
  const courses = useSelector((state: RootState) => state.listingPage.courses);

  useEffect(() => {
    !activities && fetchCourses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("language")]);

  const fetchCourses = () => {
    const requestBody = {
      page: 1,
      query: "",
      levelId: undefined,
      limit: 7,
      sort: undefined,
    };
    dispatch(listingPageActions.getCourses(requestBody));
  };

  return (
    <ImageBackground
      title={title}
      allButtonText={allButtonText}
      onAllButtonClick={onAllButtonClick}
    >
      <ActivitiesCarousel
        isLearningPath={isLearningPaths}
        data={activities || courses?.items}
      />
    </ImageBackground>
  );
};

export default RelatedLearningActivities;
