export const careerDetailsNameSpace = {
  en: {
    CAREER_DESCRIPTION: "Career description",
    READ_MORE: "Read more",
    READ_LESS: "Read less",
    JOIN_NAMMISKILLS: "Join nammiskills",
    RELATED_SKILLS: "Related skills",
    AVAILABLE: "available",
    OPPORTUNITY: "opportunity",
    OPPORTUNITIES: "opportunities",
    RELATED_LEARNING_PATHS: "Related Learning Paths",
    VIEW_ALL_LEARNING_PATHS: "View all learning paths",
    AVAILABLE_OPPORTUNITIES: "Available opportunities",
    AVAILABLE_OPPORTUNITY: "Available opportunity",
  },
  ar: {
    CAREER_DESCRIPTION: "وصف المهنة",
    READ_MORE: "اقرأ المزيد",
    READ_LESS: "اقرأ أقل",
    JOIN_NAMMISKILLS: "انضم إلى نمّي سكيلز",
    RELATED_SKILLS: "المهارات ذات الصلة",
    AVAILABLE: "متاح",
    OPPORTUNITY: "فرصة",
    OPPORTUNITIES: "فرص",
    RELATED_LEARNING_PATHS: "المسارات التعليمية ذات الصلة",
    VIEW_ALL_LEARNING_PATHS: "عرض جميع المسارات التعليمية",
    AVAILABLE_OPPORTUNITIES: "فرص متاحة",
    AVAILABLE_OPPORTUNITY: "فرصة متاحة",
  },
};
