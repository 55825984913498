export const S3_IMAGES_FOLDER = "frontend-images/";

export const images = {
  advancedCareer: S3_IMAGES_FOLDER + "advanced-career.png",
  beginnerCareer: S3_IMAGES_FOLDER + "beginner-career.png",
  carouselBack1: S3_IMAGES_FOLDER + "carouselBack1.png",
  certificateImg: S3_IMAGES_FOLDER + "certificate.svg",
  cohortCoursesImg: S3_IMAGES_FOLDER + "cohort-courses.png",
  downloadImg: S3_IMAGES_FOLDER + "download.png",
  bundlesImage: S3_IMAGES_FOLDER + "bundles-default-img.png",
  bundlesPayCashImage: S3_IMAGES_FOLDER + "pay_cash.png",
  dummyImage: S3_IMAGES_FOLDER + "dummy-user-image.png",
  hireTalentBackground: S3_IMAGES_FOLDER + "hire-talent.png",
  intermediateCareer: S3_IMAGES_FOLDER + "intermediate-career.png",
  joinRecruiterImage: S3_IMAGES_FOLDER + "join-recruiter.png",
  joinRecruiterMobileImage: S3_IMAGES_FOLDER + "join-recruiter-mobile.png",
  learningPathwayImg: S3_IMAGES_FOLDER + "learning-pathway.png",
  listingPageBackground: S3_IMAGES_FOLDER + "listing-page-background.png",
  locationMapImg: S3_IMAGES_FOLDER + "location-map.png",
  successImg: S3_IMAGES_FOLDER + "success.png",
  teamFrame: S3_IMAGES_FOLDER + "team-frame.png",
  warningBundle: S3_IMAGES_FOLDER + "warning-bundle.png",
  teamUserDummy1: S3_IMAGES_FOLDER + "team-user-dummy-1.png",
  teamUserDummy2: S3_IMAGES_FOLDER + "team-user-dummy-2.png",
  user1Image: S3_IMAGES_FOLDER + "user1.png",
  user2Image: S3_IMAGES_FOLDER + "user2.png",
  user3Image: S3_IMAGES_FOLDER + "user3.png",
  aboutUs2: S3_IMAGES_FOLDER + "about-us-photo-2.jpg",
  aboutUs3: S3_IMAGES_FOLDER + "about-us-photo-3.jpg",
  aboutUs4: S3_IMAGES_FOLDER + "about-us-photo-4.jpg",
  aboutUs6: S3_IMAGES_FOLDER + "about-us-photo-6.png",
  aboutUs7: S3_IMAGES_FOLDER + "about-us-photo-7.png",
  engagedEmployers: S3_IMAGES_FOLDER + "engaged-employers.jpg",
  studentsExposed: S3_IMAGES_FOLDER + "students-exposed.jpg",
  engagedEducation: S3_IMAGES_FOLDER + "engaged-education.jpg",
  interns: S3_IMAGES_FOLDER + "interns.jpg",
};
