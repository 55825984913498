import styled from "styled-components";
import { colors } from "&assets/constants/colors";

import { InputLabel } from "@mui/material";

interface Props {
  error: boolean;
}

export const PhoneNumberInputWrapper = styled.div<Props>`
  .PhoneInput {
    direction: ltr !important;
  }
  .PhoneInputCountrySelect {
    direction: ltr !important;
  }
  input[type="tel"] {
    direction: ltr !important;
    width: 100%;
    max-height: 50px;
    padding: 16px !important;
    border-radius: 10px;
    border: 1px solid ${(props) => (props.error ? colors.errorColor : "transparent")};
    background: ${(props) =>
      props.error
        ? "transparent"
        : `${colors.linearGradientWhite} padding-box, ${colors.linearGradientSixth} border-box`};
    opacity: 1;
    &:focus {
      outline: none;
    }
  }
`;

export const LabelWrapper = styled(InputLabel)<Props>`
  font-family: "Mulish" !important;
  ${(props) => (props.theme.dir === "rtl" ? "text-align: right;" : "")}
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 10px !important;
  line-height: 160%;
  letter-spacing: 0.03em;
  text-transform: uppercase !important;
  color: ${(props) => (props.error ? colors.errorColor : colors.dark)} !important;
  margin-bottom: 4px;
`;
