import React, { useEffect, useState } from "react";
import { DunnoWhichCareerWrapper } from "./dunnoWhichCareer.styled";
import { useTranslation } from "react-i18next";
import ImageDisplay from "&components/imageDisplay/imageDisplay.component";
import imageSrc from "&assets/images/dont-know-career-image.png";
import imageSrcAr from "&assets/images/dont-know-career-image-ar.png";
import SubmitButton from "&components/buttons/submitButton/submitButton.component";
import { useAuth0 } from "@auth0/auth0-react";
import { size } from "&assets/constants/responsiveness";
import { useMediaQuery } from "react-responsive";
interface Props {}

const DunnoWhichCareer = (props: Props) => {
  const { t, i18n } = useTranslation(["landingPage"]);
  const { loginWithRedirect } = useAuth0();
  const [image, setImage] = useState("");
  const isMobile = useMediaQuery({ maxWidth: size.mobile });
  const isTablet = useMediaQuery({
    minWidth: size.mobile,
    maxWidth: size.tablet,
  });
  const isResponsive = isMobile || isTablet;

  useEffect(() => {
    if (i18n.language.includes("en")) {
      setImage(imageSrc);
      
    } else {
      setImage(imageSrcAr);

    }
  }, [i18n.language, isResponsive]);

  return (
    <DunnoWhichCareerWrapper>
        <p className="main-title">{t("DONT_KNOW_WHICH_CAREER_YOU_WANT")}</p>
        <ImageDisplay className="image-background" src={image} />
        <p className="description">{t("DO_NOT_WORRY_SIGN_UP_NOW")}</p>
        <SubmitButton
          id={"sign-up-now-button"}
          className="sign-up-button"
          title={t("SIGN_UP_NOW")}
          handleClick={() => loginWithRedirect()}
        />
    </DunnoWhichCareerWrapper>
  );
};

export default DunnoWhichCareer;
