import React, { useState, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useTimer } from "react-timer-hook";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import { loginActions } from "../../login.slice";
import { VerifyEmailWrapper } from "./verifyEmail.styled";
import { RootState } from "&store/store";
import ActionButton from "&components/buttons/actionButton/actionButton.component";
import { Grid, Typography } from "@mui/material";
import InputComponent from "&components/inputs/input/input.component";

type ReduxProps = ConnectedProps<typeof connector>;

interface Verification {
  email: string;
}

const VerifyEmailComponent = (props: ReduxProps) => {
  const {
    // setLogin, email,
    resendVerification,
  } = props;

  const { t } = useTranslation(["login"]);

  const [resendActive, setResend] = useState<boolean>(false);

  const time = new Date();
  time.setSeconds(time.getSeconds() + 60);

  const { seconds, start, restart } = useTimer({
    expiryTimestamp: time,
    onExpire: () => setResend(true),
  });

  const initialValues: Verification = {
    email: "",
  };
  const contactUsSchema = Yup.object().shape({
    email: Yup.string().required(t("REQUIRED")).email(t("INVALID_EMAIL")),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: contactUsSchema,
    validateOnChange: false,
    onSubmit: (values) => {
      const { email } = values;
      handleResend(email);
    },
  });

  useEffect(() => {
    start();
  }, [start]);

  const handleResend = (email: string) => {
    setResend(false);
    const time = new Date();
    time.setSeconds(time.getSeconds() + 60);
    resendVerification({ email: email || "" });
    restart(time);
  };

  return (
    <VerifyEmailWrapper>
      <p className="verify-title">
        {t("VERIFY_YOUR_EMAIL_ADDRESS_IN_ORDER_TO_CONTINUE")}
      </p>
      {resendActive && (
        <Grid>
          <InputComponent
            label={t("EMAIL_ADDRESS")}
            placeholder={t("ENTER_YOUR_EMAIL_ADDRESS")}
            value={formik.values.email}
            onChange={(event: any) =>
              formik.setFieldValue("email", event.target.value)
            }
            valid={formik.touched.email && formik.errors.email}
            isWhite
          />
          {<Typography color="red">{formik.errors.email}</Typography>}
        </Grid>
      )}
      <ActionButton
        title={
          resendActive
            ? t("RESEND_VERIFICATION")
            : `${t("RESEND_IN")} ${seconds}s`
        }
        handleClick={formik.handleSubmit}
        disabled={!resendActive || (resendActive && !formik.values.email)}
      ></ActionButton>
    </VerifyEmailWrapper>
  );
};

const mapStateToProps = (state: RootState) => ({
  email: state.login.signupEmail,
});

const mapDispatchToProps = {
  setLogin: loginActions.setLogin,
  resendVerification: loginActions.resendVerification,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const VerifyEmailComponentRedux = connector(VerifyEmailComponent);

export { VerifyEmailComponentRedux as VerifyEmailComponent };
