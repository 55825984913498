import { colors } from "&assets/constants/colors";
import styled from "styled-components";

export const SourceLogoWrapper = styled.div`
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  border: 1px solid ${colors.primary};
  background-color: ${colors.white};
  ${(props) =>
          props.theme.dir === "rtl"
                  ? "margin-left: 10px;"
                  : "margin-right: 10px;"}
  border-radius: 15px;
  padding: 3px;
  .logo {
    width: 100%;
    height: 100%;
    .image-wrapper {
      background-size: contain;
    }
  }
`;
