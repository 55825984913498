import React from "react";
import { BackNextButtonWrapper } from "./backNextButton.styled";
import { ArrowForwardIcon } from "&assets/constants/icons";
import { useTranslation } from "react-i18next";
import { colors } from "&assets/constants/colors";

interface Props {
  id: string;
  isNext: boolean;
  disabled?: boolean;
  onClick?: () => void;
}

const BackNextButton = (props: Props) => {
  const { isNext, disabled, onClick, id } = props;
  const { t } = useTranslation(["assessment"]);

  return (
    <BackNextButtonWrapper onClick={onClick} disabled={disabled} isNext={isNext} id={id}>
      <div className="text">{isNext ? t("NEXT") : t("BACK")}</div>
      <div className="arrow-circle-wrapper">{ArrowForwardIcon(isNext ? colors.white : colors.dark)}</div>
    </BackNextButtonWrapper>
  );
};

export default BackNextButton;
