import { device } from "&assets/constants/responsiveness";
import styled from "styled-components";

export const DashboardWrapper = styled.div`
  background: rgba(16, 140, 210, 0.1);
  .dashboard-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 140px 25px 140px;
    box-sizing: border-box;
  }
  
  .flex-row{
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  
  @media ${device.tablet} {
    .dashboard-container {
      padding: 0;
    }
  }
  @media ${device.mobile} {
    .dashboard-container {
      padding: 0;
    }
  }
`;
