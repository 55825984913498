import styled from "styled-components";
import { colors } from "&assets/constants/colors";

interface Props {
  hasError?: boolean;
}

export const SectionTitleWithLineWrapper = styled.div<Props>`
  margin-bottom: 25px;

  .title-line {
    display: flex;
  }

  .title {
    font-weight: 700;
    font-size: 18px;
    line-height: 120%;
    color: ${colors.dark};
    white-space: nowrap;
  }

  .description-text {
    margin-top: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    color: ${(props) => (props.hasError ? colors.errorColor : colors.primary)};
  }

  .divider-line {
    ${(props) =>
            props.theme.dir === "rtl"
                    ? "margin-right: 30px;"
                    : "margin-left: 30px;"}
    width: 100%;
    opacity: 0.5;
    border: 0;
    margin-bottom: 4px;
    border-bottom: 0.5px solid ${colors.dark};
  }
`;
