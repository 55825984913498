/**
 * i18n bundles namespace
 * Consists of English and arabic translations
 */
export const bundlesNameSpace = {
  en: {
    PURCHASE_BUNDLE: "Purchase Bundle",
    LEARNING_BUNDLES: "Learning Bundles",
    LEARNING_BUNDLES_SUBTITLE: "Discover our learning bundles! Enhance your skills by selecting from the below:",
    SHOW_MORE: "Show more",
    VIEW_BRANCHES: "View Branches",
    HOW_TO_PAY_NAMMISKILLS: "Pay Cash at Forward Mena Office",
    HOW_TO_PAY_OMT: "How to pay at OMT",
    SELECT_PAYMENT_OPTION: "Select your payment option",
    HOW_PAY_CARD: "How to pay using card",
    UPLOAD_RECEIPT: "Upload Receipt",
    DRAG_AND_DROP_YOUR_RECEIPT_HERE: "Drag and drop your receipt here",
    TRANSACTION_CANCELATION: "Transaction Cancelation",
    ARE_YOU_SURE: "Are you sure you want to cancel this transaction?",
    DISAGREE: "Disagree",
    AGREE: "agree",
    CONTINUE: "Continue",
    PROCEED: "Proceed",
    CERTIFICATION: "Certification",
    VIEW_TRANSACTION: "Transactions",
    CANCEL_TRANSACTION: "Cancel Transactions",
    VIEW_RELATED_CERTIFICATES: "View Related Certificates",
    RELATED_CERTIFICATES: "Related Certificates",
    TRANSACTIONS: "Transactions",
    TRANSACTION_ADDED: "Transaction Added Successfully",
    ID: "ID",
    STATUS: "Payment Status",
    AMOUNT: "Amount",
    ACTIVE: "Bundle Active",
    REASON: "Reason",
    ACTION: "Action",
    CARD: "Card",
    WARNING: "Attention",
    ATTENTION:
      "Please be aware that purchasing a learning bundle will prevent you from sponsorship benefits if you meet eligibility criteria.",
    CASH: "Cash",
    CURRENT: "Current",
    HISTORY: "History",
    PAYMENT_HISTORY: "Payment History",
    PAYMENT_STATUS: "Payment Status",
    NO_TRANSACTIONS_FOUND: "No transactions found",
    ACTIVE_TILL: "Active till",
    EXPIRES_AT: "Expires at",
    PAY_CARD_INSTRUCTION: "Pay online using bank card",
    PAY_CARD_INSTRUCTION_TWO: "To proceed with your payment, you'll be directed to the payment form.",
    PAY_CASH_INSTRUCTION: "Please make payment in the following address:",
    PAY_CASH_INSTRUCTION_TWO: "Bshara El Khoury, Beirut Digital District, Building 1079, 4th Floor.",
    PAY_CASH_INSTRUCTION_THREE: "For any information, please contact us on 01 631 064.",
    BUNDLES_EMPTY: "There are no paid bundles currently available",
  },
  ar: {
    PURCHASE_BUNDLE: "شراء الباقة",
    PAY_CARD_INSTRUCTION: "الدفع عبر الإنترنت باستخدام البطاقة المصرفية",
    PAY_CARD_INSTRUCTION_TWO: "لمتابعة عملية الدفع، سيتم توجيهك إلى نموذج الدفع.",
    CARD: "الدفع عن طريق البطاقة",
    CASH: "الدفع نقدا",
    WARNING: "تنبيه",
    ATTENTION:
      "يُرجى اخذ العلم بأن عند شراء باقة تعليمية سوف يؤثر على إمكانية الحصول على  مزايا الرعاية في حال كنت تستوفي معايير المطلوبة.",
    LEARNING_BUNDLES_SUBTITLE: "إكتشف باقاتنا التعليمية الان ! طور مهاراتك من خلال إختيار واحدة من الباقات التالية:",
    CONTINUE: "متابعة",
    LEARNING_BUNDLES: "باقات التعلم",
    SHOW_MORE: "عرض المزيد",
    VIEW_BRANCHES: "عرض الفروع",
    CERTIFICATION: "شهادة",
    HOW_TO_PAY_NAMMISKILLS: "الدفع نقدا في مكتب فوروارد مينا ",
    HOW_TO_PAY_OMT: "كيفية الدفع في OMT",
    SELECT_PAYMENT_OPTION: "اختر خيار الدفع الخاص بك",
    HOW_PAY_CARD: "كيفية الدفع باستخدام البطاقة",
    UPLOAD_RECEIPT: "تحميل الايصال",
    DRAG_AND_DROP_YOUR_RECEIPT_HERE: "اسحب وأسقط إيصالك هنا",
    TRANSACTION_CANCELATION: "إلغاء العملية",
    ARE_YOU_SURE: "هل أنت متأكد أنك تريد إلغاء هذه العملية؟",
    DISAGREE: "عدم الموافقة",
    AGREE: "موافقة",
    PROCEED: "استمرار",
    VIEW_TRANSACTION: "عرض العمليات",
    CANCEL_TRANSACTION: "إلغاء العملية",
    VIEW_RELATED_CERTIFICATES: "عرض الشهادات المتعلقة",
    RELATED_CERTIFICATES: "الشهادات المتعلقة",
    TRANSACTIONS: "العمليات",
    TRANSACTION_ADDED: "تمت إضافة العملية بنجاح",
    ID: "بطاقة تعريف",
    STATUS: "حالة العملية",
    AMOUNT: "المبلغ",
    ACTIVE: "حالة الباقة",
    REASON: "السبب",
    ACTION: "الإجراءات",
    CURRENT: "الحالية",
    HISTORY: "السابقة",
    PAYMENT_HISTORY: "المعاملات السابقة",
    PAYMENT_STATUS: "المعاملات الحالية",
    NO_TRANSACTIONS_FOUND: "لا يوجد أي معاملات",
    ACTIVE_TILL: "فعالة لغاية",
    EXPIRES_AT: "صالحة لغاية",
    PAY_CASH_INSTRUCTION: "رجاء القدوم الى العنوان التالي للدفع :",
    PAY_CASH_INSTRUCTION_TWO: "بشارة الخوري، مدينة بيروت الرقمية، مبنى رقم 1079 ، الطابق الرابع",
    PAY_CASH_INSTRUCTION_THREE: "للمزيد من المعلومات الرجاء التواصل معنا على الرقم التالي  01631064",
    BUNDLES_EMPTY: " لا توجد باقات تعلم متاحة حاليًا",
  },
};
