import { colors } from "&assets/constants/colors";
import { device } from "&assets/constants/responsiveness";
import styled from "styled-components";

interface Props {
  isSelected?: boolean;
}

export const MatchingCareerSwipeWrapper = styled.div<Props>`
  ${(props) => (props.theme.dir === "rtl" ? "margin-right: 16px;" : "margin-left: 16px;")}
  .matching-career {
    box-sizing: border-box;
    position: relative;
    border: 1px solid transparent;
    background: ${(props) =>
          props.isSelected || props.isSelected ? colors.linearGradientOne : colors.primaryLighterGradient}
        padding-box,
      ${colors.linearGradientOne} border-box;
    width: 336px;
    height: 327px;
    display: flex;
    border-radius: 16px;
    padding: 20px 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .view-career-btn {
      width: 116px;
      height: 38px;
      justify-content: center;
      background: transparent;
      border: 1px solid ${(props) => (props.isSelected || props.isSelected ? colors.white : colors.primary)};
      margin-top: 31px;
    }
    .view-career-btn-text {
      color: ${(props) => (props.isSelected || props.isSelected ? colors.white : colors.primary)};
      font-size: 14px;
    }
    .circle-text-button-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      .arrows {
        cursor: pointer;
        ${(props) => (props.theme.dir === "rtl" ? "transform: scaleX(-1);" : "")}
      }
    }

    .career-name-text {
      margin-top: 18px;
      font-weight: 700;
      font-size: 18px;
      width: 150px;
      line-height: 120%;
      text-align: center;
      color: ${(props) => (props.isSelected || props.isSelected ? colors.white : colors.dark)};
    }
    .progress-circle-wrapper {
      position: relative;
      display: flex;
      width: 144px;
      height: 144px;
      align-items: center;
      justify-content: center;
      margin: 0 30px;

      .progress-background {
        position: absolute;
        width: 144px !important;
        height: 144px !important;
        background: transparent;
        border: 2px solid ${(props) => (props.isSelected || props.isSelected ? "#86BFDF" : "#86C4E7")};
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 100px;
      }

      .MuiCircularProgress-root {
        color: ${(props) => (props.isSelected || props.isSelected ? colors.secondary : colors.primary)};
        width: 158px !important;
        height: 158px !important;
        padding: 0 !important;
        background: transparent;
        position: absolute;

        svg {
          overflow: initial;
        }
        circle {
          stroke-width: 1.5px;
          //r: 22.3;
          stroke-linecap: round;
        }
      }

      .percentage-circle {
        box-sizing: border-box;
        width: 121.85px;
        height: 121.85px;
        border-radius: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        position: relative;
        box-shadow: ${(props) =>
          props.isSelected || props.isSelected
            ? "0 2px 13px rgba(72, 26, 17, 0.56)"
            : " 0 4px 4px rgba(0, 0, 0, 0.25)"};

        .background {
          position: absolute;
          width: 100%;
          height: 100%;
          z-index: 0;
          background: ${(props) =>
            props.isSelected || props.isSelected ? colors.linearGradientTwo : colors.linearGradientOne};
          opacity: ${(props) => (props.isSelected || props.isSelected ? 1 : 0.5)};
        }
        .background-graphic {
          position: absolute;
          bottom: 0;
          z-index: 0;
          ${(props) => (props.theme.dir === "rtl" ? "transform: scaleX(-1);" : "")}
        }

        .percentage-text {
          z-index: 1;
          width: 70.62px;
          height: 36.69px;
          font-weight: 700;
          font-size: 26px;
          line-height: 110%;
          text-align: center;
          letter-spacing: -0.02em;
          color: ${colors.white};
        }

        .text {
          width: 71px;
          height: 24px;
          z-index: 1;
          font-weight: 700;
          font-size: 14px;
          line-height: 120%;
          text-align: center;
          color: ${colors.white};
        }
      }
    }
  }
  @media ${device.mobile} {
    ${(props) => (props.theme.dir === "rtl" ? "margin-right: 0;" : "margin-left: 0;")}
    padding: 0 11px;
    .matching-career {
      width: 100%;
    }
  }
`;
