import React, { useEffect } from "react";
import { isEmpty } from "lodash";

import { ProfileAddEligibilityFormWrapper } from "./profileAddEligibilityForm.styled";
import { EligibilityRequest } from "&features/dashboard/screens/profile/profile.type";
import { EligibilityFormValues } from "&features/login/login.type";
import ApplyForSponsorshipForm from "&components/profile/buildProfileStepThree/buildProfileStepThree.component";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "&store/store";
import { profileActions } from "&features/dashboard/screens/profile/profile.slice";
import { loginActions } from "&features/login/login.slice";
// import {useTranslation} from "react-i18next";

interface Props {
  initialValues?: EligibilityFormValues;
  submit?: number;
}

const ProfileAddEligibilityForm = (props: Props) => {
  const { submit, initialValues } = props;
  // const { t } = useTranslation(["profile"]);

  const isAwaiting =
    !isEmpty(initialValues?.residence) &&
    !initialValues?.approved &&
    !initialValues?.rejected;

  const isApproved = initialValues?.approved;

  const isEdit = !isEmpty(initialValues?.residence) || isAwaiting;

  const dispatch = useDispatch();

  const countries = useSelector((state: RootState) => state.profile.countries);

  const loadingCountries = useSelector(
    (state: RootState) => state.profile.loadingCountries
  );

  const loadingEligibility = useSelector(
    (state: RootState) => state.profile.loadingEligibility
  );

  const eligibilityStatus = useSelector(
    (state: RootState) => state.profile.eligibilityStatus
  );

  const getCountries = () => dispatch(profileActions.getCountries());

  const setProfile = (body: any) => dispatch(profileActions.setProfile(body));

  const postEligibility = (body: EligibilityRequest) =>
    dispatch(profileActions.postEligibility(body));

  const updateEligibility = (body: EligibilityRequest) =>
    dispatch(profileActions.updateEligibility(body));

  useEffect(() => {
    !countries && getCountries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      eligibilityStatus === "UPDATE_ELIGIBILITY_SUCCESS" ||
      eligibilityStatus === "ELIGIBILITY_POST_SUCCESS"
    ) {
      dispatch(profileActions.setProfile({ eligibilityStatus: "" }));
      dispatch(loginActions.getProfileThunk({}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eligibilityStatus]);

  return (
    <ProfileAddEligibilityFormWrapper>
      <ApplyForSponsorshipForm
        initialValues={initialValues}
        countries={countries}
        setProfile={setProfile}
        submit={submit}
        className="remove-padding"
        postEligibility={isEdit ? updateEligibility : postEligibility}
        isAwaiting={isAwaiting}
        isApproved={isApproved}
        error={
          eligibilityStatus === "FORM_ALREADY_SUBMITTED"
            ? "Eligibility form already submitted"
            : ""
        }
        loading={loadingEligibility || loadingCountries}
      />
    </ProfileAddEligibilityFormWrapper>
  );
};

export default ProfileAddEligibilityForm;
