import React from "react";
import { PaginationMUI, PaginationWrapper } from "./pagination.styled";
import {useMediaQuery} from "react-responsive";
import {size} from "&assets/constants/responsiveness";

interface Props {
  count?: number;
  onChangePage: (page: number) => void;
  page: number;
}

const PaginationComponent = (props: Props) => {
  const { count, onChangePage, page } = props;

  const isMobile = useMediaQuery({ maxWidth: size.mobile });

  if (!count) {
    return null;
  }

  return (
    <PaginationWrapper>
      <PaginationMUI
        count={count}
        page={page}
        boundaryCount={isMobile ? 0 : 1}
        siblingCount={isMobile ? 0 : 1}
        onChange={(e, page) => onChangePage(page)}
        shape={"rounded"}
      />
    </PaginationWrapper>
  );
};

export default PaginationComponent;
