import styled from "styled-components";
import { colors } from "&assets/constants/colors";

interface Props {
  isNext: boolean;
  disabled?: boolean;
}

export const BackNextButtonWrapper = styled.button<Props>`
  display: flex;
  opacity: ${(props) => (props.isNext ? (props.disabled ? 0.5 : 1) : 0.5)};
  background: inherit;
  border: 0;
  padding: 0;
  ${(props) => (props.disabled ? "" : "cursor: pointer;")}
  align-items: center;
  ${(props) => (props.isNext ? "" : "flex-direction: row-reverse;")}

  .arrow-circle-wrapper {
    width: 48px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    background: ${(props) => (props.isNext ? `${colors.linearGradientOne};` : `${colors.white};`)} ${colors.white};

    box-shadow: 0 79px 128px rgba(192, 192, 192, 0.09), 0 28.8363px 46.7221px rgba(192, 192, 192, 0.0598508),
      0 13.9995px 22.6827px rgba(192, 192, 192, 0.0475723), 0 6.86281px 11.1195px rgba(192, 192, 192, 0.0380675),
      0 2.71357px 4.39666px rgba(192, 192, 192, 0.0270615);
    border-radius: 80px;
    ${(props) => (props.theme.dir === "rtl" ? "margin-left: 8px;" : "margin-right: 8px;")}
    ${(props) =>
      props.isNext
        ? props.theme.dir === "rtl"
          ? "transform: rotate(180deg);"
          : ""
        : props.theme.dir === "rtl"
        ? ""
        : "transform: rotate(180deg);"}
  }

  .text {
    margin: 0 10px;
    font-weight: 800;
    font-size: 18px;
    line-height: 160%;
    color: ${(props) => (props.isNext ? `${colors.primary};` : `${colors.dark};`)};
  }
`;
