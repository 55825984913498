
import styled from "styled-components";
import {colors} from "&assets/constants/colors";

export const ProfileEducationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 0;

  .education-title-edit-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 12px;
  }


  .school-name-title-text {
    font-weight: 700;
    font-size: 18px;
    line-height: 160%;
    display: flex;
    align-items: center;
    color: ${colors.primary};
  }

  .education-degree-text {
    font-weight: 700;
    font-size: 16px;
    line-height: 160%;
    color: ${colors.dark};
    margin-bottom: 6px;
  }

  .date-period-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    color: ${colors.dark};
    opacity: 0.6;
    margin-bottom: 11px;
  }

  .description-text {
    max-width: 576px;
    font-weight: 500;
    font-size: 16px;
    line-height: 160%;
    color: ${colors.dark};
  }
  
  
  `;

