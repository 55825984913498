import { useTranslation } from "react-i18next";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { connect, ConnectedProps, useSelector } from "react-redux";

import { RootState } from "&store/store";
import { images } from "&assets/constants/images-urls";

import { CertificationsWrapper } from "./certifications.styled";
import { certificationsPageActions } from "./certifications.slice";

import CircularLoader from "&components/circularLoader/circularLoader.component";
import SectionTitleButton from "&components/sectionTitleButton/sectionTitleButton.component";
import CertificationHeader from "&components/certificationHeader/certificationHeader.component";
import CertificationsCards from "&components/certificationsCards/certificationsCards.component";
import LearningPathwayCard from "&components/learningPathwayCard/learningPathwayCard.component";
import CertificationsDetailsComponent from "&components/certificationDetails/certificationDetails.component";

import { Exam, LearningPathInterface, LearningPathwayType } from "&features/listingPage/listingPage.type";

import { checkIFEnrolledLP } from "&utils/checkIfEnrolled";
import { translationSelector } from "&utils/translationSelector";
import getCDNProtectedURL from "&utils/getCDNProtectedImageSource";
import { learningPathwayActions } from "&features/learningPathway/learningPathway.slice";
import { loginActions } from "&features/login/login.slice";
import { useAuth0 } from "@auth0/auth0-react";
import { useMediaQuery } from "react-responsive";
import { size } from "&assets/constants/responsiveness";
import { Helmet } from "react-helmet";

type ReduxProps = ConnectedProps<typeof connector>;

const CertificationsComponent = (props: ReduxProps) => {
  const {
    getCertifications,
    getCertificateHeader,
    settings,
    user,
    certifications,
    certificationsHeader,
    isLoggedIn,
    enrollLearningPathway,
    setLogin,
  } = props;
  const { t } = useTranslation(["certifications"]);
  const navigate = useNavigate();
  const { loginWithRedirect } = useAuth0();

  const detailsRef: any = useRef(null);

  const loading = useSelector((state: any) => state.learningPathway.loading);
  const [selectedCertificate, setSelectedCertificate] = useState<Exam | null>(null);

  const [urlSearchParams] = useSearchParams();
  const searchParams = {
    source: urlSearchParams.get("source") !== "0" ? urlSearchParams.get("source") : undefined,
  };
  const isMobile = useMediaQuery({ maxWidth: size.mobile });

  useEffect(() => {
    getCertificateHeader();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getCertifications({ source: searchParams.source });
    setSelectedCertificate(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.source]);

  const handleClickLearningPathway = (item: LearningPathwayType) => {
    checkIFEnrolledLP(item.id, user)
      ? navigate(`/dashboard/learning-pathway/${item.id}`)
      : navigate(`/learning-pathway/${item.id}`);
  };

  useEffect(() => {
    if (selectedCertificate && detailsRef.current) {
      detailsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [selectedCertificate]);
  const loadingComponent = (
    <div className="loader-wrapper">
      <CircularLoader />
    </div>
  );
  if (loading) {
    return loadingComponent;
  }

  const handleEnrollLearningPathway = async (item: LearningPathInterface) => {
    if (isLoggedIn) {
      if (checkIFEnrolledLP(item.learningPathId, user)) {
        navigate(`/dashboard/learning-pathway/${item.learningPathId}`);
      } else {
        await enrollLearningPathway(`${item.learningPathId}`);
        navigate(`/dashboard/learning-pathway/${item.learningPathId}`);
      }
    } else {
      const id = item.learningPathId;
      setLogin({
        actionAfterSignup: "enroll-learningPathway",
        id,
      });
      loginWithRedirect();
    }
  };
  return (
    <CertificationsWrapper>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      {certificationsHeader ? (
        <>
          <CertificationHeader data={certificationsHeader.sections.Certificates[0]?.sectionContents} />
          <div style={{ position: "relative" }}>
            {!isMobile && <div className="certification-bg"></div>}

            <CertificationsCards
              className={isMobile ? "certification-bg" : ""}
              exams={certifications}
              title={t("TOP_CERTIFICATIONS")}
              description={t("VIEW_ALL")}
              settings={settings}
              selectedCertificate={selectedCertificate}
              setSelectedCertificate={(item: Exam | null) => {
                if (selectedCertificate && item && item.id === selectedCertificate.id) {
                  setSelectedCertificate(null);
                } else {
                  setSelectedCertificate(item);
                }
              }}
            />
          </div>
          {selectedCertificate && <CertificationsDetailsComponent ref={detailsRef} exam={selectedCertificate} />}

          {selectedCertificate && selectedCertificate.learningPaths.length > 0 && (
            <div className="learning-paths">
              <div
                style={{
                  maxWidth: "1089px",
                  margin: "auto",
                }}
              >
                <div
                  style={{
                    marginBottom: "20px",
                  }}
                >
                  <SectionTitleButton
                    wrapperClassName="title-button-wrapper"
                    buttonText={""}
                    onButtonClick={() => {}}
                    title={t("AVAILABLE_LEARNING_PATHS_TTILE")}
                    isWhite={false}
                    hideButton={true}
                  />
                </div>

                {selectedCertificate.learningPaths?.map((learningPath: LearningPathInterface, index: number) => {
                  if (learningPath.selected)
                    return (
                      <LearningPathwayCard
                        key={index}
                        id={learningPath?.learningPathId}
                        title={translationSelector(learningPath?.learningPaths, "name")}
                        description={translationSelector(learningPath?.learningPaths, "shortDescription")}
                        time={learningPath?.learningPaths.duration}
                        hasExam={true}
                        subTitle={
                          learningPath?.learningPaths?.learningPathExams?.length > 0
                            ? learningPath?.learningPaths?.learningPathExams[0]?.exams?.source
                            : learningPath?.learningPaths.source
                        }
                        skills={learningPath?.learningPaths?.learningPathSkills}
                        modul={
                          learningPath?.learningPaths.learningPathActivities?.length + ` ${t("LEARNING_ACTIVITIES")}`
                        }
                        image={learningPath?.learningPaths.image || getCDNProtectedURL(images.learningPathwayImg)}
                        level={translationSelector(learningPath?.level, "alias")}
                        handleClick={() => handleClickLearningPathway(learningPath?.learningPaths)}
                        isSponsored={learningPath?.learningPaths.sponsored}
                        showQuickView={false}
                        enrollBtn={true}
                        enrollClick={() => handleEnrollLearningPathway(learningPath)}
                      />
                    );
                })}
              </div>
            </div>
          )}
        </>
      ) : (
        <div style={{ height: "100vh" }}></div>
      )}
    </CertificationsWrapper>
  );
};

const mapStateToProps = (state: RootState) => ({
  certifications: state.certifications.certifications,
  certificationsHeader: state.certifications.certificationHeader,
  isLoggedIn: state.login.isLoggedIn,
  status: state.certifications.status,
  user: state.login.user,
  settings: state.global.settings,
});

const mapDispatchToProps = {
  getCertificateHeader: certificationsPageActions.getCertificateHeader,
  getCertifications: certificationsPageActions.getCertifications,
  enrollLearningPathway: learningPathwayActions.enrollLearningPathway,
  setLogin: loginActions.setLogin,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const CertificationsComponentRedux = connector(CertificationsComponent);

export { CertificationsComponentRedux as CertificationsComponent };
