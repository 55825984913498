import styled from "styled-components";
import { device } from "&assets/constants/responsiveness";
import { colors } from "&assets/constants/colors";

export const ReferralPickerWrapper = styled.div`
  position: relative;

  .select-referrals-input-error {
    border: 1px red solid !important;
  }
  .input-wrapper {
    width: 423px;
    box-sizing: border-box;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 12px;
    border-radius: 12px;
    border: 1px solid transparent;

    background: ${colors.linearGradientWhite} padding-box, ${colors.linearGradientSixth} border-box;

    &[aria-owns="other-skills-search-listbox"] {
      border-radius: 12px 12px 0 0;
      background: ${colors.linearGradientWhite} padding-box, ${colors.linearGradientOne} border-box;
    }

    @media ${device.tablet} {
      width: 423px;
    }

    @media ${device.mobile} {
      width: 90vw;
    }
  }

  .spacing-tags-wrapper {
    display: flex;
    .spacing-wrapper {
      width: 70%;
    }
  }

  @media ${device.tablet}, ${device.mobile}, ${device.inBetween} {
    .spacing-tags-wrapper {
      flex-direction: column;
      .spacing-wrapper {
        height: 165px;
      }
    }
  }

  input {
    width: 100%;
    border: 0;
    background: none;
    &::placeholder {
      font-weight: 500;
      font-size: 16px;
      line-height: 160%;
      color: ${colors.dark};
      opacity: 0.7;
    }

    &:focus {
      border: 1px solid transparent;
      outline: inherit;
    }
  }

  .description-text {
    margin-top: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    color: ${colors.primary};
  }
`;

interface ListboxProps {
  disabled: boolean;
}

export const Listbox = styled.ul<ListboxProps | any>`
  width: 423px;
  padding: 0;
  position: relative;
  list-style: none;
  z-index: 1;
  box-sizing: border-box;
  border-radius: 0 0 10px 10px;
  overflow-y: auto;
  margin-top: 0;
  overflow-x: hidden;
  max-height: 200px;
  border: 1px solid transparent;
  background: ${colors.linearGradientWhite} padding-box, ${colors.linearGradientThree} border-box;

  @media ${device.mobile} {
    width: 90vw;
  }

  & li {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    align-items: center;
    height: 30px;
    ${(props) => (props.theme.dir === "rtl" ? "padding: 0 10px 0 10px;" : "padding: 0 10px 0 10px;")}
    font-weight: 500;
    font-size: 14px;
    line-height: 160%;

    .icon-wrapper {
      visibility: hidden;
      display: flex;
      align-items: center;
    }

    //&:first-child {
    //  padding-top: 10px;
    //}
    //
    &:last-child {
      border: 0 solid transparent;
      border-width: 0 0 1px 0;
      background: ${colors.linearGradientWhite} padding-box, ${colors.linearGradientOne} border-box;
    }

    &.Mui-focused {
      background: rgba(16, 140, 210, 0.1) !important;
      cursor: ${(props) => (props.disabled ? "not-allowed" : "")};

      .icon-wrapper {
        visibility: ${(props) => (props.disabled ? "hidden" : "initial")};
      }
    }

    &:active {
      background: rgba(16, 140, 210, 0.1);
      cursor: ${(props) => (props.disabled ? "not-allowed" : "")};
    }
  }

  & li[aria-selected="true"] {
    background: rgba(16, 140, 210, 0.2);
    cursor: pointer;

    .icon-wrapper {
      visibility: hidden !important;
    }
  }
`;

interface CategoryButtonProps {
  open?: boolean;
}
export const CategoryButton = styled.button<CategoryButtonProps>`
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 10px;
  ${(props) => (props.theme.dir === "rtl" ? "text-align: right;" : "text-align: left;")}
  border: 0 solid transparent;
  ${(props) => (props.open ? "border-width: 0 0 1px 0;" : "")}
  background: ${colors.linearGradientWhite} padding-box,
  ${colors.linearGradientOne} border-box;
  ${(props) => (props.open ? "box-shadow: 0 4px 6px rgba(0, 0, 0, 0.25);" : "")}

  svg {
    ${(props) => (props.open ? "transform: rotate(180deg);" : "")}
    transition: all 300ms ease-in-out;
  }

  .button-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 160%;
  }

  &:hover {
    background: linear-gradient(56.4deg, ${colors.primaryLight} 8.08%, ${colors.primaryLight} 87.11%) padding-box,
      ${colors.linearGradientOne} border-box;
  }
`;

export const OtherSkillsTagsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;

  @media ${device.mobile} {
    min-width: 90vw;
    max-width: 90vw;
    display: grid;
    grid-gap: 16px;
    flex-wrap: nowrap;
    grid-template-columns: 47% 47%;
  }
`;

export const OptionTagSliderWrapper = styled.div`
  ${(props) => (props.theme.dir === "rtl" ? "margin-left: 20px;" : "margin-right: 20px;")}
  margin-bottom: 15px;
  .slider {
    margin-top: 6px;
  }

  @media ${device.mobile} {
    width: auto;
    ${(props) => (props.theme.dir === "rtl" ? "margin-left: 0;" : "margin-right: 0;")}
    margin-bottom: 0;
  }
`;
