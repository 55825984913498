import styled from "styled-components";
import { device } from "&assets/constants/responsiveness";
import {colors} from "&assets/constants/colors";

export const SuccessWrapper = styled.div`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;

  .linear-progress {
    width: 100%;
  }
  .title {
    max-width: 765px;
    font-family: "Mulish";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 110%;
    text-align: center;
    letter-spacing: -0.02em;
    color: ${colors.dark};
    .highlight {
      color: ${colors.primary};
      font-weight: 900;
    }
  }
  .info {
    max-width: 461px;
    font-family: "Mulish";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 160%;
    text-align: center;
    color: ${colors.dark};
    margin-top: 20px;
    opacity: 0.7;
  }
  .success-image {
    width: 380.37px;
    height: 360.62px;
  }

  @media ${device.tablet} {
    .success-image {
      width: 316.73px;
      height: 281.02px;
    }
  }

  @media ${device.mobile} {
    .title {
      font-size: 18px;
    }

    .info {
      font-size: 14px;
    }
    .success-image {
      width: 316.73px;
      height: 281.02px;
    }
  }
`;
