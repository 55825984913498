import React from "react";

import { GradientBackgroundWrapper } from "./gradientBackground.styled";
import Container from "&components/container/container.component";
import SectionTitleButton from "&components/sectionTitleButton/sectionTitleButton.component";

interface Props {
  title?: string;
  allButtonText?: string;
  onAllButtonClick?: () => void;
  children: React.ReactNode;
}

const GradientBackground = (props: Props) => {
  const {
    title = "",
    allButtonText = "",
    onAllButtonClick = () => {},
    children,
  } = props;

  return (
    <GradientBackgroundWrapper>
      <Container className="conatiner">
        <SectionTitleButton
          title={title}
          buttonText={allButtonText}
          onButtonClick={onAllButtonClick}
          wrapperClassName="header"
          isWhite={true}
        />
        {children}
        <div className="oval-left" />
        <div className="oval-right" />
      </Container>
    </GradientBackgroundWrapper>
  );
};

export default GradientBackground;
