import React from "react";
import { AutoCompleteSelectWithTitleWrapper } from "./autoCompleteSelectWithTitle.styled";
import { AutocompleteChangeReason, AutocompleteValue } from "@mui/material";
import AutoCompleteSelect from "&components/inputs/select/autoCompleteSelect/autoCompleteSelect.component";
import { LabelWrapper } from "&components/inputs/input/input.styled";

interface Props {
  id: string;
  label?: string;
  value: any;
  options: any[];
  disabled?: boolean;
  rightIcon?: any;
  error?: any;
  placeholder?: string;
  labelClassName?: string;
  multiple?: boolean;
  handleChange: (event: any, options: AutocompleteValue<any, any, any, any>, reason: AutocompleteChangeReason) => void;
  className?: string;
  inputWrapperClassName?: string;
  labelIcon?: any;
}

const AutoCompleteSelectWithLabel = (props: Props) => {
  const {
    id,
    value,
    options,
    handleChange,
    disabled,
    labelClassName,
    error,
    placeholder,
    multiple,
    inputWrapperClassName,
    label,
    rightIcon,
    className,
    labelIcon,
  } = props;
  return (
    <AutoCompleteSelectWithTitleWrapper className={className}>
      <LabelWrapper disabled={disabled} valid={error} className={labelClassName}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {label} {labelIcon && labelIcon}
        </div>
      </LabelWrapper>
      <AutoCompleteSelect
        id={id}
        value={value}
        title={error}
        multiple={multiple}
        error={error}
        placeholder={placeholder}
        rightIcon={rightIcon}
        disabled={disabled}
        className={inputWrapperClassName}
        options={options}
        handleChange={handleChange}
      />
    </AutoCompleteSelectWithTitleWrapper>
  );
};

export default AutoCompleteSelectWithLabel;
