import Container from "&components/container/container.component";
import InformativeCard from "&components/informativeCard/informativeCard.component";
import React from "react";
import { InformativeSectionWrapper } from "./informativeSection.styled";
import {translationSelector} from "&utils/translationSelector";

interface Props {
  data?: Informative[];
  className?: string;
  cardClassName?: string;
}

interface Informative {
  title?: string;
  titleAr?: string;
  description?: string;
  descriptionAr?: string;
  icon?: any;
  buttonTitle?: string;
  handleButtonClick?: () => void;
}

const InformativeSection = (props: Props) => {
  const { data, className = "", cardClassName = "" } = props;
  return (
    <InformativeSectionWrapper className={className}>
      <Container className="container">
        {data?.map((item: Informative, index: number) => {
          return (
            <InformativeCard
              key={index}
              description={translationSelector(item,'description')}
              title={translationSelector(item,'title')}
              icon={item.icon}
              className={cardClassName}
              buttonTitle={translationSelector(item,'buttonTitle')}
              handleButtonClick={item.handleButtonClick}
            />
          );
        })}
      </Container>
    </InformativeSectionWrapper>
  );
};

export default InformativeSection;
