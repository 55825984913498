import { colors } from "&assets/constants/colors";
import { device } from "&assets/constants/responsiveness";
import styled from "styled-components";

export const AboutHeaderAims = styled.div`
  margin-top: 140px;
  .header-wrapper {
    display: flex;
    ${(props) => (props.theme.dir === "rtl" ? "padding-left: 64px;" : "padding-right: 64px;")}
    justify-content: flex-start;
    gap: 56px;
    margin: 65px 0;
    padding-bottom: 20px;
  }
  .header-blue-area {
    flex-shrink: 0;
    width: 100%;
    height: 279px;
    position: relative;

    ${(props) =>
      props.theme.dir === "rtl" ? "border-radius: 40px 0px 0px 40px;" : "border-radius: 0px 40px 40px 0px;"}
    background: ${colors.linearGradientEight};
  }
  .header-blue-area-image-wrapper {
    text-align: center;
    display: flex;
    justify-content: center;
    width: 50%;
  }
  .header-blue-area-image-wrapper .about-us-header-image {
    position: absolute;
    top: 50%;
    ${(props) => (props.theme.dir === "rtl" ? "left: 64px;" : "right: 64px;")}
    bottom: 50%;
    margin: auto;
    width: 537px;
    height: 418px;
    border-radius: 40px;
  }
  .header-text-area {
    display: flex;
    width: 537px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
  }
  .header-text-area h1 {
    color: ${colors.dark};
    font-feature-settings: "cv11" on;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    letter-spacing: -0.96px;
    padding: 0;
    margin: 0;
  }
  .header-text-area p {
    color: ${colors.dark};
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
    padding: 0;
    margin: 0;
  }
  .sign-up-button-about-us {
    background: ${colors.linearGradientThree};
    width: 130px;
    height: 48px;
    border-radius: 30px;
    padding: 13px 20px 13px 20px;
    gap: 10px;
  }
  .sign-up-button-about-us p {
    color: #fff;
    line-height: 22.4px;
    text-align: center;
    font-size: 14px;
    font-weight: 800;
  }
  @media ${device.tablet} {
    .header-wrapper {
      margin: 0px;
      display: flex;
      flex-wrap: wrap;
      ${(props) => (props.theme.dir === "rtl" ? "padding-left: 0;" : "padding-right: 0;")}
      padding: 15px;
      gap: 75px;
      padding-bottom: 0px;
    }
    .header-blue-area-image-wrapper {
      width: 100%;
      ${(props) => (props.theme.dir === "rtl" ? "padding-left: 15px;" : "padding-right: 15px;")}
    }
    .header-text-area {
      padding: 15px;
    }
    .header-blue-area-image-wrapper .about-us-header-image {
      position: absolute;
      top: 50px;
      ${(props) => (props.theme.dir === "rtl" ? "right: 20px;" : "left: 20px;")}
      ${(props) => (props.theme.dir === "rtl" ? "left: 50px;" : "right: 50px;")}
      bottom: 50px;
      margin: auto;
      width: 322px;
      height: 330px;
    }
  }
  @media ${device.mobile} {
    .header-wrapper {
      display: flex;
      flex-wrap: wrap;
      margin: 0px;
      ${(props) => (props.theme.dir === "rtl" ? "padding-left: 0;" : "padding-right: 0;")}
      gap: 75px;
      padding-bottom: 0px;
    }
    .header-blue-area-image-wrapper {
      width: 100%;
      ${(props) => (props.theme.dir === "rtl" ? "padding-left: 15px;" : "padding-right: 15px;")}
    }
    .header-text-area {
      padding: 15px;
    }
    .header-blue-area-image-wrapper .about-us-header-image {
      position: absolute;
      top: 50px;
      ${(props) => (props.theme.dir === "rtl" ? "right: 20px;" : "left: 20px;")}
      ${(props) => (props.theme.dir === "rtl" ? "left: 50px;" : "right: 50px;")}
      bottom: 50px;
      margin: auto;
      width: 322px;
      height: 330px;
    }
  }
`;
