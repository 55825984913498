import { colors } from "&assets/constants/colors";
import { device } from "&assets/constants/responsiveness";
import styled from "styled-components";

export const ContactUsWrapper = styled.div`
  .header {
    background: ${colors.primaryDark};
    overflow: initial;
    height: fit-content;
    padding-bottom: 100px;
    border-bottom: 0;
  }
  .title-subtitle-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .header-title {
    color: ${colors.white};
    max-width: 664px;
  }
  .informatives {
    background: ${colors.white};
  }
  .container-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .location-image {
      width: 1090px;
      height: 520px;
      border-radius: 16px;
      object-fit: cover;
      margin-top: -133px;
      margin-bottom: 40px;
      z-index: 1;
      position: relative;
    }
  }
  @media ${device.tablet} {
    .container-content {
      box-sizing: border-box;
      padding: 0 30px;
      .location-image {
        width: 100%;
        height: 400px;
        border-radius: 16px;
        margin-top: -170px;
        margin-bottom: 40px;
        z-index: 1;
        position: relative;
      }
    }
  }

  @media ${device.mobile} {
    .header {
      padding-bottom: 160px;
    }
    .container-content {
      box-sizing: border-box;
      padding: 0 16px;
      .location-image {
        width: 100%;
        height: 400px;
        border-radius: 16px;
        margin-top: -170px;
        margin-bottom: 40px;
        z-index: 1;
      }
    }
  }
`;
