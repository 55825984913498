import React, { useEffect, useState } from "react";
import { connect, ConnectedProps, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useNavigate, useParams } from "react-router-dom";
import { isEmpty } from "lodash";

import { RootState } from "&store/store";
import { labDetailsActions } from "./labDetails.slice";
import { LabDetailsWrapper } from "./labDetails.styled";
import LearningHeader from "&components/learningHeader/learningHeader.component";
import Chip from "&components/chip/chip.component";
import MatchingOpportunities from "&components/matchingOpportunities/matchingOpportunities.component";
import ActionButton from "&components/buttons/actionButton/actionButton.component";
import { size } from "&assets/constants/responsiveness";
import Container from "&components/container/container.component";
import { CourseSkill } from "&features/listingPage/listingPage.type";
import { Clock, Document } from "&assets/constants/icons";
import secondsToHours from "&utils/secondstoHours";
import CircularLoader from "&components/circularLoader/circularLoader.component";
import HtmlParser from "&components/htmlParser/htmlParser.component";
import { opportunitiesListingActions } from "&features/opportunitiesListing/opportunitiesListing.slice";
import getCDNProtectedURL from "&utils/getCDNProtectedImageSource";
import { images } from "&assets/constants/images-urls";
import ApplyForVoucher from "&components/applyForVoucher/applyForVoucher.component";
import { LINKEDIN_URL } from "&assets/constants/statics";
import { translationSelector } from "&utils/translationSelector";

type ReduxProps = ConnectedProps<typeof connector>;

type Props = {
  auth?: boolean;
};

const LabDetailsComponent = (props: ReduxProps & Props) => {
  const {
    getLabById,
    loading,
    selectedLab,
    jobs,
    getJobs,
    loadingJobs,
    selectedCountry,
    auth,
  } = props;
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: size.mobile });
  const [readMore, setReadMore] = useState(false);
  const user = useSelector((state: RootState) => state.login.user);

  const { id } = useParams();

  const informatics: any = [
    {
      icon: <Document fill="white" />,
      text: selectedLab?.language?.toUpperCase(),
    },
    {
      icon: <Clock fill="white" />,
      text: secondsToHours(selectedLab?.duration),
    },
  ];
  useEffect(() => {
    id && getLabById(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, localStorage.getItem("language")]);

  useEffect(() => {
    getJobs({ countryId: selectedCountry.value, page: 1, limit: 9 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountry, localStorage.getItem("language")]);

  const loadingComponent = (
    <div className="loader-wrapper">
      <CircularLoader />
    </div>
  );

  const showSponsored =
    selectedLab?.sponsorshipType &&
    selectedLab?.sponsorshipType === "subscription"
      ? !isEmpty(user?.sponsorships) &&
        !isEmpty(user?.sponsorships[selectedLab?.source])
      : false;

  const isLinkedInTracked =
    selectedLab?.source === "LinkedIn" &&
    !isEmpty(user?.sponsorships) &&
    !isEmpty(user?.sponsorships["LinkedIn"]);

  return (
    <LabDetailsWrapper>
      {loading ? (
        loadingComponent
      ) : (
        <>
          <LearningHeader
            title={selectedLab?.name}
            description={selectedLab?.shortDescription}
            handleBackClick={() => navigate(-1)}
            providerName={selectedLab?.source}
            image={
              selectedLab?.image ||
              getCDNProtectedURL(images.learningPathwayImg)
            }
            hasButton={auth}
            buttonText="Launch Lab"
            handleButtonClick={() => {
              window.open(
                isLinkedInTracked
                  ? LINKEDIN_URL + translationSelector(selectedLab, "url")
                  : translationSelector(selectedLab, "url"),
                "_blank",
                "noreferrer"
              );
            }}
            level={selectedLab?.level?.alias}
            informatics={informatics}
            buttonComponent={
              auth &&
              selectedLab?.sponsorshipType &&
              !!selectedLab?.sponsored &&
              selectedLab?.sponsorshipDetails.length === 0 &&
              !selectedLab?.completed && (
                <ApplyForVoucher
                  id={selectedLab.id}
                  type="labs"
                  sponsorshipType={selectedLab?.sponsorshipType}
                  sponsored={showSponsored}
                />
              )
            }
          />
          <Container>
            <div className="course-details">
              <p className="header">Lab description</p>
              <div className={`content-text ${readMore ? "expand" : ""}`}>
                {!readMore && (
                  <div className={`layer ${readMore ? "expand" : ""}`} />
                )}
                <HtmlParser
                  className="description-content"
                  description={selectedLab?.description}
                />
              </div>
              {isMobile && (
                <ActionButton
                  className="read-more"
                  titleClassName="read-more-title"
                  title={readMore ? "Read less" : "Read more"}
                  handleClick={() => setReadMore(!readMore)}
                />
              )}
              <p className="header">Skills you will gain</p>
              <div className="chips">
                {selectedLab?.labSkills?.map(
                  (item: CourseSkill, index: number) => {
                    return <Chip title={item?.skills?.name} key={index}></Chip>;
                  }
                )}
              </div>
            </div>
          </Container>
          {!auth && (
            <MatchingOpportunities
              data={jobs?.items || jobs}
              loading={loadingJobs}
              title={"Matching opportunities"}
              allButtonText={"View all opportunities"}
              onAllButtonClick={() => navigate("/opportunities")}
            />
          )}
        </>
      )}
    </LabDetailsWrapper>
  );
};

const mapStateToProps = (state: RootState) => ({
  selectedLab: state.labDetails.selectedLab,
  loading: state.labDetails.loading,
  jobs: state.opportunitiesListing.jobs,
  loadingJobs: state.opportunitiesListing.loading,
  selectedCountry: state.landingPage.selectedCountry,
});

const mapDispatchToProps = {
  getLabById: labDetailsActions.getLabById,
  getJobs: opportunitiesListingActions.getJobs,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const LabDetailsComponentRedux = connector(LabDetailsComponent);

export { LabDetailsComponentRedux as LabDetailsComponent };
