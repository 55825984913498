import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { LabDetails } from "./labDetails.type";
import { getLabByIdApi } from "./labDetails.api";

const initialState: LabDetails = {
  loading: false,
  selectedLab: undefined,
  status: undefined,
};

const getLabById = createAsyncThunk(
  "labDetails/getLabById",
  async (id: string, { rejectWithValue, getState, dispatch }: any) => {
    try {
      const isLoggedIn = getState().login.isLoggedIn;
      const lab = await getLabByIdApi(id, isLoggedIn);
      return lab?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const labDetailsSlice = createSlice({
  name: "labDetails",
  initialState: initialState,
  reducers: {
    setLabDetails: (state, action) => {
      return { ...state, ...action.payload };
    },
    reset: () => initialState,
  },

  extraReducers: (builder) => {
    builder.addCase(getLabById.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getLabById.fulfilled, (state, action) => {
      state.loading = false;
      state.selectedLab = action.payload;
    });
    builder.addCase(getLabById.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const labDetailsReducer = labDetailsSlice.reducer;

export const labDetailsActions = { ...labDetailsSlice.actions, getLabById };
