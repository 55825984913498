export const notificationsNameSpace = {
  en: {
    // Write english key-mapping here
    MARK_ALL_AS_READ: "Mark all as read",
    NOTIFICATIONS: "Notifications",
  },
  ar: {
    // Write Arabic key-mapping here
    MARK_ALL_AS_READ: "حدد الكل كمقروء",
    NOTIFICATIONS: "إشعارات",
  },
};
