export const frequentlyAskedQuestionsNameSpace = {
  en: {
    STILL_CURIOUS_TO_LEARN_MORE: "Still curious to learn more?",
    OUR_TEAM_IS_READY_TO_SUPPORT_YOU_FURTHER:
      "Our team is ready to support you further. Please do not hesitate to send us your questions through the “Contact Us page!”",
  },
  ar: {
    STILL_CURIOUS_TO_LEARN_MORE: "هل لا تزال مهتمًا بمعرفة المزيد؟",
    OUR_TEAM_IS_READY_TO_SUPPORT_YOU_FURTHER:
      'فريقنا جاهز لتقديم المزيد من الدعم لك. لا تتردد في إرسال أسئلتك إلينا من خلال "صفحة اتصل بنا!"',
  },
};
