import React, { useEffect, useRef, useState } from "react";
import { connect, ConnectedProps, useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { RootState } from "&store/store";
import { listingPageActions } from "./listingPage.slice";
import { ListingPageWrapper } from "./listingPage.styled";
import ListingPageHeader from "&components/headers/listingPageHeader/listingPageHeader.component";
import SearchSortFilterBar from "&components/searchSortFilterBar/searchSortFilterBar.component";
import Container from "&components/container/container.component";
import { landingPageActions } from "&features/landingPage/landingPage.slice";
import CircularLoader from "&components/circularLoader/circularLoader.component";
import ActivityCard from "&components/cards/activityCard/activityCard.component";
import {
  Course,
  Exam,
  GetPageListingQuery,
  LearningPathwayType,
  ListingPageSearchParams,
} from "&features/listingPage/listingPage.type";
import PaginationComponent from "&components/pagination/pagination.component";
import LearningPathwayCard from "&components/learningPathwayCard/learningPathwayCard.component";
import { useNavigate, useSearchParams } from "react-router-dom";
import { learningPathwayActions } from "&features/learningPathway/learningPathway.slice";
import { courseDetailsActions } from "&features/courseDetails/courseDetails.slice";
import { defaultListingPageSearchParams } from "&assets/constants/statics";
import { buildDropDownList, buildSourcesList, querySelector } from "&utils/searchSortFilter/searchSortFilterUtils";
import { globalActions } from "&features/global/global.slice";
import getCDNProtectedURL from "&utils/getCDNProtectedImageSource";
import { images } from "&assets/constants/images-urls";
import { checkIFEnrolledCourse, checkIFEnrolledLP } from "&utils/checkIfEnrolled";
import { translationSelector } from "&utils/translationSelector";
import Banner from "&components/banner/banner.component";
import { useAuth0 } from "@auth0/auth0-react";

type ReduxProps = ConnectedProps<typeof connector>;

const ListingPageComponent = (props: ReduxProps) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation(["listingPage"]);
  const dispatch = useDispatch();
  const { loginWithRedirect } = useAuth0();
  const pages = useSelector((state: any) => state.global.pages);
  const [bannerType, setBannerType] = useState("");

  // const isArabic = i18n.language === "ar";

  const ref = useRef<any>(null);
  const learningActivitiesObject = {
    learning_path: t("LEARNING_PATH"),
    courses: t("COURSES"),
    exams: t("EXAMS"),
  };

  // const sortByObject = {
  //   popular: t("POPULAR"),
  //   [isArabic ? "nameAr" : "name"]: t("NAME"),
  //   createdAt: t("DATE"),
  // };

  const typeObject = {
    all: t("ALL"),
    free: t("FREE"),
    subscription: t("SUBSCRIPTION"),
  };
  const typeLearningActivity = {
    all: t("ALL"),
    withExams: t("WITH_EXAMS"),
    withoutExams: t("WITHOUT_EXAMS"),
  };
  const {
    page,
    getCourses,
    getExams,
    courses,
    exams,
    status,
    loadingApi,
    getLearningPathways,
    learningPathways,
    setLearningPathway,
    setCourseDetails,
    setListingPage,
    user,
    settings,
    getSetting,
    pathsHistory,
    setPage,
  } = props;

  useEffect(() => {
    settings && !settings?.source && getSetting();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);

  useEffect(() => {
    dispatch(globalActions.getPages("listing_page"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user && user?.name) {
      setBannerType("banner-auth");
    } else {
      setBannerType("banner");
    }
  }, [user]);

  const [urlSearchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (status && (status === "GET_COURSES_SUCCESS" || status === "GET_LEARNING_PATHWAYS_SUCCESS")) {
      ref?.current?.scrollIntoView({ behavior: "smooth", block: "nearest" });
      setListingPage({ status: "" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const searchParams: ListingPageSearchParams = {
    page: urlSearchParams.get("page") || defaultListingPageSearchParams.page,
    query: urlSearchParams.get("query") || defaultListingPageSearchParams.query,
    level: urlSearchParams.get("level") || defaultListingPageSearchParams.level,
    source: urlSearchParams.get("source") || defaultListingPageSearchParams.level,
    learning_activity: urlSearchParams.get("learning_activity") || defaultListingPageSearchParams.learning_activity,
    // sort_by:
    //   urlSearchParams.get("sort_by") || defaultListingPageSearchParams.sort_by,
    type: urlSearchParams.get("type") || defaultListingPageSearchParams.type,
    dir: urlSearchParams.get("dir") || defaultListingPageSearchParams.dir,
  };

  useEffect(() => {
    setSearchParams({
      ...searchParams,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const headerTitle = t("BOOST_YOUR_SKILLS");
  const headerDescription = t("SELECT_ONE_OF_THE_BELOW");

  const loaded = settings?.source;

  const loadingComponent = (
    <div className="loader-wrapper">
      <CircularLoader />
    </div>
  );

  const levels = {
    "0": t("ALL"),
    "1": t("ADVANCED"),
    "2": t("INTERMEDIATE"),
    "3": t("BEGINNER"),
  };

  const dir = {
    ASC: t("SHORTEST_TO_LONGEST"),
    DESC: t("LONGEST_TO_SHORTEST"),
  };
  const searchState: any = {
    level: {
      id: "level",
      title: t("LEVEL"),
      object: levels,
      list: buildDropDownList(levels),
      value: searchParams.level,
      valueItem: querySelector(buildDropDownList(levels), searchParams.level),
    },
    learning_activity: {
      id: "learning_activity",
      title: t("LEARNING_ACTIVITY"),
      object: learningActivitiesObject,
      list: buildDropDownList(learningActivitiesObject),
      value: searchParams.learning_activity,
      valueItem: querySelector(buildDropDownList(learningActivitiesObject), searchParams.learning_activity),
    },
    source: {
      id: "source",
      title: t("SOURCE"),
      object: buildSourcesList(settings?.source),
      list: buildDropDownList(buildSourcesList(settings?.source)),
      value: searchParams.source,
      valueItem: querySelector(buildDropDownList(buildSourcesList(settings?.source)), searchParams.source),
    },
    // sort_by: {
    //   id: "sort_by",
    //   title: t("SORT_BY"),
    //   object: sortByObject,
    //   list: buildDropDownList(sortByObject),
    //   value: searchParams.sort_by,
    //   valueItem: querySelector(
    //     buildDropDownList(sortByObject),
    //     searchParams.sort_by
    //   ),
    // },
    type: {
      id: "type",
      listWrapperClassName: "with-exams",
      title: t("TYPE"),
      // hide: searchParams.learning_activity === "learning_path",
      object: searchParams.learning_activity === "learning_path" ? typeLearningActivity : typeObject,
      list: buildDropDownList(searchParams.learning_activity === "learning_path" ? typeLearningActivity : typeObject),
      value: searchParams.type,
      valueItem: querySelector(
        buildDropDownList(searchParams.learning_activity === "learning_path" ? typeLearningActivity : typeObject),
        searchParams.type
      ),
    },
    dir: {
      id: "dir",
      title: t("DURATION"),
      object: dir,
      list: buildDropDownList(dir),
      value: searchParams.dir,
      valueItem: querySelector(buildDropDownList(dir), searchParams.dir),
    },
  };

  const fetchPageContent = (listingPage: number) => {
    setSearchParams({
      ...searchParams,
      page: listingPage.toString(),
    });

    if (page !== listingPage) setPage(listingPage);
    let requestBody: GetPageListingQuery = {
      page: listingPage || undefined,
      query: searchParams.query || "",
      levelId: (Number(searchParams.level) > 0 && Number(searchParams.level)) || undefined,
      sponsorshipType:
        (searchParams.type !== "all" && searchParams.learning_activity !== "learning_path" && searchParams.type) ||
        undefined,
      limit: searchParams.learning_activity === "courses" || searchParams.learning_activity === "exams" ? 12 : 5,
      // dir: (searchParams.sort_by !== "popular" && "ASC") || undefined,
      source: (searchParams.source !== "0" && searchParams.source) || undefined,
      // sort:
      //   (searchParams.sort_by !== "popular" && searchParams.sort_by) ||
      //   undefined,
      withExams:
        searchParams.learning_activity === "learning_path" && searchParams.type === "withExams"
          ? "true"
          : searchParams.learning_activity === "learning_path" && searchParams.type === "withoutExams"
          ? "false"
          : undefined,
    };

    if (urlSearchParams.get("dir") !== "undefined" && urlSearchParams.get("dir") !== null) {
      requestBody["dir"] = searchParams.dir;
      requestBody["sort"] = "duration";
    }

    searchParams.learning_activity === "courses" && getCourses(requestBody);
    searchParams.learning_activity === "learning_path" && getLearningPathways(requestBody);
    searchParams.learning_activity === "exams" && getExams(requestBody);
  };

  useEffect(() => {
    fetchPageContent(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchParams.query,
    searchParams.learning_activity,
    searchParams.level,
    // searchParams.sort_by,
    searchParams.source,
    searchParams.type,
    searchParams.dir,

    // eslint-disable-next-line react-hooks/exhaustive-deps
    localStorage.getItem("language"),
  ]);

  const totalPageNumber =
    searchParams.learning_activity === "learning_path"
      ? learningPathways?.meta?.totalPages
      : searchParams.learning_activity === "courses"
      ? courses?.meta?.totalPages
      : exams?.meta?.totalPages;

  const handleClickLearningPathway = (item: LearningPathwayType) => {
    setLearningPathway({ selectedLearningPath: item });
    checkIFEnrolledLP(item.id, user)
      ? navigate(`/dashboard/learning-pathway/${item.id}`)
      : navigate(`/learning-pathway/${item.id}`);
  };

  const handleClickCourse = (item: Course | Exam) => {
    setCourseDetails({ selectedCourse: item });
    checkIFEnrolledCourse(item.id, user)
      ? navigate(`/dashboard/course/${item.id}`)
      : navigate(`/course-details/${item.id}`);
  };

  const handleClickExam = (item: Course | Exam) => {
    setCourseDetails({ selectedCourse: item });
    checkIFEnrolledCourse(item.id, user)
      ? navigate(`/dashboard/exams/${item.id}`)
      : navigate(`/exam-details/${item.id}`);
  };
  const findPrevious = () => {
    let previousPath = "";
    pathsHistory?.some((path: string) => {
      if (path !== window.location.pathname) {
        previousPath = path;
        return true;
      }
    });
    return previousPath;
  };

  const resetFilters = () => {
    let requestBody: GetPageListingQuery = {
      page: 1,
      query: "",
      levelId: undefined,
      sponsorshipType: undefined,
      limit: 5,
      source: undefined,
      withExams: undefined,
    };

    const searchParams: ListingPageSearchParams = {
      page: defaultListingPageSearchParams.page,
      query: defaultListingPageSearchParams.query,
      level: defaultListingPageSearchParams.level,
      source: defaultListingPageSearchParams.level,
      learning_activity: defaultListingPageSearchParams.learning_activity,
      type: defaultListingPageSearchParams.type,
      dir: defaultListingPageSearchParams.dir,
    };
    setSearchParams({
      ...searchParams,
    });

    setPage(1);
    getLearningPathways(requestBody);
  };
  return (
    <ListingPageWrapper activity={searchParams?.learning_activity}>
      <ListingPageHeader
        title={headerTitle}
        description={headerDescription}
        onBackClick={() => navigate(findPrevious())}
        content="Boost your skills. Improve your career"
      />
      {loaded ? (
        <Container className="listing-container">
          <SearchSortFilterBar
            searchState={searchState}
            forwardRef={ref}
            placeHolder={t("SEARCH_COURSE_LEARNING_PATH_OR_SKILL")}
            setSearchParams={(params) => {
              setSearchParams(params);
              setPage(1);
            }}
            searchParams={searchParams}
            restoreBtn={() => {
              resetFilters();
            }}
          />
          {pages?.sections?.listing_page
            ?.find((val: any) => val.sectionContents[0].type === bannerType)
            ?.sectionContents.map(
              (banner: any, index: number) =>
                ((i18n.language.includes("en") && banner.title) ||
                  (!i18n.language.includes("en") && banner.titleAr)) && (
                  <Banner
                    key={index}
                    title={i18n.language.includes("en") ? banner.title : banner.titleAr}
                    description={i18n.language.includes("en") ? banner.content : banner.contentAr}
                    image={banner.image}
                    buttonText={i18n.language.includes("en") ? banner.buttonText : banner.buttonTextAr}
                    handleButtonClick={
                      bannerType.includes("auth") ? translationSelector(banner, "url") : loginWithRedirect
                    }
                  />
                )
            )}

          {loadingApi ? (
            loadingComponent
          ) : (
            <div className="list-wrapper">
              {searchParams.learning_activity === "courses" ? (
                courses?.items.length > 0 ? (
                  courses?.items?.map((course: Course, index: number) => (
                    <ActivityCard
                      key={index}
                      type="course"
                      course={course}
                      handleClick={() => handleClickCourse(course)}
                    />
                  ))
                ) : (
                  <div className="no-learning-type-wrapper">{t("NO_COURSES_FOUND")}</div>
                )
              ) : searchParams.learning_activity === "exams" ? (
                exams?.items.length > 0 ? (
                  exams?.items?.map((exam: Exam, index: number) => (
                    <ActivityCard key={index} type="exam" exam={exam} handleClick={() => handleClickExam(exam)} />
                  ))
                ) : (
                  <div className="no-learning-type-wrapper">{t("NO_EXAMS_FOUND")}</div>
                )
              ) : (
                <div className="learning-paths">
                  {learningPathways?.items.length > 0 ? (
                    learningPathways?.items?.map((learningPath: LearningPathwayType, index: number) => {
                      return (
                        <LearningPathwayCard
                          key={index}
                          id={learningPath?.id}
                          title={translationSelector(learningPath, "name")}
                          description={translationSelector(learningPath, "shortDescription")}
                          time={learningPath?.duration}
                          hasExam={learningPath.learningPathExams.length > 0}
                          subTitle={
                            learningPath?.learningPathExams?.length > 0
                              ? learningPath?.learningPathExams[0]?.exams?.source
                              : learningPath?.source
                          }
                          skills={learningPath?.learningPathSkills}
                          modul={
                            learningPath?.learningPathCourses?.length +
                            learningPath?.learningPathExams?.length +
                            learningPath?.learningPathLabs?.length +
                            ` ${t("LEARNING_ACTIVITIES")}`
                          }
                          image={learningPath?.image || getCDNProtectedURL(images.learningPathwayImg)}
                          level={translationSelector(learningPath?.level, "alias")}
                          handleClick={() => handleClickLearningPathway(learningPath)}
                          isSponsored={learningPath?.sponsored}
                        />
                      );
                    })
                  ) : (
                    <div className="no-learning-type-wrapper">{t("NO_LEARNING_PATH_FOUND")}</div>
                  )}
                </div>
              )}
            </div>
          )}
          {totalPageNumber !== 1 && (
            <PaginationComponent
              count={totalPageNumber}
              page={Number(searchParams.page)}
              onChangePage={(page) => fetchPageContent(page)}
            />
          )}
        </Container>
      ) : (
        loadingComponent
      )}
    </ListingPageWrapper>
  );
};

// @ts-ignore
const mapStateToProps = (state: RootState) => ({
  levels: state.landingPage.levels,
  settings: state.global.settings,
  courses: state.listingPage.courses,
  user: state.login.user,
  learningPathways: state.listingPage.learningPathways,
  status: state.listingPage.status,
  loadingApi: state.listingPage.loadingApi,
  pathsHistory: state.global.pathsHistory,
  exams: state.listingPage.exams,
  page: state.listingPage.page,
});

// @ts-ignore
const mapDispatchToProps = {
  getLevels: landingPageActions.getLevels,
  getSetting: globalActions.getSettings,
  getCourses: listingPageActions.getCourses,
  getExams: listingPageActions.getExams,
  getLearningPathways: listingPageActions.getLearningPathways,
  setLearningPathway: learningPathwayActions.setLearningPathway,
  setCourseDetails: courseDetailsActions.setCourseDetails,
  setListingPage: listingPageActions.setListingPage,
  setPage: listingPageActions.setPage,
};

// @ts-ignore
const connector = connect(mapStateToProps, mapDispatchToProps);
// @ts-ignore
const ListingPageComponentRedux = connector(ListingPageComponent);

// @ts-ignore
export { ListingPageComponentRedux as ListingPageComponent };
// @ts-ignore
