import React from "react";
import { LevelsWrapper, NewCareerLevelsWrapper } from "./newCareerLevels.styled";
import { Level, PageSection } from "&features/landingPage/landingPage.type";
import { useSelector } from "react-redux";
import { RootState } from "&store/store";
import NewCareerLevel from "&components/landing/newCareerLevel/newCareerLevel.component";
import DunnoWhichCareer from "&components/landing/dunnoWhichCareer/dunnoWhichCareer.component";
import CircularLoader from "&components/circularLoader/circularLoader.component";
import SubmitButton from "&components/buttons/submitButton/submitButton.component";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { translationSelector } from "&utils/translationSelector";

interface Props {
  title?: string;
  description?: string;
  content?: PageSection;
}

const NewCareerLevels = (props: Props) => {
  const { title, description, content } = props;
  const navigate = useNavigate();
  const { t } = useTranslation(["landingPage"]);

  const user = useSelector((state: RootState) => state.login.user);

  const loadingCareers = useSelector((state: RootState) => state.landingPage.loadingLevels);

  const careerLevels = useSelector((state: RootState) => state.landingPage.levels);
  const loadingComponent = (
    <div className="loader-wrapper">
      <CircularLoader />
    </div>
  );

  return (
    <NewCareerLevelsWrapper>
      <p className="section-title">{translationSelector(content, "title") || title}</p>
      <p
        className="section-description"
        dangerouslySetInnerHTML={{ __html: translationSelector(content, "content") || description }}
      ></p>
      <div className="levels-signup-prompt-wrapper">
        {loadingCareers ? (
          loadingComponent
        ) : (
          <>
            <LevelsWrapper>
              {careerLevels?.map((level: Level, index: number) => (
                <NewCareerLevel key={index} levelIndex={index} level={level} />
              ))}
            </LevelsWrapper>
            {!user?.name && <DunnoWhichCareer />}
          </>
        )}
      </div>
      <div className="view-all-careers-button-wrapper">
        <SubmitButton
          id={"view-all-careers-button"}
          title={t("VIEW_ALL_CAREERS")}
          handleClick={() => navigate("/future-careers")}
          className="view-all-careers-button"
          titleClassName="button-title"
        />
      </div>
    </NewCareerLevelsWrapper>
  );
};

export default NewCareerLevels;
