import * as phoneutil from "google-libphonenumber";

const regexPhoneNumber = (value: any) => /^(?:[+\d].*\d|\d)$/.test(value);
const libphoneInstance = phoneutil.PhoneNumberUtil.getInstance();
const PNF = phoneutil.PhoneNumberFormat;

const checkAndValidate = (value: any) => {
  try {
    if (value.length > 5 && regexPhoneNumber(value)) {
      const number = libphoneInstance.parseAndKeepRawInput(value, "AE");
      const isValid = libphoneInstance.isValidNumber(number);
      const isPossible = libphoneInstance.isPossibleNumber(number);

      if (isValid && isPossible) {
        return {
          status: "SUCCESS",
          countryCode: number.getCountryCode(),
          nationalNumber: number.getNationalNumber(),
          countrySource: number.getCountryCodeSource(),
          PhoneNumber: libphoneInstance.format(number, PNF.E164),
        };
      } else {
        return {
          status: "ERROR",
        };
      }
    } else {
      return {
        status: "ERROR",
      };
    }
  } catch (error) {
    return {
      status: "ERROR",
    };
  }
};

export default checkAndValidate;
