import styled from "styled-components";
import { colors } from "&assets/constants/colors";
import { device } from "&assets/constants/responsiveness";

export const SearchSortFilterBarWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 110px;
  margin-top: -55px;
  border: 2px solid transparent;
  background: ${colors.linearGradientWhite} padding-box, ${colors.linearGradientOne} border-box;
  box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  margin-bottom: 64px;
  position: relative;

  .restore-btn {
    position: absolute;
    top: 0px;
    ${(props) => (props.theme.dir === "rtl" ? "left: 10px;" : "right: 10px;")}
    cursor: pointer;
    color: ${colors.primary};
  }
  .input-dropdowns-wrapper {
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 30px;
    position: relative;
  }

  .collapse-container {
    box-sizing: border-box;
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color: transparent;
    background: ${colors.linearGradientWhite} padding-box, ${colors.linearGradientOne} border-box;
    position: absolute;
    margin-top: -16px;
    ${(props) => (props.theme.dir === "rtl" ? "right: 0;" : "left: 0;")}
    padding: 0 16px 32px 16px;
    z-index: 10000;
    width: 100vw;
    .dropdown-wrapper {
      min-width: 100%;
      margin-top: 16px;
    }
  }

  .select-wrapper {
    ${(props) => (props.theme.dir === "rtl" ? "margin-right: 15px;" : "margin-left: 15px;")}
  }

  @media ${device.tablet} {
    .input-dropdowns-wrapper {
      padding: 20px 16px;
    }

    border-width: 1px 0 1px 0;
    border-style: solid;
    border-color: transparent;
    height: 90px;
    border: 2px solid transparent;
    margin-bottom: 54px;

    .select-wrapper {
      display: none;
    }

    .auto-complete-mobile-wrapper {
      margin-top: 10px;
    }
    .collapse-container {
      width: 100%;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
    }
  }

  @media ${device.mobile} {
    .input-dropdowns-wrapper {
      padding: 20px 16px;
    }

    .auto-complete-mobile-wrapper {
      margin-top: 10px;
    }

    border-width: 1px 0 1px 0;
    border-style: solid;
    border-color: transparent;
    height: 90px;
    border: 2px solid transparent;
    margin-bottom: 48px;

    .select-wrapper {
      display: none;
    }
    .collapse-container {
      width: 100%;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
    }
  }
`;

export const FilterButton = styled.button`
  border: 1px solid transparent;
  background: ${colors.linearGradientWhite} padding-box, ${colors.linearGradientOne} border-box;
  box-sizing: border-box;
  width: 52px;
  min-width: 52px;
  height: 50px;
  min-height: 50px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  padding: 0;
  ${(props) => (props.theme.dir === "rtl" ? "margin-right: 15px;" : "margin-left: 15px;")}
  display: none;

  @media ${device.tablet} {
    display: flex;
    ${(props) => (props.theme.dir === "rtl" ? "margin-right: 15px;" : "margin-left: 15px;")}
  }

  @media ${device.mobile} {
    display: flex;
    ${(props) => (props.theme.dir === "rtl" ? "margin-right: 10px;" : "margin-left: 10px;")}
  }
`;
