import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { FutureSkillsLanding } from "./futureSkillsLanding.type";

const initialState: FutureSkillsLanding = {};

const makeFutureSkillsLandingApiCall = createAsyncThunk(

  "futureSkillsLanding/makeFutureSkillsLandingApiCallStatus",
  async (body: any) => {
    // Make your API call here
  }
);

const futureSkillsLandingSlice = createSlice({
  name: "futureSkillsLanding",
  initialState: initialState,
  reducers: {
    setFutureSkillsLanding: (state, action) => {
      return { ...state, ...action.payload };
    },
    reset: () => initialState,
  },

  extraReducers: (builder) => {
    builder.addCase(makeFutureSkillsLandingApiCall.pending, (state, action) => {
      // Write pending logic here
    });
    builder.addCase(makeFutureSkillsLandingApiCall.fulfilled, (state, action) => {
      // Write success logic here
    });
    builder.addCase(makeFutureSkillsLandingApiCall.rejected, (state, action) => {
      // Write failure logic here
    });
  },
});

export const futureSkillsLandingReducer = futureSkillsLandingSlice.reducer;

export const futureSkillsLandingActions = { ...futureSkillsLandingSlice.actions, makeFutureSkillsLandingApiCall };
