import React from "react";

import { LearningPathCardWrapper } from "./learningPathCard.styled";
import { Clock, Document } from "&assets/constants/icons";
import secondsToHours from "&utils/secondstoHours";
import LevelBanner from "&components/levelBanner/levelBanner.component";
import SourceLogo from "&components/sourceLogo/sourceLogo.component";
import { useTranslation } from "react-i18next";
import moment from "moment";

interface Props {
  title?: string;
  subTitle?: string;
  modul?: string;
  time?: number;
  image?: any;
  level?: string;
  handleClick?: Function;
  className?: string;
  startDate?: string;
  enrollmentStartDate?: string;
  enrollmentEndDate?: string;
  hasExam?: boolean;
  hideTier?: boolean
}

const LearningPathCard = (props: Props) => {
  const {
    title,
    subTitle,
    modul,
    time,
    image,
    level = "beginner",
    handleClick,
    className = "",
    startDate,
    enrollmentStartDate,
    enrollmentEndDate,
    hasExam,
    hideTier
  } = props;

  const { t } = useTranslation(["learningPathway"]);

  return (
    <LearningPathCardWrapper
      onClick={() => handleClick && handleClick()}
      className={className}
    >
      <LevelBanner hideTier={hideTier} image={image} level={level} />
      {hasExam && <div className="certification">{t("CERTIFICATION")}</div>}
      <p className="card-title">{title}</p>
      <div className="card-content">
        <SourceLogo source={subTitle?.toLowerCase()} />
        <p className="title">{subTitle}</p>
      </div>
      <div className="card-content">
        <p className="title info">
          <Document /> <span className="text">{modul}</span>
        </p>
        <p className="title info">
          <Clock />{" "}
          <span className="text">
            {startDate
              ? `Start: ${moment(startDate).format("DD-MM-YYYY")}`
              : secondsToHours(time)}
          </span>
        </p>
      </div>
      <p className="enrollment-dates">
        {enrollmentEndDate &&
          enrollmentStartDate &&
          `${t("ENROLLMENT_FROM")} ${moment(enrollmentStartDate).format(
            "DD-MM-YYYY"
          )} ${t("TO")} ${moment(enrollmentEndDate).format("DD-MM-YYYY")}`}
      </p>
    </LearningPathCardWrapper>
  );
};

export default LearningPathCard;
