import { colors } from "&assets/constants/colors";
import { device } from "&assets/constants/responsiveness";
import styled from "styled-components";

interface Props {
  selected?: boolean;
}

export const DashboardComponentWrapper = styled.div`
  .carousel-wrapper {
    direction: initial;
  }
  .dashboard-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 140px 25px 140px;
    box-sizing: border-box;
  }
  .flex-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .carousel.carousel-slider{
    overflow: inherit;
    .control-dots {
    position: absolute;
    bottom: 0;
    margin: -15px 0;
    padding: 0;
    width: 100%;
    display: flex;
    justify-content: center;
}
  }
  @media ${device.tablet} {
    .dashboard-container {
      padding: 24px 30px;
    }
    .flex-row {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  }
  @media ${device.mobile} {
    .dashboard-container {
      padding: 24px 16px;
    }
    .flex-row {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  }
`;

export const CarouselIndicator = styled.div<Props>`
  width: 6px;
  height: 6px;
  background: ${colors.primary};
  border-radius: 50%;
  margin: -37px 5px 0 5px;
  opacity: ${(props) => (props.selected ? 1 : 0.3)};

  @media ${device.mobile}, ${device.tablet} {
    margin: -20px 5px 0 5px;
  }
`;
