export const size = {
  xsmobile: "320px",
  mobile: "768px",
  tablet: "1000px",
  desktop: "1400px",
};

export const device = {
  desktop: `(min-width: ${size.desktop})`,
  xsmobile: `(max-width: ${size.xsmobile})`,
  mobile: `(max-width: ${size.mobile})`,
  tablet: `(min-width: ${size.mobile}) and (max-width: ${size.tablet})`,
  inBetween: `(min-width: ${size.tablet}) and (max-width: ${size.desktop})`,
};
