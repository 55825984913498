import React from "react";
import moment from "moment";
import { ProfileWorkExperienceWrapper } from "./profileWorkExperience.styled";
import { WorkExperience } from "&features/dashboard/screens/profile/profile.type";
import EditRoundButton from "&components/buttons/editRoundButton/editRoundButton.component";
import dateDifferenceFormat from "&utils/dateDifferenceFormat";
import UserLocation from "&components/profile/userLocation/userLocation.component";
import { translationSelector } from "&utils/translationSelector";
import 'moment/locale/ar'; 
import { useTranslation } from "react-i18next";

interface Props {
  editValue?: () => void;
}

const ProfileWorkExperience = (props: WorkExperience & Props) => {
  const {
    current,
    endDate,
    startDate,
    description,
    employmentType,
    title,
    location,
    companyName,
    editValue,
  } = props;
  const { t } = useTranslation(["profile"]);
  const startDateFormatted = startDate?.format("MMM YYYY");
  const endDateFormatted = current ? t("PRESENT"): endDate?.format("MMM YYYY");

  return (
    <ProfileWorkExperienceWrapper>
      <div className="job-title-edit-button">
        <div className="job-title-text">{title}</div>
        {editValue && <EditRoundButton onClick={editValue} />}
      </div>
      <div className="company-dates-wrapper">
        <div className="company-name-text">{companyName}</div>
        {employmentType?.name ? (
          <>
            <div className="dot" />
            <div className="full-time-text">{translationSelector(employmentType, 'name')}</div>
          </>
        ) : null}
        <div className="dot" />
        <div className="date-period-text">{`${
          startDateFormatted ? startDateFormatted + " - " : ""
        }${endDateFormatted} ${
          startDate
            ? "(" +
              dateDifferenceFormat(startDate, endDate ? endDate : moment()) +
              ")"
            : ""
        }`}</div>
      </div>
      {location && (
        <UserLocation location={location} className="location-wrapper-margin" />
      )}
      <div className="description-text">{description}</div>
    </ProfileWorkExperienceWrapper>
  );
};

export default ProfileWorkExperience;
