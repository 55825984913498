import { API } from "aws-amplify";
import { RES_JOBS } from "&store/resources";

const getJobByIdApi = async (id: string) => {
  let URL = `${RES_JOBS}/${id}`;
  return API.get(process.env.REACT_APP_NAME || "", URL, {});
};

const getAuthJobByIdApi = async (id: string) => {
  let URL = `/auth${RES_JOBS}/${id}`;
  return API.get(process.env.REACT_APP_NAME || "", URL, {});
};

const getRelatedJobByIdApi = async (id: string) => {
  let URL = `${RES_JOBS}/${id}/related`;
  return API.get(process.env.REACT_APP_NAME || "", URL, {});
};

export { getJobByIdApi, getRelatedJobByIdApi, getAuthJobByIdApi };
