import React from "react";
import { ListItemsPartiallyWrapper } from "./listItems.styled";
import SubmitButton from "&components/buttons/submitButton/submitButton.component";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";

interface Props {
  data?: any[];
  renderItem: (item: any, index: number) => any;
  className?: string;
  isBlur?: boolean;
}

const ListItemsPartially = (props: Props) => {
  const { data = [], renderItem, className, isBlur = false } = props;
  const { loginWithRedirect } = useAuth0();

  const firstPart = data?.slice(0, 1);

  const secondPart = data?.slice(1, data.length);

  const { t } = useTranslation(["listingPage"]);

  const handleLoadMore = () => {
    loginWithRedirect();
  };

  return (
    <ListItemsPartiallyWrapper className={className} isBlur={isBlur}>
      {firstPart.map((item) => renderItem(item, item.index))}
      <div className="second-part-wrapper">
        {isBlur && <div className="blur" />}
        {secondPart.map((item) => renderItem(item, item.index))}
        {isBlur && (
          <div className={`load-more-background-wrapper`}>
            <SubmitButton
              titleClassName="load-more-title"
              className={"button-wrapper"}
              id={"load-more-button"}
              title={t("SIGNUP")}
              handleClick={handleLoadMore}
            />
          </div>
        )}
      </div>
    </ListItemsPartiallyWrapper>
  );
};

export default ListItemsPartially;
