import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useTranslation } from "react-i18next";
import { RootState } from "&store/store";
import Container from "&components/container/container.component";
import { LoginWrapper } from "&features/login/login.styled";
import Slide from "@mui/material/Slide";
import { useLocation } from "react-router-dom";
import { UnsubscribeWrapper } from "./unsubscribe.styled";

type ReduxProps = ConnectedProps<typeof connector>;

const UnsubscribeComponent = (props: ReduxProps) => {
  const { t, i18n } = useTranslation(["login"]);
  const [unsubscribtionErr, setUnsubscribtionErr] = useState("");
  const location = useLocation();
  const dir = i18n.dir();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const errorParam = params.get("error");

    if (errorParam) {
      setUnsubscribtionErr(errorParam);
    }
  }, [location.search]);

  return (
    <LoginWrapper minHeight={"400px"}>
      <Container>
        <div className="content">
          <Slide
            direction={dir === "rtl" ? "right" : "left"}
            in={true}
            mountOnEnter
            unmountOnExit
          >
            <div className="auth-container">
              <UnsubscribeWrapper>
                <p className="verify-title">
                  {t(
                    unsubscribtionErr
                      ? "UNSUBSCRIBED_ERROR_MESSAGE"
                      : "UNSUBSCRIBED_MESSAGE"
                  )}
                </p>
              </UnsubscribeWrapper>
            </div>
          </Slide>
        </div>
      </Container>
    </LoginWrapper>
  );
};

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);
const UnsubscribeComponentRedux = connector(UnsubscribeComponent);

export { UnsubscribeComponentRedux as UnsubscribeComponent };
