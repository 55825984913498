import React from "react";
import { useSelector } from "react-redux";
import { SocialLinks } from "&features/dashboard/screens/profile/profile.type";
import SmallProfileItem, {
  SideDetailsProps,
} from "&components/profile/smallProfileItem/smallProfileItem.component";
import {
  ExternalLinkIcon,
  LinkedInProfileIcon,
  WorldGlobeIcon,
} from "&assets/constants/icons";
import { ProfileAdditionalDetailsWrapper } from "&components/profile/profileAdditionalDetails/profileAdditionalDetails.styled";
import { RootState } from "&store/store";
import {useTranslation} from "react-i18next";

interface Props {
  viewMode?: boolean;
}

const ProfileSocialLinks = (props: SocialLinks & Props) => {
  const { website, portfolio, linkedin, viewMode } = props;
  const { t } = useTranslation(["profile"]);

  const user = useSelector((state: RootState) => state.login.user);

  const socialLinksContent: SideDetailsProps[] = [
    {
      title: t('LINKEDIN'),
      content: linkedin
        ? viewMode
          ? linkedin
          : (user?.name &&
              user?.lastName &&
              `${user?.name} ${user?.lastName}`) ||
            "Linkedin URL"
        : undefined,
      link: linkedin,
      icon: <LinkedInProfileIcon />,
      placeholder: t('ADD_YOUR_LINKEDIN_PROFILE'),
      hide: !!(viewMode && !linkedin),
    },
    {
      title: t('PORTFOLIO'),
      content: portfolio ? (viewMode ? portfolio : "URL") : undefined,
      link: portfolio,
      icon: <ExternalLinkIcon />,
      placeholder: t('ADD_YOUR_PORTFOLIO'),
      hide: !!(viewMode && !portfolio),
    },
    {
      title: t('WEBSITE'),
      content: website,
      link: website,
      icon: <WorldGlobeIcon />,
      placeholder: t('ADD_YOUR_WEBSITE'),
      hide: !!(viewMode && !website),
    },
  ];

  return (
    <ProfileAdditionalDetailsWrapper>
      {socialLinksContent?.map(
        (detail, index) =>
          !detail.hide && (
            <SmallProfileItem
              key={index}
              title={detail.title}
              content={detail.content}
              link={detail.link}
              icon={detail.icon}
              placeholder={detail.placeholder}
              className="profile-detail-wrapper"
            />
          )
      )}
    </ProfileAdditionalDetailsWrapper>
  );
};
export default ProfileSocialLinks;
