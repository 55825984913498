import { RootState } from "&store/store";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Navigate, useLocation, Outlet } from "react-router-dom";
import checkSkill from "&utils/checkSkill";
import { useAuth0 } from "@auth0/auth0-react";

type ReduxProps = ConnectedProps<typeof connector>;

type ProtectedRouteProps = {
  roles?: string[];
  checkOnBoarding?: boolean;
};

/**
 * Conditional route used for all routes that require a specific validator.
 * Ex: If user is authenticated, the component passed to the route is rendered,
 * otherwise user is redirected to fallback route.
 * @param param0 component to render and rest props
 */
const ProtectedRoute = ({
  isAuthenticated,
  user,
  changeCareerMode,
  roles = [],
  checkOnBoarding,
}: ProtectedRouteProps & ReduxProps) => {
  let location = useLocation();
  const { loginWithRedirect } = useAuth0();

  const isOnboarded =
    Number(user?.careerId) > 0 &&
    user?.dateOfBirth &&
    user.careerId &&
    user.gender &&
    user.phoneNumber &&
    user.name &&
    user.lastName &&
    checkSkill(user?.userSkills) &&
    !changeCareerMode;

  if (!isAuthenticated) {
    return <>{loginWithRedirect()}</>;
  }

  if (checkOnBoarding && !isOnboarded) {
    return <Navigate to="/build-profile" state={{ from: location }} />;
  }

  return <Outlet />;
};

const mapStateToProps = (state: RootState) => ({
  // TODO change this to your real auth validator
  isAuthenticated: state.login.isLoggedIn,
  user: state.login.user,
  changeCareerMode: state.login.changeCareerMode,
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);
const AppRedux = connector(ProtectedRoute);

export { AppRedux as ProtectedRoute };
