import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";

import RightArrowImg from "&assets/images/rightArrow.svg";
import LeftArrowImg from "&assets/images/leftArrow.svg";
import { LearningPathStepperWrapper } from "./learningPathStepper.styled";
import { ArrowForwardIcon, Clock, Document } from "&assets/constants/icons";
import SourceLogo from "&components/sourceLogo/sourceLogo.component";
import SkillsList from "&components/skillsList/skillsList.component";
import Stepper from "&components/stepper/stepper.component";
import TextLine from "&components/textLine/textLine.component";
import { useTranslation } from "react-i18next";
import { translationSelector } from "&utils/translationSelector";
import { colors } from "&assets/constants/colors";

interface Props {
  data?: any;
  name?: string;
}

const actionButton = (text: string, handleClick: Function) => {
  return (
    <div className="view-path" onClick={() => handleClick()}>
      <p className="view-path-text">{text}</p>
      {ArrowForwardIcon(colors.primary, 20)}
    </div>
  );
};

const LearningPathStepper = (props: Props) => {
  const { data } = props;

  const { t } = useTranslation(["dashboard"]);

  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [skills, setSkills] = useState([]);
  const [currentLearningPath, setCurrentLearningPath] = useState(data[0]?.learningPaths || data[0].courses);
  const [currentLearnings, setCurrentLearnings] = useState(data);
  const [currentCourse, setCurrentCourse] = useState<any>({});

  useEffect(() => {
    setCurrentLearnings(data);
    setCurrentLearningPath(data[activeStep]?.learningPaths || data[activeStep]?.courses);

    const current =
      data[activeStep]?.learningPaths?.learningActivities?.length > 0
        ? data[activeStep]?.learningPaths?.learningActivities[0]
        : [];
    setCurrentCourse(data[activeStep]?.learningPaths?.learningActivities?.length > 0 ? current : {});
  }, [data, activeStep]);

  useEffect(() => {
    setSkills(
      isEmpty(currentLearningPath?.courseSkills)
        ? currentCourse?.courses?.courseSkills || currentCourse?.exams?.examSkills || currentCourse?.labs?.labSkills
        : currentLearningPath?.courseSkills
    );
  }, [currentCourse, currentLearningPath]);

  const item = currentCourse?.courses || currentCourse?.exams || currentCourse?.labs;
  const type = currentCourse?.labs ? "lab" : currentCourse.exams ? "exam" : "course";

  const isLearningPath = data[activeStep].learningPaths;

  const duration = currentLearningPath?.duration
    ? currentLearningPath.duration >= 2629800
      ? `${(currentLearningPath.duration / 2629800).toFixed(1)} ${t("MONTHS")}`
      : currentLearningPath.duration >= 86400
      ? `${(currentLearningPath.duration / 86400).toFixed(1)} ${t("DAYS")}`
      : `${(currentLearningPath.duration / 3600).toFixed(1)} ${t("HOURS")}`
    : "";

  return (
    <LearningPathStepperWrapper activeStep={activeStep}>
      <TextLine title={t("MY_CURRENT_LEARNINGS")} />
      <div className="current-learning-pathway">
        <>
          <div className="stepper-header">
            {currentLearnings.length > 1 ? (
              <img
                src={LeftArrowImg}
                alt=""
                className={"arrows"}
                onClick={() => {
                  setSkills([]);
                  activeStep > 0 ? setActiveStep(activeStep - 1) : setActiveStep(currentLearnings.length - 1);
                }}
              />
            ) : (
              <div />
            )}
            <p className="pathway-name">{translationSelector(currentLearningPath, "name")}</p>
            {currentLearnings.length > 1 ? (
              <img
                src={RightArrowImg}
                alt=""
                className={"arrows"}
                onClick={() => {
                  setSkills([]);
                  activeStep < currentLearnings.length - 1 ? setActiveStep(activeStep + 1) : setActiveStep(0);
                }}
              />
            ) : (
              <div />
            )}
          </div>

          <hr className="separator" />
        </>

        <div className="card-content">
          <div className="provider-content">
            <SourceLogo source={currentLearningPath?.source?.toLowerCase()} />
            <p className="provider-title">{translationSelector(currentLearningPath, "source")}</p>
          </div>
          <div className="flex-row">
            {isLearningPath && (
              <p className="title info">
                <Document />
                <span className="text">
                  {currentLearnings[activeStep]?.learningPaths?.learningActivities?.length + ` ${t("COURSES")}`}
                </span>
              </p>
            )}
            <p className="title info">
              <Clock /> <span className="text">{duration}</span>
            </p>
          </div>
          {isLearningPath &&
            actionButton(t("GO_TO_LEARNING_PATHWAY"), () =>
              navigate(`/dashboard/learning-pathway/${currentLearningPath.id}`)
            )}
        </div>

        <div className="steps">
          {isLearningPath && (
            <Stepper
              steps={currentLearnings[activeStep]?.learningPaths?.learningActivities}
              handleStepClick={(index: number) => {
                setSkills([]);
                setCurrentCourse(currentLearnings[activeStep]?.learningPaths?.learningActivities[index - 1]);
              }}
            />
          )}
        </div>
        <div className="course-information">
          <div className="selected-course">
            <span className="title">
              {translationSelector(item, "name") || translationSelector(currentLearningPath, "name")}
            </span>
            <span className="description">
              {translationSelector(item, "shortDescription") ||
                translationSelector(currentLearningPath, "shortDescription")}
            </span>
            {actionButton(`${t("GO_TO")} ${t(type)}`, () => {
              let url = `/dashboard/${type}/${item?.id || currentLearningPath?.id}${
                type !== "course" ? `/learning-path/${currentLearningPath?.id}` : ""
              }`;

              if (!isEmpty(currentCourse) && !isEmpty(currentLearningPath) && type !== "exam") {
                url = url + `/learning-path/${currentLearningPath?.id}`;
              }

              navigate(url);
            })}
          </div>
          <div className="course-skills">
            <span className="title">{t("COURSE_SKILLS")}</span>
            {skills?.length > 0 && <SkillsList skills={skills || []} />}
          </div>
        </div>
      </div>
    </LearningPathStepperWrapper>
  );
};

export default LearningPathStepper;
