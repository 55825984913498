import styled from "styled-components";
import { device } from "&assets/constants/responsiveness";
import { colors } from "&assets/constants/colors";

interface Props {
  isWhite?: boolean;
}

export const SectionTitleButtonWrapper = styled.div<Props>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .section-title {
    font-weight: 700;
    font-size: 48px;
    line-height: 110%;
    letter-spacing: -0.02em;
    font-feature-settings: "cv11" on;
    color: ${(props) => (props.isWhite ? colors.white : colors.primary)};
    z-index: 1;
  }
  .view-all {
    padding: 0;
    border: 0;
    background: transparent;
    font-weight: 800;
    font-size: 18px;
    line-height: 160%;
    text-align: center;
    color: ${(props) => (props.isWhite ? colors.white : colors.primary)};
    z-index: 1;
    align-items: center;
    display: flex;
    cursor: pointer;
    svg {
      ${(props) => (props.theme.dir === "rtl" ? "margin-right: 10px;" : "margin-left: 10px;")}
      ${(props) => (props.theme.dir === "rtl" ? "transform: rotate(180deg);" : "")}
    }
  }

  @media ${device.tablet} {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .section-title {
      font-size: 28px;
      margin-bottom: 24px;
    }
    .view-all {
      font-size: 18px;
    }
  }
  @media ${device.mobile} {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .section-title {
      font-size: 28px;
      margin-bottom: 24px;
    }
    .view-all {
      font-size: 18px;
    }
  }
`;
