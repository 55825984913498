import React from "react";
import { LevelBannerWrapper } from "./levelBanner.styled";
import ImageDisplay from "&components/imageDisplay/imageDisplay.component";

interface Props {
  image?: any;
  hideTier?: boolean;
  level?: string;
}

const LevelBanner = (props: Props) => {
  const { image, level, hideTier = undefined } = props;
  return (
    <LevelBannerWrapper>
      <ImageDisplay url={image} isFromS3={true} alt="" className="card-img" />
      {level && !hideTier && (
        <div className="level-start-date-wrapper">
          {level && <div className="tier">{level}</div>}
        </div>
      )}
    </LevelBannerWrapper>
  );
};

export default LevelBanner;
