import React, { FC, ReactNode } from "react";

import { SkillListModalWrapper } from "./skillsListModal.styled";
import CustomModal from "../custom-modal/custom-modal.component";
import SkillChip from "&components/skillChip/skillChip.component";
import {
  CourseSkill,
  ExamSkills,
} from "&features/listingPage/listingPage.type";
import Chip from "&components/chip/chip.component";

interface Props {
  handleClose: any;
  open: boolean;
  title?: string | ReactNode;
  list: CourseSkill[] | ExamSkills[];
  isChip?: boolean;
}

const SkillListModal: FC<Props> = ({
  handleClose,
  open,
  title,
  list,
  isChip,
}) => {
  return (
    <CustomModal handleClose={handleClose} open={open} title={title}>
      <SkillListModalWrapper>
        {list?.length > 0
          ? (list as (CourseSkill | ExamSkills)[])?.map(
              (option, index: number) =>
                isChip ? (
                  <Chip
                    title={option?.skills?.name || option.toString()}
                    key={index}
                  />
                ) : (
                  <SkillChip
                    sliceTitle={false}
                    title={option?.skills?.name || option.toString()}
                    level={option?.weight}
                    key={index}
                  />
                )
            )
          : null}
      </SkillListModalWrapper>
    </CustomModal>
  );
};

export default SkillListModal;
