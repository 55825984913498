import React, { useEffect } from "react";
import { isEmpty } from "lodash";

import { ProfileNationalityFormWrapper } from "./profileNationalityForm.styled";
import { NationalityFormValues } from "&features/login/login.type";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "&store/store";
import { profileActions } from "&features/dashboard/screens/profile/profile.slice";
import NationalityFormComponent from "&components/profile/buildProfileStepThree/buildProfileStepThreeNationality.component";
// import {useTranslation} from "react-i18next";

interface Props {
  initialValues?: NationalityFormValues;
  submit?: number;
}

const ProfileNationalityForm = (props: Props) => {
  const { submit, initialValues } = props;
  // const { t } = useTranslation(["profile"]);

  const dispatch = useDispatch();

  const countries = useSelector((state: RootState) => state.profile.countries);

  const loadingCountries = useSelector((state: RootState) => state.profile.loadingCountries);

  const getCountries = () => dispatch(profileActions.getCountries());

  useEffect(() => {
    !countries && getCountries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ProfileNationalityFormWrapper>
      <NationalityFormComponent
        initialValues={initialValues}
        countries={countries}
        submit={submit}
        className="remove-padding"
        loading={loadingCountries}
      />
    </ProfileNationalityFormWrapper>
  );
};

export default ProfileNationalityForm;
