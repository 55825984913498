import React from "react";
import { FileUploader } from "react-drag-drop-files";
import { isUndefined } from "lodash";

import { DragDropUploadFileWrapper } from "./dragDropUploadFile.styled";
import { LabelWrapper } from "&components/inputs/input/input.styled";
import {
  CloseRectangle,
  PhotoIcon,
  UploadedIcon,
  ErrorUpload,
} from "&assets/constants/icons";
import SubmitButton from "&components/buttons/submitButton/submitButton.component";
import ImageDisplay from "&components/imageDisplay/imageDisplay.component";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";

interface Props {
  title?: string;
  error?: any;
  description?: string;
  label?: string;
  handleChange?: (file: File) => void;
  initialValue?: string;
  className?: string;
  dragDropClassName?: string;
  value?: any;
  removePhoto?: (key?: string) => void;
  showImage?: boolean;
  multiple?: boolean;
  disabled?: boolean;
}

const DragDropUploadFile = (props: Props) => {
  const {
    label,
    error,
    handleChange,
    className = "",
    removePhoto,
    dragDropClassName = "",
    value,
    showImage,
    initialValue,
    description = "",
    multiple = false,
    disabled = false,
  } = props;

  const fileTypes = showImage
    ? ["JPEG", "JPG", "PNG", "GIF"]
    : ["JPEG", "JPG", "PNG", "GIF", "PDF"];
  const { t } = useTranslation(["profile"]);

  const dir = i18n.dir();
  const getImageUrl = (file?: any) => {
    return file && URL.createObjectURL(file);
  };

  return (
    <DragDropUploadFileWrapper
      showAll={(value || initialValue) && showImage}
      className={className}
    >
      <LabelWrapper title={error} valid={error}>
        {label}
      </LabelWrapper>
      <FileUploader
        multiple={multiple}
        handleChange={handleChange}
        name="file"
        types={fileTypes}
        disabled={!isUndefined(value) || disabled}
      >
        <div className={`drag-drop-area ${dragDropClassName}`}>
          {!isUndefined(value) ? (
            showImage ? (
              <ImageDisplay
                isBackgroundImage={true}
                className="image-wrapper"
                url={getImageUrl(value)}
              />
            ) : (
              <UploadedIcon />
            )
          ) : initialValue ? (
            <ImageDisplay
              isBackgroundImage={true}
              isFromS3={true}
              className="image-wrapper"
              url={initialValue}
            />
          ) : error ? (
            <div className="icon-wrapper">
              <ErrorUpload />
            </div>
          ) : (
            <div className="icon-wrapper">
              <PhotoIcon />
            </div>
          )}

          {error && isUndefined(value) ? (
            <div className="text">{error}</div>
          ) : (
            <>
            <Typography fontWeight={300} fontSize={12} color="green">{fileTypes?.join(', ')}</Typography>
              <div className="text">
                {!isUndefined(value)
                  ? dir === "rtl"
                    ? t("YOUR_DOCUMENT_HAS_BEEN_UPLOADED_SUCCESSFULLY")
                    : `Your ${
                        showImage ? "image" : "document"
                      } has been uploaded successfully`
                  : description}
              </div>

              {isUndefined(value) && <div className="text or-text">or</div>}
              <div className="line-div" />
            </>
          )}
          <div className="documents">
            {multiple && value
              ? Object.keys(value).map((item: any, index: number) => {
                  return (
                    <div className="document-name" key={index}>
                      {value[item]?.name}
                      <div
                        onClick={() => removePhoto && removePhoto(item)}
                        className="remove-photo-button"
                      >
                        <CloseRectangle />
                      </div>
                    </div>
                  );
                })
              : value && (
                  <div className="document-name">
                    {value.name}
                    <div
                      onClick={() => removePhoto && removePhoto()}
                      className="remove-photo-button"
                    >
                      <CloseRectangle />
                    </div>
                  </div>
                )}
          </div>
          {isUndefined(value) && (
            <SubmitButton
              title={
                dir === "rtl"
                  ? t("UPLOAD_A_DOCUMENT")
                  : `Upload ${showImage ? "an Image" : "a document"}`
              }
              id="more-details-id"
              handleClick={() => {}}
              className="upload-button"
              titleClassName="title-button"
            />
          )}
        </div>
      </FileUploader>
    </DragDropUploadFileWrapper>
  );
};

export default DragDropUploadFile;
