import styled from "styled-components";
import { device } from "&assets/constants/responsiveness";
import { colors } from "&assets/constants/colors";

interface MinWidgetHeight {
  minHeight?: string;
}
export const LoginWrapper = styled.div<MinWidgetHeight>`
  background: ${colors.linearGradientOne};
  padding: 43px 64px;
  .login-logo {
    cursor: pointer;
    width: 170px;
    height: 45px;
  }

  .loader-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    .auth-container {
      width: 757px;
      border: 1px solid transparent;
      background: ${colors.linearGradientWhite} padding-box, ${colors.linearGradientOne} border-box;
      border-radius: 40px;
      transition: all 0.2 ease-in;
      min-height: ${(props) => (props.minHeight !== undefined ? props.minHeight : "582px")};
    }
    .actions-section {
      ${(props) => (props.theme.dir === "rtl" ? "margin-left: -100px;" : "margin-right: -100px;")}
      z-index: 5;
      .back-button {
        background: transparent;
        padding: 0;
        border: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 30px;
        margin-bottom: 41px;
        cursor: pointer;
        .arrow {
          ${(props) => (props.theme.dir === "rtl" ? "" : "transform: rotate(180deg);")}
          ${(props) => (props.theme.dir === "rtl" ? "margin-left: 14px;" : "margin-right: 14px;")}
        }

        .button-text {
          font-weight: 800;
          font-size: 18px;
          line-height: 160%;
          text-align: center;
          color: ${colors.white};
        }
      }
    }
    .MuiTabs-scroller.MuiTabs-fixed {
      ${(props) => (props.theme.dir === "rtl" ? "padding: 25px 196px 0 88px;" : "padding: 25px 88px 0 196px;")}
    }
  }

  @media ${device.inBetween} {
    padding: 35px 50px;
    .content {
      .auth-container {
        width: 100%;
      }
    }
  }

  @media ${device.mobile}, ${device.tablet} {
    padding: 24px 16px;
    .content {
      flex-direction: column-reverse;
      .auth-container {
        width: 100%;
        height: auto;
      }
      .actions-section {
        margin-bottom: 32px;
        ${(props) => (props.theme.dir === "rtl" ? "margin-left: 0;" : "margin-right: 0;")}
        .back-button {
          display: none;
        }
      }
      .MuiTabs-scroller.MuiTabs-fixed {
        padding: 0;
      }
    }
  }
`;
