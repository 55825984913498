import styled from "styled-components";
import { device } from "&assets/constants/responsiveness";
import { colors } from "&assets/constants/colors";

export const BuildProfileWrapper = styled.div`
  position: relative;

  .blurred-circle {
    position: absolute;
    width: 575px;
    height: 575px;
    ${(props) => (props.theme.dir === "rtl" ? "right: 995px;" : "left: 995px;")}
    z-index: 0;
    top: 112px;
    background: ${colors.linearGradientOne};
    filter: blur(65px);
  }

  .padding-container {
    position: relative;
    box-sizing: border-box;
    padding: 0 102px;
    z-index: 1;
  }

  background: ${colors.linearGradientOne};
  .logo-container {
    box-sizing: border-box;
    ${(props) => (props.theme.dir === "rtl" ? "padding: 43px 68px 22px 0;" : "padding: 43px 0 22px 68px;")}
  }
  .mobile-container {
  }

  .continue-complete-buttons-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 48px 0;
  }

  @media ${device.tablet} {
    .title-with-steps-container {
      padding: 18px 16px 20px 16px !important;
    }
    .padding-container {
      padding: 0 50px;
    }

    .mobile-container {
      padding: 0 30px;
    }

    .blurred-circle {
      display: none;
    }

    .continue-complete-buttons-wrapper {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 40px 0;

      .submit-button {
        margin-bottom: 24px;
      }
    }
  }
  .submit-button-width {
    width: 250px !important;
  }
  @media ${device.mobile} {
    .title-with-steps-container {
      padding: 18px 16px 20px 16px !important;
    }

    .logo-container {
      ${(props) => (props.theme.dir === "rtl" ? "padding: 27px 16px 0 0;" : "padding: 27px 0 0 16px;")}
    }
    .padding-container {
      padding: 0;
    }

    .mobile-container {
      padding: 0;
    }

    .blurred-circle {
      display: none;
    }
    .continue-complete-buttons-wrapper {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 40px 0;

      .submit-button {
        margin-bottom: 24px;
      }
    }
  }
`;
