import { device } from "&assets/constants/responsiveness";
import styled from "styled-components";

export const LearningPathwayDashboardWrapper = styled.div`
  margin-bottom: 40px;
  .dashboard-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 140px 25px 140px;
    box-sizing: border-box;
  }
  .flex-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .learning-activities {
    margin-top: 24px;
  }
  @media ${device.tablet} {
    .dashboard-container {
      padding: 24px 30px;
    }
  }
  @media ${device.mobile} {
    .dashboard-container {
      padding: 24px 16px;
    }
  }
`;
