import React from "react";
import ReactPlayer from "react-player";
import { VideoModalWrapper } from "./videoModal.styled";
import CloseIconButton from "&components/closeIcon/closeIcon.component";
import { XIconBig } from "&assets/constants/icons";
import { Career } from "&features/landingPage/landingPage.type";
import getCDNProtectedURL from "&utils/getCDNProtectedImageSource";
import ImageDisplay from "&components/imageDisplay/imageDisplay.component";
import { TrackGoogleAnalyticsEvent } from "&utils/google-analytics/googleAnalytics";

interface Props {
  url?: string;
  career?: Career;
  open: boolean;
  onClose: () => void;
}

const VideoModal = (props: Props) => {
  const { career, open, onClose, url } = props;

  const videoUrl = url ? url : !career?.videoUrl?.includes("https://")
    ? getCDNProtectedURL(career?.videoUrl)
    : career?.videoUrl;

  const onVideoStart = () => {
    TrackGoogleAnalyticsEvent({
      category: "Home Page Events",
      action: "career_video_watched",
      label: career?.name,
    });
  }

  return (
    <VideoModalWrapper open={open} onClose={() => {}}>
      <div className="video-wrapper">
        <div className="close-button">
          <CloseIconButton onClick={onClose} icon={XIconBig(12)} />
        </div>
        {url || career?.videoUrl ? (
          <ReactPlayer
            width={"100%"}
            height={"100%"}
            url={videoUrl}
            onStart={onVideoStart}
            controls={true}
          />
        ) : career?.image || career?.thumbnail ? (
          <ImageDisplay
            isFromS3={true}
            url={career?.image || career?.thumbnail}
            className="thumbnail"
          />
        ) : null}
      </div>
    </VideoModalWrapper>
  );
};

export default VideoModal;
