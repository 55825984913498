import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAllJobsApi,
  getAllJobsExternalApi,
  getFeaturedJobsApi,
} from "./opportunitiesListing.api";

import { OpportunitiesListing } from "./opportunitiesListing.type";

const initialState: OpportunitiesListing = {
  jobs: undefined,
  loading: false,
  status: "",
};

const getJobs = createAsyncThunk(
  "opportunitiesListing/getJobs",
  async (body: any, { rejectWithValue, getState, dispatch }: any) => {
    try {
      const counrtyId = getState().landingPage.selectedCountry.value;
      body.countryId = counrtyId;
      const isLoggedIn = getState().login.isLoggedIn;
      const jobs = await getAllJobsApi(body, isLoggedIn);
      return jobs?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const getJobsExternal = createAsyncThunk(
  "opportunitiesListing/getJobsExternal",
  async (body: any, { rejectWithValue, getState, dispatch }: any) => {
    try {
      const jobs = await getAllJobsExternalApi(body);
      return jobs?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const getFeaturedJobs = createAsyncThunk(
  "opportunitiesListing/getFeaturedJobs",
  async (body:any, { rejectWithValue, getState, dispatch }: any) => {
    try {
      const jobs = await getFeaturedJobsApi(body);
      return jobs?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const opportunitiesListingSlice = createSlice({
  name: "opportunitiesListing",
  initialState: initialState,
  reducers: {
    setOpportunitiesListing: (state, action) => {
      return { ...state, ...action.payload };
    },
    reset: () => initialState,
  },

  extraReducers: (builder) => {
    builder.addCase(getJobs.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getJobs.fulfilled, (state, action) => {
      state.loading = false;
      state.jobs = action.payload;
      state.status = "GET_JOBS_SUCCESS";
    });
    builder.addCase(getJobs.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(getJobsExternal.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getJobsExternal.fulfilled, (state, action) => {
      state.loading = false;
      state.jobs = action.payload;
      state.status = "GET_JOBS_SUCCESS";
    });
    builder.addCase(getJobsExternal.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(getFeaturedJobs.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getFeaturedJobs.fulfilled, (state, action) => {
      state.loading = false;
      state.jobs = action.payload;
      state.status = "GET_FEATURED_JOBS_SUCCESS";
    });
    builder.addCase(getFeaturedJobs.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const opportunitiesListingReducer = opportunitiesListingSlice.reducer;

export const opportunitiesListingActions = {
  ...opportunitiesListingSlice.actions,
  getJobs,
  getJobsExternal,
  getFeaturedJobs,
};
