import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import React from "react";
import { SubmitButtonWrapper } from "./submitButton.styled";

interface Props {
  id: string;
  title: any;
  handleClick: any;
  className?: any;
  titleClassName?: any;
  icon?: any;
  loading?: boolean;
  leftIcon?: any;
  disable?: boolean;
}

const SubmitButton = (props: Props) => {
  const {
    id,
    title,
    handleClick,
    className,
    titleClassName,
    icon,
    leftIcon,
    disable = false,
    loading = false,
  } = props;
  return (
    <SubmitButtonWrapper
      id={id}
      disabled={disable}
      onClick={!loading && handleClick}
      className={className}
    >
      {leftIcon && leftIcon}
      {loading ? (
        <CircularProgress size={20} color={"inherit"} />
      ) : (
        <p className={`title ${titleClassName ? titleClassName : ""}`}>
          {title}
        </p>
      )}
      {icon && <div className='icon-wrapper'>{icon}</div>}
    </SubmitButtonWrapper>
  );
};

export default SubmitButton;
