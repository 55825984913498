import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  cancelTransactionRequest,
  checkoutTransaction,
  getbundlesApi,
  submitTransactionRequest,
  updateTransactionReceipts,
  verifyTransaction,
} from "./bundles.api";
import toast from "react-hot-toast";
import { BundlesApi } from "./bundles.type";
import { translationSelector } from "&utils/translationSelector";

const initialState: BundlesApi = {
  status: "",
  bundles: [],
  loadingBundles: false,
  sessionId: null,
  orderId: null,
};

const getBundles = createAsyncThunk(
  "bundles/getBundles",
  async (_: any, { rejectWithValue, getState, dispatch }: any) => {
    try {
      const bundles = await getbundlesApi();
      return bundles?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const verifyTransactionApiCall = createAsyncThunk(
  "bundles/verifyTransactionApiCall",
  async (body: any, { rejectWithValue, getState, dispatch }: any) => {
    const { orderId } = body;
    try {
      const bundles = await verifyTransaction(orderId);
      toast.success(
        translationSelector(
          {
            name: "Transaction Successful!",
            nameAr: "عملية ناجحة",
          },
          "name"
        )
      );
      dispatch(getBundles({}));
      return bundles?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const checkoutTransactionApiCall = createAsyncThunk(
  "bundles/checkoutTransaction",
  async (body: any, { rejectWithValue, getState, dispatch }: any) => {
    const { poolId } = body;
    try {
      const bundles = await checkoutTransaction(poolId);
      return bundles?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const submitTransaction = createAsyncThunk(
  "bundles/submitTransaction",
  async (body: any, { rejectWithValue, getState, dispatch }: any) => {
    const { poolId, paymentMethod } = body;
    try {
      const bundles = await submitTransactionRequest(poolId, paymentMethod);
      toast.success(
        translationSelector(
          {
            name: "Transaction Added Successfully",
            nameAr: "تمت إضافة العملية بنجاح",
          },
          "name"
        )
      );
      return bundles?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const updateTransactionReceipt = createAsyncThunk(
  "bundles/updateTransactionReceipt",
  async (body: any, { rejectWithValue, getState, dispatch }: any) => {
    const { transactionId, paymentReceipt } = body
    try {
      const bundles = await updateTransactionReceipts(transactionId, paymentReceipt);
      return bundles?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const cancelTransaction = createAsyncThunk(
  "bundles/cancelTransaction",
  async (body: any, { rejectWithValue, getState, dispatch }: any) => {
    const { transactionId } = body;
    try {
      const bundles = await cancelTransactionRequest(transactionId);
      return bundles?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const bundlesSlice = createSlice({
  name: "bundles",
  initialState: initialState,

  reducers: {
    setBundles: (state, action) => {
      return { ...state, ...action.payload };
    },
    reset: () => initialState,
  },

  extraReducers: (builder) => {
    builder.addCase(getBundles.pending, (state, action) => {
      state.loadingBundles = true;
    });
    builder.addCase(getBundles.fulfilled, (state, action) => {
      state.loadingBundles = false;
      state.bundles = action.payload;
      state.status = "GET_PAID_POOLS_SUCCESS";
    });
    builder.addCase(getBundles.rejected, (state, action) => {
      state.loadingBundles = false;
    });

    builder.addCase(checkoutTransactionApiCall.pending, (state, action) => {
      state.loadingBundles = true;
    });
    builder.addCase(checkoutTransactionApiCall.fulfilled, (state, action) => {
      state.loadingBundles = false;
      state.sessionId = action.payload.sessionId;
      state.orderId = action.payload.orderId;
      state.status = "SUBMIT_TRANSACTION_SUCCESS";
    });
    builder.addCase(checkoutTransactionApiCall.rejected, (state, action) => {
      state.loadingBundles = false;
    });

    builder.addCase(verifyTransactionApiCall.pending, (state, action) => {
      state.orderId = null;
      state.loadingBundles = true;
    });
    builder.addCase(verifyTransactionApiCall.fulfilled, (state, action) => {
      state.loadingBundles = false;
    });
    builder.addCase(verifyTransactionApiCall.rejected, (state, action) => {
      state.loadingBundles = false;
    });

    builder.addCase(submitTransaction.pending, (state, action) => {
      state.loadingBundles = true;
    });
    builder.addCase(submitTransaction.fulfilled, (state, action) => {
      state.loadingBundles = false;
      state.status = "SUBMIT_TRANSACTION_SUCCESS";
    });
    builder.addCase(submitTransaction.rejected, (state, action) => {
      state.loadingBundles = false;
    });

    builder.addCase(cancelTransaction.pending, (state, action) => {
      state.loadingBundles = true;
    });
    builder.addCase(cancelTransaction.fulfilled, (state, action) => {
      state.loadingBundles = false;
      state.status = "CANCEL_TRANSACTION_SUCCESS";
    });
    builder.addCase(cancelTransaction.rejected, (state, action) => {
      state.loadingBundles = false;
    });

    builder.addCase(updateTransactionReceipt.pending, (state, action) => {
      state.loadingBundles = true;
    });
    builder.addCase(updateTransactionReceipt.fulfilled, (state, action) => {
      state.loadingBundles = false;
      state.status = "UPDATE_TRANSACTION_SUCCESS";
    });
    builder.addCase(updateTransactionReceipt.rejected, (state, action) => {
      state.loadingBundles = false;
    });
  },
});

export const bundlesReducer = bundlesSlice.reducer;

export const BundlesActions = {
  ...bundlesSlice.actions,
  getBundles,
  submitTransaction,
  cancelTransaction,
  checkoutTransactionApiCall,
  updateTransactionReceipt,
  verifyTransactionApiCall
};
