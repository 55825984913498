import { device } from "&assets/constants/responsiveness";
import styled from "styled-components";

export const OpportunitiesListWrapper = styled.div`
  .search-input {
    margin: auto;
    margin-top: 65px;
    width: 100%;
    box-sizing: border-box;
    max-width: 1440px;
    padding: 0 173px;
  }

  .list-wrapper {
    display: grid;
    grid-gap: 24px;
    grid-template-columns: auto auto auto;
    justify-content: center;
    .card {
      margin-bottom: 39px;
    }
  }

  .loader-wrapper {
    height: 50px;
    width: 100%;
    display: flex;
    padding: 100px 0;
    justify-content: center;
    align-items: center;
  }

  .no-jobs-wrapper {
    padding: 100px 0;
  }

  @media ${device.inBetween} {
    .list-wrapper {
      grid-template-columns: auto auto;
    }
  }

  @media ${device.tablet} {
    .list-wrapper {
      grid-template-columns: auto auto;
      padding: 0 37px;
    }
    .listing-container {
      padding: 0;
    }
    .search-input {
      padding: 0;
    }
  }

  @media ${device.mobile} {
    .list-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0 37px;
    }
    .listing-container {
      padding: 0;
    }
    .search-input {
      padding: 0;
    }
  }
`;
