import React, { useEffect, useState } from "react";
import { CarouselWrapper } from "./carouselBanner.styled";
import { isEmpty } from "lodash";
import getCDNProtectedURL from "&utils/getCDNProtectedImageSource";
import { images } from "&assets/constants/images-urls";
import RightArrow from "&assets/images/rightArrow.svg";
import LeftArrow from "&assets/images/leftArrow.svg";
import { useMediaQuery } from "react-responsive";
import { size } from "&assets/constants/responsiveness";
import { translationSelector } from "&utils/translationSelector";
import i18n from "&config/i18n";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import CarouselSingleBanner from "&components/carouselSingleBanner/banner.component";

const CarouselBanner = ({
  banners,
  backgroundColor,
  color,
  direction,
  authenticated,
}: any) => {
  const { t } = useTranslation(["landingPage"]);
  const { loginWithRedirect } = useAuth0();
  const isMobile = useMediaQuery({ maxWidth: size.mobile });
  const [autoHeight, setAutoHeight] = useState(false);
  const isTablet = useMediaQuery({
    minWidth: size.mobile,
    maxWidth: size.tablet,
  });
  const isArabic = i18n.language === "ar";
  let autoPlay = true;
  let [currentSlide, setCurrentSlide] = useState<number>(0);

  const bannersSelected = banners
    ? banners?.map((banner: any) => {
        return {
          id: banner.id,
          title: translationSelector(banner, "title"),
          description: translationSelector(banner, "content"),
          buttonText: authenticated
            ? translationSelector(banner, "buttonText")
            : t("SIGN_UP_NOW"),
          handleButtonClick: authenticated
            ? () => window.open(translationSelector(banner, "url"))
            : () => loginWithRedirect(),
          image: banner.image ?? getCDNProtectedURL(images.learningPathwayImg),
        };
      })
    : [];

  const bannersData = bannersSelected;

  useEffect(() => {
    const interval = setTimeout(() => {
      currentSlide !== bannersData.length - 1
        ? setCurrentSlide(currentSlide + 1)
        : setCurrentSlide(0);
    }, 12000);
    if (autoPlay === false) {
      clearTimeout(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isEmpty(bannersData)) {
    return null;
  }

  return (
    <CarouselWrapper>
      <div className="carousel-banner-wrapper">
        <AliceCarousel
          autoPlay={autoPlay}
          infinite
          touchMoveDefaultEvents={false}
          activeIndex={currentSlide}
          autoPlayInterval={3000}
          autoHeight={autoHeight}
          onSlideChanged={() => {
            setAutoHeight(true);
            currentSlide !== bannersData.length - 1
              ? setCurrentSlide(currentSlide + 1)
              : setCurrentSlide((currentSlide = 0));
          }}
          items={bannersData?.map((banner: any, index: any) => {
            return (
              <CarouselSingleBanner
                currentSlide={currentSlide}
                ind={index}
                setAutoHeight={setAutoHeight}
                width={"97%"}
                title={banner.title}
                description={banner.description}
                image={banner.image}
                buttonText={banner.buttonText}
                handleButtonClick={banner.handleButtonClick}
                direction={direction ? direction : isArabic ? "rtl" : ""}
                backgroundColor={backgroundColor}
                color={color}
              />
            );
          })}
          renderNextButton={() =>
            !isMobile &&
            !isTablet && (
              <div className="arrows next">
                <img src={RightArrow} alt="" />
              </div>
            )
          }
          renderPrevButton={() =>
            !isMobile &&
            !isTablet && (
              <div className="arrows prev">
                <img src={LeftArrow} alt="" />
              </div>
            )
          }
        />
      </div>
    </CarouselWrapper>
  );
};

export default CarouselBanner;
