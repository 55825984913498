import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Navigate, Routes } from "react-router-dom";
import { Amplify } from "aws-amplify";
import { useTranslation } from "react-i18next";
import { createTheme, ThemeProvider as MUIThemeProvider } from "@mui/material/styles";
import axios from "axios";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { connect, ConnectedProps, useDispatch, useSelector } from "react-redux";
import ReactGA4 from "react-ga4";
import { Toaster } from "react-hot-toast";
import { ThemeProvider } from "styled-components";

import awsconfig from "&config/awsConfig";
import { ProtectedRoute } from "&route/protectedRoute";
import { LoginComponent } from "&features/login/login.component";
import { MainTheme } from "&assets/themes/themes.type";
import "./App.css";
import ConfigProvider from "./configProvider";
import { RootState } from "&store/store";
import { loginActions } from "&features/login/login.slice";
import GlobalStyles from "./global-styles.styled";
import { LandingPageComponent } from "&features/landingPage/landingPage.component";
import { AssessmentComponent } from "&features/assessment/assessment.component";
import Footer from "&components/footer/footer.component";
import { GlobalComponent } from "&features/global/global.component";
import { CareerMatchingComponent } from "&features/careerMatching/careerMatching.component";
import { LearningPathwayComponent } from "&features/learningPathway/learningPathway.component";
import { CourseDetailsComponent } from "&features/courseDetails/courseDetails.component";
import { ListingPageComponent } from "&features/listingPage/listingPage.component";
import { FutureSkillsLandingComponent } from "&features/futureSkillsLanding/futureSkillsLanding.component";
import { OpportunitiesListingComponent } from "&features/opportunitiesListing/opportunitiesListing.component";
import { OpportunitiesDetailsComponent } from "&features/opportunitiesDetails/opportunitiesDetails.component";
import { CareerDetailsComponent } from "&features/careerDetails/careerDetails.component";
import { FutureCareersComponent } from "&features/futureCareers/futureCareers.component";
import { PrivacyPolicyComponent } from "&features/privacyPolicy/privacyPolicy.component";
import { TermsConditionsComponent } from "&features/termsConditions/termsConditions.component";
import { FrequentlyAskedQuestionsComponent } from "&features/frequentlyAskedQuestions/frequentlyAskedQuestions.component";
import { ContactUsComponent } from "&features/contactUs/contactUs.component";
import { CareerListingComponent } from "&features/careerListing/careerListing.component";
import { AboutComponent } from "&features/about/about.component";
import { BuildProfileComponent } from "&features/login/screens/buildProfile/buildProfile.component";
import { WithNavBar } from "&route/withNavBar";
import { colors } from "&assets/constants/colors";
import { Dashboard } from "&features/dashboard/dashboard.component";
import { UnAuthRoute } from "&route/UnAuthRoute";
import { landingPageActions } from "&features/landingPage/landingPage.slice";
import { useLocation } from "react-router";
import InitializeGoogleAnalytics from "&utils/google-analytics/googleAnalytics";
import { globalActions } from "&features/global/global.slice";
import { LabDetailsComponent } from "&features/labDetails/labDetails.component";
import { ExamDetailsComponent } from "&features/examDetails/examDetails.component";
import ContactUsButton from "&components/buttons/contactUsButton/contactUsButton.component";
import { notificationsActions } from "&features/notifications/notifications.slice";
import { useAuth0 } from "@auth0/auth0-react";
import { BlogsComponent } from "&features/blogs/blogs.component";
import { BlogDetailsComponent } from "&features/blogDetails/blogDetails.component";
import moment from "moment";
import i18n from "&config/i18n";
import "moment/locale/ar"; // Import the Arabic locale
import { BundlesComponent } from "&features/bundles/bundles.component";
import { profileActions } from "&features/dashboard/screens/profile/profile.slice";
import { UnsubscribeComponent } from "&features/unsubscribe/unsubscribe.component";
import { CertificationsComponent } from "&features/certifications/certifications.component";
import { ThankYouComponent } from "&features/login/screens/thankYou/thankYou.component";
// import { MaintenanceScreenComponent } from "&features/login/screens/maintenanceScreen/maintenanceScreen.component";

declare module "styled-components" {
  export interface DefaultTheme extends MainTheme {}
}

InitializeGoogleAnalytics();

Amplify.configure(awsconfig);

type ReduxProps = ConnectedProps<typeof connector>;

const HistoryTracker = (props: any) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const pathsHistory = useSelector((state: RootState) => state.global?.pathsHistory);

  // const setVersion = async (version: string) => {
  //   await localStorage.setItem("version", version);
  // };
  // const clearCache: any = async (setItem: any, version: string) => {
  //   await localStorage.clear();
  //   return setItem(version);
  // };

  // const getSettings = async () => {
  //   try {
  //     const response = await fetch("/settings.json");
  //     const settings = await response.json();
  //     const version: string = settings.version;
  //     const storedVersion = await localStorage.getItem("version");

  //     if (!storedVersion || storedVersion !== version) {
  //       const setVersionCallback = await clearCache(setVersion, version);
  //       setVersionCallback();
  //     }
  //   } catch (error) {
  //     console.error("Error reading file:", error);
  //   }
  // };

  moment.locale(i18n.language);

  useEffect(() => {
    ReactGA4.send({
      hitType: "pageview",
      page: location.pathname,
    });
    const pathName: string = location.pathname;
    if (pathName === "/" || pathName === "/dashboard") {
      dispatch(globalActions.setGlobal({ pathsHistory: [pathName] }));
    } else {
      const newPathsHistory: string[] = [pathName, ...pathsHistory];
      dispatch(globalActions.setGlobal({ pathsHistory: newPathsHistory }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
  return <div />;
};
const App = (props: ReduxProps) => {
  const {
    // reset,
    getCountries,
    headerMenu,
    user,
    // getPanelNotifications,
    // getBannerNotifications,
  } = props;
  const { logout, loginWithRedirect } = useAuth0();
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  // const { isLoggedIn } = useSelector((state: RootState) => state.login);
  // const [underMaintenance, setUnderMaintenance] = useState(false);
  // useEffect(() => {
  //   if (user) {
  //     const intervalCall = setInterval(() => {
  //       getPanelNotifications();
  //       getBannerNotifications();
  //     }, 30000);
  //     return () => {
  //       // clean up
  //       clearInterval(intervalCall);
  //     };
  //   }
  // }, [user]);

  useEffect(() => {
    getCountries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user?.language) {
      i18n.changeLanguage(user?.language);
      localStorage.setItem("language", user?.language);
    } else {
      localStorage.setItem("language", i18n?.language);
    }
    if (user && user?.isEQ1 !== null) {
      localStorage.setItem("iseq1", user?.isEQ1?.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.language, i18n?.language, user]);

  const validEmails = [
    "t.shattat+3@zeroandone.me",
    "t.shattat@zeroandone.me",
    "a.hammoud@zeroandone.me",
    "abbas.forwardmena+2@gmail.com",
  ];
  
  const resetUser = () => {
    localStorage.clear();
    logout({
      logoutParams: {
        returnTo: `${process.env.REACT_APP_AUTH0_REDIRECT_URI}`,
      },
    });
    dispatch(profileActions.reset());
    dispatch(loginActions.reset());
    loginWithRedirect();
  };
  axios.interceptors.response.use(
    function (response: any) {
      // console.log("isLoggedIn", isLoggedIn);
      // console.log("window.location.href", window.location.href);

      if (
        !localStorage.getItem("iseq1") &&
        response.request.responseURL.includes("assessments/learning-paths") &&
        response.headers.iseq1 !== null &&
        !user
      ) {
        localStorage.setItem("iseq1", response.headers.iseq1);
      }
      if (
        response.request.responseURL.includes("/auth/user/profile") &&
        response.data.message === "ERROR_FETCHING_USER"
      ) {
        resetUser();
      }
      return response;
    },
    function (error) {
      if (401 === error.response.status) {
        resetUser();
      } else {
        return Promise.reject(error);
      }
    }
  );

  const headerRoutes = [
    { url: "future-skills/*", element: <FutureSkillsLandingComponent /> },
    { url: "future-careers/*", element: <FutureCareersComponent /> },
  ];
  const dir = i18n.dir();
  const MUITheme = createTheme({
    typography: {
      fontFamily: ["Mulish", "sans-serif"].join(","),
    },
    direction: dir,
    palette: {
      primary: {
        main: colors.primary,
        light: colors.primaryLight,
        dark: colors.primaryDark,
      },
      secondary: { main: colors.secondary, dark: colors.secondaryDark },
      info: { main: colors.white },
    },
  });

  return (
    <ConfigProvider i18n={i18n}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <GlobalComponent>
          <ThemeProvider theme={{ dir: i18n.dir() }}>
            <MUIThemeProvider theme={MUITheme}>
              <GlobalStyles />
              <Toaster position="top-right" />
              <BrowserRouter>
                <HistoryTracker />
                {/* {!underMaintenance ? ( */}
                <Routes>
                  {/*In Navbar, there is a hook that makes the screen scroll up. Sincerely Mira*/}
                  <Route element={<WithNavBar />}>
                    <Route path="/" element={<LandingPageComponent />} />
                    <Route path="/*" element={<Navigate to="/" />} />
                    {headerMenu &&
                      headerRoutes
                        .filter((route) =>
                          headerMenu.some((item: any) => route.url.toLowerCase().includes(item.url.toLowerCase()))
                        )
                        .map((route: any) => <Route path={route.url} element={route.element} />)}
                    <Route path="assessment/*" element={<AssessmentComponent />} />
                    <Route path="bundles/*" element={<BundlesComponent />} />
                    <Route path="career-matching/*" element={<CareerMatchingComponent />} />
                    <Route path="learning-pathway/:id" element={<LearningPathwayComponent />} />
                    <Route path="course-details/:id" element={<CourseDetailsComponent />} />
                    <Route path="lab-details/:id" element={<LabDetailsComponent />} />
                    <Route path="exam-details/:id" element={<ExamDetailsComponent />} />
                    <Route path="opportunities/*" element={<OpportunitiesListingComponent />} />
                    <Route path="opportunity-details/:id" element={<OpportunitiesDetailsComponent />} />
                    <Route path="career-details/:id" element={<CareerDetailsComponent />} />
                    <Route path="listing-page/*" element={<ListingPageComponent />} />
                    <Route path="privacy-policy/*" element={<PrivacyPolicyComponent />} />
                    <Route path="terms-conditions/*" element={<TermsConditionsComponent />} />
                    <Route path="unsubscribe" element={<UnsubscribeComponent />} />
                    <Route path="faq" element={<FrequentlyAskedQuestionsComponent />} />
                    <Route path="contact-us" element={<ContactUsComponent />} />
                    <Route path="certifications" element={<CertificationsComponent />} />
                    <Route path="about-nammiskills" element={<AboutComponent />} />

                    <Route path="blog" element={<BlogsComponent />} />
                    <Route path="blog-details/:id" element={<BlogDetailsComponent />} />
                    <Route path="careers/*" element={<CareerListingComponent />} />
                  </Route>
                  <Route element={<UnAuthRoute />}>
                    <Route path="signup" element={<LoginComponent />} />
                    <Route path="login" element={<LoginComponent />} />
                  </Route>
                  <Route element={<ProtectedRoute />}>
                    <Route path="build-profile/*" element={<BuildProfileComponent />} />
                    <Route path="thank-you" element={<ThankYouComponent />} />

                    <Route element={<ProtectedRoute checkOnBoarding={true} />}>
                      <Route element={<WithNavBar />}>
                        <Route path="/*" element={<Navigate to="/dashboard" />} />
                        <Route path="dashboard/*" element={<Dashboard />} />
                        <Route path="home" element={<Navigate to="/" />} />
                        <Route path="*" element={<div>page not found</div>} />
                      </Route>
                    </Route>
                  </Route>
                </Routes>
                {/* ) : (
                   <Routes>
                     <Route>
                       <Route path="login" element={<LoginComponent />} />
                       <Route path="maintenance" element={<MaintenanceScreenComponent />} />
                       <Route path="/*" element={<Navigate to="/maintenance" />} />
                     </Route>
                   </Routes>
                 )} */}
                <Footer />
                {/* {!underMaintenance && <Footer />} */}
                <ContactUsButton />
              </BrowserRouter>
            </MUIThemeProvider>
          </ThemeProvider>
        </GlobalComponent>
      </LocalizationProvider>
    </ConfigProvider>
  );
};

const mapStateToProps = (state: RootState) => ({
  isLoggedIn: state.login.isLoggedIn,
  accessToken: state.login.accessToken,
  user: state.login.user,
  pathsHistory: state.global.pathsHistory,
  headerMenu: state.global?.settings?.header_menu,
});

const mapDispatchToProps = {
  reset: loginActions.reset,
  getCountries: landingPageActions.getCountries,
  setPathsHistory: globalActions.setPathsHistory,
  getPanelNotifications: notificationsActions.getPanelNotifications,
  getBannerNotifications: notificationsActions.getBannerNotifications,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const AppComponentRedux = connector(App);

export { AppComponentRedux as App };
