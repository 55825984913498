import React, { useState } from "react";
import { Collapse } from "@mui/material";
import {
  CategoryButton,
  Listbox,
  ReferralPickerWrapper,
} from "./referralPicker.styled";
import {
  AutocompleteChangeReason,
  AutocompleteValue,
  useAutocomplete,
} from "@mui/material";
import { AddIcon, ChevronDown, SearchIcon } from "&assets/constants/icons";
import { useTranslation } from "react-i18next";
import { translationSelector } from "&utils/translationSelector";
import { Setting } from "&features/global/global.type";
import { capitalize, isEmpty } from "lodash";

interface Props {
  referrals: Setting[];
  chosenReferrals: any[];
  handleReferralChange: (setting: Setting[]) => void;
  disabled?: boolean;
  error?: boolean;
}

const ReferralPicker = (props: Props) => {
  const {
    referrals,
    chosenReferrals,
    error,
    handleReferralChange,
    // disabled = false,
  } = props;
  const { t, i18n } = useTranslation(["assessment"]);

  const handleChange = (
    event: any,
    options: AutocompleteValue<any, any, any, any>,
    reason: AutocompleteChangeReason
  ) => {
    if (
      (reason === "selectOption" && options?.length > 10) ||
      (reason === "removeOption" && event?.code === "Backspace")
    ) {
      return;
    }

    handleReferralChange(options);
  };

  // const renderOptionTagSlider = (referral: any, index: number) => {
  //   return (
  //     <OptionTagSliderWrapper key={referral.id}>
  //       <SkillOptionTag
  //         {...getTagProps({ index })}
  //         id={referral.id}
  //         key={index}
  //         title={translationSelector(referral, "name")}
  //         onDelete={(e: any) => getTagProps({ index }).onDelete(e)}
  //       />
  //     </OptionTagSliderWrapper>
  //   );
  // };

  const sortedOptions: (itemsArray: Setting[]) => Setting[] = (
    itemsArray: Setting[]
  ) => {
    const categories: any = {};

    itemsArray?.map((referral: Setting) => {
      !isEmpty(categories[referral.value])
        ? (categories[referral.value] = [
            ...categories[referral.value],
            referral,
          ])
        : (categories[referral.value] = [referral]);
    });

    let sortedArray: Setting[] = [];
    Object.keys(categories)?.map((key) => {
      sortedArray = [...sortedArray, ...categories[key]];
    });
    let modifiedArray = sortedArray?.map((setting: Setting, index) => {
      return {
        ...setting,
        sortingIndex: index,
      };
    });

    return modifiedArray;
  };

  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    // getTagProps,
  } = useAutocomplete({
    id: "referrals-search",
    options: referrals || [],
    multiple: false,
    value: chosenReferrals,
    groupBy: (option) =>
      i18n.language.includes("en") ? option?.value : option?.valueAr,
    getOptionLabel: (option) => option.name,
    onChange: handleChange,
    onClose: () => setCategoryOpenId(undefined),
    disableCloseOnSelect: false,
    isOptionEqualToValue: (option: any, value: any) => option?.id === value?.id,
    filterOptions: (options, state) => {
      return sortedOptions(
        options?.filter((option) =>
          option?.name?.toLowerCase().includes(state.inputValue.toLowerCase())
        )
      );
    },
  });

  const [categoryOpenId, setCategoryOpenId] = useState<any>(undefined);

  const renderCategoryReferrals = (category: {
    index: number;
    key: number;
    group: string;
    groupAr: string;
    options: any[];
  }) => {
    const isOtherCategory = category.group === "other";
    const open = categoryOpenId === category.index;
    return (
      <>
        {isOtherCategory ? (
          <>
            {(category.options as typeof referrals).map((option, index) => (
              <li
                {...getOptionProps({
                  option,
                  index: option.sortingIndex || index,
                })}
              >
                {translationSelector(option, "name")}
                <div className="icon-wrapper">
                  <AddIcon />
                </div>
              </li>
            ))}
          </>
        ) : (
          <>
            <CategoryButton
              open={open}
              key={category.index}
              onClick={() =>
                setCategoryOpenId(open ? undefined : category.index)
              }
            >
              <span className="button-text">
                {capitalize(translationSelector(category, "group"))}
              </span>
              <ChevronDown />
            </CategoryButton>
            <Collapse
              className="collapse-container"
              in={open}
              timeout="auto"
              unmountOnExit
            >
              {(category.options as typeof referrals).map((option, index) => (
                <li
                  {...getOptionProps({
                    option,
                    index: option.sortingIndex || index,
                  })}
                >
                  {translationSelector(option, "name")}
                  <div className="icon-wrapper">
                    <AddIcon />
                  </div>
                </li>
              ))}
            </Collapse>
          </>
        )}
      </>
    );
  };

  return (
    <ReferralPickerWrapper>
      <div
        className={`input-wrapper ${error && "select-referrals-input-error"}`}
        {...getRootProps()}
      >
        <input placeholder={t("SELECT_FROM_THE_LIST")} {...getInputProps()} />
        <SearchIcon />
      </div>
      {groupedOptions.length > 0 ? (
        <Listbox {...getListboxProps()}>
          {groupedOptions.map((category: any) => {
            return renderCategoryReferrals(category);
          })}
        </Listbox>
      ) : null}
      {/* <div className="spacing-tags-wrapper">
        {groupedOptions.length > 0 && <div className="spacing-wrapper" />}
        <OtherSkillsTagsWrapper>
          {chosenReferrals.map((option, index) => {
            return renderOptionTagSlider(option, index);
          })}
        </OtherSkillsTagsWrapper>
      </div> */}
    </ReferralPickerWrapper>
  );
};

export default ReferralPicker;
