import React, { useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { AdditionalDetails } from "&features/dashboard/screens/profile/profile.type";
import {
  FormInputContent,
  UpdateProfileBody,
} from "&features/login/login.type";
import { ProfileModalInnerContent } from "&components/profile/edit/profileEditModel/profileEditModal.styled";
import inputSelector from "&utils/ui/inputSelector";
import checkAndValidate from "&utils/validatePhoneNumber";
import { useTranslation } from "react-i18next";
import { Settings } from "&features/global/global.type";

interface Props {
  initialValues?: AdditionalDetails;
  submit?: number;
  settings?: Settings;
  updateProfile: (body: UpdateProfileBody) => void;
}

const ProfileAddAdditionalDetails = (props: Props) => {
  const { initialValues, submit, updateProfile, settings } = props;

  const values: AdditionalDetails = {
    languages: initialValues?.languages || undefined,
    email: initialValues?.email || undefined,
    phoneNumber: initialValues?.phoneNumber || undefined,
  };
  const { t } = useTranslation(["profile"]);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter a valid email")
      .trim()
      .required("Required"),
    phoneNumber: Yup.string().when({
      is: (exist: any) => exist,
      then: Yup.string().test(
        "Phone number",
        t("PHONE_NUMBER_IS_NOT_VALID"),
        function (value: any) {
          return checkAndValidate(value).status === "SUCCESS";
        }
      ),
      otherwise: Yup.string().required(t("REQUIRED")),
    }),
  });

  const formik: any = useFormik({
    initialValues: values,
    validationSchema: validationSchema,
    onSubmit: () =>
      updateProfile({
        phoneNumber: formik.values.phoneNumber,
        languages: formik.values?.languages
          .map((lang: any) => lang.name)
          ?.join(","),
      }),
  });

  useEffect(() => {
    submit && submit > 0 && formik.handleSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submit]);

  const additionalDetailsInputGrid: FormInputContent[] = [
    {
      id: "phone-input",
      label: t("PHONE"),
      placeHolder: "+961234567",
      type: "phoneNumber",
      value: formik.values?.phoneNumber,
      handleChange: (value) => formik.setFieldValue("phoneNumber", value),
      error: formik.touched.phoneNumber && formik.errors.phoneNumber,
    },
    {
      id: "languages-input",
      label: t("LANGUAGES"),
      placeHolder: t("TYPE_YOUR_LANGUAGES"),
      type: "select",
      multiple: true,
      options: settings?.language_option,
      value: formik.values?.languages,
      handleChange: (value) => formik.setFieldValue("languages", value),
      error: formik.touched.languages && formik.errors.languages,
    },
  ];

  return (
    <ProfileModalInnerContent>
      <div className="input-grid">
        {additionalDetailsInputGrid?.map(
          (item: FormInputContent, index: number) =>
            !item.hide && inputSelector(item, index)
        )}
      </div>
    </ProfileModalInnerContent>
  );
};

export default ProfileAddAdditionalDetails;
