import { colors } from "&assets/constants/colors";
import { device } from "&assets/constants/responsiveness";
import styled from "styled-components";

interface Props {
  activeLine: number;
}

export const HeaderLandingWrapper = styled.div<Props>`
  position: relative;
  box-sizing: border-box;
  background: ${colors.linearGradientOne} padding-box, ${colors.linearGradientSixth} border-box;
  ${(props) => (props.theme.dir === "rtl" ? "padding: 65px 64px 48px 40px;" : "padding: 65px 40px 48px 64px;")}
  height: 392px;
  max-height: 417px;
  .header-wrapper {
    max-width: 1355px;
    margin: 0 auto;
  }
  .carousel-title {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 110%;
    letter-spacing: -0.96px;
    color: ${colors.white};
    margin: 0;
    z-index: 3;
    position: relative;
    width: 536px;
  }
  .description {
    width: 439px;
    //height: 58px;
    font-family: "Mulish";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 160%;
    color: ${colors.white};
    margin-top: 24px;
    margin-bottom: 24px;
    position: relative;
    z-index: 3;
    opacity: 0.7 !important;
  }
  .explore-carousel {
    background: ${colors.white};
    color: ${colors.primary} !important;
    border: 0;
    width: fit-content;
    height: fit-content;
    box-sizing: border-box;
    display: flex;
    padding: 13px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    p {
      text-align: center;
      font-family: Mulish;
      font-size: 14px;
      font-style: normal;
      font-weight: 800;
      line-height: 160%; /* 22.4px */
      margin: 0;
    }
  }
  .carousel-wrapper {
    direction: initial;
  }
  .socials {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    ${(props) => (props.theme.dir === "rtl" ? "left: 20px;" : "right: 20px;")}
    top: 65px;
    height: 150px;
    .icon {
      transition: all 0.2s ease-in-out;
      cursor: pointer;
      &:hover {
        transform: scale(1.1);
      }
    }
  }
  .lines {
    position: absolute;
    top: 46%;
    ${(props) => (props.theme.dir === "rtl" ? "left: 39%;" : "left: 61%;")}
    transform: translate(-50%, -50%);
    width: 50rem;
    height: 100%;
    z-index: 0;

    .carousel {
      position: absolute;
      width: 759px !important;
      top: 80px;
      z-index: 2;
      border-radius: 16px;
      ${(props) => (props.theme.dir === "rtl" ? "left: -90px;" : "right: -90px;")}
    }
    .carousel-content {
      .content {
        display: none;
      }
    }
    .carousel-slider {
      overflow: visible;
    }

    .arrows {
      ${(props) => (props.theme.dir === "rtl" ? "transform: rotate(180deg);" : "")}
      position: absolute;
      z-index: 2;
      top: calc(50% - 15px);
      width: 36px;
      height: 36px;
      cursor: pointer;
    }

    .next {
      ${(props) => (props.theme.dir === "rtl" ? "left: -18px;" : "right: -18px;")}
    }
    .prev {
      ${(props) => (props.theme.dir === "rtl" ? "right: -12px;" : "left: -12px;")}
    }
    .carousel-image {
      z-index: 4;
      width: 100%;
      height: 523px;
      object-fit: cover;
      position: relative;
      border-radius: 16px;
    }
    .carousel-video {
      height: initial !important;
    }
    .line {
      position: absolute;
      top: -66px;
      z-index: 1;
      ${(props) => (props.theme.dir === "rtl" ? "transform: scaleX(-1);" : "")}
      ${(props) => (props.theme.dir === "rtl" ? "margin-left: 100px;" : "")}

      .carousel-steps {
        margin-top: -240px;
        display: flex;
        ${(props) => (props.theme.dir === "rtl" ? "transform: scaleX(-1);" : "")}
        margin-left: -28px;
      }

      .activation-line {
        width: 2px;
        height: 54px;
        background-color: transparent;
        transition: all 0.2s ease-in-out;
        cursor: pointer;
        position: relative;
        ${(props) => (props.theme.dir === "rtl" ? "margin-right: 10px;" : "margin-left: 10px;")}
      }
      .index-line {
        opacity: 0;
        align-self: flex-end;
      }
      &.active {
        .activation-line {
          background-color: transparent;
          height: 100px;
        }
        .index-line {
          margin: 0;
          opacity: 1;
          font-weight: 500;
          font-size: 16px;
          line-height: 160%;
          color: transparent;
        }
      }
    }
  }
  @media ${device.tablet} {
    max-height: initial;
    height: fit-content;
    padding: 30px;
    .line {
      display: none;
    }
    .socials,
    .description,
    .explore {
      //display: none;
    }
    .carousel-title-desktop {
      //display: none;
    }
    .carousel-title {
      font-size: 40px;
      width: 60%;
      ${(props) => (props.theme.dir === "rtl" ? "text-align: right;" : "text-align: left;")}
    }
    .lines {
      position: relative;
      margin-top: 20px;
      height: 100%;
      width: 100%;
      left: initial;
      top: initial;
      transform: initial;
      .carousel {
        position: relative;
        width: 100% !important;
        top: initial;
        right: initial;
      }
      .carousel-image {
        height: 466px;
        object-fit: cover;
      }
      .carousel-content {
        position: relative;
      }
      .arrows {
      }
    }
  }

  @media ${device.mobile} {
    max-height: initial;
    height: fit-content;
    box-sizing: border-box;
    padding: 20px 16px;
    .line {
      display: none;
    }
    .socials {
      display: none;
    }
    .description {
      font-weight: 500;
      font-size: 16px;
      width: 100%;
    }
    .carousel-title {
      width: 100%;
      font-size: 40px;
      ${(props) => (props.theme.dir === "rtl" ? "text-align: right;" : "text-align: left;")}
    }
    .lines {
      position: relative;
      margin-top: 20px;
      height: 100%;
      width: 100%;
      left: initial;
      top: initial;
      transform: initial;
      .carousel {
        position: relative;
        width: 100% !important;
        top: initial;
        ${(props) => (props.theme.dir === "rtl" ? "left: initial;" : "right: initial;")}
        .slider {
          .slide {
            &.selected {
              opacity: 1 !important;
              min-width: 95%;
              margin: 0px 2% 0px 0px;
            }
          }
        }
      }

      .carousel-image {
        height: 466px;
        object-fit: cover;
      }
      .carousel-content {
        position: relative;
      }
    }
    .next {
      ${(props) => (props.theme.dir === "rtl" ? "left: -12px !important" : "right: 0 !important")}
    }
    .prev {
      ${(props) => (props.theme.dir === "rtl" ? "right: 10px !important" : "left: 0 !important")}
    }
  }
`;
