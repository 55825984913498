import styled from "styled-components";
import { device } from "&assets/constants/responsiveness";

interface Props {
  short?: boolean;
}

export const CareerLevelsWrapper = styled.div<Props>`
  width: 100vw;

  @media ${device.desktop} {
    position: ${(props) => (props.short ? "absolute" : "relative")};
    height: ${(props) => (props.short ? "100vh" : "initial")};
    overflow-x: ${(props) => (props.short ? "hidden" : "visible")};
    overflow-y: ${(props) => (props.short ? "hidden" : "visible")};
  }
  
  ${(props) => (props.short ? "display: flex;" : "")}
  scrollbar-width: none;
  align-items: center;

  &::-webkit-scrollbar {
    display: none;
  }

  @media ${device.inBetween} {
    overflow-y: hidden;
    overflow-x: ${(props) => (props.short ? "auto" : "hidden")};
  }

  @media ${device.mobile} {
    overflow-y: hidden;
    overflow-x: ${(props) => (props.short ? "auto" : "hidden")};
  }

  @media ${device.tablet} {
    overflow-y: hidden;
    overflow-x: ${(props) => (props.short ? "auto" : "hidden")};
  }
`;

export const LevelButtonsWrapper = styled.div<Props>`
  width: 100%;
  position: relative;
  align-items: center;
  
  @media ${device.desktop} {
    height: ${(props) => (props.short ? "230px" : "initial")};
  }
  
  ${(props) => (props.short ? "display: flex;" : "")}

  .forward-arrow {
    position: ${(props) => (props.short ? "absolute" : "none")};
    display: ${(props) => (props.short ? "initial" : "none")};
    ${(props) =>
            props.theme.dir === "rtl"
                    ? "left: 40px;"
                    : "right: 40px;"}
    ${(props) =>
            props.theme.dir === "rtl"
                    ? "transform: rotate(180deg);"
                    : ""}
    z-index: 10;
  }

  .back-arrow {
    position: ${(props) => (props.short ? "absolute" : "none")};
    display: ${(props) => (props.short ? "initial" : "none")};
    ${(props) =>
            props.theme.dir === "rtl"
                    ? "right: 40px;"
                    : "left: 40px;"}
    ${(props) =>
            props.theme.dir === "rtl"
                    ? "transform: rotate(0deg) !important;"
                    : ""}
    z-index: 10;
  }

  @media ${device.inBetween} {
    .back-arrow {
      display: none;
    }

    .forward-arrow {
      display: none;
    }
  }

  @media ${device.mobile} {
    .back-arrow {
      display: none;
    }

    .forward-arrow {
      display: none;
    }
  }

  @media ${device.tablet} {
    .back-arrow {
      display: none;
    }

    .forward-arrow {
      display: none;
    }
  }
`;
