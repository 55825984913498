import React from "react";
import { ProfileEducationWrapper } from "./profileEducation.styled";
import { EducationItem } from "&features/dashboard/screens/profile/profile.type";
import EditRoundButton from "&components/buttons/editRoundButton/editRoundButton.component";
import dateDifferenceFormat from "&utils/dateDifferenceFormat";
import { translationSelector } from "&utils/translationSelector";
import { useTranslation } from "react-i18next";

interface Props {
  editValue?: () => void;
}
const ProfileEducation = (props: EducationItem & Props) => {
  const {
    educationDegree,
    graduationYear,
    current,
    startDate,
    schoolName,
    description,
    fieldOfStudy,
    otherSchoolName,
    editValue,
  } = props;
  const { t } = useTranslation(["profile"]);

  const startDateFormatted = startDate?.format("YYYY") || undefined;
  const graduationYearFormatted = current
    ? t("PRESENT")
    : graduationYear?.format("YYYY") || undefined;

  const isOtherInstitute = otherSchoolName && schoolName?.name === "Other";

  return (
    <ProfileEducationWrapper>
      <div className="education-title-edit-button">
        <div className="school-name-title-text">
          {isOtherInstitute
            ? otherSchoolName
            : translationSelector(schoolName, "name")}
        </div>
        {editValue && <EditRoundButton onClick={editValue} />}
      </div>
      <div className="education-degree-text">{`${translationSelector(
        educationDegree,
        "name"
      )}${
        fieldOfStudy ? ", " + translationSelector(fieldOfStudy, "name") : ""
      }`}</div>
      <div className="date-period-text">{`${
        startDateFormatted ? startDateFormatted + " - " : ""
      }${graduationYearFormatted} ${
        startDate && graduationYear
          ? "(" + dateDifferenceFormat(startDate, graduationYear) + ")"
          : ""
      }`}</div>
      <div className="description-text">{description}</div>
    </ProfileEducationWrapper>
  );
};

export default ProfileEducation;
