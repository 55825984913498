import styled from "styled-components";

export const BorderlessButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 138px;
  height: 57px;
  background: transparent;
  border: 1px solid #ffffff;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.1s ease-in;
  .btn-title {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 160%;
    text-align: center;
    /* color: #ffffff; */
  }
  &:hover {
    transform: scale(1.05);
  }
`;
