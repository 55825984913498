import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { ActivitiesCarouselWrapper } from "./activitiesCarousel.styled";
import { Course } from "&features/listingPage/listingPage.type";
import ActivityCard from "&components/cards/activityCard/activityCard.component";
import CardsCarousel from "&components/cardsCarousel/cardsCarousel.component";
import { checkIFEnrolledCourse } from "&utils/checkIfEnrolled";
import { RootState } from "&store/store";

interface Props {
  data?: any;
  isLearningPath?: boolean;
}

const ActivitiesCarousel = (props: Props) => {
  const { data, isLearningPath } = props;
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.login.user);

  const handleClickCourse = (item: any) => {
    const courseOrLP = isLearningPath ? "learning-pathway" : "course-details";
    const courseOrLPDashboard = isLearningPath ? "learning-pathway" : "course";
    checkIFEnrolledCourse(item.id, user)
      ? navigate(`/dashboard/${courseOrLPDashboard}/${item.id}`)
      : navigate(`/${courseOrLP}/${item.id}`);
  };
  return (
    <ActivitiesCarouselWrapper>
      <CardsCarousel>
        {data?.map((course: Course, index: number) => {
          return (
            <div
              className="cards"
              key={index}
              onClick={() => handleClickCourse(course)}
            >
              <ActivityCard type="course" course={course} />
            </div>
          );
        })}
      </CardsCarousel>
    </ActivitiesCarouselWrapper>
  );
};

export default ActivitiesCarousel;
