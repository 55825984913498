import styled from "styled-components";

export const OpportunitiesCarouselWrapper = styled.div`
  .carousel {
    z-index: 2;
    position: relative;
  }
  .cards {
    margin-top: 38px;
    ${(props) =>
            props.theme.dir === "rtl"
                    ? "margin-left: 16px;"
                    : "margin-right: 16px;"}
  }
`;
