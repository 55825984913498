import React, { useEffect, useState } from "react";
import { CustomSlider, SliderWrapper } from "./slider.styled";
import selectTitleForSlider from "&utils/selectTitleForSlider";
import selectValueFromTitleForSlider from "&utils/setValueFromTitleForSlider";
import { useTranslation } from "react-i18next";

interface Props {
  value: string | number | undefined;
  marks?: string[];
  disabled?: boolean;
  onChange: (value: any) => void;
  className?: string;
}

const Slider = (props: Props) => {
  const { value, marks, disabled, onChange, className } = props;

  const hasSteps = marks && marks?.length > 0;

  const [numberValue, setNumberValue] = useState<number | number[]>(0);
  const { t } = useTranslation(["assessment"]);

  useEffect(() => {
    marks && typeof value === "string"
      ? setNumberValue(selectValueFromTitleForSlider(marks, value))
      : typeof value === "number" && setNumberValue(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleChange = (value: any) => {
    const result = hasSteps ? selectTitleForSlider(marks, value) : value;
    onChange(result);
  };

  return (
    <SliderWrapper disabled={disabled} className={className}>
      {marks ? (
        <div className="slider-title">
          {value && typeof value === "string" ? t(value) : value}
        </div>
      ) : null}
      <CustomSlider
        value={numberValue}
        disabled={disabled}
        step={hasSteps ? 100 / (marks?.length - 1) : 1}
        onChange={(e: Event, newValue) => handleChange(newValue)}
      />
    </SliderWrapper>
  );
};

export default Slider;
