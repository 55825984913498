import { colors } from "&assets/constants/colors";
import { device } from "&assets/constants/responsiveness";
import styled from "styled-components";

interface Props {
  activeStep: number;
}

export const LearningPathStepperWrapper = styled.div<Props>`
  .current-learning-pathway {
    width: 805px;
    height: 329px;
    background: ${colors.linearGradientWhite} padding-box, ${colors.linearGradientOne} border-box;
    padding: 21px 32px;
    border: 1px solid transparent;
    border-radius: 16px;
    box-sizing: border-box;
    .stepper-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .pathway-name {
      font-weight: 700;
      font-size: 18px;
      line-height: 120%;
      color: ${colors.dark};
      margin: 0;
      .percentage {
        color: ${colors.secondaryDark};
      }
    }
    .arrows {
      cursor: pointer;
      ${(props) => (props.theme.dir === "rtl" ? "transform: scaleX(-1);" : "")}
    }
    .separator {
      width: 100%;
      border: 1px solid #d6ddeb;
    }
    .provider-content {
      display: flex;
      align-items: center;
      .provider-title {
        font-weight: 400;
        font-size: 14px;
        line-height: 28px;
        color: ${colors.lighterDark};
        ${(props) => (props.theme.dir === "rtl" ? "margin-left: 30px;" : "margin-right: 30px;")}
      }
    }
    .card-content {
      display: flex;
      align-items: center;
      .flex-row {
        display: flex;
        justify-content: flex-start;
        width: fit-content;
        flex-direction: row;
      }
      .title {
        font-weight: 400;
        font-size: 14px;
        ${(props) => (props.theme.dir === "rtl" ? "margin-right: 10px;" : "margin-left: 10px;")}
        color: ${colors.lighterDark};
        align-items: center;
        justify-content: center;
        display: flex;
        margin: 0;
        .text {
          ${(props) => (props.theme.dir === "rtl" ? "margin-right: 6px;" : "margin-left: 6px;")}
        }
      }
      .info {
        ${(props) => (props.theme.dir === "rtl" ? "margin-right: 0;" : "margin-left: 0;")}

        ${(props) => (props.theme.dir === "rtl" ? "margin-left: 20px;" : "margin-right: 20px;")}
      }
    }
    .view-path {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: all 0.2 ease-in;
      .view-path-text {
        font-weight: 800;
        font-size: 12px;
        line-height: 160%;
        ${(props) => (props.theme.dir === "rtl" ? "margin-left: 8px;" : "margin-right: 8px;")}
        color: ${colors.primary};
      }
      &:hover {
        opacity: 0.7;
      }

      svg {
        ${(props) => (props.theme.dir === "rtl" ? "transform: scaleX(-1);" : "")}
      }
    }
    .course-information {
      display: flex;
      justify-content: space-between;
      .selected-course,
      .course-skills {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 57%;
        .title {
          font-weight: 700;
          font-size: 14px;
          line-height: 120%;
          color: ${colors.dark};
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box !important;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          white-space: normal;
        }
        .description {
          font-weight: 500;
          font-size: 14px;
          line-height: 160%;
          color: ${colors.dark};
          margin-top: 8px;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box !important;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          white-space: normal;
        }
      }
      .course-skills {
        width: 41%;
      }
    }
    .steps {
      width: 100%;
      overflow: auto;
      padding: 0 8px;
      margin-bottom: 10px;
      ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
      }
      ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: ${colors.primary};
      }
      ::-webkit-scrollbar {
        width: 12px;
        height: 5px;
      }
    }
  }
  @media ${device.tablet} {
    .current-learning-pathway {
      width: 100%;
      height: auto;
      margin-bottom: 46px;
    }
  }
  @media ${device.mobile} {
    .current-learning-pathway {
      width: 100%;
      height: auto;
      padding: 21px 18px;
      margin-bottom: 46px;
      .card-content {
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }
`;
