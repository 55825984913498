import React, { useEffect } from "react";
import InputComponent from "&components/inputs/input/input.component";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ProfileModalInnerContent } from "&components/profile/edit/profileEditModel/profileEditModal.styled";
import { UpdateProfileBody } from "&features/login/login.type";
import { useTranslation } from "react-i18next";

interface Props {
  updateProfile: (body: UpdateProfileBody) => void;
  initialValues?: string;
  submit?: number;
}

const ProfileFullName = (props: Props) => {
  const { initialValues = "", submit, updateProfile } = props;
  const { t } = useTranslation(["profile"]);

  const [name, lastName] = initialValues.split("|");
  const values: any = {
    name: name || "",
    lastName: lastName || "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    lastName: Yup.string().required(),
  });

  const formik: any = useFormik({
    initialValues: values,
    validationSchema: validationSchema,
    onSubmit: () =>
      updateProfile({
        name: formik.values.name,
        lastName: formik.values.lastName,
      }),
  });

  useEffect(() => {
    submit && submit > 0 && formik.handleSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submit]);

  return (
    <ProfileModalInnerContent>
      <InputComponent
        multiline={true}
        label={t("FIRST_NAME")}
        placeholder={t("ENTER_FIRST_NAME")}
        value={formik.values?.name}
        onChange={(value) => formik.setFieldValue("name", value.target.value)}
        valid={formik.touched.name && formik.errors.name}
      />
      <InputComponent
        multiline={true}
        label={t("LAST_NAME")}
        placeholder={t("ENTER_LAST_NAME")}
        value={formik.values?.lastName}
        onChange={(value) =>
          formik.setFieldValue("lastName", value.target.value)
        }
        valid={formik.touched.lastName && formik.errors.lastName}
      />
    </ProfileModalInnerContent>
  );
};

export default ProfileFullName;
