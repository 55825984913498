import { colors } from "&assets/constants/colors";
import { device } from "&assets/constants/responsiveness";
import styled from "styled-components";

export const CertificationsCardsWrapper = styled.div`
  padding: 48px 72px 50px 72px;
  background-color: rgba(16, 140, 210, 0.1);

  .slide {
    min-width: 370px !important;
  }

  .certification-card-wrapper {
    max-width: 1089px;
    margin: auto;
  }
  .title {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 110%;
    letter-spacing: -0.02em;
    color: ${colors.white};
  }

  .loader-wrapper {
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .title-button-wrapper {
    margin-bottom: 20px;

    .section-title {
      font-size: 32px;
    }
  }
  .description-button-wrapper {
    .section-title {
      font-size: 15px;
    }
  }
  .no-learning-type-wrapper {
    color: white;
    font-size: 15px;
    text-align: center;
    position: relative;
  }

  .arrow-down {
    transform: rotate(90deg);
    margin-top: -7%;
    ${(props) => (props.theme.dir === "rtl" ? "margin-right: 40%;" : "margin-left: 40%;")};
  }
  .cards {
    width: 96%;
    ${(props) => (props.theme.dir === "rtl" ? "margin-left: 16px;" : "margin-right: 16px;")}
  }

  .selected-certificate {
    background: ${colors.linearGradientEight};

    svg {
      path {
        stroke: ${colors.white} !important;
      }
    }
    .activity-title,
    .source-text,
    .details-text {
      color: ${colors.white} !important;
    }
  }
  .page-slider .wrapper {
    display: flex;
    overflow: hidden;
    width: 100%;
    justify-content: center;
  }
  .certificate-description {
    font-weight: 500;
    color: ${colors.white} !important;
    position: relative;
    display: flex;
    align-items: center;
    font-size: 18px;
    line-height: 160%;
    max-width: 900px;
  }
  @media ${device.tablet} {
    padding: 40px 30px 86px 30px;
    .title {
      font-size: 30px;
    }
    .title-button-wrapper {
      margin-bottom: 48px;
    }
    .cards {
      margin: 0;
      ${(props) => (props.theme.dir === "rtl" ? "margin-left: 16px;" : "margin-right: 16px;")}
    }
  }
  @media ${device.mobile} {
    padding: 40px 16px 86px 16px;
    .title {
      font-size: 30px;
    }
    .title-button-wrapper {
      margin-bottom: 40px;
    }
    .cards {
      margin: 0;
      width: initial;
      ${(props) => (props.theme.dir === "rtl" ? "margin-left: 16px;" : "margin-right: 16px;")}
    }
  }
`;
