import { API } from "aws-amplify";
import {
  RES_SIGN_IN,
  RES_SIGN_UP,
  RES_AUTH_USER,
  RES_PROFILE,
  RES_VERIFY_EMAIL,
  RES_CHANGE_PASSWORD,
  RES_SKILLS,
} from "&store/resources";
import { LoginParams, SignUpParams, UpdateProfileBody } from "./login.type";

const signInApi = async (body: LoginParams) => {
  let URL = `${RES_SIGN_IN}`;
  return API.post(process.env.REACT_APP_NAME || "", URL, { body });
};

const signUpApi = async (body: SignUpParams) => {
  let URL = `${RES_SIGN_UP}`;
  return API.post(process.env.REACT_APP_NAME || "", URL, { body });
};

const applyForJob = async (body: { jobId: number }) => {
  let URL = `/auth/jobs/apply`;
  const myInit = { 
    body
  }
  return API.post(process.env.REACT_APP_NAME || "", URL, myInit);
};

const getProfileApi = async () => {
  let URL = `${RES_AUTH_USER}/profile`;
  return API.get(process.env.REACT_APP_NAME || "", URL, {});
};

const getSkillsWithoutCareerApi = async () => {
  let URL = `${RES_AUTH_USER}${RES_SKILLS}`;
  return API.get(process.env.REACT_APP_NAME || "", URL, {});
};

const getUserEducationApi = async () => {
  let URL = `${RES_AUTH_USER}/education`;
  return API.get(process.env.REACT_APP_NAME || "", URL, {});
};

const getUserWorkExperienceApi = async () => {
  let URL = `${RES_AUTH_USER}/work-experience`;
  return API.get(process.env.REACT_APP_NAME || "", URL, {});
};

const getUserCoursesApi = async () => {
  let URL = `${RES_AUTH_USER}/courses`;
  return API.get(process.env.REACT_APP_NAME || "", URL, {});
};

const getUserLearningPathsApi = async () => {
  let URL = `${RES_AUTH_USER}/learning-paths`;
  return API.get(process.env.REACT_APP_NAME || "", URL, {});
};

const getUserCertificatesApi = async () => {
  let URL = `${RES_AUTH_USER}/certificates`;
  return API.get(process.env.REACT_APP_NAME || "", URL, {});
};

const getUserSponsorshipsApi = async () => {
  let URL = `${RES_AUTH_USER}/sponsorships`;
  return API.get(process.env.REACT_APP_NAME || "", URL, {});
};

const getUserEligibilityFormApi = async () => {
  let URL = `${RES_AUTH_USER}/eligibility-form`;
  return API.get(process.env.REACT_APP_NAME || "", URL, {});
};

const updateProfileApi = async (body: UpdateProfileBody) => {
  let URL = `${RES_PROFILE}`;
  const myInit = {
    body: body,
  };
  return API.put(process.env.REACT_APP_NAME || "", URL, myInit);
};

const autoAssignCareerApi = async (body: any) => {
  let URL = `/auth/assessments`;
  const myInit = {
    body: body,
  };
  return API.post(process.env.REACT_APP_NAME || "", URL, myInit);
};

const resendVerificationApi = async (body: { email: string }) => {
  let URL = `${RES_VERIFY_EMAIL}`;
  const myInit = {
    body: body,
  };
  return API.post(process.env.REACT_APP_NAME || "", URL, myInit);
};

const changePasswordApi = async (email: string) => {
  let URL = `${RES_CHANGE_PASSWORD}`;
  const myInit = {
    body: {
      email,
    },
  };
  return API.post(process.env.REACT_APP_NAME || "", URL, myInit);
};

export {
  signInApi,
  signUpApi,
  getProfileApi,
  updateProfileApi,
  resendVerificationApi,
  changePasswordApi,
  getUserEducationApi,
  getUserWorkExperienceApi,
  getUserCoursesApi,
  getUserLearningPathsApi,
  getUserCertificatesApi,
  applyForJob,
  getUserSponsorshipsApi,
  getUserEligibilityFormApi,
  getSkillsWithoutCareerApi,
  autoAssignCareerApi,
};
