import styled from "styled-components";
import { device } from "&assets/constants/responsiveness";

export const ProfileAddEligibilityFormWrapper = styled.div`
  padding: 20px 0 0 0;
  .remove-padding {
    padding: 0;
  }
  @media ${device.mobile} {
    padding: 0;
  }
  @media ${device.tablet} {
    padding: 0;
  }
`;
