import React, { useEffect, useState } from "react";
import Collapse from "@mui/material/Collapse/Collapse";

import { LearningPathwayDashboardWrapper } from "./learningPathwayDashboard.styled";
import { Clock, Document } from "&assets/constants/icons";
import Container from "&components/container/container.component";
import LearningHeaderDashboard from "&components/learningHeaderDashboard/learningHeaderDashboard.component";
import {
  LearningPathItem,
  LearningPathwayType,
} from "&features/listingPage/listingPage.type";
import secondsToHours from "&utils/secondstoHours";
import { images } from "&assets/constants/images-urls";
import LearningActivityTracking from "&components/learningActivityTracking/learningActivityTracking.component";
import { translationSelector } from "&utils/translationSelector";
import { useTranslation } from "react-i18next";
import { hideActivityButtons } from "&utils/learningPaths/learningPathsSelectors";

interface Props {
  id?: number;
  learningPathItem: LearningPathItem;
  learningPath?: LearningPathwayType;
  open?: boolean;
  learningActivities?: any;
  progress?: number;
  handleOpenPopup?: any;
  handleApplyVoucher?: (
    event: any,
    item: any,
    type: string,
    learningPathId?: any
  ) => void;
  handleCompletionClick?: (activity: any, item: any, type: string) => void;
}

const LearningPathwayDashboard = (props: Props) => {
  const {
    id,
    learningPath,
    learningActivities,
    progress,
    learningPathItem,
    handleOpenPopup,
    handleApplyVoucher,
    handleCompletionClick,
    open,
  } = props;
  const [opened, setOpened] = useState(false);
  const { t } = useTranslation(["listingPage"]);

  useEffect(() => {
    open && setOpened(open);
  }, [open]);

  const informatics: any = [
    {
      icon: <Document fill="white" />,
      text: learningActivities?.length + ` ${t("LEARNING_ACTIVITY")}`,
    },
    {
      icon: <Clock fill="white" />,
      text: secondsToHours(learningPath?.duration),
    },
  ];

  return (
    <LearningPathwayDashboardWrapper id={id?.toString()}>
      <LearningHeaderDashboard
        id={learningPath?.id}
        learningPathItem={learningPathItem}
        title={translationSelector(learningPath, "name")}
        description={translationSelector(learningPath, "shortDescription")}
        providerName={learningPath?.source}
        image={learningPath?.image || images.learningPathwayImg}
        level={learningPath?.level?.alias}
        informatics={informatics}
        progress={progress}
        sponsorshipType={learningPath?.sponsorshipType}
        isSponsored={learningPath?.sponsored}
        open={opened}
        handleShowDetailsClick={(value: boolean) => setOpened(value)}
      />
      <Collapse in={opened} timeout="auto" unmountOnExit>
        <Container className="dashboard-container">
          <LearningActivityTracking
            learningPathId={learningPath?.id}
            hideActivityButtons={hideActivityButtons(learningPathItem)}
            learningActivities={learningActivities}
            handleApplyVoucher={handleApplyVoucher}
            handleOpenPopup={handleOpenPopup}
            handleCompletionClick={(activity: any, item: any, type: string) => {
              handleCompletionClick &&
                handleCompletionClick(activity, item, type);
            }}
          />
        </Container>
      </Collapse>
    </LearningPathwayDashboardWrapper>
  );
};

export default LearningPathwayDashboard;
