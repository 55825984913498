import React, { useEffect } from "react";
import { CertificationItem } from "&features/dashboard/screens/profile/profile.type";
import {
  FormInputContent,
  UpdateProfileBody,
  UserCertificatesBody,
} from "&features/login/login.type";
import { ProfileModalInnerContent } from "&components/profile/edit/profileEditModel/profileEditModal.styled";
import { getIn, useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { certificatesBodySelector } from "&utils/profile/buildCertificatesBody";
import { useSelector } from "react-redux";
import { RootState } from "&store/store";
import inputSelector from "&utils/ui/inputSelector";
import { useTranslation } from "react-i18next";

interface Props {
  initialValues?: CertificationItem;
  submit?: number;
  deleteButton?: number;
  updateProfile: (body: UpdateProfileBody) => void;
  index?: number;
}
const ProfileAddCertification = (props: Props) => {
  const { initialValues, submit, updateProfile, index, deleteButton } = props;
  const user = useSelector((state: RootState) => state.login.user);
  const { t } = useTranslation(["profile"]);

  const isEdit = !!initialValues;

  const values: CertificationItem = {
    name: initialValues?.name || undefined,
    instituteName: initialValues?.instituteName || undefined,
    issueDate: initialValues?.issueDate || undefined,
    expiryDate: initialValues?.expiryDate || undefined,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required(t("REQUIRED")),
    instituteName: Yup.string().trim().required(t("REQUIRED")),
    issueDate: Yup.date()
      .typeError(t("INVALID_REQUIRED_DATE"))
      .max(moment(), t("ISSUE_DATE_CAN_NOT_BE_IN_THE_FUTURE"))
      .when("expiryDate", {
        is: (expiryDate: boolean) => expiryDate,
        then: Yup.date().max(
          Yup.ref("expiryDate"),
          t("ISSUE_DATE_MUST_BE_LESS_THAN_EXPIRY_DATE")
        ),
      })
      .required(t("REQUIRED")),
    expiryDate: Yup.date()
      .typeError(t("INVALID_DATE"))
      .min(Yup.ref("issueDate"), t("EXPIRY_DATE_MUST_GREATER_THAN_ISSUE_DATE"))
      .notRequired(),
  });

  const handleUpdateAdd = () => {
    if (isEdit) {
      let certificate: UserCertificatesBody[] = user?.userCertificates
        ? [...user?.userCertificates]
        : [];
      certificate[index ?? -1] = certificatesBodySelector(formik.values);
      updateProfile({ userCertificates: certificate });
    } else {
      let certificate: UserCertificatesBody[] = user?.userCertificates
        ? [certificatesBodySelector(formik.values), ...user?.userCertificates]
        : [];
      updateProfile({ userCertificates: certificate });
    }
  };

  const handleDelete = () => {
    let certificate: UserCertificatesBody[] = user?.userCertificates
      ? [...user?.userCertificates]
      : [];
    if (typeof index === "number") {
      // const arraySpliced = certificate?.splice(index, 1);
      updateProfile({ userCertificates: certificate });
    }
  };

  const formik: any = useFormik({
    initialValues: values,
    validationSchema: validationSchema,
    onSubmit: handleUpdateAdd,
  });

  useEffect(() => {
    submit && submit > 0 && formik.handleSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submit]);

  useEffect(() => {
    deleteButton && deleteButton > 0 && handleDelete();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteButton]);

  const certificationInputGrid: FormInputContent[] = [
    {
      id: `name-text-input`,
      label: t("CERTIFICATE_NAME"),
      placeHolder: t("TYPE_YOUR_CERTIFICATE_NAME"),
      type: "text",
      value: formik.values?.name || undefined,
      handleChange: (value) => formik.setFieldValue(`name`, value),
      error: getIn(formik.touched, `name`) && getIn(formik.errors, `name`),
    },
    {
      id: `institute-name-text-input`,
      label: t("INSTITUTE_NAME"),
      placeHolder: t("TYPE_THE_INSTITUTE_NAME"),
      type: "text",
      value: formik.values?.instituteName || undefined,
      handleChange: (value) => formik.setFieldValue(`instituteName`, value),
      error:
        getIn(formik.touched, `instituteName`) &&
        getIn(formik.errors, `instituteName`),
    },
    {
      id: "issueDate-date-picker",
      label: t("ISSUE_YEAR"),
      placeHolder: t("SELECT_FROM_CALENDAR"),
      type: "dateYear",
      max: formik.values?.expiryDate || moment(),
      value: formik.values?.issueDate,
      handleChange: (value) =>
        formik.setFieldValue("issueDate", value ? value : undefined),
      error: formik.touched.issueDate && formik.errors.issueDate,
    },
    {
      id: "expiryDate-date-picker",
      label: t("EXPIRY_YEAR"),
      placeHolder: t("SELECT_FROM_CALENDAR"),
      type: "dateYear",
      min: formik.values?.issueDate,
      value: formik.values?.expiryDate,
      handleChange: (value) =>
        formik.setFieldValue("expiryDate", value ? value : undefined),
      error: formik.touched.expiryDate && formik.errors.expiryDate,
    },
  ];

  return (
    <ProfileModalInnerContent>
      <div className="input-grid">
        {certificationInputGrid?.map(
          (item: FormInputContent, index: number) =>
            !item.hide && inputSelector(item, index)
        )}
      </div>
    </ProfileModalInnerContent>
  );
};

export default ProfileAddCertification;
