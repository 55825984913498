import styled from "styled-components";
import { colors } from "&assets/constants/colors";

export const LevelBannerWrapper = styled.div`
  position: relative;
  width: fit-content;
  height: fit-content;
  margin: auto;

  .level-start-date-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    position: absolute;
    z-index: 2;
    bottom: 15px;
    padding: 0 10px;
    box-sizing: border-box;

    .tier {
      color: white;
      background-color: #87c5e8;
      border-radius: 8px;
      width: fit-content;
      height: 26px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 6px 10px;
      font-family: "Clash Grotesk";
      font-style: normal;
      font-weight: 600;
      font-size: 9px;
      line-height: 150%;
      ${(props) => (props.theme.dir === "rtl" ? "" : "letter-spacing: 0.2em;")}
      text-transform: uppercase;
      box-sizing: border-box;
    }

    .start-date {
      display: flex;
      padding: 6px 10px;
      justify-content: center;
      align-items: center;
      gap: 6px;
      border-radius: 8px;
      background: ${colors.white};
      color: ${colors.primary};
      font-family: Clash Grotesk;
      font-size: 9px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 13.5px */
      letter-spacing: 1.8px;
      text-transform: uppercase;
    }
  }

  .card-img {
    object-fit: fill;
    width: 100%;
    height: 150px;
  }
`;
