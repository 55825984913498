import { colors } from "&assets/constants/colors";
import { device } from "&assets/constants/responsiveness";
import styled from "styled-components";

export const InformativeSectionWrapper = styled.div`
  background: ${colors.primaryDark};
  padding: 40px 30px;
  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  @media ${device.tablet} {
    padding: 40px 70px;
    .container {
      flex-direction: column;
    }
  }

  @media ${device.mobile} {
    padding: 24px 16px;
    .container {
      flex-direction: column;
    }
  }
`;
