import { Level } from "&features/listingPage/listingPage.type";
import { Career } from "&features/landingPage/landingPage.type";
import { map } from "lodash";

const careersSelector = (levels: Level[]) => {
  let careers: Career[] = [];

  map(levels, (level) => {
    careers = careers.concat(level.careers);
  });

  return careers;
};

export default careersSelector;
