/**
 * i18n login namespace
 * Consist of English and arabic translations
 */
export const loginNameSpace = {
  en: {
    LOGIN_PAGE: "Login Page",
    UNSUBSCRIBED_MESSAGE: "Your unsubscribtion request was successfully processed",
    UNSUBSCRIBED_ERROR_MESSAGE: "Your unsubscribtion request was not processed , please try again",
    LOGIN: "Login",
    SIGNUP: "Sign up",
    BACK: "Back",
    NEED_ASSISTANCE_IN_SETTING_UP_AN_EMAIL: "Need assistance setting up an email?",
    DONT_HAVE_AN_EMAIL_NO_PROBLEM_CHECK_OUT_OUR_STEP:
      "Don't have an email? No problem! Check out our step-by-step video tutorial on how to set up an email and get started in no time.",
    NEED_TO_HIRE_NEW_TALENTS: "Need to hire new talents?",
    ENJOY_WATCHING_OUR_TUTORIAL: "Enjoy Watching our Tutorial",
    CONTACT_US: "Contact us",
    VERIFY_YOUR_EMAIL_ADDRESS_IN_ORDER_TO_CONTINUE: "Verify your email address in order to continue!",
    RESEND_VERIFICATION: "Resend verification",
    RESEND_IN: "Resend in",
    BACK_TO_LOGIN: "Back to login",
    EMAIL_ADDRESS: "Email Address*",
    ENTER_YOUR_EMAIL_ADDRESS: "Enter your email address",
    PHONE_NUMBER: "Phone Number*",
    PASSWORD: "Password*",
    ENTER_PASSWORD: "Enter password",
    FORGOT_PASSWORD: "Forgot Password?",
    CONTINUE_WITH_LINKEDIN: "Continue with Linkedin",
    USE_LINKEDIN: "Use Linkedin",
    CONTINUE_WITH_GOOGLE: "Continue with Google",
    USE_GOOGLE: "Use Google",
    OR: "OR",
    FIRST_NAME: "First Name*",
    ENTER_YOUR_FIRST_NAME: "Enter your First Name",
    LAST_NAME: "Last Name*",
    ENTER_YOUR_LAST_NAME: "Enter your Last Name",
    YES_I_UNDERSTAND_AND_AGREE: "Yes, I understand and agree to the nammiskills",
    TERMS_OF_SERVICE: "Terms of Service",
    INCLUDING_THE: "including the",
    PRIVACY_POLICY: "Privacy Policy",
    CREATE_MY_ACCOUNT: "Create my account",
    VIEW_ALL: "View All",
    LOGOUT: "Logout",
    PROFILE: "Profile",
    REQUIRED: "Required",
    INVALID_EMAIL: "Invalid email address",
    NO_NOTIFICATIONS: "No notifications",
    VERIFY_EMAIL_SUCCESS: "Thank you for verifying your email, you can now login.",
    MAINENANCE_MESSAGE_TITLE: "Site Under Renovation.",
    MAINENANCE_MESSAGE_SUBTITLE1: "Excuses for any inconvenience.",
    MAINENANCE_MESSAGE_SUBTITLE2: "We will be back in a couple of weeks.",
    MAINENANCE_MESSAGE_SUBTITLE3: "Please stay tuned.",
    THANKYOU_MESSAGE_TITLE: "Thank you for signing up on nammiskills.",
    THANKYOU_MESSAGE_SUBTITLE1: "You’ll be redirected to your dashboard in a few seconds.",
    THANKYOU_MESSAGE_SUBTITLE2: "If it doesn’t happen automatically, please click",
    THANKYOU_MESSAGE_SUBTITLE3: " here.",
  },
  ar: {
    REQUIRED: "مطلوب",
    UNSUBSCRIBED_ERROR_MESSAGE: "لم تتم معالجة طلب إلغاء الاشتراك الخاص بك، يرجى المحاولة مرة أخرى",
    UNSUBSCRIBED_MESSAGE: "تمت معالجة طلب إلغاء الاشتراك الخاص بك بنجاح",
    INVALID_EMAIL: "البريد الإلكتروني غير صالح",
    LOGIN_PAGE: "تسجيل الدخول",
    LOGIN: "تسجيل الدخول",
    SIGNUP: "إنشاء حساب",
    BACK: "السابق",
    NEED_ASSISTANCE_IN_SETTING_UP_AN_EMAIL: "هل تحتاج إلى مساعدة في إعداد بريد إلكتروني؟",
    DONT_HAVE_AN_EMAIL_NO_PROBLEM_CHECK_OUT_OUR_STEP:
      "ليس لديك بريد إلكتروني؟ لا مشكلة! شاهد الفيديو التّعليمي الخاص بنا وتعلّم كيفية إعداد بريد إلكتروني خطوة بخطوة.",
    NEED_TO_HIRE_NEW_TALENTS: "بحاجة إلى توظيف مواهب جديدة؟",
    ENJOY_WATCHING_OUR_TUTORIAL: "استمتع بمشاهدة الفيديو التّعليمي",
    CONTACT_US: "اتصل بنا",
    VERIFY_YOUR_EMAIL_ADDRESS_IN_ORDER_TO_CONTINUE: "تحقق من بريدك الإلكتروني للمتابعة!",
    RESEND_VERIFICATION: "إعادة إرسال التحقق",
    RESEND_IN: "إعادة الإرسال",
    BACK_TO_LOGIN: "العودة إلى تسجيل الدخول",
    EMAIL_ADDRESS: "*البريد الإلكتروني",
    ENTER_YOUR_EMAIL_ADDRESS: "أدخل عنوان بريدك الالكتروني",
    PHONE_NUMBER: "*رقم الهاتف",
    PASSWORD: "*كلمة السر",
    ENTER_PASSWORD: "أدخل كلمة السر",
    FORGOT_PASSWORD: "هل نسيت كلمة المرور؟",
    CONTINUE_WITH_LINKEDIN: "LinkedIn تسجيل عبر",
    USE_LINKEDIN: " LinkedIn تسجيل",
    CONTINUE_WITH_GOOGLE: "Google تسجيل عبر",
    USE_GOOGLE: "Google تسجيل",
    OR: "أو",
    FIRST_NAME: "*الاسم الأول",
    ENTER_YOUR_FIRST_NAME: "أدخل اسمك الأول",
    LAST_NAME: "*اسم العائلة",
    ENTER_YOUR_LAST_NAME: "أدخل اسم عائلتك",
    YES_I_UNDERSTAND_AND_AGREE: "نعم ، أفهم وأوافق على",
    TERMS_OF_SERVICE: "شروط خدمة",
    INCLUDING_THE: '"نمّي سكيلز" بما في ذلك',
    PRIVACY_POLICY: "سياسة الخصوصية",
    CREATE_MY_ACCOUNT: "إنشاء حسابي",
    VIEW_ALL: "اظهار الكل",
    LOGOUT: "تسجيل الخروج",
    PROFILE: "الملف الشخصي",
    NO_NOTIFICATIONS: "لا إشعارات",
    VERIFY_EMAIL_SUCCESS: "شكرًا لك على تأكيد بريدك الإلكتروني، يمكنك الآن تسجيل الدخول.",
    MAINENANCE_MESSAGE_TITLE: "موقع قيد التجديد ",
    MAINENANCE_MESSAGE_SUBTITLE: "نعتذر عن أي إزعاج، سنعود خلال أسبوعين",
    THANKYOU_MESSAGE_TITLE: "شكرًا لتسجيلك على نمّي سكلز.",
    THANKYOU_MESSAGE_SUBTITLE1: "سيتم إعادة توجيهك إلى لوحة التحكم الخاصة بك خلال ثوانٍ قليلة.",
    THANKYOU_MESSAGE_SUBTITLE2: "إذا لم يحدث ذلك تلقائيًا، يرجى الضغط",
    THANKYOU_MESSAGE_SUBTITLE3: " هنا.",
  },
};
