import { colors } from "&assets/constants/colors";
import { device } from "&assets/constants/responsiveness";
import styled from "styled-components";

interface Props {
  opened: boolean;
  showQuickView: boolean;
}

export const LearningPathwayCardWrapper = styled.div<Props>`
  background: ${colors.linearGradientWhite} padding-box, ${colors.linearGradientOne} border-box;
  border-radius: 12px;
  border: 1px solid transparent;
  width: 100%;
  box-sizing: border-box;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-content: center;
  position: relative;
  margin-bottom: 16px;
  ${(props) => props.opened && "max-width: 1089px;"};
  ${(props) => props.opened && "min-width: 1089px;"};

  .certification {
    position: absolute;
    width: 93px;
    height: 29px;
    background: ${colors.linearGradientOne};

    ${(props) => (props.theme.dir === "rtl" ? "border-radius: 0 8px 8px 0;" : "border-radius: 8px 0 0 8px;")}
    ${(props) => (props.theme.dir === "rtl" ? "left: 0;" : "right: 0;")}
    top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    font-weight: 800;
    font-size: 12px;
    line-height: 120%;
    color: ${colors.white};
    box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.25);
  }
  .before-collapse {
    display: flex;
    flex-direction: row;
    align-content: center;
  }
  .header {
    ${(props) => (props.theme.dir === "rtl" ? "margin-left: 48px;" : "margin-right: 48px;")}
  }
  .image-back {
    position: relative;
    width: fit-content;
    height: fit-content;
    .tier {
      position: absolute;
      z-index: 2;
      color: white;
      bottom: 10px;
      ${(props) => (props.theme.dir === "rtl" ? "right: 10px;" : "left: 10px;")}
      background-color: #87c5e8;
      border-radius: 8px;
      width: fit-content;
      height: 26px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 6px 10px;
      font-family: "Clash Grotesk";
      font-style: normal;
      font-weight: 600;
      font-size: 9px;
      line-height: 150%;
      ${(props) => (props.theme.dir === "rtl" ? "" : "letter-spacing: 0.2em;")}
      text-transform: uppercase;
      box-sizing: border-box;
    }
  }
  .card-img {
    object-fit: contain;
    height: 150px;
    max-width: 294px;
    min-width: 294px;
    border-radius: 10px;
  }
  .collapse-button {
    position: absolute;
    top: 128px;
    ${(props) => (props.theme.dir === "rtl" ? "left: -103px;" : "right: -103px;")}
    ${(props) => (props.theme.dir === "rtl" ? "transform: rotate(-90deg);" : "transform: rotate(90deg);")}
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px 16px;
    height: 45px;
    width: 160px;
    background: ${(props) =>
      props.opened
        ? `linear-gradient(40deg, ${colors.secondary} 40.1%, ${colors.primary} 93.81%)`
        : "rgba(16, 140, 210, 0.3)"};
    border-radius: 16px 16px 0 0;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .collapse-title {
      font-family: "Mulish";
      font-style: normal;
      font-weight: 800;
      font-size: 18px;
      line-height: 160%;
      text-align: center;
      color: ${(props) => (props.opened ? "white" : colors.primary)};
      white-space: nowrap;
    }
    .plus-icon {
      width: 24px;
      height: 24px;
      ${(props) => (props.theme.dir === "rtl" ? "margin-right: 8px;" : "margin-left: 8px;")}

      ${(props) => (props.theme.dir === "rtl" ? "transform: rotate(90deg);" : "transform: rotate(-90deg);")}
      background: ${(props) =>
        props.opened
          ? `${colors.linearGradientWhite}
      padding-box,
    ${colors.linearGradientOne} border-box`
          : `linear-gradient(56.4deg, #ffffff 8.08%, #ffffff 87.11%)
          padding-box,
        ${colors.linearGradientOne} border-box`};
    }
  }
  .card-title {
    color: #2d2a35;
    font-family: "Mulish";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 120%;
    width: 100%;
    margin: 16px 0;
  }
  .card-content {
    display: flex;
    align-items: center;
    .title {
      font-weight: 400;
      font-size: 14px;
      ${(props) => (props.theme.dir === "rtl" ? "margin-right: 10px;" : "margin-left: 10px;")}
      color: #4d525b;
      align-items: center;
      justify-content: center;
      display: flex;
      .text {
        ${(props) => (props.theme.dir === "rtl" ? "margin-right: 6px;" : "margin-left: 6px;")}
      }
    }
    .info {
      ${(props) => (props.theme.dir === "rtl" ? "margin-right: 0;" : "margin-left: 0;")}
      ${(props) => (props.theme.dir === "rtl" ? "margin-left: 20px;" : "margin-right: 20px;")}
    }
  }
  .mx-20 {
    ${(props) => (props.theme.dir === "ltr" ? "margin-right: 20px;" : "margin-left: 0px;")}
    ${(props) => (props.theme.dir === "rtl" ? "margin-left: 20px;" : "margin-right: 0px;")}
  }
  .content {
    display: flex;
    flex-direction: column;
    .pathway-title {
      font-family: "Mulish";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 120%;
      color: #2d2a35;
      margin: 0;
      width: 90%;
    }
    .description {
      font-family: "Mulish";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 160%;
      color: ${colors.dark};
      opacity: 0.7;
      margin: 16px 0 0 0;
      ${(props) => (props.theme.dir === "rtl" ? "padding-left: 50px;" : "padding-right: 50px;")}
    }
    .chips {
      display: flex;
      margin-top: 16px;
      flex-wrap: wrap;
    }
    .explore-button {
      width: fit-content;
    }
    .enroll-button {
      width: fit-content;
      ${(props) => (props.theme.dir === "rtl" ? "margin-left: 10px;" : "margin-right: 10px;")}
    }
  }

  .enroll-btn-div {
    display: flex;
    justify-content: flex-start;
  }
  .quick-view {
    width: 100%;
    position: relative;
    .separator {
      margin: 48px 0;
      width: 100%;
      height: 1px;
      background: ${colors.linearGradientOne};
    }
    .learning-activities {
      background: rgba(16, 140, 210, 0.1);
      border-radius: 0px 0px 16px 16px;
      padding: 32px 80px;
      ${(props) => (props.theme.dir === "rtl" ? "padding-right: 175px;" : "padding-left: 175px;")}
      overflow: hidden;
      .activity {
        opacity: 0.3;
        &:first-child {
          opacity: 1;
        }
        &:last-child {
          height: 107px;
        }
      }
    }
    .explore-now {
      width: 175px;
      height: 45px;
      position: absolute;
      bottom: 3%;
      ${(props) => (props.theme.dir === "rtl" ? "right: 50%;" : "left: 50%;")}

      ${(props) =>
        props.theme.dir === "rtl" ? "transform: translate(50%, -50%);" : "transform: translate(-50%, -50%);"}
    }
  }
  @media ${device.inBetween} {
    min-width: auto;
  }

  @media ${device.tablet} {
    padding: 16px;
    min-width: auto;
    .header {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .card-img {
      object-fit: contain;
      height: 166px;
      width: 326px;
    }
    .content {
      .pathway-title {
        font-family: "Mulish";
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 120%;
        color: #2d2a35;
      }
      .description {
        font-family: "Mulish";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 160%;
        color: ${colors.dark};
        opacity: 0.7;
      }
      .chips {
        display: flex;
        margin-top: 16px;
      }
      .explore-button {
        width: 250px;
        height: 42px;
      }
      .explore-button-text {
        font-size: 16px;
      }
    }
  }
  @media ${device.mobile} {
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-content: center;
    min-width: auto;
    .before-collapse {
      display: flex;
      flex-direction: column;
      align-content: center;
    }
    .header {
      display: flex;
      ${(props) => (props.theme.dir === "rtl" ? "margin-left: 0;" : "margin-right: 0;")}
      flex-direction: column;
      justify-content: center;
    }
    .card-img {
      object-fit: contain;
      height: 166px;
      width: 326px;
    }
    .content {
      .description {
        display: none;
      }
      .chips {
        display: flex;
        margin-bottom: 16px;
        margin-top: 16px;
      }
      .explore-button {
        width: 250px;
        height: 42px;
      }
      .explore-button-text {
        font-size: 16px;
      }
    }
    .collapse-button {
      display: none;
    }
    .quick-view {
      display: none;
    }
    .enroll-btn-div {
      display: block;
      .enroll-button {
        margin-bottom: 10px;
      }
    }
  }
`;
