export const courseDetailsNameSpace = {
  en: {
    CLOSE: 'Close',
    CLOSE_AND_APPLY: 'Apply',
    UPDATE_YOUR_SKILLS: 'Update your skills',
    SKILLS_MISSING: 'Updating Missing Skills for Job Application',
    SAVE_AND_APPLY: 'Save',
    DIFFERENCE_SKILLS: 'Your profile is missing some skills required for this job. To match the job criteria, you can either \'update your skills\' or proceed by clicking \'Apply\'',
    LAUNCH_COURSE: "Launch course",
    ENROLL_NOW: "Enroll now",
    READ_MORE: "Read more",
    READ_LESS: "Read less",
    COURSE_DESCRIPTION: "Course description",
    SKILLS_YOU_WILL_GAIN: "Skills you will gain",
    VIEW_ALL_COURSES: "View all courses",
    MATCHING_OPPORTUNITIES: "Matching opportunities",
    RELATED_LEARNING_ACTIVITIES: "Related learning activities",
    RELATED_LEARNING_PATHWAYS: "Related learning pathways",
    VIEW_ALL_LEARNING_PATHS: "View all learning paths",
    COURSE: "course",
    COMPLETION_PROOF_VALIDATION: "*Your completion proof is under validation",
    COMPLETION_PROOF_REJECTED: "*Your completion proof has been rejected",
    COMPLETION_PROOF_ACCEPTED: "*Your completion proof has been accepted",
    FOR_THE_FOLLOWING_REASON: "for the following reason",
    JOB_DESCRIPTION: 'Job Description',
    REQUIRED_SKILL: 'Required Skills',
    APPLY_NOW: 'Apply Now',
  },
  ar: {
    CLOSE_AND_APPLY: 'قدم',
    SAVE_AND_APPLY: 'حفظ',
    UPDATE_YOUR_SKILLS:'حدث مهاراتك',
    SKILLS_MISSING: 'تحديث المهارات الناقصة لطلب الوظيفة',
    DIFFERENCE_SKILLS: '"يفتقد ملفك الشخصي بعض المهارات المطلوبة لهذه الوظيفة. لمطابقة معايير الوظيفة، إما "حدث مهاراتك" أو المتابعة بالضغط على "تقدم',
    LAUNCH_COURSE: "انطلق بالدورة",
    CLOSE: 'اغلق',
    APPLY_NOW: 'قدم الآن',
    REQUIRED_SKILL: 'المهارات المطلوبة',
    JOB_DESCRIPTION: 'المسمى الوظيفي',
    ENROLL_NOW: "تسجل الآن",
    READ_MORE: "اقرأ المزيد",
    READ_LESS: "اقرأ أقل",
    COURSE_DESCRIPTION: "وصف الدورة",
    SKILLS_YOU_WILL_GAIN: "المهارات التي ستكتسبها",
    VIEW_ALL_COURSES: "عرض جميع الدورات",
    MATCHING_OPPORTUNITIES: "فرص العمل المطابقة",
    RELATED_LEARNING_ACTIVITIES: "النشاطات التعليمية ذات الصلة",
    RELATED_LEARNING_PATHWAYS: "المسارات التعليمية ذات الصلة",
    VIEW_ALL_LEARNING_PATHS: "عرض جميع المسارات التعليمية",
    COURSE: "الدورة التعليمية",
    COMPLETION_PROOF_VALIDATION: "جاري التحقق من اكمالك للدورة التدريبية",
    COMPLETION_PROOF_REJECTED: "لم يتم التأكد من اكمالك للدورة التدريبية",
    COMPLETION_PROOF_ACCEPTED: "لقد تم التأكد من  اكمالك للدورة التدريبية",
    FOR_THE_FOLLOWING_REASON: "للسبب التالي",
  },
};
