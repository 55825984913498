import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ApplyForVoucherWrapper } from "./applyForVoucher.styled";
import SubmitButton from "&components/buttons/submitButton/submitButton.component";
import { useDispatch, useSelector } from "react-redux";
import { courseDetailsActions } from "&features/courseDetails/courseDetails.slice";
import CustomModal from "&components/custom-modal/custom-modal.component";
import HtmlParser from "&components/htmlParser/htmlParser.component";
import { RootState } from "&store/store";
import { loginActions } from "&features/login/login.slice";
import { CompleteOneCourseWrapper } from "&components/learningActivityTracking/learningActivityTracking.styled";
import { ERROR_TEXTS } from "&assets/constants/statics";
import MessageActionModal from "&components/modals/messageActionModal/messageActionModal.component";
import { useTranslation } from "react-i18next";
import { translationSelector } from "&utils/translationSelector";
import i18n from "&config/i18n";

interface Props {
  id?: number;
  type: string;
  sponsorshipType: string;
  sponsored: boolean;
  disabled?: boolean;
  learningPathId?: any;
  handleCloseIntructions?: () => void;
}

const ApplyForVoucher = (props: Props) => {
  const {
    id,
    type,
    sponsorshipType,
    sponsored,
    disabled = false,
    learningPathId,
    handleCloseIntructions,
  } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation(["profile"]);
  const loading = useSelector(
    (state: RootState) => state.courseDetails.loadingVoucher
  );

  const isArabic = i18n.language === "ar";

  const sponsorshipError = useSelector(
    (state: RootState) => state.courseDetails.sponsorshipError
  );
  const user = useSelector((state: RootState) => state.login.user);
  const status = useSelector((state: RootState) => state.courseDetails.status);
  const { instructions, voucher, instructionsAr } = useSelector(
    (state: RootState) => state.courseDetails.voucherResponse
  );
  const [applyVoucherOpen, setApplyVoucherOpen] = useState(false);
  const [showFinishEligibility, setShowFinishEligibility] = useState(false);
  const [close, setClosed] = useState(false);
  const [goToFreeCoursesOpen, setGoToFreeCoursesOpen] = useState(false);

  useEffect(() => {
    if (status === "CLAIM_SPONSORSHIP_SUCCESSFULLY") {
      setApplyVoucherOpen(true);
      dispatch(courseDetailsActions.setCourseDetails({ status: "" }));
      dispatch(loginActions.getProfileThunk({}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    if (sponsorshipError === "COMPLETE_AT_LEAST_ONE_COURSE") {
      setGoToFreeCoursesOpen(true);
      dispatch(courseDetailsActions.setCourseDetails({ sponsorshipError: "" }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sponsorshipError]);

  useEffect(() => {
    close && setApplyVoucherOpen(false);
    setClosed(false);
  }, [close]);

  const voucherModalDescription = (instructions: string) => {
    const voucherString = voucher || "";

    // eslint-disable-next-line no-template-curly-in-string
    return instructions.replaceAll("${voucher.code}", voucherString);
  };

  const handleApplyClick = (event: any) => {
    event?.stopPropagation();
    if (
      !user?.eligibilityForm ||
      (user?.eligibilityForm && !user?.eligibilityForm?.approved) ||
      user?.isEQ2
    ) {
      setShowFinishEligibility(true);
    } else {
      dispatch(
        courseDetailsActions.claimSponsorship({
          id: id?.toString() || "",
          type: type,
          sponsorshipType,
          learningPathId:
            type === "exams" && learningPathId ? learningPathId : undefined,
        })
      );
    }
  };

  const completeOneCourseContent = () => {
    return (
      <CompleteOneCourseWrapper className="description-error-wrapper">
        <p className="description-error-text">
          {translationSelector(ERROR_TEXTS, "COMPLETE_AT_LEAST_ONE_COURSE")}
        </p>
        <SubmitButton
          id={"redirect-to-free-courses-button"}
          title={"View list of our free courses"}
          handleClick={() =>
            navigate("/listing-page?learning_activity=courses&type=free")
          }
        />
      </CompleteOneCourseWrapper>
    );
  };

  return (
    <ApplyForVoucherWrapper>
      {!sponsored && !disabled && sponsorshipType && (
        <SubmitButton
          title={
            sponsorshipType === "voucher"
              ? t("APPLY_FOR_VOUCHER")
              : t("APPLY_FOR_SPONSORSHIP")
          }
          id={`apply-voucher-button`}
          handleClick={(event: any) => {
            handleApplyClick(event);
          }}
          loading={loading}
          className="apply-voucher-button"
          titleClassName="apply-voucher-button-title"
        />
      )}
      <MessageActionModal
        open={showFinishEligibility}
        handleClose={() => {
          setShowFinishEligibility(false);
        }}
        modalClassName={"voucher-modal"}
        content={translationSelector(ERROR_TEXTS, "FILL_ELIGIBILITY_FORM")}
        actionButtonTitle={"Go to Eligibility Form"}
        onActionButtonClick={() => navigate("/dashboard/profile")}
      />
      <CustomModal
        handleClose={() => {
          setClosed(true);
          setApplyVoucherOpen(false);
          setGoToFreeCoursesOpen(false);
          handleCloseIntructions && handleCloseIntructions();
        }}
        open={applyVoucherOpen || goToFreeCoursesOpen}
        className="voucher-modal"
      >
        {applyVoucherOpen ? (
          <HtmlParser
            description={voucherModalDescription(
              isArabic && instructionsAr ? instructionsAr : instructions
            )}
          ></HtmlParser>
        ) : (
          completeOneCourseContent()
        )}
      </CustomModal>
    </ApplyForVoucherWrapper>
  );
};

export default ApplyForVoucher;
