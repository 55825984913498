import styled from "styled-components";
import { TextField } from "@mui/material";
import { device } from "&assets/constants/responsiveness";
import { colors } from "&assets/constants/colors";

export const CalendarInputWrapper = styled.div`
  .calendar-icon-button {
    cursor: pointer;
    padding: 0;
    display: flex;
    background: none;
    border: none;
  }
  .input-width {
    border-radius: 10px;
    box-sizing: border-box;
    border: 1px solid transparent;
    height: 50px;
    background: linear-gradient(56.4deg, #ffffff, #ffffff) padding-box, ${colors.linearGradientSixth} border-box;
    padding-left: 14px;
    width: 100%;
    padding: 12px 16px !important;
    opacity: 1;
    outline: none;
  }
  .react-datepicker-wrapper {
    width: 100%;
  }
`;

interface Props {
  valid?: boolean;
}

export const CustomTextInput = styled(TextField)<Props>`
  box-sizing: border-box;
  width: 100%;

  .MuiFormLabel-root {
    &.Mui-focused {
      display: none;
    }

    &.MuiInputLabel-root {
      font-weight: 500;
      font-size: 16px;
      line-height: 160%;
      color: ${colors.dark};
      opacity: 0.7;
      top: -4px;
      width: 100%;
    }

    &.MuiFormLabel-filled {
      display: none;
    }
  }

  .MuiInputBase-input {
    box-sizing: border-box;
    height: 50px;
  }

  .MuiOutlinedInput-notchedOutline {
    display: none;
  }

  .MuiInputBase-root {
    border-radius: 10px;
    box-sizing: border-box;
    border: 1px solid transparent;
    max-height: 50px;
    background: ${colors.linearGradientWhite} padding-box,
      ${(props) => (props.valid ? colors.errorColor : colors.linearGradientOne)} border-box;

    ${(props) => (props.theme.dir === "rtl" ? "padding-left: 14px;" : "padding-right: 14px;")}
  }

  &:before {
    border: 0 !important;
  }

  &:after {
    border: 0 !important;
  }

  @media ${device.tablet}, ${device.mobile} {
    height: 46px;

    .MuiFormLabel-root {
      &.MuiInputLabel-root {
        font-weight: 400;
        font-size: 14px;
      }
    }

    .MuiInputBase-root {
      max-height: 46px;
    }
  }
`;
