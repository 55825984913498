import React, { useEffect } from "react";
import * as Yup from "yup";
import { getIn, useFormik } from "formik";

import { BuildProfileStepThreeWrapper } from "./buildProfileStepThree.styled";
import { FormInputContent, NationalityFormValues } from "&features/login/login.type";
import CircularLoader from "&components/circularLoader/circularLoader.component";
import { FormSectionInputWrapper } from "&components/profile/buildProfileStepOne/buildProfileStepOne.styled";
import inputSelector from "&utils/ui/inputSelector";
import { NationalityRequest } from "&features/dashboard/screens/profile/profile.type";
import { disabilityOptions } from "&assets/constants/profile";

import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { loginActions } from "&features/login/login.slice";
import toast from "react-hot-toast";
import { translationSelector } from "&utils/translationSelector";

interface Props {
  initialValues?: NationalityFormValues;
  countries?: any;
  submit?: number;
  className?: string;
  loading?: boolean;
}

const NationalityFormComponent = (props: Props) => {
  const dispatch = useDispatch();
  const { submit, loading, className = "", countries, initialValues } = props;

  const { t } = useTranslation(["profile"]);

  const values: NationalityFormValues = {
    nationality: initialValues?.nationality || undefined,
    disability: initialValues?.disability || undefined,
    disabilityDescription: initialValues?.disabilityDescription || undefined,
  };

  const handleSubmit = async () => {
    try {
      const body: NationalityRequest = {
        nationalityId: formik?.values?.nationality?.id || undefined,

        disability: !!(formik?.values?.disability && formik?.values?.disability === 1),
        disabilityDescription: formik.values?.disabilityDescription || undefined,
      };

      dispatch(loginActions.updateProfileNationality(body));

      dispatch(
        loginActions.setUser({
          nationalityId: body.nationalityId,
          disability: body.disability,
          disabilityDescription: body.disabilityDescription,
        })
      );
      toast.success(
        translationSelector(
          {
            name: "Personal Information updated successfully",
            nameAr: "تم تحديث المعلومات الشخصية بنجاح",
          },
          "name"
        )
      );
      return;
    } catch (e) {
      console.log("e", e);
    }
  };

  const validationSchema = Yup.object().shape({
    disability: Yup.number().required(t("REQUIRED")),
    disabilityDescription: Yup.string().when("disability", {
      is: 1,
      then: Yup.string().trim().required(t("REQUIRED")),
    }),
    nationality: Yup.object()
      .required(t("REQUIRED"))
      .test(
        "is-different-country",
        t("NATIONALITY_MUST_BE_DIFFERENT_THAN_RESIDENCE_COUNTRY"),
        function (value: any, context: any) {
          const residenceType = context.parent.residenceType;
          const residenceName = context.parent.residence?.name;
          const nationalityName = context.parent.nationality?.name;
          if (residenceName && nationalityName && residenceType) {
            return !(residenceType === 2 && residenceName === nationalityName);
          }
          return true;
        }
      ),
  });

  const formik: any = useFormik({
    initialValues: values,
    validationSchema: validationSchema,
    onSubmit: () => handleSubmit(),
  });

  useEffect(() => {
    submit && submit > 0 && formik.handleSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submit]);

  const loadingComponent = (
    <div className="loader-wrapper">
      <CircularLoader />
    </div>
  );

  const sponsorshipInputGrid: FormInputContent[] = [
    {
      id: "nationality-select",
      label: t("NATIONALITY"),
      placeHolder: t("SELECT_FROM_THE_LIST"),
      type: "select",
      options: countries,
      value: formik.values?.nationality,
      handleChange: (value) => formik.setFieldValue("nationality", value),
      disabled: false,
      error: formik.touched.residence && formik.errors.nationality,
    },
  ];

  return (
    <BuildProfileStepThreeWrapper className={className}>
      {loading ? (
        loadingComponent
      ) : (
        <FormSectionInputWrapper>
          <div className="">
            <div className="sponsorship-input-column" style={{ marginBottom: "10px" }}>
              {sponsorshipInputGrid.map((item: FormInputContent, index: number) => inputSelector(item, index))}
            </div>
            <div className="sponsorship-input-column">
              {inputSelector(
                {
                  id: "disability-radio-input",
                  options: disabilityOptions,
                  label: t("DO_YOU_HAVE_ANY_DISABILITIES"),
                  type: "radio",
                  showAll: true,
                  disabled: false,
                  value: formik?.values?.disability,
                  handleChange: (value) => formik.setFieldValue(`disability`, value),
                  error: getIn(formik.touched, `disability`) && getIn(formik.errors, `disability`),
                },
                0
              )}
              {formik?.values?.disability === 1 &&
                inputSelector(
                  {
                    id: "disability-description-text-input",
                    label: t("PLEASE_SPECIFY"),
                    type: "text",
                    disabled: false,
                    value: formik?.values?.disabilityDescription,
                    handleChange: (value) => formik.setFieldValue(`disabilityDescription`, value),
                    error:
                      getIn(formik.touched, `disabilityDescription`) && getIn(formik.errors, `disabilityDescription`),
                  },
                  1
                )}
            </div>
          </div>
        </FormSectionInputWrapper>
      )}
    </BuildProfileStepThreeWrapper>
  );
};

export default NationalityFormComponent;
