import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { PrivacyPolicy } from "./privacyPolicy.type";
import {getPagesContentApi} from "&features/landingPage/landingPage.api";

const initialState: PrivacyPolicy = {
  content: undefined,
  loadingContent: false,
};

const getPageContent = createAsyncThunk(
  "privacyPolicy/getPageContent",
  async (_, { rejectWithValue, getState, dispatch }: any) => {
    try {
      const content = await getPagesContentApi('privacy');
      return content?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const privacyPolicySlice = createSlice({
  name: "privacyPolicy",
  initialState: initialState,
  reducers: {
    setPrivacyPolicy: (state, action) => {
      return { ...state, ...action.payload };
    },
    reset: () => initialState,
  },

  extraReducers: (builder) => {
    builder.addCase(getPageContent.pending, (state, action) => {
      state.loadingContent = true;
    });
    builder.addCase(getPageContent.fulfilled, (state, action) => {
      state.loadingContent = false;
      state.content = action.payload
    });
    builder.addCase(getPageContent.rejected, (state, action) => {
      state.loadingContent = false;
    });
  },
});

export const privacyPolicyReducer = privacyPolicySlice.reducer;

export const privacyPolicyActions = { ...privacyPolicySlice.actions, getPageContent };
