import { colors } from "&assets/constants/colors";
import styled from "styled-components";

export const TextLineWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 18px;
  align-items: center;
  .line {
    border: 1px solid ${colors.primary};
    width: 100%;
    height: 0;
  }
  .text {
    z-index: 1;
    ${(props) =>
            props.theme.dir === "rtl"
                    ? "margin-left: 21px;"
                    : "margin-right: 21px;"}
    white-space: nowrap;
    font-weight: 800;
    font-size: 12px;
    line-height: 160%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: ${colors.primary};
  }
`;
