import styled from "styled-components";
import { device } from "&assets/constants/responsiveness";
import { colors } from "&assets/constants/colors";

export const AssessmentWrapper = styled.div`
  background: ${colors.primaryLightGradient};
  overflow: hidden;
  position: relative;
  padding-bottom: 102px;

  .padding-container {
    padding: 0 102px;
    box-sizing: border-box;
  }

  .mobile-container {
  }

  .blurred-circle {
    position: absolute;
    width: 479px;
    height: 479px;
    border-radius: 240px;
    right: -16px;
    top: 170px;
    z-index: -1;
    background: ${colors.linearGradientOne};
    filter: blur(65px);
  }

  .buttons-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
  }

  @media ${device.tablet} {
    .padding-container {
      padding: 0 50px;
    }

    .mobile-container {
      padding: 0 30px;
    }

    .blurred-circle {
      display: none;
    }
  }
  @media ${device.mobile} {
    .padding-container {
      padding: 0;
    }

    .mobile-container {
      padding: 0 16px;
    }

    .blurred-circle {
      display: none;
    }
  }
`;

interface ContentWrapperProps {
  isWhite?: boolean;
}

export const ContentWrapper = styled.div<ContentWrapperProps>`
  background: ${(props) => (props.isWhite ? colors.white : "rgba(255, 255, 255, 0.9)")};
  border: 1px solid #d6ddeb;
  border-radius: 16px;

  .content-padding {
    padding: 48px 75px;
  }

  @media ${device.tablet} {
    .content-padding {
      padding: 0;
    }
    border-radius: 0px;
    padding: 40px 30px;
  }

  @media ${device.mobile} {
    .content-padding {
      padding: 16px;
    }
    border-radius: 0px;
    margin-top: 40px;
    padding: 40px 0;
  }
`;
