import React from "react";

import SubmitButton from "&components/buttons/submitButton/submitButton.component";
import LayeredImage from "&components/layeredImage/layeredImage.component";
import { BannerWrapper } from "./banner.styled";
import CloseIconButton from "&components/closeIcon/closeIcon.component";
import { XIconBig } from "&assets/constants/icons";
import { useMediaQuery } from "react-responsive";
import { size } from "&assets/constants/responsiveness";
import { isEmpty } from "lodash";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
interface Props {
  title?: string;
  description?: string;
  image?: any;
  buttonText?: string;
  handleButtonClick?: Function;
  backgroundColor?: string;
  color?: string;
  direction?: string;
  width?: string;
  expandBtn?: boolean;
  expandFunc?: any;
  expanded?: boolean;
  onCloseButtonClick?: () => void;
  descriptionHyperlink?: string;
}

const Banner = (props: Props) => {
  const {
    title,
    width,
    description,
    image,
    backgroundColor = undefined,
    color = undefined,
    direction = undefined,
    buttonText = "",
    handleButtonClick,
    expandBtn,
    expandFunc,
    expanded,
    onCloseButtonClick,
    descriptionHyperlink,
  } = props;
  const { t } = useTranslation(["landingPage"]);

  const navigate = useNavigate();

  const isMobile = useMediaQuery({ maxWidth: size.mobile });
  const isTablet = useMediaQuery({
    minWidth: size.mobile,
    maxWidth: size.tablet,
  });

  return (
    <BannerWrapper width={width} direction={direction} color={color} backgroundColor={backgroundColor}>
      <div className="banner-info">
        {title && <p className="banner-title">{title}</p>}
        {description && (
          <p className="banner-description">
            {description}

            {descriptionHyperlink && (
              <span
                onClick={() => {
                  navigate(descriptionHyperlink);
                }}
                className="cursor-pointer"
                style={{
                  textDecoration: "underline",
                }}
              >
                {t("HERE")}
              </span>
            )}
          </p>
        )}

        {expandBtn && (
          <Typography className="see-more-less" paddingBottom={3} color="InfoText" onClick={expandFunc} fontSize={11}>
            {expanded ? t("READ_LESS") : t("READ_MORE")}
          </Typography>
        )}
        {!isEmpty(buttonText) && (
          <SubmitButton
            id="explore-nammiskills-btn"
            title={buttonText}
            handleClick={() => handleButtonClick && handleButtonClick()}
            className="explore-nammiskills-btn"
            titleClassName="explore-nammiskills-btn-text"
          />
        )}
      </div>
      <div className="banner-background">
        {!isMobile && !isTablet && image && (
          <LayeredImage src={image} wrapperClassName="banner-image" imageClassName="banner-image" />
        )}
        <div className="close-button">
          {onCloseButtonClick !== undefined && (
            <CloseIconButton className="close-circle" onClick={onCloseButtonClick} icon={XIconBig(10)} />
          )}
        </div>
      </div>
    </BannerWrapper>
  );
};

export default Banner;
