import React, { useState } from "react";
import { MatchingCareersCardsWrapper } from "./matchingCareersCards.styled";
import MatchingCareerCard from "&components/matchingCareersCards/matchingCareerCard/matchingCareerCard.component";
import { MatchingCareers } from "&features/careerMatching/careerMatching.type";
import { useTranslation } from "react-i18next";

interface Props {
  matchingCareers: MatchingCareers;
  onSelectCareer: (careerId: number, name: string) => void;
  selectedId?: number;
  isSmall?: boolean;
  isEQ1?: boolean;
}

const MatchingCareersCards = (props: Props) => {
  const {
    matchingCareers,
    onSelectCareer,
    selectedId,
    isEQ1,
    isSmall = false,
  } = props;

  const { t } = useTranslation(["careerMatching"]);

  const [disabled, setDisabled] = useState(false);

  const handleClick = (id: number, name: string) => {
    !disabled && id !== selectedId && setDisabled(true);
    id && onSelectCareer(id, name);
  };

  const renderTextLine = (text: string) => {
    return (
      <div className="text-line-wrapper">
        <div className="text">{text}</div>
        <div className="line" />
      </div>
    );
  };

  return (
    <MatchingCareersCardsWrapper isSmall={isSmall}>
      <div className="selected-career-wrapper">
        {renderTextLine(t("YOUR_SELECTED_CAREER"))}
        {matchingCareers?.career && (
          <MatchingCareerCard
            onClick={() =>
              !disabled &&
              matchingCareers?.career?.id &&
              handleClick(
                matchingCareers?.career?.id,
                matchingCareers?.career?.name
              )
            }
            disabled={disabled}
            selectedCareer={matchingCareers?.career?.id === selectedId}
            careerMatch={matchingCareers?.career}
            onEnd={() => setDisabled(false)}
            isSmall={isSmall}
          />
        )}
      </div>
      {!isEQ1 && (
        <div className="recommended-careers-text-wrapper">
          {renderTextLine(t("OUR_RECOMMENDED_CAREERS"))}
          <div className="recommended-careers-wrapper">
            {matchingCareers?.suggestions &&
              matchingCareers?.suggestions?.length > 0 &&
              matchingCareers?.suggestions?.map((career, index) => (
                <MatchingCareerCard
                  width={isEQ1 ? 100 : null}
                  key={index}
                  onClick={() =>
                    !disabled && handleClick(career.id, career.name)
                  }
                  selectedCareer={career.id === selectedId}
                  className={"career-card-margin"}
                  disabled={disabled}
                  careerMatch={career}
                  onEnd={() => setDisabled(false)}
                  isSmall={isSmall}
                />
              ))}
          </div>
        </div>
      )}
    </MatchingCareersCardsWrapper>
  );
};

export default MatchingCareersCards;
