import { loginNameSpace } from "&features/login/login.i18n";
import { landingPageNameSpace } from "&features/landingPage/landingPage.i18n";
import { learningPathwayNameSpace } from "&features/learningPathway/learningPathway.i18n";
import { futureCareersNameSpace } from "&features/futureCareers/futureCareers.i18n";
import { aboutSpace } from "&features/about/about.i18n";
import { dashboardNameSpace } from "&features/dashboard/dashboard.i18n";
import { listingPageNameSpace } from "&features/listingPage/listingPage.i18n";
import { assessmentNameSpace } from "&features/assessment/assessment.i18n";
import { careerMatchingNameSpace } from "&features/careerMatching/careerMatching.i18n";
import { privacyPolicyNameSpace } from "&features/privacyPolicy/privacyPolicy.i18n";
import { termsConditionsNameSpace } from "&features/termsConditions/termsConditions.i18n";
import { frequentlyAskedQuestionsNameSpace } from "&features/frequentlyAskedQuestions/frequentlyAskedQuestions.i18n";
import { careerDetailsNameSpace } from "&features/careerDetails/careerDetails.i18n";
import { courseDetailsNameSpace } from "&features/courseDetails/courseDetails.i18n";
import { profileNameSpace } from "&features/dashboard/screens/profile/profile.i18n";
import { opportunitiesListingNameSpace } from "&features/opportunitiesListing/opportunitiesListing.i18n";
import { contactUsNameSpace } from "&features/contactUs/contactUs.i18n";
import { notificationsNameSpace } from "&features/notifications/notifications.i18n";
import { blogsNameSpace } from "&features/blogs/blogs.i18n";
import { bundlesNameSpace } from "&features/bundles/bundles.i18n";
import { examDetailsNameSpace } from "&features/examDetails/examDetails.i18n";
import { certificationsNameSpace } from "&features/certifications/certifications.i18n";
/**
 * English translation resources.
 * Each object correspond to a namespace related to a feature.
 */
let en = {
  login: loginNameSpace.en,
  landingPage: landingPageNameSpace.en,
  learningPathway: learningPathwayNameSpace.en,
  futureCareers: futureCareersNameSpace.en,
  about: aboutSpace.en,
  dashboard: dashboardNameSpace.en,
  listingPage: listingPageNameSpace.en,
  assessment: assessmentNameSpace.en,
  careerMatching: careerMatchingNameSpace.en,
  privacyPolicy: privacyPolicyNameSpace.en,
  termsConditions: termsConditionsNameSpace.en,
  frequentlyAskedQuestions: frequentlyAskedQuestionsNameSpace.en,
  careerDetails: careerDetailsNameSpace.en,
  courseDetails: courseDetailsNameSpace.en,
  profile: profileNameSpace.en,
  opportunitiesListing: opportunitiesListingNameSpace.en,
  contactUs: contactUsNameSpace.en,
  notifications: notificationsNameSpace.en,
  blogs: blogsNameSpace.en,
  bundles: bundlesNameSpace.en,
  examDetails: examDetailsNameSpace.en,
  certifications: certificationsNameSpace.en,
};

export default en;
