import React from "react";

import { MatchingOpportunitiesCardWrapper } from "./matchingOpportunitiesCard.styled";
import {
  ArrowForwardIcon,
  Clock,
  LocationIcon,
  Company,
} from "&assets/constants/icons";
import SubmitButton from "&components/buttons/submitButton/submitButton.component";
import CloseIconButton from "&components/closeIcon/closeIcon.component";
import { useNavigate } from "react-router-dom";
import { RootState } from "&store/store";
import { useSelector } from "react-redux";
import { sourceLogos } from "&assets/constants/statics";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";

interface Props {
  id?: number;
  url?: string;
  title?: string;
  level?: string;
  description?: string;
  provider?: string;
  skills?: any;
  percentage?: string;
  buttonText?: string;
  handleButtonClick?: Function;
  location?: string;
  company?: string;
  date?: string;
  external?: boolean;
}

const MatchingOpportunitiesCard = (props: Props) => {
  const {
    id,
    url,
    title,
    // level,
    // description,
    provider,
    // skills = [],
    percentage,
    // buttonText = "More details",
    // handleButtonClick,
    location,
    company,
    date,
    external,
  } = props;
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state: RootState) => state.login.isLoggedIn);
  const { t } = useTranslation(["futureCareers"]);
  const { loginWithRedirect } = useAuth0();
  const handleCardClick = () => {
    if (isLoggedIn) {
      if (external) {
        window.open(url, "_blank", "noreferrer");
      } else {
        navigate(`/opportunity-details/${id}`);
      }
    } else {
      loginWithRedirect();
    }
  };
  return (
    <MatchingOpportunitiesCardWrapper
      onClick={(e: any) => {
        e.stopPropagation();
        handleCardClick();
      }}
    >
      <div className="content">
        <div className="popups">
          <CloseIconButton
            className="logo-icon"
            onClick={() => {}}
            icon={
              <img
                src={sourceLogos[provider?.toLowerCase() || ""]}
                alt="plus-icon"
                className="logo"
              />
            }
          />
          {percentage && (
            <div className="score-matching">
              <span className="percentage">{percentage}</span>
              <span className="text">Matching</span>
            </div>
          )}
        </div>
        <div className="title-wrapper">
          <p title={title} className="pathway-title">
            {title}
          </p>
        </div>

        {/* <div className="provider-content">
          <SourceLogo source={provider?.toLowerCase()} />
          <p className="title">{provider}</p>
        </div> */}
        {/* {description && <p className="description">{description}</p>} */}

        {/* {company && (
          <div className="info-external">
            <span className="info-tag">Company: </span>
            <p className="description-external">{company}</p>
          </div>
        )}
        {location && (
          <div className="info-external">
            <span className="info-tag">Location: </span>
            <p className="description-external">{location}</p>
          </div>
        )}
        {date && (
          <div className="info-external">
            <span className="info-tag">Date: </span>
            <p className="description-external">{date}</p>
          </div>
        )} */}

        {/* {skills && skills.length > 0 && (
          <SkillsList skills={skills}></SkillsList>
        )} */}

        {/* {level && type && salary && (
          <div className="card-content">
            <p className="title info">
              <img src={UserIcon} alt={title} />{" "}
              <span className="text">{level}</span>
            </p>
            <p className="title info">
              <img src={BriefcaseIcon} alt={title} />{" "}
              <span className="text">{type}</span>
            </p>
            <p className="title info">
              <img src={DollarIcon} alt={title} />{" "}
              <span className="text">{salary}</span>
            </p>
          </div>
        )} */}

        <div className="details-wrapper">
          <div className="card-row">
            {company && (
              <>
                <div className="info-tag">
                  <Company size="18px" />
                </div>
                <p title={company} className="description-external">
                  {company}
                </p>
              </>
            )}
          </div>

          <div className="card-row">
            {location && (
              <>
                <div className="info-tag">
                  <LocationIcon />
                </div>
                <p title={location} className="description-external">
                  {location}
                </p>
              </>
            )}
          </div>

          <div className="card-row">
            {date && (
              <>
                <div className="info-tag">
                  <Clock />
                </div>
                <p className="description-external">
                  {moment(date).format("DD-MM-yyyy")}
                </p>
              </>
            )}
          </div>
        </div>
        <SubmitButton
          title={t("MORE_DETAILS")}
          id="more-details-id"
          handleClick={(event: any) => {
            event.stopPropagation();
            handleCardClick();
          }}
          icon={ArrowForwardIcon("#FFFFFF")}
          className="more-details"
          titleClassName="more-details-text"
        />
      </div>
    </MatchingOpportunitiesCardWrapper>
  );
};

export default MatchingOpportunitiesCard;
