import React, { useEffect, useState } from "react";
import { connect, ConnectedProps, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useNavigate, useParams } from "react-router-dom";
import { isEmpty } from "lodash";

import { RootState } from "&store/store";
import { examDetailsActions } from "./examDetails.slice";
import { ExamDetailsWrapper } from "./examDetails.styled";
import LearningHeader from "&components/learningHeader/learningHeader.component";
import Chip from "&components/chip/chip.component";
import MatchingOpportunities from "&components/matchingOpportunities/matchingOpportunities.component";
import ActionButton from "&components/buttons/actionButton/actionButton.component";
import { size } from "&assets/constants/responsiveness";
import Container from "&components/container/container.component";
import { CourseSkill } from "&features/listingPage/listingPage.type";
import { Clock, Document } from "&assets/constants/icons";
import secondsToHours from "&utils/secondstoHours";
import CircularLoader from "&components/circularLoader/circularLoader.component";
import HtmlParser from "&components/htmlParser/htmlParser.component";
import { opportunitiesListingActions } from "&features/opportunitiesListing/opportunitiesListing.slice";
import getCDNProtectedURL from "&utils/getCDNProtectedImageSource";
import { images } from "&assets/constants/images-urls";
import { LINKEDIN_URL } from "&assets/constants/statics";
import { checkIFLPCompleted } from "&utils/checkIfEnrolled";
import { translationSelector } from "&utils/translationSelector";
import LearningPathway from "&components/learningPathway/learningPathway.component";
import { useTranslation } from "react-i18next";

type ReduxProps = ConnectedProps<typeof connector>;
type Props = {
  auth?: boolean;
};

const ExamDetailsComponent = (props: ReduxProps & Props) => {
  const {
    getExamById,
    loading,
    selectedExam,
    jobs,
    getJobs,
    loadingJobs,
    selectedCountry,
    auth,
    learningPath,
    getExamsLearningPath,
  } = props;
  const navigate = useNavigate();

  const { t } = useTranslation(["examDetails"]);

  const user = useSelector((state: RootState) => state.login.user);
  const isMobile = useMediaQuery({ maxWidth: size.mobile });
  const [readMore, setReadMore] = useState(false);

  const { id, learningPathId } = useParams();

  const [
    ,
    // isLPCompleted
    setIsLPCompleted,
  ] = useState(false);
  useEffect(() => {
    setIsLPCompleted(
      checkIFLPCompleted(
        learningPathId ? parseInt(learningPathId) : undefined,
        user
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const informatics: any = [
    {
      icon: <Document fill="white" />,
      text: selectedExam?.language?.toUpperCase(),
    },
    {
      icon: <Clock fill="white" />,
      text: secondsToHours(selectedExam?.duration),
    },
  ];

  useEffect(() => {
    if (id) {
      getExamById({ id: id, learningPathId: learningPathId });
      getExamsLearningPath({ examId: id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, localStorage.getItem("language")]);

  useEffect(() => {
    getJobs({ countryId: selectedCountry.value, page: 1, limit: 9 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountry, localStorage.getItem("language")]);

  const loadingComponent = (
    <div className="loader-wrapper">
      <CircularLoader />
    </div>
  );

  // const showSponsored =
  //   selectedExam?.sponsorshipType &&
  //   selectedExam?.sponsorshipType === "subscription"
  //     ? !isEmpty(user?.sponsorships) &&
  //       !isEmpty(user?.sponsorships[selectedExam?.source])
  //     : false;

  const isLinkedInTracked =
    selectedExam?.source === "LinkedIn" &&
    !isEmpty(user?.sponsorships) &&
    !isEmpty(user?.sponsorships["LinkedIn"]);
  return (
    <ExamDetailsWrapper>
      {loading ? (
        loadingComponent
      ) : (
        <>
          <LearningHeader
            title={selectedExam?.name}
            description={selectedExam?.shortDescription}
            handleBackClick={() => navigate(-1)}
            providerName={selectedExam?.source}
            image={
              selectedExam?.image ||
              getCDNProtectedURL(images.learningPathwayImg)
            }
            hasButton={false}
            buttonText="Launch Exam"
            handleButtonClick={() => {
              window.open(
                isLinkedInTracked
                  ? LINKEDIN_URL + translationSelector(selectedExam, "url")
                  : translationSelector(selectedExam, "url"),
                "_blank",
                "noreferrer"
              );
            }}
            level={selectedExam?.level?.alias}
            informatics={informatics}
            type="exam"
            // buttonComponent={
            //   auth && isLPCompleted &&
            //   selectedExam?.sponsorshipType &&
            //   !!(selectedExam?.sponsored) &&
            //   selectedExam?.sponsorshipDetails.length === 0 &&
            //   !selectedExam.completed && (
            //     <ApplyForVoucher
            //       id={selectedExam.id}
            //       type="exams"
            //       sponsorshipType={selectedExam?.sponsorshipType}
            //       sponsored={showSponsored}
            //       learningPathId={learningPathId}
            //     />
            //   )
            // }
          />
          <Container>
            <div className="course-details">
              <p className="header">Exam description</p>
              <div className={`content-text ${readMore ? "expand" : ""}`}>
                {!readMore && (
                  <div className={`layer ${readMore ? "expand" : ""}`} />
                )}
                <HtmlParser
                  className="description-content"
                  description={selectedExam?.description}
                />
              </div>
              {isMobile && (
                <ActionButton
                  className="read-more"
                  titleClassName="read-more-title"
                  title={readMore ? "Read less" : "Read more"}
                  handleClick={() => setReadMore(!readMore)}
                />
              )}
              <p className="header">Skills you will gain</p>
              <div className="chips">
                {selectedExam?.examSkills?.map(
                  (item: CourseSkill, index: number) => {
                    return <Chip title={item?.skills?.name} key={index}></Chip>;
                  }
                )}
              </div>
            </div>
          </Container>
          {learningPath && learningPath.length > 0 && (
            <LearningPathway
              title={t("RELATED_LEARNING_PATHS")}
              allButtonText={t("VIEW_ALL_LEARNING_PATHS")}
              pathways={learningPath}
              imgBg
            />
          )}
          {!auth && (
            <MatchingOpportunities
              data={jobs?.items || jobs}
              loading={loadingJobs}
              title={t("MATCHING_OPPORTUNITIES")}
              allButtonText={t("AVAILABLE_OPPORTUNITIES")}
              onAllButtonClick={() => navigate("/opportunities")}
            />
          )}
        </>
      )}
    </ExamDetailsWrapper>
  );
};

const mapStateToProps = (state: RootState) => ({
  selectedExam: state.examDetails.selectedExam,
  loading: state.labDetails.loading,
  jobs: state.opportunitiesListing.jobs,
  loadingJobs: state.opportunitiesListing.loading,
  selectedCountry: state.landingPage.selectedCountry,
  learningPath: state.examDetails.learningPath,
});

const mapDispatchToProps = {
  getExamById: examDetailsActions.getExamById,
  getExamsLearningPath: examDetailsActions.getExamsLearningPath,
  getJobs: opportunitiesListingActions.getJobs,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const ExamDetailsComponentRedux = connector(ExamDetailsComponent);

export { ExamDetailsComponentRedux as ExamDetailsComponent };
