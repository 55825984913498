import React, { useEffect, useRef, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { RootState } from "&store/store";
import { OpportunitiesListWrapper } from "./opportunitiesList.styled";
import SearchSortFilterBar from "&components/searchSortFilterBar/searchSortFilterBar.component";
import Container from "&components/container/container.component";
import CircularLoader from "&components/circularLoader/circularLoader.component";
import PaginationComponent from "&components/pagination/pagination.component";
import { defaultOpportunitiesSearchParams } from "&assets/constants/statics";
import MatchingOpportunitiesCard from "&components/matchingOpportunitiesCard/matchingOpportunitiesCard.component";
import {
  appliedFilter,
  buildCareerList,
  buildDropDownList,
  buildJobSourcesList,
  querySelector,
} from "&utils/searchSortFilter/searchSortFilterUtils";
import { opportunitiesListingActions } from "&features/opportunitiesListing/opportunitiesListing.slice";
import TextLine from "&components/textLine/textLine.component";
import { careerListingActions } from "&features/careerListing/careerListing.slice";
import { translationSelector } from "&utils/translationSelector";

type ReduxProps = ConnectedProps<typeof connector>;

const OpportunitiesListComponent = (props: ReduxProps) => {
  // const navigate = useNavigate();
  const {
    t,
    // i18n
  } = useTranslation(["listingPage"]); // Make sure namespace is added to locales

  // const isArabic = i18n.language === "ar";

  // const sortByObject = {
  //   datePosted: t("LATEST"),
  //   popular: t("POPULAR"),
  //   [isArabic ? "titleAr" : "title"]: t("NAME"),
  // };

  const ref = useRef<any>(null);

  const { jobs, status, loading, getJobs, selectedCountry, user } = props;

  const [urlSearchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    props.getAllCareers({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("language")]);

  const appliedStatusFormatter: any = {
    Applied: "APPLIED",
  };

  useEffect(() => {
    if (status && status === "GET_JOBS_SUCCESS") {
      ref?.current?.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  }, [status]);

  const searchParams: any = {
    page: urlSearchParams.get("page") || defaultOpportunitiesSearchParams.page,
    query:
      urlSearchParams.get("query") || defaultOpportunitiesSearchParams.query,
    dir: "DESC" || undefined,
    countryId: selectedCountry.value,
    applied: urlSearchParams.get("applied") || "all",
    source:
      urlSearchParams.get("source") ||
      defaultOpportunitiesSearchParams.job_source,
    career:
      urlSearchParams.get("career") || defaultOpportunitiesSearchParams.career,
  };

  useEffect(() => {
    fetchPageContent(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchParams.query,
    searchParams.applied,
    searchParams.source,
    searchParams.career,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    localStorage.getItem("language"),
  ]);

  useEffect(() => {
    setSearchParams({
      ...searchParams,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [searchState, setSearchState] = useState({});

  useEffect(() => {
    const newSearchState: any = {
      source: {
        id: "source",
        title: t("PROVIDER"),
        object: buildJobSourcesList(props?.settings?.job_source),
        list: buildDropDownList(
          buildJobSourcesList(props?.settings?.job_source)
        ),
        value: searchParams.source,
        valueItem: querySelector(
          buildDropDownList(buildJobSourcesList(props?.settings?.job_source)),
          searchParams.source
        ),
      },
      career: {
        id: "career",
        title: t("CAREER"),
        isAutoComplete: true,
        object: buildCareerList(props?.careers),
        list: buildDropDownList(buildCareerList(props?.careers)),
        value: searchParams.career,
        valueItem: querySelector(
          buildDropDownList(buildCareerList(props?.careers)),
          searchParams.career
        ),
      },
    };
    if (user) {
      newSearchState.applied = {
        id: "applied",
        title: t("JOB_APPLIED"),
        object: buildJobSourcesList(appliedFilter),
        list: buildDropDownList(buildJobSourcesList(appliedFilter)),
        value: appliedStatusFormatter[searchParams.applied],
        valueItem: querySelector(
          buildDropDownList(buildJobSourcesList(appliedFilter)),
          searchParams.applied
        ),
      };
      setSearchState(newSearchState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    user?.email,
    searchParams.applied,
    searchParams.source,
    searchParams.career,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    localStorage.getItem("language"),
  ]);

  useEffect(() => {
    fetchPageContent(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountry, localStorage.getItem("language")]);

  const loadingComponent = (
    <div className="loader-wrapper">
      <CircularLoader />
    </div>
  );

  const fetchPageContent = (page: number) => {
    setSearchParams({
      ...searchParams,
      page: page.toString(),
    });

    const requestBody = {
      page: page || undefined,
      query: searchParams.query || "",
      limit: 9,
      dir: "DESC",
      countryId: selectedCountry.value,
      showApplied:
        (searchParams.applied !== "all" &&
          appliedStatusFormatter[searchParams.applied]) ||
        undefined,
      source:
        (searchParams.source !== "all" && searchParams.source) || undefined,
      careerId:
        (searchParams.career !== "all" && searchParams.career) || undefined,
    };

    getJobs(requestBody);
  };

  const totalPageNumber = jobs?.meta?.totalPages;

  const content = jobs?.items || jobs;

  return (
    <OpportunitiesListWrapper>
      <Container className="dashboard-container">
        {/* <OpportunityBanner opportunity={jobs?.items[0]} /> */}
        <TextLine title={t("JOB_OPPORTUNITIES")} />
      </Container>

      <div className="search-input">
        <SearchSortFilterBar
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          searchState={searchState}
          forwardRef={ref}
          placeHolder={t("SEARCH_JOB_OPPORTUNITIES")}
        />
      </div>
      <Container className="dashboard-container">
        {loading ? (
          loadingComponent
        ) : (
          <div className="list-wrapper">
            {content && content?.length === 0 ? (
              <p className="no-jobs-wrapper">
                {t("NO_JOB_OPPORTUNITIES_REMAINING")}
              </p>
            ) : (
              content &&
              content?.map((item: any, index: number) => (
                <div className="card" key={index}>
                  <MatchingOpportunitiesCard
                    level={item?.level?.alias}
                    title={item?.title}
                    description={item?.shortDescription}
                    provider={item?.source}
                    skills={item?.jobSkills}
                    location={item?.location}
                    company={item?.company ?? item?.internalCompany?.name}
                    date={item?.datePosted ?? item?.createdAt}
                    id={item?.id}
                    external={item?.external && item?.external === 1}
                    url={translationSelector(item, "url")}
                    buttonText={t("MORE_DETIALS")}
                  />
                </div>
              ))
            )}
          </div>
        )}

        {totalPageNumber !== 1 && (
          <PaginationComponent
            count={totalPageNumber}
            page={Number(searchParams.page)}
            onChangePage={(page) => fetchPageContent(page)}
          />
        )}
      </Container>
    </OpportunitiesListWrapper>
  );
};

const mapStateToProps = (state: RootState) => ({
  status: state.opportunitiesListing.status,
  loading: state.opportunitiesListing.loading,
  jobs: state.opportunitiesListing.jobs,
  isLoggedIn: state.login.isLoggedIn,
  selectedCountry: state.landingPage.selectedCountry,
  settings: state.global.settings,
  careers: state.careerListing.careers,
  user: state.login.user,
});

const mapDispatchToProps = {
  getJobs: opportunitiesListingActions.getJobs,
  getAllCareers: careerListingActions.getAllCareers,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const OpportunitiesListComponentRedux = connector(OpportunitiesListComponent);

export { OpportunitiesListComponentRedux as OpportunitiesListComponent };
