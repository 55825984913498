import {SkillRequest} from "&features/assessment/assessment.type";
import {CourseSkill} from "&features/listingPage/listingPage.type";

export const addedSkillsSelector: (
  userSkills: SkillRequest[],
  addedSkills: CourseSkill[]
) => {
  hasNewSkills: boolean;
  disableAddSkills: boolean;
  updatedSkills: any;
  finalSkills: any;
} = (userSkills: SkillRequest[], addedSkills: CourseSkill[]) => {
  let found = false;
  let updatedSkills: any = [];

  if (addedSkills?.length > 0) {
    addedSkills.forEach((course) => {
      const existingSkillIndex = userSkills.findIndex(
        (skill) => skill.skillId === course.skillId
      );
      if (existingSkillIndex === -1) {
        updatedSkills.push(course);
      } else {
        const existingSkill = userSkills[existingSkillIndex];
        if (existingSkill?.weight && existingSkill?.weight < course.weight) {
          found = true;
          existingSkill.weight = course.weight;
        }
      }
    });
  }

  return {
    hasNewSkills: found,
    disableAddSkills: updatedSkills.length === 0 && !found,
    updatedSkills,
    finalSkills: [...userSkills, ...updatedSkills],
  };
};
