import styled from "styled-components";

interface Props {
  url?: string;
  disabled?: boolean;
}

export const ImageDisplayWrapper = styled.div<Props>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${(props) => (props.url ? props.url : '')});
  width: 37px;
  height: 37px;
`;


export const ImageOverlay = styled.div`
  position: absolute;


  display: block;
  transform: translateX(-50%);
  left: 50%;
  width: 100%;
  height: 100%;
  z-index: 6;
  content: "";
  opacity: 0.3;
  background: linear-gradient(
      107.74deg,
      rgba(0, 160, 251, 0.9) 31.6%,
      rgba(245, 100, 47, 0.9) 76.97%
    ),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  background-blend-mode: overlay, normal, normal;
`;

interface ImageWrapperProps {
  isBackground?: boolean;
}
export const ImageWrapper = styled.div<ImageWrapperProps>`
  overflow: hidden;
  position: relative;

  ${(props) => (props.isBackground ? "width: 100%;" : "")}
  ${(props) => (props.isBackground ? "height: 100%;" : "")}
  ${(props) => (props.isBackground ? "justify-content: center;" : "")}
  ${(props) => (props.isBackground ? "align-items: center;" : "")}
  .image-wrapper {
    width: 100%;
    height: 100%;
  }
`;
