import { colors } from "&assets/constants/colors";
import { device } from "&assets/constants/responsiveness";
import styled from "styled-components";

export const FrequentlyAskedQuestionsWrapper = styled.div`
  background: linear-gradient(14.29deg, rgba(207, 232, 246, 0) 0.64%, #cfe8f6 89.18%);
  padding: 50px 175px;
  position: relative;
  .title {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 110%;
    text-align: center;
    letter-spacing: -0.02em;
    color: ${colors.dark};
    margin-bottom: 23px;
  }
  .circle-back {
    position: absolute;
    top: 0;
    ${(props) => (props.theme.dir === "rtl" ? "transform: scaleX(-1);" : "")}
    ${(props) => (props.theme.dir === "rtl" ? "right: 0;" : "left: 0;")}
    opacity: 0.3;
  }
  .faqs {
    z-index: 2;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  .faqs-section {
    display: flex;
    flex-direction: column;
    width: 49%;
    align-items: flex-start;
  }
  .view-all-button {
    background: transparent;
    border: 1px solid ${colors.primary};
    width: 160px;
    height: 38px;
    justify-content: center;
    margin: auto;
    margin-top: 24px;
  }
  .view-all-button-title {
    font-weight: 800 !important;
    font-size: 14px !important;
    line-height: 160% !important;
    text-align: center !important;
    color: ${colors.primary} !important;
    margin: 0 !important;
  }
  @media ${device.tablet} {
    padding: 20px 30px;
    .faqs {
      width: 100%;
    }
  }
  @media ${device.mobile} {
    width: 100%;
    padding: 20px 0;
    .content {
      padding: 16px;
    }
    .faqs {
      width: 100%;
      flex-direction: column;
      .faqs-section {
        width: 100%;
      }
    }
    .title {
      font-size: 28px;
    }
  }
`;
