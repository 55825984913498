import { colors } from "&assets/constants/colors";
import styled from "styled-components";

export const HtmlParserWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  display: flex;
  color: ${colors.dark};
  p {
    margin: 10px 0;
  }
`;
