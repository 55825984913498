import React, { useEffect, useState } from "react";
import {
  CareerLevelsWrapper,
  LevelButtonsWrapper,
} from "./careerLevels.styled";
import CareerLevel from "&components/careerLevel/careerLevel.component";
import { Level } from "&features/landingPage/landingPage.type";
import ArrowButton from "&components/buttons/arrowButton/arrowButton.component";
import { translationSelector } from "&utils/translationSelector";

interface Props {
  short?: boolean;
  isAssessment?: boolean;
  careerLevels: Level[];
  showAll?: boolean;
  hideJobs?: boolean;
}

const CareerLevels = (props: Props) => {
  const { short, isAssessment, careerLevels, showAll, hideJobs } = props;

  const [levelIndex, setLevelIndex] = useState(0);

  const refs = careerLevels.reduce((acc: any, value: any, index: number) => {
    acc[index] = React.createRef();
    return acc;
  }, {});

  useEffect(() => {
    if (short) {
      refs[levelIndex]?.current?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [levelIndex]);

  const handleNext = () => {
    if (levelIndex < careerLevels.length - 1) {
      setLevelIndex(levelIndex + 1);
    }
  };

  const handleBack = () => {
    if (levelIndex > 0) {
      setLevelIndex(levelIndex - 1);
    }
  };

  return (
    <LevelButtonsWrapper short={short}>
      <CareerLevelsWrapper short={short}>
        {Array.isArray(careerLevels) &&
          careerLevels?.map((careerLevel: Level, index: number) => {
            return careerLevel?.careers?.length > 0 ? (
              <CareerLevel
                key={index}
                forwardedRef={refs[index]}
                short={short}
                showAll={showAll}
                index={index}
                hideJobs={hideJobs}
                careers={careerLevel.careers}
                level={translationSelector(careerLevel, "alias")}
                isAssessment={isAssessment}
              />
            ) : null;
          })}
      </CareerLevelsWrapper>
      {levelIndex < careerLevels.length - 1 && (
        <ArrowButton className={"forward-arrow"} onClick={handleNext} />
      )}
      {levelIndex > 0 && (
        <ArrowButton
          isBack={true}
          className={"back-arrow"}
          onClick={handleBack}
        />
      )}
    </LevelButtonsWrapper>
  );
};

export default CareerLevels;
