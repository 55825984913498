import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useTranslation } from "react-i18next";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { RootState } from "&store/store";
import { useMediaQuery } from "react-responsive";
import { isEmpty } from "lodash";

import { dashboardActions } from "../../dashboard.slice";
import {
  CarouselIndicator,
  DashboardComponentWrapper,
} from "./dashboardComponent.styled";
import MatchingCareersCards from "&components/matchingCareersCards/matchingCareersCards.component";
import LearningPathStepper from "&components/learningPathStepper/learningPathStepper.component";
import DashboardListing from "&components/dashboardListing/dashboardListing.component";
import MatchingCareerSwipe from "&components/matchingCareerSwipe/matchingCareerSwipe.component";
import Container from "&components/container/container.component";
import { size } from "&assets/constants/responsiveness";
import TextLine from "&components/textLine/textLine.component";
import CircularLoader from "&components/circularLoader/circularLoader.component";
import { opportunitiesListingActions } from "&features/opportunitiesListing/opportunitiesListing.slice";
import DashboardBanner from "&components/dashboardBanner/dashboardBanner.component";
import getTopLearningActivities from "&utils/getTopLearningActivities";
import { Course } from "&features/listingPage/listingPage.type";

type ReduxProps = ConnectedProps<typeof connector>;

const DashboardComponent = (props: ReduxProps) => {
  const { t } = useTranslation(["dashboard"]);
  const {
    jobs,
    getAssessment,
    matchingCareers,
    loading,
    userLoading,
    getAssessmentLearningPath,
    learningPathwaysAssessment,
    loadingLearningPath,
    user,
    getJobs,
    selectedCountry,
  } = props;
  const isMobile = useMediaQuery({ maxWidth: size.mobile });
  const isTablet = useMediaQuery({
    minWidth: size.mobile,
    maxWidth: size.tablet,
  });
  const [matchingCards, setMatchingCards] = useState<any>([]);

  const isDevice = isMobile || isTablet;
  const [selectedId, setSelectedId] = useState(matchingCareers?.career?.id);
  const [activities, setActivities] = useState<Course[]>([]);

  const onSelectCareer = (careerId: number, name: string) => {
    careerId && setSelectedId(careerId);
  };

  useEffect(() => {
    getAssessment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    selectedId && getAssessmentLearningPath(selectedId.toString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedId, localStorage.getItem("language")]);

  useEffect(() => {
    if (!isEmpty(matchingCareers.career)) {
      const cards = matchingCareers?.suggestions
        ? [...matchingCareers?.suggestions]
        : [];
      let selectedCareer: any = { ...matchingCareers?.career };
      selectedCareer.isSelected = true;
      const newArray = [selectedCareer].concat(cards);
      setSelectedId(matchingCareers?.career?.id);
      setMatchingCards(newArray);
    }
  }, [matchingCareers]);

  useEffect(() => {
    selectedId &&
      getJobs({
        countryId: selectedCountry.value,
        page: 1,
        limit: 9,
        careerId: selectedId,
        sort: "datePosted",
        dir: "DESC",
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedId, selectedCountry, localStorage.getItem("language")]);

  useEffect(() => {
    setActivities(getTopLearningActivities(learningPathwaysAssessment));
  }, [learningPathwaysAssessment]);

  return (
    <DashboardComponentWrapper>
      <Container className="dashboard-container">
        <DashboardBanner />

        {user?.userLearningPaths && user?.userLearningPaths?.length > 0 ? (
          <div className="flex-row">
            <LearningPathStepper
              data={user?.userLearningPaths.concat(user?.userCourses)}
            />
            {isDevice ? (
              <>
                <TextLine title={t("THINKING_OF_UPGRADING_YOUR_CAREER")} />
                <Carousel
                  swipeable={true}
                  showStatus={false}
                  showThumbs={false}
                  className="carousel-wrapper"
                  preventMovementUntilSwipeScrollTolerance={true}
                  swipeScrollTolerance={50}
                  showIndicators
                  onChange={(index, item) =>
                    onSelectCareer(
                      matchingCards[index].id,
                      matchingCards[index].name
                    )
                  }
                  renderIndicator={(
                    onClickHandler,
                    isSelected,
                    index,
                    label
                  ) => {
                    return (
                      <CarouselIndicator
                        selected={isSelected}
                        onClick={onClickHandler}
                        key={index}
                      />
                    );
                  }}
                >
                  {matchingCards.map((item: any, index: number) => {
                    return (
                      <MatchingCareerSwipe
                        isEQ1={!!user?.isEQ1}
                        key={index}
                        matchingCareers={matchingCards}
                        selectedCareer={item}
                        onSelectCareer={onSelectCareer}
                      />
                    );
                  })}
                </Carousel>
              </>
            ) : loading || userLoading ? (
              <CircularLoader />
            ) : (
              matchingCards[0] && (
                <MatchingCareerSwipe
                  isEQ1={!!user?.isEQ1}
                  matchingCareers={matchingCards}
                  selectedCareer={matchingCards[0]}
                  onSelectCareer={onSelectCareer}
                />
              )
            )}
          </div>
        ) : !loading && !userLoading ? (
          <MatchingCareersCards
            selectedId={selectedId || matchingCareers?.career?.id}
            onSelectCareer={onSelectCareer}
            matchingCareers={matchingCareers}
            isSmall={true}
            isEQ1={!!user?.isEQ1}
          />
        ) : (
          <CircularLoader />
        )}
        {loadingLearningPath ? (
          <CircularLoader />
        ) : (
          <>
            {learningPathwaysAssessment?.length > 0 && (
              <DashboardListing
                data={learningPathwaysAssessment}
                title={t("RECOMMENDED_LEARNING_PATHS")}
                showViewAll
              />
            )}
            {activities && activities?.length > 0 && (
              <DashboardListing
                data={activities}
                title={t("RECOMMENDED_COURSES")}
                isCourse
                showViewAll
              />
            )}
          </>
        )}
        {jobs?.items?.length > 0 && (
          <DashboardListing
            data={jobs?.items}
            title={t("MATCHING_OPPORTUNITIES")}
            isJob
            showViewAll
          />
        )}
      </Container>
    </DashboardComponentWrapper>
  );
};

const mapStateToProps = (state: RootState) => ({
  learningPathways: state.listingPage.learningPathways,
  jobs: state.opportunitiesListing.jobs,
  matchingCareers: state.dashboard.matchingCareers,
  loading: state.dashboard.loading,
  loadingLearningPath: state.dashboard.loadingLearningPath,
  learningPathwaysAssessment: state.dashboard.learningPathways,
  user: state.login.user,
  userLoading: state.login.loading,
  selectedCountry: state.landingPage.selectedCountry,
});

const mapDispatchToProps = {
  getAssessment: dashboardActions.getAssessment,
  getAssessmentLearningPath: dashboardActions.getAssessmentLearningPath,
  getJobs: opportunitiesListingActions.getJobs,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const DashboardComponentRedux = connector(DashboardComponent);

export { DashboardComponentRedux as DashboardComponent };
