export const aboutSpace = {
  en: {
    MAIN_ATTRIBUTE_NAMMISKILLS: "The main attributes of nammiskills",
    ABOUT_NAMMISKILLS: "About nammiskills",
    SIGN_UP_NOW: "Sign Up Now",
    KNOWLEDGE_OF_THE_MOST: "Knowledge of the most in-demand jobs in the market today",
    SELF_ASSESSMENT: "Self-Assessment for enhancing capabilities",
    ENHANCED_ACCESS:
      "Enhanced access to a wide range of sponsored and discounted courses from the world’s leading tech providers",
    MATCHING_YOU: "Matching you to the existing job opportunities in the region",
    SUPPORT_IN_IDENTIFYING: "Support in preparing and applying for employment opportunities",
    OUR_MISSION:
      "Our mission is to help you unlock your potential and seize outstanding opportunities in the digital world ",
    WITH_OUR_UNWAVERING: `At nammiskills, we provide a comprehensive platform where you can discover exciting career paths, take specialized courses, earn certificates to help you acquire valuable skills, that will pave the way for your success.  
      We focus on fast-growing digital industries, equipping you with the technical and digital expertise needed to excel in the fields related to the most in demand jobs, and increase your chances of landing outsourced opportunities.  
      With our unwavering support, we'll be by your side every step of the way on your journey to achieving your dreams and making them a reality! 
      `,
    AT_NAMMISKILLS:
      "nammiskills aims to empower young people by providing them with the digital and technical skills required for the top 50 most in demand jobs featured on the platform.",
    IS_A_PLATFORM_THAT_EMPOWERS_ETC:
      "nammiskills is the go-to platform to drive learners' careers forward using targeted learning activities based on the most in-demand jobs. nammiskills aims to create learning journeys and pathways for people wanting to learn new skills that will help them find job opportunities in today’s ever-digitalized labor market. Developed with the support of UNICEF and the World Bank, our platform is designed to help users achieve their career goals and maximize their earning potential, in partnership with leading tech companies.",
  },
  ar: {
    MAIN_ATTRIBUTE_NAMMISKILLS: 'أهمّ ميزات منصة "نمّي سكيلز"',
    ABOUT_NAMMISKILLS: 'عن "نمّي سكيلز"',
    IS_A_PLATFORM_THAT_EMPOWERS_ETC:
      "nammiskills is the go-to platform to drive learners' careers forward using targeted learning activities based on the most in-demand jobs. nammiskills aims to create learning journeys and pathways for people wanting to learn new skills that will help them find job opportunities in today’s ever-digitalized labor market. Developed with the support of UNICEF and the World Bank, our platform is designed to help users achieve their career goals and maximize their earning potential, in partnership with leading tech companies.",
    SIGN_UP_NOW: "تسجل الآن",
    KNOWLEDGE_OF_THE_MOST: "التعرّف على الوظائف الأكثر طلبًا في السوق اليوم",
    SELF_ASSESSMENT: "التقييم الذاتي لتعزيز قدراتك",
    ENHANCED_ACCESS:
      "الحصول على مجموعة واسعة من الدورات التدريبية المقدمة من قبل شركات التكنولوجيا الرائدة في العالم. جميع هذه الدورات مقدمة بدعم مالي كامل أو جزئي",
    MATCHING_YOU: "مطابقتك مع فرص العمل المتوفرة في المنطقة",
    SUPPORT_IN_IDENTIFYING: "مساعدتك في التحضير والتقديم على فرص العمل المتوفرة حاليا ",
    WITH_OUR_UNWAVERING: `"نمّي سكيلز" هي منصة شاملة يمكنك من خلالها اكتشاف مسارات وظيفية متعددة، أخذ دورات تدريبية متخصصة، والحصول على شهادات لمساعدتك في اكتساب مهارات قيمة، من شأنها أن تمهد الطريق لنجاحك. 
    نحن نركز على الصناعات الرقمية السريعة النمو، ونُزوّدك بالخبرة التقنية والرقمية اللازمة للتميز في المجالات المتعلقة بالوظائف الأكثر طلبًا، وزيادة فرصك في الحصول على وظائف محلية وخارجية.  
    مع دعمنا الدائم، سنُرافقك في كل خطوة من رحلتك لتحقيق أحلامك وجعلها حقيقة!`,
    OUR_MISSION: "مهمتنا هي مساعدتك على اكتشاف امكانياتك وتطويرها للحصول على فرص استثنائية في العالم الرقمي الحالي",
    AT_NAMMISKILLS:
      'تهدف منصة "نمّي سكيلز" إلى تمكين آلاف الشباب من خلال تزويدهم بالمهارات الرقمية والتقنية المطلوبة للوظائف الخمسين الأكثر طلبًا، والمتوفرة حاليا على المنصة',
  },
};
