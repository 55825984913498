import styled from "styled-components";
import { colors } from "&assets/constants/colors";

interface Props {
  isWhite?: boolean;
}
export const UserLocationWrapper = styled.div<Props>`
  display: flex;

  .location-text {
    margin-left: ${(props) => (props.isWhite ? "5px" : "10px")};
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.05em;
    color: ${(props) => (props.isWhite ? colors.white : colors.dark)};
  }
`;
