import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";

import {
  AvailableJobsWrapper,
  CircleIconWrapper,
  DescriptionModal,
  DescriptionModalWrapper,
  IconPlayButtonWrapper,
  OptionCardBackWrapper,
  OptionCardFrontWrapper,
  OptionCardWrapper,
  TitleArrowWrapper,
  TitleJobsWrapper,
  VideoDescriptionWrapper,
} from "./optionCard.styled";
import { ArrowForwardIcon, PlayButtonIcon, XIconBig } from "&assets/constants/icons";
import ImageDisplay from "&components/imageDisplay/imageDisplay.component";
import { Career } from "&features/landingPage/landingPage.type";
import { colors } from "&assets/constants/colors";
import { size } from "&assets/constants/responsiveness";
import CloseIconButton from "&components/closeIcon/closeIcon.component";
import SubmitButton from "&components/buttons/submitButton/submitButton.component";
import { useTranslation } from "react-i18next";

interface Props {
  index: number;
  career: Career;
  id: string;
  className?: string;
  icon?: any;
  title: string;
  jobsNumber: number;
  description: string;
  thumbnailUrl?: string;
  onClick: () => void;
  onStartAssessmentClick?: () => void;
  onViewDetailsClick?: () => void;
  onVideoClick?: () => void;
  hideJobs?: boolean;
  vertical?: boolean;
  showAll?: boolean;
  isAssessment?: boolean;
  level?: string;
  careerIndex?: number;
  reverse?: boolean;
}

const OptionCard = (props: Props) => {
  const {
    index,
    className,
    icon,
    title,
    careerIndex,
    jobsNumber = 0,
    level,
    career,
    thumbnailUrl = "",
    id,
    onClick,
    onVideoClick,
    description = "",
    hideJobs,
    vertical,
    showAll,
    isAssessment,
    reverse,
    onStartAssessmentClick = () => {},
    onViewDetailsClick = () => {},
  } = props;
  const isMobile = useMediaQuery({ maxWidth: size.mobile });
  const isTablet = useMediaQuery({
    minWidth: size.mobile,
    maxWidth: size.tablet,
  });
  // const navigate = useNavigate();
  const { t } = useTranslation(["landingPage"]);

  const disableHover = isMobile || isTablet;

  const [isHover, setIsHover] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);

  const iconSelector =
    typeof icon === "string" ? <ImageDisplay className="icon-image" isFromS3={true} url={icon} /> : icon;

  const titleArrow = (back?: boolean) => (
    <TitleArrowWrapper className={back ? "next" : ""}>
      <div className="title" style={{ color: isHover ? colors.white : colors.dark }}>
        {title}
      </div>
      {ArrowForwardIcon(back ? colors.white : colors.dark)}
    </TitleArrowWrapper>
  );

  const renderDescriptionModal = () => {
    const handleClose = () => setShowVideoModal(false);

    return (
      <DescriptionModal open={showVideoModal && disableHover}>
        <VideoDescriptionWrapper>
          <div className="close-icon-wrapper">
            <CloseIconButton onClick={handleClose} icon={XIconBig(12)} />
          </div>
          <DescriptionModalWrapper>
            <ImageDisplay isFromS3={true} isBackgroundImage={true} className="image" url={thumbnailUrl}>
              <button onClick={career?.videoUrl ? onVideoClick : onViewDetailsClick} className="image-button-wrapper">
                {!isAssessment && career.videoUrl && (
                  <>
                    {PlayButtonIcon()}
                    <div className="watch-video-text">Watch Video</div>
                  </>
                )}
              </button>
            </ImageDisplay>
            <div className="title-description-wrapper">
              <div className="description">{description}</div>
              <div className="card-buttons-wrapper">
                <SubmitButton
                  id={`view-career-${id}-details-button`}
                  className="view-career-button"
                  titleClassName="view-career-button-text"
                  title={t("VIEW_CAREER_DETAILS")}
                  handleClick={onViewDetailsClick}
                />
                <SubmitButton
                  id={`start-assessment-${id}-button`}
                  className="start-assessment-button"
                  titleClassName="start-assessment-button-text"
                  title={t("START_ASSESSMENT")}
                  handleClick={onStartAssessmentClick}
                />
              </div>
            </div>
          </DescriptionModalWrapper>
        </VideoDescriptionWrapper>
      </DescriptionModal>
    );
  };

  return (
    <OptionCardWrapper
      key={id}
      id={id}
      showAll={showAll}
      vertical={vertical}
      reverse={reverse}
      onMouseLeave={() => setIsHover(false)}
      onMouseEnter={() => !disableHover && setIsHover(true)}
    >
      <OptionCardFrontWrapper
        onClick={() => (!isMobile && !isTablet ? onClick() : setShowVideoModal(true))}
        className={className}
        show={!isHover}
        index={careerIndex || 0}
        level={level}
        long={vertical && showAll}
      >
        <div className="level-card-tag">
          <span className="career-name">{level}</span>
        </div>
        <IconPlayButtonWrapper>
          <CircleIconWrapper>
            <div className="icon">{iconSelector}</div>
          </CircleIconWrapper>
        </IconPlayButtonWrapper>
        <TitleJobsWrapper onClick={onClick}>
          {titleArrow()}
          {!hideJobs && (
            <AvailableJobsWrapper>
              <span className="number-text">{jobsNumber}</span>
              <span className="text">{t("JOBS_AVAILABLE")}</span>
            </AvailableJobsWrapper>
          )}
        </TitleJobsWrapper>
      </OptionCardFrontWrapper>
      <VideoDescriptionWrapper increaseHeight={(vertical || showAll) && !isMobile}>
        <OptionCardBackWrapper
          vertical={(vertical && index !== 0) || !!(showAll && index > 4)}
          shouldLower={(vertical && index === 0) || !!(showAll && index <= 4)}
          show={isHover}
        >
          <ImageDisplay isFromS3={true} isBackgroundImage={true} className="image" url={thumbnailUrl}>
            <button onClick={career?.videoUrl ? onVideoClick : onViewDetailsClick} className="image-button-wrapper">
              {!isAssessment && career.videoUrl && PlayButtonIcon()}
            </button>
          </ImageDisplay>
          <div onClick={onClick} className="title-description-wrapper">
            {titleArrow(true)}
            <div className="description">{description}</div>
            <div className="card-buttons-wrapper">
              <SubmitButton
                id={`view-career-${id}-details-button`}
                className="view-career-button"
                titleClassName="view-career-button-text"
                title={t("VIEW_CAREER_DETAILS")}
                handleClick={onViewDetailsClick}
              />
              <SubmitButton
                id={`start-assessment-${id}-button`}
                className="start-assessment-button"
                titleClassName="start-assessment-button-text"
                title={t("START_ASSESSMENT")}
                handleClick={onStartAssessmentClick}
              />
            </div>
          </div>
        </OptionCardBackWrapper>
      </VideoDescriptionWrapper>
      {renderDescriptionModal()}
    </OptionCardWrapper>
  );
};

export default OptionCard;
