import { educationDegrees, fullTimePartTime, incomeRanges } from "&assets/constants/profile";
import {
  EligibilityFormValues,
  User,
  UserCertificates,
  UserEducation,
  UserEligibilityForm,
  UserWorkExperience,
} from "&features/login/login.type";
import { CertificationItem, EducationItem, WorkExperience } from "&features/dashboard/screens/profile/profile.type";
import moment from "moment";
import { definedObjectBuilder } from "&utils/definedObjectBuilder";
import { Skill, Skills } from "&features/assessment/assessment.type";
import { isEmpty, map } from "lodash";

export const findSalaryRange = (salaryRange: string) => {
  let returnItem = undefined;
  map(incomeRanges, (item) => {
    if (salaryRange === item.value) {
      returnItem = item;
    }
  });
  return returnItem;
};
export const findDegree = (degree: string) => {
  let returnItem = undefined;
  map(educationDegrees, (item) => {
    if (degree === item.name) {
      returnItem = item;
    }
  });
  return returnItem;
};

export const findEmploymentType = (employmentType: string) => {
  let returnItem = undefined;
  map(fullTimePartTime, (item) => {
    if (employmentType === item.name) {
      returnItem = item;
    }
  });
  return returnItem;
};

export const educationSelector: (userEducations: UserEducation[]) => EducationItem[] = (
  userEducations: UserEducation[]
) => {
  const educationItemBody: EducationItem[] = [];
  userEducations?.map((education: UserEducation) => {
    const educationItem: EducationItem = {
      educationDegree: findDegree(education?.degree) || undefined,
      fieldOfStudy: education?.major || undefined,
      schoolName: education?.institute || undefined,
      otherSchoolName: education?.otherInstitute || undefined,
      startDate: education?.startDate ? moment(education?.startDate) : undefined,
      graduationYear: education?.graduationDate ? moment(education?.graduationDate) : undefined,
      description: education?.description || undefined,
      current: education?.current || undefined,
    };
    educationItemBody.push(definedObjectBuilder(educationItem));
  });

  return educationItemBody;
};

export const eligibilitySelector: (userEligibility: UserEligibilityForm) => EligibilityFormValues = (
  userEligibility: UserEligibilityForm
) => {
  const eligibilityItem: EligibilityFormValues = {
    approved: userEligibility?.approved || undefined,
    salaryRange: findSalaryRange(userEligibility?.salaryRange) || undefined,
    // disability: userEligibility?.disability ? 1 : 2,
    residenceType: userEligibility?.typeOfResidence
      ? userEligibility?.typeOfResidence === "resident"
        ? 1
        : 2
      : undefined,
    residence: userEligibility?.residenceCountry || undefined,
    idPhotoName: userEligibility?.document || undefined,
    // nationality: userEligibility?.nationality || undefined,
    city: userEligibility?.city || undefined,
    kazza: userEligibility?.governorate || undefined,
    reason: userEligibility?.reason || undefined,
    reasonAr: userEligibility?.reasonAr || undefined,
    rejected: userEligibility?.reason === "" || userEligibility?.reason ? 2 : undefined,
    // disabilityDescription: userEligibility?.disabilityDescription || undefined,
  };

  return definedObjectBuilder(eligibilityItem);
};

export const workExperienceSelector: (userWorkExperiences: UserWorkExperience[]) => WorkExperience[] = (
  userWorkExperiences: UserWorkExperience[]
) => {
  const workExperienceBody: WorkExperience[] = [];

  userWorkExperiences?.map((workExperience: UserWorkExperience) => {
    const workExperienceItem: WorkExperience = {
      current: workExperience.current,
      employmentType: findEmploymentType(workExperience.employmentType) || undefined,
      description: workExperience.description || undefined,
      location: workExperience.location || undefined,
      startDate: workExperience.startDate ? moment(workExperience.startDate) : undefined,
      endDate: workExperience.endDate ? moment(workExperience.endDate) : undefined,
      companyName: workExperience.companyName || undefined,
      title: workExperience.title || undefined,
    };
    workExperienceBody.push(definedObjectBuilder(workExperienceItem));
  });

  return workExperienceBody;
};

export const certificationSelector: (userCertificates: UserCertificates[]) => CertificationItem[] = (
  userCertificates: UserCertificates[]
) => {
  const certificationBody: CertificationItem[] = [];

  userCertificates?.map((certification: UserCertificates) => {
    const certificateItem: CertificationItem = {
      name: certification.name,
      instituteName: certification.instituteName || undefined,
      issueDate: certification.issueDate ? moment(certification.issueDate) : undefined,
      expiryDate: certification.expiryDate ? moment(certification.expiryDate) : undefined,
    };
    certificationBody.push(definedObjectBuilder(certificateItem));
  });

  return certificationBody;
};

const userSkillsSelector: (skillsObject?: Skills) => any = (skillsObject?: Skills) => {
  const filterSkills = (skillList: Skill[]) => {
    let skillsArray: Skill[] = [...skillList];
    return skillsArray.filter((skill) => skill?.weight && skill?.weight > 0);
  };

  if (!skillsObject) {
    return {};
  }

  return {
    hard_skills: skillsObject?.hard_skills ? filterSkills(skillsObject.hard_skills) : {},
    soft_skills: skillsObject?.soft_skills ? filterSkills(skillsObject.soft_skills) : {},
    other_skills: skillsObject?.other_skills ? filterSkills(skillsObject.other_skills) : {},
  };
};

export const otherSkillsFilter = (skillsObject?: Skill[]) => {
  const returnSkills = skillsObject ? [...skillsObject] : [];
  return returnSkills.filter((skill) => skill?.weight && skill?.weight > 0);
};

export const userSelector = (user: User, oldUser?: User) => {
  return {
    ...oldUser,
    ...user,
    userSkills: userSkillsSelector(user?.userSkills),
    language: user?.contentLanguage,
  };
};

export const eligibilityStatusSelector = (eligibilityForm?: UserEligibilityForm) => {
  const eligibilityData = (eligibilityForm && eligibilitySelector(eligibilityForm)) || {};

  const rejectedEligibility = eligibilityForm?.reason === "" || eligibilityForm?.reason ? 2 : 1;

  const isAwaitingEligibility = !isEmpty(eligibilityForm) && !eligibilityForm?.approved && rejectedEligibility === 1;

  return isAwaitingEligibility
    ? "pending"
    : eligibilityData?.rejected === 2 && !eligibilityData?.approved
    ? "rejected"
    : eligibilityData?.approved
    ? "accepted"
    : "initial";
};
