import React, { useState } from "react";
import { UploadCompletionComponentWrapper } from "./uploadCompletionComponent.styled";
import UploadCompletionProof from "&components/uploadCompletionProof/uploadCompletionProof.component";
import SubmitButton from "&components/buttons/submitButton/submitButton.component";
import AddSkillModal from "&components/addSkillModal/addSkillModal.component";
import { useSelector } from "react-redux";
import { RootState } from "&store/store";
import { buildAssessmentBodyForAdd } from "&utils/buildAssessmentObject";
import { addedSkillsSelector } from "&utils/addedSkillsSelector";
import { useTranslation } from "react-i18next";
import { translationSelector } from "&utils/translationSelector";

interface Props {
  item?: any;
  type: string;
  learningPathId?: number;
}

const UploadCompletionComponent = (props: Props) => {
  const { item, type, learningPathId } = props;
  const [uploadProofOpen, setUploadProof] = useState(false);

  const [addSkillsOpen, setAddSkillsOpen] = useState(false);
  const [activitySelected, setActivity] = useState<{
    item?: any;
    type: string;
    reason?: string;
  }>({ item: undefined, type: "", reason: "" });
  const { t } = useTranslation(["listingPage"]);

  const user = useSelector((state: RootState) => state.login.user);

  let userSkills = buildAssessmentBodyForAdd(
    {
      hardSkills: user?.userSkills?.hard_skills,
      softSkills: user?.userSkills?.soft_skills,
      otherSkills: user?.userSkills?.other_skills,
    },
    true
  );

  const { disableAddSkills } = addedSkillsSelector(
    userSkills,
    item?.courseSkills || []
  );

  return (
    <UploadCompletionComponentWrapper>
      <SubmitButton
        id="upload-completion-btn"
        title={
          item?.status === 2
            ? t("UPLOAD_COMPLETION_AGAIN")
            : item?.status === 3
            ? disableAddSkills
              ? t("VIEW_GAINED_SKILLS")
              : t("UPDATE_SKILLS_IN_PROFILE")
            : t("UPLOAD_COMPLETION_PROOF")
        }
        handleClick={(event: any) => {
          setActivity({
            reason: translationSelector(item, "reason") || undefined,
            item: item,
            type,
          });
          item?.status === 3 ? setAddSkillsOpen(true) : setUploadProof(true);
        }}
        className="upload-completion-btn"
        titleClassName="upload-completion-btn-text"
      />
      <UploadCompletionProof
        open={uploadProofOpen}
        handleClose={() => {
          setUploadProof(!uploadProofOpen);
        }}
        id={activitySelected?.item?.id}
        type={activitySelected?.type}
        reason={activitySelected?.reason}
        learningPathId={learningPathId}
      />
      <AddSkillModal
        open={addSkillsOpen}
        handleClose={() => {
          setAddSkillsOpen(!addSkillsOpen);
        }}
        skills={activitySelected?.item?.courseSkills}
      ></AddSkillModal>
    </UploadCompletionComponentWrapper>
  );
};

export default UploadCompletionComponent;
