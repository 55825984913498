import { colors } from "&assets/constants/colors";
import { device } from "&assets/constants/responsiveness";
import styled from "styled-components";

export const BlogCardWrapper = styled.div`
  background: ${colors.linearGradientWhite} padding-box, ${colors.linearGradientSixth} border-box;
  border-radius: 12px;
  border: 1px solid transparent;
  box-sizing: border-box;
  width: 316px;
  min-height: 300px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-content: center;
  position: relative;
  transition: all 0.2s ease-in;
  cursor: pointer;
  &:hover {
    background: ${colors.linearGradientWhite} padding-box, ${colors.linearGradientOne} border-box;
  }
  .blog-image {
    width: 268px;
    height: 150px;
    border-radius: 16px;
    margin: auto;
  }
  .card-title {
    color: #2d2a35;
    font-family: "Mulish";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 120%;
    width: 100%;
    margin: 0;
    margin-top: 10px;
    margin-bottom: 0px;
  }
  .card-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      font-weight: 400;
      font-size: 14px;

      ${(props) => (props.theme.dir === "rtl" ? "margin-right: 10px;" : "margin-left: 10px;")}
      color: #4d525b;
      align-items: center;
      justify-content: center;
      display: flex;
      .text {
        ${(props) => (props.theme.dir === "rtl" ? "margin-right: 6px;" : "margin-left: 6px;")}
      }
    }
    .info {
      ${(props) => (props.theme.dir === "rtl" ? "margin-right: 0;" : "margin-left: 0;")}

      ${(props) => (props.theme.dir === "rtl" ? "margin-left: 20px;" : "margin-right: 20px;")}
    }
  }
  @media ${device.tablet} {
    padding: 16px;
    height: 320px;
    min-width: 296px;
  }
  @media ${device.mobile} {
    padding: 16px;
    min-height: 320px;
    min-width: 296px;
  }
`;
