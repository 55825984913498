import { RootState } from "&store/store";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Outlet } from "react-router-dom";
import Navbar from "&components/navbar/navbar.component";
import { Grid } from "@mui/material";

type ReduxProps = ConnectedProps<typeof connector>;

type WithNavBarProps = {};

const WithNavBar = () => {
  return (
    <>
      {/*In Navbar, there is a hook that makes the screen scroll up. Sincerely Mira*/}
      <Navbar />
      <Grid paddingTop="100px">
        <Outlet />
      </Grid>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  // TODO change this to your real auth validator
  isAuthenticated: state.login.isLoggedIn,
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);
const AppRedux = connector(WithNavBar);

export { AppRedux as WithNavBar };
