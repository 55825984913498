import { device } from "&assets/constants/responsiveness";
import { colors } from "&assets/constants/colors";
import styled from "styled-components";

export const GradientBackgroundWrapper = styled.div`
  background: ${colors.linearGradientSixth};
  padding: 48px 64px;
  position: relative;
  overflow: hidden;
  .header {
    margin-bottom: 60px;
  }
  .oval-left {
    position: absolute;
    width: 303px;
    height: 303px;
    ${(props) => (props.theme.dir === "rtl" ? "right: -125px;" : "left: -125px;")}
    top: 150px;
    background: linear-gradient(56.4deg, ${colors.primaryDark} 8.08%, ${colors.secondary} 87.11%);
    filter: blur(65px);
  }
  .oval-right {
    position: absolute;
    width: 303px;
    height: 303px;
    ${(props) => (props.theme.dir === "rtl" ? "left: -80px;" : "right: -80px;")}
    top: 50px;
    background: linear-gradient(56.4deg, ${colors.primaryLight} 8.08%, ${colors.secondary} 87.11%);
    filter: blur(65px);
  }
  @media ${device.tablet} {
    padding: 20px 30px 20px 30px;
    .alice-carousel__stage-item {
      width: 324px !important;
      height: 282px !important;
      ${(props) => (props.theme.dir === "rtl" ? "margin-left: 16px;" : "margin-right: 16px;")}
    }
    .oval-right {
      display: none;
    }
    .header {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 32px;
      .title {
        font-size: 28px;
        margin-bottom: 24px;
      }
      .view-all {
        font-size: 18px;
      }
    }
  }
  @media ${device.mobile} {
    padding: 20px 16px;
    overflow: hidden;
    .header {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 32px;
      .title {
        font-size: 28px;
        margin-bottom: 24px;
      }
      .view-all {
        font-size: 18px;
      }
    }
    .alice-carousel__stage-item {
      width: 324px !important;
      height: 282px !important;
      ${(props) => (props.theme.dir === "rtl" ? "margin-left: 16px;" : "margin-right: 16px;")}
    }
    .oval-left {
      position: absolute;
      width: 303px;
      height: 303px;
      ${(props) => (props.theme.dir === "rtl" ? "right: -150px;" : "left: -150px;")}
      top: 160px;
      background: linear-gradient(56.4deg, ${colors.primaryDark} 8.08%, ${colors.secondary} 87.11%);
      filter: blur(65px);
    }
    .oval-right {
      display: none;
    }
  }
`;
