
import styled from "styled-components";
import {colors} from "&assets/constants/colors";

export const SmallBackButtonWrapper = styled.button`
  background: transparent;
  cursor: pointer;
  padding: 0;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .arrow {
    ${(props) =>
            props.theme.dir === "rtl"
                    ? "transform: rotate(0deg);"
                    : "transform: rotate(180deg);"}
    ${(props) =>
            props.theme.dir === "rtl"
                    ? "margin-left: 14px;"
                    : "margin-right: 14px;"}
  }

  .button-text {
    font-weight: 800;
    font-size: 18px;
    line-height: 160%;
    text-align: center;
    color: ${colors.white};
  }
  `;

