import React from "react";

import { CheckboxWrapper } from "./checkbox.styled";
import Check from "&assets/images/check.svg";

interface Props {
  id?: string;
  title?: any;
  selected?: boolean;
  handleClick?: Function;
  wrapperClassName?: string;
  hide?: boolean;
  hasError?: boolean;
}

const Checkbox = (props: Props) => {
  const { title, selected, handleClick, wrapperClassName, hide, id, hasError } =
    props;
  return (
    <CheckboxWrapper
      id={id}
      hide={hide}
      className={wrapperClassName}
      hasError={hasError}
      onClick={() => handleClick && handleClick()}
    >
      <div className="checkbox">
        {selected && <img src={Check} alt="check-icon" />}
      </div>

      <div className={'checkbox-title'}>{title}</div>
    </CheckboxWrapper>
  );
};

export default Checkbox;
