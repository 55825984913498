import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { GetCareersBody, LandingPage } from "./landingPage.type";
import {
  getCurrentCountryApi,
  getFooterCountriesApi,
  getLevelsApi,
  getPagesContentApi,
} from "&features/landingPage/landingPage.api";
import countriesSelector from "&utils/countriesSelector";

let defaultCountry: any = {
  title: "Lebanon",
  code: "961",
  createdAt: "2023-01-17T12:21:58.131Z",
  deletedAt: null,
  geoId: "101834488",
  id: 130,
  name: "Lebanon",
  nameAr: "لبنان",
  updatedAt: "2023-03-22T12:13:55.884Z",
  value: "130",
  valueAr: "130",
};

const initialState: LandingPage = {
  selectedCareerPath: "",
  loadingLevels: false,
  levels: [],
  status: "",
  homeContent: {},
  loadingContent: false,
  countries: undefined,
  countryChanged: false,
  selectedCountry: defaultCountry,
};

const getLevels = createAsyncThunk(
  "landingPage/getLevels",
  async (
    body: GetCareersBody,
    { rejectWithValue, getState, dispatch }: any
  ) => {
    try {
      const countryId = getState().landingPage.selectedCountry.value;
      const levels = await getLevelsApi(body.limit, countryId);
      return levels?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const getPagesContent = createAsyncThunk(
  "landingPage/getPagesContent",
  async (_, { rejectWithValue, getState, dispatch }: any) => {
    try {
      const content = await getPagesContentApi("home");
      return content?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const getCountries = createAsyncThunk(
  "landingPage/getCountries",
  async (_, { rejectWithValue, getState, dispatch }: any) => {
    let currentCountry = getState().landingPage?.selectedCountry;

    let currentCountryResponse: any = { data: undefined };
    try {
      const countries = await getFooterCountriesApi();
      const newCountries = countriesSelector(countries?.data);
      let countryFound = false;

      if (!getState().landingPage?.countryChanged) {
        try {
          currentCountryResponse = await getCurrentCountryApi();
        } catch (e) {
          console.error(e);
          currentCountry = defaultCountry;
        }
        newCountries?.map((country: any) => {
          if (country.name === currentCountryResponse?.data["country_name"]) {
            countryFound = true;
            currentCountry = country;
          }
        });
        if (!countryFound) {
          currentCountry = defaultCountry;
        }
      }

      return { countries: newCountries, current: currentCountry };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const landingPageSlice = createSlice({
  name: "landingPage",

  initialState: initialState,

  reducers: {
    setLandingPage: (state, action) => {
      return { ...state, ...action.payload };
    },
    reset: () => initialState,
  },

  extraReducers: (builder) => {
    builder.addCase(getLevels.pending, (state, action) => {
      state.loadingLevels = true;
    });
    builder.addCase(getLevels.fulfilled, (state, action) => {
      state.loadingLevels = false;
      state.levels = action.payload;
      state.status = "GET_LEVELS_SUCCESS";
    });
    builder.addCase(getLevels.rejected, (state, action) => {
      state.loadingLevels = false;
    });
    builder.addCase(getPagesContent.pending, (state, action) => {
      state.loadingContent = true;
    });
    builder.addCase(getPagesContent.fulfilled, (state, action) => {
      state.loadingContent = false;
      state.homeContent = action.payload;
      state.status = "GET_PAGES_CONTENT_SUCCESS";
    });
    builder.addCase(getPagesContent.rejected, (state, action) => {
      state.loadingContent = false;
    });

    builder.addCase(getCountries.pending, (state, action) => {});
    builder.addCase(getCountries.fulfilled, (state, action: any) => {
      const countries = action.payload?.countries;
      state.countries = countries;
      state.selectedCountry = action.payload?.current;
    });
    builder.addCase(getCountries.rejected, (state, action) => {});
  },
});

export const landingPageReducer = landingPageSlice.reducer;

export const landingPageActions = {
  ...landingPageSlice.actions,
  getLevels,
  getPagesContent,
  getCountries,
};
