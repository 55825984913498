import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { isEmpty } from "lodash";

import { RootState } from "&store/store";
import { learningPathwayActions } from "./learningPathway.slice";
import { LearningPathwayWrapper } from "./learningPathway.styled";
import LearningPathwayActivity from "&components/learningPathwayActivity/LearningPathwayActivity.component";
import MatchingOpportunities from "&components/matchingOpportunities/matchingOpportunities.component";
import LearningHeader from "&components/learningHeader/learningHeader.component";
import Container from "&components/container/container.component";
import CircularLoader from "&components/circularLoader/circularLoader.component";
import { learningPathCourses } from "&features/listingPage/listingPage.type";
import secondsToHours from "&utils/secondstoHours";
import { Clock, Document } from "&assets/constants/icons";
import { courseDetailsActions } from "&features/courseDetails/courseDetails.slice";
import { loginActions } from "&features/login/login.slice";
import { images } from "&assets/constants/images-urls";
import { checkIFEnrolledLP } from "&utils/checkIfEnrolled";
import ListItemsPartially from "&components/listItemsPartially/listItemsPartially.component";
import { translationSelector } from "&utils/translationSelector";
import { useAuth0 } from "@auth0/auth0-react";
import MessageActionModal from "&components/modals/messageActionModal/messageActionModal.component";
import { ERROR_TEXTS } from "&assets/constants/statics";
import { eligibilityStatusSelector } from "&utils/profile/profileSelectors";
import { hasExam } from "&utils/hasExam";

type ReduxProps = ConnectedProps<typeof connector>;

interface PopupDetails {
  show: boolean;
  type?: string;
  message?: string;
  buttonMessage?: string;
  buttonAction?: () => void;
}

const LearningPathwayComponent = (props: ReduxProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation(["learningPathway"]);
  const {
    selectedLearningPath,
    getLearningPathwayById,
    loading,
    setCourseDetails,
    enrollLearningPathway,
    isLoggedIn,
    loadingEnroll,
    status,
    setLearningPathway,
    getProfile,
    getActivityJobs,
    selectedCountry,
    setLogin,
    activityJobs,
    loadingJobs,
    user,
  } = props;
  let { id } = useParams();
  const { loginWithRedirect } = useAuth0();

  const popupDetailsInitial: PopupDetails = {
    show: false,
    type: "",
    message: "",
    buttonMessage: "",
    buttonAction: () => {},
  };

  const [popupDetails, setPopupDetails] =
    useState<PopupDetails>(popupDetailsInitial);

  const informatics: any = [
    {
      icon: <Document fill="white" />,
      text:
        selectedLearningPath?.learningActivities?.length + ` ${t("COURSES")}`,
    },
    {
      icon: <Clock fill="white" />,
      text: secondsToHours(selectedLearningPath?.duration),
    },
  ];

  useEffect(() => {
    if (id) {
      getLearningPathwayById(id);
      getActivityJobs({
        id,
        type: "learning-paths",
        body: {
          page: 1,
          limit: 9,
          countryId: selectedCountry.value,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, selectedCountry, localStorage.getItem("language")]);

  useEffect(() => {
    if (status === "ENROLLED_LEARNING_PATH_SUCCESSFULLY") {
      selectedLearningPath?.assessmentUrl &&
        window.open(
          selectedLearningPath?.assessmentUrl,
          "_blank",
          "noreferrer"
        );
      navigate("/dashboard/activities", { state: { learningPath: id } });
      setLearningPathway({ status: "" });
      getProfile({});
    }
    if (status === "USER_ALREADY_ENROLLED") {
      setPopupDetails({
        show: true,
        type: "already_enrolled",
        message: translationSelector(
          ERROR_TEXTS,
          "USER_ALREADY_ENROLLED_WAITING_ASSESSMENT"
        ),
        buttonAction: handleClosePopup,
        buttonMessage: "Close",
      });
      setLearningPathway({ status: "" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const loadingComponent = (
    <div className="loader-wrapper">
      <CircularLoader />
    </div>
  );

  const eligibilityStatus = eligibilityStatusSelector(user?.eligibilityForm);

  const handleClickActivity = (item: any, type: string) => {
    setCourseDetails({ selectedCourse: {} });
    navigate(`/${type}-details/${item.id}`);
  };

  const handleClosePopup = () => {
    setPopupDetails(popupDetailsInitial);
  };

  const handleEnrollLearningPathway = () => {
    if (checkIFEnrolledLP(selectedLearningPath?.id)) {
      window.open(
        translationSelector(selectedLearningPath, "url"),
        "_blank",
        "noreferrer"
      );
      return;
    }
    if (isLoggedIn) {
      if (selectedLearningPath?.type === "need_assessment") {
        if (eligibilityStatus === "initial") {
          setPopupDetails({
            show: true,
            type: "fill_eligibility",
            message: translationSelector(
              ERROR_TEXTS,
              "PLEASE_FILL_THE_ELIGIBILITY_FORM"
            ),
            buttonMessage: "Go to Eligibility Form",
            buttonAction: () => navigate("/dashboard/profile"),
          });
        } else {
          id && enrollLearningPathway(id);
        }
      } else {
        id && enrollLearningPathway(id);
      }
    } else {
      setLogin({
        actionAfterSignup: "enroll-learningPathway",
        id,
      });
      loginWithRedirect();
    }
  };

  const enrolled = checkIFEnrolledLP(selectedLearningPath?.id, user);

  return (
    <LearningPathwayWrapper>
      {loading ? (
        loadingComponent
      ) : (
        <>
          <LearningHeader
            title={translationSelector(selectedLearningPath, "name")}
            description={translationSelector(
              selectedLearningPath,
              "shortDescription"
            )}
            handleBackClick={() => navigate(-1)}
            providerName={selectedLearningPath?.source}
            startDate={selectedLearningPath?.startDate}
            enrollmentStartDate={selectedLearningPath?.enrollmentStartDate}
            enrollmentEndDate={selectedLearningPath?.enrollmentEndDate}
            image={selectedLearningPath?.image || images.learningPathwayImg}
            level={translationSelector(selectedLearningPath?.level, "alias")}
            informatics={informatics}
            hasButton={!enrolled}
            handleButtonClick={() => handleEnrollLearningPathway()}
            loading={loadingEnroll}
            isSponsored={selectedLearningPath?.sponsored}
            hasCertification={hasExam(selectedLearningPath)}
            buttonText={enrolled ? t("VIEW_LEARNING_PATHWAY") : t("ENROLL_NOW")}
          />

          <Container className="activities-container">
            <div className="learning-activities">
              <ListItemsPartially
                data={selectedLearningPath?.learningActivities}
                stickyExam={true}
                renderItem={(activity: learningPathCourses, index: number) => {
                  const item =
                    activity?.courses || activity?.exams || activity?.labs;
                  const type = activity?.labs
                    ? "lab"
                    : activity.exams
                    ? "exam"
                    : "course";
                  return (
                    <LearningPathwayActivity
                      key={index}
                      image={item.image || images.learningPathwayImg}
                      activity={item}
                      index={index + 1}
                      handleClick={() => {
                        handleClickActivity(item, type);
                      }}
                      isExam={!isEmpty(activity.exams)}
                    />
                  );
                }}
              />
            </div>
          </Container>
          <MatchingOpportunities
            opportunities={activityJobs}
            loading={loadingJobs}
            onAllButtonClick={() => navigate("/opportunities")}
          />
        </>
      )}
      <MessageActionModal
        open={popupDetails.show}
        handleClose={handleClosePopup}
        modalClassName={"voucher-modal"}
        content={popupDetails.message}
        actionButtonTitle={popupDetails.buttonMessage}
        onActionButtonClick={popupDetails.buttonAction}
      />
    </LearningPathwayWrapper>
  );
};

const mapStateToProps = (state: RootState) => ({
  selectedLearningPath: state.learningPathway.selectedLearningPath,
  loading: state.learningPathway.loading,
  loadingEnroll: state.learningPathway.loadingEnroll,
  isLoggedIn: state.login.isLoggedIn,
  user: state.login.user,
  status: state.learningPathway.status,
  selectedCountry: state.landingPage.selectedCountry,
  activityJobs: state.courseDetails.activityJobs,
  loadingJobs: state.courseDetails.loadingJobs,
});

const mapDispatchToProps = {
  getLearningPathwayById: learningPathwayActions.getLearningPathwayById,
  enrollLearningPathway: learningPathwayActions.enrollLearningPathway,
  setLearningPathway: learningPathwayActions.setLearningPathway,
  setCourseDetails: courseDetailsActions.setCourseDetails,
  getProfile: loginActions.getProfileThunk,
  setLogin: loginActions.setLogin,
  getActivityJobs: courseDetailsActions.getActivityJobs,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const LearningPathwayComponentRedux = connector(LearningPathwayComponent);

export { LearningPathwayComponentRedux as LearningPathwayComponent };
