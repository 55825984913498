import { colors } from "&assets/constants/colors";
import { device } from "&assets/constants/responsiveness";
import styled from "styled-components";

export const BlogsWrapper = styled.div`
  .listing-container {
    width: initial;
    box-sizing: border-box;
    margin-bottom: 48px;
  }
  .loader-wrapper {
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .list-wrapper {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: auto auto auto auto;
    justify-content: center;
    margin-top: 48px;
  }
  .blog-header {
    height: 349px;
    padding: 18px 64px;
    background: ${colors.primaryDark};
    .header-title {
      font-weight: 700;
      font-size: 48px;
      line-height: 110%;
      letter-spacing: -0.02em;
      color: #ffffff;
      margin: 0;
      margin-top: 17px;
      margin-bottom: 103px;
    }
    .back-button {
      background: transparent;
      padding: 0;
      border: none;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;
      cursor: pointer;
      .arrow {
        transform: rotate(180deg);
        margin-right: 14px;
      }

      .button-text {
        font-weight: 800;
        font-size: 18px;
        line-height: 160%;
        text-align: center;
        color: ${colors.white};
      }
    }
  }
  @media ${device.inBetween} {
    .list-wrapper {
      grid-template-columns: auto auto auto;
    }
  }

  @media ${device.tablet} {
    .list-wrapper {
      grid-template-columns: auto auto;
      padding: 0 37px;
    }
    .listing-container {
      padding: 0;
    }
    .blog-header {
      padding: 50px 0 0 0;
      height: auto;
      .header-title {
        ${(props) => (props.theme.dir === "rtl" ? "margin-right: 16px;" : "margin-left: 16px;")}
      }
      .back-button {
        ${(props) => (props.theme.dir === "rtl" ? "margin-right: 16px;" : "margin-left: 16px;")}
      }
    }
  }

  @media ${device.mobile} {
    .list-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0 37px;
    }
    .listing-container {
      padding: 0;
    }
    .blog-header {
      padding: 50px 0 0 0;
      height: auto;
      .header-title {
        ${(props) => (props.theme.dir === "rtl" ? "margin-right: 16px;" : "margin-left: 16px;")}
      }
      .back-button {
        ${(props) => (props.theme.dir === "rtl" ? "margin-right: 16px;" : "margin-left: 16px;")}
      }
    }
  }
`;
