import styled from "styled-components";

export const ProfileAdditionalDetailsWrapper = styled.div`
  padding-top: 20px;
  
  .profile-detail-wrapper {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;
