import styled from "styled-components";
import { colors } from "&assets/constants/colors";
import {device} from "&assets/constants/responsiveness";

export const ProfileWorkExperienceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 0;

  .job-title-edit-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 12px;
  }
  
  .location-wrapper-margin {
    margin-bottom: 11px;
  }

  .dot {
    width: 4px;
    height: 4px;
    border-radius: 4px;
    background: ${colors.primary};
    margin: 0 8px;
  }

  .company-dates-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 7px;
  }

  .job-title-text {
    font-weight: 700;
    font-size: 18px;
    line-height: 160%;
    display: flex;
    align-items: center;
    color: ${colors.primary};
  }

  .company-name-text {
    font-weight: 700;
    font-size: 16px;
    line-height: 160%;
    color: ${colors.dark};
  }

  .full-time-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    color: ${colors.dark};
    opacity: 0.6;
  }

  .date-period-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    color: ${colors.dark};
    opacity: 0.6;
  }

  .location-text {
    ${(props) =>
            props.theme.dir === "rtl"
                    ? "margin-right: 10px;"
                    : "margin-left: 10px;"}
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.05em;
    color: ${colors.dark};
  }

  .description-text {
    max-width: 576px;
    font-weight: 500;
    font-size: 16px;
    line-height: 160%;
    color: ${colors.dark};
  }
  
  
  @media ${device.tablet}, ${device.mobile} {
    .dot {
      display: none;
    }
    .company-dates-wrapper {
      flex-direction: column;
      align-items: flex-start;
    }
  }
`;
