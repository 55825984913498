import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { useMediaQuery } from "react-responsive";

import {
  MatchingCareerCardWrapper,
  TitleDescriptionSkillsWrapper,
} from "./matchingCareerCard.styled";
import {
  ArrowForwardIcon,
  PercentageCircleGraphic,
} from "&assets/constants/icons";
import SubmitButton from "&components/buttons/submitButton/submitButton.component";
import { colors } from "&assets/constants/colors";
import { size } from "&assets/constants/responsiveness";
import CloseIconButton from "&components/closeIcon/closeIcon.component";
import { CareerMatch } from "&features/careerMatching/careerMatching.type";
import SkillsList from "&components/skillsList/skillsList.component";
import { translationSelector } from "&utils/translationSelector";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { useAuth0 } from "@auth0/auth0-react";

interface Props {
  careerMatch: CareerMatch;
  selectedCareer: boolean;
  onClick: () => void;
  className?: string;
  onEnd?: () => void;
  disabled?: boolean;
  isSmall?: boolean;
  width?: any;
}

const MatchingCareerCard = (props: Props) => {
  const {
    careerMatch,
    onClick,
    width,
    selectedCareer,
    className,
    onEnd,
    disabled,
    isSmall = false,
  } = props;

  const [selected, setSelected] = useState(selectedCareer);
  const [openCard, setOpenCard] = useState(selectedCareer);
  const [number, setNumber] = useState(0);
  const navigate = useNavigate();

  const { t } = useTranslation(["careerMatching"]);
  const dir = i18n.dir();

  useEffect(() => {
    setOpenCard(selectedCareer);
  }, [selectedCareer]);

  const missingSkillsText = t("HERE_ARE_SOME_MISSING_SKILLS_FOR_YOU");

  const isMobile = useMediaQuery({ maxWidth: size.mobile });
  const isTablet = useMediaQuery({
    minWidth: size.mobile,
    maxWidth: size.tablet,
  });
  const { loginWithRedirect } = useAuth0();
  const isNotDesktop = isMobile || isTablet;
  const showEnrollNow = false;

  const renderEnrollButton = (className: string) => {
    return (
      <SubmitButton
        id={`enroll-${careerMatch?.name}`}
        title={t("ENROLL_NOW")}
        className={className + " enroll-button"}
        titleClassName="button-title"
        handleClick={() => loginWithRedirect()}
        icon={ArrowForwardIcon(colors.white)}
      />
    );
  };

  const renderPercentageCircle = (percentage: number) => {
    return (
      <div className="progress-circle-wrapper">
        <div className="progress-background" />
        <CircularProgress
          variant={"determinate"}
          value={dir === "rtl" ? -percentage : percentage}
        />
        <div className="percentage-circle">
          <div className="background" />
          <PercentageCircleGraphic
            className={"background-graphic"}
            selected={selected || openCard}
          />
          <div className="percentage-text">{`${percentage}%`}</div>
          <div className="text">{t("CAREER_MATCHING")}</div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    setNumber(careerMatch.learnerScore);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = () => {
    onClick();
  };

  const handleOnTransitionEnd = () => {
    if (disabled) {
      setSelected(!selected);
      disabled && onEnd && onEnd();
    }
  };

  return (
    <MatchingCareerCardWrapper
      width={width}
      onClick={() => (!disabled && !openCard ? handleClick() : null)}
      id={`career-matching-card-${careerMatch.id}`}
      openCard={openCard}
      selected={selected}
      className={className}
      onTransitionEnd={handleOnTransitionEnd}
      isSmall={isSmall}
    >
      <div className="circle-text-button-wrapper">
        {renderPercentageCircle(number ? Math.round(number * 100) : 0)}
        {(!isNotDesktop && (selected || openCard)) || (openCard && selected) ? (
          showEnrollNow && !isSmall && renderEnrollButton("desktop")
        ) : (
          <div className="career-name-text">
            {translationSelector(careerMatch, "name")}
          </div>
        )}
        {isSmall && openCard && (
          <div className="dashboard-actions">
            <SubmitButton
              id={`view-${careerMatch?.name}`}
              title={t("VIEW_CAREER")}
              className="view-button"
              titleClassName="view-button-title"
              handleClick={() => navigate(`/career-details/${careerMatch?.id}`)}
            />
          </div>
        )}
      </div>
      {openCard && selected ? (
        <TitleDescriptionSkillsWrapper>
          <div className="card-title">
            {translationSelector(careerMatch, "name")}
          </div>
          <div className="description">{missingSkillsText}</div>
          <div className="skills-wrapper">
            <SkillsList
              chipClassName={"skills-chip"}
              skills={careerMatch?.missingSkills}
              isChip={true}
              height={
                isSmall ? (isMobile || isTablet ? "75px" : "45px") : "75px"
              }
            />
          </div>
          {showEnrollNow && !isSmall && renderEnrollButton("mobile")}
          <CloseIconButton
            onClick={() => {}}
            className={
              isNotDesktop
                ? "mobile arrow-icon-mobile"
                : "desktop arrow-icon-desktop"
            }
            icon={ArrowForwardIcon(colors.primary, 20)}
          />
        </TitleDescriptionSkillsWrapper>
      ) : null}
    </MatchingCareerCardWrapper>
  );
};

// @ts-ignore
export default MatchingCareerCard;
// @ts-ignore
