import React from "react";
import { CareerLevelsWrapper, LevelButtonsWrapper } from "./allCareers.styled";
import { OptionsWrapper } from "&components/careerLevel/careerLevel.styled";
import ListAllCareers from "&components/listAllCareers/listAllCareers.component";
import DunnoWhichCareer from "&components/landing/dunnoWhichCareer/dunnoWhichCareer.component";
import SubmitButton from "&components/buttons/submitButton/submitButton.component";
import { useTranslation } from "react-i18next";

interface Props {
  short?: boolean;
  isAssessment?: boolean;
  handleLoadMore?: () => void;
  careers: any[];
  showAll?: boolean;
  hideJobs?: boolean;
  hideLoadMore: boolean;
  user: any;
}

const AllCareers = (props: Props) => {
  const {
    short,
    isAssessment,
    careers,
    hideLoadMore,
    showAll,
    hideJobs,
    handleLoadMore,
    user,
  } = props;
  const { t } = useTranslation(["listingPage"]);
  return (
    <LevelButtonsWrapper short={short}>
      <CareerLevelsWrapper>
        <OptionsWrapper>
          <div className="careers-container">
            <ListAllCareers
              short={short}
              careers={careers}
              hideJobs={hideJobs}
              showAll={showAll}
              isAssessment={isAssessment}
            />
            {careers.length > 0 && !hideLoadMore && (
              <center>
                <SubmitButton
                  titleClassName="load-more-title"
                  className={"button-wrapper"}
                  id={"load-more-button"}
                  title={t("LOAD_MORE")}
                  handleClick={handleLoadMore}
                />
              </center>
            )}
          </div>
          {!user?.name && <DunnoWhichCareer />}
        </OptionsWrapper>
      </CareerLevelsWrapper>
    </LevelButtonsWrapper>
  );
};

export default AllCareers;
