import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {EligibilityRequest, Profile} from "./profile.type";
import {
  getCountriesApi,
  getFieldsOfStudyApi,
  getInstitutesApi,
  postEligibilityApi, updateEligibilityApi
} from "&features/dashboard/screens/profile/profile.api";
import {loginActions} from "&features/login/login.slice";

const initialState: Profile = {
  loadingFieldsOfStudy: false,
  fieldsOfStudy: undefined,
  countries: undefined,
  loadingCountries: false,
  loadingInstitutes: false,
  loadingEligibility: false,
  eligibilityStatus: '',
  institutes: undefined,
  eligibilityForm: undefined,
};

const makeProfileApiCall = createAsyncThunk(

  "profile/makeProfileApiCallStatus",
  async (body: any) => {
    // Make your API call here
  }
);

const getFieldsOfStudy = createAsyncThunk(
  "profile/getFieldOfStudies",
  async (_,
    { rejectWithValue, getState, dispatch }: any
  ) => {
    try {
      const fieldsOfStudy = await getFieldsOfStudyApi();
      return fieldsOfStudy?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const getInstitutes = createAsyncThunk(
  "profile/getInstitutes",
  async (_,
    { rejectWithValue, getState, dispatch }: any
  ) => {
    try {
      const institutes = await getInstitutesApi();
      return institutes?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const getCountries = createAsyncThunk(
  "profile/getCountries",
  async (_,
         { rejectWithValue, getState, dispatch }: any
  ) => {
    try {
      const institutes = await getCountriesApi();
      return institutes?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const postEligibility = createAsyncThunk(
  "profile/postEligibility",
  async (body: EligibilityRequest,
         { rejectWithValue, getState, dispatch }: any
  ) => {
    try {
      const response = await postEligibilityApi(body);
      dispatch(loginActions.getUserEligibilityForm());
      return response?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const updateEligibility = createAsyncThunk(
  "profile/updateEligibility",
  async (body: EligibilityRequest,
         { rejectWithValue, getState, dispatch }: any
  ) => {
    try {
      const response = await updateEligibilityApi(body);
      dispatch(loginActions.getUserEligibilityForm());
      return response?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const profileSlice = createSlice({
  name: "profile",
  initialState: initialState,
  reducers: {
    setProfile: (state, action) => {
      return { ...state, ...action.payload };
    },
    reset: () => initialState,
  },

  extraReducers: (builder) => {
    builder.addCase(getFieldsOfStudy.pending, (state, action) => {
      state.loadingFieldsOfStudy = true;
    });
    builder.addCase(getFieldsOfStudy.fulfilled, (state, action) => {
      state.fieldsOfStudy = action.payload;
      state.loadingFieldsOfStudy = false;
    });
    builder.addCase(getFieldsOfStudy.rejected, (state, action) => {
      state.loadingFieldsOfStudy = false;
    });
    builder.addCase(getInstitutes.pending, (state, action) => {
      state.loadingInstitutes = true;
    });
    builder.addCase(getInstitutes.fulfilled, (state, action) => {
      state.institutes = action.payload;
      state.loadingInstitutes = false;
    });
    builder.addCase(getInstitutes.rejected, (state, action) => {
      state.loadingInstitutes = false;
    });
    builder.addCase(getCountries.pending, (state, action) => {
      state.loadingCountries = true;
    });
    builder.addCase(getCountries.fulfilled, (state, action) => {
      state.countries = action.payload;
      state.loadingCountries = false;
    });
    builder.addCase(getCountries.rejected, (state, action) => {
      state.loadingCountries = false;
    });
    builder.addCase(postEligibility.pending, (state, action) => {
      state.loadingEligibility = true;
    });
    builder.addCase(postEligibility.fulfilled, (state, action) => {
      state.eligibilityStatus = 'ELIGIBILITY_POST_SUCCESS'
      state.eligibilityForm = action.payload
      state.loadingEligibility = false;
    });
    builder.addCase(postEligibility.rejected, (state, action: any) => {
      state.loadingEligibility = false;
      state.eligibilityStatus = action.payload?.response?.data?.message
    });
    builder.addCase(updateEligibility.pending, (state, action) => {
      state.loadingEligibility = true;
    });
    builder.addCase(updateEligibility.fulfilled, (state, action) => {
      state.eligibilityStatus = 'UPDATE_ELIGIBILITY_SUCCESS'
      state.eligibilityForm = action.payload
      state.loadingEligibility = false;
    });
    builder.addCase(updateEligibility.rejected, (state, action: any) => {
      state.loadingEligibility = false;
      state.eligibilityStatus = action.payload?.response?.data?.message
    });
  },
});

export const profileReducer = profileSlice.reducer;

export const profileActions = { ...profileSlice.actions, updateEligibility, postEligibility, makeProfileApiCall, getFieldsOfStudy, getInstitutes, getCountries };
