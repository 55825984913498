import styled from "styled-components";
import { colors } from "&assets/constants/colors";
import { device } from "&assets/constants/responsiveness";

export const BuildProfileStepOneWrapper = styled.div`
  min-height: 400px;
  max-height: fit-content;
  padding: 48px 75px;
  * {
    direction: ${(props) => props.theme.dir} !important;
  }
  .section-wrapper {
    margin-bottom: 43px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .loader-wrapper {
    height: 400px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .latest-education-options {
    margin-bottom: 24px;
  }

  .add-experience-button {
    margin-top: 24px;
  }

  @media ${device.tablet}, ${device.mobile} {
    padding: 0 16px;
  }
`;

export const AddExperienceButton = styled.button`
  display: flex;
  border: 0;
  padding: 0;
  background: none;

  cursor: pointer;

  .button-text {
    ${(props) => (props.theme.dir === "rtl" ? "margin-right: 5px;" : "margin-left: 5px;")}
    font-weight: 800;
    font-size: 14px;
    line-height: 160%;
    /* or 22px */

    text-align: center;
    background: ${colors.linearGradientOne};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
`;

export const FormSectionInputWrapper = styled.div`
  .please-note-wrapper {
    ${(props) => (props.theme.dir === "rtl" ? "gap: 5px;" : "")};
  }
  .options-wrapper {
    margin-bottom: 24px;
  }
  .input-info {
    font-size: 12px;
  }

  .minus-checkbox-wrapper {
    display: flex;
    flex-direction: column;
    height: 117px;
    justify-content: space-between;
  }

  .checkbox-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    color: ${colors.dark};
    cursor: pointer;
    opacity: 0.7;
  }

  .input-margin-bottom {
    margin-bottom: 16px;
    max-width: 425px;
  }

  .input-min-width {
    width: 425px;
  }

  .grid-checkbox-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .current-work-experience {
    height: 50px;
    display: flex;
    align-items: center;
  }

  .input-grid {
    display: grid;
    width: 100%;
    grid-gap: 16px;
    max-width: 846px;
    grid-template-columns: 50% auto;
  }

  @media ${device.mobile}, ${device.tablet}, ${device.inBetween} {
    .input-grid {
      display: flex;
      max-width: initial;
      flex-direction: column;
      grid-gap: 10px;
      grid-template-columns: auto auto;
    }

    .grid-checkbox-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: initial;
      align-items: initial;
      margin-bottom: 27px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .minus-checkbox-wrapper {
      flex-direction: row-reverse;
      align-items: center;
      height: 100%;
    }
    .input-min-width {
      width: 100%;
    }

    .input-margin-bottom {
      margin-bottom: 11px;
      max-width: 100%;
    }
  }
`;
