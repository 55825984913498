export const assessmentNameSpace = {
  en: {
    SELECT_YOUR_AGE_RANGE: "Select your age range",
    SELECT_YOUR_EDUCATION_LEVEL: "Select your education level",
    SELECT_YOUR_ENGLISH_PROFICIENCY: "Select your english proficiency level",
    ADD_YOUR_SKILLS: "Add your skills",
    SELECT_AT_LEAST_3_SKILLS_TO_CONTINUE: "Select at least 3 skills to continue",
    TECH_SKILLS: "Tech Skills",
    SOFT_SKILLS: "Soft Skills",
    OTHER_SKILLS: "Other Skills",
    MAIN_SKILLS: "Your Main Skills",
    RECOMMENDED_SKILLS: "Recommended Skills",
    beginner: "beginner",
    intermediate: "intermediate",
    SELECT_FROM_THE_LIST: "Select from the list",
    advanced: "advanced",
    ANY_OTHER_SKILLS_ENTER_KEYWORDS: "Any other skills? Enter keywords",
    ANY_OTHER_REFERRALS_ENTER_KEYWORDS: "Any other referrals? Enter keywords",
    ADD_UP_TO_10_SKILLS: "Add up to 10 skills",
    ADD_UP_TO_10_REFERRALS: "Add up to 10 referrals",
    NEXT: "Next",
    BACK: "Back",
    CONGRATULATIONS: "Congratulations! You have successfully completed the assessment of your profile",
    OUR_SYSTEM_IS_IDENTIFYING_THE_TOP_COURSES:
      "Our system is identifying the top courses and learning paths that align with your career aspirations, helping you reach your desired job",
    YOUR_PERSONALIZED_LEARNING_ACTIVITIES_ARE_LOADING: "Your personalized learning activities are loading…",
    GET_YOUR_CAREER_MATCHING: "Get your career matching scorecard",
    BY_FOLLOWING_OUR_STEPS_YOU_WILL_EASILY: "By following our steps you will easily get started in nammiskills",
  },
  ar: {
    SELECT_YOUR_AGE_RANGE: "اختر عمرك",
    SELECT_YOUR_EDUCATION_LEVEL: "اختر مستوى تعليمك",
    SELECT_YOUR_ENGLISH_PROFICIENCY: "حدد مستوى إتقان اللغة الإنجليزية الخاص بك",
    ADD_YOUR_SKILLS: "أضف مهاراتك",
    SELECT_AT_LEAST_3_SKILLS_TO_CONTINUE: "اختر ما لا يقل عن 3 مهارات",
    TECH_SKILLS: "المهارات التقنية",
    SOFT_SKILLS: "المهارات الشخصية",
    OTHER_SKILLS: "المهارات الأخرى",
    MAIN_SKILLS: "مهاراتك الاساسية",
    beginner: "مبتدئ",
    intermediate: "متوسط",
    advanced: "متقدم",
    ANY_OTHER_SKILLS_ENTER_KEYWORDS: "أي مهارات أخرى؟ أدخل الكلمات الرئيسية",
    ANY_OTHER_REFERRALS_ENTER_KEYWORDS: "أي الإحالات أخرى؟ أدخل الكلمات الرئيسية",
    ADD_UP_TO_10_SKILLS: "أضف ما يصل إلى 10 مهارات",
    ADD_UP_TO_10_REFERRALS: "أضف ما يصل إلى 10 إحالات",
    SELECT_FROM_THE_LIST: "اختر من القائمة",
    NEXT: "التالي",
    BACK: "السابق",
    CONGRATULATIONS: "مبروك! لقد أكملت التقييم الشخصي بنجاح",
    OUR_SYSTEM_IS_IDENTIFYING_THE_TOP_COURSES:
      "Our system is identifying the top courses and learning paths that align with your career aspirations, helping you reach your desired job",
    YOUR_PERSONALIZED_LEARNING_ACTIVITIES_ARE_LOADING: "جاري تحميل الأنشطة التعليمية المُخصصة لك",
    GET_YOUR_CAREER_MATCHING: "احصل على درجة مطابقتك مع المهنة التي اخترتها",
    BY_FOLLOWING_OUR_STEPS_YOU_WILL_EASILY: 'اتّبع خطواتنا لكي تستطيع التقدم على منصة "نمّي سكيلز" بسهولة',
    RECOMMENDED_SKILLS: "المهارات الموصى بها",
  },
};
