import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { Notifications } from "./notifications.type";
import { findAndReadNotificationById } from "&utils/searchSortFilter/searchSortFilterUtils";
import {
  getNotifications,
  readBannerNotifications,
} from "&features/notifications/notifications.api";

const initialState: Notifications = {
  panelNotifications: undefined,
  bannerNotifications: undefined,
  loadingPanelNotifications: false,
  loadingBannerNotifications: false,
};

const getPanelNotifications = createAsyncThunk(
  "notifications/getPanelNotifications",
  async (_, { rejectWithValue, getState, dispatch }: any) => {
    try {
      const response = await getNotifications("panel");
      return response?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const getBannerNotifications = createAsyncThunk(
  "notifications/getBannerNotifications",
  async (_, { rejectWithValue, getState, dispatch }: any) => {
    try {
      const response = await getNotifications("banner");
      return response?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const markBannerAsRead = createAsyncThunk(
  "notifications/markBannerAsRead",
  async (id: number, { rejectWithValue, getState, dispatch }: any) => {
    try {
      const bannerNotifications = getState().notifications.bannerNotifications;
      await readBannerNotifications(id);
      const response = findAndReadNotificationById(bannerNotifications, id);
      return response;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const notificationsSlice = createSlice({
  name: "notifications",
  initialState: initialState,
  reducers: {
    setNotifications: (state, action) => {
      return { ...state, ...action.payload };
    },
    reset: () => initialState,
  },

  extraReducers: (builder) => {
    builder.addCase(getPanelNotifications.pending, (state, action) => {
      state.loadingPanelNotifications = true;
    });
    builder.addCase(getPanelNotifications.fulfilled, (state, action) => {
      state.loadingPanelNotifications = false;
      state.panelNotifications = action.payload;
    });
    builder.addCase(getPanelNotifications.rejected, (state, action) => {
      state.loadingPanelNotifications = false;
    });
    builder.addCase(getBannerNotifications.pending, (state, action) => {
      state.loadingBannerNotifications = true;
    });
    builder.addCase(getBannerNotifications.fulfilled, (state, action) => {
      state.loadingBannerNotifications = false;
      state.bannerNotifications = action.payload;
    });
    builder.addCase(getBannerNotifications.rejected, (state, action) => {
      state.loadingBannerNotifications = false;
    });
    builder.addCase(markBannerAsRead.pending, (state, action) => {
      state.loadingBannerNotifications = true;
    });
    builder.addCase(markBannerAsRead.fulfilled, (state, action) => {
      state.loadingBannerNotifications = false;
      state.bannerNotifications = action.payload;
    });
    builder.addCase(markBannerAsRead.rejected, (state, action) => {
      state.loadingBannerNotifications = false;
    });
  },
});

export const notificationsReducer = notificationsSlice.reducer;

export const notificationsActions = {
  ...notificationsSlice.actions,
  getPanelNotifications,
  getBannerNotifications,
  markBannerAsRead,
};
