import { colors } from "&assets/constants/colors";
import { device } from "&assets/constants/responsiveness";
import styled from "styled-components";

export const FooterWrapper = styled.div`
  position: relative;
  background: ${colors.linearGradientWhite} padding-box, ${colors.linearGradientOne} border-box;
  padding: 64px 64px;
  border-top: 1px solid transparent;
  .separator {
    margin-top: 20px;
    border: 0;
    background: ${colors.linearGradientOne};
    width: 100%;
    padding: 0.5px;
  }
  .socials {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      font-style: normal;
      font-weight: 800;
      font-size: 18px;
      line-height: 160%;
      text-align: center;
      color: ${colors.primary};
      margin-top: 5px;
      text-align: left;
    }
    .social-wrapper {
      .title {
        font-size: 13px;
      }
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }
    .social-btns {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      width: 33%;
      gap: 10px;
      img {
        margin: 0;
        cursor: pointer;
      }
    }
    .informative {
      width: 33%;
      display: flex;
      justify-content: space-between;
    }
    .rights {
      font-size: 14px;
      color: ${colors.dark};
      width: 33%;
      font-weight: 400;
    }
    .more-actions {
      font-size: 14px;
      cursor: pointer;
    }
  }
  .bottom-content {
    display: grid;
    grid-template-columns: 25% 35% 23% 15%;
    align-items: flex-start;
    img {
      margin-top: 5px;
    }
    .country-wrapper {
      margin-top: 10px;
      .drop-down-wrapper {
        min-width: 250px;
        max-width: fit-content;
        width: fit-content;
        @media ${device.tablet} {
          width: 423px;
        }

        @media ${device.mobile} {
          width: 90vw;
        }
      }

      .drop-down-list-wrapper {
        width: fit-content;
        @media ${device.mobile} {
          width: 90vw;
        }
      }
    }
    .country {
      width: fit-content;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }
    .actions {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: flex-start;
      .action-text {
        ${(props) => (props.theme.dir === "rtl" ? "text-align: right;" : "text-align: left;")}
        font-size: 13px;
      }
      .action-text-notification {
        font-family: "Mulish";
        font-style: normal;
        font-weight: 800;
        font-size: 18px;
        line-height: 160%;
        color: ${colors.dark};
      }

      .action-btn {
        padding: 0;
        margin-bottom: 10px;
      }
      .subtitle {
        font-family: "Mulish";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 160%;
        color: ${colors.dark};
      }
    }
    .subscribe-content {
      display: flex;
      align-items: center;
      width: 100%;
      .subscribe-btn {
        width: 134px;
        height: 61px;
        background: ${colors.linearGradientOne};
        ${(props) => (props.theme.dir === "rtl" ? "margin-right: 10px;" : "margin-left: 10px;")}
      }
    }
  }
  @media ${device.tablet} {
    padding: 40px 16px;
    .actions {
      width: auto;
    }
    .socials {
      flex-wrap: wrap;
      justify-content: center;
      .more-actions {
        margin: 20px;
      }
      .social-btns {
        width: auto;
        margin: 0;
      }
      .social-wrapper {
        width: 100%;
      }
      .informative {
        width: auto;
      }
      .rights {
        width: auto;
      }
    }

    .bottom-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: inherit;
      .country {
        width: calc(40%+100px);
        margin-bottom: 30px;
      }
      .actions {
        width: auto;
        .action-text {
          font-size: 16px;
        }
        .action-text-notification {
          font-size: 16px;
        }
      }
    }
    .email-input {
      width: 40%;
      height: 38px;
      min-width: auto;
    }
    .subscribe-btn {
      width: 100px !important;
      height: 38px !important;
      min-width: auto;
      .title {
        font-size: 14px;
      }
    }
  }
  @media ${device.mobile} {
    padding: 40px 16px;
    .bottom-content {
      .actions {
        margin-top: 10px;
        width: auto;
        .action-text-notification {
          font-family: "Mulish";
          font-style: normal;
          font-weight: 800;
          font-size: 16px;
          line-height: 160%;
          color: ${colors.dark};
          margin-bottom: -20px;
        }
        .action-text {
          font-size: 16px;
        }
      }
    }
    .socials {
      flex-wrap: wrap;
      justify-content: center;
      .more-actions {
        margin: 5px;
      }
      .social-wrapper {
        width: 100%;
      }
      .social-btns {
        width: auto;
        margin: 0;
      }
      .informative {
        width: auto;
      }
      .rights {
        width: auto;
      }
    }
    .bottom-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: inherit;
      .country {
        width: auto;
        margin-bottom: 30px;
      }
      .actions {
        width: auto;
      }
    }
    .email-input {
      width: 70%;
      height: 38px;
      min-width: auto;
    }
    .subscribe-btn {
      width: 30% !important;
      height: 38px !important;
      min-width: auto;
      .title {
        font-size: 14px;
      }
    }
    .social-btns {
      display: flex;
      justify-content: center;
      margin: 40px 0 24px 0;
      width: auto;
      img {
        margin: 0 15px;
      }
    }
  }
`;
