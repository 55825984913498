import React, { useEffect, useRef, useState } from "react";
import { connect, ConnectedProps, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { filter, isEmpty, split, map, size as lodashSize, find } from "lodash";

import { RootState } from "&store/store";
import { profileActions } from "./profile.slice";
import {
  AboutMeWrapper,
  ProfileContentWrapper,
  ProfileGrid,
  ProfileInnerBannerWrapper,
  ProfileInnerContentWrapper,
  ProfileWrapper,
} from "./profile.styled";
import CompleteProfilePrompt from "&components/prompts/completeProfilePrompt/completeProfilePrompt.component";
import ProfileSectionTitle from "&components/profile/profileSectionTitle/profileSectionTitle.component";
import EditRoundButton from "&components/buttons/editRoundButton/editRoundButton.component";
import {
  AdditionalDetails,
  CertificationItem,
  EditSelection,
  EducationItem,
  PersonalDetails,
  ProfileSection,
  SectionTypes,
  SocialLinks,
  WorkExperience,
} from "&features/dashboard/screens/profile/profile.type";
import SubmitButton from "&components/buttons/submitButton/submitButton.component";
import { ArrowForwardIcon, PlusIcon } from "&assets/constants/icons";
import ProfileWorkExperience from "&components/profile/profileWorkExperience/profileWorkExperience.component";
import Container from "&components/container/container.component";
import ProfileEducation from "&components/profile/profileEducation/profileEducation.component";
import ProfileSkills from "&components/profile/profileSkills/profileSkills.component";
import ProfileAdditionalDetails from "&components/profile/profileAdditionalDetails/profileAdditionalDetails.component";
import ProfileSocialLinks from "&components/profile/profileSocialLinks/profileSocialLinks.component";
import ProfileEditModal from "&components/profile/edit/profileEditModel/profileEditModal.component";
import { loginActions } from "&features/login/login.slice";
import {
  certificationSelector,
  educationSelector,
  eligibilitySelector,
  workExperienceSelector,
} from "&utils/profile/profileSelectors";
// import ProfilePersonalDetails from "&components/profile/profilePersonalDetails/profilePersonalDetails.component";
import DownloadCV from "&components/downloadCV/downloadCV.component";
import { downloadCV } from "&utils/profile/downloadCV";
import DashboardMenu from "&components/dashboardMenu/dashboardMenu.component";
import ProfileCertificate from "&components/profile/profileCertificate/profileCertificate.component";
import { colors } from "&assets/constants/colors";
import { useLocation, useNavigate } from "react-router-dom";
import { eligibilityFormTexts } from "&assets/constants/statics";
import NewLineText from "&components/texts/newLineText/newLineText.component";
import { useMediaQuery } from "react-responsive";
import { size } from "&assets/constants/responsiveness";
import toast from "react-hot-toast";
import ChangeCareerSection from "&components/profile/changeCareerSection/changeCareerSection.component";
import i18n from "&config/i18n";
import { translationSelector } from "&utils/translationSelector";
import { Grid } from "@mui/material";

type ReduxProps = ConnectedProps<typeof connector>;

const ProfileComponent = (props: ReduxProps) => {
  const isArabic = i18n.language === "ar";

  const { user, updateProfileStatus, setLogin, getFieldsOfStudy, getInstitutes } = props;
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: size.mobile });
  const isTablet = useMediaQuery({
    minWidth: size.mobile,
    maxWidth: size.tablet,
  });

  const isDesktop = !isMobile && !isTablet;
  const settings = useSelector((state: RootState) => state.global.settings);

  const personalDetails: PersonalDetails = {
    gender: user?.gender,
    dateOfBirth: user?.dateOfBirth ? moment(user?.dateOfBirth) : undefined,
  };

  const componentRef = useRef<any>();

  const additionalDetails: AdditionalDetails = {
    email: user?.email,
    phoneNumber: user?.phoneNumber,
    languages: filter(settings?.language_option, (lang: any) => split(user?.languages, ",").includes(lang.name)),
  };

  const socialLinks: SocialLinks = {
    linkedin: user?.linkedin,
    portfolio: user?.portfolio,
    website: user?.website,
  };

  const { state } = useLocation();
  const { t } = useTranslation(["profile"]);

  const [selectedEdit, setSelectedEdit] = useState<EditSelection>({
    section: undefined,
    initialValues: undefined,
    index: -1,
  });

  const isEmployer = false;

  const [showPrompt, setShowPrompt] = useState(true);
  const [cvMode, setCVMode] = useState(false);

  useEffect(() => {
    if (cvMode) {
      const cvName =
        user?.name && user?.lastName
          ? user?.name + " " + user?.lastName + (isArabic ? " - السيرة الذاتية.pdf" : " - Resume.pdf")
          : "السيرة الذاتية.pdf";
      const text: any = document.querySelector("#capture");
      downloadCV({
        text: text,
        cvName: cvName,
        onFinish: () => setCVMode(false),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cvMode]);

  const viewMode = cvMode || isEmployer;

  useEffect(() => {
    if (state?.openEligibiltyForm) {
      handleAddEditSection("eligibilityForm", user?.eligibilityForm ? eligibilitySelector(user?.eligibilityForm) : {});
    }
    getInstitutes();
    getFieldsOfStudy();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (updateProfileStatus === "UPDATE_PROFILE_SUCCESSFUL") {
      setSelectedEdit({
        section: undefined,
        initialValues: undefined,
      });
      setLogin({ updateProfileStatus: "" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateProfileStatus]);

  const handleAddEditSection = (section: SectionTypes, initialValue?: any, index?: number) => {
    setSelectedEdit({
      section: section,
      index,
      initialValues: initialValue || undefined,
    });
  };

  const renderBannerWithTitle = (props: ProfileSection) => {
    const { title, content, actionButton, closeButton } = props;

    return (
      <ProfileInnerBannerWrapper>
        {closeButton}
        <div className="banner-title">{title}</div>
        <div className="content-wrapper">
          <NewLineText className="banner-content-text" text={content} />
        </div>
        {actionButton}
      </ProfileInnerBannerWrapper>
    );
  };

  const renderContentWithTitle = (props: ProfileSection) => {
    const { title, content, topRightButton, isSmall, isCentered } = props;

    return (
      <ProfileInnerContentWrapper>
        <ProfileSectionTitle
          isSmall={isSmall}
          title={title}
          viewMode={viewMode}
          isCentered={isCentered}
          rightButton={topRightButton}
        />
        {content}
      </ProfileInnerContentWrapper>
    );
  };

  const AboutMeContent = (props: { text?: string }) => {
    const { text } = props;

    return <AboutMeWrapper>{text && <div className="text">{text}</div>}</AboutMeWrapper>;
  };

  const ExperienceContent = (props?: any) => {
    const experiencesArray = user?.workExperience && workExperienceSelector(user?.workExperience);
    return (
      <div className="detail-sections-wrapper">
        {experiencesArray?.map((item: WorkExperience, index: number) => (
          <div key={index}>
            <ProfileWorkExperience
              key={index}
              {...item}
              editValue={viewMode ? undefined : () => handleAddEditSection("experience", item, index)}
            />
            {index < experiencesArray?.length - 1 && <div className="line-section" />}
          </div>
        ))}
      </div>
    );
  };

  const EducationContent = () => {
    const educationsArray = user?.education && educationSelector(user?.education);

    return (
      <div className="detail-sections-wrapper">
        {map(educationsArray, (item: EducationItem, index: number) => (
          <div key={index}>
            <ProfileEducation
              key={index}
              {...item}
              editValue={viewMode ? undefined : () => handleAddEditSection("education", item, index)}
            />
            {index < lodashSize(educationsArray) - 1 && <div className="line-section" />}
          </div>
        ))}
      </div>
    );
  };

  const CertificationsContent = (props?: any) => {
    const certificationArray = user?.userCertificates && certificationSelector(user?.userCertificates);

    return (
      <div className="detail-sections-wrapper">
        {certificationArray?.map((item: CertificationItem, index: number) => (
          <div key={index}>
            <ProfileCertificate
              key={index}
              {...item}
              editValue={viewMode ? undefined : () => handleAddEditSection("certifications", item, index)}
            />
            {index < certificationArray?.length - 1 && <div className="line-section" />}
          </div>
        ))}
      </div>
    );
  };

  const handleDownloadCV = async () => {
    setCVMode(true);
  };

  const ChangeCareerContent = () => {
    return <ChangeCareerSection user={user} />;
  };
  // const PersonalDetailsContent = (props?: any) => {
  //   return <ProfilePersonalDetails {...personalDetails} />;
  // };

  const AdditionalDetailsContent = (props?: any) => {
    if (user && user?.nationalityId !== null)
      additionalDetails["nationality"] = isArabic ? user?.nationality?.nameAr : user?.nationality?.name;
    if (user && user?.disability !== null) additionalDetails["disability"] = user?.disability;

    if (user && user?.disability === true && user?.disabilityDescription !== null)
      additionalDetails["disabilityDescription"] = user?.disabilityDescription;

    return <ProfileAdditionalDetails viewMode={cvMode} {...additionalDetails} {...personalDetails} />;
  };

  const SocialLinksContent = (props?: any) => {
    return <ProfileSocialLinks viewMode={cvMode} {...socialLinks} />;
  };

  const mainSections: ProfileSection[] = [
    {
      title: t("ABOUT_ME"),
      content: <AboutMeContent text={user?.aboutMe} />,
      topRightButton: (
        <EditRoundButton
          onClick={() => handleAddEditSection("aboutMe", user?.aboutMe)}
          className="edit-button-wrapper"
        />
      ),
      hide: cvMode && !user?.aboutMe,
    },
    {
      title: t("EXPERIENCE"),
      content: <ExperienceContent />,
      topRightButton: (
        <SubmitButton
          title={isDesktop ? t("ADD_EXPERIENCE") : t("ADD")}
          id={"add-experience-button"}
          handleClick={() => handleAddEditSection("experience")}
          leftIcon={<PlusIcon />}
          className="add-button-wrapper"
          titleClassName="add-button-title"
        />
      ),
      // hide: cvMode && user?.workExperience && user?.workExperience?.length === 0,
    },
    {
      title: t("EDUCATION"),
      content: <EducationContent />,
      topRightButton: (
        <SubmitButton
          title={isDesktop ? t("ADD_EDUCATION") : t("ADD")}
          id={"add-education-button"}
          handleClick={() => handleAddEditSection("education")}
          leftIcon={<PlusIcon />}
          className="add-button-wrapper"
          titleClassName="add-button-title"
        />
      ),
      // hide: cvMode && user?.education && user?.education?.length === 0,
    },
    {
      title: t("SKILLS"),
      content: <ProfileSkills />,
      topRightButton: (
        <EditRoundButton onClick={() => handleAddEditSection("skills")} className="edit-button-wrapper" />
      ),
    },
    {
      title: t("CERTIFICATIONS"),
      content: <CertificationsContent />,
      topRightButton: (
        <SubmitButton
          title={isDesktop ? t("ADD_CERTIFICATION") : t("ADD")}
          id={"add-certification-button"}
          handleClick={() => handleAddEditSection("certifications")}
          leftIcon={<PlusIcon />}
          className="add-button-wrapper secondary-color"
          titleClassName="add-button-title"
        />
      ),
      hide: cvMode && user?.userCertificates && user?.userCertificates?.length === 0,
    },
  ];
  const eligibilityData = (user?.eligibilityForm && eligibilitySelector(user?.eligibilityForm)) || {};

  const isAwaiting = !isEmpty(eligibilityData.residence) && !eligibilityData?.approved && !eligibilityData.rejected;

  const stateSelector = isAwaiting
    ? "pending"
    : eligibilityData?.rejected === 2 && !eligibilityData?.approved
    ? "rejected"
    : eligibilityData?.approved
    ? "approved"
    : "initial";

  const showBundleBtn = user && (stateSelector === "rejected" || user?.isEQ2 || stateSelector === "initial");

  const sideSections: ProfileSection[] = [
    {
      title: t("SPONSORSHIP_ELIGIBILITY_APPLICATION"),
      content: eligibilityFormTexts("description", stateSelector, translationSelector(eligibilityData, "reason")),
      isBanner: true,
      hide: cvMode,
      actionButton: (
        <Grid display="flex" flexWrap="wrap" justifyContent="center" gap={2}>
          <SubmitButton
            id={"discounts-apply-now-button"}
            title={eligibilityFormTexts("buttonText", stateSelector)}
            handleClick={() => {
              if (!user?.nationalityId) {
                toast.error(
                  translationSelector(
                    {
                      name: "Please complete your Personal Information before applying for Eligibility",
                      nameAr: "يرجى إكمال معلوماتك الشخصية قبل التقدم بطلب الأهلية",
                    },
                    "name"
                  )
                );

                handleAddEditSection("nationality", user);
              } else if (user?.dateOfBirth && user?.dateOfBirth?.length > 0 && user?.gender && user?.gender?.length > 0)
                handleAddEditSection("eligibilityForm", eligibilityData);
              else toast.error(t("PLEASE_FILL_IN_YOUR_DATE_OF_BIRTH_AND_GENDER"));
            }}
            className="action-button-wrapper"
            titleClassName="action-button-text"
            icon={ArrowForwardIcon(colors.primary, 16)}
          />
          {showBundleBtn && (
            <SubmitButton
              id={"discounts-apply-now-button"}
              title={t("VIEW_BUNDLES")}
              handleClick={() => navigate("/bundles")}
            />
          )}
        </Grid>
      ),
    },
    {
      title: t("YOUR_SELECTED_CAREER"),
      content: <ChangeCareerContent />,
      isCentered: true,
      hide: cvMode,
      isSmall: true,
    },
    {
      title: t("PERSONAL_DETAILS"),
      content: <AdditionalDetailsContent />,
      isSmall: true,
      topRightButton: (
        <EditRoundButton
          onClick={() => handleAddEditSection("additionalDetails", additionalDetails)}
          className="edit-button-wrapper"
        />
      ),
    },
    {
      title: t("SOCIAL_LINKS"),
      content: <SocialLinksContent />,
      hide: cvMode && !user?.linkedin && !user?.portfolio && !user?.website,
      isSmall: true,
      topRightButton: (
        <EditRoundButton
          onClick={() => handleAddEditSection("socialLinks", socialLinks)}
          className="edit-button-wrapper secondary-color"
        />
      ),
    },
    {
      title: t("CV"),
      content: <DownloadCV handleClick={() => handleDownloadCV()} />,
      isSmall: true,
      hide: cvMode || isMobile,
    },
  ];

  return (
    <ProfileWrapper cvMode={cvMode}>
      <Container className="profile-container">
        {showPrompt && user?.completionScore && user?.completionScore < 50 && (
          <CompleteProfilePrompt
            className="prompt-wrapper"
            title={t("COMPLETE_YOUR_PROFILE")}
            subtitle={t("COMPLETE_YOUR_PROFILE_TO_BENEFIT_FROM_MORE_ACCURATE")}
            percentage={user?.completionScore}
            onClose={() => setShowPrompt(false)}
          />
        )}
        <div id="capture" className="capture-wrapper">
          {cvMode && <DashboardMenu viewMode={cvMode} />}
          <ProfileGrid ref={componentRef}>
            <div className="column">
              {mainSections.map(
                (section: ProfileSection, index: number) =>
                  !section.hide && (
                    <ProfileContentWrapper key={index} viewMode={cvMode}>
                      {renderContentWithTitle(section)}
                    </ProfileContentWrapper>
                  )
              )}
            </div>
            <div className="column">
              {sideSections.map(
                (section: ProfileSection, index: number) =>
                  !section.hide && (
                    <ProfileContentWrapper
                      key={index}
                      isSmall={true}
                      isBanner={section.isBanner}
                      isCentered={section.isCentered}
                      viewMode={cvMode}
                    >
                      {section.isBanner ? renderBannerWithTitle(section) : renderContentWithTitle(section)}
                    </ProfileContentWrapper>
                  )
              )}
            </div>
          </ProfileGrid>
        </div>
      </Container>
      <ProfileEditModal
        handleClose={() => setSelectedEdit({ section: undefined, initialValues: undefined })}
        open={!!selectedEdit?.section}
        selectedSection={selectedEdit}
      />
    </ProfileWrapper>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: state.login.user,
  updateProfileStatus: state.login.updateProfileStatus,
});

const mapDispatchToProps = {
  setLogin: loginActions.setLogin,
  getFieldsOfStudy: profileActions.getFieldsOfStudy,
  getInstitutes: profileActions.getInstitutes,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const ProfileComponentRedux = connector(ProfileComponent);

export { ProfileComponentRedux as ProfileComponent };
