import { API } from "aws-amplify";
import { RES_EXAMS, RES_PAGES } from "&store/resources";

const getCertificationsAPI = async (source: string | undefined | null) => {
  let URL = RES_EXAMS + `/learning-paths/certificates`;
  if (source) URL = URL + `?source=${source}`;
  return API.get(process.env.REACT_APP_NAME || "", URL, {});
};

const getCertificateHeaderAPI = async () => {
  let URL = RES_PAGES + `/certificates`;
  return API.get(process.env.REACT_APP_NAME || "", URL, {});
};

export { getCertificationsAPI, getCertificateHeaderAPI };
