import React from "react";
import { PageTitleSubtitleWrapper } from "./pageTitleSubtitle.styled";

interface Props {
  title?: string;
  subTitle?: any;
  className?: string;
  isWhite?: boolean;
  titleClassName?: string;
  subtitleClassName?: string;
}

const PageTitleSubtitle = (props: Props) => {
  const {
    title,
    subTitle,
    className,
    isWhite,
    titleClassName = "",
    subtitleClassName = "",
  } = props;
  return (
    <PageTitleSubtitleWrapper isWhite={isWhite} className={className}>
      {title && <div className={`title ${titleClassName}`}>{title}</div>}
      {subTitle && (
        <div
          className={`sub-title ${
            subtitleClassName.length > 0 ? subtitleClassName : titleClassName
          }`}
        >
          {subTitle}
        </div>
      )}
    </PageTitleSubtitleWrapper>
  );
};

export default PageTitleSubtitle;
