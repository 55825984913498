import { GetPageListingQuery } from "&features/listingPage/listingPage.type";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getBlogsApi } from "./blogs.api";

import { Blogs } from "./blogs.type";

const initialState: Blogs = {
  blogs: undefined,
  loading: false,
};

const getBlogs = createAsyncThunk(
  "blogs/getBlogs",
  async (
    body: GetPageListingQuery,
    { rejectWithValue, getState, dispatch }: any
  ) => {
    try {
      const blogs = await getBlogsApi(body);
      return blogs?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const blogsSlice = createSlice({
  name: "blogs",
  initialState: initialState,
  reducers: {
    setBlogs: (state, action) => {
      return { ...state, ...action.payload };
    },
    reset: () => initialState,
  },

  extraReducers: (builder) => {
    builder.addCase(getBlogs.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getBlogs.fulfilled, (state, action) => {
      state.loading = false;
      state.blogs = action.payload;
    });
    builder.addCase(getBlogs.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const blogsReducer = blogsSlice.reducer;

export const blogsActions = { ...blogsSlice.actions, getBlogs };
