import React from "react";
import { SectionTitleWithLineWrapper } from "./sectionTitleWithLine.styled";
import NewLineText from "&components/texts/newLineText/newLineText.component";

interface Props {
  title?: string;
  description?: string;
  error?: string;
}

const SectionTitleWithLine = (props: Props) => {
  const { title, description, error } = props;
  return (
    <SectionTitleWithLineWrapper hasError={!!(error && error?.length > 0)}>
      <div className="title-line">
        <div className="title">{title}</div>
        <div className="divider-line" />
      </div>
      {(error || description) && (
        <NewLineText className="description-text" text={error || description} />
      )}
    </SectionTitleWithLineWrapper>
  );
};

export default SectionTitleWithLine;
