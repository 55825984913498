
import styled from "styled-components";
import {colors} from "&assets/constants/colors";
import {device} from "&assets/constants/responsiveness";

export const SkillsBuilderWrapper = styled.div`
  `;


export const SkillsSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  
  .title-skills-wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
  
  .skills-section-wrapper {
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
  }
  
  .skills-title {
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    color: ${colors.dark};
    margin-bottom: 15px;
  }

  @media ${device.tablet} {
    flex-direction: column;
    justify-content: center;

    .skills-section-wrapper {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  @media ${device.mobile} {
    flex-direction: column;
    justify-content: center;

    .skills-section-wrapper {
      display: grid;
      grid-gap: 16px;
      flex-wrap: nowrap;
      grid-template-columns: 47% 47%;
    }
  }

`

export const CheckBoxSliderWrapper = styled.div`
  margin-bottom: 30px;
  .slider {
    margin-top: 6px;
  }

`
