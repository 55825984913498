import React, { useState, useEffect, useRef } from "react";
import SubmitButton from "&components/buttons/submitButton/submitButton.component";
import LayeredImage from "&components/layeredImage/layeredImage.component";
import { BannerWrapper } from "./banner.styled";
import CloseIconButton from "&components/closeIcon/closeIcon.component";
import { XIconBig } from "&assets/constants/icons";
import { useMediaQuery } from "react-responsive";
import { size } from "&assets/constants/responsiveness";
import { isEmpty } from "lodash";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

interface Props {
  title?: string;
  description?: string;
  image?: any;
  buttonText?: string;
  handleButtonClick?: Function;
  backgroundColor?: string;
  color?: string;
  direction?: string;
  width?: string;
  onCloseButtonClick?: () => void;
  ind?: number;
  currentSlide?: number;
  setAutoHeight?: any;
}

const CarouselSingleBanner = (props: Props) => {
  const {
    title,
    width,
    description = "",
    image,
    backgroundColor = undefined,
    color = undefined,
    direction = undefined,
    setAutoHeight,
    buttonText = "",
    handleButtonClick,
    // ind,
    currentSlide,
    onCloseButtonClick,
  } = props;
  const { t } = useTranslation(["landingPage"]);
  const isMobile = useMediaQuery({ maxWidth: size.mobile });
  const isTablet = useMediaQuery({
    minWidth: size.mobile,
    maxWidth: size.tablet,
  });
  const descriptionRef = useRef<any>(null);
  const [isExpanded, setIsExpanded] = useState<boolean | undefined>(undefined);

  const checkHeight = () => {
    if (descriptionRef.current) {
      if (descriptionRef?.current?.scrollHeight > descriptionRef?.current?.clientHeight) {
        setIsExpanded(false);
      } else {
        setIsExpanded(undefined);
      }
    }
  };

  useEffect(() => {
    checkHeight();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [descriptionRef, descriptionRef?.current?.scrollHeight, currentSlide]);

  return (
    <BannerWrapper width={width} direction={direction} color={color} backgroundColor={backgroundColor}>
      <div className="banner-info">
        {title && <p className="banner-title">{title}</p>}
        <div className={`banner-description ${!isExpanded && "wrapped"}`} ref={descriptionRef}>
          {description}
        </div>
        {isExpanded !== undefined && (
          <Typography
            className="see-more-less"
            paddingBottom={3}
            color="InfoText"
            onClick={() => {
              setIsExpanded(!isExpanded);
              setAutoHeight(false);
            }} // Toggle isExpanded
            fontSize={11}
          >
            {isExpanded ? t("READ_LESS") : t("READ_MORE")}
          </Typography>
        )}
        {!isEmpty(buttonText) && (
          <SubmitButton
            id="explore-nammiskills-btn"
            title={buttonText}
            handleClick={() => handleButtonClick && handleButtonClick()}
            className="explore-nammiskills-btn"
            titleClassName="explore-nammiskills-btn-text"
          />
        )}
      </div>
      <div className="banner-background">
        {!isMobile && !isTablet && image && (
          <LayeredImage src={image} wrapperClassName="banner-image" imageClassName="banner-image" />
        )}
        <div className="close-button">
          {onCloseButtonClick !== undefined && (
            <CloseIconButton className="close-circle" onClick={onCloseButtonClick} icon={XIconBig(10)} />
          )}
        </div>
      </div>
    </BannerWrapper>
  );
};

export default CarouselSingleBanner;
