import React, { useEffect, useState } from "react";
import { Fade } from "@mui/material";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

import { CertificationHeaderWrapper } from "./certificationHeader.styled";
import RightArrow from "&assets/images/rightArrow.svg";
import LeftArrow from "&assets/images/leftArrow.svg";
import Container from "&components/container/container.component";
import { PageSection, PageSectionContent } from "&features/landingPage/landingPage.type";
import ImageDisplay from "&components/imageDisplay/imageDisplay.component";
import { translationSelector } from "&utils/translationSelector";
import { useNavigate } from "react-router-dom";
import { CertificateSectionContentI } from "&features/certifications/certifications.type";

interface Props {
  data: CertificateSectionContentI[] | undefined;
}

const CertificationHeader = ({ data }: Props) => {
  const navigate = useNavigate();
  // const { loginWithRedirect } = useAuth0();
  const carouselArray = data;
  let [activeLine, setActiveLine] = useState(0);
  let [selectedContent, setSelectedContent] = useState<CertificateSectionContentI | undefined>(
    carouselArray ? carouselArray[0] : undefined
  );
  let [checked, setChecked] = useState<boolean>(true);

  useEffect(() => {
    if (carouselArray)
      setTimeout(() => {
        setChecked(true);
        setSelectedContent(carouselArray[activeLine]);
      }, 200);
  }, [activeLine, carouselArray]);

  return (
    <CertificationHeaderWrapper activeLine={activeLine}>
      <Container className="header-wrapper">
        <div>
          <Fade in={checked}>
            <p className="certification-title">{translationSelector(selectedContent, "title")}</p>
          </Fade>
          <Fade in={checked}>
            <p className="description">{translationSelector(selectedContent, "content")}</p>
          </Fade>
        </div>
        <div className="content-div">
          <div className="content-background"></div>
          <Carousel
            // autoPlay={!isMobile ? true : false}
            swipeable={true}
            showStatus={false}
            showThumbs={false}
            showIndicators={false}
            showArrows={carouselArray && carouselArray?.length > 1}
            className="carousel-wrapper"
            selectedItem={activeLine}
            preventMovementUntilSwipeScrollTolerance={true}
            swipeScrollTolerance={50}
            renderArrowNext={(onClickHandler, hasNext, label) =>
              hasNext && (
                <div onClick={onClickHandler} title={label} className="arrows next">
                  <img src={RightArrow} alt="" />
                </div>
              )
            }
            onChange={(value) => {
              setActiveLine(value);
            }}
            renderArrowPrev={(onClickHandler, hasPrev, label) =>
              hasPrev && (
                <div onClick={onClickHandler} title={label} className="arrows prev">
                  <img src={LeftArrow} alt="" />
                </div>
              )
            }
          >
            {carouselArray &&
              carouselArray.map((item, index) => {
                return (
                  <div key={index} className="carousel-content">
                    <ImageDisplay
                      showOverlay={true}
                      isFromS3={true}
                      className={`carousel-image ${item.mediaType === "video" && "carousel-video"}`}
                      url={translationSelector(item, "image")}
                      mediaType={item.mediaType}
                      alt=""
                    />
                  </div>
                );
              })}
          </Carousel>
        </div>
      </Container>
    </CertificationHeaderWrapper>
  );
};

export default CertificationHeader;
