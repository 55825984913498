import styled from "styled-components";
import { device } from "&assets/constants/responsiveness";

export const UnsubscribeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  .verify-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;
    color: #2d2a35;
    width: 290px;
    text-align: center;
    margin: 10px;
    margin-bottom: 50px;
  }
  .login-btn {
    margin-top: 20px;
    height: 54px;
    justify-content: center;
  }

  @media ${device.inBetween} {
    ${(props) =>
      props.theme.dir === "rtl"
        ? "padding: 0 150px 0 70px;"
        : "padding: 0 70px 0 150px;"}
  }

  @media ${device.mobile}, ${device.tablet} {
    padding: 0px;
    .signup-form {
      width: 100%;
      margin-top: 17px;
      .flex-row {
        flex-direction: column;
        .name-input {
          width: 100%;
          margin-top: 10px;
        }
      }
      .input-row {
        margin-top: 10px;
      }
      .create-account-btn {
        width: auto;
        height: 57px;
        margin: auto;
        margin-top: 16px;
      }
    }
    .socials {
      .social-btn {
        min-width: auto;
        height: 37px;
      }
    }
  }
`;
