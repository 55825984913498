import { colors } from "&assets/constants/colors";
import { device } from "&assets/constants/responsiveness";
import styled from "styled-components";

export const OpportunitiesListingWrapper = styled.div`
  .listing-container {
    padding: 0 64px;
    width: initial;
    box-sizing: border-box;
    .input-wrapper {
      min-width: 250px;
    }
  }
  .view-all-button {
    background: transparent;
    border: 1px solid ${colors.primary};
    width: 160px;
    height: 38px;
    justify-content: center;
    margin: auto;
    margin-top: 24px;
  }
  .view-all-button-title {
    font-weight: 800;
    font-size: 14px !important;
    line-height: 160%;
    text-align: center;
    color: ${colors.primary};
    margin: 0;
  }

  .list-wrapper {
    display: grid;
    grid-gap: 24px;
    grid-template-columns: auto auto auto;
    justify-content: center;
    .card {
      margin-bottom: 39px;
    }
  }

  .loader-wrapper {
    height: 50px;
    width: 100%;
    display: flex;
    padding: 100px 0;
    justify-content: center;
    align-items: center;
  }

  .no-jobs-wrapper {
    padding: 100px 0;
  }

  @media ${device.inBetween} {
    .list-wrapper {
      grid-template-columns: auto auto auto;
    }
  }

  @media ${device.tablet} {
    .list-wrapper {
      grid-template-columns: auto auto;
      padding: 0 37px;
    }
    .listing-container {
      padding: 0;
      .input-wrapper {
        min-width: 100%;
      }
    }
  }

  @media ${device.mobile} {
    .list-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0 37px;
    }
    .listing-container {
      padding: 0;
      .input-wrapper {
        min-width: 100%;
      }
    }
  }
`;
