import CircularLoader from "&components/circularLoader/circularLoader.component";
import { RootState } from "&store/store";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, useDispatch, useSelector } from "react-redux";
import { BundlesWrapper } from "./bundles.styled";
import { landingPageActions } from "&features/landingPage/landingPage.slice";
import PageHeader from "&components/pageHeader/pageHeader.component";
import { Grid } from "@mui/material";
import BundleCards from "&components/bundlesCards/bundles.component";
import getCDNProtectedURL from "&utils/getCDNProtectedImageSource";
import { images } from "&assets/constants/images-urls";
import SubmitButton from "&components/buttons/submitButton/submitButton.component";
import { useNavigate } from "react-router-dom";
import { BundlesActions } from "./bundles.slice";
import moment from "moment";
import Container from "&components/container/container.component";
import { isEmpty } from "lodash";
import { eligibilitySelector } from "&utils/profile/profileSelectors";

const BundlesComponent = () => {
  const { t } = useTranslation(["bundles"]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const paidPools: any = useSelector(
    (state: RootState) => state.bundles.bundles
  );
  const user = useSelector((state: RootState) => state.login.user);
  const DEFAULT_LIMIT = 3;
  const [limit, setLimit] = useState<number>(DEFAULT_LIMIT);

  const eligibilityData =
    (user?.eligibilityForm && eligibilitySelector(user?.eligibilityForm)) || {};

  const isAwaiting =
    !isEmpty(eligibilityData.residence) &&
    !eligibilityData?.approved &&
    !eligibilityData.rejected;

  const stateSelector = isAwaiting
    ? "pending"
    : eligibilityData?.rejected === 2 && !eligibilityData?.approved
    ? "rejected"
    : eligibilityData?.approved
    ? "approved"
    : "initial";

  const allow =
    user &&
    (stateSelector === "rejected" ||
      user?.isEQ2 ||
      stateSelector === "initial");

  useEffect(() => {
    if (allow) {
      dispatch(BundlesActions.getBundles({}));
    } else {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formattedBundles = (entities: any) => {
    const data = entities
      ?.slice(0, limit)
      .filter(
        (bundle: any) =>
          moment(bundle.expireAt).isAfter(moment()) || bundle.expireAt === null
      )
      .map((bundle: any) => ({
        id: bundle.id,
        name: bundle.name,
        price: `${bundle.price}$`,
        discountedPrice: `${bundle.discountedPrice}$`,
        expiryDate: bundle?.expireAt ?? null,
        instructions: bundle.instructions,
        exams: bundle.exams ?? [],
        type: bundle.type,
        paymentStatus: bundle.transactions.some((trans: any) =>
          trans.status.includes("awaiting")
        )
          ? "awaiting"
          : "history",
        status:
          bundle.type === "subscription" &&
          bundle.transactions.some((trans: any) => trans.status === "approved")
            ? "approved"
            : "not approved",
        transactions: bundle.transactions ?? [],
        image: getCDNProtectedURL(images.bundlesImage),
      }));
    return data;
  };

  const loadingComponent = (
    <div className="loader-wrapper">
      <CircularLoader />
    </div>
  );

  const handleShowMore = () => {
    if (paidPools) {
      const remainingItems = paidPools.length - limit;
      const increment = Math.min(DEFAULT_LIMIT, remainingItems);
      setLimit(limit + increment);
    }
  };

  if (!paidPools) {
    return loadingComponent;
  } else if (paidPools.length === 0) {
    return (
      <BundlesWrapper showBundlesBanner={stateSelector === "initial"}>
        <div className="empty-bundles">{t("BUNDLES_EMPTY")}</div>{" "}
      </BundlesWrapper>
    );
  }
  return (
    <BundlesWrapper showBundlesBanner={stateSelector === "initial"}>
      <PageHeader
        title={t("LEARNING_BUNDLES")}
        subTitle={t("LEARNING_BUNDLES_SUBTITLE")}
        className="header"
        hasBackButton={true}
        onBackClicked={() => navigate(-1)}
        titleClassName="header-title"
        titleSubtitleWrapper="title-subtitle-wrap"
      />
      <Container className="container-content">
        <BundleCards
          showBundlesBanner={stateSelector === "initial"}
          bundles={formattedBundles(paidPools)}
          fetch={() => dispatch(BundlesActions.getBundles({}))}
        />
        <Grid
          display="flex"
          justifyContent="center"
          alignItems="center"
          padding="45px"
        >
          {paidPools && limit < paidPools.length && (
            <SubmitButton
              id={"sign-up-now-button"}
              className="purchase-bundle-button"
              title={t("SHOW_MORE")}
              handleClick={handleShowMore}
            />
          )}
        </Grid>
      </Container>
    </BundlesWrapper>
  );
};

const mapStateToProps = (state: RootState) => ({
  homeContent: state.landingPage.homeContent,
});

const mapDispatchToProps = {
  getPagesContent: landingPageActions.getPagesContent,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const BundlesComponentRedux = connector(BundlesComponent);

export { BundlesComponentRedux as BundlesComponent };
