export const mainTheme = {
  colors: {
    primary: "#108CD2",
    primaryLight: "#D1EAFF",
    primaryDark: "#0577E1",
    primaryDark2: "#057add",
    // primary: "#108CD2",
    // primaryDark: "#0C679B",
    // primaryLight: "#CFE8F6",
    secondary: "#04D8A4",
    secondaryDark: "#04af84",
    // secondary: "#FF7B02",
    // secondaryDark: "#FE512F",
    tirtiary: "#355268",
    fourth: "#057770",
    fifth: "#355268",

    secondaryLight: "#c2c5d1",
    background: "#FFFFFF",
    foreground: "#f6fcfd",
    selectedBackground: "#7BC9DB4D",
    success: "#6FEDA8",
    danger: "#d7494e",
    dangerLight: "#da6468",
    info: "#8A6EFF",
    warning: "#F8D210",
    backdrop: "#ffffff7a",

    test: "#CFE8F6",
    text: {
      primary: "#2C383F",
      primaryLight: "#FFFFFF",
      secondary: "#4D525B",
      selected: "#49BCD7",
      link: "#49BCD7",
      gray: "#2f34407f",
      placeholder: "#a8aaaf",
    },
    input: {
      background: "#FFFFFF",
      selected: "#49BCD7",
      border: {
        primary: "#2F3440",
        secondary: "#D5D6D9",
        selected: "#49BCD7",
      },
    },
    button: {
      color: "#FFFFFF",
      background: "#2F34400D",
      border: "#2F344033",
      secondary: "#a0a4b5",
    },
    status: {
      pending: "#8A6EFF",
      accepted: "#6AEDA6",
      rejected: "#F51720",
      expired: "#2F34407F",
      "accepted-expired": "#2F34407F",
      "extended-expired": "#2F34407F",
      extended: "#6AEDA6",
      canceled: "#2F34407F",
      revoked: "#F51720",
      "in-progress": "#8A6EFF",
      completed: "#6AEDA6",
      incomplete: "#F51720",
      failed: "#F51720",
    },
  },
  custom: {
    green: "#6FEDA8",
    blue: "#52D8FF",
    violet: "#8A6EFF",
    white: "#FFFFFF",
    soap: "#D3D7F2",
    lightBlue: "#7BC9DB",
    lightViolet: "#CFD3F1",
    black: "#2F3440",
    red: "#D7494E",
    brightRed: "#F51720",
    turquoise: "#2FF3E0",
    pink: "#FA26A0",
    yellow: "#F8D210",
  },
  fonts: {
    main: "Poppins",
  },
};

const themes: any = {
  light: mainTheme,
};

export default themes;
