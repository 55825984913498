import React from "react";
import { CloseIconWrapper } from "./closeIcon.styled";

interface Props {
  className?: string;
  onClick: () => void;
  icon: any;
}

const CloseIconButton = (props: Props) => {
  const { onClick, className, icon } = props;
  return (
    <CloseIconWrapper onClick={onClick} className={className}>
      {icon}
    </CloseIconWrapper>
  );
};

export default CloseIconButton;
