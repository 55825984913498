import React from "react";

import { BlogCardWrapper } from "./blogCard.styled";
import { Clock } from "&assets/constants/icons";
import moment from "moment";
import SkillsList from "&components/skillsList/skillsList.component";
import getCDNProtectedURL from "&utils/getCDNProtectedImageSource";

interface Props {
  title?: string;
  date?: string;
  image?: any;
  tags?: any;
  handleClick?: Function;
}

const BlogCard = (props: Props) => {
  const { title, tags = [], date, image, handleClick } = props;
  return (
    <BlogCardWrapper onClick={() => handleClick && handleClick()}>
      <img src={getCDNProtectedURL(image)} alt={title} className="blog-image" />
      <div className="card-content">
        <p className="title info">
          <Clock /> <span className="text">{moment(date).format("DD MMM yy")}</span>
        </p>
      </div>
      <p className="card-title">{title}</p>
      <SkillsList skills={tags.split(",")}></SkillsList>
    </BlogCardWrapper>
  );
};

export default BlogCard;
