import { colors } from "&assets/constants/colors";
import styled from "styled-components";

export const ChipWrapper = styled.div`
  padding: 2px 8px;
  min-width: 72px;
  height: 23px;
  background: rgba(16, 140, 210, 0.1);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) =>
          props.theme.dir === "rtl"
                  ? "margin-left: 8px;"
                  : "margin-right: 8px;"}
  margin-bottom: 16px;
  box-sizing: border-box;
  .chip-title {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 160%;
    text-align: center;
    color: ${colors.primaryDark};
  }
`;
