const RES_LEVELS = `/levels`;
const RES_TIERS = `/tiers`;
const RES_CAREERS = `/careers`;
const RES_SKILLS = `/skills`;
const RES_MAJORS = `/majors`;
const RES_INSTITUTES = `/institutes`;
const RES_COUNTRIES = `/countries`;
const RES_SETTINGS = `/settings`;
const RES_PAGES = `/pages`;
const RES_COURSES = `/courses`;
const RES_LABS = `/labs`;
const RES_EXAMS = `/exams`;
const RES_LEARNING_PATHWAY = `/learning-paths`;
const RES_ASSESSMENTS = `/assessments`;
const RES_CONTACT_US = `/contact-us`;
const RES_BLOGS = `/blogs`;
const RES_POOLS = `/financial-pools`;
const RES_JOBS = `/jobs`;
const RES_SIGN_IN = `/sign-in`;
const RES_SIGN_UP = `/sign-up`;
const RES_AUTH = "/auth";
const RES_AUTH_USER = `/auth/user`;
const RES_AUTH_REPORTS = `/auth/user/reports`;
const RES_NOTIFICATIONS = `${RES_AUTH}/notifications`;
const RES_PROFILE = `${RES_AUTH_USER}/profile`;
const RES_ELIGIBILITY = `${RES_AUTH_USER}/eligibility-form`;
const RES_VERIFY_EMAIL = `/verification-email`;
const RES_CHANGE_PASSWORD = `/change-password`;

export {
  RES_CAREERS,
  RES_SKILLS,
  RES_SETTINGS,
  RES_PAGES,
  RES_LEVELS,
  RES_MAJORS,
  RES_INSTITUTES,
  RES_COURSES,
  RES_LEARNING_PATHWAY,
  RES_ASSESSMENTS,
  RES_POOLS,
  RES_CONTACT_US,
  RES_BLOGS,
  RES_JOBS,
  RES_SIGN_IN,
  RES_SIGN_UP,
  RES_AUTH_USER,
  RES_PROFILE,
  RES_TIERS,
  RES_COUNTRIES,
  RES_ELIGIBILITY,
  RES_VERIFY_EMAIL,
  RES_AUTH_REPORTS,
  RES_LABS,
  RES_EXAMS,
  RES_CHANGE_PASSWORD,
  RES_NOTIFICATIONS,
};
