import React from "react";

import { OpportunitiesCarouselWrapper } from "./opportunitiesCarousel.styled";
import MatchingOpportunitiesCard from "&components/matchingOpportunitiesCard/matchingOpportunitiesCard.component";
import CardsCarousel from "&components/cardsCarousel/cardsCarousel.component";
import { translationSelector } from "&utils/translationSelector";
import { useTranslation } from "react-i18next";

interface Props {
  data?: any;
}

const OpportunitiesCarousel = (props: Props) => {
  const { data } = props;
  const { t } = useTranslation(["opportunitiesListing"]);

  return (
    <OpportunitiesCarouselWrapper>
      <CardsCarousel>
        {data?.map((item: any, index: number) => {
          return (
            <div className="cards" key={index}>
              <MatchingOpportunitiesCard
                level={translationSelector(item?.level, "alias")}
                title={translationSelector(item, "title")}
                description={translationSelector(item, "shortDescription")}
                provider={translationSelector(item, "source")}
                skills={item?.jobSkills}
                location={item?.location}
                company={item?.company ?? item?.internalCompany?.name}
                date={item?.datePosted ?? item?.createdAt}
                url={translationSelector(item, "url")}
                external={item?.external && item?.external === 1}
                buttonText={t("MORE_DETIALS")}
                id={item?.id}
              />
            </div>
          );
        })}
      </CardsCarousel>
    </OpportunitiesCarouselWrapper>
  );
};

export default OpportunitiesCarousel;
