import styled from "styled-components";
import { Modal } from "@mui/material";
import {device} from "&assets/constants/responsiveness";

export const VideoModalWrapper = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;

  .video-wrapper {
    position: relative;
    width: 70%;
    height: 70%;
    outline: 0;
    border-width: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .thumbnail {
      height: 100%;
    }

    @media ${device.mobile} {
      width: 90%;
      height: 50%;

      .thumbnail {
        width: 100%;
        height: auto;
      }
    }
  }
  
  .close-button {
    position: absolute;
    top: -45px;
    right: 0;
    
    &:hover {
      opacity: 0.9;
    }
  }
`;
