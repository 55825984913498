import styled from "styled-components";
import { colors } from "&assets/constants/colors";
import { device } from "&assets/constants/responsiveness";
export const FourEasyStepsWrapper = styled.div`
  position: relative;
  width: fit-content;
  height: 225px;
  border-radius: 12px 18px 12px 12px;
  margin: 74px 64px 70px;
  background: ${colors.primary};
  box-sizing: border-box;
  .background-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.05;
    flex-shrink: 0;
  }
  .padding-wrapper {
    width: 100%;
    box-sizing: border-box;
    padding: 33px 24px 40px;
  }
  .title {
    margin: 0 0 24px;
    white-space: nowrap;
    font-feature-settings: "cv11" on;
    color: ${colors.white};
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%; /* 35.2px */
    letter-spacing: -0.64px;
  }
  .steps-wrapper {
    display: flex;
    align-items: center;
    .step-wrapper {
      display: flex;
      svg {
        ${(props) => (props.theme.dir === "rtl" ? "transform: scaleX(-1);" : "")}
      }
      ${(props) => (props.theme.dir === "rtl" ? "margin-left: 48px;" : "margin-right: 48px;")}

      .number-description {
        display: flex;
        flex-direction: column;
        width: 197px;
        ${(props) => (props.theme.dir === "rtl" ? "margin-left: 37px;" : "margin-right: 37px;")}
        .number {
          color: ${colors.white};
          font-size: 32px;
          font-style: normal;
          font-weight: 700;
          line-height: 120%; /* 38.4px */
          margin: 0;
        }

        .line {
          height: 0.5px;
          background: ${colors.white};
          margin: 6px 0 15px;
        }

        .description {
          color: ${colors.white};
          margin: 0;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 120%; /* 16.8px */
        }
      }
    }
  }

  @media ${device.mobile}, ${device.tablet}, ${device.inBetween} {
    overflow-x: auto;
    overflow-y: hidden;
    height: fit-content;
    width: 100%;
    margin: 50px 16px;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;
