import styled from "styled-components";
import { colors } from "&assets/constants/colors";

interface Props {
  short?: boolean;
}

export const GettingStartedStepsWrapper = styled.div<Props>`
  .title-with-steps {
    min-height: 233px !important;
    background: linear-gradient(
      180deg,
      rgba(207, 232, 246, 0) 0%,
      ${colors.primaryLight} 100%
    );
  }

  .loader {
    margin-top: 20px;
  }
`;
