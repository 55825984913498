import React, { useEffect, useState } from "react";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useMediaQuery } from "react-responsive";
import { useNavigate, useParams } from "react-router-dom";
import { concat, isArray } from "lodash";
import toast from "react-hot-toast";
import { RootState } from "&store/store";
import { opportunitiesDetailsActions } from "./opportunitiesDetails.slice";
import { OpportunitiesDetailsWrapper } from "./opportunitiesDetails.styled";
import LearningHeader from "&components/learningHeader/learningHeader.component";
import Chip from "&components/chip/chip.component";
import MatchingOpportunities from "&components/matchingOpportunities/matchingOpportunities.component";
import ActionButton from "&components/buttons/actionButton/actionButton.component";
import { size } from "&assets/constants/responsiveness";
import Container from "&components/container/container.component";
import RelatedLearningActivities from "&components/relatedLearningActivities/relatedLearningActivities.component";
import { CourseSkill } from "&features/listingPage/listingPage.type";
import { Clock, Company, LocationIcon, WorkTypeIcon } from "&assets/constants/icons";
import CircularLoader from "&components/circularLoader/circularLoader.component";
import HtmlParser from "&components/htmlParser/htmlParser.component";
import getCDNProtectedURL from "&utils/getCDNProtectedImageSource";
import { images } from "&assets/constants/images-urls";
import { useAuth0 } from "@auth0/auth0-react";
import { translationSelector } from "&utils/translationSelector";
import { loginActions } from "&features/login/login.slice";
import CustomModal from "&components/custom-modal/custom-modal.component";
import { Button, CircularProgress, Grid, Typography } from "@mui/material";
import { AssessmentForm } from "&features/assessment/assessment.type";
import { UpdateProfileBody } from "&features/login/login.type";
import { OtherSkillsTagsWrapper } from "&components/searchOtherSkills/searchOtherSkills.styled";
import SkillChip from "&components/skillChip/skillChip.component";
import SkillsBuilder from "&components/skillsBuilder/skillsBuilder.component";
import { otherSkillsFilter } from "&utils/profile/profileSelectors";
import buildAssessmentBody from "&utils/buildAssessmentObject";

type ReduxProps = ConnectedProps<typeof connector>;

const OpportunitiesDetailsComponent = (props: ReduxProps) => {
  const {
    selectedJob,
    getJobById,
    loading,
    authSelectedJob,
    getAuthJobById,
    isLoggedIn,
    formValues,
    setLogin,
    loadingSkills,
    skillsStatus,
    user,
    settings,
    skills,
    setProfileForm,
    getSkills,
    loadingRelatedJobs,
    relatedJobs,
    getRelatedJobsById,
  } = props;
  const { t } = useTranslation(["courseDetails"]);

  const initialValues: AssessmentForm = {
    softSkills: skills?.soft_skills,
    hardSkills: skills?.hard_skills,
    otherSkills: otherSkillsFilter(skills?.other_skills),
    otherSkillsList: skills?.other_skills,
  };

  useEffect(() => {
    if (skillsStatus === "GET_SKILLS_SUCCESS") {
      handleChangeValues(initialValues);
      setLogin({
        skillsStatus: "",
        updateProfileStatus: "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skillsStatus]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const isMobile = useMediaQuery({ maxWidth: size.mobile });
  const [readMore, setReadMore] = useState(false);
  const [loadingWhileCheckingSkills, setLoadingWhileCheckingSkills] = useState(false);
  const [skillsMissing, setSkillsMissing] = useState([]);
  const { loginWithRedirect } = useAuth0();
  const [openProfileSkillsModal, setOpenProfileSkillsModal] = useState(false);
  const [openSkillsDifferenceModal, setOpenSkillsDifferenceModal] = useState(false);

  const job = isLoggedIn ? authSelectedJob : selectedJob;
  const informatics = [
    {
      icon: <Company fill="white" />,
      text: job?.internalCompany?.name || "",
    },
    {
      icon: <LocationIcon fill="white" />,
      text: job?.location || "",
    },
    {
      icon: <WorkTypeIcon />,
      text: job?.workplaceType || "",
    },
    {
      icon: <Clock fill="white" />,
      text: moment(job?.createdAt).format("DD-MM-yyyy") || "",
    },
  ];

  useEffect(() => {
    if (isLoggedIn) {
      getSkills({});
      id && getAuthJobById(id);
    } else {
      id && getJobById(id);
    }
    id && getRelatedJobsById(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, localStorage.getItem("language")]);

  const handleCloseSkillsDifferenceModal = () => {
    setOpenSkillsDifferenceModal(false);
  };

  const updateProfile = (body: UpdateProfileBody) => {
    dispatch(loginActions.updateProfile(body));
  };

  const handleCloseProfileSkillsModal = () => {
    setOpenProfileSkillsModal(false);
  };

  const handleCloseAndApply = () => {
    handleApplyForJob();
    setOpenSkillsDifferenceModal(false);
  };

  const handleSaveProfileAndApply = () => {
    updateProfile({
      skills: buildAssessmentBody(formValues, true),
    });
    handleApplyForJob();
    setOpenProfileSkillsModal(false);
    setOpenSkillsDifferenceModal(false);
  };

  const renderOptionTagSlider = (skill: any, index: number) => {
    return (
      <SkillChip
        key={index}
        sliceTitle={false}
        level={skill?.skills?.weight}
        className="skill-chip"
        title={skill?.skills?.name}
        titleClassName="skill-name-text"
      />
    );
  };

  const handleCheckSkillsDifference = (job: any) => {
    setLoadingWhileCheckingSkills(true);
    const { jobSkills } = job;
    if (user && user.userSkills) {
      const userSkills = concat(user.userSkills.hard_skills, user.userSkills.other_skills, user.userSkills.soft_skills);

      const differingSkills = jobSkills.filter((jobSkill: any) => {
        const matchingUserSkill = userSkills.find(
          (userSkill: any) => userSkill.id === jobSkill.skillId && userSkill.weight >= jobSkill.weight
        );
        return !matchingUserSkill;
      });
      setSkillsMissing(differingSkills);

      if (differingSkills.length > 0) {
        setOpenSkillsDifferenceModal(true);
      } else {
        handleApplyForJob();
      }
    }
    setTimeout(() => {
      setLoadingWhileCheckingSkills(false);
    }, 500);
  };

  const handleApplyForJob = async () => {
    await dispatch(loginActions.applyForJobApiCall({ jobId: Number(id) }));
    toast.success(
      translationSelector(
        {
          name: "Job applied successfully",
          nameAr: "تم التقديم للوظيفة بنجاح",
        },
        "name"
      )
    );
    id && getAuthJobById(id);
  };

  const handleChangeValues = (value: AssessmentForm) => {
    setProfileForm({ ...formValues, ...value });
  };

  const RenderRequiredSkills = () => {
    return (
      <SkillsBuilder
        formValues={formValues}
        setAssessmentForm={setProfileForm}
        loadingAssessment={loadingSkills}
        settings={settings}
      />
    );
  };

  const loadingComponent = (
    <div className="loader-wrapper">
      <CircularLoader />
    </div>
  );

  return (
    <OpportunitiesDetailsWrapper>
      {loading ? (
        loadingComponent
      ) : (
        <>
          <LearningHeader
            title={job?.title}
            career={job?.career ?? undefined}
            description={job?.shortDescription}
            handleBackClick={() => navigate(-1)}
            providerName={job?.source}
            image={job?.career?.image || job?.career?.thumbnail || getCDNProtectedURL(images.learningPathwayImg)}
            hasButton={job?.jobApplicant === null}
            buttonText={t("APPLY_NOW")}
            level={job?.level?.alias}
            informatics={informatics}
            handleButtonClick={() => {
              isLoggedIn ? handleCheckSkillsDifference(job) : loginWithRedirect();
            }}
          />
          <Container>
            <div className="course-details">
              <p className="header">{t("JOB_DESCRIPTION")}</p>
              <div className={`content-text ${readMore ? "expand" : ""}`}>
                {!readMore && <div className={`layer ${readMore ? "expand" : ""}`} />}
                <HtmlParser className="description-content" description={job?.description} />
              </div>
              {isMobile && (
                <ActionButton
                  className="read-more"
                  titleClassName="read-more-title"
                  title={readMore ? t("READ_LESS") : t("READ_MORE")}
                  handleClick={() => setReadMore(!readMore)}
                />
              )}

              <p className="header">{t("REQUIRED_SKILL")}</p>
              <div className="chips">
                {job?.jobSkills?.map((item: CourseSkill, index: number) => {
                  return <Chip title={item.skills.name} key={index}></Chip>;
                })}
              </div>
            </div>
          </Container>
          <RelatedLearningActivities
            allButtonText={"View all courses"}
            title={"Related Courses"}
            onAllButtonClick={() => navigate("/listing-page?learning_activity=courses")}
            activities={job?.relatedCourses}
          />
          {loadingRelatedJobs
            ? loadingComponent
            : relatedJobs &&
              isArray(relatedJobs) &&
              relatedJobs?.length > 0 && (
                <MatchingOpportunities
                  title="Related opportunities"
                  onAllButtonClick={() => navigate("/opportunities")}
                  opportunities={relatedJobs}
                />
              )}
        </>
      )}
      <CustomModal
        handleClose={handleCloseSkillsDifferenceModal}
        open={openSkillsDifferenceModal}
        title={t("SKILLS_MISSING")}
        titleClassName={"title"}
      >
        {loadingWhileCheckingSkills || skillsMissing.length === 0 ? (
          <Grid width="100%" display="flex" margin="0 auto" justifyContent="center" alignContent="center">
            <CircularProgress />
          </Grid>
        ) : (
          <Grid>
            <Typography id="alert-dialog-slide-description">{t("DIFFERENCE_SKILLS")}</Typography>
            <OtherSkillsTagsWrapper>
              {skillsMissing.map((option, index) => {
                return renderOptionTagSlider(option, index);
              })}
            </OtherSkillsTagsWrapper>
            <Grid display="flex" width="100%" justifyContent="flex-end" gap={3} paddingTop={2}>
              <Button onClick={handleCloseAndApply}>{t("CLOSE_AND_APPLY")}</Button>
              <Button onClick={() => setOpenProfileSkillsModal(true)}>{t("UPDATE_YOUR_SKILLS")}</Button>
            </Grid>
          </Grid>
        )}
      </CustomModal>
      <CustomModal
        handleClose={handleCloseProfileSkillsModal}
        open={openProfileSkillsModal}
        title={t("UPDATE_YOUR_SKILLS")}
        titleClassName={"title"}
      >
        {loadingSkills ? (
          <Grid width="100%" display="flex" margin="0 auto" justifyContent="center" alignContent="center">
            <CircularProgress />
          </Grid>
        ) : (
          <Grid display="flex" width="100%" flexDirection="column" justifyContent="flex-end" gap={3} paddingTop={2}>
            <Grid>{RenderRequiredSkills()}</Grid>
            <Grid display="flex" justifyContent="flex-end" alignItems="center" gap={3}>
              <Button onClick={handleCloseProfileSkillsModal}>{t("CLOSE")}</Button>
              <Button onClick={handleSaveProfileAndApply}>{t("SAVE_AND_APPLY")}</Button>
            </Grid>
          </Grid>
        )}
      </CustomModal>
    </OpportunitiesDetailsWrapper>
  );
};

const mapStateToProps = (state: RootState) => ({
  selectedJob: state.opportunitiesDetails.selectedJob,
  authSelectedJob: state.opportunitiesDetails.authSelectedJob,
  loading: state.opportunitiesDetails.loading,
  relatedJobs: state.opportunitiesDetails.relatedJobs,
  user: state.login.user,
  formValues: state.login.profileFormState,
  settings: state.global.settings,
  skills: state.login.skills,
  loadingSkills: state.login.loadingSkills,
  skillsStatus: state.login.skillsStatus,
  loadingRelatedJobs: state.opportunitiesDetails.loadingRelatedJobs,
  isLoggedIn: state.login.isLoggedIn,
});

const mapDispatchToProps = {
  getJobById: opportunitiesDetailsActions.getJobsById,
  getAuthJobById: opportunitiesDetailsActions.getAuthJobsByIdApiCall,
  getSkills: loginActions.getSkills,
  setLogin: loginActions.setLogin,
  setProfileForm: loginActions.setProfileForm,
  getRelatedJobsById: opportunitiesDetailsActions.getRelatedJobsById,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const OpportunitiesDetailsComponentRedux = connector(OpportunitiesDetailsComponent);

export { OpportunitiesDetailsComponentRedux as OpportunitiesDetailsComponent };
