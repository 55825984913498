import { device } from "&assets/constants/responsiveness";
import { colors } from "&assets/constants/colors";
import styled from "styled-components";

export const ImageBackgroundWrapper = styled.div`
  position: relative;
  margin-bottom: 40px;
  padding: 48px 64px;
  .background-image {
    width: 100%;
    height: 323px;
    position: absolute;
    z-index: -2;
    ${(props) => (props.theme.dir === "rtl" ? "right: 0;" : "left: 0;")}
    ${(props) => (props.theme.dir === "rtl" ? "transform: scaleX(-1);" : "")}
  }

  .container {
    box-sizing: border-box;
  }
  .title-button-wrapper {
    margin-bottom: 24px;
    .section-title {
      margin-top: 48px;
    }
    .view-all {
      margin-top: 48px;
    }
  }

  .gradient-layer {
    position: absolute;
    width: 100%;
    height: 323px;
    background: ${colors.linearGradientOne};
    opacity: 0.9;
    z-index: -1;
    ${(props) => (props.theme.dir === "rtl" ? "right: 0;" : "left: 0;")}
    ${(props) => (props.theme.dir === "rtl" ? "transform: scaleX(-1);" : "")}
  }
  @media ${device.tablet} {
    padding: 20px 30px 20px 30px;
    .title-button-wrapper {
      margin-bottom: 65px;
    }
    .oval-right {
      display: none;
    }
    .title-button-wrapper {
      .section-title {
        margin-top: 20px;
      }
      .view-all {
        margin-top: 0;
      }
    }
  }
  @media ${device.mobile} {
    overflow: hidden;
    padding: 20px 16px;
    .title-button-wrapper {
      margin-bottom: 65px;
    }
    .oval-left {
      position: absolute;
      width: 303px;
      height: 303px;
      ${(props) => (props.theme.dir === "rtl" ? "right: -150px;" : "left: -150px;")}

      top: 160px;
      background: linear-gradient(56.4deg, ${colors.primaryDark} 8.08%, ${colors.secondary} 87.11%);
      filter: blur(65px);
    }
    .oval-right {
      display: none;
    }
    .title-button-wrapper {
      .section-title {
        margin-top: 20px;
      }
      .view-all {
        margin-top: 0;
      }
    }
  }
`;
