import React from "react";
import { SectionTitleButtonWrapper } from "./sectionTitleButton.styled";
import { ArrowForwardIcon } from "&assets/constants/icons";
import stringToDashedLowercase from "&utils/stringToDashedId";
import {colors} from "&assets/constants/colors";

interface Props {
  title: string;
  buttonText: string;
  onButtonClick: () => void;
  wrapperClassName?: string;
  isWhite?: boolean;
  hideButton?: boolean;
}

const SectionTitleButton = (props: Props) => {
  const { title, buttonText, onButtonClick, wrapperClassName, isWhite, hideButton } = props;
  return (
    <SectionTitleButtonWrapper isWhite={isWhite} className={wrapperClassName}>
      <span className="section-title">{title}</span>
      {hideButton ? null : <button onClick={onButtonClick} id={`${stringToDashedLowercase(title)}-button`} className="view-all">
        {buttonText} {ArrowForwardIcon(isWhite ? colors.white : colors.primary)}
      </button>}
    </SectionTitleButtonWrapper>
  );
};

export default SectionTitleButton;
