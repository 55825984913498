import { colors } from "&assets/constants/colors";
import { device } from "&assets/constants/responsiveness";
import styled from "styled-components";

interface Props {
  showNavbar?: boolean;
}

export const NavbarWrapper = styled.div<Props>`
  height: 100px;
  background-color: #fff;
  position: ${(props) => (props.showNavbar ? "fixed" : "fixed")};
  width: 100%;
  ${(props) => (props.theme.dir === "ltr" ? "padding-left: 64px;" : "padding-right: 64px;")}
  ${(props) => (props.theme.dir === "ltr" ? "padding-right: 40px;" : "padding-left: 40px;")}  
  z-index: 6;
  box-sizing: border-box;
  border-bottom: 1px solid #70778433;
  .logo {
    cursor: pointer;
    padding: 0;
    border: 0;
    background: inherit;
    display: flex;
  }
  .container {
    display: grid;
    grid-template-columns: 35% 36% 25%;
    align-items: center;
    gap: 2%;
    height: 100%;
  }
  .actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .logout-btn {
    background: ${colors.white};
  }
  .logout-btn-text {
    ${(props) => (props.theme.dir === "ltr" ? "margin-right: 11px;" : "margin-left: 11px;")}
    font-weight: 500;
    font-size: 14px;
    line-height: 160%;
    text-align: center;
    color: ${colors.primary};
  }
  .action-item {
    margin: 0 12px;
    cursor: pointer;
    display: flex;
    align-items: center;

    .user-image-wrapper {
      position: relative;
      cursor: pointer;
      background: ${colors.linearGradientWhite} padding-box, ${colors.linearGradientSixth} border-box;
      border-radius: 45px;
      border: 1px solid transparent;
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      overflow: hidden;
      .user-image {
        width: 100%;
        height: 100%;
      }

      .person-icon {
        width: 30px;
        height: 30px;
        color: ${colors.borderLight};
      }

      .notification-dot {
        z-index: 1;
        position: absolute;
        top: 7px;
        right: 7px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 12px;
        height: 12px;
        padding: 1px;
        flex-shrink: 0;
        background: ${colors.secondary};
        border: 1px solid ${colors.primary};
        border-radius: 12px;

        .number-of-unread {
          font-size: 10px;
          font-weight: 700;
          color: ${colors.white};
        }
      }
    }
  }
  .links {
    font-family: "Mulish" !important;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 160%;
    color: ${colors.dark};
    white-space: nowrap;
  }
  .mobile-menu {
    display: none;
  }
  .menu-icon {
    display: none;
    .burger-menu {
      width: ${(props) => (props.showNavbar ? "12px" : "24px")};
      height: ${(props) => (props.showNavbar ? "12px" : "24px")};
    }
  }
  .actions-responsive {
    display: none;
  }
  .nav-elements {
    height: 100%;
    .search-input {
      display: none;
    }
  }
  .search {
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
    display: flex;
    background: ${colors.linearGradientWhite} padding-box, ${colors.linearGradientSixth} border-box;
    border-radius: 50%;
    border: 1px solid transparent;
    ${(props) => (props.theme.dir === "ltr" ? "margin-right: 28px;" : "margin-left: 28px;")}
  }
  .nav-elements .tabs {
    display: flex;
    justify-content: space-between;
    list-style-type: none;
    cursor: pointer;
    height: 100%;
  }
  .nav-elements .tabs .menu:not(:last-child) {
    ${(props) => (props.theme.dir === "ltr" ? "margin-right: 30px;" : "margin-left: 30px;")}
    height: 100%;
    display: flex;
    align-items: center;
    text-decoration: none;
  }

  .selected {
    transition: all 0.2s ease-in;
    border-bottom: 3px solid ${colors.primary};
    align-items: center;
    justify-content: center;
    &::before {
      content: "";
      position: absolute;
      width: 8.17px;
      height: 8.17px;
      bottom: 0;
      background: ${colors.secondary};
      border-radius: 2px;
      transform: rotate(45deg);
    }
  }
  @media (max-width: 1550px) {
    ${(props) => (props.theme.dir === "ltr" ? "padding-left: 20px;" : "padding-right: 20px;")}
    .container {
      display: grid;
      grid-template-columns: 21% 46% 34%;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      gap: 10px;
      height: 100%;
    }

    .nav-elements .tabs .menu:not(:last-child) {
      ${(props) => (props.theme.dir === "ltr" ? "margin-right: 10px;" : "margin-left: 10px;")}
    }

    .action-item {
      margin: 0 6px;
    }
  }
  @media ${device.tablet} {
    .nav-elements .tabs .menu:not(:last-child) {
      ${(props) => (props.theme.dir === "ltr" ? "margin-right: 30px;" : "margin-left: 30px;")}
    }

    .logout-btn-text {
      ${(props) => (props.theme.dir === "ltr" ? "margin-right: 8px;" : "margin-left: 8px;")}
    }
    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .mobile-menu {
      display: flex;
      .action-item {
        margin: 0 6px;
      }
    }
    .menu-icon {
      display: block;
      cursor: pointer;
      width: 40px;
      height: 40px;
      border-radius: 50px;
      padding: 5px;
      border: 1px solid transparent;
      background: ${colors.linearGradientWhite} padding-box, ${colors.linearGradientSixth} border-box;
      justify-content: center;
      align-items: center;
      display: flex;
      box-sizing: border-box;
      ${(props) => (props.theme.dir === "ltr" ? "margin-left: 6px;" : "margin-right: 6px;")}
    }
    .actions {
      display: none;
    }
    .actions-responsive {
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
    .nav-elements {
      position: absolute;
      ${(props) => (props.theme.dir === "ltr" ? "right: 0px;" : "left: 0px;")}
      top: 85px;
      background-color: white;
      width: 0;
      height: calc(100vh - 85px);
      transition: all 0.3s ease-in;
      overflow: hidden;
      margin: 0;
      border-top: 1px solid rgba(44, 56, 63, 0.2);
      .search-input {
        display: block;
        margin: 32px 17px;
      }
    }
    .selected {
      border-bottom: 2px solid ${colors.primary} !important;
      justify-content: inherit;
      &::before {
        display: none;
      }
    }
    .nav-elements.active {
      position: fixed;
      width: 100%;
    }
    .nav-elements .tabs {
      display: flex;
      flex-direction: column;
      height: auto;
    }

    .nav-elements .tabs .menu {
      margin-right: unset;
      margin-top: 19px;
      ${(props) => (props.theme.dir === "ltr" ? "margin-left: 22px;" : "margin-right: 22px;")}
      border-bottom: 1px solid rgba(44, 56, 63, 0.1);
      .links {
        font-weight: 500;
        font-size: 14px;
      }
    }
    padding-left: 16px;
    padding-right: 16px;
    height: 86px;
  }

  @media ${device.mobile} {
    .nav-elements .tabs .menu:not(:last-child) {
      ${(props) => (props.theme.dir === "ltr" ? "margin-right: 30px;" : "margin-left: 30px;")}
    }
    .mobile-menu {
      display: flex;
      .action-item {
        margin: 0 6px;
      }
    }
    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .menu-icon {
      display: block;
      cursor: pointer;
      width: 40px;
      height: 40px;
      border-radius: 50px;
      padding: 5px;
      border: 1px solid transparent;
      background: ${colors.linearGradientWhite} padding-box, ${colors.linearGradientSixth} border-box;
      justify-content: center;
      align-items: center;
      display: flex;
      box-sizing: border-box;
      ${(props) => (props.theme.dir === "ltr" ? "margin-left: 6px;" : "margin-right: 6px;")}
    }
    .actions {
      display: none;
    }
    .actions-responsive {
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-top: 32px;
    }
    .nav-elements {
      position: absolute;
      right: 0px;
      top: 85px;
      background-color: white;
      width: 0;
      height: calc(100vh - 85px);
      transition: all 0.3s ease-in;
      overflow: hidden;
      margin: 0;
      border-top: 1px solid rgba(44, 56, 63, 0.2);
      .search-input {
        display: block;
        margin: 32px 17px;
      }
    }
    .selected {
      border-bottom: 2px solid ${colors.primary} !important;
      justify-content: inherit;
      &::before {
        display: none;
      }
    }
    .nav-elements.active {
      position: fixed;
      width: 100%;
    }
    .nav-elements .tabs {
      display: flex;
      flex-direction: column;
      height: auto;
    }
    .nav-elements .tabs .menu {
      ${(props) => (props.theme.dir === "ltr" ? "margin-right: unset;" : "margin-left: unset;")}
      margin-top: 19px;
      ${(props) => (props.theme.dir === "ltr" ? "margin-left: 22px;" : "margin-right: 22px;")}
      border-bottom: 1px solid rgba(44, 56, 63, 0.1);
      .links {
        font-weight: 500;
        font-size: 14px;
      }
    }
    padding-left: 16px;
    padding-right: 16px;
    height: 86px;
  }
`;

export const EnvironmentTag = styled.div`
  background: ${colors.linearGradientThree};
  border-radius: 4px;
  padding: 2px 4px;
  ${(props) => (props.theme.dir === "ltr" ? "margin-left: 8px;" : "margin-right: 8px;")}
  margin-top: 4px;
  height: 11px;
  .env-text {
    font-weight: 700;
    font-size: 9px;
    line-height: 11px;
    letter-spacing: 0.05em;
    color: ${colors.white};
    text-transform: uppercase;
  }
`;
