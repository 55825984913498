
import styled from "styled-components";

interface Props {
  isBack?: boolean
}

export const ArrowButtonWrapper = styled.div<Props>`
  ${props => props.isBack ? 'transform: scale(-1, 1);' : ''}
  `;

