import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getCareersByIdApi } from "./careerDetails.api";

import { CareerDetails } from "./careerDetails.type";

const initialState: CareerDetails = {
  loading: false,
  selectedCareer: undefined,
};

const getCareersById = createAsyncThunk(
  "careerDetails/getCareersById",
  async (id: string, { rejectWithValue, getState, dispatch }: any) => {
    try {
      const isLoggedIn = getState().login.isLoggedIn;
      const career = await getCareersByIdApi(id, isLoggedIn);
      return career?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const careerDetailsSlice = createSlice({
  name: "careerDetails",
  initialState: initialState,
  reducers: {
    setCareerDetails: (state, action) => {
      return { ...state, ...action.payload };
    },
    reset: () => initialState,
  },

  extraReducers: (builder) => {
    builder.addCase(getCareersById.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getCareersById.fulfilled, (state, action) => {
      state.loading = false;
      state.selectedCareer = action.payload;
    });
    builder.addCase(getCareersById.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const careerDetailsReducer = careerDetailsSlice.reducer;

export const careerDetailsActions = {
  ...careerDetailsSlice.actions,
  getCareersById,
};
