import { ERROR_TEXTS } from "&assets/constants/statics";
import { LearningPathItem } from "&features/listingPage/listingPage.type";
import { translationSelector } from "&utils/translationSelector";
import moment from "moment";
// import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

export const hideActivityButtons = (learningPathItem?: LearningPathItem) => {
  const isNeedAssessment = !!(
    learningPathItem?.learningPaths?.assessmentUrl && learningPathItem?.learningPaths?.type === "need_assessment"
  );
  const isNotAccepted = learningPathItem?.accepted !== 3;
  const isNotShortListed = learningPathItem?.shortListed !== 3;
  const cantStart = moment() < moment(learningPathItem?.learningPaths?.startDate);

  return !!(isNeedAssessment && (isNotAccepted || isNotShortListed || cantStart));
};
export const assessmentCaseSelector = (learningPathItem?: LearningPathItem) => {
  // const isNeedAssessment = !!(
  //   learningPathItem?.learningPaths?.assessmentUrl &&
  //   learningPathItem?.learningPaths?.type === "need_assessment"
  // );
  const isPending = learningPathItem?.accepted === 1;
  const isAccepted = learningPathItem?.accepted === 3;
  const isRejected = learningPathItem?.accepted === 2;
  const isShortListed = learningPathItem?.shortListed === 3;
  const isShortListedRejected = learningPathItem?.shortListed === 2;
  const isShortListedPending = learningPathItem?.shortListed === 1;

  const canStart = moment() >= moment(learningPathItem?.learningPaths?.startDate);

  const case1 = isPending && isShortListedPending;
  const case2 = isShortListed && isPending;
  const case3 = isAccepted && isShortListed && !canStart;
  const case4 = isRejected || isShortListedRejected;

  return case1 ? "case1" : case2 ? "case2" : case3 ? "case3" : case4 ? "case4" : null;
};
export const needAssessmentMessageSelector = (t?: any, learningPathItem?: LearningPathItem) => {
  // const isNeedAssessment = !!(
  //   learningPathItem?.learningPaths?.assessmentUrl &&
  //   learningPathItem?.learningPaths?.type === "need_assessment"
  // );
  const isPending = learningPathItem?.accepted === 1;
  const isAccepted = learningPathItem?.accepted === 3;
  const isRejected = learningPathItem?.accepted === 2;
  const isShortListed = learningPathItem?.shortListed === 3;
  const isShortListedRejected = learningPathItem?.shortListed === 2;
  const isShortListedPending = learningPathItem?.shortListed === 1;

  const canStart = moment() >= moment(learningPathItem?.learningPaths?.startDate);

  const case1 = isPending && isShortListedPending;
  const case2 = isShortListed && isPending;
  const case3 = isAccepted && isShortListed && !canStart;
  const case4 = isRejected || isShortListedRejected;

  return case1
    ? parse(
        `${translationSelector(
          ERROR_TEXTS({
            ASSESSMENT_PENDING: `To access the pre-assessment again, please click on the <a style="color: white;" target="_blank" href=${learningPathItem.learningPaths.assessmentUrl}>link</a> here`,
            ASSESSMENT_PENDINGAr: ` للوصول إلى اختبار التقييم، يرجى النقر على <a style="color: white;" target="_blank" href=${learningPathItem.learningPaths.assessmentUrl}>الرابط</a> هنا`,
          }),
          "ASSESSMENT_PENDING"
        )}`
      )
    : case2
    ? translationSelector(ERROR_TEXTS, "ASSESSMENT_SHORTLISTED_PENDING_ACCEPTED")
    : case3
    ? translationSelector(ERROR_TEXTS, "ASSESSMENT_APPROVED_DID_NOT_START")
    : case4
    ? translationSelector(ERROR_TEXTS, "ASSESSMENT_NOT_SHORTLISTED_OR_REJECTED")
    : null;
};

export const getCourseIdFromPath = (pathname: string) => {
  const pathSplitted = pathname.split("/");
  const indexOfCourseId = pathSplitted.indexOf("course-details") + 1;
  return pathSplitted[indexOfCourseId] ? pathSplitted[indexOfCourseId] : undefined;
};
