import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { CertificationsI } from "./certifications.type";
import { getCertificateHeaderAPI, getCertificationsAPI } from "./certifications.api";

const initialState: CertificationsI = {
  loadingCertifications: false,
  status: "",
  certifications: [],
  certificationHeader: null,
  loadingHeader: false,
};

const getCertifications = createAsyncThunk(
  "certifications/getCertifications",
  async (body: { source: string | undefined | null }, { rejectWithValue, getState, dispatch }: any) => {
    try {
      const certificates = await getCertificationsAPI(body.source);
      return certificates?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const getCertificateHeader = createAsyncThunk(
  "certifications/getCertificateHeader",
  async (body, { rejectWithValue, getState, dispatch }: any) => {
    try {
      const certificates = await getCertificateHeaderAPI();
      return certificates?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
const certificationsPageSlice = createSlice({
  name: "certifications",

  initialState: initialState,

  reducers: {
    setCertifications: (state, action) => {
      return { ...state, ...action.payload };
    },
    reset: () => initialState,
  },

  extraReducers: (builder) => {
    builder.addCase(getCertifications.pending, (state, action) => {
      state.loadingCertifications = true;
    });
    builder.addCase(getCertifications.fulfilled, (state, action) => {
      state.loadingCertifications = false;
      state.certifications = action.payload;
      state.status = "GET_CERTIFICATIONS_SUCCESS";
    });
    builder.addCase(getCertifications.rejected, (state, action) => {
      state.loadingCertifications = false;
    });

    builder.addCase(getCertificateHeader.pending, (state, action) => {
      state.loadingHeader = true;
    });
    builder.addCase(getCertificateHeader.fulfilled, (state, action) => {
      state.loadingHeader = false;
      state.certificationHeader = action.payload;
      state.status = "GET_CERTIFICATIONS_HEADER_SUCCESS";
    });
    builder.addCase(getCertificateHeader.rejected, (state, action) => {
      state.loadingHeader = false;
    });
  },
});

export const certificationsPageReducer = certificationsPageSlice.reducer;

export const certificationsPageActions = {
  ...certificationsPageSlice.actions,
  getCertifications,
  getCertificateHeader,
};
