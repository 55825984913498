import React from "react";
import { AboutHeaderAims } from "./aboutAims.styled";
import { useTranslation } from "react-i18next";
import ImageDisplay from "&components/imageDisplay/imageDisplay.component";
import { images } from "&assets/constants/images-urls";

interface Props {}

const AboutAims = (props: Props) => {
  const { t } = useTranslation(["about"]);
  return (
    <AboutHeaderAims>
      <div className="header-wrapper">
        <div className="header-blue-area-image-wrapper">
          <div className="header-blue-area">
            <ImageDisplay
              url={images.aboutUs6}
              isBackgroundImage={true}
              isFromS3={true}
              className="about-us-header-image"
            />
          </div>
        </div>
        <div className="header-text-area">
          <p>{t("WITH_OUR_UNWAVERING")}</p>
        </div>
      </div>
    </AboutHeaderAims>
  );
};

export default AboutAims;
