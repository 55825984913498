import React, { useEffect } from "react";
import { toast } from "react-hot-toast";

import { AddSkillModalWrapper } from "./addSkillModal.styled";
import CertificateIcon from "&assets/images/certificate.svg";
import SubmitButton from "&components/buttons/submitButton/submitButton.component";
import CustomModal from "&components/custom-modal/custom-modal.component";
import SkillsList from "&components/skillsList/skillsList.component";
import { CourseSkill } from "&features/listingPage/listingPage.type";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "&store/store";
import { loginActions } from "&features/login/login.slice";
import { addedSkillsSelector } from "&utils/addedSkillsSelector";
import { buildAssessmentBodyForAdd } from "&utils/buildAssessmentObject";
import { useTranslation } from "react-i18next";

interface Props {
  title?: string;
  handleButtonClick?: Function;
  handleClose: Function;
  open: boolean;
  skills: CourseSkill[];
}

const AddSkillModal = (props: Props) => {
  const { handleClose, open, skills = [] } = props;
  const { t } = useTranslation(["listingPage"]);

  const user = useSelector((state: RootState) => state.login.user);

  let userSkills = buildAssessmentBodyForAdd(
    {
      hardSkills: user?.userSkills?.hard_skills,
      softSkills: user?.userSkills?.soft_skills,
      otherSkills: user?.userSkills?.other_skills,
    },
    true
  );

  const dispatch = useDispatch();
  const loadingUpdateProfile = useSelector(
    (state: RootState) => state.login.loadingUpdateProfile
  );
  const updateProfileStatus = useSelector(
    (state: RootState) => state.login.updateProfileStatus
  );

  const { finalSkills, disableAddSkills: disabled } = addedSkillsSelector(
    userSkills,
    skills
  );

  useEffect(() => {
    if (updateProfileStatus === "UPDATE_PROFILE_SUCCESSFUL") {
      dispatch(loginActions.setLogin({ updateProfileStatus: "" }));
      dispatch(loginActions.getProfileThunk({}));
      toast.success(t("SKILLS_ADEDD_SUCCESS"), { position: "top-right" });
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateProfileStatus]);

  const handleAddSkills = () => {
    dispatch(
      loginActions.updateProfile({
        skills: finalSkills,
      })
    );
  };

  return (
    <CustomModal handleClose={() => handleClose()} open={open}>
      <AddSkillModalWrapper>
        <div className="content">
          <img src={CertificateIcon} width={"174px"} height={"174px"} alt="" />
          <div className="line-div" />
          <p className="description">{disabled ? "" : t("BELOW_SKILLS")}</p>
          <SkillsList skills={skills}></SkillsList>
        </div>
        {(!disabled || loadingUpdateProfile) && (
          <SubmitButton
            id="add-skills-btn"
            title={t("UPDATE_SKILLS_IN_PROFILE")}
            handleClick={() => handleAddSkills()}
            className="add-skills-btn"
            loading={loadingUpdateProfile}
            disable={disabled}
            titleClassName="add-skills-btn-text"
          />
        )}
      </AddSkillModalWrapper>
    </CustomModal>
  );
};

export default AddSkillModal;
