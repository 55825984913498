import styled from "styled-components";
import { colors } from "&assets/constants/colors";
import { device } from "&assets/constants/responsiveness";

export const DunnoWhichCareerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 228px;
  min-width: 228px;
  max-height: 432px;
  min-height: 432px;
  flex-shrink: 0;
  border-radius: 16px;
  background: ${colors.linearGradientOne};
  margin-bottom: 24px;

  .main-title {
    margin: 32px 24px 17px;
    width: 165px;
    color: ${colors.white};
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 21.6px */
  }

  .image-background {
    width: 240px;
    height: 124px;
    position: relative;
  }

  .description {
    margin: 24px 24px 16px;
    width: 172px;
    color: ${colors.white};
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 18px */
  }

  .sign-up-button {
    margin: 0 24px;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 30px;
    background: ${colors.white};
    .title {
      color: ${colors.primary};
    }
  }

  @media ${device.tablet} {
    min-width: unset;
    max-width: unset;
    min-height: 130px;
    height: fit-content;
    max-height: 160px;
    padding: 25px;
    position: relative;
    margin: 30px 0;
    width: auto;

    .image-background {
      position: absolute;
      width: 245px;
      height: unset;
      ${(props) => (props.theme.dir === "rtl" ? "left: 0px;" : "right: 0px;")}
      top: 10px;
      bottom: 10px;
      margin: auto;
    }
    .description {
      margin: 10px;
      margin-left: 0px;
      width: calc(100% - 270px);
    }
    .main-title {
      margin: 0px;
      margin-left: 0px;
      width: 470px;
    }
    .sign-up-button {
      margin: 0px;
    }
  }
  @media ${device.mobile} {
    min-width: unset;
    max-width: unset;
    min-height: unset;
    height: fit-content;
    max-height: unset;
    padding: 0px;
    position: relative;
    margin: 30px 0;
    width: auto;

    .image-background {
      position: relative;
      height: unset;
      ${(props) => (props.theme.dir === "rtl" ? "left: 0px;" : "right: 0px;")}
      top: 10px;
      bottom: 10px;
      width: 100%;
    }
    .description {
      margin: 0;
      margin: 20px 0;
      margin-left: 0px;
      width: auto;
      padding: 0 25px;
    }
    .main-title {
      margin: 0px;
      margin-top: 10px;
      margin-left: 0px;
      width: auto;
      padding: 0 25px;
    }
    .sign-up-button {
      margin: 0 25px;
      margin-bottom: 10px;
      padding: 0 25px;
    }
  }
`;
