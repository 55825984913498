// @ts-nocheck
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { useTranslation } from "react-i18next";

import { RootState } from "&store/store";

import { assessmentActions } from "./../../assessment.slice";
import { SectionWrapper } from "&features/assessment/screens/skills/skills.styled";
import RadioGroup from "&components/radioGroup/radioGroup.component";
import {
  AssessmentForm,
  RadioOptionsTypes,
} from "&features/assessment/assessment.type";
import CircularLoader from "&components/circularLoader/circularLoader.component";
import { Setting } from "&features/global/global.type";
import SkillsBuilder from "&components/skillsBuilder/skillsBuilder.component";
import SectionTitleWithLine from "&components/sectionTitleWithLine/sectionTitleWithLine.component";

type ReduxProps = ConnectedProps<typeof connector>;

interface Props {
  formValues: AssessmentForm;
  setAssessmentForm: any;
  loadingAssessment: boolean;
  settings: Setting[];
}

const SkillsComponent = (props: ReduxProps | Props) => {
  const { setAssessmentForm, formValues, loadingAssessment, settings } = props;

  const { t } = useTranslation(["assessment"]);

  const handleChangeValues = (value: AssessmentForm) => {
    setAssessmentForm({ ...formValues, ...value });
  };

  const renderRadioOptions = (
    data: any[],
    key: RadioOptionsTypes,
    title: string
  ) => {
    return (
      <SectionWrapper>
        <SectionTitleWithLine title={title} />
        <RadioGroup
          data={data}
          value={formValues[key]}
          onChange={(value) => handleChangeValues({ [key]: value })}
        />
      </SectionWrapper>
    );
  };

  if (loadingAssessment) {
    return <CircularLoader />;
  }

  return (
    <div className="content-padding">
      {settings?.age_range &&
        renderRadioOptions(
          settings.age_range,
          "age_range",
          t("SELECT_YOUR_AGE_RANGE")
        )}
      {settings?.education_level &&
        renderRadioOptions(
          settings.education_level,
          "education_level",
          t("SELECT_YOUR_EDUCATION_LEVEL")
        )}
      {/* {settings?.english_proficiency &&
        renderRadioOptions(
          settings.english_proficiency,
          "english_proficiency",
          t("SELECT_YOUR_ENGLISH_PROFICIENCY")
        )} */}
      <SkillsBuilder
        formValues={formValues}
        setAssessmentForm={setAssessmentForm}
        loadingAssessment={loadingAssessment}
        settings={settings}
      />
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  // Map your redux state to your props here
  formValues: state.assessment.assessmentFormState,
  selectedCareerPath: state.landingPage.selectedCareerPath,
  loadingAssessment: state.assessment.loadingAssessment,
  settings: state.global.settings,
});

const mapDispatchToProps = {
  setAssessmentForm: assessmentActions.setAssessmentForm,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const SkillsComponentRedux = connector(SkillsComponent);

export { SkillsComponentRedux as SkillsComponent };
