import React from "react";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import { LanguagePickerWrapper } from "./languagePicker.styled";
import ArrowDownOutlined from "&assets/images/arrow-down-outlined.svg";
import { useDispatch, useSelector } from "react-redux";
import { loginActions } from "&features/login/login.slice";
import { RootState } from "&store/store";

interface Props {}

const LanguagePicker = (props: Props) => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.login.user);

  const handleChangeLanguage = (language: "en" | "ar") => {
    dispatch(loginActions.setUser({ language: language }));
    if (user?.name) {
      dispatch(
        loginActions.updateProfileLanguage({ contentLanguage: language })
      );
    }
  };

  return (
    <LanguagePickerWrapper>
      <Select
        variant="standard"
        labelId="demo-simple-select-standard-label"
        id="demo-simple-select-standard"
        value={user?.language || "en"}
        onChange={(event: SelectChangeEvent) => {
          const lang: "en" | "ar" = event.target.value === "ar" ? "ar" : "en";
          handleChangeLanguage(lang);
        }}
        label="Language"
        className="select-component"
        IconComponent={(props) => (
          <img src={ArrowDownOutlined} alt="country" {...props} />
        )}
      >
        <MenuItem value={"en"}>English</MenuItem>
        <MenuItem value={"ar"}>العربية</MenuItem>
      </Select>
    </LanguagePickerWrapper>
  );
};

export default LanguagePicker;
