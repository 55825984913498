import { API } from "aws-amplify";
import { RES_BLOGS } from "&store/resources";
import { definedObjectBuilder } from "&utils/definedObjectBuilder";
import { GetPageListingQuery } from "&features/listingPage/listingPage.type";

const getBlogsApi = async (body: GetPageListingQuery) => {
  let URL = `${RES_BLOGS}`;

  const myInit: any = {
    queryStringParameters: definedObjectBuilder(body),
  };
  return API.get(process.env.REACT_APP_NAME || "", URL, myInit);
};

export { getBlogsApi };
