import {
  AssessmentForm,
  SkillRequest,
  SkillValueAttributes,
} from "&features/assessment/assessment.type";
import { levelsDict } from "&assets/constants/levelMarks";
import { isEmpty } from "lodash";

const buildAssessmentBody: (
  state: AssessmentForm,
  isWeight?: boolean
) => SkillRequest[] = (formState: AssessmentForm, isWeight?: boolean) => {
  const skillsList: SkillRequest[] = [];
  const valueAttribute: SkillValueAttributes = isWeight ? "weight" : "value";
  Object.keys(formState.hardSkills)?.map((key) => {
    formState?.hardSkills[key]?.value?.checked &&
      skillsList.push({
        skillId: formState?.hardSkills[key]?.id,
        [valueAttribute]: levelsDict[formState?.hardSkills[key]?.value?.level],
      });
  });
  Object.keys(formState.softSkills)?.map((key) => {
    formState?.softSkills[key]?.value?.checked &&
      skillsList.push({
        skillId: formState?.softSkills[key]?.id,
        [valueAttribute]: levelsDict[formState?.softSkills[key]?.value?.level],
      });
  });
  formState?.otherSkills?.map((skill) => {
    skillsList.push({
      skillId: skill?.id,
      [valueAttribute]: levelsDict[skill?.value?.level || "beginner"],
    });
  });

  return skillsList;
};

export const buildAssessmentBodyForAdd: (
  state: AssessmentForm,
  isWeight?: boolean
) => SkillRequest[] = (formState: AssessmentForm, isWeight?: boolean) => {
  const skillsList: SkillRequest[] = [];
  const valueAttribute: SkillValueAttributes = isWeight ? "weight" : "value";

  if (!isEmpty(formState?.otherSkills)) {
    formState?.otherSkills?.map((skill) => {
      skillsList.push({
        skillId: skill?.id,
        [valueAttribute]: skill.weight,
      });
    });
  }

  if (!isEmpty(formState?.softSkills)) {
    formState?.softSkills?.map((skill: any) => {
      skillsList.push({
        skillId: skill?.id,
        [valueAttribute]: skill.weight,
      });
    });
  }

  if (!isEmpty(formState?.hardSkills)) {
    formState?.hardSkills?.map((skill: any) => {
      skillsList.push({
        skillId: skill?.id,
        [valueAttribute]: skill.weight,
      });
    });
  }

  return skillsList;
};

export default buildAssessmentBody;
