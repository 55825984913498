import React from "react";
import { NewLineTextWrapper } from "./newLineText.styled";

interface Props {
  text?: string;
}

const NewLineText = (props: Props | any) => {
  const { text } = props;

  const newText: string[] = text?.split("\n");

  return (
    <>
      {newText?.map((str: string, index) => (
        <NewLineTextWrapper key={index} {...props}>{str}</NewLineTextWrapper>
      ))}
    </>
  );
};

export default NewLineText;
