import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getUserCertificatesApi,
  getUserCoursesApi,
  getUserEducationApi,
  getUserEligibilityFormApi,
  getUserLearningPathsApi,
  getUserSponsorshipsApi,
  getUserWorkExperienceApi,
} from "&features/login/login.api";
import { eligibilityStatusSelector } from "&utils/profile/profileSelectors";
import { loginActions } from "&features/login/login.slice";
import { NoInfer } from "@reduxjs/toolkit/dist/tsHelpers";

export const getUserEligibilityForm = createAsyncThunk(
  "login/getUserEligibilityForm",
  async (_, { rejectWithValue, getState, dispatch }: any) => {
    try {
      const result = await getUserEligibilityFormApi();
      const newStatus = eligibilityStatusSelector(result.data);
      if (
        getState().login?.eligibilityStatus &&
        newStatus !== getState().login.eligibilityStatus
      ) {
        dispatch(
          loginActions.setLogin({
            eligibilityStatus: newStatus,
            showBanner: true,
          })
        );
      }
      return { ...result?.data, status: newStatus };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getUserEducation = createAsyncThunk(
  "login/getUserEducation",
  async (_, { rejectWithValue, getState, dispatch }: any) => {
    try {
      const result = await getUserEducationApi();
      return result?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getUserWorkExperience = createAsyncThunk(
  "login/getUserWorkExperience",
  async (_, { rejectWithValue, getState, dispatch }: any) => {
    try {
      const result = await getUserWorkExperienceApi();
      return result?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getUserCourses = createAsyncThunk(
  "login/getUserCourses",
  async (_, { rejectWithValue, getState, dispatch }: any) => {
    try {
      const result = await getUserCoursesApi();
      return result?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getUserLearningPaths = createAsyncThunk(
  "login/getUserLearningPaths",
  async (_, { rejectWithValue, getState, dispatch }: any) => {
    try {
      const result = await getUserLearningPathsApi();
      return result?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getUserCertificates = createAsyncThunk(
  "login/getUserCertificates",
  async (_, { rejectWithValue, getState, dispatch }: any) => {
    try {
      const result = await getUserCertificatesApi();
      return result?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getUserSponsorships = createAsyncThunk(
  "login/getUserSponsorships",
  async (_, { rejectWithValue, getState, dispatch }: any) => {
    try {
      const result = await getUserSponsorshipsApi();
      return result?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const userExtraReducers = (
  builder: ActionReducerMapBuilder<NoInfer<any>>
) => {
  builder.addCase(getUserEligibilityForm.pending, (state, action) => {
    state.loadingUserInfo = true;
  });
  builder.addCase(getUserEligibilityForm.fulfilled, (state, action) => {
    state.loadingUserInfo = false;
    state.user.eligibilityForm = action.payload;
  });
  builder.addCase(getUserEligibilityForm.rejected, (state, action) => {
    state.loadingUserInfo = false;
  });
  builder.addCase(getUserEducation.pending, (state, action) => {
    state.loadingUserInfo = true;
  });
  builder.addCase(getUserEducation.fulfilled, (state, action) => {
    state.loadingUserInfo = false;
    state.user.education = action.payload;
  });
  builder.addCase(getUserEducation.rejected, (state, action) => {
    state.loadingUserInfo = false;
  });
  builder.addCase(getUserWorkExperience.pending, (state, action) => {
    state.loadingUserInfo = true;
  });
  builder.addCase(getUserWorkExperience.fulfilled, (state, action) => {
    state.loadingUserInfo = false;
    state.user.workExperience = action.payload;
  });
  builder.addCase(getUserWorkExperience.rejected, (state, action) => {
    state.loadingUserInfo = false;
  });
  builder.addCase(getUserCourses.pending, (state, action) => {
    state.loadingUserInfo = true;
  });
  builder.addCase(getUserCourses.fulfilled, (state, action) => {
    state.loadingUserInfo = false;
    state.user.userCourses = action.payload?.onGoingCourses;
    state.user.completedCourses = action.payload?.userCourses;
  });
  builder.addCase(getUserCourses.rejected, (state, action) => {
    state.loadingUserInfo = false;
  });
  builder.addCase(getUserLearningPaths.pending, (state, action) => {
    state.loadingUserInfo = true;
  });
  builder.addCase(getUserLearningPaths.fulfilled, (state, action) => {
    state.loadingUserInfo = false;
    state.user.userLearningPaths = action.payload?.onGoingLearningPaths;
    state.user.completedLearningPaths = action.payload?.userLearningPaths;
  });
  builder.addCase(getUserLearningPaths.rejected, (state, action) => {
    state.loadingUserInfo = false;
  });
  builder.addCase(getUserCertificates.pending, (state, action) => {
    state.loadingUserInfo = true;
  });
  builder.addCase(getUserCertificates.fulfilled, (state, action) => {
    state.loadingUserInfo = false;
    state.user.userCertificates = action.payload;
  });
  builder.addCase(getUserCertificates.rejected, (state, action) => {
    state.loadingUserInfo = false;
  });
  builder.addCase(getUserSponsorships.pending, (state, action) => {
    state.loadingUserInfo = true;
  });
  builder.addCase(getUserSponsorships.fulfilled, (state, action) => {
    state.loadingUserInfo = false;
    state.user.sponsorships = action.payload;
  });
  builder.addCase(getUserSponsorships.rejected, (state, action) => {
    state.loadingUserInfo = false;
  });
};
