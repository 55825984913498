import styled from "styled-components";
import { device } from "&assets/constants/responsiveness";

export const ProfileNationalityFormWrapper = styled.div`
  padding: 20px 0 0 0;
  .remove-padding {
    padding: 16px 35px 135px 5px;
  }
  @media ${device.mobile}, ${device.tablet} {
    padding: 0 0 0 0;
  }
`;
