import { colors } from "&assets/constants/colors";
import { device } from "&assets/constants/responsiveness";
import styled from "styled-components";

export const UploadCompletionProofWrapper = styled.div`
  padding: 10px 135px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .upload-title {
    font-weight: 700;
    font-size: 48px;
    line-height: 110%;
    text-align: center;
    letter-spacing: -0.02em;
    color: ${colors.dark};
    margin: 0;
    margin-bottom: 24px;
  }
  .upload-subtitle {
    font-weight: 500;
    font-size: 18px;
    line-height: 160%;
    text-align: center;
    margin: 0;
    color: ${colors.dark};
    margin-bottom: 30px;
  }
  .upload-proof {
    width: 592px;
    min-height: 246px;
    height: auto;
  }

  .send-approval-button {
    height: 38px;
    margin-top: 16px;
    .send-approval-title {
      font-weight: 800;
      font-size: 14px;
      line-height: 160%;
    }
  }

  @media ${device.mobile} {
    padding: 10px 16px;
    .upload-title {
      font-weight: 700;
      font-size: 30px;
      line-height: 110%;
      text-align: center;
      letter-spacing: -0.02em;
      color: ${colors.dark};
      margin: 0;
      margin-bottom: 24px;
    }
    .upload-subtitle {
      font-weight: 500;
      font-size: 16px;
      line-height: 160%;
      text-align: center;
      margin: 0;
      color: ${colors.dark};
      margin-bottom: 30px;
    }
    .upload-proof {
      width: 100%;
    }
  }
`;
