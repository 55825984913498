import { colors } from "&assets/constants/colors";
import { device } from "&assets/constants/responsiveness";
import styled from "styled-components";

export const AboutWrapper = styled.div`
  .about-us {
    display: flex;
    flex-direction: column;
    padding: 72px 289px 80px 289px;
    margin-top: 132px;
    .title {
      font-weight: 700;
      font-size: 48px;
      line-height: 110%;
      text-align: center;
      letter-spacing: -0.02em;
      color: ${colors.dark};
      margin-bottom: 24px;
    }
    .sub-title {
      font-weight: 500;
      font-size: 18px;
      line-height: 160%;
      text-align: center;
      color: ${colors.dark};
      opacity: 0.7;
    }
  }
  .informative {
    background: ${colors.linearGradientOne};
    padding: 64px 92px;
    .card {
      width: 609px;
      height: 100%;
      .informative-description {
        height: 80px;
      }
    }
  }
  @media ${device.inBetween} {
    .about-us {
      padding: 72px 200px 80px 200px;
      .title {
        font-size: 40px;
      }
      .sub-title {
        font-size: 18px;
      }
    }
    .informative {
      .card {
        margin: 8px 0;
      }
    }
  }
  @media ${device.tablet} {
    .about-us {
      padding: 0px 30px 80px 30px;
      .title {
        font-size: 30px;
      }
      .sub-title {
        font-size: 17px;
      }
    }
  }
  @media ${device.mobile}, ${device.tablet} {
    .about-us {
      padding: 0px 18.5px 80px 18.5px;
      .title {
        font-size: 28px;
      }
      .sub-title {
        font-size: 16px;
      }
    }

    .informative {
      background: ${colors.linearGradientOne};
      padding: 24px 16px;
      box-sizing: border-box;
      .card {
        width: 100%;
        min-height: 340px;
        height: auto;
        padding: 32px 26px;
        .informative-description {
          padding: 0;
          margin-bottom: 16px;
          height: auto;
        }
        .informative-card-btn {
          min-width: 208px;
          width: auto;
          height: 38px;
          margin-top: 0;
          justify-content: center;
        }
      }
    }
  }
`;
