import styled from "styled-components";
import { colors } from "&assets/constants/colors";
import { device } from "&assets/constants/responsiveness";

export const InCollaborationWithWrapper = styled.div`
  padding: 24px 64px;
  .collaboration-title {
    margin: 0;
    color: ${colors.primary};
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 21.6px */
  }
  .logos-wrapper {
    display: grid;
    grid-template-columns: auto auto auto;
    justify-content: space-around;
    width: 390px;
    padding: 0 24px;
    margin: 16px 0;
    align-items: center;
    gap: 10px;
    border-radius: 16px;
    background: ${colors.white};

    .aws {
      width: 44px;
      height: 26px;
    }

    .linkedin {
      width: 100px;
      height: 24px;
    }

    .microsoft {
      width: 94px;
      height: 52px;
    }

    .dot {
      width: 50px;
    }

    .simplilearn {
      width: 94px;
      height: 52px;
    }
  }
  .description {
    color: ${colors.dark};
    width: 465px;
    margin: 0;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 24px */
    opacity: 0.7;
  }

  @media ${device.tablet} {
    padding: 130px 16px 0;
  }

  @media ${device.mobile} {
    .logos-wrapper {
      gap: initial;
      width: initial;
    }
    .description {
      width: initial;
    }
    padding: 30px 16px 0;
  }
`;
