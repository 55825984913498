import styled from "styled-components";
import { device } from "&assets/constants/responsiveness";
import { Tabs } from "@mui/material";
import { colors, levelsBackground } from "&assets/constants/colors";

export const CareerListingWrapper = styled.div`
  .loader-wrapper {
    height: 200px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .listing-container {
    padding: 0 64px;
    width: initial;
    box-sizing: border-box;
  }

  @media ${device.tablet}, ${device.mobile} {
    .listing-container {
      padding: 0;
    }
  }
`;

export const LevelsMobileTabs = styled(Tabs)`
  width: 100%;
  min-height: 19px !important;
  box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.25);

  .MuiButtonBase-root {
    color: ${colors.primary};
    font-family: "Mulish";
    font-style: normal;
    font-weight: 800;
    font-size: 12px;
    line-height: 160%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    opacity: 0.5;
    min-height: 19px;
    padding: 5px 16px;
    &.Mui-selected {
      color: ${colors.primary};
      opacity: 1;
    }
  }
  .MuiTabs-indicator {
    background-color: ${colors.secondaryDark};
  }

  @media ${device.tablet} {
    min-height: 30px !important;

    .MuiButtonBase-root {
      font-size: 14px;
    }
  }
`;

interface TabPanelProps {
  level: string;
  index: number;
}

export const TabPanelWrapper = styled.div<TabPanelProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 0;
  height: 100%;
  background: ${(props) => (props?.level ? levelsBackground[props.index] : levelsBackground[0])};
  box-sizing: border-box;
`;
