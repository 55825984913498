import React from "react";
import { useSelector } from "react-redux";

import { ProfileSkillsWrapper } from "./profileSkills.styled";
import { Skill, Skills } from "&features/assessment/assessment.type";
import SkillChip from "&components/skillChip/skillChip.component";
import { RootState } from "&store/store";

// const skillsNameSelector: any = {
//   soft_skills: "Soft Skills",
//   hard_skills: "Hard Skills",
//   other_skills: "Other Skills",
// };
const ProfileSkills = (props: any) => {
  const userSkills: Skills | undefined = useSelector(
    (state: RootState) => state.login.user?.userSkills
  );

  return (
    <ProfileSkillsWrapper>
      {userSkills &&
        Object.entries(userSkills)?.map(
          (skills: any, index: number) =>
            skills[1] &&
            skills[1]?.length > 0 && (
              <div className="skill-type-wrapper" key={index}>
                {/*<div className="skill-title">{skillsNameSelector[skills[0]]}</div>*/}
                <div className="skills-wrapper">
                  {skills[1]?.map((skill: Skill, index: number) => (
                    <SkillChip
                      key={index}
                      sliceTitle={false}
                      level={skill?.weight}
                      className="skill-chip"
                      title={skill?.name}
                      titleClassName="skill-name-text"
                    />
                  ))}
                </div>
              </div>
            )
        )}
    </ProfileSkillsWrapper>
  );
};

export default ProfileSkills;
