import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";

import { colors } from "&assets/constants/colors";
import { ArrowForwardIcon, HeaderLines } from "&assets/constants/icons";
import { size } from "&assets/constants/responsiveness";
import SubmitButton from "&components/buttons/submitButton/submitButton.component";
import Container from "&components/container/container.component";
import PageTitleSubtitle from "&components/pageTitleSubtitle/pageTitleSubtitle.component";
import { LearningHeaderWrapper } from "./learningHeader.styled";
import SourceLogo from "&components/sourceLogo/sourceLogo.component";
import LayeredImage from "&components/layeredImage/layeredImage.component";
import IsSponsoredMessage from "&components/messages/isSponsoredMessage/isSponsoredMessage.component";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Career } from "&features/landingPage/landingPage.type";
import VideoModal from "&components/modals/videoModal/videoModal.component";
import { translationSelector } from "&utils/translationSelector";

interface Informatics {
  icon: any;
  text: string;
}

interface Props {
  title?: string;
  description?: string;
  image?: any;
  handleBackClick?: Function;
  providerName?: string;
  hasButton?: boolean;
  handleButtonClick?: Function;
  level?: string;
  informatics?: Informatics[];
  buttonText?: string;
  loading?: boolean;
  buttonComponent?: any;
  disbaledButton?: boolean;
  sponsored?: boolean;
  disclaimer?: string;
  isSponsored?: boolean;
  enrollForSponsorship?: boolean;
  startDate?: string;
  enrollmentStartDate?: string;
  enrollmentEndDate?: string;
  hasCertification?: boolean;
  selectedCareer?: Career;
  career?: Career;
  otherMessage?: string;
  type?: string;
}

const LearningHeader = (props: Props) => {
  const {
    title,
    description,
    image,
    handleBackClick,
    providerName,
    hasButton = false,
    handleButtonClick,
    level,
    informatics,
    career,
    buttonText = "Enroll now",
    loading,
    buttonComponent,
    disbaledButton = false,
    sponsored = false,
    disclaimer = undefined,
    isSponsored,
    enrollForSponsorship,
    enrollmentEndDate,
    enrollmentStartDate,
    startDate,
    hasCertification,
    selectedCareer = undefined,
    otherMessage,
    type,
  } = props;
  const { t } = useTranslation(["learningPathway"]);
  const [showVideo, setShowVideo] = useState<{
    show: boolean;
    career?: Career;
  }>({ show: false, career: undefined });
  const isMobile = useMediaQuery({ maxWidth: size.mobile });
  const isTablet = useMediaQuery({
    minWidth: size.mobile,
    maxWidth: size.tablet,
  });

  const hasDates = startDate || enrollmentStartDate || enrollmentEndDate;

  const renderCertificationBadge = () => {
    return (
      <div className="certification-badge">
        <span className="certification-badge-text">{t("CERTIFICATION")}</span>
      </div>
    );
  };

  const handleClickVideo = (career: Career) => {
    setShowVideo({ show: true, career: career });
  };

  const handleCloseVideo = () => {
    setShowVideo({ show: false, career: undefined });
  };

  return (
    <LearningHeaderWrapper isExam={type === "exam"}>
      <HeaderLines className={"header-lines"} width={isMobile ? 265 : 396} height={isMobile ? 254 : 380} opacity={1} />
      <Container className="container">
        <button onClick={() => handleBackClick && handleBackClick()} className="back-button">
          <div className="arrow">{ArrowForwardIcon(colors.white)}</div>
          <div className="button-text">{t("BACK")}</div>
        </button>
        <div className="content">
          {(isMobile || isTablet) && <p className="title-mobile">{title}</p>}
          <LayeredImage
            src={image}
            level={level}
            wrapperClassName="learning-path-image"
            sponsored={sponsored}
            onVideoClick={handleClickVideo}
            selectedCareer={selectedCareer}
          />

          <div className="information">
            <PageTitleSubtitle className={"title-subtitle-wrapper"} title={title} subTitle={description} />
            <div className="card-career-details">
              <span>{translationSelector(career, "name")}</span>
              <span>{translationSelector(career, "shortDescription")}</span>
            </div>
            <div className="card-content">
              {providerName && (
                <div className="card-content info expand">
                  <SourceLogo source={providerName ? providerName.toLowerCase() : ""} />
                  <p className="title">{providerName}</p>
                </div>
              )}
              {informatics?.map((item: Informatics, index: number) => {
                return (
                  <p className="info" key={index}>
                    {item.icon}
                    <span className="text">{item.text}</span>
                  </p>
                );
              })}
              {hasCertification && renderCertificationBadge()}
            </div>
            {hasDates && (
              <div className="start-end-dates-wrapper">
                {enrollmentStartDate && enrollmentEndDate && (
                  <span className={"enrollment-dates-wrapper"}>
                    {`${t("ENROLLMENT_FROM")} ${moment(enrollmentStartDate).format("DD-MM-YYYY")} ${t("TO")} ${moment(
                      enrollmentEndDate
                    ).format("DD-MM-YYYY")}`}
                  </span>
                )}
                {startDate && <span>{`${t("START")}: ${moment(startDate).format("DD-MM-YYYY")}`}</span>}
              </div>
            )}
            <div className="button-components">
              {disclaimer && <p className="disclaimer">{disclaimer}</p>}
              {buttonComponent && buttonComponent}
              {hasButton && (
                <SubmitButton
                  id="course-details-enroll-btn"
                  title={buttonText}
                  icon={ArrowForwardIcon("#FFFFFF")}
                  handleClick={() => handleButtonClick && handleButtonClick()}
                  className="course-details-enroll"
                  loading={loading}
                  disable={disbaledButton}
                />
              )}
            </div>
            {isSponsored && <IsSponsoredMessage />}
            {otherMessage && <IsSponsoredMessage otherMessage={otherMessage} />}
            {enrollForSponsorship && (
              <IsSponsoredMessage otherMessage={t("UPON_ENROLLMENT_YOU_CAN_APPLY_FOR_SPONSORSHIP")} />
            )}
          </div>
        </div>
        <VideoModal onClose={handleCloseVideo} open={showVideo?.show} career={showVideo?.career} />
      </Container>
    </LearningHeaderWrapper>
  );
};

export default LearningHeader;
