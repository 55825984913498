import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { FutureCareers } from "./futureCareers.type";
import { GetCareersBody } from "&features/landingPage/landingPage.type";
import { getLevelsApi } from "&features/landingPage/landingPage.api";

const initialState: FutureCareers = {
  levels: [],
  loadingLevels: false,
  status: "",
};

const getLevels = createAsyncThunk(
  "futureCareers/getLevels",
  async (
    body: GetCareersBody,
    { rejectWithValue, getState, dispatch }: any
  ) => {
    try {
      const countryId = getState().landingPage.selectedCountry.value;
      const levels = await getLevelsApi(body.limit, countryId);
      return levels?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const futureCareersSlice = createSlice({
  name: "futureCareers",
  initialState: initialState,
  reducers: {
    setFutureCareers: (state, action) => {
      return { ...state, ...action.payload };
    },
    reset: () => initialState,
  },

  extraReducers: (builder) => {
    builder.addCase(getLevels.pending, (state, action) => {
      state.loadingLevels = true;
    });
    builder.addCase(getLevels.fulfilled, (state, action) => {
      state.loadingLevels = false;
      state.levels = action.payload;
      state.status = "GET_LEVELS_SUCCESS";
    });
    builder.addCase(getLevels.rejected, (state, action) => {
      state.loadingLevels = false;
    });
  },
});

export const futureCareersReducer = futureCareersSlice.reducer;

export const futureCareersActions = {
  ...futureCareersSlice.actions,
  getLevels,
};
