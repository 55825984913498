import styled from "styled-components";
import { colors } from "&assets/constants/colors";

export const IsSponsoredMessageWrapper = styled.div`
  color: ${colors.white};
  font-weight: 400;
  font-size: 13px;
  line-height: 160%;
  font-style: italic;

  .black-text {
    color: ${colors.dark};
  }
`;
