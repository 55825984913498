import React from "react";
import { TextLineWrapper } from "./textLine.styled";

interface Props {
  title?: string;
}

const TextLine = (props: Props) => {
  const { title } = props;
  return (
    <TextLineWrapper>
      <div className="text">{title}</div>
      <div className="line" />
    </TextLineWrapper>
  );
};

export default TextLine;
