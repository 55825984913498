import { colors } from "&assets/constants/colors";
import { device } from "&assets/constants/responsiveness";
import styled from "styled-components";

interface Props {
  selected?: boolean;
  viewMode?: boolean;
}

export const DashboardMenuWrapper = styled.div<Props>`
  width: 1161px;
  height: 126px;
  background: ${colors.linearGradientSixth};
  border-radius: 0 0 24px 24px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0 41px;
  box-sizing: border-box;
  margin-bottom: ${(props) => (props.viewMode ? "10px" : "0")};
  .actions-list {
    height: 80%;
    display: flex;
  }
  @media ${device.inBetween} {
    width: 100%;
  }

  @media ${device.mobile}, ${device.tablet} {
    width: 100%;
    padding: 0;
    border-radius: 24px 24px 0 0;
    position: fixed;
    z-index: 6;
    bottom: 0;
    height: 116px;
    .actions-list {
      width: 100%;
    }
  }
`;

export const MenuButtonWrapper = styled.div<Props>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  ${(props) =>
    props.theme.dir === "rtl"
      ? "border-right: 1px solid rgba(255, 255, 255, 0.1);"
      : "border-left: 1px solid rgba(255, 255, 255, 0.1);"}
  padding: 0 22px;
  height: 100%;
  width: 118px;
  box-sizing: border-box;
  transition: all 0.2s ease-in;
  border-bottom: ${(props) => (props.selected ? `10px solid ${colors.secondary}` : "none")};
  cursor: pointer;
  &:last-child {
    ${(props) =>
      props.theme.dir === "rtl"
        ? "border-left: 1px solid rgba(255, 255, 255, 0.1);"
        : "border-right: 1px solid rgba(255, 255, 255, 0.1);"}
  }
  &:hover {
    opacity: 0.7;
  }
  .action-text {
    font-weight: 500;
    font-size: 12px;
    line-height: 120%;
    text-align: center;
    letter-spacing: 0.03em;
    color: ${(props) => (props.selected ? "#fff" : "#dadada")};
    margin-top: 10px;
  }
  @media ${device.tablet} {
    width: 25%;
    height: 85%;
    padding: 0 10px;
    flex: 1;
    &:last-child {
      ${(props) => (props.theme.dir === "rtl" ? "border-left: 0;" : "border-right: 0;")}
    }
  }

  @media ${device.mobile} {
    width: 25%;
    height: 85%;
    padding: 0 10px;
    flex: 1;
    &:last-child {
      ${(props) => (props.theme.dir === "rtl" ? "border-left: 0;" : "border-right: 0;")}
    }
  }
`;
