import React, { useState } from "react";
import { isEmpty, map } from "lodash";

import { Collapse } from "@mui/material";
import {
  CategoryButton,
  Listbox,
  OptionTagSliderWrapper,
  OtherSkillsTagsWrapper,
  SearchOtherSkillsWrapper,
} from "./searchOtherSkills.styled";
import { AutocompleteChangeReason, AutocompleteValue, useAutocomplete } from "@mui/material";
import { Skill, SkillLevels } from "&features/assessment/assessment.type";
import { levelMarks } from "&assets/constants/levelMarks";
import SkillOptionTag from "&components/skillOptionTag/skillOptionTag.component";
import Slider from "&components/slider/slider.component";
import { AddIcon, ChevronDown, SearchIcon } from "&assets/constants/icons";
import { useTranslation } from "react-i18next";
import { translationSelector } from "&utils/translationSelector";
import i18n from "i18next";

interface Props {
  skills: Skill[];
  otherSkillsValue: Skill[];
  handleSkillsChange: (skills: Skill[]) => void;
  disabled?: boolean;
}

const SearchOtherSkills = (props: Props) => {
  const { skills, otherSkillsValue, handleSkillsChange, disabled = false } = props;
  const { t } = useTranslation(["assessment"]);

  const dir = i18n.dir();

  const handleChange = (
    event: any,
    options: AutocompleteValue<any, any, any, any>,
    reason: AutocompleteChangeReason
  ) => {
    if (
      (reason === "selectOption" && options?.length > 10) ||
      (reason === "removeOption" && event?.code === "Backspace")
    ) {
      return;
    }

    let changedArray: any = [];
    options?.map((option: any) => {
      changedArray.push({
        ...option,
        value: { level: option?.value?.level || levelMarks[0] },
      });
    });
    handleSkillsChange(changedArray);
  };

  const handleSliderChange = (skill: Skill, levelValue: SkillLevels) => {
    const newSkills: Skill[] = [];
    map(otherSkillsValue, (option: Skill) => {
      if (skill.id === option.id) {
        newSkills.push({
          ...skill,
          value: {
            level: levelValue,
          },
        });
      } else {
        newSkills.push(option);
      }
    });
    handleSkillsChange(newSkills);
  };

  const renderOptionTagSlider = (skill: Skill, index: number) => {
    return (
      <OptionTagSliderWrapper key={skill.id}>
        <SkillOptionTag
          {...getTagProps({ index })}
          id={skill.id}
          key={index}
          title={translationSelector(skill, "name")}
          onDelete={(e: any) => getTagProps({ index }).onDelete(e)}
        />
        <Slider
          className="slider"
          disabled={false}
          marks={levelMarks}
          value={skill.value?.level || levelMarks[0]}
          onChange={(value) => handleSliderChange(skill, value)}
        />
      </OptionTagSliderWrapper>
    );
  };

  const sortedOptions: (itemsArray: Skill[]) => Skill[] = (itemsArray: Skill[]) => {
    const categories: any = {};

    itemsArray?.map((skill) => {
      !isEmpty(categories[skill.skillCategoryId])
        ? (categories[skill.skillCategoryId] = [...categories[skill.skillCategoryId], skill])
        : (categories[skill.skillCategoryId] = [skill]);
    });

    let sortedArray: Skill[] = [];
    Object.keys(categories)?.map((key) => {
      sortedArray = [...sortedArray, ...categories[key]];
    });

    let modifiedArray = sortedArray?.reverse().map((skill: Skill, index) => {
      return {
        ...skill,
        sortingIndex: index,
      };
    });

    return modifiedArray;
  };

  const { getRootProps, getInputProps, getListboxProps, getOptionProps, groupedOptions, getTagProps } = useAutocomplete(
    {
      id: "other-skills-search",
      options: skills || [],
      multiple: true,
      value: otherSkillsValue,
      groupBy: (option) => option.skillCategory?.name,
      getOptionLabel: (option) => option.name,
      onChange: handleChange,
      disableCloseOnSelect: true,
      isOptionEqualToValue: (option: any, value: any) => option?.id === value?.id,
      filterOptions: (options, state) => {
        return sortedOptions(
          options?.filter((option) => option?.name?.toLowerCase().includes(state.inputValue.toLowerCase()))
        );
      },
    }
  );

  const [categoryOpenId, setCategoryOpenId] = useState<any>(undefined);

  const renderCategorySkills = (category: { index: number; key: number; group: string; options: any[] }) => {
    const open = categoryOpenId && categoryOpenId === category.index?.toString();

    return (
      <>
        <CategoryButton
          open={open}
          key={category.index}
          onClick={() => setCategoryOpenId(open ? undefined : category.index?.toString())}
        >
          <span className="button-text">
            {dir === "rtl" && category?.options[0]?.skillCategory
              ? category?.options[0]?.skillCategory?.nameAr
              : category.group}
          </span>
          <ChevronDown />
        </CategoryButton>
        <Collapse className="collapse-container" in={open} timeout="auto" unmountOnExit>
          {(category.options as typeof skills).reverse().map((option, index) => (
            <li
              className="list-dropdown"
              {...getOptionProps({
                option,
                index: option.sortingIndex || index,
              })}
            >
              {translationSelector(option, "name")}
              <div className="icon-wrapper">
                <AddIcon />
              </div>
            </li>
          ))}
        </Collapse>
      </>
    );
  };

  return (
    <SearchOtherSkillsWrapper>
      <div className="input-wrapper" {...getRootProps()}>
        <input disabled={disabled} placeholder={t("ANY_OTHER_SKILLS_ENTER_KEYWORDS")} {...getInputProps()} />
        <SearchIcon />
      </div>
      {groupedOptions.length > 0 ? (
        <Listbox {...getListboxProps()} disabled={otherSkillsValue?.length >= 10}>
          {groupedOptions.map((category: any) => renderCategorySkills(category))}
        </Listbox>
      ) : null}
      <div className="description-text">{t("ADD_UP_TO_10_SKILLS")}</div>
      <div className="spacing-tags-wrapper">
        {groupedOptions.length > 0 && <div className="spacing-wrapper" />}
        <OtherSkillsTagsWrapper>
          {otherSkillsValue.map((option, index) => {
            return renderOptionTagSlider(option, index);
          })}
        </OtherSkillsTagsWrapper>
      </div>
    </SearchOtherSkillsWrapper>
  );
};

export default SearchOtherSkills;
