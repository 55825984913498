import styled from "styled-components";
import { colors } from "&assets/constants/colors";
import { device } from "&assets/constants/responsiveness";

export const CareerMatchingWrapper = styled.div``;

export const PageHeaderWrapper = styled.div`
  box-sizing: border-box;
  background: ${colors.primaryLightGradient};
  display: flex;
  position: relative;
  height: 380px;
  overflow: hidden;
  border-bottom: 4px solid #9bcfec;

  .title-subtitle-wrapper {
    padding: 64px 288px 0 288px;
  }

  .header-lines {
    position: absolute;
    z-index: 0;
    top: 0;
    ${(props) => (props.theme.dir === "rtl" ? "right: 0;" : "left: 0;")}
    ${(props) => (props.theme.dir === "rtl" ? "transform: scaleX(-1);" : "")}
  }

  @media ${device.tablet} {
    .title-subtitle-wrapper {
      padding: 64px 102px 0 102px;
    }
  }

  @media ${device.mobile} {
    height: 355px;
    .title-subtitle-wrapper {
      padding: 40px 38px 0 38px;
    }

    .header-lines {
      width: 265px;
      height: 254px;
    }
  }
  @media ${device.xsmobile} {
    height: 400px;
  }
`;
