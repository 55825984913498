const selectTitleForSlider = (marks: string[], value: any) => {
  let result = '';
  const sectionValue = 100 / marks.length;

  marks?.map((mark, index) => {
    const min = sectionValue * index;
    const max = sectionValue * (index + 1);

    if (value >= min && value <= max) {
      result = mark;
    }
  });
  return result;
};

export default selectTitleForSlider
