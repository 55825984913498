import React from "react";
import {
  AutocompleteChangeReason,
  AutocompleteValue,
  useAutocomplete,
} from "@mui/material";
import {
  AutoCompleteSelectWrapper,
  Listbox,
} from "./autoCompleteSelect.styled";
import { translationSelector } from "&utils/translationSelector";
import { join, map } from "lodash";

interface Props {
  id: string;
  value: any;
  error?: boolean;
  options: any[];
  disabled?: boolean;
  rightIcon?: any;
  placeholder?: string;
  handleChange: (
    event: any,
    options: AutocompleteValue<any, any, any, any>,
    reason: AutocompleteChangeReason
  ) => void;
  className?: string;
  multiple?: boolean;
  title?: string;
}

const AutoCompleteSelect = (props: Props) => {
  const {
    id,
    value,
    options,
    handleChange,
    error,
    title,
    disabled,
    placeholder,
    rightIcon,
    className,
    multiple,
  } = props;
  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
  } = useAutocomplete({
    id: id,
    options: options,
    multiple: multiple,
    value: value,
    disableCloseOnSelect: multiple ? true : false,
    getOptionLabel: (option) =>
      option?.name ? translationSelector(option, "name") : "",
    onChange: handleChange,
    isOptionEqualToValue: (option: any, value: any) => option.id === value.id,
  });

  const inputValue = join(
    map(value, (v: any) => translationSelector(v, "name")),
    ", "
  );

  return (
    <AutoCompleteSelectWrapper
      isOpen={groupedOptions.length > 0}
      error={error}
      listBoxId={`${id}-listbox`}
      className={className}
      disabled={disabled}
    >
      <div className="input-wrapper" {...getRootProps()}>
        {multiple ? (
          <input
            disabled={disabled}
            placeholder={placeholder}
            title={title}
            {...getInputProps()}
            value={inputValue}
          />
        ) : (
          <input
            disabled={disabled}
            placeholder={placeholder}
            title={title}
            {...getInputProps()}
          />
        )}
        <div className="right-icon" {...getInputProps()}>
          {!disabled && rightIcon}
        </div>
      </div>
      {groupedOptions.length > 0 ? (
        <Listbox {...getListboxProps()} disabled={value?.length >= 10}>
          {(groupedOptions as typeof options).map((option, index) => (
            <li
              {...getOptionProps({ option, index })}
              hidden={option.value === ""}
            >
              {
                <span className="option-name">
                  {translationSelector(option, "name")}
                </span>
              }
            </li>
          ))}
        </Listbox>
      ) : null}
    </AutoCompleteSelectWrapper>
  );
};

export default AutoCompleteSelect;
