import { colors } from "&assets/constants/colors";
import styled from "styled-components";

interface Props {
  hide?: boolean;
  hasError?: boolean;
}
export const CheckboxWrapper = styled.div<Props>`
  display: flex;
  justify-content: flex-start;
  margin-top: 16px;

  opacity: ${(props) => (props.hide ? "0" : "1")};
  visibility: ${(props) => (props.hide ? "hidden" : "visible")};

  .checkbox-title {
    ${(props) => (props.theme.dir === "rtl" ? "text-align: right;" : "")}
  }
  .checkbox {
    border: 1px solid ${(props) => (props.hasError ? colors.errorColor : colors.primary)};
    min-width: 24px;
    max-width: 24px;
    height: 24px;
    border-radius: 8px;
    background-color: ${colors.white};
    ${(props) => (props.theme.dir === "rtl" ? "margin-left: 8px;" : "margin-right: 8px;")}
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    cursor: pointer;
    .check-dot {
      width: 4px;
      padding: 0;
      height: 4px;
      background: ${colors.linearGradientThree};
      border-radius: 50px;
    }
  }
`;
