import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useTranslation } from "react-i18next";

import { RootState } from "&store/store";

import { termsConditionsActions } from "./termsConditions.slice";
import { TermsConditionsWrapper } from "./termsConditions.styled";
import PageHeader from "&components/pageHeader/pageHeader.component";
import Container from "&components/container/container.component";
import CircularLoader from "&components/circularLoader/circularLoader.component";
import HtmlParser from "&components/htmlParser/htmlParser.component";
import { translationSelector } from "&utils/translationSelector";

type ReduxProps = ConnectedProps<typeof connector>;

const TermsConditionsComponent = (props: ReduxProps) => {
  const { t } = useTranslation(["termsConditions"]);

  const { getTermsConditions, termsConditions, loadingContent } = props;

  useEffect(() => {
    getTermsConditions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadingComponent = (
    <div className="loader-wrapper">
      <CircularLoader />
    </div>
  );

  return (
    <TermsConditionsWrapper>
      <PageHeader
        title={t("TERMS_AND_CONDITIONS")}
        subTitle={translationSelector(termsConditions, "subTitle") || ""}
        className="header"
        titleClassName="header-title"
        titleSubtitleWrapper="title-subtitle-wrap"
      />
      <Container className="content-container">
        {loadingContent ? (
          loadingComponent
        ) : (
          <HtmlParser
            description={translationSelector(termsConditions, "description")}
          />
        )}
      </Container>
    </TermsConditionsWrapper>
  );
};

const mapStateToProps = (state: RootState) => ({
  termsConditions: state.termsConditions.content,
  loadingContent: state.termsConditions.loadingContent,
});

const mapDispatchToProps = {
  getTermsConditions: termsConditionsActions.getPageContent,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const TermsConditionsComponentRedux = connector(TermsConditionsComponent);

export { TermsConditionsComponentRedux as TermsConditionsComponent };
