import styled from "styled-components";
import { device } from "&assets/constants/responsiveness";

export const DashboardBannerWrapper = styled.div`
    width: 100%;

  .carousel-banner-wrapper {
    direction: initial;
    width: 100%;

    .carousel * {
      box-sizing: initial;
    }

    .arrows {
      ${(props) =>
        props.theme.dir === "rtl" ? "transform: rotate(180deg);" : ""}
      position: absolute;
      z-index: 2;
      top: calc(50% - 15px);
      width: 36px;
      height: 36px;
      cursor: pointer;
    }

    .next {
      ${(props) =>
        props.theme.dir === "rtl" ? "left: -18px;" : "right: -18px;"}
    }
    .prev {
      ${(props) =>
        props.theme.dir === "rtl" ? "right: -12px;" : "left: -12px;"}
    }
    @media ${device.inBetween} {
      margin: 0 20px;
    }
    @media ${device.mobile}, ${device.tablet} {
      width: 95vw;
    }
  }
`;
