import React from "react";
import { TitleArrowButtonWrapper } from "./titleArrowButton.styled";
import { ArrowForwardIcon } from "&assets/constants/icons";
import { colors } from "&assets/constants/colors";

interface Props {
  id: string;
  title?: string;
  isWhite?: boolean;
  onClick?: () => void;
}

const TitleArrowButton = (props: Props) => {
  const { id, title, isWhite, onClick } = props;
  return (
    <TitleArrowButtonWrapper isWhite={isWhite} onClick={() => onClick && onClick()} id={id} className="view-all">
      {title} {ArrowForwardIcon(colors.white)}
    </TitleArrowButtonWrapper>
  );
};

export default TitleArrowButton;
