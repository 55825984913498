export const profileNameSpace = {
  en: {
    CHOOSE_YOUR_CAREER: "Choose Your Career",
    FOLLOW_OUR_STEPS_TO_GET_STARTED: "Follow our steps to get started",
    BY_FOLLOWING_OUR_STEPS_YOU_WILL_EASILY: "By following our steps you will easily get started in nammiskills",
    ELIGIBILITY_FORM_ALREADY_SUBMITTED: "Eligibility form already submitted",
    CREATE_ACCOUNT: "Create Account",
    CONTINUE: "Continue",
    VIEW_BUNDLES: "View Bundles",
    COMPLETE_LATER: "Complete Later",
    YOU_SHOULD_BE_15_YEARS_OR_OLDER: "You should be 15 years or older",
    PLEASE_PROVIDE_IF_YOU_HAVE_ANY_EDUCATIONAL_BACKGROUND: "Please provide if you have any educational background",
    PHONE_NUMBER_IS_NOT_VALID: "Phone number is not valid",
    REQUIRED: "Required",
    PLEASE_PROVIDE_IF_YOU_HAVE_ANY_WORK_EXPERIENCE: "Please provide if you have any work experience",
    START_DATE_MUST_BE_LESS_THAN_GRADUATION_DATE: "Start date must be less than graduation date",
    GRADUATION_YEAR_CAN_NOT_BE_IN_THE_FUTURE: "Graduation year can not be in the future",
    GRADUATION_YEAR_MUST_BE_GREATER_THAN_START_DATE: "Graduation year must be greater than Start date",
    START_DATE_MUST_BE_LESS_THAN_END_DATE: "Start date must be less than end date",
    END_DATE_CAN_NOT_BE_IN_THE_FUTURE: "End date can not be in the future",
    END_DATE_CAN_NOT_BE_GREATER_THAN_START_DATE: "End date must be greater than Start date",
    INVALID_REQUIRED_DATE: "Invalid/Required date",
    DATE_OF_BIRTH: "Date of Birth",
    ADD_YOUR_DATE_OF_BIRTH: "Add your date of birth",
    DATE_OF_BIRTH_CAN_NOT_BE_IN_THE_FUTURE: "Date of Birth can not be in the future",
    SELECT_FROM_CALENDAR: "Select from the calendar",
    PHONE_NUMBER: "Phone Number",
    GENDER: "Gender",
    ADD_YOUR_GENDER: "Add your gender",
    EDUCATION_DEGREE: "Education Degree",
    SELECT_FROM_THE_LIST: "Select from the list",
    FIELD_OF_STUDY: "Field of Study",
    TYPE_OR_SELECT_FROM_THE_LIST: "Type or select from the list",
    SCHOOL_UNIVERSITY_NAME: "School/University Name",
    TYPE_OR_SELECT_YOUR_SCHOOL_OR_UNIVERSITY: "Select your school or university",
    SPECIFY_SCHOOL_UNIVERSITY_NAME: "Specify your school/university name",
    TYPE_YOUR_SCHOOL_OR_UNIVERSITY_NAME: "Type your school or university name",
    START_DATE: "Start Date",
    GRADUATION_YEAR: "Graduation Year",
    IM_CURRENTLY_ATTENDING_THIS_SCHOOL: "I’m currently attending this school",
    DESCRIPTION: "Description",
    DESCRIBE_YOUR_EDUCATION: "Describe your education",
    JOB_TITLE: "Job Title",
    EX_SENIOR_GRAPHIC_DESIGNER: "EX. Senior Graphic Designer",
    COMPANY_NAME: "Company Name",
    TYPE_YOUR_COMPANY_NAME: "Type your company name",
    LOCATION: "Location",
    TYPE_YOUR_COMPANY_LOCATION: "Type your company location",
    EMPLOYMENT_TYPE: "Employment type",
    END_DATE: "End Date",
    CURRENT_CAREER: "Current Career",
    YOUR_DREAM_CAREER: "Your Dream Career",
    IM_CURRENTLY_WORKING_HERE: "I’m currently working here",
    DESCRIBE_YOUR_JOB_POSITION: "Describe your job position",
    ADD_ANOTHER_EXPERIENCE: "Add Another Experience",
    NATIONALITY_MUST_BE_DIFFERENT_THAN_RESIDENCE_COUNTRY: "Nationality must be different than residence country",
    RESPONSIBILITY_DISCLAIMER_MUST_BE_CHECKED: "Responsibility disclaimer must be checked",
    RESIDENCE_COUNTRY: "Residence Country",
    KAZA: "District (caza)",
    CITY: "City",
    NATIONALITY: "Nationality",
    INCOME_RANGE: "Income Range",
    IM_RESPONSIBLE_FROM_THE_INFORMATION_MENTIONED_ABOVE: "I'm responsible for the information mentioned above",
    APPLY_FOR_SPONSORSHIP: "Apply for sponsorship",
    HOW_DID_YOU_HEAR: "How did you hear about us",
    APPLY_FOR_VOUCHER: "Apply for voucher",
    TYPE_OF_RESIDENCE: "Type of Residence",
    DO_YOU_HAVE_ANY_DISABILITIES: "Do you have any disabilities",
    PLEASE_SPECIFY: "Please specify",
    UPLOAD_ID: "Upload Id",
    DRAG_AND_DROP_YOUR_ID_OR_PASSPORT_DOCUMENT_HERE: "Drag and drop your ID or passport document here",
    YOUR_DOCUMENT_HAS_BEEN_UPLOADED_SUCCESSFULLY: "Your document has been uploaded successfully",
    UPLOAD_A_DOCUMENT: "Upload a document",
    COMPLETE_YOUR_PROFILE: "Complete your profile",
    COMPLETE_YOUR_PROFILE_TO_BENEFIT_FROM_MORE_ACCURATE:
      "Complete your profile to benefit from more accurate learning recommendations and enhance your chances of receiving vouchers for your next exam.",
    ABOUT_ME: "About Me",
    EXPERIENCE: "Experience",
    FULL_NAME: "Full Name",
    FIRST_NAME: "First Name",
    LAST_NAME: "Last Name",
    ENTER_FULL_NAME: "Enter your full name",
    ENTER_FIRST_NAME: "Enter first name",
    ENTER_LAST_NAME: "Enter last name",
    ADD_EXPERIENCE: "Add Experience",
    ADD: "Add",
    EDUCATION: "Education",
    ADD_EDUCATION: "Add Education",
    SKILLS: "Skills",
    CERTIFICATIONS: "Certifications",
    ADD_CERTIFICATION: "Add Certification",
    INTERESTS_AND_HOBBIES: "Interests and Hobbies",
    SPONSORSHIP_ELIGIBILITY_APPLICATION: "Sponsorship Eligibility Application",
    PLEASE_FILL_IN_YOUR_DATE_OF_BIRTH_AND_GENDER:
      "Please fill in your date of birth and gender to apply for sponsorship.",
    PERSONAL_DETAILS: "Personal Details",
    YOUR_SELECTED_CAREER: "Your Selected Career",
    CHANGE_CAREER: "Change Career",
    ADDITIONAL_DETAILS: "Additional Details",
    SOCIAL_LINKS: "Social Links",
    CV: "CV",
    PROFILE_PICTURE: "Profile Picture",
    ELIGIBILITY_FORM: "Eligibility Form",
    CERTIFICATION: "Certification",
    PICTURE: "Picture",
    DELETE: "Delete",
    SAVE: "Save",
    ARE_YOU_SURE_YOU_WANT_TO_DELETE: "Are you sure you want to delete?",
    UPLOAD_PROFILE_PICTURE: "Upload Profile Picture",
    DRAG_AND_DROP_YOUR_PROFILE_PICTURE_HERE: "Drag and drop your profile picture here",
    PLEASE_PROVIDE_A_VALID_URL: "Please provide a valid url EX.",
    LINKEDIN: "linkedin",
    LINKEDIN_URL: "Linkedin URL",
    TYPE_YOUR_LINKEDIN_URL: "Type your LinkedIn url",
    ADD_YOUR_LINKEDIN_PROFILE: "Add your Linkedin profile",
    PORTFOLIO: "portfolio",
    PORTFOLIO_URL: "Portfolio URL",
    TYPE_YOUR_PORTFOLIO_URL: "Type your portfolio url",
    ADD_YOUR_PORTFOLIO: "Add your portfolio",
    WEBSITE: "website",
    WEBSITE_URL: "Website URL",
    TYPE_YOUR_WEBSITE_URL: "Type your website url",
    ADD_YOUR_WEBSITE: "Add your website",
    EMAIL: "Email",
    ADD_YOUR_EMAIL: "Add your email",
    PHONE: "Phone",
    ADD_YOUR_PHONE_NUMBER: "Add your phone number",
    LANGUAGES: "Languages",
    ADD_YOUR_LANGUAGES: "Add your languages",
    TYPE_YOUR_LANGUAGES: "Select your languages",
    TYPE_AN_INTEREST_OR_HOBBY: "Type an Interest or Hobby",
    ISSUE_DATE_CAN_NOT_BE_IN_THE_FUTURE: "Issue date can not be in the future",
    ISSUE_DATE_MUST_BE_LESS_THAN_EXPIRY_DATE: "Issue date must be less than expiry date",
    EXPIRY_DATE_MUST_GREATER_THAN_ISSUE_DATE: "Expiry date must be greater than Issue date",
    INVALID_DATE: "Invalid date",
    CERTIFICATE_NAME: "Certificate Name",
    TYPE_YOUR_CERTIFICATE_NAME: "Type your certificate name",
    INSTITUTE_NAME: "Institute Name",
    TYPE_THE_INSTITUTE_NAME: "Type the institute name",
    ISSUE_YEAR: "Issue Year",
    EXPIRY_YEAR: "Expiry Year",
    DOWNLOAD_CV: "Download CV",
    REFERRALS: "Referrals",
    PERSONAL_INFORMATION: "Personal Information",
    LATEST_EDUCATION: "Latest Education",
    LATEST_WORK_EXPERIENCE: "Latest Work Experience",
    DESCRIBE_YOURSELF: "Describe yourself",
    male: "male",
    female: "female",
    PRESENT: "present",
    PLEASE_NOTE_STATEMENT:
      "The name you enter here will appear on any certificate you will receive on nammiskills. Therefore, it's crucial to make sure that you enter the correct professional name you use.",
    YES: "Yes",
    NO: "No",
    ADD_YOUR_NATIONALITY: "Add your nationality",
    DISABILITY: "Disability",
    ADD_YOUR_DISABILITY: "Add your disability",
    DISABILITY_DESCRIPTION: "Disability Description",
    ADD_YOUR_DISABILITY_DESCRIPTION: "Add your disability description",
    SELECT_NATIONALITY: "Select the nationality that matches your ID",
    CONTINUE_TO_SIGNUP: "Continue to Sign up",
  },
  ar: {
    CHOOSE_YOUR_CAREER: "اختر مهنتك",
    FOLLOW_OUR_STEPS_TO_GET_STARTED: "اتبع خطواتنا للبدء",
    BY_FOLLOWING_OUR_STEPS_YOU_WILL_EASILY: 'باتباع خطواتنا سوف تبدأ بسهولة مع منصة "نمّي سكيلز"',
    ELIGIBILITY_FORM_ALREADY_SUBMITTED: "استمارة الأهلية تم إرسالها مسبقًا",
    CREATE_ACCOUNT: "إنشاء حساب",
    CONTINUE: "اكمل",
    REFERRALS: "الإحالات",
    COMPLETE_LATER: "أكمل لاحقًا",
    YOU_SHOULD_BE_15_YEARS_OR_OLDER: "يجب أن تكون بعمر 15 سنة أو أكثر",
    PLEASE_PROVIDE_IF_YOU_HAVE_ANY_EDUCATIONAL_BACKGROUND: "الرجاء ذكر ما إذا كان لديك أي خلفية تعليمية",
    PHONE_NUMBER_IS_NOT_VALID: "رقم الهاتف غير متوفر",
    REQUIRED: "مطلوب",
    HOW_DID_YOU_HEAR: "كيف سمعت عنا؟",
    PLEASE_PROVIDE_IF_YOU_HAVE_ANY_WORK_EXPERIENCE: "يرجى ذكر ما إذا كان لديك أي خبرة عملية",
    START_DATE_MUST_BE_LESS_THAN_GRADUATION_DATE: "يجب أن يكون تاريخ البدء قبل تاريخ التخرج",
    GRADUATION_YEAR_CAN_NOT_BE_IN_THE_FUTURE: "لا يمكن أن تكون سنة التخرج في المستقبل",
    GRADUATION_YEAR_MUST_BE_GREATER_THAN_START_DATE: "يجب أن تكون سنة التخرج بعد تاريخ البدء",
    START_DATE_MUST_BE_LESS_THAN_END_DATE: "يجب أن يكون تاريخ البدء قبل تاريخ التخرج",
    END_DATE_CAN_NOT_BE_IN_THE_FUTURE: "لا يمكن أن يكون تاريخ الانتهاء في المستقبل",
    END_DATE_CAN_NOT_BE_GREATER_THAN_START_DATE: "يجب أن يكون تاريخ الانتهاء بعد تاريخ البدء",
    INVALID_REQUIRED_DATE: "التاريخ غير صحيح / مطلوب",
    DATE_OF_BIRTH: "تاريخ الولادة",
    ADD_YOUR_DATE_OF_BIRTH: "أضف تاريخ ميلادك",
    VIEW_BUNDLES: "تعرّف على الباقات",
    DATE_OF_BIRTH_CAN_NOT_BE_IN_THE_FUTURE: "تاريخ الولادة لا يمكن أن يكون في المستقبل",
    SELECT_FROM_CALENDAR: "اختر من التقويم",
    PHONE_NUMBER: "رقم الهاتف",
    GENDER: "الجنس",
    ADD_YOUR_GENDER: "أضف جنسك",
    EDUCATION_DEGREE: "الدرجة العلمية",
    SELECT_FROM_THE_LIST: "اختر من القائمة",
    FIELD_OF_STUDY: "مجال الدراسة",
    TYPE_OR_SELECT_FROM_THE_LIST: "اكتب أو اختر من القائمة",
    SCHOOL_UNIVERSITY_NAME: "اسم المعهد / الجامعة",
    TYPE_OR_SELECT_YOUR_SCHOOL_OR_UNIVERSITY: "اكتب أو اختر المعهد أو الجامعة",
    SPECIFY_SCHOOL_UNIVERSITY_NAME: "اسم المعهد / الجامعة",
    TYPE_YOUR_SCHOOL_OR_UNIVERSITY_NAME: "اكتب اسم مدرستك أو جامعتك",
    START_DATE: "تاريخ البدء",
    GRADUATION_YEAR: "سنة التخرّج",
    IM_CURRENTLY_ATTENDING_THIS_SCHOOL: "أنا حاليًا مُلتحق في هذه الجامعة",
    DESCRIPTION: "وصف",
    DESCRIBE_YOUR_EDUCATION: "صف تعليمك",
    JOB_TITLE: "المسمى الوظيفي",
    EX_SENIOR_GRAPHIC_DESIGNER: "مصمم غرافيك محترف سابق",
    COMPANY_NAME: "اسم الشركة",
    TYPE_YOUR_COMPANY_NAME: "اكتب اسم الشركة",
    LOCATION: "موقع",
    TYPE_YOUR_COMPANY_LOCATION: "اكتب موقع شركتك",
    EMPLOYMENT_TYPE: "نوع الوظيفة",
    END_DATE: "تاريخ نهاية العمل",
    CURRENT_CAREER: "المهنة الحالية",
    YOUR_DREAM_CAREER: "مهنة أحلامك",
    IM_CURRENTLY_WORKING_HERE: "أنا حاليًا أعمل هنا",
    DESCRIBE_YOUR_JOB_POSITION: "صف وظيفتك",
    ADD_ANOTHER_EXPERIENCE: "أضف خبرة أخرى",
    NATIONALITY_MUST_BE_DIFFERENT_THAN_RESIDENCE_COUNTRY: "يجب أن تكون الجنسية مختلفة عن بلد الإقامة",
    RESPONSIBILITY_DISCLAIMER_MUST_BE_CHECKED: "يجب التحقق من رفع المسؤولية",
    RESIDENCE_COUNTRY: "دولة الإقامة",
    KAZA: "القضاء",
    CITY: "المدينة",
    NATIONALITY: "الجنسية",
    INCOME_RANGE: "نطاق الدخل",
    IM_RESPONSIBLE_FROM_THE_INFORMATION_MENTIONED_ABOVE: "أتحمل مسؤولية المعلومات المذكورة أعلاه",
    APPLY_FOR_SPONSORSHIP: "تقدّم بطلب رعاية",
    TYPE_OF_RESIDENCE: "نوع الإقامة",
    DO_YOU_HAVE_ANY_DISABILITIES: "هل لديك أي إعاقة",
    PLEASE_SPECIFY: "حدد من فضلك",
    UPLOAD_ID: "تحميل الهوية",
    DRAG_AND_DROP_YOUR_ID_OR_PASSPORT_DOCUMENT_HERE: "قم بسحب وإسقاط بطاقة الهوية أو وثيقة جواز السفر هنا",
    YOUR_DOCUMENT_HAS_BEEN_UPLOADED_SUCCESSFULLY: "تم تحميل وثيقتك بنجاح",
    UPLOAD_A_DOCUMENT: "تحميل وثيقة",
    COMPLETE_YOUR_PROFILE: "أكمل ملفك الشخصي",
    COMPLETE_YOUR_PROFILE_TO_BENEFIT_FROM_MORE_ACCURATE:
      "أكمل ملفك الشخصي للاستفادة من توصيات تعليمية أكثر دقة ولتعزيز فرصك في الحصول على قسائم لامتحانك التالي.",
    ABOUT_ME: "معلومات عني",
    EXPERIENCE: "الخبرة",
    ADD_EXPERIENCE: "إضافة خبرة",
    FULL_NAME: "الاسم الكامل",
    FIRST_NAME: "الاسم الأول",
    LAST_NAME: "اسم العائلة",
    ENTER_FULL_NAME: "أدخل اسمك الكامل",
    ENTER_FIRST_NAME: "أدخل الاسم الأول",
    ENTER_LAST_NAME: "أدخل اسم العائلة",
    ADD: "اضف",
    EDUCATION: "تعليم",
    ADD_EDUCATION: "إضافة خبرة تعليمية",
    SKILLS: "المهارات",
    CERTIFICATIONS: "الشهادات",
    ADD_CERTIFICATION: "إضافة شهادة",
    INTERESTS_AND_HOBBIES: "الاهتمامات والهوايات",
    SPONSORSHIP_ELIGIBILITY_APPLICATION: "طلب الرعاية للتأكد من المؤهلات",
    PLEASE_FILL_IN_YOUR_DATE_OF_BIRTH_AND_GENDER: "يرجى ملء تاريخ ميلادك وجنسك لتقديم طلب للحصول على الرعاية.",
    PERSONAL_DETAILS: "المعلومات الشخصية ",
    YOUR_SELECTED_CAREER: "المهنة التي اخترتها",
    CHANGE_CAREER: "تغيير مجال العمل",
    ADDITIONAL_DETAILS: "تفاصيل إضافية",
    SOCIAL_LINKS: "الروابط الاجتماعية",
    CV: "السيرة الذاتية",
    PROFILE_PICTURE: "صورة ملفك الشخصي",
    ELIGIBILITY_FORM: "الاستمارة الأهلية",
    CERTIFICATION: "الشهادات",
    PICTURE: "صورة",
    DELETE: "حذف",
    SAVE: "حفظ",
    ARE_YOU_SURE_YOU_WANT_TO_DELETE: "هل أنت متأكد من أنك تريد الحذف؟",
    UPLOAD_PROFILE_PICTURE: "تحميل صورة ملفك الشخصي",
    DRAG_AND_DROP_YOUR_PROFILE_PICTURE_HERE: "قم بسحب وإسقاط صورة ملفك الشخصي هنا",
    PLEASE_PROVIDE_A_VALID_URL: "يرجى تقديم عنوان إلكتروني صالح EX.",
    LINKEDIN: "لينكد إن",
    LINKEDIN_URL: "رابط لينكد إن",
    TYPE_YOUR_LINKEDIN_URL: "اكتب رابط لينكد إن الخاص بك",
    ADD_YOUR_LINKEDIN_PROFILE: "أضف ملفك الشخصي على لينكد إن",
    PORTFOLIO: "المحفظة",
    PORTFOLIO_URL: "الرابط الإلكتروني للمحفظة",
    TYPE_YOUR_PORTFOLIO_URL: "اكتب الرابط الإلكتروني لمحفظتك",
    ADD_YOUR_PORTFOLIO: "أضف محفظتك",
    WEBSITE: "الموقع الإلكتروني",
    WEBSITE_URL: "رابط الموقع الإلكتروني",
    TYPE_YOUR_WEBSITE_URL: "اكتب رابط موقعك الإلكتروني",
    ADD_YOUR_WEBSITE: "أضف موقعك الإلكتروني",
    EMAIL: "البريد الإلكتروني",
    ADD_YOUR_EMAIL: "أضف بريدك الإلكتروني",
    PHONE: "الهاتف",
    ADD_YOUR_PHONE_NUMBER: "أضف رقمك الهاتفي",
    LANGUAGES: "اللغات",
    ADD_YOUR_LANGUAGES: "أضف لغاتك",
    TYPE_YOUR_LANGUAGES: "اختر لغاتك",
    TYPE_AN_INTEREST_OR_HOBBY: "اكتب اهتمامًا أو هواية",
    ISSUE_DATE_CAN_NOT_BE_IN_THE_FUTURE: "لا يمكن أن يكون تاريخ الإصدار في المستقبل",
    ISSUE_DATE_MUST_BE_LESS_THAN_EXPIRY_DATE: "يجب أن يكون تاريخ الإصدار قبل تاريخ انتهاء الصلاحية",
    EXPIRY_DATE_MUST_GREATER_THAN_ISSUE_DATE: "يجب أن يكون تاريخ انتهاء الصلاحية بعد تاريخ الإصدار",
    INVALID_DATE: "تاريخ غير صالح",
    CERTIFICATE_NAME: "اسم الشهادة",
    TYPE_YOUR_CERTIFICATE_NAME: "اكتب اسم شهادتك",
    INSTITUTE_NAME: "اسم المؤسسة",
    TYPE_THE_INSTITUTE_NAME: "اكتب اسم المؤسسة",
    ISSUE_YEAR: "سنة الإصدار",
    EXPIRY_YEAR: "سنة انتهاء الصلاحية",
    DOWNLOAD_CV: "قم بتنزيل السيرة الذاتية",
    PERSONAL_INFORMATION: "المعلومات الشخصية",
    LATEST_EDUCATION: "أحدث شهادة علمية",
    LATEST_WORK_EXPERIENCE: "أحدث خبرة عمل",
    DESCRIBE_YOURSELF: "صف نفسك",
    male: "ذكر",
    female: "اتثى",
    APPLY_FOR_VOUCHER: "اطلب القسيمة",
    PRESENT: "حالياً",
    PLEASE_NOTE_STATEMENT:
      "ان الاسم الذي تدخله هنا سوف يظهر على أي شهادة سوف تحصل عليها على منصة نمّي سكيلز.تأكد أنك تُدخل الاسم المهني الصحيح الذي تستخدمه",
    YES: "نعم",
    NO: "لا",
    ADD_YOUR_NATIONALITY: "أضف جنسيتك",
    DISABILITY: "عجز",
    ADD_YOUR_DISABILITY: "أضف إعاقتك",
    DISABILITY_DESCRIPTION: "وصف الإعاقة",
    ADD_YOUR_DISABILITY_DESCRIPTION: "أضف وصف الإعاقة الخاصة بك",
    SELECT_NATIONALITY: "اختر الجنسية الموجودة على بطاقة الهوية",
    CONTINUE_TO_SIGNUP: "متابعة التسجيل",
  },
};
