
import styled from "styled-components";
import {colors} from "&assets/constants/colors";

export const LevelTagWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  height: 27px;
  width: fit-content;
  padding: 0 10px;
  background-color: ${colors.white};
  border-radius: 0 0 6px 6px;
  font-weight: 800;
  font-size: 12px;
  line-height: 160%;
  letter-spacing: 0.05em;
  color: ${colors.primary};
  text-transform: uppercase;
  `;

