import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { ExamDetails } from "./examDetails.type";
import { getExamByIdApi, getExamsLearningPathApi } from "./examDetails.api";
import { map } from "lodash";
import { LearningPathwayType } from "&features/listingPage/listingPage.type";

const initialState: ExamDetails = {
  loading: false,
  selectedExam: undefined,
  learningPath: [],
  status: undefined,
};

const getExamById = createAsyncThunk(
  "examDetails/getExamById",
  async (
    body: { id: string; learningPathId?: any },
    { rejectWithValue, getState, dispatch }: any
  ) => {
    try {
      const isLoggedIn = getState().login.isLoggedIn;
      const exam = await getExamByIdApi(
        body.id,
        isLoggedIn,
        body.learningPathId
      );
      return exam?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const getExamsLearningPath = createAsyncThunk(
  "examDetails/getExamsLearningPath",
  async (body: { examId: string }, { rejectWithValue }: any) => {
    try {
      const exam = await getExamsLearningPathApi(body.examId);
      let learningPath: LearningPathwayType[] = [];
      map(exam.data, (oneLearningPath) => {
        learningPath.push(oneLearningPath.learningPaths);
      });
      return learningPath;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const examDetailsSlice = createSlice({
  name: "examDetails",
  initialState: initialState,
  reducers: {
    setExamDetails: (state, action) => {
      return { ...state, ...action.payload };
    },
    reset: () => initialState,
  },

  extraReducers: (builder) => {
    builder.addCase(getExamById.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getExamById.fulfilled, (state, action) => {
      state.loading = false;
      state.selectedExam = action.payload;
    });
    builder.addCase(getExamById.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(getExamsLearningPath.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getExamsLearningPath.fulfilled, (state, action) => {
      state.loading = false;
      state.learningPath = action.payload;
    });
    builder.addCase(getExamsLearningPath.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const examDetailsReducer = examDetailsSlice.reducer;

export const examDetailsActions = {
  ...examDetailsSlice.actions,
  getExamById,
  getExamsLearningPath,
};
