import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";

import { SocialLinks } from "&features/dashboard/screens/profile/profile.type";
import { UpdateProfileBody } from "&features/login/login.type";
import { ProfileModalInnerContent } from "&components/profile/edit/profileEditModel/profileEditModal.styled";
import DragDropUploadFile from "&components/inputs/dragDropUploadFile/dragDropUploadFile.component";
import uploadImageToS3 from "&utils/uploadImageToS3";
import { RootState } from "&store/store";
import { loginActions } from "&features/login/login.slice";
import { useTranslation } from "react-i18next";

interface Props {
  initialValues?: SocialLinks;
  submit?: number;
  deleteButton?: number;
  updateProfile: (body: UpdateProfileBody) => void;
}

const ProfileAddProfilePicture = (props: Props) => {
  const {
    // initialValues,

    submit,
    updateProfile,
    deleteButton,
  } = props;

  const user = useSelector((state: RootState) => state.login.user);
  const { t } = useTranslation(["profile"]);

  const values = {
    profilePic: undefined,
    profilePicName: undefined,
  };
  const validationSchema = Yup.object().shape({
    profilePicName: Yup.string().required("Profile Pic Required"),
  });

  const dispatch = useDispatch();

  const handleSubmit = async () => {
    dispatch(loginActions.setLogin({ loadingUpdateProfile: true }));
    const imageKey = await uploadImageToS3({
      value: formik?.values?.profilePic,
      userId: user?.auth0Id,
      compress: true,
    });

    updateProfile({
      image: imageKey,
    });
  };

  const handleDelete = () => {
    updateProfile({
      image: "",
    });
  };

  const formik: any = useFormik({
    initialValues: values,
    validationSchema: validationSchema,
    onSubmit: () => handleSubmit(),
  });

  useEffect(() => {
    submit && submit > 0 && formik.handleSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submit]);

  useEffect(() => {
    deleteButton && deleteButton > 0 && handleDelete();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteButton]);

  return (
    <ProfileModalInnerContent>
      <DragDropUploadFile
        showImage={true}
        label={t("UPLOAD_PROFILE_PICTURE")}
        description={t("DRAG_AND_DROP_YOUR_PROFILE_PICTURE_HERE")}
        value={formik.values?.profilePic}
        initialValue={user?.image}
        handleChange={(file) => {
          formik.setFieldValue(`profilePic`, file);
          formik.setFieldValue(`profilePicName`, file.name);
        }}
        removePhoto={() => {
          formik.setFieldValue(`profilePic`, undefined);
          formik.setFieldValue(`profilePicName`, undefined);
        }}
        error={
          formik?.touched?.profilePicName && formik?.errors?.profilePicName
        }
      />
    </ProfileModalInnerContent>
  );
};

export default ProfileAddProfilePicture;
