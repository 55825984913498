import styled from "styled-components";
import { colors } from "&assets/constants/colors";
import { device } from "&assets/constants/responsiveness";

interface Props {
  listBoxId: string;
  error?: boolean;
  isOpen?: boolean;
  disabled?: boolean;
}

export const AutoCompleteSelectWrapper = styled.div<Props>`
  position: relative;

  .right-icon {
    cursor: pointer;
    ${(props) => (props.isOpen ? "transform: rotate(180deg);" : "")};
    transition: all 0.1s ease-in-out;
  }

  .input-wrapper {
    min-width: 423px;
    box-sizing: border-box;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 16px;
    border-radius: 12px;
    border: 1px solid transparent;

    background: ${colors.linearGradientWhite} padding-box,
      ${(props) => (props.error ? colors.errorColor : props.disabled ? colors.dark : colors.linearGradientOne)}
        border-box;

    opacity: ${(props) => (props.disabled ? 0.3 : 1)};
    &[aria-owns=${(props) => props.listBoxId}] {
      border-radius: 12px 12px 0 0;
      background: ${colors.linearGradientWhite} padding-box, ${colors.linearGradientOne} border-box;
    }

    @media ${device.tablet}, ${device.mobile} {
      width: 100px;
      height: 46px;
      min-width: 100%;
    }
  }

  input {
    width: 100%;
    border: 0;
    background: none;
    font-size: 16px;

    &::placeholder {
      font-weight: 500;
      font-size: 16px;
      line-height: 160%;
      color: ${colors.dark};
      opacity: ${(props) => (props.disabled ? 1 : 0.7)};
    }

    &:focus {
      border: 1px solid transparent;
      outline: inherit;
    }

    @media ${device.tablet}, ${device.mobile} {
      &::placeholder {
        font-weight: 400;
        font-size: 14px;
        line-height: 160%;
      }
    }
  }
`;
interface ListboxProps {
  disabled: boolean;
}
export const Listbox = styled.ul<ListboxProps | any>`
  width: 100%;
  padding: 0;
  position: absolute;
  list-style: none;
  z-index: 3;
  box-sizing: border-box;
  border-radius: 0 0 10px 10px;
  overflow-y: auto;
  margin-top: 0;
  overflow-x: hidden;
  max-height: 200px;
  border: 1px solid transparent;
  background: ${colors.linearGradientWhite} padding-box, ${colors.linearGradientOne} border-box;

  @media ${device.mobile} {
    width: 100%;
  }

  & li {
    .option-name {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-weight: 500;
      font-size: 14px;
      line-height: 160%;
    }

    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    align-items: center;
    height: 30px;
    padding: 0 10px;

    .icon-wrapper {
      visibility: hidden;
      display: flex;
      align-items: center;
    }

    &:first-child {
      padding-top: 10px;
    }

    &:last-child {
      padding-bottom: 10px;
    }

    &.Mui-focused {
      background: rgba(16, 140, 210, 0.1) !important;
      cursor: ${(props) => (props.disabled ? "not-allowed" : "")};

      .icon-wrapper {
        visibility: ${(props) => (props.disabled ? "hidden" : "initial")};
      }
    }

    &:active {
      background: rgba(16, 140, 210, 0.1);
      cursor: ${(props) => (props.disabled ? "not-allowed" : "")};
    }
  }

  & li[aria-selected="true"] {
    background: rgba(16, 140, 210, 0.2);
    cursor: pointer;

    .icon-wrapper {
      visibility: hidden !important;
    }
  }
`;
