/**
 * This interface is for the initial state of the feature slice
 */
export interface Bundles {
  status: string;
  image: string;
  discountedPrice: string | number;
  price: string | number;
  paymentStatus: string;
  name: string;
  type: string;
  instructions: any;
  exams: any[];
  transactions: any[];
  expiryDate: string;
}

export interface TransactionUploadFile {
  file: any
}
export const HISTORY = 'history'
export const CURRENT = 'current'
export const ABORTED = 'aborted'
export const APPROVED = 'approved'
export const REJECTED = 'rejected'
export const AWAITING_PAYMENT_APPROVAL = 'awaiting_payment_approval'
export const AWAITING_PAYMENT = 'awaiting_payment'
export const AWAITING_ACTIVATION = 'awaiting_activation'

export interface BundlesApi {
  status: string,
  sessionId: any;
  orderId: any;
  bundles: any[],
  loadingBundles: boolean
}