import React, { useState } from "react";
import { ListCareersWrapper } from "./listCareers.styled";
import { Career } from "&features/landingPage/landingPage.type";
import OptionCard from "&components/optionCard/optionCard.component";
import { BusinessOptionIcon } from "&assets/constants/icons";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { landingPageActions } from "&features/landingPage/landingPage.slice";
import { assessmentActions } from "&features/assessment/assessment.slice";
import VideoModal from "&components/modals/videoModal/videoModal.component";
import { translationSelector } from "&utils/translationSelector";

interface Props {
  careers: Career[];
  isAssessment?: boolean;
  level?: string;
  short?: boolean;
  vertical?: boolean;
  className?: string;
  showAll?: boolean;
  hideJobs?: boolean;
  careerIndex?: number;
  reverse?: boolean;
}

const ListCareers = (props: Props) => {
  const {
    careers,
    isAssessment,
    level,
    careerIndex,
    short,
    vertical,
    className,
    showAll,
    hideJobs,
    reverse,
  } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showVideo, setShowVideo] = useState<{
    show: boolean;
    career?: Career;
  }>({ show: false, career: undefined });

  const handleCardClicked = (selectedCareer: string, id: number) => {
    if (isAssessment) {
      dispatch(
        landingPageActions.setLandingPage({
          selectedCareerPath: selectedCareer,
        })
      );
      dispatch(assessmentActions.reset());
      navigate(`/assessment?career=${selectedCareer}&id=${id}`);
    } else {
      navigate(`/career-details/${id}`);
    }
  };

  const handleClickVideo = (career: Career) => {
    setShowVideo({ show: true, career: career });
  };

  const handleCloseVideo = () => {
    setShowVideo({ show: false, career: undefined });
  };

  const createLinkId = (name: string, tier?: string) => {
    return `${name.toLowerCase().replaceAll(" ", "-")}-${
      tier?.toLowerCase()?.replaceAll(" ", "-") || "tier"
    }`;
  };

  return (
    <ListCareersWrapper
      showAll={showAll}
      short={short}
      vertical={vertical}
      className={className}
    >
      {careers?.map((option: Career, index: number) => (
        <OptionCard
          index={index}
          level={level}
          careerIndex={careerIndex}
          career={option}
          vertical={vertical}
          hideJobs={hideJobs}
          id={createLinkId(option.name, level)}
          key={option.id}
          showAll={showAll}
          reverse={reverse}
          onClick={() =>
            handleCardClicked(createLinkId(option.name, level), option.id)
          }
          title={translationSelector(option, "name")}
          icon={option.icon || <BusinessOptionIcon />}
          description={translationSelector(option, "shortDescription")}
          jobsNumber={option.jobsCount}
          onVideoClick={() =>
            isAssessment
              ? navigate(`/career-details/${option.id}`)
              : handleClickVideo(option)
          }
          thumbnailUrl={option.thumbnail || option.image}
          isAssessment={isAssessment}
        />
      ))}
      {
        <VideoModal
          onClose={handleCloseVideo}
          open={showVideo?.show}
          career={showVideo?.career}
        />
      }
    </ListCareersWrapper>
  );
};

// @ts-ignore
export default ListCareers;
// @ts-ignore
