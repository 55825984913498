import React, { useState, useEffect } from "react";
import { StepperWrapper, StepWrapper } from "./stepper.styled";
import { colors } from "&assets/constants/colors";

interface Props {
  steps?: any;
  handleStepClick?: Function;
}

const colorPalette = {
  completed: colors.green,
  uncompleted: colors.secondary,
  current: colors.green,
};

const Stepper = (props: Props) => {
  const [selectedStep, setSelectedStep] = useState<number>(1);
  const { steps = [], handleStepClick } = props;

  const step = (status = "", index: number) => {
    return (
      <div
        className={status}
        onClick={() => {
          setSelectedStep(index);
          handleStepClick && handleStepClick(index);
        }}
      >
        <p>{index}</p>
      </div>
    );
  };

  useEffect(() => {
    setSelectedStep(1);
  }, [steps]);

  const status = (step: any) => {
    return step.onGoing
      ? "current"
      : step.status === 3
      ? "completed"
      : !step.onGoing && !step.status
      ? "uncompleted"
      : "completed";
  };

  return (
    <StepperWrapper>
      <div className="step-separator" />
      {steps.length > 0 &&
        steps.map((item: any, index: number) => {
          return (
            <StepWrapper key={index} selected={selectedStep === index + 1} selectedColor={colorPalette[status(item)]}>
              <div className="border">{step(status(item), index + 1)}</div>
              <div className="arrow"></div>
            </StepWrapper>
          );
        })}
    </StepperWrapper>
  );
};

export default Stepper;
