import styled from "styled-components";
import CustomModal from "&components/custom-modal/custom-modal.component";
import { colors } from "&assets/constants/colors";
import { device } from "&assets/constants/responsiveness";

export const ProfileEditModelWrapper = styled(CustomModal)``;

interface Props {
  showDelete?: boolean;
}

export const EditModalFooter = styled.div<Props>`
  display: flex;
  justify-content: ${(props) => (props.showDelete ? "space-between" : "flex-end")};
  align-items: center;

  .error-message {
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    color: ${colors.errorColor};
    ${(props) => (props.theme.dir === "rtl" ? "margin-left: 20px;" : "margin-right: 20px;")}
  }

  .delete-button {
    background: ${colors.white};
    min-width: fit-content;
    transition: all 100ms ease-in-out;

    &:hover {
      background: ${colors.primaryLight};
    }
    p {
      transition: all 100ms ease-in-out;
      color: ${colors.primaryDark} !important;
    }
  }

  .delete-confirmation {
    background: ${colors.errorColor};
    min-width: fit-content;
  }

  .submit-button {
    background: ${colors.linearGradientThree};
    min-width: fit-content;
  }
`;

export const ProfileModalInnerContent = styled.div`
  width: 1000px;
  padding: 20px 0;
  box-sizing: border-box;
  overflow: auto;
  max-height: 80vh;
  min-height: 200px;
  display: grid;
  gap: 10px;

  .delete-button {
    background: ${colors.primaryDark};
    min-width: fit-content;
    margin-top: 22px;
  }

  .input-add-button-wrapper {
    display: flex;
    align-items: center;
    .icon-wrapper {
      margin-top: 22px;
      ${(props) => (props.theme.dir === "rtl" ? "margin-right: 10px;" : "margin-left: 10px;")}
      cursor: pointer;
    }
  }
  .input-grid {
    display: grid;
    width: 100%;
    grid-gap: 16px;
    grid-template-columns: 50% auto;
  }

  .skills-wrapper {
    ${(props) => (props.theme.dir === "rtl" ? "padding: 0 5px 0 10px;" : "padding: 0 10px 0 5px;")}
  }

  .interest-tags-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;

    .interest-wrapper {
      ${(props) => (props.theme.dir === "rtl" ? "margin-left: 15px;" : "margin-right: 15px;")}
      margin-top: 10px;

      .title {
        ${(props) => (props.theme.dir === "rtl" ? "margin-left: 10px;" : "margin-right: 10px;")}
      }
    }
  }

  .description-wrapper {
    margin-top: 16px;
  }

  .input-width-limit {
    width: 423px;
  }

  @media ${device.mobile}, ${device.tablet}, ${device.inBetween} {
    width: 100%;
    .input-grid {
      display: flex;
      max-width: initial;
      flex-direction: column;
      grid-gap: 10px;
      grid-template-columns: auto auto;
    }
    .input-width-limit {
      width: 100%;
    }
  }
`;
