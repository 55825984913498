import styled from "styled-components";
import { colors } from "&assets/constants/colors";
import { device } from "&assets/constants/responsiveness";

interface Props {
  selected: boolean;
  inputType?: string;
  showAll?: boolean;
}

export const InputButtonWrapper = styled.button<Props>`
  background: ${(props) =>
    props.selected
      ? `${colors.linearGradientOne}
      padding-box,
    ${colors.linearGradientSixth} border-box`
      : `${colors.linearGradientWhite}
      padding-box,
      ${colors.linearGradientOne} border-box`};
  border-radius: 12px;
  border: 1px solid transparent;
  box-shadow: ${(props) => (props.selected ? "4px 4px 24px rgba(0, 0, 0, 0.25)" : 0)};
  ${(props) => (props.showAll ? "min-width: fit-content;" : "width: fit-content;")}
  min-height: 46px;
  ${(props) => (props.theme.dir === "rtl" ? "margin-left: 20px;" : "margin-right: 20px;")}
  padding: ${(props) =>
    props.inputType === "checkbox" ? (props.theme.dir === "rtl" ? "0 20px 0 20px" : "0 20px 0 20px") : "0 20px"};
  cursor: pointer;

  .content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .title {
      font-style: normal;
      font-size: 16px;
      font-weight: ${(props) => (props.selected ? 700 : 500)};
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
      color: ${(props) => (props.selected ? colors.white : colors.dark)};
      opacity: ${(props) => (props.selected ? 1 : 0.7)};
      white-space: nowrap;
    }
  }

  @media ${device.inBetween}, ${device.tablet} {
    ${(props) => (props.showAll ? "width: fit-content;" : "")}
    ${(props) => (props.showAll ? "" : "min-width: 190px;")}
    ${(props) => (props.showAll ? "" : "max-width: fit-content;")}
    ${(props) => (props.theme.dir === "rtl" ? "margin-left: 15px;" : "margin-right: 15px;")}
    margin-top: 15px;
    padding: 0 15px;

    .content {
      justify-content: flex-start;
    }
  }

  @media ${device.mobile} {
    width: 100%;

    ${(props) => (props.theme.dir === "rtl" ? "margin-left: 0;" : "margin-right: 0;")}
    padding: ${(props) =>
      props.inputType === "checkbox" ? (props.theme.dir === "rtl" ? "0 15px 0 5px" : "0 5px 0 15px") : "0 15px"};
    ${(props) => (props.showAll ? "margin-bottom: 11px;" : "")}
    .content {
      justify-content: flex-start;

      .title {
        white-space: initial;
        font-size: 13px;
        ${(props) => (props.theme.dir === "rtl" ? "text-align: right;" : "text-align: left;")}
      }
    }
  }
`;

export const RadioWrapper = styled.div<Props>`
  border: 1px solid ${(props) => (props.selected ? colors.white : colors.primary)};
  min-width: 16px;
  max-width: 16px;
  height: 16px;
  border-radius: 50px;
  background-color: ${colors.white};
  ${(props) => (props.theme.dir === "rtl" ? "margin-left: 8px;" : "margin-right: 8px;")}
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  cursor: pointer;
  .check-dot {
    width: 4px;
    padding: 0;
    height: 4px;
    background: ${colors.linearGradientThree};
    border-radius: 50px;
  }
`;

export const CheckBoxWrapper = styled.div<Props>`
  border: 1px solid ${(props) => (props.selected ? colors.white : colors.primary)};
  min-width: 16px;
  max-width: 16px;
  height: 16px;
  border-radius: 3px;
  background-color: ${colors.white};
  ${(props) => (props.theme.dir === "rtl" ? "margin-left: 8px;" : "margin-right: 8px;")}
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  cursor: pointer;
  .check-dot {
    width: 4px;
    padding: 0;
    height: 4px;
    background: ${colors.linearGradientThree};
    border-radius: 50px;
  }
`;
