import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";
import React from "react";

import { LearningActivityTrackingWrapper } from "./learningActivityTracking.styled";
import { learningPathCourses } from "&features/listingPage/listingPage.type";
import LearningPathwayActivity from "&components/learningPathwayActivity/LearningPathwayActivity.component";
import getCDNProtectedURL from "&utils/getCDNProtectedImageSource";
import { images } from "&assets/constants/images-urls";
import ListItemsPartially from "&components/listItemsPartially/listItemsPartially.component";
import { useTranslation } from "react-i18next";
import { translationSelector } from "&utils/translationSelector";
interface Props {
  learningActivities?: learningPathCourses[];
  hideActivityButtons?: boolean;
  handleOpenPopup: any;
  handleApplyVoucher?: (
    event: any,
    item: any,
    type: string,
    learningPathId?: any
  ) => void;
  handleCompletionClick?: (activity: any, item: any, type: string) => void;
  learningPathId?: number;
}

const LearningActivityTracking = (props: Props) => {
  const { t } = useTranslation(["learningPathway"]);
  const {
    learningActivities,
    handleApplyVoucher,
    handleCompletionClick,
    learningPathId,
    hideActivityButtons,
    handleOpenPopup,
  } = props;
  const navigate = useNavigate();
  const handleClickActivity = (item: any, type: string) => {
    navigate(`/dashboard/${type}/${item.id}/learning-path/${learningPathId}`);
  };

  return (
    <LearningActivityTrackingWrapper>
      <ListItemsPartially
        data={learningActivities}
        stickyExam={true}
        renderItem={(activity: learningPathCourses, index: number) => {
          const item = activity?.courses || activity?.exams || activity?.labs;
          const type = activity?.labs
            ? "lab"
            : activity.exams
            ? "exam"
            : "course";

          const typeAr = activity?.labs
            ? t("LAB")
            : activity.exams
            ? t("EXAM")
            : t("COURSE");
          return (
            <LearningPathwayActivity
              key={index}
              type={type}
              typeAr={typeAr}
              hideActivityButtons={hideActivityButtons}
              started={activity.started}
              onGoing={activity.onGoing}
              isClaimed={activity.claimed}
              status={activity.status}
              isLocked={activity.locked}
              handleOpenPopup={handleOpenPopup}
              image={
                item.image || getCDNProtectedURL(images.learningPathwayImg)
              }
              activity={{ ...item, learningPathId }}
              index={index + 1}
              handleApplyForVoucher={(event) =>
                handleApplyVoucher &&
                handleApplyVoucher(event, item, type, learningPathId)
              }
              handleClick={() => {
                handleClickActivity(item, type);
              }}
              isExam={!isEmpty(activity?.exams)}
              reason={translationSelector(activity, "reason") || undefined}
              hasCompletionButton={
                activity.onGoing ||
                activity.status === 3 ||
                activity.status === 2
              }
              handleCompletionClick={() => {
                handleCompletionClick &&
                  handleCompletionClick(activity, item, type);
              }}
            />
          );
        }}
      />
    </LearningActivityTrackingWrapper>
  );
};

export default LearningActivityTracking;
