import React, { useEffect } from "react";
import moment from "moment/moment";
import * as Yup from "yup";
import { useFormik } from "formik";
import { EducationItem } from "&features/dashboard/screens/profile/profile.type";
import {
  EducationItemUpdateProfileBody,
  FormInputContent,
  UpdateProfileBody,
  WorkExperienceUpdateProfileBody,
} from "&features/login/login.type";
import { ProfileModalInnerContent } from "&components/profile/edit/profileEditModel/profileEditModal.styled";
import inputSelector from "&utils/ui/inputSelector";
import InputComponent from "&components/inputs/input/input.component";
import { educationDegrees } from "&assets/constants/profile";
import { useSelector } from "react-redux";
import { RootState } from "&store/store";
import { educationBodySelector } from "&utils/profile/buildEducationBody";
import { useTranslation } from "react-i18next";

interface Props {
  initialValues?: EducationItem;
  submit?: number;
  deleteButton?: number;
  updateProfile: (body: UpdateProfileBody) => void;
  index?: number;
}

const ProfileAddEducation = (props: Props) => {
  const { initialValues, submit, updateProfile, index, deleteButton } = props;
  const { t } = useTranslation(["profile"]);

  const user = useSelector((state: RootState) => state.login.user);
  const loadingFieldsOfStudy = useSelector(
    (state: RootState) => state.profile.loadingFieldsOfStudy
  );
  const loadingInstitutes = useSelector(
    (state: RootState) => state.profile.loadingInstitutes
  );
  const fieldOfStudyOptions = useSelector(
    (state: RootState) => state.profile.fieldsOfStudy
  );
  const institutes = useSelector(
    (state: RootState) => state.profile.institutes
  );

  const isEdit = !!initialValues;

  const values: EducationItem = {
    current: initialValues?.current || undefined,
    description: initialValues?.description || undefined,
    graduationYear: initialValues?.graduationYear || undefined,
    startDate: initialValues?.startDate || undefined,
    educationDegree: initialValues?.educationDegree || undefined,
    fieldOfStudy: initialValues?.fieldOfStudy || undefined,
    schoolName: initialValues?.schoolName || undefined,
    otherSchoolName: initialValues?.otherSchoolName || undefined,
  };

  const validationSchema = Yup.object().shape({
    educationDegree: Yup.object().required(t("REQUIRED")),
    fieldOfStudy: Yup.object().required(t("REQUIRED")),
    schoolName: Yup.object().required(t("REQUIRED")),
    otherSchoolName: Yup.string().when("schoolName", {
      is: (schoolName: any) => schoolName?.name === "Other",
      then: Yup.string()?.trim()?.required(t("REQUIRED")),
    }),
    startDate: Yup.date()
      .when("current", {
        is: (current: boolean) => !current,
        then: Yup.date().max(
          Yup.ref("graduationYear"),
          t("START_DATE_MUST_BE_LESS_THAN_GRADUATION_DATE")
        ),
      })
      .required(t("REQUIRED")),
    graduationYear: Yup.date().when("current", {
      is: (current: boolean) => !current,
      then: Yup.date()
        .max(moment(), t("GRADUATION_YEAR_CAN_NOT_BE_IN_THE_FUTURE"))
        .min(
          Yup.ref("startDate"),
          t("GRADUATION_YEAR_MUST_BE_GREATER_THAN_START_DATE")
        )
        .required(t("REQUIRED")),
    }),
  });

  const handleUpdateAdd = () => {
    if (isEdit) {
      let education: EducationItemUpdateProfileBody[] = user?.education
        ? [...user?.education]
        : [];
      education[index ?? -1] = educationBodySelector(formik.values);
      updateProfile({ educations: education });
    } else {
      let education: EducationItemUpdateProfileBody[] = user?.education
        ? [educationBodySelector(formik.values), ...user?.education]
        : [];
      updateProfile({ educations: education });
    }
  };

  const handleDelete = () => {
    let education: WorkExperienceUpdateProfileBody[] = user?.education
      ? [...user?.education]
      : [];
    if (typeof index === "number") {
      // const arraySpliced =
      education?.splice(index, 1);
      updateProfile({ educations: education });
    }
  };

  const formik: any = useFormik({
    initialValues: values,
    validationSchema: validationSchema,
    onSubmit: handleUpdateAdd,
  });

  useEffect(() => {
    submit && submit > 0 && formik.handleSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submit]);

  useEffect(() => {
    deleteButton && deleteButton > 0 && handleDelete();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteButton]);

  const educationInputGrid: FormInputContent[] = [
    {
      id: "education-degree-select",
      label: t("EDUCATION_DEGREE"),
      placeHolder: t("SELECT_FROM_THE_LIST"),
      type: "select",
      options: educationDegrees,
      value: formik.values?.educationDegree,
      handleChange: (value) => formik.setFieldValue("educationDegree", value),
      error: formik.touched.educationDegree && formik.errors.educationDegree,
    },
    {
      id: "field-of-study-select",
      label: t("FIELD_OF_STUDY"),
      placeHolder: t("SELECT_FROM_THE_LIST"),
      type: "select",
      options: fieldOfStudyOptions,
      value: formik.values?.fieldOfStudy,
      handleChange: (value) => formik.setFieldValue("fieldOfStudy", value),
      error: formik.touched.fieldOfStudy && formik.errors.fieldOfStudy,
    },
    {
      id: "school-university-name-input",
      label: t("SCHOOL_UNIVERSITY_NAME"),
      placeHolder: t("TYPE_OR_SELECT_YOUR_SCHOOL_OR_UNIVERSITY"),
      type: "select",
      options: institutes,
      value: formik.values?.schoolName,
      handleChange: (value) => formik.setFieldValue("schoolName", value),
      error: formik.touched.schoolName && formik.errors.schoolName,
    },
    {
      id: "other-school-university-name",
      label: t("SPECIFY_SCHOOL_UNIVERSITY_NAME"),
      placeHolder: t("TYPE_YOUR_SCHOOL_OR_UNIVERSITY_NAME"),
      type: "text",
      value: formik.values?.otherSchoolName || undefined,
      handleChange: (value) => formik.setFieldValue("otherSchoolName", value),
      hide: formik.values?.schoolName?.name !== "Other",
      error: formik.touched?.otherSchoolName && formik.errors?.otherSchoolName,
    },
    {
      id: "startDate-date-picker",
      label: t("START_DATE"),
      placeHolder: t("SELECT_FROM_CALENDAR"),
      type: "dateYear",
      max: formik.values?.graduationYear || moment(),
      value: formik.values?.startDate,
      handleChange: (value) => formik.setFieldValue("startDate", value),
      error: formik.touched.startDate && formik.errors.startDate,
    },
    {
      id: "graduation-year-date-picker",
      label: t("GRADUATION_YEAR"),
      placeHolder: t("SELECT_FROM_CALENDAR"),
      type: "dateYear",
      min: formik.values?.startDate,
      max: moment(),
      value: formik.values?.graduationYear,
      handleChange: (value) => formik.setFieldValue("graduationYear", value),
      hide: formik.values?.current,
      error: formik.touched.graduationYear && formik.errors.graduationYear,
    },
  ];

  const loading = loadingInstitutes || loadingFieldsOfStudy;
  return (
    <ProfileModalInnerContent>
      <div className="input-grid">
        {!loading &&
          educationInputGrid?.map(
            (item: FormInputContent, index: number) =>
              !item.hide && inputSelector(item, index)
          )}
      </div>
      {inputSelector(
        {
          id: "current-education-checkbox",
          label: t("IM_CURRENTLY_ATTENDING_THIS_SCHOOL"),
          value: formik?.values?.current,
          handleChange: () => {},
          onClick: () =>
            formik.setFieldValue(`current`, !formik?.values?.current),
          type: "checkbox",
        },
        0
      )}
      <InputComponent
        multiline={true}
        label={t("DESCRIPTION")}
        placeholder={t("DESCRIBE_YOUR_EDUCATION")}
        value={formik.values?.description}
        wrapperClassName="description-wrapper"
        onChange={(value) =>
          formik.setFieldValue("description", value.target.value)
        }
        valid={formik.touched.description && formik.errors.description}
      />
    </ProfileModalInnerContent>
  );
};

export default ProfileAddEducation;
