import styled from "styled-components";

export const LandingPageWrapper = styled.div`
background: linear-gradient(180deg, rgba(207,232,246,0.4) 0%, rgba(255,255,255,1) 52%, rgba(255,255,255,1) 88%, rgba(207,232,246,0.4) 100%);
  .landing-container {
    box-sizing: border-box;
  }
  .landing-banner-wrapper{
    width: 90%;
    margin: 0 auto;
  }
`;
