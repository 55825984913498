import { colors } from "&assets/constants/colors";
import { device } from "&assets/constants/responsiveness";
import styled from "styled-components";

export const SupportersWrapper = styled.div`
  background: #ffffff00;
  padding: 112px 65px;
  @media ${device.desktop} {
    .title-subtitle-wrapper {
      width: 100%;
    }
    .content {
      display: flex;
      justify-content: space-between;
      width: 100%;
      gap: 127px;
    }
  }

  .title {
    margin-top: 0;
    font-family: "Mulish";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 110%;
    letter-spacing: -0.02em;
    color: ${colors.primary};
  }
  .subtitle {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 160%;
    color: ${colors.dark};
    opacity: 0.7;
  }
  .supporters-cards {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
    margin-top: 50px;
    .image-card {
      background: ${colors.linearGradientWhite} padding-box, ${colors.linearGradientSixth} border-box;
      border: 1px solid transparent;
      width: 139.551px;
      height: 90px;
      border-radius: 16px;
      justify-content: center;
      align-items: center;
      display: flex;
      transition: all 0.2s ease-in;
      img {
        width: 108px;
      }

      &:hover {
        transform: scale(1.05);
      }
    }
    .large {
      img {
        width: 100px !important;
      }
    }
  }
  @media ${device.inBetween} {
    width: 100%;
    padding: 0;

    .content {
      padding: 16px;
    }
    .title {
      font-size: 28px;
    }
    .subtitle {
      font-size: 18px;
    }
    .supporters-cards {
      flex-wrap: wrap;
      justify-content: center;
      gap: initial;
      .image-card {
        width: 244px;
        height: 160px;
        ${(props) => (props.theme.dir === "rtl" ? "margin: 0 0 10px 10px;" : "margin: 0 10px 10px 0;")}

        img {
          width: 45%;
        }
      }
      .large {
        img {
          width: 45% !important;
        }
      }
    }
  }
  @media ${device.tablet} {
    width: 100%;
    padding: 0;
    .content {
      padding: 16px;
    }
    .title {
      font-size: 28px;
      width: 65%;
    }
    .subtitle {
      font-size: 18px;
      width: 100%;
    }
    .supporters-cards {
      flex-wrap: wrap;
      justify-content: center;
      gap: initial;
      .image-card {
        width: 200px;
        height: 170px;
        ${(props) => (props.theme.dir === "rtl" ? "margin: 0 0 20px 20px;" : "margin: 0 20px 20px 0;")}
        img {
          width: 85px;
        }
      }
    }
  }

  @media ${device.mobile} {
    width: 100%;
    padding: 0;
    .content {
      padding: 16px;
    }
    .title {
      font-size: 28px;
      width: 100%;
    }
    .subtitle {
      font-size: 18px;
      width: 100%;
    }
    .supporters-cards {
      flex-wrap: wrap;
      justify-content: center;
      gap: initial;
      .image-card {
        width: 45%;
        max-width: 220px;
        ${(props) => (props.theme.dir === "rtl" ? "margin: 0 0 5px 5px;" : "margin: 0 5px 5px 0;")}
        img {
          width: 85px;
        }
      }
    }
  }
`;
