import React, { FC, ReactNode } from "react";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import CustomModalWrapper from "./custom-modal.styled";
import CloseIconComponent from "&components/closeIcon/closeIcon.component";
import { XIcon } from "&assets/constants/icons";

interface Props {
  handleClose: any;
  open: boolean;
  title?: string | ReactNode;
  footer?: ReactNode;
  children: ReactNode;
  disableClose?: boolean;
  className?: string;
  titleClassName?: string;
}

const CustomModal: FC<Props> = ({
  handleClose,
  open,
  title,
  footer = <></>,
  children,
  disableClose,
  className = "",
  titleClassName,
}) => {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={!disableClose && handleClose}
      closeAfterTransition
      BackdropProps={{
        timeout: 0,
      }}
      slots={{
        backdrop: Backdrop,
      }}
    >
      <Fade in={open}>
        <CustomModalWrapper className={className}>
          {!disableClose && (
            <CloseIconComponent
              onClick={handleClose}
              className="close-icon"
              icon={<XIcon size={10} />}
            />
          )}
          <div className="custom-modal-header">
            <div className={`title ${titleClassName}`}>{title}</div>
            <div className="custom-modal-header-separator"></div>
          </div>
          <div className="custom-modal-body">{children}</div>
          <div className="custom-modal-footer">{footer}</div>
        </CustomModalWrapper>
      </Fade>
    </Modal>
  );
};

export default CustomModal;
