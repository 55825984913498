import React, { useEffect, useRef, useState } from "react";
import { connect, ConnectedProps, useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { useNavigate, useParams } from "react-router-dom";
import { isEmpty } from "lodash";

import { RootState } from "&store/store";
import { CourseDetailsWrapper } from "./courseDetails.styled";
import LearningHeader from "&components/learningHeader/learningHeader.component";
import Chip from "&components/chip/chip.component";
import ActionButton from "&components/buttons/actionButton/actionButton.component";
import { size } from "&assets/constants/responsiveness";
import Container from "&components/container/container.component";
import { CourseSkill } from "&features/listingPage/listingPage.type";
import { Clock, Document } from "&assets/constants/icons";
import secondsToHours from "&utils/secondstoHours";
import CircularLoader from "&components/circularLoader/circularLoader.component";
import HtmlParser from "&components/htmlParser/htmlParser.component";
import { courseDetailsActions } from "&features/courseDetails/courseDetails.slice";
import getCDNProtectedURL from "&utils/getCDNProtectedImageSource";
import { images } from "&assets/constants/images-urls";
import { LINKEDIN_URL } from "&assets/constants/statics";
import UploadCompletionComponent from "&components/uploadCompletionComponent/uploadCompletionComponent.component";
import { courseURLBuilder } from "&utils/courseURLBuilder";
import { translationSelector } from "&utils/translationSelector";
import LearningActivityActionButtonsModal from "&components/dashboard/learningActivityActionButtonsModal/learningActivityActionButtonsModal.component";
import { RenderButtonsProps } from "&components/learningPathwayActivity/LearningPathwayActivity.component";
import { addedSkillsSelector } from "&utils/addedSkillsSelector";
import { buildAssessmentBodyForAdd } from "&utils/buildAssessmentObject";
import { simplilearnURLBuilder } from "&utils/simplilearnUrlBuilder";

type ReduxProps = ConnectedProps<typeof connector>;

const CourseDetailsComponent = (props: ReduxProps) => {
  const { getCourseById, loading, selectedCourse } = props;
  const user = useSelector((state: RootState) => state.login.user);

  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: size.mobile });
  const { t } = useTranslation(["courseDetails"]);
  const [readMore, setReadMore] = useState(false);
  const [showReadMore, setShowReadMore] = useState(false);
  const descriptionRef = useRef<HTMLDivElement>(null);
  const status = useSelector((state: RootState) => state.courseDetails.status);

  const statusContent: any = {
    1: t("COMPLETION_PROOF_VALIDATION"),
    2: t("COMPLETION_PROOF_REJECTED"),
    3: t("COMPLETION_PROOF_ACCEPTED"),
  };

  const [openActivityPopup, setOpenActivityPopup] = useState<{
    renderButtonsProps?: RenderButtonsProps;
    open: boolean;
  }>({ renderButtonsProps: undefined, open: false });

  const dispatch = useDispatch();
  const type = "course";

  useEffect(() => {
    if (status === "SENT_REPORT_SUCCESSFULLY") {
      id && getCourseById(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    const descriptionElement = descriptionRef.current;
    if (descriptionElement) {
      const isContentOverflowing =
        descriptionElement.scrollHeight > descriptionElement.clientHeight;
      setShowReadMore(isContentOverflowing);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCourse?.description, isMobile]);

  const { id, learningPathId } = useParams();

  const launchActivityTracker = () =>
    selectedCourse?.id &&
    dispatch(
      courseDetailsActions.launchActivity({
        type: "courses",
        id: selectedCourse?.id,
        fromCourseDetails: true,
      })
    );

  const informatics: any = [
    {
      icon: <Document fill="white" />,
      text: selectedCourse?.language?.toUpperCase(),
    },
    {
      icon: <Clock fill="white" />,
      text: secondsToHours(selectedCourse?.duration),
    },
  ];

  useEffect(() => {
    id && getCourseById(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, localStorage.getItem("language")]);

  const loadingComponent = (
    <div className="loader-wrapper">
      <CircularLoader />
    </div>
  );

  let userSkills = buildAssessmentBodyForAdd(
    {
      hardSkills: user?.userSkills?.hard_skills,
      softSkills: user?.userSkills?.soft_skills,
      otherSkills: user?.userSkills?.other_skills,
    },
    true
  );

  // const { disableAddSkills } =
  addedSkillsSelector(userSkills, selectedCourse?.courseSkills || []);

  const completedEligibility =
    user?.eligibilityForm && user.eligibilityForm.approved;

  const showSponsored =
    selectedCourse?.sponsorshipType &&
    selectedCourse?.sponsorshipType === "subscription" &&
    !!selectedCourse?.sponsored
      ? !isEmpty(user?.sponsorships) &&
        !isEmpty(user?.sponsorships[selectedCourse?.source])
      : false;

  const isLinkedInTracked =
    selectedCourse?.source === "LinkedIn" &&
    !isEmpty(user?.sponsorships) &&
    !isEmpty(user?.sponsorships["LinkedIn"]);

  const showReason =
    selectedCourse?.status === 2 && !isEmpty(selectedCourse?.reason);

  const cantUploadCompletion = selectedCourse?.status === 1;
  // || (selectedCourse?.source === "LinkedIn" &&
  //   !!selectedCourse?.sponsored &&
  //   !isEmpty(user?.sponsorships) &&
  //   !isEmpty(user?.sponsorships["LinkedIn"]));

  const rejectedEligibility =
    user?.eligibilityForm &&
    !user.eligibilityForm.approved &&
    user.eligibilityForm?.reason?.length > 0;

  const showBundles = !!(
    selectedCourse?.sponsored &&
    (rejectedEligibility || (user?.isEQ2 && completedEligibility))
  );

  const shouldShowSponsorshipButton =
    !showSponsored &&
    selectedCourse?.sponsorshipType &&
    !!selectedCourse?.sponsored &&
    selectedCourse?.sponsorshipType === "subscription" &&
    type === "course"
      ? true
      : false;

  const openPopup = () => {
    selectedCourse &&
      setOpenActivityPopup({
        open: true,
        renderButtonsProps: {
          activity: selectedCourse,
          shouldShowBundles: showBundles,
          isLinkedInTracked: isLinkedInTracked,
          loadingVoucher: false,
          shouldShowSponsorshipButton: shouldShowSponsorshipButton,
          status: selectedCourse.status,
          type: type,
          // handleApplyForVoucher: handleApplyForVoucher,
          onGoing: true,
          completedEligibility: completedEligibility,
          started: selectedCourse?.started,
          launchActivityTracker: launchActivityTracker,
          typeAr: t("COURSE"),
        },
      });
  };

  const showPopup =
    ((shouldShowSponsorshipButton && completedEligibility) ||
      (!completedEligibility && selectedCourse?.sponsored)) &&
    !rejectedEligibility;

  const hideLaunchButton =
    selectedCourse?.source?.toLowerCase() === "simplilearn";
  return (
    <CourseDetailsWrapper>
      {loading ? (
        loadingComponent
      ) : (
        <>
          <LearningHeader
            title={translationSelector(selectedCourse, "name")}
            description={translationSelector(
              selectedCourse,
              "shortDescription"
            )}
            handleBackClick={() => navigate(-1)}
            providerName={translationSelector(selectedCourse, "source")}
            image={
              selectedCourse?.image ||
              getCDNProtectedURL(images.learningPathwayImg)
            }
            hasButton={!hideLaunchButton}
            buttonText={t("LAUNCH_COURSE")}
            handleButtonClick={() => {
              if (showPopup) {
                openPopup();
              } else {
                !selectedCourse?.started && launchActivityTracker();
                window.open(
                  isLinkedInTracked
                    ? LINKEDIN_URL + translationSelector(selectedCourse, "url")
                    : selectedCourse?.source?.toLowerCase() === "simplilearn"
                    ? simplilearnURLBuilder(translationSelector(selectedCourse, "url"))
                    : courseURLBuilder(translationSelector(selectedCourse, "url")),
                  "_blank",
                  "noreferrer"
                );
              }
            }}
            level={translationSelector(selectedCourse?.level, "alias")}
            informatics={informatics}
            sponsored={showSponsored}
            disclaimer={
              statusContent[selectedCourse?.status || ""]
                ? `${statusContent[selectedCourse?.status || ""]} ${
                    showReason
                      ? `${t(
                          "FOR_THE_FOLLOWING_REASON"
                        )}: ${translationSelector(selectedCourse, "reason")}`
                      : ""
                  } `
                : undefined
            }
            buttonComponent={
              <div className="buttonComponents">
                {(!cantUploadCompletion && selectedCourse?.started) ||
                selectedCourse?.status === 3 ? (
                  <UploadCompletionComponent
                    type="course"
                    item={selectedCourse}
                    learningPathId={
                      learningPathId ? parseInt(learningPathId) : undefined
                    }
                  />
                ) : null}
              </div>
            }
          />
          <Container>
            <div className="course-details">
              <p className="header">{t("COURSE_DESCRIPTION")}</p>
              <div
                className={`content-text ${readMore ? "expand" : ""}`}
                ref={descriptionRef}
              >
                {!readMore && showReadMore && (
                  <div className={`layer ${readMore ? "expand" : ""}`} />
                )}
                <HtmlParser
                  className="description-content"
                  description={translationSelector(
                    selectedCourse,
                    "description"
                  )}
                />
              </div>
              {isMobile && showReadMore && (
                <ActionButton
                  className="read-more"
                  titleClassName="read-more-title"
                  title={readMore ? t("READ_LESS") : t("READ_MORE")}
                  handleClick={() => setReadMore(!readMore)}
                />
              )}
              <p className="header">{t("SKILLS_YOU_WILL_GAIN")}</p>
              <div className="chips">
                {selectedCourse?.courseSkills?.map(
                  (item: CourseSkill, index: number) => {
                    return (
                      <Chip
                        title={translationSelector(item?.skills, "name")}
                        key={index}
                      ></Chip>
                    );
                  }
                )}
              </div>
            </div>
          </Container>
        </>
      )}
      <LearningActivityActionButtonsModal
        handleClose={() =>
          setOpenActivityPopup({
            open: false,
            renderButtonsProps: undefined,
          })
        }
        fromCourseDetails={true}
        open={openActivityPopup.open}
        renderButtonsProps={openActivityPopup.renderButtonsProps}
      />
    </CourseDetailsWrapper>
  );
};

const mapStateToProps = (state: RootState) => ({
  selectedCourse: state.courseDetails.selectedCourse,
  loading: state.courseDetails.loading,
  user: state.login.user,
});

const mapDispatchToProps = {
  getCourseById: courseDetailsActions.getCourseById,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const CourseDetailsComponentRedux = connector(CourseDetailsComponent);

export { CourseDetailsComponentRedux as CourseDetailsComponent };
