import { API } from "aws-amplify";
import { RES_LEARNING_PATHWAY } from "&store/resources";
import { GetPageListingQuery } from "&features/listingPage/listingPage.type";

const getLearningPathwayByIdApi = async (id: string, isLoggedIn: boolean) => {
  let URL = isLoggedIn
    ? `/auth${RES_LEARNING_PATHWAY}/${id}`
    : `${RES_LEARNING_PATHWAY}/${id}`;
  return API.get(process.env.REACT_APP_NAME || "", URL, {});
};

const enrollLearningPathwayApi = async (id: string) => {
  let URL = `/auth/user/learning-paths/${id}/enroll`;
  return API.post(process.env.REACT_APP_NAME || "", URL, {});
};

const getAvailableSimplilearnLearningPaths = async () => {
  let URL = `${RES_LEARNING_PATHWAY}/available/simplilearn`;
  return API.get(process.env.REACT_APP_NAME || "", URL, {});
};

const getFeaturedLearningPathwayApi = async ({
  params,
  ids,
  isLoggedIn
}: {
  params: GetPageListingQuery;
  ids: string[];
  isLoggedIn: boolean
}) => {
  const { limit, page, countryId } = params;
  const concatenatedIds = ids?.map(id => `careerIds[]=${id}`).join('&');
  let URL = isLoggedIn ? 
  `/auth${RES_LEARNING_PATHWAY}/top/careers?${concatenatedIds}&limit=${limit}&page=${page}&countryId=${countryId}` : 
  `${RES_LEARNING_PATHWAY}/top/careers?${concatenatedIds}&limit=${limit}&page=${page}&countryId=${countryId}`;

  return API.get(process.env.REACT_APP_NAME || "", URL, {});
};

export {
  getLearningPathwayByIdApi,
  enrollLearningPathwayApi,
  getFeaturedLearningPathwayApi,
  getAvailableSimplilearnLearningPaths
};
