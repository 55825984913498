import React from "react";
import {
  CheckBoxSliderWrapper,
  SkillsBuilderWrapper,
  SkillsSectionWrapper,
} from "./skillsBuilder.styled";
import SearchOtherSkills from "&components/searchOtherSkills/searchOtherSkills.component";
import SectionTitleWithLine from "&components/sectionTitleWithLine/sectionTitleWithLine.component";
import {
  AssessmentForm,
  Skill,
  SkillLevels,
  SkillTypes,
} from "&features/assessment/assessment.type";
import { Setting } from "&features/global/global.type";
import { levelMarks } from "&assets/constants/levelMarks";
import InputButton from "&components/buttons/inputButton/inputButton.component";
import Slider from "&components/slider/slider.component";
import { useTranslation } from "react-i18next";
import { translationSelector } from "&utils/translationSelector";

interface Props {
  formValues: AssessmentForm;
  setAssessmentForm: any;
  loadingAssessment: boolean;
  settings: Setting[];
}

const SkillsBuilder = (props: Props) => {
  const {
    setAssessmentForm,
    // loadingAssessment, settings,
    formValues,
  } = props;
  const { t } = useTranslation(["assessment"]);

  const handleChangeValues = (value: AssessmentForm) => {
    setAssessmentForm({ ...formValues, ...value });
  };

  const handleSkillValueChange = (skills: Skill[]) => {
    handleChangeValues({
      otherSkills: skills,
    });
  };

  const renderCheckboxSlider = (skill: Skill, skillType: SkillTypes) => {
    const handleChange = (
      inputType: "checkBox" | "slider",
      levelValue?: SkillLevels
    ) => {
      const newObject: Skill = {
        ...skill,
        value: {
          level: levelValue || levelMarks[0],
          checked:
            inputType === "checkBox"
              ? !formValues[skillType][skill?.skillId].value.checked
              : formValues[skillType][skill?.skillId].value.checked,
        },
      };

      handleChangeValues({
        [skillType]: {
          ...formValues[skillType],
          [skill.skillId]: newObject,
        },
      });
    };

    return (
      <CheckBoxSliderWrapper key={skill.id}>
        <InputButton
          id={skill.skillId}
          type={"checkbox"}
          selected={skill.value?.checked}
          title={translationSelector(skill, "name")}
          handleClick={() => handleChange("checkBox")}
        />
        <Slider
          className="slider"
          disabled={!skill.value?.checked}
          marks={levelMarks}
          value={skill.value?.level || levelMarks[0]}
          onChange={(value) => handleChange("slider", value)}
        />
      </CheckBoxSliderWrapper>
    );
  };
  const renderSkillList = (objectList: any, skillId: SkillTypes) => {
    return Object.keys(objectList)?.map((key, index) =>
      renderCheckboxSlider(objectList[key], skillId)
    );
  };

  return (
    <SkillsBuilderWrapper>
      <SectionTitleWithLine
        title={t("ADD_YOUR_SKILLS")}
        description={t("SELECT_AT_LEAST_3_SKILLS_TO_CONTINUE")}
      />
      <SkillsSectionWrapper>
        <div className="title-skills-wrapper">
          <div className="skills-title">{t("MAIN_SKILLS")}</div>
          {formValues?.otherSkillsList && (
            <SearchOtherSkills
              handleSkillsChange={handleSkillValueChange}
              otherSkillsValue={formValues?.otherSkills || []}
              skills={formValues?.otherSkillsList}
            />
          )}
        </div>
        <div className="title-skills-wrapper">
          {(formValues?.hardSkills &&
            Object.keys(formValues?.hardSkills)?.length > 0) ||
          (formValues?.softSkills &&
            Object.keys(formValues?.softSkills)?.length > 0) ? (
            <div className="skills-title">{t("RECOMMENDED_SKILLS")}</div>
          ) : null}
          {formValues?.hardSkills &&
            Object.keys(formValues?.hardSkills)?.length > 0 && (
              <div className="title-skills-wrapper">
                <div className="skills-section-wrapper">
                  {formValues?.hardSkills &&
                    renderSkillList(formValues?.hardSkills, "hardSkills")}
                </div>
              </div>
            )}
          {formValues?.softSkills &&
            Object.keys(formValues?.softSkills)?.length > 0 && (
              <div className="title-skills-wrapper">
                <div className="skills-section-wrapper">
                  {formValues?.softSkills &&
                    renderSkillList(formValues?.softSkills, "softSkills")}
                </div>
              </div>
            )}
        </div>
      </SkillsSectionWrapper>
    </SkillsBuilderWrapper>
  );
};

export default SkillsBuilder;
