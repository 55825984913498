import React, { useEffect, useRef, useState } from "react";
import { connect, ConnectedProps, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { RootState } from "&store/store";
import { futureCareersActions } from "./futureCareers.slice";
import { FutureCareersWrapper } from "./futureCareers.styled";
import { landingPageActions } from "&features/landingPage/landingPage.slice";
import OpportunitiesOrCourse from "&components/opportunitiesOrCourse/opportunitiesOrCourse.component";
import { opportunitiesListingActions } from "&features/opportunitiesListing/opportunitiesListing.slice";
import CircularLoader from "&components/circularLoader/circularLoader.component";
import ListingPageHeader from "&components/headers/listingPageHeader/listingPageHeader.component";
import SearchSortFilterBar from "&components/searchSortFilterBar/searchSortFilterBar.component";
import { useMediaQuery } from "react-responsive";
import { size } from "&assets/constants/responsiveness";
import { buildDropDownList, buildLevelsList, querySelector } from "&utils/searchSortFilter/searchSortFilterUtils";
import { CareersSearchParams } from "&features/careerListing/careerListing.type";
import { defaultCareersSearchParams } from "&assets/constants/statics";
import { Container, Tab } from "@mui/material";
import { LevelsMobileTabs, TabPanelWrapper } from "&features/careerListing/careerListing.styled";
import SwipeableViews from "react-swipeable-views";
import AllCareers from "&components/AllCareers/allCareers.component";
import ListAllCareers from "&components/listAllCareers/listAllCareers.component";
import { careerListingActions } from "&features/careerListing/careerListing.slice";
import { Career } from "&features/landingPage/landingPage.type";
import DunnoWhichCareer from "&components/landing/dunnoWhichCareer/dunnoWhichCareer.component";
import { translationSelector } from "&utils/translationSelector";

type ReduxProps = ConnectedProps<typeof connector>;

const FutureCareersComponent = (props: ReduxProps) => {
  const {
    assessmentLevels,
    homeContent,
    jobs,
    loading,
    totalCareers,
    levels,
    selectedCountry,
    careers,
    levelsLanding,
    pathsHistory,
    loadingCareers,
    getAllCareers,
    getAssessmentLevels,
    getPagesContent,
    getLevels,
    getFeaturedJobs,
    getLevelsLanding,
  } = props;

  const navigate = useNavigate();
  const { t, i18n } = useTranslation(["futureCareers"]);
  const [limit, setLimit] = useState<number>(24);
  const user = useSelector((state: RootState) => state.login.user);
  const isArabic = i18n.language === "ar";
  const sortByObject = {
    popular: t("POPULAR"),
    [isArabic ? "nameAr" : "name"]: t("NAME"),
    createdAt: t("DATE"),
    vacancies: t("VACANCIES"),
  };

  useEffect(() => {
    !homeContent?.sections && getPagesContent();
    getAllCareers({ page: 1, limit });
    getLevels({ limit: 3 });
    (!assessmentLevels || assessmentLevels?.length === 0) && getAssessmentLevels({ limit: 5 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountry, localStorage.getItem("language")]);

  const headerTitle = t("FIND_CAREER");
  const headerDescription = t("FIND_CAREER_DESCRIPTION");
  const findPrevious = () => {
    let previousPath = "";
    pathsHistory?.some((path: string) => {
      if (path !== window.location.pathname) {
        previousPath = path;
        return true;
      }
    });
    return previousPath;
  };

  const isMobile = useMediaQuery({ maxWidth: size.mobile });
  const isTablet = useMediaQuery({
    minWidth: size.mobile,
    maxWidth: size.tablet,
  });

  const ref = useRef<any>(null);
  const loaded = levelsLanding?.length > 0;

  const loadingComponent = (
    <div className="loader-wrapper">
      <CircularLoader />
    </div>
  );

  const fetchPageContent = () => {
    setSearchParams({
      ...searchParams,
    });

    const requestBody: any = {
      query: searchParams.query || "",
      tierId: (Number(searchParams.level) > 0 && Number(searchParams.level)) || undefined,
      page: 1,
      limit,
      dir: "ASC",
      sort:
        (searchParams.sort_by !== "popular" && searchParams.sort_by !== "vacancies" && searchParams.sort_by) ||
        undefined,
    };

    getAllCareers(requestBody);
  };

  useEffect(() => {
    levels.length > 0 &&
      getFeaturedJobs({
        params: {
          countryId: selectedCountry.value,
          page: 1,
          limit: 9,
          sort: "datePosted",
          dir: "DESC",
        },
        ids: levels?.slice(0, 3)?.map((level: any) => level?.careers[0]?.id),
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountry, levels, localStorage.getItem("language")]);

  useEffect(() => {
    levelsLanding && levelsLanding?.length === 0 && getLevelsLanding({ limit: 5 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [levelsLanding]);
  const handleLoadMore = () => {
    if (limit < totalCareers) {
      setLimit(limit + 24);
    }
  };
  const [urlSearchParams, setSearchParams] = useSearchParams();
  const [tabValue, setTabValue] = useState<number>(0);

  const searchParams: CareersSearchParams = {
    query: urlSearchParams.get("query") || defaultCareersSearchParams.query,
    level: urlSearchParams.get("level") || defaultCareersSearchParams.level,
    sort_by: urlSearchParams.get("sort_by") || defaultCareersSearchParams.sort_by,
  };

  const searchState: any = {
    level: {
      id: "level",
      title: t("LEVEL"),
      object: buildLevelsList(levelsLanding),
      list: buildDropDownList(buildLevelsList(levelsLanding)),
      value: searchParams.level,
      valueItem: querySelector(buildDropDownList(buildLevelsList(levelsLanding)), searchParams.level),
    },
    sort_by: {
      id: "sort_by",
      title: t("SORT_BY"),
      object: sortByObject,
      list: buildDropDownList(sortByObject),
      value: searchParams.sort_by,
      valueItem: querySelector(buildDropDownList(sortByObject), searchParams.sort_by),
    },
  };

  useEffect(() => {
    setSearchParams({
      ...searchParams,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderTabPanel = (level: string, index: number, tabValue: number) => {
    const isArabic = i18n.language === "ar";
    return (
      <TabPanelWrapper key={index} level={level} index={index}>
        {index === tabValue ? (
          <ListAllCareers
            showAll={true}
            reverse={isArabic}
            careers={careers.filter((career: Career) => translationSelector(career.tier, "alias") === level)}
            vertical={true}
            hideJobs={false}
          />
        ) : null}
      </TabPanelWrapper>
    );
  };

  const renderMobileTabs = () => {
    const uniqueLevels = [...new Set(careers.map((career) => translationSelector(career.tier, "alias")))];
    return (
      <>
        {!user?.name && (
          <div className="mobile-tabs-careers-wrapper">
            <DunnoWhichCareer />
          </div>
        )}
        <LevelsMobileTabs centered value={tabValue} onChange={(event, newValue) => setTabValue(newValue)}>
          {uniqueLevels.map((level, index) => (
            <Tab label={level} id={`level-mobile-tab-${level}`} key={index} />
          ))}
        </LevelsMobileTabs>
        <SwipeableViews axis={"x"} index={tabValue} onChangeIndex={(value: number) => setTabValue(value)}>
          {uniqueLevels.map((level, index) => renderTabPanel(level, index, tabValue))}
        </SwipeableViews>
      </>
    );
  };

  useEffect(() => {
    fetchPageContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchParams.query,
    searchParams.level,
    searchParams.sort_by,
    limit,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    localStorage.getItem("language"),
  ]);

  return (
    <FutureCareersWrapper>
      <ListingPageHeader
        title={headerTitle}
        description={headerDescription}
        onBackClick={() => navigate(findPrevious())}
        content="Find the career of your dreams. Achieve your career goals."
      />
      {loaded ? (
        <>
          <Container className="listing-container">
            <SearchSortFilterBar
              searchState={searchState}
              forwardRef={ref}
              placeHolder={t("SEARCH_CAREERS")}
              setSearchParams={setSearchParams}
              searchParams={searchParams}
            />
          </Container>
          {loadingCareers ? (
            loadingComponent
          ) : isMobile || isTablet ? (
            renderMobileTabs()
          ) : (
            <AllCareers
              hideLoadMore={limit >= totalCareers}
              hideJobs={false}
              showAll={true}
              careers={careers}
              handleLoadMore={handleLoadMore}
              user={user}
            />
          )}
        </>
      ) : (
        loadingComponent
      )}
      {loading ? (
        <CircularLoader />
      ) : (
        jobs && (
          <OpportunitiesOrCourse
            data={jobs?.items || jobs || []}
            title={t("FEATURED_JOB_OPPORTUNITIES")}
            allButtonText={t("VIEW_ALL")}
            onAllButtonClick={() => navigate("/opportunities")}
            haveImageBackground={true}
          />
        )
      )}
    </FutureCareersWrapper>
  );
};

const mapStateToProps = (state: RootState) => ({
  homeContent: state.landingPage.homeContent,
  assessmentLevels: state.landingPage.levels,
  loading: state.opportunitiesListing.loading,
  jobs: state.opportunitiesListing.jobs,
  selectedCountry: state.landingPage.selectedCountry,
  careers: state.careerListing.careers,
  totalCareers: state.careerListing.totalCareers,
  levels: state.futureCareers.levels,
  levelsLanding: state.landingPage.levels,
  pathsHistory: state.global.pathsHistory,
  loadingLevels: state.careerListing.loadingLevels,
  loadingCareers: state.careerListing.loadingCareers,
});

const mapDispatchToProps = {
  getPagesContent: landingPageActions.getPagesContent,
  getAllCareers: careerListingActions.getAllCareers,
  getLevelsLanding: landingPageActions.getLevels,
  getLevels: futureCareersActions.getLevels,
  getAssessmentLevels: landingPageActions.getLevels,
  getFeaturedJobs: opportunitiesListingActions.getFeaturedJobs,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const FutureCareersComponentRedux = connector(FutureCareersComponent);

export { FutureCareersComponentRedux as FutureCareersComponent };
