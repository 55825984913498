import { API } from "aws-amplify";
import {RES_ASSESSMENTS, RES_LEARNING_PATHWAY} from "&store/resources";

const getMatchingCareersApi = async (body: any) => {
  let URL = `${RES_ASSESSMENTS}`;

  const myInit = {
    body: body, // replace this with attributes you need
  };
  return API.post(process.env.REACT_APP_NAME || "", URL, myInit);
};

const getCareerLearningPathsApi = async (body: any) => {
  let URL = `${RES_ASSESSMENTS}${RES_LEARNING_PATHWAY}`;
  const myInit = {
    body: body, // replace this with attributes you need
  };
  return API.post(process.env.REACT_APP_NAME || "", URL, myInit);
};

export { getMatchingCareersApi, getCareerLearningPathsApi };
