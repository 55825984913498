import React from "react";
import { TitleWithStepsWrapper } from "./titleWithSteps.styled";
import AssessmentSteps from "&components/assessmentSteps/assessmentSteps.component";
import Container from "&components/container/container.component";
import PageTitleSubtitle from "&components/pageTitleSubtitle/pageTitleSubtitle.component";
import { EyeIcon, FormIcon, FuturePathStepIcon } from "&assets/constants/icons";
import SmallBackButton from "&components/buttons/smallBackButton/smallBackButton.component";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";

interface Props {
  id?: string;
  step: number;
  className?: string;
  forwardRef?: any;
  onStepClick?: (step: number) => void;
  short?: boolean;
  isBuildProfile?: boolean;
  hasBackButton?: boolean;
  onBackButtonClick?: () => void;
  title?: string;
  subTitle?: string;
  titleSubtitleWrapper?: string;
  containerWrapper?: string;
  showSignupText?: boolean;
}

interface Step {
  icon: any;
  description: string;
}

const TitleWithSteps = (props: Props) => {
  const {
    id,
    step,
    className,
    forwardRef,
    onStepClick,
    short,
    isBuildProfile,
    containerWrapper,
    titleSubtitleWrapper,
    hasBackButton,
    onBackButtonClick,
    title,
    subTitle,
    showSignupText,
  } = props;
  const { loginWithRedirect } = useAuth0();
  const { t } = useTranslation(["landingPage"]);

  // const navigate = useNavigate();

  const AssessmentStepsContent: Step[] | any = [
    {
      icon: <FuturePathStepIcon />,
      description: t("SELECT_YOUR_CAREER"),
    },
    {
      icon: <EyeIcon />,
      description: t("COMPARE_YOUR_SKILLS"),
    },
    {
      icon: <FormIcon />,
      description: t("GET_YOUR_MATCHING_SCORECARD"),
    },
  ];

  const BuildProfileContent: Step[] | any = [
    {
      icon: <EyeIcon />,
      description: t("LETS_GET_TO_KNOW_YOU"),
    },
    {
      icon: <FormIcon />,
      description: t("YOUR_SKILLS"),
    },
    {
      icon: <FormIcon />,
      description: t("APPLY_FOR_FINANCIAL_SPONSORSHIP"),
    },
  ];

  const renderSignupText = () => {
    return (
      <div className="signup-text-wrapper">
        <div className="sub-title">
          {t("NOT_SURE_WHICH_CAREER_TO_PICK")}{" "}
          <span onClick={() => loginWithRedirect()} className="link">
            {t("CLICK_HERE_TO_SIGN_UP")}
          </span>
        </div>
      </div>
    );
  };

  return (
    <TitleWithStepsWrapper
      id={id}
      noTopMargin={isBuildProfile}
      short={short}
      ref={forwardRef}
      className={className}
    >
      <Container className={`title-with-steps-container ${containerWrapper}`}>
        {hasBackButton && (
          <SmallBackButton
            onBackButtonClick={onBackButtonClick}
            className="back-button"
          />
        )}
        <PageTitleSubtitle
          isWhite={isBuildProfile}
          title={title}
          subTitle={subTitle}
          className={titleSubtitleWrapper}
        />
        {showSignupText && renderSignupText()}
        <AssessmentSteps
          onStepClick={(step: number) => onStepClick && onStepClick(step)}
          step={step}
          steps={isBuildProfile ? BuildProfileContent : AssessmentStepsContent}
        />
      </Container>
    </TitleWithStepsWrapper>
  );
};

export default TitleWithSteps;
