import React from "react";
import { SmallBackButtonWrapper } from "./smallBackButton.styled";
import { ArrowForwardIcon } from "&assets/constants/icons";
import { colors } from "&assets/constants/colors";
import {useTranslation} from "react-i18next";

interface Props {
  className?: string;
  onBackButtonClick?: () => void;
}

const SmallBackButton = (props: Props) => {
  const { className, onBackButtonClick } = props;
  const { t } = useTranslation(["login"]);

  return (
    <SmallBackButtonWrapper onClick={onBackButtonClick} className={className}>
      <div className="arrow">{ArrowForwardIcon(colors.white)}</div>
      <div className="button-text">{t('BACK')}</div>
    </SmallBackButtonWrapper>
  );
};

export default SmallBackButton;
