import styled from "styled-components";
import { colors } from "&assets/constants/colors";
import { device } from "&assets/constants/responsiveness";

interface Props {
  isSmall?: boolean;
  viewMode?: boolean;
  isCentered?: boolean;
}
export const ProfileSectionTitleWrapper = styled.div<Props>`
  display: flex;
  justify-content: ${props => props.viewMode || props.isCentered ? "center" : 'space-between'};
  .title-section {
    width: 100%;
    height: 45px;
    border-bottom: 1px solid ${colors.primary};
    font-weight: 800;
    font-size: 24px;
    line-height: 120%;
    display: flex;
    align-items: flex-start;
    color: #25324b;
  }

  .small-title-section {
    font-weight: 700;
    font-size: 18px;
    line-height: 120%;
    display: flex;
    align-items: center;
    color: #25324b;
  }

  @media ${device.tablet}, ${device.mobile} {
    .title-section {
      font-weight: 700;
      font-size: 20px;
      margin-bottom: 10px;
      align-items: center;
      padding-bottom: 12px;
      justify-content: space-between;
    }
  }
`;
