import React, { useEffect, useState } from "react";
import { ContactUsButtonWrapper } from "./contactUsButton.styled";
import { useLocation } from "react-router";
import i18n from "&config/i18n";
import { useMediaQuery } from "react-responsive";
import { size } from "&assets/constants/responsiveness";
import { useSelector } from "react-redux";
import { RootState } from "&store/store";

interface Props {}

const ContactUsButton = (props: Props) => {
  // const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector((state: RootState) => state.login.user);

  const isArabic = i18n.language === "ar";
  let observer: MutationObserver | null = null;

  const isMobile = useMediaQuery({ maxWidth: size.mobile });
  const isTablet = useMediaQuery({
    minWidth: size.mobile,
    maxWidth: size.tablet,
  });

  const isInDashboard = location.pathname.includes("dashboard");

  const [widgetContainerFound, setWidgetContainerFound] =
    useState<boolean>(false);

  const changeRespondIOSrc = () => {
    // Replace 'NEW_SRC_URL_HERE' with the new src URL you want
    const srcRespondIo = isArabic
      ? "https://cdn.respond.io/widget/widget.js?wId=e8c1ca48-4353-42ce-b64f-777b9942b557"
      : "https://cdn.respond.io/widget/widget.js?wId=07c780fb-684a-47ca-b76f-56737d1263f8";

    // Create a new script element with the updated src
    const scriptElement: any = document.createElement("script");
    scriptElement.id = "respondio__growth_tool";
    scriptElement.src = srcRespondIo;
    // Append the new script to the DOM to trigger loading and execution
    document.body.appendChild(scriptElement);
    const widgetElement: any = document.querySelector("respond-io-widget");
    const shadowRoot = widgetElement?.shadowRoot;
    const widgetContainer = shadowRoot?.querySelector(".container");

    if (widgetContainerFound && widgetContainer?.style) {
      widgetContainer.style.right = isArabic ? "initial" : "25px";
      widgetContainer.style.left = isArabic ? "25px" : "initial";
      widgetContainer.style.bottom =
        (isMobile || isTablet) && user?.name && isInDashboard
          ? "100px"
          : "25px";

      const iconTags = widgetContainer.getElementsByClassName(
        "speed-dial__caption"
      );
      if (iconTags && iconTags?.length) {
        for (var i = 0; i < iconTags.length; i++) {
          var iconTag = iconTags[i];
          iconTag.style.right = isArabic ? "-10px" : "initial";
          iconTag.style.left = isArabic ? "initial" : "-10px";
          iconTag.style.transform = isArabic
            ? "translateX(100%)"
            : "translateX(-100%)";
        }
      }
    }
  };

  const checkForWidget = () => {
    const widgetElement: any = document.querySelector("respond-io-widget");
    const shadowRoot = widgetElement?.shadowRoot;
    const widgetContainer = shadowRoot?.querySelector(".container");
    if (widgetContainer) {
      setWidgetContainerFound(true);
      if (observer) {
        // Disconnect the observer when the widget is found
        observer.disconnect();
        observer = null;
      }
    }
  };

  const observeForWidget = () => {
    if (!observer) {
      observer = new MutationObserver(checkForWidget);
      // Start observing changes in the DOM
      observer.observe(document.documentElement, {
        childList: true,
        subtree: true,
      });
    }
  };

  useEffect(() => {
    changeRespondIOSrc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language, widgetContainerFound]);

  useEffect(() => {
    if (!widgetContainerFound) {
      observeForWidget();
    }

    return () => {
      if (observer) {
        // Disconnect the observer when the component unmounts
        observer.disconnect();
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer = null;
      }
    };
  }, [widgetContainerFound]);

  return <ContactUsButtonWrapper></ContactUsButtonWrapper>;
};

export default ContactUsButton;
