import styled from "styled-components";
import { Input, InputLabel } from "@mui/material";

import { device } from "&assets/constants/responsiveness";
import { colors } from "&assets/constants/colors";

interface Props {
  valid?: boolean;
  isgradient?: boolean;
  isWhite?: boolean;
}

export const InputWrapper = styled(Input)<Props>`
  box-sizing: border-box;
  width: 100%;
  ${(props) => (props.multiline ? "min-height: 50px;" : "max-height: 50px;")}
  padding: 12px 16px !important;
  border-radius: 10px;
  border: 1px solid transparent;
  background: ${colors.linearGradientWhite} padding-box,
    ${(props) =>
        props.valid
          ? colors.errorColor
          : props.disabled
          ? colors.dark
          : props.isgradient
          ? colors.linearGradientOne
          : colors.primary}
      border-box;

  opacity: ${(props) => (props.disabled ? 0.3 : 1)};
  &:before {
    border: 0 !important;
  }
  &:after {
    border: 0 !important;
  }

  input {
    &::placeholder {
      font-weight: ${(props) => (props.isgradient ? 500 : 400)};
      font-size: 16px;
      ${(props) => (props.theme.dir === "rtl" ? "text-align: right;" : "")}
      line-height: ${(props) => (props.isgradient ? "160%" : "150%")};
      color: ${colors.dark};
      opacity: ${(props) => (props.isgradient ? 0.7 : 0.4)};
    }
  }

  @media ${device.tablet}, ${device.mobile} {
    ${(props) => (props.multiline ? "min-height: 46px;" : "height: 46px;")}

    input {
      &::placeholder {
        font-weight: 400;
        font-size: 14px;
      }
    }
  }
`;

export const LabelWrapper = styled(InputLabel)<Props>`
  font-family: "Mulish" !important;
  ${(props) => (props.theme.dir === "rtl" ? "text-align: right;" : "")}
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 10px !important;
  line-height: 160%;
  letter-spacing: 0.03em;
  text-transform: uppercase !important;
  color: ${(props) => (props.valid ? colors.errorColor : props.isWhite ? colors.white : colors.dark)} !important;
  margin-bottom: 4px;
  height: 20px;
`;
