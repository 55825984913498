import React from "react";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";

import { FooterWrapper } from "./footer.styled";
import FacebookIcon from "&assets/images/facebook.svg";
import InstagramIcon from "&assets/images/instagram.svg";
import TwitterIcon from "&assets/images/twitter.svg";
import LinkedInIcon from "&assets/images/linkedIn.svg";
import LogoImg from "&assets/images/logo.svg";
import ActionButton from "&components/buttons/actionButton/actionButton.component";
import SelectDropDown from "&components/selectDropDown/selectDropDown.component";
import { size } from "&assets/constants/responsiveness";
import { RootState } from "&store/store";
import { Setting } from "&features/global/global.type";
import { useNavigate } from "react-router-dom";
import { landingPageActions } from "&features/landingPage/landingPage.slice";
import { socialMediaLinks } from "&assets/constants/statics";
import { useTranslation } from "react-i18next";
import { translationSelector } from "&utils/translationSelector";
import { filter } from "lodash";

interface Props {}

const Footer = (props: Props) => {
  const isMobile = useMediaQuery({ maxWidth: size.mobile });
  const dispatch = useDispatch();
  const footerMenu = useSelector((state: RootState) => state.global?.settings?.footer_menu);
  const { t } = useTranslation(["landingPage"]);

  const countries = useSelector((state: RootState) => state.landingPage.countries);

  const selectedCountry = useSelector((state: RootState) => state.landingPage.selectedCountry);

  const navigate = useNavigate();

  const socials = () => {
    return (
      <div className="social-wrapper">
        <p className="title">{t("FOLLOW_US_ON_SOCIAL_MEDIA")}</p>
        <div className="social-btns">
          <a href={socialMediaLinks.facebook} target="_blank" rel="noopener noreferrer">
            <img id="facebook-icon" src={FacebookIcon} alt="facebook-icon" />
          </a>
          <a href={socialMediaLinks.instagram} target="_blank" rel="noopener noreferrer">
            <img id="instagram-icon" src={InstagramIcon} alt="instagram-icon" />
          </a>
          <a href={socialMediaLinks.linkedin} target="_blank" rel="noopener noreferrer">
            <img id="linkedIn-icon" src={LinkedInIcon} alt="linkedIn-icon" />
          </a>
          <a href={socialMediaLinks.twitter} target="_blank" rel="noopener noreferrer">
            <img id="twitter-icon" src={TwitterIcon} alt="twitter-icon" />
          </a>
        </div>
      </div>
    );
  };

  // const buildLevelsList = (countries: any) => {
  //   let returnObject = {};

  //   countries?.map((country: any) => {
  //     returnObject = {
  //       ...returnObject,
  //       [country?.value?.toString()]: translationSelector(country, "name"),
  //     };
  //   });

  //   return returnObject;
  // };

  const list = filter(countries, (country) => Number(country.value) !== Number(selectedCountry.id));

  return (
    <FooterWrapper>
      <div className="bottom-content">
        <img src={LogoImg} alt="logo" width="221px" height="58px" />
        <div className="actions">
          {footerMenu?.map((menu: Setting, index: number) => {
            return (
              <a href={`/${menu?.url}`} target="_blank" rel="noopener noreferrer">
                <ActionButton
                  key={index}
                  className="action-btn"
                  titleClassName="action-text"
                  title={translationSelector(menu, "name")}
                  // handleClick={() => navigate(`/${menu?.url}`)}
                />
              </a>
            );
          })}
        </div>
        <div className="socials">{!isMobile && socials()}</div>

        <div className="country">
          <div className="country-wrapper">
            <SelectDropDown
              value={selectedCountry}
              onSelect={(country: any) =>
                dispatch(
                  landingPageActions.setLandingPage({
                    countryChanged: true,
                    selectedCountry: country,
                  })
                )
              }
              inputClassName="drop-down-wrapper"
              listWrapperClassName="drop-down-list-wrapper"
              title={t("COUNTRY")}
              list={list}
              type="country"
              disabled={list.length <= 1}
            />
          </div>
        </div>

        {/* <div className="actions">
          <ActionButton
            className="action-btn"
            titleClassName="action-text-notification"
            title="Get job notifications"
          />
          <p className="subtitle">
            The latest jobs, courses, and articles sent
            <br />
            to your inbox weekly.
          </p>
          <div className="subscribe-content">
            <TextInput
              id="email-address-input-subscribe"
              placeholder="Email Address"
              className="email-input"
            />
            <SubmitButton
              id="subscribe-btn"
              title="Subscribe"
              handleClick={() => {}}
              className="subscribe-btn"
            />
          </div>
        </div> */}
      </div>
      <div className="socials">{isMobile && socials()}</div>
      <hr className="separator" />

      {/* {!isMobile && (
          <p className="rights">{t('ALL_RIGHTS_RESERVED')}</p>
        )} */}
      {/* <div className="informative">
          <p
            onClick={() => navigate("/privacy-policy")}
            className="more-actions"
          >
            {t('PRIVACY_POLICY')}
          </p>
          <p
            onClick={() => navigate("/terms-conditions")}
            className="more-actions"
          >
            {t('TERMS_AND_CONDITIONS')}
          </p>
        </div> */}
      {/* {isMobile && (
          <p className="rights">{t('ALL_RIGHTS_RESERVED')}</p>
        )} */}
      {/* </div> */}
    </FooterWrapper>
  );
};

export default Footer;
