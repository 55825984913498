/**
 * i18n landingPage namespace
 * Consists of English and arabic translations
 */
export const landingPageNameSpace = {
  en: {
    FOLLOW_OUR_STEPS_TO_GET_STARTED: "Follow our steps to get started",
    BY_FOLLOWING_OUR_STEPS_YOU_WILL_EASILY: "By following our steps you will easily get started in nammiskills",
    SELECT_YOUR_CAREER: "Select your desired career",
    COMPARE_YOUR_SKILLS: "Compare your skills with your desired career",
    GET_YOUR_MATCHING_SCORECARD: "Find the career that best matches your skills",
    LETS_GET_TO_KNOW_YOU: "Let's get to know you",
    YOUR_SKILLS: "Your skills",
    APPLY_FOR_FINANCIAL_SPONSORSHIP: "Apply for financial Sponsorship",
    NOT_SURE_WHICH_CAREER_TO_PICK: "Not sure which career to pick,",
    CLICK_HERE_TO_SIGN_UP: "click here to Sign Up",
    STEP: "Step",
    READ_MORE: "Read more",
    READ_LESS: "Read less",
    IN_PROGRESS: "In Progress",
    COMPLETED: "Completed",
    PENDING: "Pending",
    VIEW_ALL: "View all",
    DOWNLOAD_REPORT: "Download report",
    COMING_SOON: "Coming soon",
    SHOW_MORE: "Show more",
    COUNTRY: "Country",
    FOLLOW_US_ON_SOCIAL_MEDIA: "Follow us on social media",
    ALL_RIGHTS_RESERVED: "2023 @ nammiskills. All rights reserved.",
    PRIVACY_POLICY: "Privacy Policy",
    TERMS_AND_CONDITIONS: "Terms & Conditions",
    IN_COLLABORATION_WITH: "In collaboration with",
    UNLOCK_YOUR_POTENTIAL_WITH_ACCESS:
      "We believe everyone has the right to succeed! By providing access to the most in-demand digital skills, you can fulfill the jobs of the future globally.",
    FOUR_EASY_STEPS_TO_GUIDE_YOU: "Four easy steps to guide you through your career journey",
    SELECT_ONE_OF_THE_MOST_IN_DEMAND_CAREERS: "Select your career of interest and get started.",
    WE_LL_HELP_YOU_CHOOSE_THE_SPONSORED_LEARNINGS:
      "We will provide customized learning pathways for your career of choice and recommend other related careers based on your profile and skills. Careers are categorized based on the level of technical skills required",
    DONT_KNOW_WHICH_CAREER_YOU_WANT: "Unsure of the career you wish to pursue?",
    DO_NOT_WORRY_SIGN_UP_NOW:
      "Do not worry! Sign up now, add your skills, and we will match you to in-demand jobs that are a good fit for you.",
    SIGN_UP_NOW: "Sign Up Now",
    VIEW_CAREER_DETAILS: "View Career Details",
    START_ASSESSMENT: "Start Assessment",
    VIEW_ALL_CAREERS: "View All Careers",
    JOBS_AVAILABLE: "JOBS AVAILABLE",
    DRAG_DROP: "Drag and drop your files here",
    GOOD_NEWS: "Good news",
    COMPLETION_PROOF_REJECTED: "*Your completion proof has been rejected",
    DESCRIPTION_MESSAGE: `It seems that you’ve finished your enrolled course, to be able to
    continue your next course, you need to upload a screenshot or a PDF of
    the finalized course. The screenshot should clearly show your user identity and your name for it to be accepted`,
    SEND_FOR_APPROVAL: "Send for approval",
    SPONSORED_CERTIFICATIONS: "Sponsored Certifications",
    HERE: "here",
  },
  ar: {
    FOLLOW_OUR_STEPS_TO_GET_STARTED: "اتبع خطواتنا للبدء",
    BY_FOLLOWING_OUR_STEPS_YOU_WILL_EASILY: 'اتّبع خطواتنا لكي تستطيع التقدم على منصة "نمّي سكيلز" بسهولة',
    SELECT_YOUR_CAREER: "اختر المهنة التي تريدها",
    COMPARE_YOUR_SKILLS: "قارن مهاراتك مع المهنة التي تريدها",
    ASSESS_YOUR_SKILLS: "قيّم مهاراتك",
    GET_YOUR_MATCHING_SCORECARD: "ابحث عن المهنة الأكثر تناسبًا مع مهاراتك",
    LETS_GET_TO_KNOW_YOU: "فلنتعرف إليك",
    YOUR_SKILLS: "مهاراتك",
    APPLY_FOR_FINANCIAL_SPONSORSHIP: "التقدم بطلب للحصول على رعاية مالية",
    NOT_SURE_WHICH_CAREER_TO_PICK: "غير متأكد من المهنة التي سأختارها,",
    CLICK_HERE_TO_SIGN_UP: "انقر هنا للتسجيل",
    STEP: "خطوة",
    READ_MORE: "اقرأ المزيد",
    READ_LESS: "اقرأ أقل",
    IN_PROGRESS: "جاري",
    COMPLETED: "منجز",
    PENDING: "معلّق",
    VIEW_ALL: "اظهار الكل",
    DOWNLOAD_REPORT: "تحميل التقرير",
    COMING_SOON: "قريبا",
    FOLLOW_US_ON_SOCIAL_MEDIA: "تابعنا غلى مواقع التواصل الاجتماعي",
    SHOW_MORE: "أظهر المزيد",
    COUNTRY: "الدولة",
    ALL_RIGHTS_RESERVED: '2023 @ "نمّي سكيلز". كل الحقوق محفوظة',
    PRIVACY_POLICY: "سياسة الخصوصية",
    TERMS_AND_CONDITIONS: "الاحكام و الشروط",
    IN_COLLABORATION_WITH: "بالتعاون مع",
    UNLOCK_YOUR_POTENTIAL_WITH_ACCESS:
      "نحن نؤمن أن لكل شخص الحق في النجاح! من خلال مساعدتك على تطوير مهاراتك الرقمية، يمكنك الحصول على الوظائف الأكثر طلبًا في العالم",
    FOUR_EASY_STEPS_TO_GUIDE_YOU: "أربع خطوات سهلة لإرشادك خلال رحلتك المهنية",
    SELECT_ONE_OF_THE_MOST_IN_DEMAND_CAREERS: "Select your career of interest and get started.",
    WE_LL_HELP_YOU_CHOOSE_THE_SPONSORED_LEARNINGS:
      "سنقترح مسارات تعليمية شخصية تتناسب مع المهنة التي اخترتها، كما سنوصي بمهن أخرى ذات صلة بناءً على ملفك الشخصي ومهاراتك. يتم تصنيف الوظائف على أساس مستوى المهارات التقنية المطلوبة",
    DONT_KNOW_WHICH_CAREER_YOU_WANT: "هل أنت غير متأكد من المهنة التي ترغب في متابعتها؟",
    DO_NOT_WORRY_SIGN_UP_NOW: "لا تقلق! قم بالتسجيل الآن، أضف مهاراتك، وسنطابقك مع الوظائف المطلوبة التي تناسبك",
    SIGN_UP_NOW: "تسجل الآن",
    VIEW_CAREER_DETAILS: "عرض تفاصيل المهنة",
    START_ASSESSMENT: "ابدأ التقييم",
    VIEW_ALL_CAREERS: "اظهار كل المهن",
    JOBS_AVAILABLE: "فرص متاحة",
    DRAG_DROP: "قم بسحب ملفاتك وضعها هنا",
    GOOD_NEWS: "تهانينا",
    COMPLETION_PROOF_REJECTED: "لم يتم التأكد من اكمالك للدورة التدريبية",
    DESCRIPTION_MESSAGE:
      "تهانينا ! يبدو أنك قد انهيت الدورة التدريبية المسجلة، لتتمكن من متابعة دورتك التالية، تحتاج إلى تحميل لقطة شاشة أو ملف PDF لشهادة او رسالة اكمال الدورة. يجب أن تظهر لقطة الشاشة  هوية المستخدم الخاصة بك واسمك بوضوح حتى يتم قبولها",
    SEND_FOR_APPROVAL: "إرسال للموافقة",
    SPONSORED_CERTIFICATIONS: "الشهادات المدعومة",
    HERE: "هنا",
  },
};
