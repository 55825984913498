export const blogsNameSpace = {
  en: {
    BLOG: "Blog",
    BACK: "Back",
    POPULAR: "Popular",
    NAME: "Name",
    DATE: "Date",
    LATEST: "Latest",
    SORT_BY: "Sort by",
    SEARCH_BY_TITLE_OR_KEYWORDS: "Search by title or keywords",
  },
  ar: {
    // Write Arabic key-mapping here
    BLOG: "مدونة",
    BACK: "السابق",
    POPULAR: "الشعبية",
    NAME: "الاسم",
    DATE: "التاريخ",
    LATEST: "الاحدث",
    SORT_BY: "الترتيب بحسب",
    SEARCH_BY_TITLE_OR_KEYWORDS: "ابحث حسب العنوان أو الكلمات الرئيسية",
  },
};
