import React, { useState } from "react";

import { TestimonialWrapper } from "./testimonial.styled";
import RightArrow from "&assets/images/rightArrow.svg";
import LeftArrow from "&assets/images/leftArrow.svg";
import Quotes from "&assets/images/quotes.svg";
import DownQuotes from "&assets/images/down-quotes.svg";
import Container from "&components/container/container.component";
import {
  PageSection,
} from "&features/landingPage/landingPage.type";
import ImageDisplay from "&components/imageDisplay/imageDisplay.component";
import {translationSelector} from "&utils/translationSelector";

interface Props {
  data: PageSection;
}

const Testimonial = (props: Props) => {
  const { data } = props;
  const membersArray = data.sectionContents;
  const [index, setIndex] = useState(0);
  const message: any = membersArray[index];

  return (
    <TestimonialWrapper>
      <Container className="wrapper">
        <p className="title">{translationSelector(data, 'title')}</p>

        <div className="content">
          <ImageDisplay
            isFromS3={true}
            url={message?.image}
            alt="user"
            className="user-image"
          />
          <img src={Quotes} alt="quotes" className="quotes" />
          <img src={DownQuotes} alt="down-quotes" className="down-quotes" />
          <p className="name">
            {translationSelector(message,'title')?.split("|")[0].trim()}{" "}
            <span>| {translationSelector(message,'title')?.split("|")[1]?.trim()}</span>
          </p>
          <p className="message">{translationSelector(message, 'content')}</p>
          <div className="arrows">
            <img
              src={LeftArrow}
              alt="left-arrow"
              onClick={() =>
                index !== 0
                  ? setIndex(index - 1)
                  : setIndex(membersArray.length - 1)
              }
            />
            <img
              src={RightArrow}
              alt="right-arrow"
              onClick={() =>
                index !== membersArray.length - 1
                  ? setIndex(index + 1)
                  : setIndex(0)
              }
            />
          </div>
        </div>
      </Container>
    </TestimonialWrapper>
  );
};

export default Testimonial;
