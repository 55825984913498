import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { size } from "&assets/constants/responsiveness";
import LogoImg from "&assets/images/logo-white.svg";
import { useNavigate } from "react-router-dom";
import Slide from "@mui/material/Slide";
import i18n from "i18next";
import { TrackGoogleAnalyticsEvent } from "&utils/google-analytics/googleAnalytics";

import { ThankYouWrapper } from "./thankYou.styled";
import { LoginWrapper } from "&features/login/login.styled";
import Container from "&components/container/container.component";

const ThankYouComponent = () => {
  let navigate = useNavigate();

  const { t } = useTranslation(["login"]);
  const isMobile = useMediaQuery({ maxWidth: size.mobile });
  const isTablet = useMediaQuery({
    minWidth: size.mobile,
    maxWidth: size.tablet,
  });
  const dir = i18n.dir();

  useEffect(() => {
    TrackGoogleAnalyticsEvent({
      category: "Thank You",
      action: "thank_you",
      label: "thank you page",
    });
    setTimeout(() => {
      navigate("/dashboard");
    }, 10000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LoginWrapper>
      <Container>
        {!isMobile && !isTablet && (
          <img src={LogoImg} alt="logo" className="login-logo" onClick={() => navigate("/")} />
        )}
        <div className="content">
          <Slide direction={dir === "rtl" ? "right" : "left"} in={true} mountOnEnter unmountOnExit>
            <div className="auth-container">
              <ThankYouWrapper>
                <p className="thankyou-title">{t("THANKYOU_MESSAGE_TITLE")}</p>
                <p className="thankyou-subtitle">{t("THANKYOU_MESSAGE_SUBTITLE1")}</p>
                <p className="thankyou-subtitle">
                  {t("THANKYOU_MESSAGE_SUBTITLE2")}
                  <span
                    className="thankyou-click-here"
                    onClick={() => {
                      navigate("/dashboard");
                    }}
                  >
                    {t("THANKYOU_MESSAGE_SUBTITLE3")}
                  </span>
                </p>
              </ThankYouWrapper>
            </div>
          </Slide>
        </div>
      </Container>
    </LoginWrapper>
  );
};

export { ThankYouComponent };
