import React, { useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { SocialLinks } from "&features/dashboard/screens/profile/profile.type";
import {
  FormInputContent,
  UpdateProfileBody,
} from "&features/login/login.type";
import { ProfileModalInnerContent } from "&components/profile/edit/profileEditModel/profileEditModal.styled";
import inputSelector from "&utils/ui/inputSelector";
import { useTranslation } from "react-i18next";

interface Props {
  initialValues?: SocialLinks;
  submit?: number;
  updateProfile: (body: UpdateProfileBody) => void;
}

const ProfileAddSocialLinks = (props: Props) => {
  const { initialValues, submit, updateProfile } = props;

  const values: SocialLinks = {
    linkedin: initialValues?.linkedin || undefined,
    portfolio: initialValues?.portfolio || undefined,
    website: initialValues?.website || undefined,
  };
  const { t } = useTranslation(["profile"]);

  const validationSchema = Yup.object().shape({
    linkedin: Yup.string()
      ?.trim()
      .url(`${t("PLEASE_PROVIDE_A_VALID_URL")} https://linkedin.com/in/janedoe`)
      .notRequired(),
    portfolio: Yup.string()
      ?.trim()
      .url(`${t("PLEASE_PROVIDE_A_VALID_URL")} https://coolportfolio.com`)
      .notRequired(),
    website: Yup.string()
      ?.trim()
      .url(`${t("PLEASE_PROVIDE_A_VALID_URL")} https://coolwebsite.com`)
      .notRequired(),
  });

  const formik: any = useFormik({
    initialValues: values,
    validationSchema: validationSchema,
    onSubmit: () =>
      updateProfile({
        linkedin: formik.values.linkedin,
        portfolio: formik.values.portfolio,
        website: formik.values.website,
      }),
  });

  useEffect(() => {
    submit && submit > 0 && formik.handleSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submit]);

  const socialLinksInputGrid: FormInputContent[] = [
    {
      id: "linkedin-input",
      label: t("LINKEDIN_URL"),
      placeHolder: t("TYPE_YOUR_LINKEDIN_URL"),
      type: "text",
      value: formik.values?.linkedin,
      handleChange: (value) => formik.setFieldValue("linkedin", value),
      error: formik.touched.linkedin && formik.errors.linkedin,
    },
    {
      id: "portfolio-input",
      label: t("PORTFOLIO_URL"),
      placeHolder: t("TYPE_YOUR_PORTFOLIO_URL"),
      type: "text",
      value: formik.values?.portfolio,
      handleChange: (value) => formik.setFieldValue("portfolio", value),
      error: formik.touched.portfolio && formik.errors.portfolio,
    },
    {
      id: "website-input",
      label: t("WEBSITE_URL"),
      placeHolder: t("TYPE_YOUR_WEBSITE_URL"),
      type: "text",
      value: formik.values?.website,
      handleChange: (value) => formik.setFieldValue("website", value),
      error: formik.touched.website && formik.errors.website,
    },
  ];
  return (
    <ProfileModalInnerContent>
      <div className="input-grid">
        {socialLinksInputGrid?.map(
          (item: FormInputContent, index: number) =>
            !item.hide && inputSelector(item, index)
        )}
      </div>
    </ProfileModalInnerContent>
  );
};

export default ProfileAddSocialLinks;
