import React, { useEffect } from "react";
import { connect, ConnectedProps, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";
import { RootState } from "&store/store";
import { AboutWrapper } from "./about.styled";
import Container from "&components/container/container.component";
import { landingPageActions } from "&features/landingPage/landingPage.slice";
import AboutHeader from "&components/aboutHeader/aboutHeader.component";
import AboutGrid from "&components/aboutGrid/aboutGrid.component";
import AboutGridImageOne from "../../assets/images/about-grid-1.png";
import AboutGridImageFive from "../../assets/images/about-grid-5.png";
import AboutAims from "&components/aboutAims/aboutAims.component";
import Supporters from "&components/supporters/supporters.component";
import AboutNumbers from "&components/aboutNumbers/aboutNumbers.component";
import { images } from "&assets/constants/images-urls";
import getCDNProtectedURL from "&utils/getCDNProtectedImageSource";
import { colors } from "&assets/constants/colors";

export interface GridData {
  textBackground: string;
  textColor: string;
  gridTemplate: string;
  dir: string;
  text: string;
  imageUrl: any;
  fromS3: boolean;
}

type ReduxProps = ConnectedProps<typeof connector>;

const AboutComponent = (props: ReduxProps) => {
  const { homeContent, getPagesContent } = props;
  const content = homeContent?.sections;
  const { t } = useTranslation(["about"]);
  const user = useSelector((state: RootState) => state.login.user);

  useEffect(() => {
    if (isEmpty(homeContent)) {
      getPagesContent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const gridData: GridData[] = [
    {
      textBackground: colors.linearGradientSixth,
      textColor: "#fff",
      gridTemplate: "50% 50%",
      dir: "ltr",
      text: t("KNOWLEDGE_OF_THE_MOST"),
      imageUrl: AboutGridImageOne,
      fromS3: false,
    },
    {
      textBackground:
        "linear-gradient(56.4deg,#FFFFFF,#FFFFFF) padding-box, linear-gradient(56.4deg,#057ADD 8.08%,#108cd2 87.11%) border-box;",
      textColor: colors.primaryDark,
      gridTemplate: "75% 25%",
      dir: "rtl",
      text: t("SELF_ASSESSMENT"),
      imageUrl: images.aboutUs2,
      fromS3: true,
    },
    {
      textBackground: colors.linearGradientSixth,
      textColor: "#fff",
      gridTemplate: "50% 50%",
      dir: "ltr",
      text: t("ENHANCED_ACCESS"),
      imageUrl: images.aboutUs3,
      fromS3: true,
    },
    {
      textBackground:
        "linear-gradient(56.4deg,#FFFFFF,#FFFFFF) padding-box, linear-gradient(56.4deg,#057ADD 8.08%,#108cd2 87.11%) border-box;",
      textColor: colors.primaryDark,
      gridTemplate: "75% 25%",
      dir: "rtl",
      text: t("MATCHING_YOU"),
      imageUrl: images.aboutUs4,
      fromS3: true,
    },
    {
      textBackground: colors.linearGradientSixth,
      textColor: "#fff",
      gridTemplate: "50% 50%",
      dir: "ltr",
      text: t("SUPPORT_IN_IDENTIFYING"),
      imageUrl: AboutGridImageFive,
      fromS3: false,
    },
  ];

  const statisticsChildsBackgroundImages = {
    0: getCDNProtectedURL(images.aboutUs7),
    1: getCDNProtectedURL(images.interns),
    2: getCDNProtectedURL(images.studentsExposed),
    3: getCDNProtectedURL(images.engagedEducation),
    4: getCDNProtectedURL(images.engagedEmployers),
  };

  return (
    <AboutWrapper>
      {!isEmpty(homeContent) ? (
        <>
          <AboutHeader userName={user?.name} />
          <Container>
            {gridData.map(({ textColor, textBackground, gridTemplate, dir, text, imageUrl, fromS3 }) => (
              <AboutGrid
                textColor={textColor}
                fromS3={fromS3}
                textBackground={textBackground}
                gridTemplate={gridTemplate}
                dir={dir}
                text={text}
                imageUrl={imageUrl}
              />
            ))}
          </Container>
          <AboutAims />
          <AboutNumbers backgroundImages={statisticsChildsBackgroundImages} data={content.statistics[0]} />
          <Supporters data={content.contributors[0]} />
        </>
      ) : (
        <div style={{ height: "100vh" }}></div>
      )}
    </AboutWrapper>
  );
};

const mapStateToProps = (state: RootState) => ({
  homeContent: state.landingPage.homeContent,
});

const mapDispatchToProps = {
  getPagesContent: landingPageActions.getPagesContent,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const AboutComponentRedux = connector(AboutComponent);

export { AboutComponentRedux as AboutComponent };
