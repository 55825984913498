/**
 * i18n certifications namespace
 * Consists of English and arabic translations
 */
export const certificationsNameSpace = {
  en: {
    TOP_CERTIFICATIONS: "Top Earned Certifications",
    CERTIFICATION: "Certification",
    AVAILABLE_LEARNING_PATHS_TTILE: "Discover Our Learning Paths",
    ABOUT: "About This Certificate",
    BENEFITS: "Benefits",
    RESOURCES: "Resources",
    REQUIREMENTS: "Requirements",
    SKILLS: "Skills you gain",
    LEARNING_ACTIVITIES: "Learning Activities",
    NO_CERTIFICATES_FOUND: "No certificates found",
    DESCRIPTION_CERTIFICATION: 'Explore our top earned certifications! The most popular choices among learners, they offer great value and support your career advancement.',
    DESCRIPTION_CERTIFICATION_NOTE: 'Note: These certifications are available exclusively in English.',
    PROVIDERS: 'Providers',
    OVERVIEW : 'Overview',
  },
  ar: {
    TOP_CERTIFICATIONS: "الشهادات الأكثر  طلبا",
    CERTIFICATION: "شهادة",
    AVAILABLE_LEARNING_PATHS_TTILE: "اكتشف المسيرات التعليمية المختلفة",
    ABOUT: "حول هذه الشهادة",
    BENEFITS: "الفوائد",
    RESOURCES: "المصادر",
    REQUIREMENTS: "المتطلبات",
    SKILLS: "المهارات التي تكتسبها",
    LEARNING_ACTIVITIES: "النشاطات التعليمية",
    NO_CERTIFICATES_FOUND: "لم يتم العثور على شهادات",
    DESCRIPTION_CERTIFICATION: 'إكتشف الشهادات الأكثر  طلبا من قبل المتعلمين. تساعدك هذه الشهادات على التقدم المهني.',
    DESCRIPTION_CERTIFICATION_NOTE: 'ملاحظة: هذه الشهادات متاحة فقط باللغة الإنجليزية.',
    PROVIDERS: 'مقدمة من ',
    OVERVIEW : 'نظرة عامة',


  },
};
