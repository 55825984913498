import { device } from "&assets/constants/responsiveness";
import styled from "styled-components";
import { colors } from "&assets/constants/colors";

export const AboutNumbersWrapper = styled.div`
  margin-top: 110px;
  padding-bottom: 40px;
  .title {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 700;
    font-size: 64px;
    line-height: 110%;
    letter-spacing: -0.02em;
    color: ${colors.primaryDark};
    text-align: center;
  }
  .statistics {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    text-align: center;
  }

  .statistics-title {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 120%;
    color: #ffffff;
    text-align: center;
    margin-top: 50px;
    width: 100%;
  }
  @media ${device.mobile} {
    .title {
      font-size: 48px;
      padding: 0 16px;
      line-height: 110%;
    }
    .statistics-block {
      width: 100%;
    }
  }
`;

interface StatisticsBlock {
  index: number;
  backgroundImages: any;
}
export const StatisticsBlock = styled.div<StatisticsBlock>`
  background: ${colors.primary};
  height: 310px;
  width: 20%;
  min-width: 250px;
  padding: 40px 0;
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${(props) =>
    props.backgroundImages && props.backgroundImages[props.index] === null ? colors.primary : ""};
  background-image: ${(props) =>
    props.backgroundImages[props.index] !== null ? `url(${props.backgroundImages[props.index]})` : ""};
  background-repeat: no-repeat;
  background-size: cover;
  filter: saturate(150%);
  position: relative;
  &::before {
    content: "";
    background: #0098e190;
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  @media ${device.mobile} {
    margin: auto;
  }
`;
