import styled from "styled-components";
import { careerLevelsBackground, colors } from "&assets/constants/colors";
import { device } from "&assets/constants/responsiveness";
import { Modal } from "@mui/material";

interface Props {
  vertical?: boolean;
  showAll?: boolean;
  reverse?: boolean;
}

export const OptionCardWrapper = styled.div<Props>`
  border: 0;
  background-color: inherit;
  min-height: 128px;
  min-width: 228px;
  position: relative;

  ${(props) => (props.reverse ? "direction: rtl !important;" : "")}
  ${(props) => (props.theme.dir === "rtl" ? "margin-left: 5px;" : "margin-right: 5px;")}

  @media ${device.desktop}, ${device.inBetween} {
    margin-bottom: ${(props) => (props.vertical || props.showAll ? "16px" : "initial")};
    ${(props) =>
      props.vertical || props.showAll
        ? props.theme.dir === "rtl"
          ? "margin-left: 16px;"
          : "margin-right: 16px;"
        : ""};
    &:last-child {
      margin-bottom: ${(props) => (props.showAll ? "16px" : "0")};
    }
  }

  @media ${device.tablet} {
    &:first-child {
      ${(props) =>
        props.showAll && props.vertical ? "" : props.theme.dir === "rtl" ? "margin-right: 0px;" : "margin-left: 0px;"}
    }
    ${(props) =>
      props.showAll && props.vertical ? "" : props.theme.dir === "rtl" ? "margin-left: 15px;" : "margin-right: 15px;"}

    min-width: ${(props) => (props.vertical && props.showAll ? "296px" : "228px")};

    ${(props) => (props.showAll && props.vertical ? "margin: 7px 0;" : "")}
  }

  @media ${device.mobile} {
    &:first-child {
      ${(props) =>
        props.showAll && props.vertical ? "" : props.theme.dir === "rtl" ? "margin-right: 0px;" : "margin-left: 0px;"}
    }
    ${(props) =>
      props.showAll && props.vertical ? "" : props.theme.dir === "rtl" ? "margin-left: 15px;" : "margin-right: 15px;"}

    min-width: ${(props) => (props.vertical && props.showAll ? "296px" : "228px")};

    ${(props) => (props.showAll && props.vertical ? "margin: 7px 0;" : "")}
  }
`;

interface PropsFront {
  show?: boolean;
  long?: boolean;
  level?: string;
  index: number;
}

export const OptionCardFrontWrapper = styled.div<PropsFront>`
  position: absolute;
  max-width: 234px;
  min-width: 234px;
  height: 128px;
  box-sizing: border-box;
  opacity: ${(props) => (props.show ? "1" : "0")};
  visibility: ${(props) => (props.show ? "visible" : "hidden")};
  transition: opacity 0.5s ease-in-out;
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  z-index: 4;
  border: 1px solid transparent;
  top: 0;
  ${(props) => (props.theme.dir === "rtl" ? "right: 0;" : "left: 0;")}
  border-radius: 16px;
  background: ${colors.linearGradientWhite} padding-box, ${colors.linearGradientOne} border-box;

  .level-card-tag {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 20px;
    padding: 4px 8px;
    box-sizing: border-box;

    ${(props) => (props.theme.dir === "rtl" ? "left: 0;" : "right: 0;")}
    ${(props) => (props.theme.dir === "rtl" ? "border-radius: 0px 6px 6px 0px;" : "border-radius: 6px 0px 0px 6px;")}
    
    background: ${(props) => (props?.level ? careerLevelsBackground[props.index] : careerLevelsBackground[0])};

    .career-name {
      color: ${(props) => (props?.level !== "Beginner" ? colors.white : colors.primary)};
      font-family: "Clash Grotesk";
      font-size: 9px;
      margin: 0;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 13.5px */

      ${(props) => (props.theme.dir === "rtl" ? "" : "letter-spacing: 1.8px;")}
      text-transform: uppercase;
    }
  }

  @media ${device.mobile}, ${device.tablet} {
    min-width: ${(props) => (props.long ? "296px" : "234px")};
  }
`;

export const DescriptionModal = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;

  .MuiModal-backdrop {
    background-color: white !important;
    opacity: 0.8 !important;
  }
`;

export const DescriptionModalWrapper = styled.div`
  width: 90vw;
  max-height: 90vh;
  border-radius: 16px !important;
  overflow-y: auto;

  .card-buttons-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 16px;

    .view-career-button {
      background: transparent;
      border-radius: 30px;
      border: 1px solid ${colors.white};
      display: flex;
      padding: 8px 10px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      align-self: stretch;
    }

    .view-career-button-text {
      color: ${colors.white};
      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 800;
      line-height: 160%; /* 20.8px */
    }

    .start-assessment-button {
      background: ${colors.white};
      display: flex;
      padding: 8px 10px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      align-self: stretch;
    }

    .start-assessment-button-text {
      color: ${colors.secondaryDark};
      text-align: center;
      font-family: Mulish;
      font-size: 12px;
      font-style: normal;
      font-weight: 800;
      line-height: 160%; /* 20.8px */
    }
  }

  @media ${device.tablet} {
    width: 60vw;
    .image {
      height: 183px !important;
    }
  }
  @media ${device.mobile} {
    width: 90vw;
    .image {
      height: 183px !important;
    }
  }
`;

export const IconPlayButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-direction: flex-start;

  .play-button {
    border: 0;
    padding: 0;
    ${(props) => (props.theme.dir === "rtl" ? "transform: scaleX(-1);" : "")}
    background: inherit;
    display: none;

    @media ${device.tablet} {
      display: initial;
    }
    @media ${device.mobile} {
      display: initial;
    }
  }
`;

export const TitleJobsWrapper = styled.button`
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  flex: 1;
  background: transparent;
  border: 0;
`;

export const CircleIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .icon-image {
    max-width: 28px;
    max-height: 28px;
  }
`;

export const TitleArrowWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
  width: 100%;
  justify-content: space-between;

  .title {
    font-weight: 700;
    font-size: 14px;
    line-height: 110%;
    color: ${colors.dark};

    ${(props) => (props.theme.dir === "rtl" ? "text-align: right;" : "text-align: left;")}
    ${(props) => (props.theme.dir === "rtl" ? "margin-left: 10px;" : "margin-right: 10px;")}
  }

  svg {
    min-width: 24px;
    ${(props) => (props.theme.dir === "rtl" ? "transform: rotate(180deg);" : "")}
  }

  &.back {
    color: white;
  }

  /* @media ${device.tablet} {
    ${(props) => (props.theme.dir === "rtl" ? "flex-direction: row-reverse;" : "flex-direction: row;")}
  }
  @media ${device.mobile} {
    ${(props) => (props.theme.dir === "rtl" ? "flex-direction: row-reverse;" : "flex-direction: row;")}
  } */
`;

export const AvailableJobsWrapper = styled.div`
  font-size: 12px;
  margin-top: 2px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  display: flex;
  align-items: flex-start;
  .number-text {
    font-weight: 800;
    color: ${colors.primary};
    ${(props) => (props.theme.dir === "rtl" ? "margin-left: 5px;" : "margin-right: 5px;")}
  }

  .text {
    font-weight: 600;
    color: ${colors.dark};
    opacity: 0.6;
  }
`;

interface PropsBack {
  show?: boolean;
  vertical?: boolean;
  shouldLower?: boolean;
  showAll?: boolean;
}

export const OptionCardBackWrapper = styled.div<PropsBack>`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  z-index: 1000;
  top: ${(props) => (props.vertical ? "-145px" : props.shouldLower ? "0" : "-75px")};
  ${(props) => (props.theme.dir === "rtl" ? "right: 0;" : "left: 0;")}
  opacity: ${(props) => (props.show ? "1" : "0")};
  visibility: ${(props) => (props.show ? "visible" : "hidden")};
  transition: opacity 0.5s ease-in-out;
  max-width: 380px;
  min-width: 380px;
  min-height: 165px;
  padding: 0 !important;
  box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  overflow: hidden;

  .card-buttons-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 16px;

    .view-career-button {
      background: transparent;
      border-radius: 30px;
      border: 1px solid ${colors.white};
      display: flex;
      padding: 8px 16px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      align-self: stretch;
    }

    .view-career-button-text {
      color: ${colors.white};
      text-align: center;
      font-size: 13px;
      font-style: normal;
      font-weight: 800;
      line-height: 160%; /* 20.8px */
    }

    .start-assessment-button {
      background: ${colors.white};
      display: flex;
      padding: 8px 16px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      align-self: stretch;
    }

    .start-assessment-button-text {
      color: ${colors.secondaryDark};
      text-align: center;
      font-family: Mulish;
      font-size: 13px;
      font-style: normal;
      font-weight: 800;
      line-height: 160%; /* 20.8px */
    }
  }

  @media ${device.tablet} {
    display: none;
  }
  @media ${device.mobile} {
    display: none;
  }
`;

interface VideoDescriptionProps {
  increaseHeight?: boolean;
}

export const VideoDescriptionWrapper = styled.div<VideoDescriptionProps>`
  .close-icon-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 15px;

    .close-icon {
      display: flex;
      align-items: center;
      padding: 0;
      justify-content: center;
      width: 32px;
      height: 32px;
      border-radius: 20px;
      box-sizing: border-box;
      box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
      border: 1px solid transparent;
      background: ${colors.linearGradientWhite} padding-box, ${colors.linearGradientOne} border-box;
    }
  }

  .image {
    width: 100%;
    height: 180px;
    margin-bottom: -1px;
    //z-index: 4;

    .watch-video-text {
      margin-top: 5px;
      font-weight: 800;
      font-size: 12px;
      line-height: 160%;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: ${colors.white};
      text-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
    }
  }

  .title-description-wrapper {
    width: 100%;
    display: flex;
    ${(props) => (props.increaseHeight ? "min-height: 153px;" : "")}
    box-sizing: border-box;
    //flex-grow: 1;
    flex-direction: column;
    padding: 16px 24px;
    border-radius: 0 0 16px 16px;
    border: 1px solid transparent;
    background: ${colors.linearGradientThree} padding-box, ${colors.linearGradientOne} border-box;
  }
  .description {
    margin-top: 10px;
    ${(props) => (props.theme.dir === "rtl" ? "text-align: right;" : "text-align: left;")}
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.03em;
    line-height: 160%;
    color: ${colors.white};
  }
  .image-button-wrapper {
    background: inherit;
    cursor: pointer;
    width: 100%;
    height: 100%;
    border: 0;

    &:hover {
      opacity: 0.7;
    }
  }
`;
