export const examDetailsNameSpace = {
  en: {
    RELATED_LEARNING_PATHS: "Related Learning Paths",
    VIEW_ALL_LEARNING_PATHS: "View all learning paths",
    MATCHING_OPPORTUNITIES: "Matching opportunities",
    AVAILABLE_OPPORTUNITIES: "Available opportunities",
  },
  ar: {
    RELATED_LEARNING_PATHS: "المسارات التعليمية ذات الصلة",
    VIEW_ALL_LEARNING_PATHS: "عرض جميع المسارات التعليمية",
    MATCHING_OPPORTUNITIES: "فرص المطابقة",
    AVAILABLE_OPPORTUNITIES: "فرص متاحة",
  },
};
