import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useTranslation } from "react-i18next";

import { RootState } from "&store/store";

import { contactUsActions } from "./contactUs.slice";
import { ContactUsWrapper } from "./contactUs.styled";
import PageHeader from "&components/pageHeader/pageHeader.component";
import InformativeSection from "&components/informativeSection/informativeSection.component";
import ContactUs from "&components/contactUs/contactUs.component";
import Container from "&components/container/container.component";
import ImageDisplay from "&components/imageDisplay/imageDisplay.component";
import { informativeData } from "&assets/constants/statics";
import { translationSelector } from "&utils/translationSelector";
import { Helmet } from "react-helmet";

type ReduxProps = ConnectedProps<typeof connector>;

const ContactUsComponent = (props: ReduxProps) => {
  const { getPageContent, content } = props;
  const { t } = useTranslation(["contactUs"]);

  useEffect(() => {
    getPageContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ContactUsWrapper>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <PageHeader
        title={t("CONTACT_US")}
        subTitle={translationSelector(content, "subTitle")}
        className="header"
        titleClassName="header-title"
        titleSubtitleWrapper="title-subtitle-wrap"
      />
      <Container className="container-content">
        <ImageDisplay isFromS3={true} url={content?.image} alt="" className="location-image" />
      </Container>
      <InformativeSection data={informativeData} className="informatives" />
      <ContactUs />
    </ContactUsWrapper>
  );
};

const mapStateToProps = (state: RootState) => ({
  content: state.contactUs.content,
});

const mapDispatchToProps = {
  getPageContent: contactUsActions.getPageContent,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const ContactUsComponentRedux = connector(ContactUsComponent);

export { ContactUsComponentRedux as ContactUsComponent };
