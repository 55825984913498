import { colors } from "&assets/constants/colors";
import { device } from "&assets/constants/responsiveness";
import styled from "styled-components";

export const DashboardListingWrapper = styled.div`
  width: 100%;
  margin-top: 34px;
  .list-wrapper {
    display: grid;
    grid-template-columns: auto auto auto;
    justify-content: flex-start;
    margin-bottom: 34px;
    .matching-opportunities-card {
      margin-top: 47px;
      ${(props) =>
              props.theme.dir === "rtl"
                      ? "margin-left: 16px;"
                      : "margin-right: 16px;"}
    }
    .learning-path {
      width: 336px;
    }
    .course-cards {
      width: 96%;
      ${(props) =>
              props.theme.dir === "rtl"
                      ? "margin-left: 16px;"
                      : "margin-right: 16px;"}
      margin-bottom: 16px;
    }
  }
  .view-all-button {
    background: transparent;
    border: 1px solid ${colors.primary};
    width: 160px;
    height: 38px;
    justify-content: center;
    margin: auto;
  }
  .view-all-button-title {
    font-weight: 800;
    font-size: 14px;
    line-height: 160%;
    text-align: center;
    color: ${colors.primary};
  }

  @media ${device.inBetween} {
    .list-wrapper {
      grid-template-columns: auto auto auto;
    }
  }

  @media ${device.tablet} {
    .list-wrapper {
      display: block;
      padding: 0;
      margin-bottom: 24px;
      .learning-path {
        min-width: 296px;
      }
    }
  }

  @media ${device.mobile} {
    .list-wrapper {
      display: block;
      padding: 0;
      margin-bottom: 24px;
      .learning-path {
        min-width: 296px;
      }
    }
  }
`;
