
import React from "react";
import { CircularLoaderWrapper } from "./circularLoader.styled";
import {CircularProgress} from "@mui/material";

interface Props {
  className?: string;
}

const CircularLoader = (props: Props) => {
  const { className } = props;
  return (
    <CircularLoaderWrapper className={className}>
        <CircularProgress color="primary" />
    </CircularLoaderWrapper>
  );
};

export default CircularLoader;
