import React from "react";
import { CircularProgress } from "@mui/material";

import { ActionButtonWrapper } from "./actionButton.styled";

interface Props {
  title?: string;
  className?: string;
  titleClassName?: string;
  handleClick?: Function;
  disabled?: boolean;
  loading?: boolean;
}

const ActionButton = (props: Props) => {
  const { title, className, titleClassName, handleClick, disabled, loading } =
    props;
  return (
    <ActionButtonWrapper
      className={className}
      onClick={() => handleClick && handleClick()}
      disabled={disabled}
    >
      {loading ? (
        <CircularProgress size={24} color={"inherit"} />
      ) : (
        <p className={`title ${titleClassName ? titleClassName : ""}`}>
          {title}
        </p>
      )}
    </ActionButtonWrapper>
  );
};

export default ActionButton;
