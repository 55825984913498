import { API } from "aws-amplify";
import { RES_JOBS } from "&store/resources";
import { definedObjectBuilder } from "&utils/definedObjectBuilder";
import { GetPageListingQuery } from "&features/listingPage/listingPage.type";

const getAllJobsApi = async (body: GetPageListingQuery, isLoggedIn?: boolean) => {
  let URL = isLoggedIn ? `/auth${RES_JOBS}` : RES_JOBS;
  const myInit: any = {
    queryStringParameters: definedObjectBuilder(body),
  };
  return API.get(process.env.REACT_APP_NAME || "", URL, myInit);
};

const getAllJobsExternalApi = async (body: GetPageListingQuery) => {
  let URL = `${RES_JOBS}/linkedin/scraper`;
  const myInit: any = {
    queryStringParameters: definedObjectBuilder(body),
  };
  return API.get(process.env.REACT_APP_NAME || "", URL, myInit);
};

const getFeaturedJobsApi = async ({
  params,
  ids,
}: {
  params: GetPageListingQuery;
  ids: string[];
}) => {
  const { countryId, dir, limit, page, sort } = params;
  const concatenatedIds = ids?.map(id => `careerIds[]=${id}`).join('&');
  let URL = `${RES_JOBS}/top/careers?${concatenatedIds}&limit=${limit}&page=${page}&countryId=${countryId}&sort=${sort}&dir=${dir}`;

  return API.get(process.env.REACT_APP_NAME || "", URL, {});
};

export { getAllJobsApi, getAllJobsExternalApi, getFeaturedJobsApi };
