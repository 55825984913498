import i18n from "&config/i18n";
import moment, { Moment } from "moment";

const dateDifferenceFormat = (startDate: Moment, endDate: Moment) => {
  const isArabic = i18n.language === "ar";
  let returnStartDate = moment(startDate);
  let returnEndDate = moment(endDate);

  const years = returnEndDate.diff(returnStartDate, "year");
  returnStartDate?.add(years, "years");

  const months = returnEndDate.diff(returnStartDate, "months");
  returnStartDate?.add(months, "months");

  const days = returnEndDate.diff(returnStartDate, "days");
  returnStartDate?.add(days, "days");

  return `${
    years > 0
      ? years + `${isArabic ? " سنوات" : "y"}` + (months > 0 ? " " : "")
      : ""
  }${
    months > 0
      ? (years > 0 ? " " : "") + months + `${isArabic ? " اشهر" : "m"}`
      : days > 0
      ? days + `${isArabic ? " ايام" : "d"}`
      : ""
  }`;
};

export default dateDifferenceFormat;
