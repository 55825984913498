import LinkedInIcon from "&assets/images/linkedInBlue.svg";
import Microsoft from "&assets/images/microsoft.svg";
import Logo from "&assets/images/logo.svg";
import Simplilearn from "&assets/images/simplilearn-solutions-logo.png";
import AWS from "&assets/images/aws.png";
import JFL from "&assets/images/jfl.svg";
import dotLogo from "&assets/images/dotlogo.png";
import SmsIcon from "&assets/images/sms.svg";
import LocationIcon from "&assets/images/location.svg";
import SupportIcon from "&assets/images/24-support.svg";
import BDDIcon from "&assets/images/bdd.png";

import { ListingPageSearchParams } from "&features/listingPage/listingPage.type";
import { OpportunitiesSearchParams } from "&features/opportunitiesListing/opportunitiesListing.type";
import { CareersSearchParams } from "&features/careerListing/careerListing.type";
import { translationSelector } from "&utils/translationSelector";

export const sourceLogos: any = {
  linkedin: LinkedInIcon,
  microsoft: Microsoft,
  nammiskills: Logo,
  aws: AWS,
  simplilearn: Simplilearn,
  jobsforlebanon: JFL,
  dot: dotLogo,
  bdd: BDDIcon,
};

export const defaultListingPageSearchParams: ListingPageSearchParams | any = {
  page: 1,
  level: "0",
  source: "0",
  learning_activity: "learning_path",
  sort_by: "popular",
  query: "",
  type: "all",
};

export const defaultOpportunitiesSearchParams: OpportunitiesSearchParams | any = {
  page: 1,
  location: "all",
  career: "",
  sort_by: "createdAt",
  skills: "select",
  query: "",
  source: "nammiskills",
  job_source: "all",
};

export const defaultCareersSearchParams: CareersSearchParams | any = {
  sort_by: "",
  level: "0",
  query: "",
};

export const fourEasyStepsContent: any[] = [
  {
    description: "Select your desired career and fill in your profile and skills",
    descriptionAr: "حدد المهنة التي تريد واملأ ملفك الشخصي ومهاراتك",
  },
  {
    description: "Get personalized learning recommendations",
    descriptionAr: "احصل على توصيات تعليمية شخصية",
  },
  {
    description: "Enroll in sponsored courses from global tech companies",
    descriptionAr: "قم بالتسجيل في دورات مقدمة من قبل أهم شركات التكنولوجيا العالمية",
  },
  {
    description: "Search and apply for in-demand jobs.",
    descriptionAr: "ابحث وتقدم للوظائف الأكثر طلباً",
  },
];

export const ERROR_TEXTS: any = (errorObject?: any) => {
  return {
    REPORT_NOT_VALID: "please complete all pending exams before claiming a new voucher",
    REPORT_NOT_VALIDAr: "يرجى إكمال جميع الاختبارات المعلقة قبل المطالبة بقسيمة جديدة",
    ERROR_ALREADY_CLAIMED: "Voucher already claimed",
    ERROR_ALREADY_CLAIMEDAr: "لقد تم طلب القسيمة من قبل",
    WRONG_USERNAME_OR_EMAIL: "Invalid username or password",
    WRONG_USERNAME_OR_EMAILAr: "خطأ في اسم المستخدم أو كلمة مرور",
    Invalid_sign_up: "Email already exists",
    Invalid_sign_upAr: "البريد الالكتروني موجود بالفعل",
    Password_is_too_weak:
      "Password should be minimum 6 characters and include at least one upper case, one number and one special character",
    Password_is_too_weakAr:
      "يجب أن تتكون كلمة المرور من 6 أحرف على الأقل وتتضمن حرفًا كبيرًا واحدًا ورقمًا واحدًا وحرفًا خاصًا واحدًا على الأقل",
    ERROR_NO_MORE_VOUCHERS: "Sorry! There are no available vouchers at this moment. Please try again later.",
    ERROR_NO_MORE_VOUCHERSAr: "عذرا! لم يعد هناك قسائم متوفرة حاليا. يرجى المحاولة لاحقا.",
    NO_SPONSORSHIP_POOLS_FOR_THIS_USER:
      "Sorry! There are no available vouchers at this moment. Please try again later.",
    NO_SPONSORSHIP_POOLS_FOR_THIS_USERAr: "عذرا! لم يعد هناك قسائم متوفرة حاليا. يرجى المحاولة لاحقا.",
    COMPLETE_AT_LEAST_ONE_COURSE: "You need to complete at least one free course to claim your sponsorship.",
    COMPLETE_AT_LEAST_ONE_COURSEAr: "يجب عليك إكمال دورة مجانية واحدة على الأقل للمطالبة برعايتك.",
    ALREADY_SUBSCRIBED: "You have aleady applied to this sponsorship.",
    ALREADY_SUBSCRIBEDAr: "لقد تقدمت بالفعل بطلب للحصول على هذه الرعاية.",
    FILL_ELIGIBILITY_FORM:
      "To get sponsored, you need to fill the sponsorship eligibility application and wait for approval.",
    FILL_ELIGIBILITY_FORMAr: "للحصول على الرعاية، يجب عليك ملء طلب أهلية الرعاية وانتظار الموافقة.",
    NO_PLACE: "There are no seats currently available. Please try again later",
    NO_PLACEAr: "لم يعد هناك اماكن متاحة. يرجى المحاولة لاحقا",
    PLEASE_FILL_THE_ELIGIBILITY_FORM: "To Enroll, you need to fill the sponsorship eligibility application.",
    PLEASE_FILL_THE_ELIGIBILITY_FORMAr: "للتسجيل، تحتاج إلى ملء طلب أهلية الرعاية.",
    ELIGIBILITY_FORM_PENDING:
      "Your sponsorship eligibility application is still pending, awaiting approval from admin to continue.",
    ELIGIBILITY_FORM_PENDINGAr: "لا يزال طلب أهلية الرعاية الخاص بك معلقًا، في انتظار موافقة المشرف للمتابعة.",
    ELIGIBILITY_FORM_REJECTED:
      "Your sponsorship eligibility application is rejected, please resubmit it and wait for admin approval.",
    ELIGIBILITY_FORM_REJECTEDAr: "تم رفض طلب أهلية الرعاية الخاص بك، يرجى إعادة تقديمه وانتظار موافقة المشرف.",
    USER_ALREADY_ENROLLED_WAITING_ASSESSMENT:
      "You have enrolled in this learning path, you can start with once the results of the pre-assessment are received.",
    USER_ALREADY_ENROLLED_WAITING_ASSESSMENTAr:
      "لقد قمت بالتسجيل في هذا المسار التعليمي، يمكنك البدء به بمجرد استلام نتائج التقييم المسبق.",
    NO_MATCHING_POOLS: "There are no seats currently available. Please try again later",
    NO_MATCHING_POOLSAr: "لم يعد هناك اماكن متاحة. يرجى المحاولة لاحق",
    ASSESSMENT_PENDING:
      errorObject && errorObject["ASSESSMENT_PENDING"]
        ? `Thank you for submitting your assessment for this activity. Your answers are still under review and you will be notified once the results are ready. ${errorObject["ASSESSMENT_PENDING"]}`
        : "",
    ASSESSMENT_PENDINGAr:
      errorObject && errorObject["ASSESSMENT_PENDINGAr"]
        ? `شكراً لتعبئة التقييم الخاص بهذا النشاط. إجابتك ما زالت قيد المراجعة و سوف نعلمك فور ظهور النتيجة. ${errorObject["ASSESSMENT_PENDINGAr"]}`
        : "",
    ASSESSMENT_SHORTLISTED_PENDING_ACCEPTED:
      "Congratulations, you have successfully passed our assessment. Our team will get in touch with you soon to schedule the next steps",
    ASSESSMENT_SHORTLISTED_PENDING_ACCEPTEDAr:
      "مبروك، لقد اجتزت التقييم بنجاح. سوف يتواصل فريقنا معك قريبا لاخبارك بالخطوة التالية",
    ASSESSMENT_APPROVED_DID_NOT_START:
      "Congratulations, your seat for this activity has been reserved. However, this activity is not yet scheduled to start. Make sure to check the details of this activity to know when it is set to start!",
    ASSESSMENT_APPROVED_DID_NOT_STARTAr:
      "مبروك, تم حجز مقعدك لهذا النشاط. لكن، موعد البدء غير محدد بعد . تأكد من الاطلاع على تفاصيل هذا النشاط  لمعرفة تاريخ بدئه",
    ASSESSMENT_NOT_SHORTLISTED_OR_REJECTED: "Application Closed",
    ASSESSMENT_NOT_SHORTLISTED_OR_REJECTEDAr: "تم إغلاق الطلب",
  };
};

export const bannerContent: any = {
  0: {
    title: "Did you know?",
    titleAr: "هل كنت تعلم؟",
    description:
      "By applying for sponsorship, nammiskills will give you free access to paid exams from top technology partners.",
    descriptionAr:
      'من خلال التقدم بطلب للحصول على الرعاية، ستمنحك منصة "نمّي سكيلز" إمكانية الوصول مجانًا إلى الاختبارات المدفوعة من أفضل شركاء التكنولوجيا.',
    button: "Apply now",
    buttonAr: "تقدم الآن",
  },
  1: {
    title: "You are one step away from your free certification!",
    titleAr: "You are one step away from your free certification!",
    description:
      "We are currently validating your sponsorship application. You will be notified once your application is approved to start accessing your free exams or subscriptions.",
    descriptionAr:
      "We are currently validating your sponsorship application. You will be notified once your application is approved to start accessing your free exams or subscriptions.",
    button: "Explore nammiskills",
    buttonAr: 'اكتشف "نمّي سكيلز"',
  },
  2: {
    title: "Congratulations! You have been sponsored",
    titleAr: "Congratulations! You have been sponsored",
    description:
      "You are now officially eligible and can start benefiting from our sponsorships to get free access to paid exams and subscriptions. Make sure to claim your sponsorship once you reach an exam activity in your learning path or a course that requires a paid subscription. Hurry up! The seats are limited. ",
    descriptionAr:
      "You are now officially eligible and can start benefiting from our sponsorships to get free access to paid exams and subscriptions. Make sure to claim your sponsorship once you reach an exam activity in your learning path or a course that requires a paid subscription. Hurry up! The seats are limited. ",
    button: "Explore nammiskills",
    buttonAr: 'اكتشف "نمّي سكيلز"',
  },
  3: {
    title: "Feedback on your sponsorship application",
    titleAr: "Feedback on your sponsorship application",
    description:
      "Your profile does not meet the criteria that we have set for receiving sponsorship to paid exams and subscriptions. Did you know? nammiskills has many free courses that you can benefit from even if you do not get a sponsorship! You can also re-apply for sponsorship if the information that you provided was incomplete. You can also check the details for your previous application by clicking here.",
    descriptionAr:
      "لم يتطابق ملفك الشخصي مع المعايير التي حددناها لتلقي الرعاية المالية للامتحانات والاشتراكات المدفوعة",
    button: "View Application",
    buttonAr: "تصفح الطلب",
  },
};

export const LINKEDIN_URL =
  "https://www.linkedin.com/checkpoint/enterprise/login/145983921?application=learning&redirect=";

export const MICROSOFT_API_KEY = "forwardmena_website_highered";

export const eligibilityFormTexts: any = (
  status: "description" | "buttonText",
  type: "approved" | "rejected" | "pending" | "initial",
  reason?: string
) => {
  const texts = {
    description: {
      approved: "Your application is successfully reviewed and you are now eligible for sponsorship",
      approvedAr: "تمت مراجعة طلبك بنجاح، وأنت الآن مؤهل للحصول على الرعاية",
      rejected: `Your sponsorship has been rejected ${reason ? `for the following reason: \n` : ""}${
        reason ? ` \n${reason}` : ""
      }`,
      rejectedAr: `تم رفض رعايتك  ${reason ? `للسبب التالي: \n` : ""}${reason ? ` \n${reason}` : ""}`,
      pending: "Your application is under review",
      pendingAr: "طلبك قيد المراجعة",
      initial: "Get sponsored to receive free access to the most popular certifications, exams, and subscriptions!",
      initialAr: "احصل على رعاية للحصول المجاني على الشهادات والامتحانات والاشتراكات الأكثر شيوعًا!",
    },
    buttonText: {
      approved: "View",
      approvedAr: "عرض",
      rejected: "Update",
      rejectedAr: "تحديث",
      pending: "View",
      pendingAr: "عرض",
      initial: "Apply Now",
      initialAr: "تقدم الآن",
    },
  };

  return translationSelector(texts[status], type);
};

export const socialMediaLinks = {
  instagram: "https://www.instagram.com/forward.mena/",
  facebook: "https://www.facebook.com/Forward.mena/",
  linkedin: "https://www.linkedin.com/company/forward-mena/",
  twitter: "https://twitter.com/ForwardMena/",
};

export const informativeData = [
  {
    title: "Email Us",
    titleAr: "راسلنا عبر البريد الإلكتروني",
    description: "info@nammiskills.org",
    descriptionAr: "info@nammiskills.org",
    icon: SmsIcon,
  },
  {
    title: "Address",
    titleAr: "العنوان",
    description: `Beirut Digital District\nBuilding 1294, 3rd floor`,
    descriptionAr: `مدينة بيروت الرقمية\n مبنى رقم 1294 - الطابق الثالث`,
    icon: LocationIcon,
  },
  {
    title: "Phone Number",
    titleAr: "رقم الهاتف",
    descriptionAr: "هاتف : +961 1 631 064 \n(أيام الأسبوع فقط من 9 صباحًا إلى 5 مساءً)\nواتساب : +961 76 910 256",
    description: "Tel: +961 1 631 064\n(Weekdays only, 9 AM to 5 PM)\nWhatsApp: +961 76 910 256",
    icon: SupportIcon,
  },
];

export const statusContent: any = {
  1: "*Your completion proof is under validation",
  2: "*Your completion proof has been rejected",
  3: "*Your completion proof has been accepted",
};
