import styled from "styled-components";
import {colors} from "&assets/constants/colors";

export const CircularLoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px 0;
  svg {
    color: ${colors.primary} !important;
    opacity: 0.8;
  }
`;
