import styled from "styled-components";
import { device } from "&assets/constants/responsiveness";
import { colors } from "&assets/constants/colors";

export const ThankYouWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  .thankyou-title {
    font-weight: 700;
    font-size: 40px;
    line-height: 120%;
    color: ${colors.primary};
    text-align: center;
    margin: 10px;
    margin-bottom: 50px;
  }
  .thankyou-subtitle {
    font-weight: 700;
    font-size: 14px;
    color: ${colors.colorNeutralBlack};
    text-align: center;
    margin: 0;
    line-height: 30px;
  }

  .thankyou-click-here {
    font-weight: 700;
    font-size: 14px;
    color: ${colors.colorNeutralBlack};
    text-align: center;
    margin: 0;
    text-decoration: underline;
    cursor: pointer;
    line-height: 30px;
  }
  .login-btn {
    margin-top: 20px;
    height: 54px;
    justify-content: center;
  }

  @media ${device.inBetween} {
    ${(props) => (props.theme.dir === "rtl" ? "padding: 0 150px 0 70px;" : "padding: 0 70px 0 150px;")}
  }

  @media ${device.mobile}, ${device.tablet} {
    padding: 0px;
    .signup-form {
      width: 100%;
      margin-top: 17px;
      .flex-row {
        flex-direction: column;
        .name-input {
          width: 100%;
          margin-top: 10px;
        }
      }
      .input-row {
        margin-top: 10px;
      }
      .create-account-btn {
        width: auto;
        height: 57px;
        margin: auto;
        margin-top: 16px;
      }
    }
    .socials {
      .social-btn {
        min-width: auto;
        height: 37px;
      }
    }
  }
`;
