import styled from "styled-components";
import { device } from "&assets/constants/responsiveness";

export const BuildProfileStepTwoWrapper = styled.div`
  padding: 48px 75px 200px 75px;
  * {
    direction: ${(props) => props.theme.dir} !important;
  }
  @media ${device.mobile}, ${device.tablet} {
    padding: 16px;
  }
`;
