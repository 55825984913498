import { Skills } from "&features/assessment/assessment.type";

const checkSkill = (userSkills?: Skills) => {
  const softSkill =
    userSkills?.soft_skills && userSkills?.soft_skills?.length
      ? userSkills?.soft_skills?.length
      : 0;
  const hardSkill =
    userSkills?.hard_skills && userSkills?.hard_skills?.length
      ? userSkills?.hard_skills?.length
      : 0;

  const otherSkills =
      userSkills?.other_skills && userSkills?.other_skills?.length
          ? userSkills?.other_skills?.length
          : 0;

  return softSkill + hardSkill + otherSkills >= 3;
};

export default checkSkill;
