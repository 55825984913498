import { API } from "aws-amplify";
import { RES_AUTH_USER } from "&store/resources";

const getAssessmentApi = async () => {
  let URL = `${RES_AUTH_USER}/assessment`;
  return API.get(process.env.REACT_APP_NAME || "", URL, {});
};

const getAssessmentLearningPathApi = async (id: string) => {
  let URL = `${RES_AUTH_USER}/assessment/learning-paths/${id}`;
  return API.get(process.env.REACT_APP_NAME || "", URL, {});
};



export {
  getAssessmentApi,
  getAssessmentLearningPathApi,
};
