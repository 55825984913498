import React from "react";
import { isArray } from "lodash";
import { SmallProfileItemWrapper } from "./smallProfileItem.styled";

export interface SideDetailsProps {
  title?: string;
  content?: any;
  icon?: any;
  link?: string;
  className?: string;
  placeholder?: string;
  hide?: boolean;
}

const SmallProfileItem = (props: SideDetailsProps) => {
  const { title, icon, content, className, link, placeholder } = props;

  const parsedContent = isArray(content)
    ? content?.map((item: string, index: number) =>
        index !== content.length - 1 ? `${item}, ` : item
      )
    : content
    ? content
    : placeholder
    ? placeholder
    : "";

  return (
    <SmallProfileItemWrapper className={className}>
      <div className="icon-wrapper">{icon}</div>
      <div className="title-detail-wrapper">
        <div className="side-detail-title">{title}</div>
        {link ? (
          <a
            className="side-detail-content-text"
            href={link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {parsedContent}
          </a>
        ) : (
          <div
            className={`side-detail-content-text ${
              !content && placeholder && "placeholder-text"
            }`}
          >
            {parsedContent}
          </div>
        )}
      </div>
    </SmallProfileItemWrapper>
  );
};

export default SmallProfileItem;
