import React from "react";
import { ActivitiesWrapper, ActivityCardWrapper, ActivityLogoNameWrapper, SkillsWrapper } from "./activityCard.styled";
import ImageDisplay from "&components/imageDisplay/imageDisplay.component";
import { Course, Exam } from "&features/listingPage/listingPage.type";
import toHoursAndMinutes from "&utils/fromSecondsToHoursMinutes";
import { ActivityLevelIcon, Clock, Document } from "&assets/constants/icons";
import SourceLogo from "&components/sourceLogo/sourceLogo.component";
import SkillsList from "&components/skillsList/skillsList.component";
import getCDNProtectedURL from "&utils/getCDNProtectedImageSource";
import { images } from "&assets/constants/images-urls";
import { translationSelector } from "&utils/translationSelector";
import { useTranslation } from "react-i18next";

interface Props {
  type: "course" | "exam";
  course?: Course;
  exam?: Exam;
  wrapperClassName?: string;
  handleClick?: Function;
  hasSponsorship?: boolean;
}

const ActivityCard = (props: Props) => {
  const { wrapperClassName, handleClick, hasSponsorship } = props;
  const activity = props.type === "course" ? props.course : props.exam;

  const { t } = useTranslation(["listingPage"]);

  const formattedDuration = activity?.duration
    ? `${toHoursAndMinutes(activity?.duration).h}${t("H")} ${toHoursAndMinutes(activity?.duration).m}${t("M")}`
    : "";

  const detailsList = [
    {
      icon: <ActivityLevelIcon />,
      text: translationSelector(activity?.level, "alias"),
    },
    { icon: <Clock />, text: formattedDuration },
    {
      icon: <Document />,
      text: t(props.type === "course" ? "COURSE" : "EXAMS"),
    },
  ];
  return (
    <ActivityCardWrapper
      className={wrapperClassName}
      onClick={() => handleClick && handleClick()}
      isExam={props.type === "exam"}
    >
      <ImageDisplay
        className="activity-image"
        isFromS3={true}
        isBackgroundImage={true}
        url={activity?.image || getCDNProtectedURL(images.learningPathwayImg)}
      />
      {hasSponsorship && <div className="sponsorship">{t("SPONSORSHIP")}</div>}

      <div className="activity-title" title={translationSelector(activity, "name")}>
        {translationSelector(activity, "name")}
      </div>
      <ActivityLogoNameWrapper>
        <SourceLogo source={activity?.source?.toLowerCase()} />
        <div className="source-text">
          {activity?.source} - {t(props.type === "course" ? "COURSE" : "EXAMS")}
        </div>
      </ActivityLogoNameWrapper>
      <SkillsWrapper>
        <SkillsList
          skills={
            props.type === "course" ? (activity as Course)?.courseSkills || [] : (activity as Exam)?.examSkills || []
          }
        />
      </SkillsWrapper>
      <ActivitiesWrapper>
        {detailsList?.map((detail, index) => (
          <div className="activity-wrapper" key={index}>
            {detail?.icon}
            <div className="details-text">{detail?.text}</div>
          </div>
        ))}
      </ActivitiesWrapper>
    </ActivityCardWrapper>
  );
};

export default ActivityCard;
