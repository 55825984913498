import styled from "styled-components";
import { InputLabel } from "@mui/material";

import { colors } from "&assets/constants/colors";
import { device } from "&assets/constants/responsiveness";

interface Props {
  disabled?: boolean;
}

export const SelectDropDownWrapper = styled.div<Props>`
  position: relative;
  .input-wrapper {
    width: fit-content;
    height: 50px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 12px;
    border-radius: 12px;
    border: 1px solid transparent;
    background: ${colors.linearGradientWhite} padding-box, ${colors.linearGradientSixth} border-box;
    &.opened {
      border-radius: 12px 12px 0 0;
      background: ${colors.linearGradientWhite} padding-box, ${colors.linearGradientOne} border-box;
    }
    .item-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      position: relative;
      .title {
        font-family: "Mulish";
        white-space: nowrap;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        color: ${colors.dark};
      }
      .country-flag-wrapper {
        margin: 0 8px;
        align-items: center;
        display: flex;
      }
      .selected-title {
        font-family: "Mulish";
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 150%;
        white-space: nowrap;
        color: ${colors.primary};
        flex: 1;
        text-transform: capitalize;
      }

      .margin-left-title {
        ${(props) => (props.theme.dir === "rtl" ? "margin-right: 5px;" : "margin-left: 5px;")}
      }

      .arrow-icon {
        ${(props) => (props.theme.dir === "rtl" ? "margin-right: 5px;" : "margin-left: 5px;")}
      }

      .rotate {
        transform: rotate(180deg);
        transition: all ease-in-out 100ms;
      }
    }
  }

  @media ${device.mobile} {
    margin: 10px;
  }
`;

export const Listbox = styled.ul<any>`
  width: 100%;
  box-sizing: border-box;
  padding: 0;
  position: absolute;
  list-style: none;
  z-index: 1;
  border-radius: 0 0 10px 10px;
  overflow-y: auto;
  margin-top: 0;
  overflow-x: hidden;
  max-height: 200px;
  border: 1px solid transparent;
  background: ${colors.linearGradientWhite} padding-box, ${colors.linearGradientOne} border-box;

  & li {
    box-sizing: border-box;
    width: 100%;
    overflow: hidden;
    display: flex;
    cursor: pointer;
    align-items: center;
    height: 40px;
    padding: 17px 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 160%;
    min-width: 248px;
    max-width: fit-content;

    .list-item-name {
      text-transform: capitalize;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:hover {
      background: rgba(16, 140, 210, 0.1) !important;
      cursor: pointer;
    }
    &:first-child {
      padding-top: 10px;
    }
    &:last-child {
      padding-bottom: 10px;
    }
  }
`;

export const LabelWrapper = styled(InputLabel)`
  font-family: "Mulish" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 10px !important;
  line-height: 160%;
  letter-spacing: 0.03em;
  text-transform: uppercase !important;
  color: ${colors.dark} !important;
  margin-bottom: 4px;
`;
