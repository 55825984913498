import { API } from "aws-amplify";
import { RES_NOTIFICATIONS } from "&store/resources";

const getNotifications = async (type: "banner" | "panel") => {
  let URL = `${RES_NOTIFICATIONS}/${type}`;
  return API.get(process.env.REACT_APP_NAME || "", URL, {});
};

const readPanelNotifications = async () => {
  let URL = `${RES_NOTIFICATIONS}`;
  return API.put(process.env.REACT_APP_NAME || "", URL, {});
};

const readBannerNotifications = async (id: number) => {
  let URL = `${RES_NOTIFICATIONS}/${id.toString()}`;
  return API.put(process.env.REACT_APP_NAME || "", URL, {});
};
export { getNotifications, readPanelNotifications, readBannerNotifications };
