import { colors } from "&assets/constants/colors";
import { device } from "&assets/constants/responsiveness";
import styled from "styled-components";

export const CustomModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 29px 43px;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-width: 0;
  border-radius: 16px;
  outline: 0;
  background: ${colors.white};
  max-height: 100vh;
  overflow-y: auto;

  .confirmation-modal-text {
    font-size: 18px;
    color: ${colors.dark};
    padding: 20px 0;
  }

  .custom-modal-header {
    width: 100%;
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    .title {
      font-weight: bold;
      font-size: 20px;
    }
    .custom-modal-header-separator {
      min-width: 10px;
    }
  }
  .custom-modal-body {
    margin-bottom: 15px;
    width: 100%;
  }
  .close-icon {
    position: absolute;
    width: 24px;
    height: 24px;
    top: 20px;
    ${(props) => (props.theme.dir === "rtl" ? "left: 20px;" : "right: 20px;")}
    cursor: pointer;
    z-index: 1;
  }

  .custom-modal-footer {
    width: 100%;
  }
  @media ${device.tablet} {
    .custom-modal-body {
      overflow: scroll;
    }
  }
  @media ${device.mobile} {
    box-sizing: border-box;
    width: 95%;
    padding: 29px 16px;
    .custom-modal-body {
      overflow: scroll;
    }
  }
`;

export default CustomModalWrapper;
