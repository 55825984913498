import React, { useEffect, useRef, useState } from "react";

import SkillChip from "&components/skillChip/skillChip.component";
import SkillListModal from "&components/skillsListModal/skillsListModal.component";
import {
  CourseSkill,
  ExamSkills,
} from "&features/listingPage/listingPage.type";
import { SkillsListWrapper } from "./skillsList.styled";
import Chip from "&components/chip/chip.component";
import { useMediaQuery } from "react-responsive";
import { size } from "&assets/constants/responsiveness";
import { translationSelector } from "&utils/translationSelector";
import { useTranslation } from "react-i18next";

interface Props {
  skills: CourseSkill[] | ExamSkills[];
  isChip?: boolean;
  chipClassName?: string;
  height?: string;
}

const SkillsList = (props: Props) => {
  const { skills, isChip, chipClassName, height } = props;
  const isMobile = useMediaQuery({ maxWidth: size.mobile });
  const { t } = useTranslation(["dashboard"]);

  const ref = useRef<any>(null);
  const [filteredList, setFilteredList] = useState<
    CourseSkill[] | ExamSkills[]
  >(skills);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (ref?.current?.scrollHeight > ref?.current?.clientHeight) {
      setFilteredList(filteredList.slice(0, filteredList?.length - 1));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredList.length]);

  const handleOpenModal = (event: any) => {
    event.stopPropagation();
    setOpenModal(true);
  };

  return (
    <SkillsListWrapper ref={ref} height={height}>
      {filteredList.map((item, index) => {
        return isChip ? (
          <Chip
            className={chipClassName}
            title={translationSelector(item?.skills, "name") || item.toString()}
            key={index}
          />
        ) : (
          <SkillChip
            handleClick={(event: any) => isMobile && handleOpenModal(event)}
            title={translationSelector(item?.skills, "name") || item.toString()}
            key={index}
            level={item?.weight}
            className={chipClassName}
          />
        );
      })}
      {filteredList.length < skills.length &&
        (isChip ? (
          <Chip
            title={`+ ${skills.length - filteredList.length} ${t("MORE")}`}
            handleClick={handleOpenModal}
            className={`more-skills ${chipClassName}`}
          />
        ) : (
          <SkillChip
            title={`+ ${skills.length - filteredList.length} ${t("MORE")}`}
            handleClick={handleOpenModal}
            className={`more-skills ${chipClassName}`}
          />
        ))}
      <SkillListModal
        open={openModal}
        handleClose={(event: any) => {
          event.stopPropagation();
          setOpenModal(false);
        }}
        list={skills}
        title={t("SKILLS")}
        isChip={isChip}
      />
    </SkillsListWrapper>
  );
};

export default SkillsList;
