import CircularProgress from "@mui/material/CircularProgress";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import { MatchingCareerSwipeWrapper } from "./matchingCareerSwipe.styled";
import RightArrowImg from "&assets/images/rightArrow.svg";
import LeftArrowImg from "&assets/images/leftArrow.svg";
import { PercentageCircleGraphic } from "&assets/constants/icons";
import SubmitButton from "&components/buttons/submitButton/submitButton.component";
import TextLine from "&components/textLine/textLine.component";
import { size } from "&assets/constants/responsiveness";
import {useTranslation} from "react-i18next";
import i18n from "i18next";
import {translationSelector} from "&utils/translationSelector";

interface Props {
  matchingCareers: any;
  isEQ1?: boolean;
  selectedCareer?: any;
  onSelectCareer: (careerId: number, name: string) => void;
}

const MatchingCareerSwipe = (props: Props) => {
  const { matchingCareers, selectedCareer, isEQ1, onSelectCareer } = props;
  const { t } = useTranslation(["dashboard"]);
  const dir = i18n.dir()

  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: size.mobile });
  const isTablet = useMediaQuery({
    minWidth: size.mobile,
    maxWidth: size.tablet,
  });
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedMatchingCareer, setSelectedMatchingCareer] =
    useState<any>(selectedCareer);

  const isDevice = isMobile || isTablet;

  const handleArrowClick = (index: number) => {
    setSelectedMatchingCareer(matchingCareers[index]);
    setSelectedIndex(index);
    onSelectCareer(matchingCareers[index]?.id, matchingCareers[index]?.name);
  };

  const renderPercentageCircle = (percentage: number) => {
    return (
      <div className="progress-circle-wrapper">
        <div className="progress-background" />
        <CircularProgress variant={"determinate"} value={dir === 'rtl' ? -percentage : percentage} />
        <div className="percentage-circle">
          <div className="background" />
          <PercentageCircleGraphic
            className={"background-graphic"}
            selected={false}
          />
          <div className="percentage-text">{`${percentage}%`}</div>
          <div className="text">{t('CAREER_MATCHING')}</div>
        </div>
      </div>
    );
  };

  return (
    <MatchingCareerSwipeWrapper isSelected={selectedMatchingCareer.isSelected}>
      {!isDevice && <TextLine title={t("THINKING_OF_UPGRADING_YOUR_CAREER")} />}
      <div className="matching-career">
        <div className="circle-text-button-wrapper">
          <div className="flex-row-space-between">
            {!isEQ1 && !isDevice && (
              <img
                src={LeftArrowImg}
                alt=""
                className="arrows"
                onClick={() => {
                  selectedIndex > 0
                    ? handleArrowClick(selectedIndex - 1)
                    : handleArrowClick(matchingCareers?.length - 1);
                }}
              />
            )}
            {renderPercentageCircle(
              selectedMatchingCareer?.learnerScore
                ? Math.round(selectedMatchingCareer?.learnerScore * 100)
                : 0
            )}
            {!isEQ1 && !isDevice && (
              <img
                src={RightArrowImg}
                alt=""
                className="arrows"
                onClick={() => {
                  selectedIndex < matchingCareers?.length - 1
                    ? handleArrowClick(selectedIndex + 1)
                    : handleArrowClick(0);
                }}
              />
            )}
          </div>
          <div className="career-name-text">{translationSelector(selectedMatchingCareer,'name')}</div>
        </div>
        <div>
          <SubmitButton
            id="view-career-btn"
            title={t('VIEW_CAREER')}
            handleClick={() => {
              navigate(`/career-details/${selectedMatchingCareer?.id}`);
            }}
            className="view-career-btn"
            titleClassName="view-career-btn-text"
          />
        </div>
      </div>
    </MatchingCareerSwipeWrapper>
  );
};

export default MatchingCareerSwipe;
