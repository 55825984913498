import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Global } from "./global.type";
import { getPageContentApi, getSettingsApi } from "&features/global/global.api";
import buildSettingsObject from "&utils/buildSettingsObject";

const initialState: Global = {
  settings: {},
  pages: [],
  loadingSettings: false,
  loadingPages: false,
  pathsHistory: [],
  status: "",
};

const getSettings = createAsyncThunk(
  "global/getSettings",
  async (_, { rejectWithValue, getState, dispatch }: any) => {
    try {
      const settings = await getSettingsApi();
      return settings?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const getPages = createAsyncThunk(
  "global/getPages",
  async (title: string, { rejectWithValue, getState, dispatch }: any) => {
    try {
      const pages = await getPageContentApi(title);
      return pages?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const globalSlice = createSlice({
  name: "global",
  initialState: initialState,
  reducers: {
    setGlobal: (state, action) => {
      return { ...state, ...action.payload };
    },
    setPathsHistory: (state, action) => {
      return { ...state, pathsHistory: action.payload };
    },
    reset: () => initialState,
  },

  extraReducers: (builder) => {
    builder.addCase(getSettings.pending, (state, action) => {
      state.loadingSettings = true;
    });
    builder.addCase(getSettings.fulfilled, (state, action) => {
      state.loadingSettings = false;
      state.settings = buildSettingsObject(action.payload);
      state.status = "GET_SETTINGS_SUCCESS";
    });
    builder.addCase(getSettings.rejected, (state, action) => {
      state.loadingSettings = false;
    });
    builder.addCase(getPages.pending, (state, action) => {
      state.loadingPages = true;
    });
    builder.addCase(getPages.fulfilled, (state, action) => {
      state.loadingPages = false;
      state.pages = action.payload;
      state.status = "GET_PAGES_SUCCESS";
    });
    builder.addCase(getPages.rejected, (state, action) => {
      state.loadingPages = false;
    });
  },
});

export const globalReducer = globalSlice.reducer;

export const globalActions = { ...globalSlice.actions, getSettings, getPages };
