import { colors } from "&assets/constants/colors";
import { device } from "&assets/constants/responsiveness";
import styled from "styled-components";

interface Props {
  right?: boolean;
  inProgress?: boolean;
  completed?: boolean;
  unCompleted?: boolean;
}

export const LayeredImageWrapper = styled.div<Props>`
  position: relative;
  width: fit-content;
  height: fit-content;
  .image-button-wrapper {
    background: transparent none;
    border: none transparent;
    cursor: pointer;
  }
  .sponsored {
    position: absolute;
    width: 93px;
    height: 29px;
    background: ${colors.linearGradientOne};

    ${(props) => (props.theme.dir === "rtl" ? "border-radius: 0 8px 8px 0;" : "border-radius: 8px 0 0 8px;")}
    ${(props) => (props.theme.dir === "rtl" ? "left: 0;" : "right: 0;")}
    top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    font-weight: 800;
    font-size: 12px;
    line-height: 120%;
    color: ${colors.white};
    box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.25);
  }
  .tier {
    position: absolute;
    color: white;
    bottom: 24px;
    ${(props) => (props.theme.dir === "rtl" ? "right: 34px;" : "left: 34px;")}
    background-color: #87c5e8;
    border-radius: 8px;
    width: fit-content;
    height: 26px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 10px;
    font-family: "Clash Grotesk";
    font-style: normal;
    font-weight: 600;
    font-size: 9px;
    line-height: 150%;
    ${(props) => (props.theme.dir === "rtl" ? "" : "letter-spacing: 0.2em;")}
    text-transform: uppercase;
    z-index: 3;
  }
  .learning-index {
    position: absolute;
    width: 59px;
    height: 59px;
    ${(props) => (props.theme.dir === "rtl" ? "margin-right: -30px;" : "margin-left: -30px;")}
    background: ${(props) =>
      props.inProgress ? colors.green : props.unCompleted ? colors.linearGradientThree : colors.green};
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;
    color: #ffffff;
    top: 50%;
    ${(props) => (props.theme.dir === "rtl" ? "right: 0;" : "left: 0;")}
    z-index: 2;
    transform: translateY(-50%);
  }

  .background-gradient {
    position: absolute;
    width: 537px;
    height: 100%;
    z-index: 1;
    top: 10px;
    ${(props) => (props.theme.dir === "rtl" ? "right:" : "left:")};
    ${(props) => (props.right ? "10px" : "-10px")};
    background: ${(props) => (props.inProgress || props.completed ? colors.green : colors.linearGradientThree)};
    border-radius: 16px;
    box-shadow: ${(props) => (props.inProgress ? "4px 4px 8px rgba(0, 0, 0, 0.25)" : "none")};
  }

  .learning-path-image {
    width: 537px;
    height: 336px;
    z-index: 2;
    object-fit: cover;
    border-radius: 10px;
    position: relative;
  }

  @media ${device.mobile}, ${device.tablet} {
    width: 100%;
    max-width: 351.46px;
    height: 219.9px;
    margin-top: 24px;
    .background-gradient {
      top: 5px;
      width: 100%;
      ${(props) => (props.theme.dir === "rtl" ? "right: -5px;" : "left: -5px;")}
    }
    .learning-path-image {
      width: 100%;
      height: 219.9px;
      z-index: 2;
      margin: 0;
      margin-right: 0;
    }
    .learning-index {
      width: 41px;
      height: 40px;
      ${(props) => (props.theme.dir === "rtl" ? "margin-right: -16px;" : "margin-left: -16px;")}
      border-radius: 6px;
      font-weight: 700;
      font-size: 16px;
      margin-top: -40px;
    }
  }
`;
