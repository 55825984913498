import { colors } from "&assets/constants/colors";
import { device } from "&assets/constants/responsiveness";
import styled from "styled-components";

export const LearningPathwayWrapper = styled.div`
  background: #ffffff;
  padding: 58px 72px 50px 72px;
  .slide {
    min-width: 370px !important;
  }
  .title {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 110%;
    letter-spacing: -0.02em;
    color: ${colors.dark};
  }

  .loader-wrapper {
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .title-button-wrapper {
    margin-bottom: 48px;
  }

  .cards {
    width: 96%;
    ${(props) => (props.theme.dir === "rtl" ? "margin-left: 16px;" : "margin-right: 16px;")}
  }
  @media ${device.tablet} {
    padding: 40px 30px 86px 30px;
    .title {
      font-size: 30px;
    }
    .title-button-wrapper {
      margin-bottom: 48px;
    }
    .cards {
      margin: 0;
      ${(props) => (props.theme.dir === "rtl" ? "margin-left: 16px;" : "margin-right: 16px;")}
    }
  }
  @media ${device.mobile} {
    padding: 40px 16px 86px 16px;
    .title {
      font-size: 30px;
    }
    .title-button-wrapper {
      margin-bottom: 40px;
    }
    .cards {
      margin: 0;
      width: initial;
      ${(props) => (props.theme.dir === "rtl" ? "margin-left: 16px;" : "margin-right: 16px;")}
    }
  }
`;
