import { Level } from "&features/landingPage/landingPage.type";
import { Setting } from "&features/global/global.type";
import { Notification } from "&features/notifications/notifications.type";
import { translationSelector } from "&utils/translationSelector";
import i18n from "i18next";

export const buildDropDownList = (object: any) => {
  return Object?.keys(object)?.map((key) => {
    return { title: object[key], id: key, name: object[key], value: key };
  });
};

export const addAllToList = (object: any) => {
  const lang = i18n.language;
  let allObject =
    lang === "ar"
      ? {
          "0": "الكل",
        }
      : {
          "0": "All",
        };
  let objectWithAll = {
    ...allObject,
    ...object,
  };
  return objectWithAll;
};

export const buildLevelsList = (levels: Level[]) => {
  const lang = i18n.language;
  let returnObject =
    lang === "ar"
      ? {
          "0": "الكل",
        }
      : {
          "0": "All",
        };

  levels?.map((level: Level) => {
    returnObject = {
      ...returnObject,
      [level?.id?.toString()]: translationSelector(level, "alias"),
    };
  });

  return returnObject;
};

export const buildSourcesList = (sources: Setting[]) => {
  const lang = i18n.language;
  let returnObject =
    lang === "ar"
      ? {
          "0": "الكل",
        }
      : {
          "0": "All",
        };

  sources?.map((source: Setting) => {
    returnObject = {
      ...returnObject,
      [source?.name?.toString()]: source.name,
    };
  });

  return returnObject;
};

export const appliedFilter = [
  {
    createdAt: "",
    deletedAt: "",
    id: 1,
    image: "string",
    name: "Applied",
    nameAr: "المقدم عليها",
    type: "type",
    typeAr: "typeAr",
    updatedAt: "",
    url: "",
    value: "Applied",
    valueAr: "Applied",
  },
];

export const buildJobSourcesList = (sources: Setting[]) => {
  const lang = i18n.language;
  let returnObject =
    lang === "ar"
      ? {
          all: "الكل",
        }
      : {
          all: "All",
        };

  sources?.map((source: Setting) => {
    returnObject = {
      ...returnObject,
      [source?.name?.toString()]: translationSelector(source, "name"),
    };
  });

  return returnObject;
};

export const buildCareerList = (careers: any[]) => {
  // const lang = i18n.language;
  let returnObject = {};
  careers?.map((career: any) => {
    returnObject = {
      ...returnObject,
      [career?.id?.toString()]: translationSelector(career, "name"),
    };
  });

  return returnObject;
};

export const querySelector = (list: any, id: string) => {
  let returnItem = { title: "", id: "" };
  list?.map((item: any) => {
    if (item.id === id) {
      returnItem = item;
    }
  });
  return returnItem;
};

export const searchListById = (list: any, id: number, key: any) => {
  let returnList: any[] = [];
  let returnItem: any = {};
  list?.map((item: any) => {
    if (item?.id === id) {
      returnItem = item;
    }
  });
  if (returnItem[key]) {
    returnList = returnItem[key];
  }
  return returnList;
};

export const findAndReadNotificationById = (
  notifications: Notification[],
  id: number
) => {
  return notifications?.map((notification) => {
    if (notification.id === id) {
      return {
        ...notification,
        status: true,
      };
    } else {
      return notification;
    }
  });
};
