import React from "react";
import { ArrowButtonWrapper } from "./arrowButton.styled";
import CloseIconButton from "&components/closeIcon/closeIcon.component";
import { SmallArrowForward } from "&assets/constants/icons";

interface Props {
  ref?: any;
  className?: string;
  onClick: () => void;
  isBack?: boolean;
}

const ArrowButton = (props: Props) => {
  const { ref, className, onClick, isBack } = props;
  return (
    <ArrowButtonWrapper ref={ref} isBack={isBack} className={className}>
      <CloseIconButton onClick={onClick} icon={<SmallArrowForward />} />
    </ArrowButtonWrapper>
  );
};

export default ArrowButton;
