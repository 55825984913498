import React from "react";
import { Helmet } from "react-helmet";

import { AboutHeaderWrapper } from "./aboutHeader.styled";
import { useTranslation } from "react-i18next";
import SubmitButton from "&components/buttons/submitButton/submitButton.component";
import { useAuth0 } from "@auth0/auth0-react";
import ImageDisplay from "&components/imageDisplay/imageDisplay.component";
import AboutusHEader from "../../assets/images/about-us-header.png";

const AboutHeader = ({ userName }: { userName?: string | undefined }) => {
  const { t } = useTranslation(["about"]);
  const { loginWithRedirect } = useAuth0();
  return (
    <AboutHeaderWrapper>
      <Helmet>
        <title>{t("OUR_MISSION")}</title>
        <meta name="description" content="Mission: Unlock your potential Seize outstanding opportunities" />
      </Helmet>
      <div className="header-wrapper">
        <div className="header-text-area">
          <h1>{t("OUR_MISSION")}</h1>
          <p>{t("AT_NAMMISKILLS")}</p>
          {!userName && (
            <SubmitButton
              id={"sign-up-now-button"}
              className="sign-up-button-about-us"
              title={t("SIGN_UP_NOW")}
              handleClick={() => loginWithRedirect()}
            />
          )}
        </div>
        <div className="header-blue-area-image-wrapper">
          <div className="header-blue-area">
            <ImageDisplay url={AboutusHEader} isBackgroundImage={true} className="about-us-header-image" />
          </div>
        </div>
      </div>
    </AboutHeaderWrapper>
  );
};

export default AboutHeader;
