import { colors } from "&assets/constants/colors";
import { device } from "&assets/constants/responsiveness";
import styled from "styled-components";

interface Props {
  isExam?: boolean;
  disabled?: boolean;
  hasCompletionButton?: boolean;
  status?: number;
  type?: string;
}

export const LearningPathwayActivityWrapper = styled.div<Props>`
  background: ${(props) =>
    props.isExam
      ? colors.linearGradientOne
      : `${colors.linearGradientWhite}
      padding-box,
    ${colors.linearGradientOne} border-box`};

  border-radius: 16px;
  border: 1px solid transparent;
  width: 774px;
  min-height: 195px;
  height: auto;
  box-sizing: border-box;
  ${(props) => (props.theme.dir === "rtl" ? "padding: 26px 26px 26px 32px;" : "padding: 26px 32px 26px 26px;")}
  display: flex;
  flex-direction: row;
  align-items: "flex-start";
  position: relative;
  margin-bottom: 10px;
  cursor: ${(props) => (props.disabled ? "initial" : "pointer")};
  .card-img {
    object-fit: fill !important;
    width: 248px;
    height: 143px;
    ${(props) => (props.theme.dir === "rtl" ? "margin-left: 48px;" : "margin-right: 48px;")}
    min-width: 248px !important;
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 16px;
    z-index: 1;
    ${(props) => (props.theme.dir === "rtl" ? "margin-right: -97px;" : "margin-left: -97px;")}
    .background-gradient {
      position: absolute;
      width: 250px !important;
      height: 143px !important;
      z-index: 1;
      top: 5px;
      ${(props) => (props.theme.dir === "rtl" ? "right: 3px;" : "left: 3px;")}
      z-index: 0;
      border-radius: 16px;
    }
  }
  .course-img {
    object-fit: fill !important;
    width: 248px !important;
    height: 143px !important;
    min-width: 248px !important;
    position: relative;
    display: flex;
    align-items: center;
    background-repeat: round;
    background-size: cover;
    border-radius: 16px !important;
    z-index: 1;
  }
  .card-content {
    display: flex;
    align-items: center;
    .source {
      background-color: ${(props) => (props.isExam ? colors.white : "transparent")};
    }
    .info {
      font-weight: 400;
      font-size: 14px;
      ${(props) => (props.theme.dir === "rtl" ? "margin-right: 10px;" : "margin-left: 10px;")}
      color: ${(props) => (props.isExam ? colors.white : colors.lighterDark)};
      align-items: center;
      justify-content: center;
      display: flex;

      ${(props) => (props.theme.dir === "rtl" ? "margin-right: 0;" : "margin-left: 0;")}
      ${(props) => (props.theme.dir === "rtl" ? "margin-left: 20px;" : "margin-right: 20px;")}
      .text {
        ${(props) => (props.theme.dir === "rtl" ? "margin-right: 6px;" : "margin-left: 6px;")}
        color: ${(props) => (props.isExam ? colors.white : colors.lighterDark)};
      }
    }
  }
  .content {
    .action-buttons {
      display: flex;
      margin-top: 10px;
      align-items: center;
      flex-wrap: wrap;
    }
    .launch-course-button,
    .apply-voucher-button {
      background: transparent;
      border: 1px solid ${(props) => (props.type === "exam" ? colors.white : colors.primary)};
      width: auto;
      height: 38px;
      margin-top: 10px;
      ${(props) => (props.theme.dir === "rtl" ? "margin-left: 10px;" : "margin-right: 10px;")}
      justify-content: center;
      .MuiCircularProgress-circle {
        color: ${(props) => (props.type === "exam" ? colors.white : colors.primary)};
      }
    }
    .launch-course-button-title,
    .apply-voucher-button-title {
      font-weight: 800;
      font-size: 14px;
      line-height: 160%;
      text-align: center;
      color: ${(props) => (props.type === "exam" ? colors.white : colors.primary)};
    }
    .pathway-title {
      font-family: "Mulish";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 120%;
      color: ${(props) => (props.isExam ? colors.white : "#2d2a35")};
      margin: 0;
    }

    .upload-completion-white-btn {
      background: transparent;
      border: 1px solid white;
    }
    .upload-completion-btn {
      width: auto;
      margin-top: 10px;
      height: 38px;
      justify-content: center;
      ${(props) => (props.theme.dir === "rtl" ? "margin-left: 10px;" : "margin-right: 10px;")}
    }
    .upload-completion-btn-text {
      font-size: 14px;
    }
    .description {
      font-family: "Mulish";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 160%;
      color: ${(props) => (props.isExam ? colors.white : colors.dark)};
      opacity: 0.7;
      margin: 7px 0;
      ${(props) => (props.theme.dir === "rtl" ? "padding-left: 0;" : "padding-right: 0;")}
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .disclaimer {
      font-weight: 500;
      font-size: 12px;
      line-height: 160%;
      color: ${(props) =>
        props.isExam
          ? colors.white
          : props.status === 2
          ? colors.errorColor
          : props.status === 3
          ? colors.green
          : colors.primary};
      opacity: 0.7;
    }
    .chips {
      display: flex;
      margin-top: 16px;
      flex-wrap: wrap;
    }
    .explore-button {
      width: 271px;
    }
  }
  @media ${device.inBetween} {
    padding: 30px;
    height: auto;
    .before-collapse {
      display: flex;
      flex-direction: column;
      align-content: center;
    }
    .header {
      display: flex;
      ${(props) => (props.theme.dir === "rtl" ? "margin-left: 0;" : "margin-right: 0;")}
      flex-direction: column;
      justify-content: center;
    }
    .content {
      .pathway-title {
        font-size: 22px;
        margin: 16px 0;
      }
      .description {
        font-size: 16px;
      }
      .chips {
        display: flex;
        margin-bottom: 16px;
        margin-top: 16px;
      }
      .explore-button {
        width: 250px;
        height: 42px;
      }
      .explore-button-text {
        font-size: 16px;
      }
    }
    .card-content {
      flex-wrap: wrap;
    }
  }
  @media ${device.tablet} {
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-content: center;
    height: auto;
    margin-bottom: 43px;
    width: 100%;
    .before-collapse {
      display: flex;
      flex-direction: column;
      align-content: center;
    }
    .header {
      display: flex;
      ${(props) => (props.theme.dir === "rtl" ? "margin-left: 0;" : "margin-right: 0;")}
      flex-direction: column;
      justify-content: center;
    }
    .content {
      .pathway-title {
        font-size: 18px;
        margin: 16px 0;
      }
      .chips {
        display: flex;
        margin-bottom: 16px;
        margin-top: 16px;
      }
      .explore-button {
        width: 250px;
        height: 42px;
      }
      .explore-button-text {
        font-size: 16px;
      }
    }
    .card-content {
      flex-wrap: wrap;
    }

    .card-img {
      margin-top: -40px;
      object-fit: fill;
      width: 100%;
      height: 187.4px;
      margin-right: 0;
      margin-left: 0;
      position: relative;
      display: flex;
      align-items: center;
      .learning-index {
        width: 41px;
        height: 40px;
        ${(props) => (props.theme.dir === "rtl" ? "margin-right: -16px;" : "margin-left: -16px;")}
        border-radius: 6px;
        font-weight: 700;
        font-size: 16px;
        margin-top: -40px;
      }
      .background-gradient {
        position: absolute;
        width: 100% !important;
        height: 187.4px !important;
        z-index: 1;
        top: 5px;
        ${(props) => (props.theme.dir === "rtl" ? "right: 3px;" : "left: 3px;")}
        z-index: 0;
        background: ${colors.linearGradientThree};
        border-radius: 16px;
      }
    }
    .course-img {
      object-fit: fill !important;
      width: 100% !important;
      height: 187.4px !important;
      min-width: 248px !important;
      position: relative;
      border-radius: 12px !important;
      z-index: 1;
    }
  }
  @media ${device.mobile} {
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-content: center;
    height: auto;
    margin-bottom: 43px;
    width: 100%;
    .before-collapse {
      display: flex;
      flex-direction: column;
      align-content: center;
    }
    .header {
      display: flex;
      ${(props) => (props.theme.dir === "rtl" ? "margin-left: 0;" : "margin-right: 0;")}
      flex-direction: column;
      justify-content: center;
    }
    .content {
      .pathway-title {
        font-size: 18px;
        margin: 16px 0;
      }
      .chips {
        display: flex;
        margin-bottom: 16px;
        margin-top: 16px;
      }
      .explore-button {
        width: 250px;
        height: 42px;
      }
      .explore-button-text {
        font-size: 16px;
      }
    }
    .card-content {
      flex-wrap: wrap;
    }
    .expand {
      width: 100%;
      justify-content: flex-start !important;
    }
    .card-img {
      margin-top: -40px;
      object-fit: fill;
      width: 100%;
      height: 187.4px;
      margin-right: 0;
      margin-left: 0;
      position: relative;
      display: flex;
      align-items: center;
      .learning-index {
        width: 41px;
        height: 40px;
        ${(props) => (props.theme.dir === "rtl" ? "margin-right: -16px;" : "margin-left: -16px;")}
        border-radius: 6px;
        font-weight: 700;
        font-size: 16px;
        margin-top: -40px;
      }
      .background-gradient {
        position: absolute;
        width: 100% !important;
        height: 187.4px !important;
        z-index: 1;
        top: 5px;
        ${(props) => (props.theme.dir === "rtl" ? "right: 3px;" : "left: 3px;")}
        z-index: 0;
        background: ${colors.linearGradientThree};
        border-radius: 16px;
      }
    }
    .course-img {
      object-fit: fill !important;
      width: 100% !important;
      height: 187.4px !important;
      min-width: 248px !important;
      position: relative;
      border-radius: 12px !important;
      z-index: 1;
    }
  }
`;
