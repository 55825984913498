import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { getIn, useFormik } from "formik";
import toast from "react-hot-toast";

import { BuildProfileStepThreeWrapper } from "./buildProfileStepThree.styled";
import { FormInputContent, EligibilityFormValues } from "&features/login/login.type";
import CircularLoader from "&components/circularLoader/circularLoader.component";
import SectionTitleWithLine from "&components/sectionTitleWithLine/sectionTitleWithLine.component";
import { FormSectionInputWrapper } from "&components/profile/buildProfileStepOne/buildProfileStepOne.styled";
import inputSelector from "&utils/ui/inputSelector";
import { Setting } from "&features/global/global.type";
import Checkbox from "&components/checkbox/checkbox.component";
import DragDropUploadFile from "&components/inputs/dragDropUploadFile/dragDropUploadFile.component";
import { searchListById } from "&utils/searchSortFilter/searchSortFilterUtils";
import uploadImageToS3 from "&utils/uploadImageToS3";
import { RootState } from "&store/store";
import { EligibilityRequest } from "&features/dashboard/screens/profile/profile.type";
import { isEmpty } from "lodash";
import { disabilityOptions, incomeRanges } from "&assets/constants/profile";
import { useMediaQuery } from "react-responsive";
import { size } from "&assets/constants/responsiveness";
import { eligibilityFormTexts } from "&assets/constants/statics";
import { useTranslation } from "react-i18next";
import { translationSelector } from "&utils/translationSelector";

interface Props {
  submit?: number;
  loading?: boolean;
  hide?: boolean;
  className?: string;
  countries?: any;
  postEligibility: (body: EligibilityRequest) => void;
  setProfile?: (body: any) => void;
  error?: string;
  isAwaiting?: boolean;
  isApproved?: boolean;
  initialValues?: EligibilityFormValues;
}

const residenceTypeOptions: Setting[] = [
  {
    settingId: "resident",
    title: "Resident",
    titleAr: "مقيم",
    createdAt: "2023-01-16T16:31:58.441Z",
    updatedAt: "2023-01-26T09:26:35.000Z",
    deletedAt: "null",
    id: 1,
    name: "Resident",
    nameAr: "مقيم",
    type: "residence-type",
    typeAr: "residence-type",
    value: "",
    valueAr: "",
    url: "",
    image: "",
  },
  {
    settingId: "refugee",
    title: "Refugee",
    titleAr: "لاجئ",
    createdAt: "2023-01-16T16:31:58.441Z",
    updatedAt: "2023-01-26T09:26:35.000Z",
    deletedAt: "null",
    id: 2,
    name: "Refugee",
    nameAr: "لاجئ",
    type: "residence-type",
    typeAr: "residence-type",
    value: "",
    valueAr: "",
    url: "",
    image: "",
  },
];

const ApplyForSponsorshipForm = (props: Props) => {
  const {
    submit,
    loading,
    postEligibility,
    hide,
    className = "",
    countries,
    setProfile,
    error,
    initialValues,
    isAwaiting,
    isApproved,
  } = props;
  const user = useSelector((state: RootState) => state.login.user);
  const isMobile = useMediaQuery({ maxWidth: size.mobile });
  const isTablet = useMediaQuery({
    minWidth: size.mobile,
    maxWidth: size.tablet,
  });
  const isDesktop = !isMobile && !isTablet;
  const { t } = useTranslation(["profile"]);

  const emptyValue = { id: undefined, name: "" };

  const values: EligibilityFormValues = {
    residence: initialValues?.residence || undefined,
    city: initialValues?.city || undefined,
    kazza: initialValues?.kazza || undefined,
    // nationality: initialValues?.nationality || undefined,
    residenceType: initialValues?.residenceType || undefined,
    idPhoto: undefined,
    idPhotoName: initialValues?.idPhotoName || undefined,
    responsible: initialValues?.responsible || initialValues?.approved || undefined,
    salaryRange: initialValues?.salaryRange || undefined,
    // disability: initialValues?.disability || undefined,
    // disabilityDescription: initialValues?.disabilityDescription || undefined,
  };

  const handleSubmit = async () => {
    setProfile &&
      setProfile({
        loadingEligibility: true,
      });
    const imageKey = formik?.values?.idPhoto?.name
      ? await uploadImageToS3({
          value: formik?.values?.idPhoto,
          userId: user?.auth0Id,
        })
      : initialValues?.idPhotoName;
    const body: EligibilityRequest = {
      cityId: formik?.values?.city?.id || undefined,
      document: imageKey || undefined,
      governorateId: formik?.values?.kazza?.id || undefined,
      // nationalityId: formik?.values?.nationality?.id || undefined,
      residenceCountryId: formik?.values?.residence?.id || undefined,
      salaryRange: formik?.values?.salaryRange?.value || undefined,
      // disability: !!(
      //   formik?.values?.disability && formik?.values?.disability === 1
      // ),
      // disabilityDescription: formik.values?.disabilityDescription || undefined,
      typeOfResidence:
        (formik?.values?.residenceType && formik?.values?.residenceType === 1 ? "resident" : "refugee") || undefined,
    };

    postEligibility(body);
  };

  const validationSchema = Yup.object().shape({
    residence: Yup.object()
      .shape({
        id: Yup.number().required(t("REQUIRED")),
        name: Yup.string().required(t("REQUIRED")),
      })
      .required(t("REQUIRED"))
      .test(
        "is-different-country",
        t("NATIONALITY_MUST_BE_DIFFERENT_THAN_RESIDENCE_COUNTRY"),
        function (value: any, context: any) {
          const residenceType = context.parent.residenceType;
          const residenceName = context.parent.residence?.name;
          const nationalityName = user && user.nationality.name;

          // console.log("residenceType", residenceType);
          // console.log("residenceName", residenceName);
          // console.log("nationalityName", nationalityName);

          // console.log("residenceType === 2", residenceType === 2);
          // console.log(" residenceName === nationalityName", residenceName === nationalityName);

          // console.log(
          //   "(residenceType === 2 && residenceName === nationalityName)",
          //   residenceType === 2 && residenceName === nationalityName
          // );

          if (residenceName && nationalityName && residenceType) {
            if (
              (residenceType === 1 && residenceName !== nationalityName) ||
              (residenceType === 2 && residenceName === nationalityName)
            ) {
              toast.error(
                translationSelector(
                  {
                    name: "Residence and Nationality should be the same to apply for eligibility",
                    nameAr: "يجب أن تكون الإقامة والجنسية واحدة لتقديم طلب الأهلية",
                  },
                  "name"
                )
              );
              return false;
            }
          }
          return true;
        }
      ),
    // governance: Yup.object().shape({
    //   id: Yup.number().required()
    // }).required("Required"),
    city: Yup.object().when("residence", {
      is: (residence: any) => residence?.name === "Lebanon",
      then: Yup.object().shape({
        id: Yup.number().required(t("REQUIRED")),
      }),
    }),
    kazza: Yup.object().when("residence", {
      is: (residence: any) => residence?.name === "Lebanon",
      then: Yup.object().shape({
        id: Yup.number().required(t("REQUIRED")),
      }),
    }),
    salaryRange: Yup.object().required(t("REQUIRED")),
    // disability: Yup.number().required(t("REQUIRED")),
    // disabilityDescription: Yup.string().when("disability", {
    //   is: 1,
    //   then: Yup.string().trim().required(t("REQUIRED")),
    // }),
    // nationality: Yup.object()
    //   .required(t("REQUIRED"))
    //   .test(
    //     "is-different-country",
    //     t("NATIONALITY_MUST_BE_DIFFERENT_THAN_RESIDENCE_COUNTRY"),
    //     function (value: any, context: any) {
    //       const residenceType = context.parent.residenceType;
    //       const residenceName = context.parent.residence?.name;
    //       const nationalityName = context.parent.nationality?.name;
    //       if (residenceName && nationalityName && residenceType) {
    //         return !(residenceType === 2 && residenceName === nationalityName);
    //       }
    //       return true;
    //     }
    //   ),
    residenceType: Yup.number().required(t("REQUIRED")),
    idPhotoName: Yup.string().required(t("REQUIRED")),
    responsible: Yup.boolean()?.oneOf([true], t("RESPONSIBILITY_DISCLAIMER_MUST_BE_CHECKED")).required(t("REQUIRED")),
  });

  const formik: any = useFormik({
    initialValues: values,
    validationSchema: validationSchema,
    onSubmit: () => handleSubmit(),
  });
  useEffect(() => {
    submit && submit > 0 && formik.handleSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submit]);

  const loadingComponent = (
    <div className="loader-wrapper">
      <CircularLoader />
    </div>
  );

  const governanceOptions = searchListById(countries, formik.values?.residence?.id, "governorates");

  const citiesOptions = searchListById(
    searchListById(countries, formik.values?.residence?.id, "governorates"),
    formik.values?.kazza?.id,
    "cities"
  );

  const formDisabled = isAwaiting || isApproved;

  const sponsorshipInputGrid: FormInputContent[] = [
    {
      id: "residence-country-select",
      label: t("RESIDENCE_COUNTRY"),
      placeHolder: t("SELECT_FROM_THE_LIST"),
      type: "select",
      options: countries,
      value: formik.values?.residence,
      handleChange: (value) => {
        formik.setValues({
          ...formik.values,
          residence: value,
          kazza: emptyValue,
        });
      },
      disabled: formDisabled,
      error: formik.touched.residence && formik.errors.residence?.id,
    },
    {
      id: "kazza-select",
      label: t("KAZA"),
      placeHolder: t("SELECT_FROM_THE_LIST"),
      type: "select",
      options: governanceOptions,
      disabled: isEmpty(governanceOptions) || !formik.values?.residence?.id || formDisabled,
      value: formik.values?.kazza,
      handleChange: (value) => {
        formik.setValues({
          ...formik.values,
          kazza: value,
          city: emptyValue,
        });
      },
      error: formik.touched.kazza && formik.errors.kazza?.id,
    },
    {
      id: "city-select",
      label: t("CITY"),
      placeHolder: t("SELECT_FROM_THE_LIST"),
      type: "select",
      options: citiesOptions,
      disabled: isEmpty(citiesOptions) || !formik.values?.kazza?.id || formDisabled,
      value: formik.values?.city,
      handleChange: (value) => formik.setFieldValue("city", value),
      error: formik.touched.city && formik.errors.city?.id,
    },
    // {
    //   id: "nationality-select",
    //   label: t("NATIONALITY"),
    //   placeHolder: t("SELECT_FROM_THE_LIST"),
    //   type: "select",
    //   options: countries,
    //   value: formik.values?.nationality,
    //   handleChange: (value) => formik.setFieldValue("nationality", value),
    //   disabled: formDisabled,
    //   error: formik.touched.residence && formik.errors.nationality,
    // },
    {
      id: "income-range-select",
      label: t("INCOME_RANGE"),
      placeHolder: t("SELECT_FROM_THE_LIST"),
      type: "select",
      options: incomeRanges,
      value: formik.values?.salaryRange,
      handleChange: (value) => formik.setFieldValue("salaryRange", value),
      disabled: formDisabled,
      error: formik.touched.salaryRange && formik.errors.salaryRange,
    },
  ];

  const renderResponsibilityCheckbox = () => {
    return !formDisabled ? (
      <Checkbox
        selected={formik.values.responsible}
        hasError={formik.touched.responsible && formik.errors.responsible}
        handleClick={() => formik.setFieldValue("responsible", !formik.values.responsible)}
        title={<div className="checkbox-text">{t("IM_RESPONSIBLE_FROM_THE_INFORMATION_MENTIONED_ABOVE")}</div>}
      />
    ) : null;
  };

  const descriptionText = eligibilityFormTexts("description", "initial");
  const processingText = eligibilityFormTexts("description", "pending");
  const approvedText = eligibilityFormTexts("description", "approved");
  const rejectedText = eligibilityFormTexts("description", "rejected", initialValues?.reason);

  return (
    <BuildProfileStepThreeWrapper className={className}>
      <SectionTitleWithLine
        title={t("APPLY_FOR_SPONSORSHIP")}
        description={!isApproved && descriptionText}
        error={isAwaiting ? processingText : initialValues?.rejected === 2 && !isApproved ? rejectedText : error}
      />
      {isApproved && <p className="approved-reason">{approvedText}</p>}
      {hide ? null : loading ? (
        loadingComponent
      ) : (
        <FormSectionInputWrapper>
          <div className="input-grid">
            <div className="sponsorship-input-column">
              {sponsorshipInputGrid.map((item: FormInputContent, index: number) => inputSelector(item, index))}
              {isDesktop && renderResponsibilityCheckbox()}
            </div>
            <div className="sponsorship-input-column">
              {inputSelector(
                {
                  id: "residence-type-radio-input",
                  options: residenceTypeOptions,
                  label: t("TYPE_OF_RESIDENCE"),
                  type: "radio",
                  showAll: true,
                  value: formik?.values?.residenceType,
                  disabled: formDisabled,
                  handleChange: (value) => formik.setFieldValue(`residenceType`, value),
                  error: getIn(formik.touched, `residenceType`) && getIn(formik.errors, `residenceType`),
                },
                0
              )}
              {/* {inputSelector(
                {
                  id: "disability-radio-input",
                  options: disabilityOptions,
                  label: t("DO_YOU_HAVE_ANY_DISABILITIES"),
                  type: "radio",
                  showAll: true,
                  disabled: formDisabled,
                  value: formik?.values?.disability,
                  handleChange: (value) =>
                    formik.setFieldValue(`disability`, value),
                  error:
                    getIn(formik.touched, `disability`) &&
                    getIn(formik.errors, `disability`),
                },
                0
              )}
          {formik?.values?.disability === 1 &&
                inputSelector(
                  {
                    id: "disability-description-text-input",
                    label: t("PLEASE_SPECIFY"),
                    type: "text",
                    disabled: formDisabled,
                    value: formik?.values?.disabilityDescription,
                    handleChange: (value) =>
                      formik.setFieldValue(`disabilityDescription`, value),
                    error:
                      getIn(formik.touched, `disabilityDescription`) &&
                      getIn(formik.errors, `disabilityDescription`),
                  },
                  0
                )}*/}
              <DragDropUploadFile
                label={t("UPLOAD_ID")}
                description={t("DRAG_AND_DROP_YOUR_ID_OR_PASSPORT_DOCUMENT_HERE")}
                value={formik.values?.idPhoto}
                handleChange={(file) => {
                  formik.setFieldValue(`idPhoto`, file);
                  formik.setFieldValue(`idPhotoName`, file.name);
                }}
                removePhoto={() => {
                  formik.setFieldValue(`idPhoto`, undefined);
                  formik.setFieldValue(`idPhotoName`, undefined);
                }}
                error={formik?.touched?.idPhotoName && formik?.errors?.idPhotoName}
                disabled={formDisabled}
              />
              {!isDesktop && renderResponsibilityCheckbox()}
            </div>
          </div>
        </FormSectionInputWrapper>
      )}
    </BuildProfileStepThreeWrapper>
  );
};

export default ApplyForSponsorshipForm;
