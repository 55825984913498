import { colors } from "&assets/constants/colors";
import { device } from "&assets/constants/responsiveness";
import styled from "styled-components";

export const LearningPathCardWrapper = styled.div`
  background: ${colors.linearGradientWhite} padding-box, ${colors.linearGradientSixth} border-box;
  border-radius: 12px;
  border: 1px solid transparent;
  width: 354px;
  max-width: 354px;
  box-sizing: border-box;
  min-height: 352px;
  padding: 30px 30px 15px 30px;
  display: flex;
  flex-direction: column;
  align-content: center;
  position: relative;
  transition: all 0.2s ease-in;
  cursor: pointer;
  .certification {
    position: absolute;
    width: 93px;
    height: 29px;
    background: ${colors.linearGradientOne};

    ${(props) => (props.theme.dir === "rtl" ? "border-radius: 0 8px 8px 0;" : "border-radius: 8px 0 0 8px;")}
    ${(props) => (props.theme.dir === "rtl" ? "left: 0;" : "right: 0;")}
    top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    font-weight: 800;
    font-size: 12px;
    line-height: 120%;
    color: ${colors.white};
    box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.25);
  }
  &:hover {
    background: ${colors.linearGradientWhite} padding-box, ${colors.linearGradientOne} border-box;
    box-shadow: 4px 4px 24px 0px #00000040;
  }

  .enrollment-dates {
    color: var(--blue-2, ${colors.primaryDark});
    font-family: Mulish;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 200%; /* 26px */
    margin: 0;
    height: 26px;
  }
  .card-img {
    object-fit: contain !important;
    height: 150px;
    max-width: 276px;
    border-radius: 10px;
  }
  .card-title {
    color: #2d2a35;
    font-family: "Mulish";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 120%;
    width: 100%;
    height: 44px;
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
  }
  .card-content {
    display: flex;
    align-items: center;
    .title {
      font-weight: 400;
      font-size: 14px;
      ${(props) => (props.theme.dir === "rtl" ? "margin-right: 10px;" : "margin-left: 10px;")}

      color: #4d525b;
      align-items: center;
      justify-content: center;
      display: flex;
      .text {
        ${(props) => (props.theme.dir === "rtl" ? "margin-right: 6px;" : "margin-left: 6px;")}
      }
    }
    .info {
      ${(props) => (props.theme.dir === "rtl" ? "margin-left: 0;" : "margin-left: 0;")}
      ${(props) => (props.theme.dir === "rtl" ? "margin-left: 20px;" : "margin-right: 20px;")}
    }
  }
  @media ${device.tablet} {
    padding: 16px;
    height: 320px;
    min-width: 296px;
  }
  @media ${device.mobile} {
    padding: 16px;
    height: 320px;
    min-width: 296px;
  }
`;
