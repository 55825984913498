import { colors } from "&assets/constants/colors";
import styled from "styled-components";
import { device } from "&assets/constants/responsiveness";

export const PrivacyPolicyWrapper = styled.div`
  .header {
    background: ${colors.linearGradientSixth};
    overflow: initial;
    height: 243px;
    border-bottom: 0;
  }
  .header-title {
    color: ${colors.white};
    max-width: 664px;
  }

  .title-subtitle-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .content-container {
    box-sizing: border-box;
    padding: 64px 0;
    max-width: 870px;
  }

  .loader-wrapper {
    height: 200px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media ${device.mobile}, ${device.tablet}, ${device.inBetween} {
    .content-container {
      padding: 48px 16px;
    }
  }
`;
