export const listingPageNameSpace = {
  en: {
    BACK: "Back",
    BOOST_YOUR_SKILLS: "Boost your skills and improve your career",
    SELECT_ONE_OF_THE_BELOW: "Search or select one of the below learning activities to get started",
    LEVEL: "Level",
    LEARNING_ACTIVITY: "Learning Type",
    SOURCE: "Source",
    SORT_BY: "Sort by",
    TYPE: "Type",
    ALL: "All",
    FREE: "Free",
    SUBSCRIPTION: "Paid",
    CERTIFICATION: "Certification",
    POPULAR: "Popular",
    NAME: "Name",
    DATE: "Date",
    LEARNING_PATH: "Learning Path",
    COURSES: "Courses",
    EXAMS: "Exams",
    ADVANCED: "advanced",
    INTERMEDIATE: "intermediate",
    BEGINNER: "beginner",
    SEARCH_COURSE_LEARNING_PATH_OR_SKILL: "Search course, learning path or skill",
    H: "h",
    M: "m",
    COURSE: "Course",
    LEARNING_ACTIVITIES: "Learning activities",
    EXPLORE_LEARNING_PATH: "Explore learning path",
    THIS_LEARNING_PATH_CONTAINS_SPONSORED_ACTIVITIES: "* This learning path contains sponsored activities!",
    QUICK_VIEW: "Quick view",
    CLOSE: "Close",
    FOR_THE_FOLLOWING_REASON: "for the following reason",
    UPLOAD_COMPLETION_AGAIN: "Upload Completion Again",
    VIEW_GAINED_SKILLS: "View gained skills",
    UPDATE_SKILLS_IN_PROFILE: "Update skills in profile",
    UPLOAD_COMPLETION_PROOF: "Upload Completion Proof",
    LAUNCH: "Launch",
    APPLY_FOR_VOUCHER: "Apply For Voucher",
    APPLY_FOR_SPONSORSHIP: "Apply For Sponsorship",
    SPONSORED: "Sponsored",
    SIGNUP: "Sign up",
    LOAD_MORE: "Load More",
    MATCHING_OPPORTUNITIES: "Matching opportunities",
    VIEW_ALL_OPPORTUNITIES: "View all opportunities",
    EXAM_LOCKED_AUTO_MESSAGE:
      "This is locked as some courses are not yet completed in this Learning Pathway. Please note that the completion of some courses is automatically tracked and it will take 48 hours to be reflected in our system.",
    EXAM_LOCKED_MANUAL_MESSAGE:
      "This is locked as some courses are not yet completed or validated in this Learning Pathway.",
    EXAM_LOCKED_MESSAGE:
      "This is locked as some courses are not yet completed in this Learning Pathway. Please note that the completion of some courses is automatically tracked and it will take 48 hours to be reflected in our system.",
    GO_TO_ELIGIBILITY_FORM: "Go to Eligibility Form",
    SEARCH_JOB_OPPORTUNITIES: "Search for opportunities",
    JOB_OPPORTUNITIES: "JOB OPPORTUNITIES",
    LATEST: "Latest",
    SEARCH_FOR_OPPORTUNITIES: "Search for opportunities",
    ENROLL_NOW: "Enroll now",
    BELOW_SKILLS: "The below skills will be added to your profile!",
    APPLY_FOR_ELIGIBILITY: "Apply for Eligibility",
    CONGRATULATIONS_YOUR_PROFILE_MEETS_THE_REQUIREMENTS:
      "Congratulations, your profile meets the requirements for this sponsorship opportunity. Click on “Apply for Sponsorship” for a chance to win free access to this paid activity.",
    CLICK_ON_APPLY_FOR_ELIGIBILITY_IF_YOU_ARE_INTERESTED:
      "Click on “Apply for Eligibility” if you are interested in getting sponsored. If you already bought a bundle for this activity, click “Launch to proceed",
    YOURE_READY_TO_USE_YOUR_EXAM_VOUCHER:
      'You\'re ready to use your exam voucher. Just click "Apply for Voucher" to move forward',
    YOU_ARE_ONE_STEP_AWAY_FROM_GETTING_SPONSORED: "You are one step away from getting sponsored!",
    YOU_ARE_ONE_STEP_AWAY_FROM_WINNING_YOUR_VOUCHER: "You are one step away from winning your voucher",
    CHECK_YOUR_ELIGIBILITY_TO_GET_SPONSORED: "Check your eligibility to get sponsored",
    COMPLETION_PROOF_VALIDATION: "*Your completion proof is under validation",
    COMPLETION_PROOF_REJECTED: "*Your completion proof has been rejected",
    COMPLETION_PROOF_ACCEPTED: "*Your completion proof has been accepted",
    COMPLETION_PROOF_FAILED:
      "*You can now request another exam voucher. Please ensure that you re-upload the proof of completion after finishing the exam",
    MORE_DETAILS: "More details",
    SKILLS_ADEDD_SUCCESS: "Skills added successfully",
    PROVIDER: "Provider",
    JOB_APPLIED: "Job Listing",
    CAREER: "Career",
    NO_JOB_OPPORTUNITIES_REMAINING: "No job opportunities found",
    VIEW_PAID_LEARNING_BUNDLES: "View Paid Learning Bundles",
    YOU_ARE_LAUNCHING_A_PAID_LEARNING_ACTIVITY:
      "You are launching a paid learning activity. You can subscribe to one of our learning bundles to get up to 50% discount on this activity! Click on “View Bundles” to check our learning plans. If you already bought a bundle, click “Launch” to proceed to the learning activity",
    VIEW_BUNDLES: "View Bundles",
    SORRY_NO_SEATS_AVAILABLE: "Receive Discounted Rates",
    WE_ARE_SORRY_TO_INFORM_YOU_THAT_NO_MORE_SPONSORED:
      "Unfortunately, full sponsorship is not available in your case, however, you can still enroll in the course at a discounted rate. Keep the momentum going!",
    NO_COURSES_FOUND: "No courses found",
    NO_EXAMS_FOUND: "No exams found",
    NO_LEARNING_PATH_FOUND: "No learning path found",
    WITH_EXAMS: "With exams",
    WITHOUT_EXAMS: "Without exams",
    SPONSORSHIP: "Sponsored",
    ORDER_BY: "Order",
    SHORTEST_TO_LONGEST: "Shortest",
    LONGEST_TO_SHORTEST: "Longest",
    DURATION: "Duration",
  },
  ar: {
    BACK: "السابق",
    BOOST_YOUR_SKILLS: "عزز مهاراتك وطوّر حياتك المهنية",
    JOB_APPLIED: "قائمة الوظائف",
    SELECT_ONE_OF_THE_BELOW: "ابحث أو اختر واحدًا من الأنشطة التعليمية أدناه للبدء",
    LEVEL: "مستوى",
    LEARNING_ACTIVITY: "انواع التعلم",
    SOURCE: "المصدر",
    ENROLL_NOW: "تسجل الآن",
    SORT_BY: "الترتيب بحسب",
    TYPE: "النوع",
    ALL: "الكل",
    FREE: "مجاني",
    SUBSCRIPTION: "مدفوع",
    CERTIFICATION: "شهادة",
    POPULAR: "الشعبية",
    NAME: "الاسم",
    DATE: "التاريخ",
    LEARNING_PATH: "المسار التعليمي",
    COURSES: "الدورات التدريبية",
    ADVANCED: "متقدم",
    INTERMEDIATE: "متوسط",
    BEGINNER: "مبتدئ",
    SEARCH_COURSE_LEARNING_PATH_OR_SKILL: "ابحث عن الدورة أو مسار التعلم أو المهارة",
    H: "س",
    M: "د",
    COURSE: "الدورة التدريبية",
    LEARNING_ACTIVITIES: "النشاطات التعليمية",
    EXPLORE_LEARNING_PATH: "اكتشف المسار التعليمي",
    THIS_LEARNING_PATH_CONTAINS_SPONSORED_ACTIVITIES: "* يتضمن هذا المسار التعليمي على نشاطات تحت الرعاية",
    QUICK_VIEW: "عرض سريع",
    CLOSE: "إغلاق",
    FOR_THE_FOLLOWING_REASON: "للسبب التالي",
    UPLOAD_COMPLETION_AGAIN: "تحميل الإتمام مرة أخرى",
    VIEW_GAINED_SKILLS: "عرض المهارات المكتسبة",
    UPDATE_SKILLS_IN_PROFILE: "تحديث المهارات في الملف الشخصي",
    UPLOAD_COMPLETION_PROOF: "تحميل وثيقة الاكمال",
    LAUNCH: "بدء",
    APPLY_FOR_VOUCHER: "اطلب القسيمة",
    APPLY_FOR_SPONSORSHIP: "تقدم للحصول على الرعاية",
    SPONSORED: "مع رعاية",
    SIGNUP: "إنشاء حساب",
    LOAD_MORE: "عرض المزيد",
    MATCHING_OPPORTUNITIES: "فرص العمل المطابقة",
    VIEW_ALL_OPPORTUNITIES: "عرض فرص العمل كلها",
    EXAM_LOCKED_AUTO_MESSAGE:
      "تم اقفال الامتحان لأن بعض الدورات التدريبية لم تكتمل بعد في هذا المسار التعليمي. يرجى الملاحظة أنه يتم تتبع إكمال بعض الدورات تلقائيًا وسيستغرق ظهورها في نظامنا 48 ساعة.",
    EXAM_LOCKED_MANUAL_MESSAGE:
      "تم اقفال الامتحان لأن بعض الدورات التدريبية لم تكتمل بعد أو لم يتم التحقق من اكمالها في هذا المسار التعليمي.",
    EXAM_LOCKED_MESSAGE:
      "تم اقفال الامتحان لأن بعض الدورات التدريبية لم تكتمل بعد في هذا المسار التعليمي. يرجى الملاحظة أنه يتم تتبع إكمال بعض الدورات تلقائيًا وسيستغرق ظهورها في نظامنا 48 ساعة.",
    SEARCH_JOB_OPPORTUNITIES: "ابحث عن فرص العمل",
    GO_TO_ELIGIBILITY_FORM: "انتقل إلى استمارة الأهلية",
    JOB_OPPORTUNITIES: "فرص عمل",
    LATEST: "الاحدث",
    BELOW_SKILLS: "سيتم إضافة المهارات التالية إلى ملفك الشخصي!",
    APPLY_FOR_ELIGIBILITY: "طلب التأكد من المؤهلات",
    CONGRATULATIONS_YOUR_PROFILE_MEETS_THE_REQUIREMENTS:
      "مبروك، ملفك الشخصي يطابق المعايير المطلوبة للحصول على فرصة  الرعاية. اضغط على “طلب الرعاية’’ لفرصة الحصول على هذه الخدمة مجاناً",
    CLICK_ON_APPLY_FOR_ELIGIBILITY_IF_YOU_ARE_INTERESTED:
      "إذا كنت ترغب بالحصول على الرعاية، اضغط على ’’طلب التأكد من المؤهلات’’. إذا كنت قد اشتريت باقة لهذا النشاط، اضغط على ’’ابدأ’’ للمتابعة",
    YOURE_READY_TO_USE_YOUR_EXAM_VOUCHER:
      "بإمكانك الآن استخدام القسيمة المخصصة لتقديم الاختبار. اضغط على ”طلب القسيمة” للمتابعة",
    YOU_ARE_ONE_STEP_AWAY_FROM_GETTING_SPONSORED: "باقي خطوة واحدة للحصول على الرعاية!",
    YOU_ARE_ONE_STEP_AWAY_FROM_WINNING_YOUR_VOUCHER: "انت على خطوة واحدة للفوز بالقسيمة",
    CHECK_YOUR_ELIGIBILITY_TO_GET_SPONSORED: "تحقق من مؤهلاتك للحصول على الرعاية",
    COMPLETION_PROOF_VALIDATION: "جاري التحقق من اكمالك للدورة التدريبية",
    COMPLETION_PROOF_REJECTED: "لم يتم التأكد من اكمالك للدورة التدريبية",
    COMPLETION_PROOF_ACCEPTED: "لقد تم التأكد من  اكمالك للدورة التدريبية",
    COMPLETION_PROOF_FAILED:
      "*يمكنك الآن طلب قسيمة أخرى للامتحان. يرجى التأكد من إعادة تحميل شهادة الإتمام بعد إتمام الامتحان",
    MORE_DETAILS: "المزيد من التفاصيل",
    SKILLS_ADEDD_SUCCESS: "تمت إضافة المهارات بنجاح",
    PROVIDER: "مقدم الخدمة",
    CAREER: "المهنة",
    NO_JOB_OPPORTUNITIES_REMAINING: "لا توجد فرص عمل",
    VIEW_PAID_LEARNING_BUNDLES: "تعرّف على باقاتنا التعليمية المدفوعة",
    YOU_ARE_LAUNCHING_A_PAID_LEARNING_ACTIVITY:
      "أنت على وشك البدء بنشاط تعليمي مدفوع . يمكنك شراء واحدة من الباقات التعليمية والحصول على تخفيض يصل إلى 50% على هذا النشاط! اضغط على ”تعرّف على الباقات” للتعرف على الباقات التعليمية. في حال قمت بشراء الباقة مسبقاً، اضغط على ”ابدأ” للمتابعة",
    VIEW_BUNDLES: "تعرّف على الباقات",
    SORRY_NO_SEATS_AVAILABLE: "احصل على أسعار مخفضة",
    WE_ARE_SORRY_TO_INFORM_YOU_THAT_NO_MORE_SPONSORED:
      "لسوء الحظ، لا تتوفر الرعاية الكاملة في حالتك، ومع ذلك، لا يزال بإمكانك التسجيل في الدورة بسعر مخفض. حافظ على الحماسة!",
    EXAMS: "الامتحانات",
    NO_COURSES_FOUND: "لم يتم العثور على دورات",
    NO_EXAMS_FOUND: "لم يتم العثور على الامتحانات",
    NO_LEARNING_PATH_FOUND: "لم يتم العثور على مسار التعلم",
    WITH_EXAMS: "مع الامتحانات",
    WITHOUT_EXAMS: "بدون امتحانات",
    SPONSORSHIP: "مدعوم",
    ORDER_BY: "ترتيب",
    SHORTEST_TO_LONGEST: "الأقصر ",
    LONGEST_TO_SHORTEST: "الأطول",
    DURATION: "مدة",
  },
};
