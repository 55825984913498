import React from "react";
import { MinusCircleIconWrapper } from "./minusCircleIcon.styled";
import CloseIconComponent from "&components/closeIcon/closeIcon.component";

interface Props {
  title?: string;
  onClick?: any;
  className?: string;
}

const MinusCircleIcon = (props: Props) => {
  const { onClick, className } = props;

  return (
    <MinusCircleIconWrapper>
      <CloseIconComponent
        className="icon-wrapper"
        onClick={onClick}
        icon={<div className={`minus ${className}`} />}
      />
    </MinusCircleIconWrapper>
  );
};

export default MinusCircleIcon;
