import React from "react";
import { ListingPageHeaderWrapper } from "./listingPageHeader.styled";
import ImageDisplay from "&components/imageDisplay/imageDisplay.component";
import { ArrowForwardIcon } from "&assets/constants/icons";
import { colors } from "&assets/constants/colors";
import Container from "&components/container/container.component";
import { images } from "&assets/constants/images-urls";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

interface Props {
  title?: string;
  description?: string;
  onBackClick?: () => void;
  content?: string;
}

const ListingPageHeader = (props: Props) => {
  const { title, description, onBackClick } = props;
  const { t } = useTranslation(["listingPage"]);

  return (
    <ListingPageHeaderWrapper>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={props.content} />
      </Helmet>
      <div className="gradient-layer" />
      <ImageDisplay
        isBackgroundImage={true}
        isFromS3={true}
        url={images.listingPageBackground}
        className={"background-image"}
      />
      <Container className="header-container">
        {onBackClick && (
          <button onClick={onBackClick} className="back-button">
            <div className="arrow">{ArrowForwardIcon(colors.white)}</div>
            <div className="button-text">{t("BACK")}</div>
          </button>
        )}
        <div className="title">{title}</div>
        <div className="description">{description}</div>
      </Container>
    </ListingPageHeaderWrapper>
  );
};

export default ListingPageHeader;
