import styled from "styled-components";
import { colors, skillsBackground } from "&assets/constants/colors";

interface Props {
  level?: number;
}

export const SkillChipWrapper = styled.div<Props>`
  padding: 6px 10px;
  min-width: 59px;
  height: 26px;
  background: ${(props) =>
    props?.level && skillsBackground[props.level - 1]
      ? skillsBackground[props.level - 1]
      : "#87c5e8"};
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) =>
    props.theme.dir === "rtl" ? "margin-left: 10px;" : "margin-right: 10px;"}
  margin-bottom: 6px;
  box-sizing: border-box;
  .chip-title {
    font-weight: 600;
    font-size: 9px;
    line-height: 150%;
    ${(props) => (props.theme.dir === "rtl" ? "" : "letter-spacing: 0.2em;")}
    text-transform: uppercase;
    color: ${colors.white};
    white-space: nowrap;
  }
`;
