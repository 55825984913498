import styled from "styled-components";
import { device } from "&assets/constants/responsiveness";

interface Props {
  activity?: string;
}

export const ListingPageWrapper = styled.div<Props>`
  .listing-container {
    padding: 0 64px;
    width: initial;
    box-sizing: border-box;
  }

  .list-wrapper {
    display: ${(props) =>
      props.activity === "courses" || "exams" ? "grid" : "flex"};
    grid-gap: 16px;
    grid-template-columns: auto auto auto auto;
    justify-content: center;
  }

  .loader-wrapper {
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .learning-paths {
    display: flex;
    flex-direction: column;
  }

  .no-learning-type-wrapper {
    padding: 100px 0;
  }
  .with-exams {
    width: 135px;
  }
  @media ${device.inBetween} {
    .list-wrapper {
      grid-template-columns: auto auto auto;
    }
  }

  @media ${device.tablet} {
    .list-wrapper {
      grid-template-columns: auto auto;
      padding: 0 37px;
    }
    .listing-container {
      padding: 0;
    }
  }

  @media ${device.mobile} {
    .list-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0 37px;
    }
    .listing-container {
      padding: 0;
    }
  }
`;

export const ListWithBarWrapper = styled.div``;
