import styled from "styled-components";
import { colors } from "&assets/constants/colors";

export const CloseIconWrapper = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 20px;
  box-sizing: border-box;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
  border: 1px solid transparent;
  background: ${colors.linearGradientWhite} padding-box, ${colors.linearGradientOne} border-box;
`;
