import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { TermsConditions } from "./termsConditions.type";
import {getPagesContentApi} from "&features/landingPage/landingPage.api";

const initialState: TermsConditions = {
  content: undefined,
  loadingContent: false,
};


const getPageContent = createAsyncThunk(
  "termsConditions/getPageContent",
  async (_, { rejectWithValue, getState, dispatch }: any) => {
    try {
      const content = await getPagesContentApi('terms');
      return content?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const termsConditionsSlice = createSlice({
  name: "termsConditions",
  initialState: initialState,
  reducers: {
    setTermsConditions: (state, action) => {
      return { ...state, ...action.payload };
    },
    reset: () => initialState,
  },

  extraReducers: (builder) => {
    builder.addCase(getPageContent.pending, (state, action) => {
      // Write pending logic here
      state.loadingContent = true;
    });
    builder.addCase(getPageContent.fulfilled, (state, action) => {
      // Write success logic here
      state.loadingContent = false;
      state.content = action.payload
    });
    builder.addCase(getPageContent.rejected, (state, action) => {
      // Write failure logic here
      state.loadingContent = false;
    });
  },
});

export const termsConditionsReducer = termsConditionsSlice.reducer;

export const termsConditionsActions = { ...termsConditionsSlice.actions, getPageContent };
