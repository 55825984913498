import styled from "styled-components";
import { size } from "&assets/constants/responsiveness";
import { colors } from "&assets/constants/colors";

export const AssessmentStepsWrapper = styled.div`
  min-height: 85px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  padding: 14px 45px;
  background: ${colors.white};
  border: 1px solid ${colors.borderLight};
  border-radius: 16px;
  align-items: center;

  hr {
    max-width: 160px;
    width: 160px;
    min-width: 120px;
    border: 1px #d6ddeb solid;
  }
  .steps-wrapper {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    gap: 20px;
  }

  .step-indecator {
    min-width: 16px;
    width: 16px;
    height: 16px;
    min-height: 16px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
  }
  .step-divider {
    display: contents;
  }
  .active-step {
    background-color: ${colors.primary};
  }
  .completed-step {
    background-color: ${colors.secondaryDark};
  }
  .pending-step {
    background-color: #2c383f50;
  }

  .active-step-text {
    color: ${colors.primary};
  }
  .completed-step-text {
    color: #1fb06b;
  }
  .pending-step-text {
    color: #2c383f50;
  }

  .divider {
    width: 40px;
    height: 0;
    border: 1px solid ${colors.borderLight};
    transform: rotate(90deg);
  }

  .step-number {
    font-weight: 900;
    font-size: 16px;
    line-height: 160%;
    color: ${colors.primary};
  }

  .description {
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    color: ${colors.dark};
  }

  .step-number-description-mobile {
    display: none;
  }

  @media (max-width: ${size.tablet}) {
    hr {
      display: none;
    }
  }
  @media (max-width: ${size.mobile}) {
    flex-direction: column;

    .steps-wrapper {
      justify-content: space-around;
      flex-wrap: wrap;
    }

    .divider {
      width: 55px;
      transform: initial;
    }
    .step-number-description-mobile {
      display: flex;
      margin-bottom: 8px;
    }

    .step-number {
      ${(props) => (props.theme.dir === "rtl" ? "padding-left: 8px;" : "padding-right: 8px;")}
      ${(props) => (props.theme.dir === "rtl" ? "margin-left: 8px;" : "margin-right: 8px;")}
      ${(props) =>
        props.theme.dir === "rtl"
          ? `border-left: 1px solid ${colors.borderLight};`
          : `border-right: 1px solid ${colors.borderLight};`}
    }
  }
`;

interface Props {
  selected?: boolean;
}

export const SingleStepsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  .active-item-description {
    color: black;
    opacity: 1;
  }
  span {
    font-size: 9px;
    color: 2C383F;
    ${(props) => (props.theme.dir === "rtl" ? "" : "letter-spacing: 1.8px;")}
    font-weight: 900;
    text-transform: uppercase;
  }
  div {
    font-size: 16px;
    font-weight: 400;
    opacity: 0.5;
    color: ${colors.dark};
  }
  @media (max-width: ${size.tablet}) {
    .step-title-seperator {
      display: none;
    }
  }
  @media (max-width: ${size.mobile}) {
    div,
    .step-title-seperator {
      display: none;
    }
  }
`;

export const StepWrapper = styled.div<Props>`
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: center;
  gap: 16px;
  .icon-circle-wrapper {
    min-width: 56px;
    max-width: 56px;
    min-height: 56px;
    max-height: 56px;
    border-radius: 28px;

    background: ${(props) => (props.selected ? colors.linearGradientOne : colors.linearGradientOne)};
    opacity: ${(props) => (props.selected ? 1 : 0.5)};
    transition: all 0.2s ease-in;
    transform: rotate(0.04deg);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .step-number-description {
    display: flex;
    flex-direction: column;
    ${(props) => (props.theme.dir === "rtl" ? "margin-right: 16px;" : "margin-left: 16px;")}
    opacity: ${(props) => (props.selected ? 1 : 0.6)};
    transition: all 0.3s ease-in;
  }

  .step-number-description-mobile {
    display: flex;
  }

  @media (max-width: ${size.tablet}) {
    align-items: center;
  }
  @media (max-width: ${size.mobile}) {
    align-items: center;
    justify-content: flex-start;
  }
`;
