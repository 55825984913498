import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "yup-phone";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

import { ContactUsWrapper } from "./contactUs.styled";
import InputComponent from "&components/inputs/input/input.component";
import { Box } from "@mui/material";
import SubmitButton from "&components/buttons/submitButton/submitButton.component";
import Container from "&components/container/container.component";
import { contactUsActions } from "&features/contactUs/contactUs.slice";
import { RootState } from "&store/store";
import { size } from "&assets/constants/responsiveness";
import { useTranslation } from "react-i18next";

interface Props {
  title?: string;
}

interface ContactUsForm {
  name: string;
  emailAddress: string;
  mobileNumber: string;
  subject: string;
  additionalMessage: string;
}

const ContactUs = (props: Props) => {
  const { t } = useTranslation(["contactUs"]);
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ maxWidth: size.mobile });
  const isTablet = useMediaQuery({
    minWidth: size.mobile,
    maxWidth: size.tablet,
  });
  const status = useSelector((state: RootState) => state.contactUs.status);
  const loading = useSelector((state: RootState) => state.contactUs.loading);

  const initialValues: ContactUsForm = {
    name: "",
    emailAddress: "",
    mobileNumber: "",
    subject: "",
    additionalMessage: "",
  };

  useEffect(() => {
    if (status && status === "EMAIL_SENT_SUCCESSFULLY") {
      formik.resetForm();
      dispatch(contactUsActions.setContactUs({ status: "" }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const contactUsSchema = Yup.object().shape({
    mobileNumber: Yup.string()
      .required("Required")
      .phone("", false, "Invalid mobile number"),
    emailAddress: Yup.string()
      .required("Required")
      .email("Invalid email address"),
    additionalMessage: Yup.string().required("Required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: contactUsSchema,
    validateOnChange: true,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleSubmit = (values: ContactUsForm) => {
    const { name, additionalMessage, emailAddress, mobileNumber, subject } =
      values;
    const body = {
      name,
      subject,
      email: emailAddress,
      message: additionalMessage,
      phoneNumber: mobileNumber,
    };
    dispatch(contactUsActions.sendEmail(body));
  };

  return (
    <ContactUsWrapper>
      <span className="contact-title">{t("STAY_CONNECTED")}</span>
      <span className="contact-description">{t("FILL_INFORMATION")}</span>
      <Container>
        <div className="contact-form">
          <div className="input-flex">
            <Box
              width={isMobile || isTablet ? "100%" : "48%"}
              marginBottom={isMobile || isTablet ? "20px" : 0}
            >
              <InputComponent
                label={t("YOUR_NAME")}
                placeholder={t("YOUR_NAME_PLACEHOLDER")}
                value={formik.values.name}
                onChange={(event: any) =>
                  formik.setFieldValue("name", event.target.value)
                }
                valid={formik.touched.name && formik.errors.name}
                isWhite
              />
            </Box>
            <Box width={isMobile || isTablet ? "100%" : "48%"}>
              <InputComponent
                label={t("EMAIL_ADDRESS")}
                placeholder={t("EMAIL_ADDRESS_PLACEHOLDER")}
                value={formik.values.emailAddress}
                onChange={(event: any) =>
                  formik.setFieldValue("emailAddress", event.target.value)
                }
                valid={
                  formik.touched.emailAddress && formik.errors.emailAddress
                }
                isWhite
              />
            </Box>
          </div>
          <div className="input-flex">
            <Box
              width={isMobile || isTablet ? "100%" : "48%"}
              marginBottom={isMobile || isTablet ? "20px" : 0}
            >
              <InputComponent
                label={t("MOBILE_NUMBER")}
                placeholder={t("MOBILE_NUMBER_PLACEHOLDER")}
                value={formik.values.mobileNumber}
                onChange={(event: any) =>
                  formik.setFieldValue("mobileNumber", event.target.value)
                }
                valid={
                  formik.touched.mobileNumber && formik.errors.mobileNumber
                }
                isWhite
              />
            </Box>

            <Box width={isMobile || isTablet ? "100%" : "48%"}>
              <InputComponent
                label={t("SUBJECT")}
                placeholder={t("SUBJECT_PLACEHOLDER")}
                value={formik.values.subject}
                onChange={(event: any) =>
                  formik.setFieldValue("subject", event.target.value)
                }
                valid={formik.touched.subject && formik.errors.subject}
                isWhite
              />
            </Box>
          </div>
          <InputComponent
            label={t("ANYTHING_ELSE")}
            placeholder={t("ANYTHING_ELSE_PLACEHOLDER")}
            value={formik.values.additionalMessage}
            onChange={(event: any) =>
              formik.setFieldValue("additionalMessage", event.target.value)
            }
            valid={
              formik.touched.additionalMessage &&
              formik.errors.additionalMessage
            }
            multiline={true}
            className="input textarea"
            isWhite
          />
          <div className="input-flex">
            <SubmitButton
              title={t("SUBMIT")}
              id="contact-submit-btn"
              handleClick={formik.handleSubmit}
              className="contact-us-button"
              loading={loading}
            ></SubmitButton>
          </div>
        </div>
      </Container>
    </ContactUsWrapper>
  );
};

export default ContactUs;
