import styled from "styled-components";
import { colors } from "&assets/constants/colors";
import { device } from "&assets/constants/responsiveness";

export const ListingPageHeaderWrapper = styled.div`
  width: 100%;
  min-height: 349px;
  position: relative;

  .header-container {
    box-sizing: border-box;
    padding: 16px 65px;
    z-index: 0;
  }

  .background-image {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -2;
    ${(props) => (props.theme.dir === "rtl" ? "transform: scaleX(-1);" : "")}
  }

  .gradient-layer {
    position: absolute;
    width: 100%;
    height: 100%;
    background: ${colors.linearGradientOne};
    ${(props) => (props.theme.dir === "rtl" ? "transform: scaleX(-1);" : "")}
    opacity: 0.9;
    z-index: -1;
  }

  .back-button {
    background: transparent;
    padding: 0;
    border: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    cursor: pointer;
    .arrow {
      ${(props) => (props.theme.dir === "rtl" ? "" : "transform: rotate(180deg);")}
      ${(props) => (props.theme.dir === "rtl" ? "margin-left: 14px;" : "margin-right: 14px;")}
    }

    .button-text {
      font-weight: 800;
      font-size: 18px;
      line-height: 160%;
      text-align: center;
      color: ${colors.white};
    }
  }

  .title {
    font-weight: 700;
    font-size: 48px;
    line-height: 110%;
    letter-spacing: -0.02em;
    font-feature-settings: "cv11" on;
    color: ${colors.white};
  }

  .description {
    font-weight: 500;
    margin-top: 32px;
    max-width: 976px;
    font-size: 18px;
    line-height: 160%;
    color: ${colors.white};
  }

  @media ${device.tablet} {
    min-height: 349px;

    .header-container {
      box-sizing: border-box;
      padding: 56px 16px;
    }

    .title {
      font-size: 40px;
    }

    .description {
      margin-top: 24px;
      margin-bottom: 32px;
    }
  }

  @media ${device.mobile} {
    min-height: 349px;

    .header-container {
      box-sizing: border-box;
      padding: 56px 16px;
    }

    .title {
      font-size: 40px;
    }

    .description {
      margin-top: 24px;
      margin-bottom: 32px;
    }
  }
`;
