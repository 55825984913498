import styled from "styled-components";
import { Popover } from "@mui/material";
import { colors } from "&assets/constants/colors";
import {device} from "&assets/constants/responsiveness";

export const NotificationPopoverWrapper = styled(Popover)`
  .MuiPopover-paper {
    background: ${colors.white};
    border-radius: 0px 0px 24px 24px;
    margin-top: 30px;
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
      0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 10px 14px 2px rgba(0, 0, 0, 0.12);

    @media ${device.mobile} {
      margin-top: 23px;
    }
  }
`;

export const NotificationPopoverContent = styled.div`
  display: flex;
  max-width: 391px;
  min-width: 300px;
  //height: 438px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  .view-all-button {
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: transparent;
    border: none;
    width: 100%;
    
    .view-all-text {
      font-size: 14px;
      font-style: normal;
      font-weight: 800;
      line-height: 160%;
      text-align: center;
      color: ${colors.primary};
    }
  }
`;


interface ItemProps {
  read?: boolean;
}

export const NotificationItem = styled.div<ItemProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 8px 16px 6px 16px;
  box-sizing: border-box;
  align-items: flex-start;
  border-bottom: 1px solid rgba(38, 50, 56, 0.10);
  background: ${props => !props.read ? 'rgba(16, 140, 210, 0.10)' : 'transparent' } ;
  
  .notification-message {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
    margin: 0;
    color: ${colors.dark};
    
    &.no-notifications-message{
      opacity: 0.5;
    }
  }
  .date-text {
    color: ${colors.primary};
    font-size: 9px;
    font-style: normal;
    font-weight: 800;
    line-height: 150%; /* 13.5px */
    letter-spacing: 1.8px;
    text-transform: uppercase;
    margin: 0 0 6px 0;
  }
  
`
