import styled from "styled-components";
import { colors } from "&assets/constants/colors";
import { device } from "&assets/constants/responsiveness";

export const NewCareerLevelsWrapper = styled.div`
  padding: 0 65px;
  box-sizing: border-box;
  .section-title {
    color: ${colors.primary};
    font-feature-settings: "cv11" on;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%; /* 35.2px */
    letter-spacing: -0.64px;
    margin: 0 0 24px;
  }

  .loader-wrapper {
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .section-description {
    color: ${colors.dark};
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
    margin: 0;
    opacity: 0.7;
  }

  .levels-signup-prompt-wrapper {
    display: flex;
    margin: 32px 0 8px;
  }

  .view-all-careers-button-wrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;

    .view-all-careers-button {
      display: flex;
      padding: 13px 20px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      align-self: stretch;
      border-radius: 30px;
      border: 1px solid ${colors.primary};
      background: transparent;

      .button-title {
        color: ${colors.primary};
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 800;
        line-height: 160%; /* 22.4px */
      }
    }
  }
  @media ${device.inBetween} {
    .levels-signup-prompt-wrapper {
      overflow-x: auto;
      overflow-y: hidden;
      height: fit-content;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  @media ${device.tablet}, ${device.mobile} {
    padding: 0 16px;
    .levels-signup-prompt-wrapper {
      display: flex;
      flex-direction: column-reverse;
      margin: 32px 0 8px;
    }
    .section-title {
      font-size: 28px;
    }
  }
`;

export const LevelsWrapper = styled.div`
  ${(props) => (props.theme.dir === "rtl" ? "margin-left: 44px;" : "margin-right: 44px;")}
`;
