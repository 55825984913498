import React, { useState } from "react";
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import { useLocation } from "react-router-dom";
import { UserCircleWrapper } from "./userCircle.styled";
import { colors } from "&assets/constants/colors";
import { RootState } from "&store/store";
import { ArrowForwardIcon, CameraIcon, ProfilePicPlaceholder } from "&assets/constants/icons";
import { UserWorkExperience } from "&features/login/login.type";
import { EditSelection, SectionTypes } from "&features/dashboard/screens/profile/profile.type";
import ProfileEditModal from "&components/profile/edit/profileEditModel/profileEditModal.component";
import ImageDisplay from "&components/imageDisplay/imageDisplay.component";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import EditRoundButton from "&components/buttons/editRoundButton/editRoundButton.component";

interface Props {
  onClick?: () => void;
  viewMode?: boolean;
}

const UserCircle = (props: Props) => {
  const { onClick, viewMode } = props;
  const { pathname } = useLocation();
  const { t } = useTranslation(["dashboard"]);

  const [selectedEdit, setSelectedEdit] = useState<EditSelection>({
    section: undefined,
    initialValues: undefined,
    index: -1,
  });

  // const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.login.user);
  const isProfile = pathname === "/dashboard/profile";

  const dir = i18n.dir();

  const latestExperience: UserWorkExperience | undefined =
    (user?.workExperience && user?.workExperience[0]) || undefined;

  const handleChangeProfilePic = () => {
    setSelectedEdit({
      section: "profilePicture",
      initialValues: user?.image || undefined,
    });
  };

  const handleAddEditSection = (section: SectionTypes, initialValue?: any, index?: number) => {
    setSelectedEdit({
      section: section,
      index,
      initialValues: initialValue || undefined,
    });
  };

  return (
    <UserCircleWrapper onClick={onClick}>
      {!viewMode && (
        <div className="progress-circle-wrapper ">
          {isProfile && (
            <div className="change-picture-wrapper" onClick={handleChangeProfilePic}>
              {<CameraIcon />}
            </div>
          )}
          <div className="progress-background" />
          <CircularProgress
            variant={"determinate"}
            value={(user?.completionScore && (dir === "rtl" ? -user?.completionScore : user?.completionScore)) || 0}
          />
          <div className="percentage-circle">
            {user?.image ? (
              <ImageDisplay url={user?.image} isBackgroundImage={true} isFromS3={true} className="user-profile" />
            ) : (
              <ProfilePicPlaceholder />
            )}
          </div>
        </div>
      )}
      <div className="user-information">
        <div className="name-edit-section">
          <span className="user-name">
            {user?.name} {user?.lastName}
          </span>
          {!viewMode && (
            <EditRoundButton
              onClick={() => handleAddEditSection("fullname", `${user?.name}|${user?.lastName}`)}
              className="edit-button-wrapper"
            />
          )}
        </div>
        {latestExperience && (
          <span className="user-title">
            {`${latestExperience?.title || ""} ${isProfile && latestExperience?.companyName ? `${t("AT")} ` : ""}`}
            <span className="company-name">
              {isProfile && latestExperience?.companyName ? latestExperience?.companyName : null}
            </span>
          </span>
        )}
        {/* {isProfile && latestExperience?.location ? (
          <UserLocation isWhite={true} location={latestExperience?.location} />
        ) : ( */}
        {!viewMode && (
          <div className="profile-completeness">
            <span className="first-text">{t("PROFILE_COMPLETENESS")}</span>
            <span className="percent-text">
              {user?.completionScore || ""}
              {user?.completionScore ? "%" : ""}
            </span>
            <span>{ArrowForwardIcon(colors.secondary, 16)}</span>
          </div>
        )}
        <ProfileEditModal
          handleClose={() => setSelectedEdit({ section: undefined, initialValues: undefined })}
          open={!!selectedEdit?.section}
          selectedSection={selectedEdit}
        />
      </div>
    </UserCircleWrapper>
  );
};

export default UserCircle;
