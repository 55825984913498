import React, { forwardRef } from "react";
import { map } from "lodash";
import { Fade, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import { Exam } from "&features/listingPage/listingPage.type";
import { translationSelector } from "&utils/translationSelector";
import { CertificationsDetailsWrapper } from "./certificationDetails.styled";
import { ref } from "yup";

interface Props {
  exam: Exam;
  ref: HTMLInputElement;
}

const CertificationsDetailsComponent = forwardRef<HTMLDivElement, Props>(({ exam }, ref) => {
  const { t } = useTranslation(["certifications"]);

  return (
    <CertificationsDetailsWrapper ref={ref}>
      <div className="certf-details-wrapper">
        <Grid
          container
          spacing={1}
          className="certificate-details"
          sx={{
            marginTop: "0px",
            maxWidth: "1440px",
            margin: "auto",
          }}
        >
          <Grid item xs={12} sm={6}>
            {" "}
            <Fade in={true}>
              <p className="certification-title">{t("OVERVIEW")}</p>
            </Fade>
            <Fade in={true}>
              <div
                className="certification-description"
                dangerouslySetInnerHTML={{ __html: translationSelector(exam, "about") }}
              />
            </Fade>
          </Grid>
          <Grid item xs={12} sm={6}>
            {" "}
            <Fade in={true}>
              <p className="certification-title">{t("BENEFITS")}</p>
            </Fade>
            <Fade in={true}>
              <div
                className="certification-description"
                dangerouslySetInnerHTML={{ __html: translationSelector(exam, "benefits") }}
              />
            </Fade>
          </Grid>
          <Grid item xs={12} sm={6}>
            {" "}
            <Fade in={true}>
              <p className="certification-title">{t("RESOURCES")}</p>
            </Fade>
            <Fade in={true}>
              <div
                className="certification-description"
                dangerouslySetInnerHTML={{ __html: translationSelector(exam, "resources") }}
              />
            </Fade>
          </Grid>
          <Grid item xs={12} sm={6}>
            {" "}
            <Fade in={true}>
              <p className="certification-title">{t("REQUIREMENTS")}</p>
            </Fade>
            <Fade in={true}>
              <div
                className="certification-description"
                dangerouslySetInnerHTML={{ __html: translationSelector(exam, "preRequisites") }}
              />
            </Fade>
          </Grid>
          <Grid item xs={12} sm={6}>
            {" "}
            <Fade in={true}>
              <p className="certification-title">{t("SKILLS")}</p>
            </Fade>
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
              }}
            >
              {map(exam.examSkills, (skills, index) => {
                return (
                  <Fade in={true}>
                    <span className="skill-div" key={index}>
                      {translationSelector(skills.skills, "name")}
                    </span>
                  </Fade>
                );
              })}
            </div>
          </Grid>
        </Grid>
      </div>
    </CertificationsDetailsWrapper>
  );
});
export default CertificationsDetailsComponent;
