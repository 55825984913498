import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment/moment";

import { PersonalDetails } from "&features/dashboard/screens/profile/profile.type";
import {
  FormInputContent,
  UpdateProfileBody,
} from "&features/login/login.type";
import { ProfileModalInnerContent } from "&components/profile/edit/profileEditModel/profileEditModal.styled";
import inputSelector from "&utils/ui/inputSelector";
import { genderOptions } from "&assets/constants/profile";
import { useTranslation } from "react-i18next";

interface Props {
  initialValues?: PersonalDetails;
  submit?: number;
  updateProfile: (body: UpdateProfileBody) => void;
}

const ProfileAddPersonalDetails = (props: Props) => {
  const { updateProfile, submit, initialValues } = props;

  const values: PersonalDetails = {
    gender: initialValues?.gender || undefined,
    dateOfBirth: initialValues?.dateOfBirth || undefined,
  };
  const { t } = useTranslation(["profile"]);

  const validationSchema = Yup.object().shape({
    gender: Yup.string()?.required(t("REQUIRED")),
    dateOfBirth: Yup.date()
      .typeError(t("INVALID_REQUIRED_DATE"))
      .max(
        moment().subtract(14, "years"),
        t("DATE_OF_BIRTH_CAN_NOT_BE_IN_THE_FUTURE")
      )
      .required(t("REQUIRED")),
  });

  const formik: any = useFormik({
    initialValues: values,
    validationSchema: validationSchema,
    onSubmit: () =>
      updateProfile({
        gender: formik.values.gender,
        dateOfBirth: formik.values.dateOfBirth,
      }),
  });

  useEffect(() => {
    submit && submit > 0 && formik.handleSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submit]);

  const additionalDetailsInputGrid: FormInputContent[] = [
    {
      id: "gender-edit-options-input",
      label: t("GENDER"),
      type: "radio",
      options: genderOptions,
      value:
        (formik.values?.gender?.toLowerCase() === "male" ? 1 : 2) || undefined,
      handleChange: (value) =>
        formik.setFieldValue("gender", value === 1 ? "male" : "female"),
      error: formik.touched.gender && formik.errors.gender,
    },
    {
      id: "date-of-birth-edit-date-input",
      label: t("DATE_OF_BIRTH"),
      placeHolder: t("SELECT_FROM_CALENDAR"),
      type: "date",
      value: formik.values?.dateOfBirth,
      max: moment().subtract(14, "years"),
      handleChange: (value) => formik.setFieldValue("dateOfBirth", value),
      error: formik.touched.dateOfBirth && formik.errors.dateOfBirth,
    },
  ];

  return (
    <ProfileModalInnerContent>
      <div className="input-grid">
        {additionalDetailsInputGrid?.map(
          (item: FormInputContent, index: number) =>
            !item.hide && inputSelector(item, index)
        )}
      </div>
    </ProfileModalInnerContent>
  );
};

export default ProfileAddPersonalDetails;
