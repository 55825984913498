import React, { ChangeEvent } from "react";
import { InputProps } from "@mui/material";

import { InputWrapper, LabelWrapper } from "./input.styled";
import { muiInputTypes } from "&features/global/global.type";

interface Props {
  id?: string;
  type?: muiInputTypes;
  label?: string;
  wrapperClassName?: string;
  className?: string;
  labelClassName?: string;
  value: string;
  onChange: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  placeholder?: string;
  icon?: any;
  valid?: any;
  isGradient?: boolean;
  disabled?: boolean;
  isWhite?: boolean;
  multiline?: boolean;
  onEnterKey?: () => void;
  labelIcon?: any;
}

const InputComponent = (props: Props & InputProps) => {
  const {
    id,
    label,
    className,
    labelClassName,
    onChange,
    value,
    placeholder,
    icon,
    valid,
    isGradient,
    wrapperClassName,
    type = "text",
    disabled,
    multiline,
    isWhite,
    onEnterKey,
    labelIcon,
  } = props;

  const handleEnterKey = (value: any) => {
    if (value === "Enter") {
      onEnterKey && onEnterKey();
    }
  };

  return (
    <div className={`wrapperClassName ${wrapperClassName}`}>
      <LabelWrapper disabled={disabled} valid={valid} className={labelClassName} isWhite={isWhite}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {label} {labelIcon && labelIcon}
        </div>
      </LabelWrapper>

      <InputWrapper
        type="text"
        {...props}
        isgradient={isGradient}
        id={id}
        value={value}
        disabled={disabled}
        inputProps={{ type: type }}
        multiline={multiline}
        title={valid}
        onKeyDown={(value) => handleEnterKey(value?.key)}
        endAdornment={icon}
        placeholder={placeholder}
        onChange={(event) => onChange(event)}
        className={`${className} ${isGradient ? "is-gradient" : ""}`}
        valid={valid}
      />
    </div>
  );
};

export default InputComponent;
