export const dashboardNameSpace = {
  en: {
    DASHBOARD: "Dashboard",
    MY_LEARNING_ACTIVITIES: "My Learning Activities",
    AVAILABLE_OPPORTUNITIES: "Available Opportunities",
    AT: "AT",
    PROFILE_COMPLETENESS: "Profile Completeness",
    MY_CURRENT_LEARNINGS: "My Current Learnings",
    COURSES: "Courses",
    GO_TO_LEARNING_PATHWAY: "Go to learning pathway",
    MONTHS: "months",
    DAYS: "days",
    HOURS: "hours",
    GO_TO: "Go to",
    course: "course",
    exam: "exam",
    lab: "lab",
    COURSE_SKILLS: "Course Skills",
    MORE: "More",
    SKILLS: "Skills",
    THINKING_OF_UPGRADING_YOUR_CAREER: "Thinking of upgrading your career?",
    CAREER_MATCHING: "Career Matching",
    VIEW_CAREER: "View Career",
    RECOMMENDED_LEARNING_PATHS: "Recommended Learning Paths",
    RECOMMENDED_COURSES: "Recommended Courses",
    MATCHING_OPPORTUNITIES: "Matching Opportunities",
    VIEW_ALL: "View All",
    APPLY_NOW: "Apply Now",
    REGISTERED_COURSES: "Registered Courses",
    REGISTERED_LEARNING_PATHWAY: "Registered Learning pathway",
    COMPLETED: "Completed",
    HIDE_DETAILS: "Hide Details",
    SHOW_DETAILS: "Show Details",
    GO_TO_ELIGIBILITY_FORM: "Go to Eligibility Form",
    COMPLETED_LEARNING_PATHWAY: "Completed Learning pathway",
    COMPLETED_COURSES: "Completed Courses",
    MORE_DETAILS: "More Details",
  },
  ar: {
    DASHBOARD: "لوحة المعلومات",
    MY_LEARNING_ACTIVITIES: "نشاطاتي التعليمية",
    AVAILABLE_OPPORTUNITIES: "الفرص المتاحة",
    AT: "في",
    PROFILE_COMPLETENESS: "اكتمال الملف",
    MY_CURRENT_LEARNINGS: "مكتسباتي التعليمية الحالية",
    COURSES: "دورات تعليمية",
    GO_TO_LEARNING_PATHWAY: "انتقل إلى المسار التعليمي",
    MONTHS: "أشهر",
    DAYS: "أيام",
    HOURS: "ساعات",
    GO_TO: "اذهب الى",
    course: "الدورة التعليمية",
    exam: "الامتحان",
    lab: "العمل التطبيقي",
    COURSE_SKILLS: "مهارات الدورة",
    MORE: "المزيد",
    SKILLS: "المهارات",
    THINKING_OF_UPGRADING_YOUR_CAREER: "هل تفكر في الارتقاء بمهنتك؟",
    CAREER_MATCHING: "مطابقة المهنة",
    VIEW_CAREER: "عرض المهنة",
    RECOMMENDED_LEARNING_PATHS: "المسارات التعليمية الموصى بها",
    RECOMMENDED_COURSES: "الدورات التدريبية الموصى بها",
    MATCHING_OPPORTUNITIES: "الفرص المطابقة",
    VIEW_ALL: "اظهار الكل",
    APPLY_NOW: "تقدم الآن",
    REGISTERED_COURSES: "الدورات المسجلة",
    REGISTERED_LEARNING_PATHWAY: "المسارات التعليمية المسجلة",
    COMPLETED: "منجز",
    HIDE_DETAILS: "إخفاء التفاصيل",
    SHOW_DETAILS: "إظهار التفاصيل",
    GO_TO_ELIGIBILITY_FORM: "انتقل إلى استمارة الأهلية",
    COMPLETED_LEARNING_PATHWAY: "مسار تعليمي مكتمل",
    COMPLETED_COURSES: "نشاط تعليمي مكتمل",
    MORE_DETAILS: "المزيد من التفاصيل",
  },
};
