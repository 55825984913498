import styled from "styled-components";
import { device } from "&assets/constants/responsiveness";

interface Props {
  short?: boolean;
  vertical?: boolean;
  showAll?: boolean;
}

export const ListCareersWrapper = styled.div<Props>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 208px;
  position: relative;
  box-sizing: content-box;
  max-width: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media ${device.desktop}, ${device.inBetween} {
    ${(props) =>
      props.showAll ? "flex-wrap: wrap;\n  justify-content: flex-start;" : ""}
    flex-direction: ${(props) => (props.vertical ? "column" : "row")};
  }

  @media ${device.tablet}, ${device.mobile} {
    flex-direction: ${(props) =>
      props.vertical && props.showAll ? "column" : "row"};
    overflow-x: ${(props) =>
      props.short ? "visible" : props.vertical && props.showAll ? "visible" : "auto"};
    justify-content: initial;
    min-height: 175px;
  }
`;
