import React from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";

import LearningPathCard from "&components/learningPathCard/learningPathCard.component";
import TextLine from "&components/textLine/textLine.component";
import { DashboardListingWrapper } from "./dashboardListing.styled";
import ActivityCard from "&components/cards/activityCard/activityCard.component";
import SubmitButton from "&components/buttons/submitButton/submitButton.component";
import MatchingOpportunitiesCard from "&components/matchingOpportunitiesCard/matchingOpportunitiesCard.component";
import { size } from "&assets/constants/responsiveness";
import CardsCarousel from "&components/cardsCarousel/cardsCarousel.component";
import { RootState } from "&store/store";
import getCDNProtectedURL from "&utils/getCDNProtectedImageSource";
import { images } from "&assets/constants/images-urls";
import { checkIFEnrolledCourse } from "&utils/checkIfEnrolled";
import { useTranslation } from "react-i18next";
import { translationSelector } from "&utils/translationSelector";

interface Props {
  title?: string;
  data?: any;
  isCourse?: boolean;
  isJob?: boolean;
  showViewAll?: boolean;
}

const DashboardListing = (props: Props) => {
  const { title, data, isCourse, showViewAll = false, isJob } = props;
  const navigate = useNavigate();
  const { t } = useTranslation(["dashboard"]);

  const user = useSelector((state: RootState) => state.login.user);
  const isMobile = useMediaQuery({ maxWidth: size.mobile });
  const isTablet = useMediaQuery({
    minWidth: size.mobile,
    maxWidth: size.tablet,
  });
  const handleViewAllClick = () => {
    isCourse
      ? navigate("/listing-page?learning_activity=courses")
      : isJob
      ? navigate("/dashboard/opportunities")
      : navigate("/listing-page?learning_activity=learning_path");
  };

  const Component = ({ children }: any) => {
    return isMobile || isTablet ? (
      <CardsCarousel>{children}</CardsCarousel>
    ) : (
      <>{children}</>
    );
  };

  return (
    <DashboardListingWrapper>
      <TextLine title={title} />
      <div className="list-wrapper">
        <Component>
          {isCourse
            ? data.map((item: any, index: number) => {
                const data = item?.courses || item;
                return (
                  <div key={index} className="course-cards">
                    <ActivityCard
                      key={index}
                      type="course"
                      course={data}
                      handleClick={() => {
                        checkIFEnrolledCourse(data?.id, user)
                          ? navigate(`/dashboard/course/${data?.id}`)
                          : navigate(`/course-details/${data?.id}`);
                      }}
                    />
                  </div>
                );
              })
            : isJob
            ? data.map((item: any, index: number) => {
                return (
                  <div key={index} className="matching-opportunities-card">
                    <MatchingOpportunitiesCard
                      id={item?.id}
                      url={translationSelector(item, "url")}
                      level={translationSelector(item?.level, "alias")}
                      title={translationSelector(item, "title")}
                      description={translationSelector(
                        item,
                        "shortDescription"
                      )}
                      provider={item?.source}
                      skills={item?.jobSkills}
                      buttonText={t("MORE_DETAILS")}
                      location={item?.location}
                      company={item?.company ?? item?.internalCompany?.name}
                      date={item?.datePosted ?? item?.createdAt}
                      external={item?.external && item?.external === 1}
                    />
                  </div>
                );
              })
            : data.map((item: any, index: number) => {
                return (
                  <div key={index} className="course-cards">
                    <LearningPathCard
                      image={
                        item?.image ||
                        getCDNProtectedURL(images.learningPathwayImg)
                      }
                      hasExam={item?.learningPathExams.length > 0}
                      time={item?.duration}
                      modul={
                        item?.learningPathCourses?.length + ` ${t("COURSES")}`
                      }
                      title={translationSelector(item, "name")}
                      subTitle={
                        item?.learningPathExams?.length > 0
                          ? item?.learningPathExams[0]?.exams?.source
                          : item?.source
                      }
                      level={translationSelector(item?.level, "alias")}
                      handleClick={() => {
                        navigate(`/learning-pathway/${item.id}`);
                      }}
                      className="learning-path"
                    />
                  </div>
                );
              })}
        </Component>
      </div>
      {showViewAll && (
        <SubmitButton
          title={t("VIEW_ALL")}
          id={`view-all-button`}
          handleClick={() => {
            handleViewAllClick();
          }}
          className="view-all-button"
          titleClassName="view-all-button-title"
        />
      )}
    </DashboardListingWrapper>
  );
};

export default DashboardListing;
