import React from "react";
import { CalendarInputWrapper } from "./calendarInput.styled";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ar from "date-fns/locale/ar";
import { Moment } from "moment";

import { LabelWrapper } from "&components/inputs/input/input.styled";
import i18n from "&config/i18n";

interface Props {
  id: string;
  label?: string;
  className?: string;
  value?: Moment | null;
  placeholder?: string;
  icon?: any;
  valid?: any;
  labelClassName?: string;
  isYear?: boolean;
  handleChange: (date: Moment) => void;
  handleChangeFormatted?: (
    value: string | null,
    keyboardInputValue?: string | undefined
  ) => void;
  max?: Moment;
  min?: Moment;
}

const CalendarInput = (props: Props) => {
  const {
    id,
    label,
    value,
    valid,
    max,
    min,
    handleChange,
    isYear,
    className,
    placeholder,
    labelClassName,
  } = props;

  const isArabic = i18n.language === "ar";
  //
  return (
    <CalendarInputWrapper className={className}>
      <LabelWrapper valid={valid} className={labelClassName}>
        {label}
      </LabelWrapper>
      <DatePicker
        id={id}
        selected={value ? new Date(value.toString()) : null}
        onChange={(value: any) => handleChange(value)}
        locale={isArabic ? ar : ""}
        placeholderText={placeholder}
        className={`input-width ${className}`}
        minDate={min && new Date(min.toString())}
        maxDate={max && new Date(max.toString())}
        dateFormat={isYear ? "yyyy" : "dd/MM/yyyy"}
        showYearDropdown
        yearDropdownItemNumber={50}
        scrollableYearDropdown
        showYearPicker={isYear}
      />
    </CalendarInputWrapper>
  );
};

export default CalendarInput;
