import React, { useEffect } from "react";
import InputComponent from "&components/inputs/input/input.component";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ProfileModalInnerContent } from "&components/profile/edit/profileEditModel/profileEditModal.styled";
import { UpdateProfileBody } from "&features/login/login.type";
import { useTranslation } from "react-i18next";

interface Props {
  updateProfile: (body: UpdateProfileBody) => void;
  initialValues?: string;
  submit?: number;
}

const ProfileAddAboutMe = (props: Props) => {
  const { initialValues = "", submit, updateProfile } = props;
  const { t } = useTranslation(["profile"]);

  const values: any = {
    aboutMe: initialValues || "",
  };

  const validationSchema = Yup.object().shape({
    aboutMe: Yup.string().trim().notRequired(),
  });

  const formik: any = useFormik({
    initialValues: values,
    validationSchema: validationSchema,
    onSubmit: () => updateProfile({ aboutMe: formik.values.aboutMe?.trim() }),
  });

  useEffect(() => {
    submit && submit > 0 && formik.handleSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submit]);

  return (
    <ProfileModalInnerContent>
      <InputComponent
        multiline={true}
        label={""}
        placeholder={t("DESCRIBE_YOURSELF")}
        value={formik.values?.aboutMe}
        onChange={(value) =>
          formik.setFieldValue("aboutMe", value.target.value)
        }
        valid={formik.touched.aboutMe && formik.errors.aboutMe}
      />
    </ProfileModalInnerContent>
  );
};

export default ProfileAddAboutMe;
