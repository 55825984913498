import styled from "styled-components";
import { colors } from "&assets/constants/colors";

export const SectionWrapper = styled.div`
  padding-bottom: 45px;

  .description-text {
    margin-top: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    color: ${colors.primary};
  }
`;
