import React from "react";
import { SkillOptionTagWrapper } from "./skillOptionTag.styled";
import { XIcon } from "&assets/constants/icons";
import { AutocompleteGetTagProps } from "@mui/material";

interface Props extends ReturnType<AutocompleteGetTagProps> {
  title?: string;
  id: number;
  className?: string;
  hideX?: boolean;
}

const SkillOptionTag = (props: Props) => {
  const { title, onDelete, id, className, hideX, ...other } = props;
  return (
    <SkillOptionTagWrapper
      id={`other-option-tag-${id}`}
      className={className}
      title={title}
      {...other}
    >
      <div className="content">
        <div className="title">{title}</div>
        {!hideX && (
          <button
            id={`delete-${id}`}
            onClick={onDelete}
            className="delete-icon"
          >
            <XIcon />
          </button>
        )}
      </div>
    </SkillOptionTagWrapper>
  );
};

export default SkillOptionTag;
