import { colors } from "&assets/constants/colors";
import { device } from "&assets/constants/responsiveness";
import styled from "styled-components";

export const FrequentlyAskedQuestionsWrapper = styled.div`
  .header {
    background: ${colors.primaryDark};
    overflow: initial;
    height: 243px;
    border-bottom: 0;
  }
  .title-subtitle-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .header-title {
    color: ${colors.white};
    max-width: 664px;
  }
  .question {
    display: flex;
    flex-direction: column;
    padding: 72px 289px 40px 289px;
    .title {
      font-weight: 700;
      font-size: 48px;
      line-height: 110%;
      text-align: center;
      letter-spacing: -0.02em;
      color: ${colors.primaryDark};
      margin-bottom: 24px;
    }
    .sub-title {
      font-weight: 500;
      font-size: 18px;
      line-height: 160%;
      text-align: center;
      color: ${colors.dark};
      opacity: 0.7;
    }
  }

  @media ${device.tablet} {
    .question {
      padding: 50px 30px;
      .title {
        font-size: 30px;
      }
      .sub-title {
        font-size: 18px;
      }
    }
  }

  @media ${device.mobile} {
    .question {
      padding: 40px 16px;
      .title {
        font-size: 28px;
      }
      .sub-title {
        font-size: 16px;
      }
    }
  }
`;
