import React, { useState } from "react";
import "react-phone-number-input/style.css";
import en from "react-phone-number-input/locale/en.json";
import ar from "react-phone-number-input/locale/ar.json";
import PhoneInput, {
  getCountries,
  isValidPhoneNumber,
} from "react-phone-number-input";
import {
  PhoneNumberInputWrapper,
  LabelWrapper,
} from "./phoneNumberInput.styled";
import { useTranslation } from "react-i18next";
interface Props {
  id?: string;
  value: any;
  className?: string;
  onChange?: any;
  label?: string;
  placeholder?: string;
}

const PhoneNumberInput = (props: Props) => {
  const { i18n } = useTranslation();

  const { id, value, onChange, placeholder, label } = props;
  const [error, setError] = useState(false);
  const handleChange = (value: any) => {
    const valid = value && isValidPhoneNumber(value);
    setError(!valid);
    onChange(value);
  };

  const localeData = i18n.language === "ar" ? ar : en;
  const filteredCountries = getCountries().filter(
    (country) => country !== "IL"
  );

  return (
    <div className={"wrapperClassName"}>
      <LabelWrapper error={error}>{label}</LabelWrapper>
      <PhoneNumberInputWrapper error={error}>
        {filteredCountries && (
          <PhoneInput
            id={id}
            placeholder={placeholder}
            value={value}
            defaultCountry="LB"
            international
            onChange={handleChange}
            label={label}
            countries={filteredCountries}
            labels={localeData}
            error={error}
          />
        )}
      </PhoneNumberInputWrapper>
    </div>
  );
};

export default PhoneNumberInput;
