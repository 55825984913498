import moment from "moment";
import * as Yup from "yup";
import { Grid, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getIn, useFormik } from "formik";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { RootState } from "&store/store";
import { AddExperienceButton, BuildProfileStepOneWrapper, FormSectionInputWrapper } from "./buildProfileStepOne.styled";

import PlusIcon from "&assets/images/plus.svg";
import { ProfileInfo } from "&assets/constants/icons";
import { disabilityOptions, educationDegrees, genderOptions } from "&assets/constants/profile";

import Checkbox from "&components/checkbox/checkbox.component";
import RadioGroup from "&components/radioGroup/radioGroup.component";
import ReferralPicker from "&components/referralsPicker/referralPicker";
import CircularLoader from "&components/circularLoader/circularLoader.component";
import MinusCircleIcon from "&components/icons/minusCircleIcon/minusCircleIcon.component";
import SectionTitleWithLine from "&components/sectionTitleWithLine/sectionTitleWithLine.component";

import { Setting, Settings } from "&features/global/global.type";
import {
  FormInputContent,
  FormSectionContent,
  StepOneValues,
  UpdateProfileBody,
  User,
} from "&features/login/login.type";
import { Career } from "&features/landingPage/landingPage.type";
import { EducationItem, WorkExperience } from "&features/dashboard/screens/profile/profile.type";

import inputSelector from "&utils/ui/inputSelector";
import checkAndValidate from "&utils/validatePhoneNumber";
import buildEducationBody from "&utils/profile/buildEducationBody";
import { buildWorkExperiencesBody } from "&utils/profile/buildWorkExperiencesBody";
import { educationSelector, workExperienceSelector } from "&utils/profile/profileSelectors";
import InfoIcon from "@mui/icons-material/Info";
import { colors } from "&assets/constants/colors";
interface Props {
  fieldsOfStudy?: any[];
  user?: User;
  institutes?: any[];
  settings: Settings;
  submit?: number;
  setNoCareerMode?: (noCareer: boolean) => void;
  getSkills: (body: { id: string }) => void;
  careers?: Career[];
  loading?: boolean;
  updateProfile: (body: UpdateProfileBody) => void;
  hide?: boolean;
  countries?: any;
}

// const educationOptions: Setting[] = [
//   {
//     settingId: "noEducation",
//     title: "I don't have educational background",
//     titleAr: "ليس لدي خلفية تعليمية",
//     createdAt: "2023-01-16T16:31:58.441Z",
//     updatedAt: "2023-01-26T09:26:35.000Z",
//     deletedAt: "null",
//     id: 1,
//     name: "I don't have educational background",
//     nameAr: "ليس لدي خلفية تعليمية",
//     type: "education-background",
//     typeAr: "education-background",
//     value: "",
//     valueAr: "",
//     url: "",
//     image: "",
//   },
//   {
//     settingId: "hasEducation",
//     title: "I have educational background",
//     titleAr: "لدي خلفية علمية",
//     createdAt: "2023-01-16T16:31:58.441Z",
//     updatedAt: "2023-01-26T09:26:35.000Z",
//     deletedAt: "null",
//     id: 2,
//     name: "I don't have educational background",
//     nameAr: "لدي خلفية علمية",
//     type: "education-background",
//     typeAr: "education-background",
//     value: "",
//     valueAr: "",
//     url: "",
//     image: "",
//   },
// ];

const experienceOptions: Setting[] = [
  {
    settingId: "noExperience",
    title: "I don’t have work experience",
    titleAr: "ليس لدي خبرة عمل",
    createdAt: "2023-01-16T16:31:58.441Z",
    updatedAt: "2023-01-26T09:26:35.000Z",
    deletedAt: "null",
    id: 1,
    name: "I don’t have work experience",
    nameAr: "ليس لدي خبرة عمل",
    type: "experience-option",
    typeAr: "experience-option",
    value: "",
    valueAr: "",
    url: "",
    image: "",
  },
  {
    settingId: "hasExperience",
    title: "I have work experience",
    titleAr: "لدي خبرة عمل",
    createdAt: "2023-01-16T16:31:58.441Z",
    updatedAt: "2023-01-26T09:26:35.000Z",
    deletedAt: "null",
    id: 2,
    name: "I have work experience",
    nameAr: "لدي خبرة عمل",
    type: "experience-option",
    typeAr: "experience-option",
    value: "",
    valueAr: "",
    url: "",
    image: "",
  },
];

const careerOptions: Setting[] = [
  {
    settingId: "knowCareer",
    title: "I know my career",
    titleAr: "انا اعرف مهنتي",
    createdAt: "2023-01-16T16:31:58.441Z",
    updatedAt: "2023-01-26T09:26:35.000Z",
    deletedAt: "null",
    id: 1,
    name: "I know my career",
    nameAr: "انا اعرف مهنتي",
    type: "career-option",
    typeAr: "career-option",
    value: "",
    valueAr: "",
    url: "",
    image: "",
  },
  {
    settingId: "hasExperience",
    title: "I am still not sure",
    titleAr: "ما زلت غير متأكد",
    createdAt: "2023-01-16T16:31:58.441Z",
    updatedAt: "2023-01-26T09:26:35.000Z",
    deletedAt: "null",
    id: 2,
    name: "I am still not sure",
    nameAr: "ما زلت غير متأكد",
    type: "career-option",
    typeAr: "career-option",
    value: "",
    valueAr: "",
    url: "",
    image: "",
  },
];

const BuildProfileStepOne = (props: Props) => {
  let { settings, submit, user, careers, loading, hide, updateProfile, fieldsOfStudy, institutes, setNoCareerMode } =
    props;
  const changeCareerMode = useSelector((state: RootState) => state.login.changeCareerMode);

  const emptyWorkExperience: WorkExperience = {
    title: undefined,
    companyName: undefined,
    startDate: undefined,
    endDate: undefined,
    current: undefined,
  };

  const emptyEducationProfile: EducationItem = {
    educationDegree: undefined,
    fieldOfStudy: undefined,
    schoolName: undefined,
    otherSchoolName: undefined,
    startDate: undefined,
    graduationYear: undefined,
    current: undefined,
  };

  const { t } = useTranslation(["profile"]);

  const values: StepOneValues = {
    name: user?.name || "",
    lastName: user?.lastName || "",
    gender: (user?.gender && (user?.gender?.toLocaleLowerCase() === "male" ? 1 : 2)) || undefined,
    dateOfBirth: (user?.dateOfBirth && moment(user?.dateOfBirth)) || undefined,
    phoneNumber: user?.phoneNumber || undefined,
    referrals: settings.referral?.find((ref: any) => ref.name === user?.referral?.name),
    // hasEducation: user?.education?.length === 0 ? 1 : 2,
    educationProfile:
      user?.education && user?.education?.length > 0 ? educationSelector(user?.education)[0] : emptyEducationProfile,
    hasWorkExperience: user?.workExperience && user?.workExperience?.length === 0 ? 1 : 2,
    career: user?.career || undefined,
    knowCareer: 1,
    workExperience:
      user?.workExperience && user?.workExperience?.length > 0
        ? workExperienceSelector(user?.workExperience)
        : [emptyWorkExperience],
    nationality: user?.nationality,
    nationalityId: user?.nationalityId || undefined,
    disability: user?.disability ? 1 : 2 || undefined,
    disabilityDescription: user?.disabilityDescription || undefined,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()?.required(t("REQUIRED")),
    lastName: Yup.string()?.required(t("REQUIRED")),
    gender: Yup.number()?.required(t("REQUIRED")),
    dateOfBirth: Yup.date()
      .typeError(t("INVALID_REQUIRED_DATE"))
      .max(moment().subtract(14, "years"), t("YOU_SHOULD_BE_15_YEARS_OR_OLDER"))
      .required(t("REQUIRED")),
    // hasEducation: Yup.number()?.required(
    //   t("PLEASE_PROVIDE_IF_YOU_HAVE_ANY_EDUCATIONAL_BACKGROUND")
    // ),
    phoneNumber: Yup.string().when({
      is: (exist: any) => exist,
      then: Yup.string().test("Phone number", t("PHONE_NUMBER_IS_NOT_VALID"), function (value: any) {
        return checkAndValidate(value).status === "SUCCESS";
      }),
      otherwise: Yup.string().trim().required(t("REQUIRED")),
    }),
    referrals: Yup.object().required(t("REQUIRED")),
    hasWorkExperience: Yup.number()?.required(t("PLEASE_PROVIDE_IF_YOU_HAVE_ANY_WORK_EXPERIENCE")),
    educationProfile: Yup.object().shape({
      educationDegree: Yup.object().required(t("REQUIRED")),
      fieldOfStudy: Yup.object().required(t("REQUIRED")),
      schoolName: Yup.object().required(t("REQUIRED")),
      // otherSchoolName: Yup.string().when("schoolName", {
      //   is: (schoolName: any) => schoolName?.name === "Other",
      //   then: Yup.string()?.trim()?.required(t("REQUIRED")),
      // }),
      startDate: Yup.date()
        .when("current", {
          is: (current: boolean) => !current,
          then: Yup.date().max(Yup.ref("graduationYear"), t("START_DATE_MUST_BE_LESS_THAN_GRADUATION_DATE")),
        })
        .required(t("REQUIRED")),
      graduationYear: Yup.date().when("current", {
        is: (current: boolean) => !current,
        then: Yup.date().max(moment(), t("GRADUATION_YEAR_CAN_NOT_BE_IN_THE_FUTURE")).required(t("REQUIRED")),
      }),
    }),
    knowCareer: Yup.number().required(t("REQUIRED")),
    career: Yup.object()
      ?.nullable()
      .when("knowCareer", {
        is: (knowCareer: any) => knowCareer === 1,
        then: Yup.object()?.required(t("REQUIRED")),
      }),
    workExperience: Yup.array().when("hasWorkExperience", {
      is: (hasWorkExperience: number) => hasWorkExperience === 2,
      then: Yup.array(
        Yup.object().shape({
          current: Yup.boolean().notRequired(),
          title: Yup.string()?.trim().required(t("REQUIRED")),
          companyName: Yup.string()?.trim().required(t("REQUIRED")),
          startDate: Yup.date()
            .when("current", {
              is: (current: boolean) => !current,
              then: Yup.date().max(Yup.ref("endDate"), t("START_DATE_MUST_BE_LESS_THAN_END_DATE")),
            })
            .required(t("REQUIRED")),
          endDate: Yup.date().when("current", {
            is: (current: boolean) => !current,
            then: Yup.date()
              .max(moment(), t("END_DATE_CAN_NOT_BE_IN_THE_FUTURE"))
              .min(Yup.ref("startDate"), t("END_DATE_CAN_NOT_BE_GREATER_THAN_START_DATE"))
              .required(t("REQUIRED")),
          }),
        })
      ),
    }),

    disability: Yup.number().required(t("REQUIRED")),
    disabilityDescription: Yup.string().when("disability", {
      is: 1,
      then: Yup.string().trim().required(t("REQUIRED")),
    }),
    nationality: Yup.object()
      .required(t("REQUIRED"))
      .test(
        "is-different-country",
        t("NATIONALITY_MUST_BE_DIFFERENT_THAN_RESIDENCE_COUNTRY"),
        function (value: any, context: any) {
          const residenceType = context.parent.residenceType;
          const residenceName = context.parent.residence?.name;
          const nationalityName = context.parent.nationality?.name;
          if (residenceName && nationalityName && residenceType) {
            return !(residenceType === 2 && residenceName === nationalityName);
          }
          return true;
        }
      ),
  });

  const formik: any = useFormik({
    initialValues: values,
    validationSchema: validationSchema,
    onSubmit: () => {
      handleUpdateProfile();
    },
  });

  const handleUpdateProfile = () => {
    setNoCareerMode && setNoCareerMode(!!(formik.values.knowCareer === 2));
    updateProfile({
      name: formik.values.name,
      lastName: formik.values.lastName,
      dateOfBirth: formik.values.dateOfBirth,
      referralId: Number(formik.values.referrals.id),
      gender: formik.values.gender === 1 ? "male" : "female",
      careerId: formik.values.knowCareer === 2 ? undefined : formik.values.career?.id,
      phoneNumber: formik?.values?.phoneNumber || undefined,
      workExperiences:
        formik.values.hasWorkExperience === 1 ? [] : buildWorkExperiencesBody(formik.values.workExperience),
      educations:
        // formik.values.hasEducation === 1
        //   ? []:
        buildEducationBody(formik.values.educationProfile),
      nationalityId: formik?.values?.nationality?.id || undefined,
      disability: !!(formik?.values?.disability && formik?.values?.disability === 1),
      disabilityDescription: formik.values?.disabilityDescription || undefined,
    });
  };

  useEffect(() => {
    submit && submit > 0 && formik.handleSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submit]);

  const loadingComponent = (
    <div className="loader-wrapper">
      <CircularLoader />
    </div>
  );

  const RenderAddExperience = (props: { onClick?: () => void; className?: string; title: string; icon?: any }) => {
    const { onClick, className, title } = props;

    return (
      <AddExperienceButton onClick={onClick} className={className}>
        <img src={PlusIcon} alt="plus-icon" />
        <div className="button-text">{title}</div>
      </AddExperienceButton>
    );
  };

  const handleAddExperience = () => {
    formik.setFieldValue("workExperience", [...formik.values.workExperience, emptyWorkExperience]);
  };

  const handleRemoveExperience = (index: number) => {
    const array = formik.values.workExperience;
    // const arraySpliced = formik.values.workExperience.splice(index, 1);
    formik.setFieldValue("workExperience", array);
  };

  const RenderPersonalInfo = () => {
    return (
      <FormSectionInputWrapper>
        <div className="input-grid">
          {inputSelector(
            {
              id: `name-input`,
              label: t("FIRST_NAME"),
              placeHolder: t("ENTER_FIRST_NAME"),
              type: "text",
              value: formik.values?.name || undefined,
              className: "input-min-width",
              handleChange: (value) => formik.setFieldValue(`name`, value),
              error: getIn(formik.touched, `name`) && getIn(formik.errors, `name`),
              labelIcon: (
                <Tooltip title={t("PLEASE_NOTE_STATEMENT")} enterDelay={100} leaveDelay={200}>
                  <InfoIcon
                    style={{
                      color: colors.primary,
                      margin: "0px 5px",
                    }}
                    fontSize="small"
                  />
                </Tooltip>
              ),
            },
            0
          )}
          {inputSelector(
            {
              id: `name-input`,
              label: t("LAST_NAME"),
              placeHolder: t("ENTER_LAST_NAME"),
              type: "text",
              value: formik.values?.lastName || undefined,
              className: "input-min-width",
              handleChange: (value) => formik.setFieldValue(`lastName`, value),
              error: getIn(formik.touched, `lastName`) && getIn(formik.errors, `lastName`),
            },
            1
          )}
          {inputSelector(
            {
              id: `date-of-birth-date-input`,
              label: t("DATE_OF_BIRTH"),
              placeHolder: t("SELECT_FROM_CALENDAR"),
              type: "date",
              value: formik.values?.dateOfBirth || undefined,
              className: "input-min-width",
              max: moment().subtract(14, "years"),
              handleChange: (value) => formik.setFieldValue(`dateOfBirth`, value),
              error: getIn(formik.touched, `dateOfBirth`) && getIn(formik.errors, `dateOfBirth`),
            },
            2
          )}

          {inputSelector(
            {
              id: "nationality-select",
              label: t("NATIONALITY"),
              placeHolder: t("SELECT_FROM_THE_LIST"),
              type: "select",
              options: props.countries,
              value: formik.values?.nationality,
              handleChange: (value) => formik.setFieldValue("nationality", value),
              disabled: false,
              error: formik.touched.nationality && formik.errors.nationality,
              labelIcon: (
                <Tooltip title={t("SELECT_NATIONALITY")} enterDelay={100} leaveDelay={200}>
                  <InfoIcon
                    style={{
                      color: colors.primary,
                      margin: "0px 5px",
                    }}
                    fontSize="small"
                  />
                </Tooltip>
              ),
            },
            3
          )}
          {inputSelector(
            {
              id: "phone-input",
              label: t("PHONE_NUMBER"),
              placeHolder: "+961234567",
              type: "phoneNumber",
              value: formik.values?.phoneNumber,
              handleChange: (value) => formik.setFieldValue("phoneNumber", value),
              error: formik.touched.phoneNumber && formik.errors.phoneNumber,
            },
            3
          )}
          {inputSelector(
            {
              id: "disability-radio-input",
              options: disabilityOptions,
              label: t("DO_YOU_HAVE_ANY_DISABILITIES"),
              type: "radio",
              showAll: true,
              disabled: false,
              value: formik?.values?.disability,
              handleChange: (value) => {
                formik.setFieldValue(`disability`, value);
              },
              error: formik.touched.disability && formik.errors.disability,
            },
            4
          )}
          {formik?.values?.disability === 1
            ? inputSelector(
                {
                  id: "disability-description-text-input",
                  label: t("PLEASE_SPECIFY"),
                  type: "text",
                  disabled: false,
                  value: formik?.values?.disabilityDescription,
                  handleChange: (value) => formik.setFieldValue(`disabilityDescription`, value),
                  error: formik.touched.disabilityDescription && formik.errors.disabilityDescription,
                },
                5
              )
            : null}
          {inputSelector(
            {
              id: "gender-input",
              options: genderOptions,
              label: t("GENDER"),
              type: "radio",
              showAll: true,
              value: formik?.values?.gender,
              handleChange: (value) => formik.setFieldValue(`gender`, value),
              error: getIn(formik.touched, `gender`) && getIn(formik.errors, `gender`),
            },
            6
          )}
        </div>
      </FormSectionInputWrapper>
    );
  };

  const RenderEducationSection = () => {
    const educationInputGrid: FormInputContent[] = [
      {
        id: "education-degree-select",
        label: t("EDUCATION_DEGREE"),
        placeHolder: t("SELECT_FROM_THE_LIST"),
        type: "select",
        options: educationDegrees,
        value: formik.values?.educationProfile?.educationDegree,
        handleChange: (value) => formik.setFieldValue("educationProfile.educationDegree", value),
        error: formik.touched.educationProfile?.educationDegree && formik.errors.educationProfile?.educationDegree,
      },
      {
        id: "field-of-study-select",
        label: t("FIELD_OF_STUDY"),
        placeHolder: t("SELECT_FROM_THE_LIST"),
        type: "select",
        options: fieldsOfStudy,
        value: formik.values?.educationProfile?.fieldOfStudy,
        handleChange: (value) => formik.setFieldValue("educationProfile.fieldOfStudy", value),
        error: formik.touched.educationProfile?.fieldOfStudy && formik.errors.educationProfile?.fieldOfStudy,
      },
      {
        id: "school-university-name-input",
        label: t("SCHOOL_UNIVERSITY_NAME"),
        placeHolder: t("TYPE_OR_SELECT_YOUR_SCHOOL_OR_UNIVERSITY"),
        type: "select",
        options: institutes,
        value: formik.values?.educationProfile?.schoolName,
        handleChange: (value) => formik.setFieldValue("educationProfile.schoolName", value),
        error: formik.touched.educationProfile?.schoolName && formik.errors.educationProfile?.schoolName,
      },
      {
        id: "other-school-university-name",
        label: t("SPECIFY_SCHOOL_UNIVERSITY_NAME"),
        placeHolder: t("TYPE_YOUR_SCHOOL_OR_UNIVERSITY_NAME"),
        type: "text",
        value: formik.values?.educationProfile?.otherSchoolName || undefined,
        handleChange: (value) => formik.setFieldValue("educationProfile.otherSchoolName", value),
        hide: formik.values?.educationProfile?.schoolName?.name !== "Other",
        error: formik.touched.educationProfile?.otherSchoolName && formik.errors.educationProfile?.otherSchoolName,
      },
      {
        id: "graduation-start-year-date-picker",
        label: t("START_DATE"),
        placeHolder: t("SELECT_FROM_CALENDAR"),
        type: "dateYear",
        max: moment(),
        value: formik.values?.educationProfile?.startDate,
        handleChange: (value) => formik.setFieldValue("educationProfile.startDate", value),
        error: formik.touched.educationProfile?.startDate && formik.errors.educationProfile?.startDate,
      },
      {
        id: "graduation-year-date-picker",
        label: t("GRADUATION_YEAR"),
        placeHolder: t("SELECT_FROM_CALENDAR"),
        hide: formik.values.educationProfile.current,
        type: "dateYear",
        max: moment(),
        value: formik.values?.educationProfile?.graduationYear,
        handleChange: (value) => formik.setFieldValue("educationProfile.graduationYear", value),
        error: formik.touched.educationProfile?.graduationYear && formik.errors.educationProfile?.graduationYear,
      },
    ];

    return (
      <FormSectionInputWrapper>
        {/*<div className="options-wrapper">
          <RadioGroup
            id={"has-education-radio-input"}
            value={formik.values.hasEducation}
            showAll={true}
            onChange={(value) => formik.setFieldValue("hasEducation", value)}
            data={educationOptions || []}
          />
          </div>*/}
        {/* {formik.values.hasEducation === 2 && ( */}
        <>
          <div className="input-grid">
            {educationInputGrid?.map(
              (item: FormInputContent, index: number) => !item.hide && inputSelector(item, index)
            )}
          </div>
          <Checkbox
            selected={formik.values.educationProfile.current}
            handleClick={() =>
              formik.setFieldValue("educationProfile.current", !formik.values.educationProfile.current)
            }
            title={<div className="checkbox-text">{t("IM_CURRENTLY_ATTENDING_THIS_SCHOOL")}</div>}
          />
        </>
        {/* )} */}
      </FormSectionInputWrapper>
    );
  };

  const handleReferralValueChange = (referrals: Setting[]) => {
    formik.setFieldValue("referrals", referrals);
  };

  const RenderReferralsSection = () => {
    return (
      <FormSectionInputWrapper>
        <ReferralPicker
          handleReferralChange={handleReferralValueChange}
          chosenReferrals={formik.values.referrals}
          error={formik.errors.referrals !== undefined}
          referrals={settings.referral}
        />
      </FormSectionInputWrapper>
    );
  };

  const RenderCareerSection = () => {
    return (
      <FormSectionInputWrapper>
        <div className="options-wrapper">
          <RadioGroup
            id={"knows-career-radio-input"}
            value={formik.values?.knowCareer}
            showAll={true}
            onChange={(value) => formik.setFieldValue("knowCareer", value)}
            data={careerOptions || []}
          />
        </div>
        {formik.values?.knowCareer !== 2
          ? inputSelector(
              {
                id: "current-career-select",
                type: "select",
                label: formik.values.hasWorkExperience === 2 ? t("CURRENT_CAREER") : t("YOUR_DREAM_CAREER"),
                placeHolder: t("SELECT_FROM_THE_LIST"),
                options: careers,
                className: "input-margin-bottom",
                disabled: formik.values?.knowCareer === 2,
                value: formik?.values?.career,
                handleChange: (value) => formik.setFieldValue("career", value),
                error: getIn(formik.touched, `career`) && getIn(formik.errors, `career`),
              },
              0
            )
          : null}
        {changeCareerMode && <div />}
      </FormSectionInputWrapper>
    );
  };

  const RenderWorkExperienceSection = () => {
    const workExperienceInputGrid: (index: number) => FormInputContent[] = (index: number) => [
      {
        id: `job-title-text-input-${index}`,
        label: t("JOB_TITLE"),
        placeHolder: t("EX_SENIOR_GRAPHIC_DESIGNER"),
        type: "text",
        value: formik.values?.workExperience[index]?.title || undefined,
        handleChange: (value) => formik.setFieldValue(`workExperience[${index}].title`, value),
        error:
          getIn(formik.touched, `workExperience[${index}].title`) &&
          getIn(formik.errors, `workExperience[${index}].title`),
      },
      {
        id: `company-name-text-input-${index}`,
        label: t("COMPANY_NAME"),
        placeHolder: t("TYPE_YOUR_COMPANY_NAME"),
        type: "text",
        value: formik.values?.workExperience[index]?.companyName || undefined,
        handleChange: (value) => formik.setFieldValue(`workExperience[${index}].companyName`, value),
        error:
          getIn(formik.touched, `workExperience[${index}].companyName`) &&
          getIn(formik.errors, `workExperience[${index}].companyName`),
      },
      {
        id: `start-date-date-input-${index}`,
        label: t("START_DATE"),
        placeHolder: t("SELECT_FROM_CALENDAR"),
        type: "date",
        max: formik.values?.workExperience[index]?.endDate || moment(),
        value: formik.values?.workExperience[index]?.startDate || undefined,
        handleChange: (value) => formik.setFieldValue(`workExperience[${index}].startDate`, value),
        error:
          getIn(formik.touched, `workExperience[${index}].startDate`) &&
          getIn(formik.errors, `workExperience[${index}].startDate`),
      },
      {
        id: `end-date-date-input-${index}`,
        label: t("END_DATE"),
        placeHolder: t("SELECT_FROM_CALENDAR"),
        type: "date",
        min: formik.values?.workExperience[index]?.startDate || undefined,
        max: moment(),
        hide: formik.values?.workExperience[index]?.current,
        value: formik.values?.workExperience[index]?.endDate || undefined,
        handleChange: (value) => formik.setFieldValue(`workExperience[${index}].endDate`, value),
        error:
          getIn(formik.touched, `workExperience[${index}].endDate`) &&
          getIn(formik.errors, `workExperience[${index}].endDate`),
      },
    ];

    return (
      <FormSectionInputWrapper>
        <div className="options-wrapper">
          <RadioGroup
            id={"has-experience-radio-input"}
            value={formik.values.hasWorkExperience}
            showAll={true}
            onChange={(value) => formik.setFieldValue("hasWorkExperience", value)}
            data={experienceOptions || []}
          />
        </div>
        {formik.values.hasWorkExperience === 2 &&
          formik.values?.workExperience?.map((experience: WorkExperience, index: number) => (
            <div key={index} className="grid-checkbox-wrapper">
              <div className="input-grid">
                {workExperienceInputGrid(index)?.map(
                  (item: FormInputContent, index: number) => !item.hide && inputSelector(item, index)
                )}
              </div>
              <div className="minus-checkbox-wrapper">
                {index !== 0 ? <MinusCircleIcon onClick={() => handleRemoveExperience(index)} /> : <div />}
                <Checkbox
                  selected={formik?.values?.workExperience[index]?.current || undefined}
                  wrapperClassName="current-work-experience"
                  handleClick={() =>
                    formik.setFieldValue(
                      `workExperience[${index}].current`,
                      !formik?.values?.workExperience[index]?.current
                    )
                  }
                  title={<div className="checkbox-text">{t("IM_CURRENTLY_WORKING_HERE")}</div>}
                />
              </div>
            </div>
          ))}
        {formik.values.hasWorkExperience === 2 ? (
          <RenderAddExperience
            className="add-experience-button"
            title={t("ADD_ANOTHER_EXPERIENCE")}
            onClick={handleAddExperience}
          />
        ) : (
          <div />
        )}
      </FormSectionInputWrapper>
    );
  };

  const formSections: FormSectionContent[] = [
    {
      title: t("PERSONAL_INFORMATION"),
      component: RenderPersonalInfo(),
      hide: changeCareerMode,
    },
    {
      title: t("LATEST_EDUCATION"),
      component: RenderEducationSection(),
      hide: changeCareerMode,
    },
    {
      title: t("CHOOSE_YOUR_CAREER"),
      component: RenderCareerSection(),
    },
    {
      title: t("LATEST_WORK_EXPERIENCE"),
      component: RenderWorkExperienceSection(),
      hide: changeCareerMode,
    },
    {
      title: t("HOW_DID_YOU_HEAR"),
      component: RenderReferralsSection(),
      hide: changeCareerMode,
    },
  ];

  return (
    <BuildProfileStepOneWrapper>
      {hide
        ? null
        : loading
        ? loadingComponent
        : formSections?.map(
            (section, index) =>
              !section.hide && (
                <div className="section-wrapper" key={index}>
                  <SectionTitleWithLine title={section.title} description={section.description} error={section.error} />
                  {section.component}
                </div>
              )
          )}
    </BuildProfileStepOneWrapper>
  );
};

export default BuildProfileStepOne;
