import React, { useEffect, useRef, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useTranslation } from "react-i18next";
import SwipeableViews from "react-swipeable-views";

import { RootState } from "&store/store";

import { careerListingActions } from "./careerListing.slice";
import { CareerListingWrapper, LevelsMobileTabs, TabPanelWrapper } from "./careerListing.styled";
import ListingPageHeader from "&components/headers/listingPageHeader/listingPageHeader.component";
import { useNavigate, useSearchParams } from "react-router-dom";
import CircularLoader from "&components/circularLoader/circularLoader.component";
import { defaultCareersSearchParams } from "&assets/constants/statics";
import { CareersSearchParams } from "&features/careerListing/careerListing.type";
import { buildDropDownList, buildLevelsList, querySelector } from "&utils/searchSortFilter/searchSortFilterUtils";
import Container from "&components/container/container.component";
import SearchSortFilterBar from "&components/searchSortFilterBar/searchSortFilterBar.component";
import { landingPageActions } from "&features/landingPage/landingPage.slice";
import CareerLevels from "&components/careerLevels/careerLevels.component";
import { Tab } from "@mui/material";
import { Level } from "&features/landingPage/landingPage.type";
import { useMediaQuery } from "react-responsive";
import { size } from "&assets/constants/responsiveness";
import ListCareers from "&components/listCareers/listCareers.component";
import { GetPageListingQuery } from "&features/listingPage/listingPage.type";

type ReduxProps = ConnectedProps<typeof connector>;

const CareerListingComponent = (props: ReduxProps) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation(["careerListing"]); // Make sure namespace is added to locales

  const isArabic = i18n.language === "ar";

  const sortByObject = {
    popular: t("POPULAR"),
    [isArabic ? "nameAr" : "name"]: t("NAME"),
    createdAt: t("DATE"),
  };
  const headerTitle = "Find the career of your dreams";
  const headerDescription =
    "Whether you are an experienced professional or someone just starting to explore their options, nammiskills can help you achieve your career goals. ";
  const { levels, getLevels, loadingLevels, levelsLanding, getLevelsLanding, pathsHistory } = props;

  useEffect(() => {
    levelsLanding && levelsLanding?.length === 0 && getLevelsLanding({ limit: 5 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [levelsLanding]);

  const isMobile = useMediaQuery({ maxWidth: size.mobile });
  const isTablet = useMediaQuery({
    minWidth: size.mobile,
    maxWidth: size.tablet,
  });

  const ref = useRef<any>(null);

  const loaded = levelsLanding?.length > 0;

  const loadingComponent = (
    <div className="loader-wrapper">
      <CircularLoader />
    </div>
  );

  const [urlSearchParams, setSearchParams] = useSearchParams();
  const [tabValue, setTabValue] = useState<number>(0);

  useEffect(() => {
    // Write your side-effects here
  }, []);

  const searchParams: CareersSearchParams = {
    query: urlSearchParams.get("query") || defaultCareersSearchParams.query,
    level: urlSearchParams.get("level") || defaultCareersSearchParams.level,
    sort_by: urlSearchParams.get("sort_by") || defaultCareersSearchParams.sort_by,
  };

  useEffect(() => {
    setSearchParams({
      ...searchParams,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchState: any = {
    level: {
      id: "level",
      title: t("LEVEL"),
      object: buildLevelsList(levelsLanding),
      list: buildDropDownList(buildLevelsList(levelsLanding)),
      value: searchParams.level,
      valueItem: querySelector(buildDropDownList(buildLevelsList(levelsLanding)), searchParams.level),
    },
    sort_by: {
      id: "sort_by",
      title: t("SORT_BY"),
      object: sortByObject,
      list: buildDropDownList(sortByObject),
      value: searchParams.sort_by,
      valueItem: querySelector(buildDropDownList(sortByObject), searchParams.sort_by),
    },
  };

  const findPrevious = () => {
    let previousPath = "";
    pathsHistory?.some((path: string) => {
      if (path !== window.location.pathname) {
        previousPath = path;
        return true;
      }
    });
    return previousPath;
  };

  const fetchPageContent = () => {
    setSearchParams({
      ...searchParams,
    });

    const requestBody: GetPageListingQuery = {
      query: searchParams.query || "",
      tierId: (Number(searchParams.level) > 0 && Number(searchParams.level)) || undefined,
      // limit: 30,
      dir: "ASC",
      sort: (searchParams.sort_by !== "popular" && searchParams.sort_by) || undefined,
    };

    getLevels(requestBody);
  };

  const renderTabPanel = (level: Level, index: number, tabValue: number) => {
    return (
      <TabPanelWrapper key={index} level={level.alias} index={index}>
        {index === tabValue ? (
          <ListCareers showAll={true} careers={level.careers} vertical={true} hideJobs={true} />
        ) : null}
      </TabPanelWrapper>
    );
  };

  const renderMobileTabs = () => {
    return (
      <>
        <LevelsMobileTabs centered value={tabValue} onChange={(event, newValue) => setTabValue(newValue)}>
          {levels.map(
            (level: Level, index: number) =>
              level?.careers?.length > 0 && (
                <Tab label={level.alias} id={`level-mobile-tab-${level.alias}`} key={index} />
              )
          )}
        </LevelsMobileTabs>
        <SwipeableViews axis={"x"} index={tabValue} onChangeIndex={(value: number) => setTabValue(value)}>
          {levels.map(
            (level: Level, index: number) => level?.careers?.length > 0 && renderTabPanel(level, index, tabValue)
          )}
        </SwipeableViews>
      </>
    );
  };

  useEffect(() => {
    fetchPageContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchParams.query,
    searchParams.level,
    searchParams.sort_by,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    localStorage.getItem("language"),
  ]);

  return (
    <CareerListingWrapper>
      <ListingPageHeader
        title={headerTitle}
        description={headerDescription}
        onBackClick={() => navigate(findPrevious())}
      />
      {loaded ? (
        <>
          <Container className="listing-container">
            <SearchSortFilterBar
              searchState={searchState}
              forwardRef={ref}
              placeHolder={t("SEARCH_CAREERS")}
              setSearchParams={setSearchParams}
              searchParams={searchParams}
            />
          </Container>
          {loadingLevels ? (
            loadingComponent
          ) : isMobile || isTablet ? (
            renderMobileTabs()
          ) : (
            <CareerLevels hideJobs={true} showAll={true} careerLevels={levels} />
          )}
        </>
      ) : (
        loadingComponent
      )}
    </CareerListingWrapper>
  );
};

const mapStateToProps = (state: RootState) => ({
  levelsLanding: state.landingPage.levels,
  levels: state.careerListing.levels,
  loadingLevels: state.careerListing.loadingLevels,
  status: state.careerListing.status,
  user: state.login.user,
  pathsHistory: state.global.pathsHistory,
});

const mapDispatchToProps = {
  getLevelsLanding: landingPageActions.getLevels,
  getLevels: careerListingActions.getLevels,
  setCareerListing: careerListingActions.setCareerListing,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const CareerListingComponentRedux = connector(CareerListingComponent);

export { CareerListingComponentRedux as CareerListingComponent };
