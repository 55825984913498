import React from "react";
import { FourEasyStepsWrapper } from "./fourEasySteps.styled";
import ImageDisplay from "&components/imageDisplay/imageDisplay.component";
import { translationSelector } from "&utils/translationSelector";
import { LandingStepArrow } from "&assets/constants/icons";
import { colors } from "&assets/constants/colors";

interface Props {
  title?: string;
  backgroundImage?: string;
  data?: any[];
}

const FourEasySteps = (props: Props) => {
  const { title, backgroundImage, data } = props;
  return (
    <FourEasyStepsWrapper>
      <ImageDisplay isFromS3={true} url={backgroundImage} isBackgroundImage={true} className="background-wrapper" />
      <div className="padding-wrapper">
        <p className="title">{title}</p>
        <div className="steps-wrapper">
          {data?.map((item: any, index) => (
            <div className="step-wrapper" key={index}>
              <div className="number-description">
                <p className="number">{index + 1}</p>
                <div className="line" />
                <div className="description">{translationSelector(item, "description")}</div>
              </div>
              {data?.length !== index + 1 && LandingStepArrow(colors.secondary, "1")}
            </div>
          ))}
        </div>
      </div>
    </FourEasyStepsWrapper>
  );
};

export default FourEasySteps;
