import styled from "styled-components";
import { colors } from "&assets/constants/colors";

export const MinusCircleIconWrapper = styled.div`
  .icon-wrapper {
    width: 24px;
    height: 24px;
  }

  .minus {
    width: 9px;
    height: 1.5px;
    border-radius: 1px;
    background: ${colors.linearGradientOne};
  }
`;
