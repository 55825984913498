import styled from "styled-components";
import { colors } from "&assets/constants/colors";

export const ChangeCareerSectionWrapper = styled.div`
  padding-top: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;

  .career-card {
    display: flex;
    padding: 16px 24px;
    box-sizing: border-box;
    flex-direction: column;
    width: 100%;
    min-height: 99px;
    border-radius: 16px;
    border: 1px solid transparent;
    background: ${colors.linearGradientWhite} padding-box, ${colors.linearGradientOne} border-box;

    .circle-icon-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .circle {
        width: 32px;
        height: 32px;
        border-radius: 16px;
        background: linear-gradient(180deg, ${colors.primary} 0%, rgba(16, 140, 210, 0) 100%);
        opacity: 0.2;
      }

      .icon-image {
        max-width: 28px;
        max-height: 28px;
      }

      .icon {
        margin-left: -15px;
      }
    }

    .career-title {
      color: var(--Titles, #2d2a35);
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%; /* 21.6px */
    }
  }
`;
