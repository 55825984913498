import { device } from "&assets/constants/responsiveness";
import styled from "styled-components";
import { colors } from "&assets/constants/colors";

export const TestimonialWrapper = styled.div`
  padding: 50px 175px;
  .wrapper {
    display: flex;
    flex-direction: column;
  }
  .title {
    margin-top: 0;
    font-family: "Mulish";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 110%;
    letter-spacing: -0.02em;
    color: ${colors.primary};
    width: 800px;
  }
  .content {
    position: relative;
    min-height: 323px;
    height: fit-content;
    background: ${colors.linearGradientOne};
    border-radius: 16px;
    width: 90%;
    max-width: 978px;
    margin: 0 auto;
    padding: 48px 0;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    align-self: flex-end;
    justify-content: space-between;
    box-sizing: border-box;
    .quotes {
      position: absolute;
      top: -100px;
      ${(props) => (props.theme.dir === "rtl" ? "left: -50px;" : "right: -50px;")}
      ${(props) => (props.theme.dir === "rtl" ? "transform: scaleX(-1);" : "")}
      width: 221px;
      height: 168px;
    }
    .down-quotes {
      position: absolute;
      width: 100px;
      height: 76px;
      ${(props) => (props.theme.dir === "rtl" ? "transform: scaleX(-1);" : "")}
      ${(props) => (props.theme.dir === "rtl" ? "right: -180px;" : "left: -180px;")}
      bottom: 0;
    }
    .user-image {
      width: 237px;
      height: 247px;
      position: absolute;
      ${(props) => (props.theme.dir === "rtl" ? "right: 0;" : "left: 10px;")}
      top: 53%;
      ${(props) =>
        props.theme.dir === "rtl" ? "transform: translate(50%, -50%);" : "transform: translate(-50%, -50%);"}
      filter: drop-shadow(10px 10px 15px rgba(0, 0, 0, 0.25));
      border-radius: 16px;
      object-fit: cover;
    }
    .message {
      font-family: "Mulish";
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 160%;
      color: #ffffff;
      ${(props) => (props.theme.dir === "rtl" ? "padding: 0 222px 0 100px;" : "padding: 0 100px 0 222px;")}
    }
    .name {
      font-family: "Mulish";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 160%;
      color: #ffffff;
      ${(props) => (props.theme.dir === "rtl" ? "padding: 0 222px 0 100px;" : "padding: 0 100px 0 222px;")}
      margin: 0;
      span {
        font-family: "Clash Grotesk";
        font-weight: 100;
      }
    }
    .arrows {
      ${(props) => (props.theme.dir === "rtl" ? "padding: 0 222px 0 100px;" : "padding: 0 100px 0 222px;")}
      align-items: center;
      img {
        ${(props) => (props.theme.dir === "rtl" ? "margin-left: 16px;" : "margin-right: 16px;")}
        ${(props) => (props.theme.dir === "rtl" ? "transform: scale(1) rotate(180deg);" : "transform: scale(1);")}
        cursor: pointer;
        transition: all 0.1s ease-in;
        &:hover {
          ${(props) =>
            props.theme.dir === "rtl" ? "transform: scale(1.05) rotate(180deg);" : "transform: scale(1.05);"}
        }
      }
    }
  }
  @media ${device.inBetween} {
    .content {
      height: auto;
    }
    .message,
    .name,
    .arrows {
      ${(props) =>
        props.theme.dir === "rtl" ? "padding: 0 150px 0 70px !important;" : "padding: 0 70px 0 150px !important;"}
    }
  }
  @media ${device.tablet} {
    padding: 40px 30px;
    .title {
      font-size: 28px;
      width: auto;
    }
    .content {
      width: 90%;
      padding-top: 70px;
      margin-top: 70px;
      height: auto;
      .quotes {
        width: 129px;
        height: 98px;
        position: absolute;
        top: -50px;
        ${(props) => (props.theme.dir === "rtl" ? "left: 50px;" : "right: 50px;")}
      }
      .down-quotes {
        position: absolute;
        width: 100px;
        height: 76px;
        ${(props) => (props.theme.dir === "rtl" ? "right: -30px;" : "left: -30px;")}
        bottom: -30px;
        width: 69px;
        height: 52px;
        z-index: -1;
      }
      .user-image {
        width: 97px;
        height: 101px;
        ${(props) => (props.theme.dir === "rtl" ? "right: 30px;" : "left: 30px;")}
        top: 0px;
      }
      .message {
        ${(props) =>
          props.theme.dir === "rtl" ? "padding: 0 24px 0 10px !important;" : "padding: 0 10px 0 24px !important;"}
        line-height: 130%;
      }
      .name {
        ${(props) =>
          props.theme.dir === "rtl" ? "padding: 0 24px 0 10px !important;" : "padding: 0 10px 0 24px !important;"}
      }
      .arrows {
        ${(props) =>
          props.theme.dir === "rtl" ? "padding: 0 24px 0 10px !important;" : "padding: 0 10px 0 24px !important;"}
      }
    }
  }
  @media ${device.mobile} {
    padding: 40px 16px;
    .title {
      font-size: 28px;
      width: auto;
    }
    .content {
      width: 90%;
      padding-top: 70px;
      margin-top: 70px;
      height: auto;
      .quotes {
        width: 129px;
        height: 98px;
        position: absolute;
        top: -50px;
        ${(props) => (props.theme.dir === "rtl" ? "left: 50px;" : "right: 50px;")}
      }
      .down-quotes {
        position: absolute;
        width: 100px;
        height: 76px;
        ${(props) => (props.theme.dir === "rtl" ? "right: -30px;" : "left: -30px;")}
        bottom: -30px;
        width: 69px;
        height: 52px;
        z-index: -1;
      }
      .user-image {
        width: 97px;
        height: 101px;
        ${(props) => (props.theme.dir === "rtl" ? "right: 30px;" : "left: 30px;")}
        top: 0px;
      }
      .message {
        ${(props) =>
          props.theme.dir === "rtl" ? "padding: 0 24px 0 10px !important;" : "padding: 0 10px 0 24px !important;"}
        line-height: 120%;
      }
      .name {
        ${(props) =>
          props.theme.dir === "rtl" ? "padding: 0 24px 0 10px !important;" : "padding: 0 10px 0 24px !important;"}
      }
      .arrows {
        ${(props) =>
          props.theme.dir === "rtl" ? "padding: 0 24px 0 10px !important;" : "padding: 0 10px 0 24px !important;"}
        ${(props) => (props.theme.dir === "rtl" ? "margin-right: 28px;" : "margin-left: 28px;")}
      }
    }
  }
`;
