import styled from "styled-components";
import { Slider } from "@mui/material";
import { device } from "&assets/constants/responsiveness";
import { colors } from "&assets/constants/colors";

interface Props {
  disabled?: boolean;
}

export const SliderWrapper = styled.div<Props>`
  width: 90%;
  padding: 3px 0;

  @media ${device.mobile} {
    width: 100%;
  }

  .slider-title {
    font-size: 12px;
    font-weight: 800;
    color: ${colors.primary};
    letter-spacing: 0.05em;
    text-transform: uppercase;
    ${(props) => (props.disabled ? "opacity: 0.5;" : "")}
  }
`;

export const CustomSlider = styled(Slider)`
  padding: 0 !important;
  width: 95% !important;
  &.Mui-disabled {
    opacity: 0.4;
  }

  .MuiSlider-track {
    background: ${colors.linearGradientOne};
    height: 10px;
    ${(props) => (props.disabled ? "display: none;" : "")}
  }

  .MuiSlider-thumb {
    ${(props) => (props.theme.dir === "rtl" ? "margin-right: -10px;" : "margin-left: 5px;")}
    height: 16px;
    width: 16px;
    background: ${colors.linearGradientOne};
    border: 0 solid currentColor;

    &.Mui-disabled {
      display: none;
    }

    &:hover {
      box-shadow: inherit;
    }

    &:active {
      box-shadow: inherit;
    }

    &.Mui-focusVisible {
      box-shadow: inherit;
    }
  }

  .MuiSlider-rail {
    color: ${colors.primary};
    opacity: 0.2;
    height: 4px;
  }
`;
