import { colors } from "&assets/constants/colors";
import { device } from "&assets/constants/responsiveness";
import styled from "styled-components";

interface Props {
  isExam?: boolean;
}

export const LearningHeaderWrapper = styled.div<Props>`
  box-sizing: border-box;
  background: ${colors.linearGradientOne};
  display: flex;
  position: relative;
  min-height: 380px;
  height: auto;

  .learning-path-image {
    object-fit: contain;
  }

  .certification-badge {
    width: 89px;
    height: 29px;
    flex-shrink: 0;
    border-radius: 8px;
    background: ${colors.white};
    display: flex;
    align-items: center;
    justify-content: center;

    .certification-badge-text {
      color: ${colors.secondaryDark};
      font-size: 12px;
      font-style: normal;
      font-weight: 800;
      line-height: 120%;
    }
  }
  .disclaimer {
    font-weight: 500;
    font-size: 12px;
    line-height: 160%;
    color: ${colors.white};
    opacity: 1;
    width: 100%;
  }
  .title-subtitle-wrapper {
    .title {
      color: #ffffff;
      ${(props) => (props.theme.dir === "rtl" ? "text-align: right;" : "text-align: left;")}
    }
    .sub-title {
      color: #ffffff;
      ${(props) => (props.theme.dir === "rtl" ? "text-align: right;" : "text-align: left;")}
      opacity: 1;
      margin-bottom: 25px;
    }
  }
  .header-lines {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    height: 100%;
  }

  .card-content {
    display: flex;
    align-items: center;
    .info {
      font-weight: 400;
      font-size: 14px;
      ${(props) => (props.theme.dir === "rtl" ? "margin-right: 10px;" : "margin-left: 10px;")}
      color: #ffffff;
      align-items: center;
      justify-content: center;
      display: flex;
      ${(props) => (props.theme.dir === "rtl" ? "margin-right: 0;" : "margin-left: 0;")}
      ${(props) => (props.theme.dir === "rtl" ? "margin-left: 20px;" : "margin-right: 20px;")}
      .text {
        ${(props) => (props.theme.dir === "rtl" ? "margin-right: 6px;" : "margin-left: 6px;")}
      }
    }
  }
  .start-end-dates-wrapper {
    display: flex;
    margin: 5px 0;

    .enrollment-dates-wrapper {
      ${(props) => (props.theme.dir === "rtl" ? "margin-left: 48px;" : "margin-right: 48px;")}
    }
    span {
      color: ${colors.white};
      font-family: Mulish;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 200%; /* 26px */
    }
  }
  .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 18px 64px 18px 64px;
    box-sizing: border-box;
    .back-button {
      background: transparent;
      padding: 0;
      border: none;
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      width: fit-content;
      z-index: 2;
      cursor: pointer;
      .arrow {
        ${(props) => (props.theme.dir === "rtl" ? "" : "transform: rotate(180deg);")}
        ${(props) => (props.theme.dir === "rtl" ? "margin-left: 14px;" : "margin-right: 14px;")}
      }

      .button-text {
        font-weight: 800;
        font-size: 18px;
        line-height: 160%;
        text-align: center;
        color: ${colors.white};
      }
    }
    .content {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      .information {
        .card-career-details {
          padding: 20px 0;
          display: flex;
          flex-direction: column;
          gap: 10px;
          span:nth-child(1) {
            font-size: 20px;
            font-weight: 400;
            color: #ffffff;
            line-height: 27px;
            letter-spacing: -0.02em;
          }
          span:nth-child(2) {
            font-size: 16px;
            line-height: 27px;
            font-weight: 200;
            color: #ffffff;
            letter-spacing: -0.02em;
          }
        }
        ${(props) => (props.theme.dir === "rtl" ? "margin-right: 40px;" : "margin-left: 40px;")}
        .button-components {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
        }
      }
      .course-details-enroll {
        background: ${colors.secondary};
        min-width: 175px;
        height: 45px;
        margin-top: 10px;
        ${(props) => (props.theme.dir === "rtl" ? "margin-left: 5px;" : "margin-right: 5px;")}
      }
    }
  }

  @media ${device.inBetween} {
    .title-subtitle-wrapper {
      .title {
        font-size: 28px;
      }
      .sub-title {
        font-size: 16px;
      }
    }
    .card-content {
      .info {
        font-size: 12px;
      }
    }
  }

  @media ${device.mobile}, ${device.tablet} {
    height: auto;
    min-height: auto;
    .title-subtitle-wrapper {
      .title {
        margin: 0;
        display: none;
        margin-bottom: 24px;
      }
      .sub-title {
        margin-top: 24px;
      }
    }
    .header-lines {
      width: 265px;
      height: 254px;
    }
    .card-content {
      flex-wrap: wrap;
      .expand {
        width: 100%;
        justify-content: flex-start;
      }
    }
    .container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      max-width: 100%;
      padding: 16px;
      .content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        .information {
          ${(props) => (props.theme.dir === "rtl" ? "margin-right: 0;" : "margin-left: 0;")}
        }
        .title-mobile {
          font-weight: 700;
          font-size: 40px;
          line-height: 110%;
          letter-spacing: -0.02em;
          font-feature-settings: "cv11" on;
          color: #ffffff;
          margin: 0;
        }
        .image-back {
          margin-top: 24px;
          margin-bottom: 30px;
          .tier {
            width: fit-content;
            height: 14px;
            font-size: 9px;
            ${(props) => (props.theme.dir === "rtl" ? "right: 33px;" : "left: 33px;")}
          }
        }
        .course-details-enroll {
          width: fit-content;
          height: 38px;
          margin: 10px 0;
        }
      }
    }
  }
`;
