import React, { useEffect, useRef, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { isEmpty } from "lodash";

import { RootState } from "&store/store";
import { careerDetailsActions } from "./careerDetails.slice";
import { CareerDetailsWrapper } from "./careerDetails.styled";
import LearningHeader from "&components/learningHeader/learningHeader.component";
import Chip from "&components/chip/chip.component";
import MatchingOpportunities from "&components/matchingOpportunities/matchingOpportunities.component";
import ActionButton from "&components/buttons/actionButton/actionButton.component";
import { size } from "&assets/constants/responsiveness";
import Container from "&components/container/container.component";
import LearningPathway from "&components/learningPathway/learningPathway.component";
import CircularLoader from "&components/circularLoader/circularLoader.component";
import HtmlParser from "&components/htmlParser/htmlParser.component";
import { opportunitiesListingActions } from "&features/opportunitiesListing/opportunitiesListing.slice";
import getCDNProtectedURL from "&utils/getCDNProtectedImageSource";
import { images } from "&assets/constants/images-urls";
import { translationSelector } from "&utils/translationSelector";
import { useAuth0 } from "@auth0/auth0-react";

type ReduxProps = ConnectedProps<typeof connector>;

const CareerDetailsComponent = (props: ReduxProps) => {
  const { selectedCareer, getCareersById, loading, getJobs, loadingJobs, isLoggedIn, selectedCountry, jobs }: any =
    props;
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: size.mobile });
  const { t } = useTranslation(["careerDetails"]);
  const [readMore, setReadMore] = useState(false);
  const { loginWithRedirect } = useAuth0();
  const [showReadMore, setShowReadMore] = useState(false);

  const descriptionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const descriptionElement = descriptionRef.current;
    if (descriptionElement) {
      const isContentOverflowing = descriptionElement.scrollHeight > descriptionElement.clientHeight;
      setShowReadMore(isContentOverflowing);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCareer?.description]);

  const { id } = useParams();

  const jobsContent = jobs && jobs?.items ? jobs?.items : jobs;

  useEffect(() => {
    id && getCareersById(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("language")]);

  useEffect(() => {
    if (!isEmpty(selectedCareer)) {
      getJobs({
        countryId: selectedCountry?.value,
        careerId: selectedCareer?.id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCareer, localStorage.getItem("language")]);

  const loadingComponent = (
    <div className="loader-wrapper">
      <CircularLoader />
    </div>
  );

  const sortSkillsByRequired = (firstSkill: any, secondSkill: any) => {
    if (firstSkill?.required && !secondSkill?.required) {
      return -1;
    }
    if (!firstSkill?.required && secondSkill?.required) {
      return 1;
    }
    return 0;
  };
  let sortedSkills = selectedCareer?.careerSkills?.slice().sort(sortSkillsByRequired);

  return (
    <CareerDetailsWrapper>
      {loading ? (
        loadingComponent
      ) : (
        <>
          <LearningHeader
            title={translationSelector(selectedCareer, "name")}
            description={translationSelector(selectedCareer, "shortDescription")}
            handleBackClick={() => navigate(-1)}
            image={selectedCareer?.thumbnail || selectedCareer?.image || getCDNProtectedURL(images.learningPathwayImg)}
            hasButton={!isLoggedIn}
            handleButtonClick={() => loginWithRedirect()}
            buttonText={t("JOIN_NAMMISKILLS")}
            level={translationSelector(selectedCareer?.level, "alias")}
            selectedCareer={selectedCareer}
          />
          <Container>
            <div className="course-details">
              <p className="header">{t("CAREER_DESCRIPTION")}</p>
              <div className={`content-text ${readMore ? "expand" : ""}`} ref={descriptionRef}>
                {!readMore && showReadMore && <div className={`layer ${readMore ? "expand" : ""}`} />}
                <HtmlParser description={translationSelector(selectedCareer, "description")} />
              </div>
              {isMobile && showReadMore && (
                <ActionButton
                  className="read-more"
                  titleClassName="read-more-title"
                  title={readMore ? t("READ_LESS") : t("READ_MORE")}
                  handleClick={() => setReadMore(!readMore)}
                />
              )}

              <p className="header">{t("RELATED_SKILLS")}</p>
              <div className="chips">
                {sortedSkills?.map((item: any, index: number) => {
                  return <Chip title={translationSelector(item?.skills, "name")} key={index}></Chip>;
                })}
              </div>
            </div>
          </Container>
          {loadingJobs ? (
            <CircularLoader />
          ) : (
            <MatchingOpportunities
              opportunities={jobsContent}
              title={`${jobsContent?.length} ${
                jobsContent?.length > 1 ? t("AVAILABLE_OPPORTUNITIES") : t("AVAILABLE_OPPORTUNITY")
              }`}
              onAllButtonClick={() => navigate("/opportunities")}
            />
          )}
          {selectedCareer?.learningPaths && selectedCareer?.learningPaths.length > 0 && (
            <LearningPathway
              title={t("RELATED_LEARNING_PATHS")}
              allButtonText={t("VIEW_ALL_LEARNING_PATHS")}
              pathways={selectedCareer?.learningPaths}
            />
          )}
        </>
      )}
    </CareerDetailsWrapper>
  );
};

const mapStateToProps = (state: RootState) => ({
  selectedCareer: state.careerDetails.selectedCareer,
  loading: state.careerDetails.loading,
  loadingJobs: state.opportunitiesListing.loading,
  jobs: state.opportunitiesListing.jobs,
  isLoggedIn: state.login.isLoggedIn,
  selectedCountry: state.landingPage.selectedCountry,
});

const mapDispatchToProps = {
  getCareersById: careerDetailsActions.getCareersById,
  getJobs: opportunitiesListingActions.getJobs,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const CareerDetailsComponentRedux = connector(CareerDetailsComponent);

export { CareerDetailsComponentRedux as CareerDetailsComponent };
