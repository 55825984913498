
import styled from "styled-components";
import {colors} from "&assets/constants/colors";

export const AutoCompleteSelectWithTitleWrapper = styled.div`
  .select-title {
    font-weight: 700;
    font-size: 10px;
    line-height: 160%;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: ${colors.dark};
    margin-bottom: 4px;
  }
  `;

