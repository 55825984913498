import React, { FC } from "react";
import { BundleModalWrapper } from "./bundlesModal.styled";
import SubmitButton from "&components/buttons/submitButton/submitButton.component";
import { useTranslation } from "react-i18next";
import CustomModal from "&components/custom-modal/custom-modal.component";
import { CircularProgress, Grid } from "@mui/material";

interface Props {
  handleClose: any;
  open: boolean;
  title: string;
  bundle: any;
  action: any;
  content: any;
  disableButton: boolean;
  step: number;
  loading?: boolean;
  paymentMethod: string;
}
const BundlesModal: FC<Props> = ({
  handleClose,
  open,
  title,
  loading,
  action,
  disableButton,
  content,
  step,
}) => {
  const { t } = useTranslation(["bundles"]);

  return (
    <CustomModal
      handleClose={handleClose}
      titleClassName="bundle-modal-title"
      open={open}
      title={title}
    >
      <BundleModalWrapper step={step}>
        {content}
        <Grid padding={3}>
          <SubmitButton
            disable={disableButton}
            id={"sign-up-now-button"}
            className="proceed-button"
            title={loading ? <CircularProgress size={20}/> : step < 1 ? t("PROCEED") : t("CONTINUE")}
            handleClick={action}
          />
        </Grid>
      </BundleModalWrapper>
    </CustomModal>
  );
};

export default BundlesModal;
