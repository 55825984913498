import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { LearningPathwayWrapper } from "./examCards.styled";
import Container from "&components/container/container.component";
import CardsCarousel from "&components/cardsCarousel/cardsCarousel.component";
import SectionTitleButton from "&components/sectionTitleButton/sectionTitleButton.component";
import { listingPageActions } from "&features/listingPage/listingPage.slice";
import { RootState } from "&store/store";
import CircularLoader from "&components/circularLoader/circularLoader.component";
import { Exam } from "&features/listingPage/listingPage.type";
import { checkIFEnrolledLP } from "&utils/checkIfEnrolled";
import ImageBackground from "&components/imageBackground/imageBackground.component";
import ActivityCard from "&components/cards/activityCard/activityCard.component";

interface Props {
  title: string;
  allButtonText: string;
  exams?: Exam[];
  imgBg?: boolean;
}

const ExamCards = (props: Props) => {
  const { title, allButtonText, exams } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const examsRedux = useSelector((state: RootState) => state.listingPage.exams);
  const loading = useSelector(
    (state: RootState) => state.listingPage.loadingApi
  );
  const user = useSelector((state: RootState) => state.login.user);

  useEffect(() => {
    if (exams && exams?.length === 0) {
      const requestBody = {
        page: 1,
        limit: 12,
      };
      dispatch(listingPageActions.getExams(requestBody));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("language")]);

  const loadingComponent = (
    <div className="loader-wrapper">
      <CircularLoader />
    </div>
  );

  const handleClickExams = (item: any) => {
    checkIFEnrolledLP(item.id, user)
      ? navigate(`/dashboard/exam-details/${item.id}`)
      : navigate(`/exam-details/${item.id}`);
  };

  const content = exams || examsRedux?.items;

  const renderList = () => {
    return (
      <>
        {loading ? (
          loadingComponent
        ) : (
          <CardsCarousel>
            {content.map((item: Exam, index: number) => {
              return (
                <div className="cards" key={index}>
                  <ActivityCard
                    key={index}
                    type="exam"
                    exam={item}
                    handleClick={() => handleClickExams(item)}
                  />
                </div>
              );
            })}
          </CardsCarousel>
        )}
      </>
    );
  };
  if (props.imgBg)
    return (
      <ImageBackground
        title={title}
        allButtonText={allButtonText}
        children={renderList()}
        onAllButtonClick={() =>
          navigate("/listing-page?learning_activity=exams")
        }
      />
    );
  return (
    <LearningPathwayWrapper>
      <Container>
        <SectionTitleButton
          wrapperClassName="title-button-wrapper"
          buttonText={allButtonText}
          onButtonClick={() =>
            navigate("/listing-page?learning_activity=exams")
          }
          title={title}
        />
        {renderList()}
      </Container>
    </LearningPathwayWrapper>
  );
};

export default ExamCards;
