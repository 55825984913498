import React from "react";
import { ProfileAdditionalDetailsWrapper } from "./profileAdditionalDetails.styled";
import { AdditionalDetails, PersonalDetails } from "&features/dashboard/screens/profile/profile.type";
import SmallProfileItem, { SideDetailsProps } from "&components/profile/smallProfileItem/smallProfileItem.component";
import {
  CalendarIconDOB,
  EmailIcon,
  GenderIcon,
  PhoneIcon,
  ProfileInfoBlue,
  WorldGlobeIcon,
} from "&assets/constants/icons";
import { useTranslation } from "react-i18next";
import { upperFirst } from "lodash";
import { translationSelector } from "&utils/translationSelector";

interface Props {
  viewMode?: boolean;
}
const ProfileAdditionalDetails = (props: AdditionalDetails & PersonalDetails & Props) => {
  const {
    email,
    languages,
    phoneNumber,
    viewMode,
    dateOfBirth,
    gender,
    nationality,
    disability,
    disabilityDescription,
  } = props;
  const { t } = useTranslation(["profile"]);

  const additionalDetailsContent: SideDetailsProps[] = [
    {
      title: t("GENDER"),
      content: upperFirst(t(`${gender}`)),
      placeholder: t("ADD_YOUR_GENDER"),
      icon: <GenderIcon />,
    },
    {
      title: t("DATE_OF_BIRTH"),
      content: dateOfBirth?.format("D MMMM YYYY"),
      placeholder: t("ADD_YOUR_DATE_OF_BIRTH"),
      icon: <CalendarIconDOB />,
    },
    {
      title: t("EMAIL"),
      content: email,
      placeholder: t("ADD_YOUR_EMAIL"),
      icon: <EmailIcon />,
      hide: !!(viewMode && !email),
    },
    {
      title: t("PHONE"),
      content: phoneNumber,
      placeholder: t("ADD_YOUR_PHONE_NUMBER"),
      icon: <PhoneIcon />,
      hide: !!(viewMode && !phoneNumber),
    },
    {
      title: t("LANGUAGES"),
      content: languages?.map((lang) => translationSelector(lang, "name")),
      placeholder: t("ADD_YOUR_LANGUAGES"),
      icon: <WorldGlobeIcon />,
      hide: !!(viewMode && !languages),
    },
    {
      title: t("NATIONALITY"),
      content: nationality,
      placeholder: t("ADD_YOUR_NATIONALITY"),
      icon: <WorldGlobeIcon />,
      hide: !nationality,
    },
    {
      title: t("DISABILITY"),
      content: disability ? t("YES") : t("NO"),
      placeholder: t("ADD_YOUR_DISABILITY"),
      icon: <ProfileInfoBlue />,
      hide: disability === null,
    },
    {
      title: t("DISABILITY_DESCRIPTION"),
      content: disabilityDescription,
      placeholder: t("ADD_YOUR_DISABILITY_DESCRIPTION"),
      icon: <ProfileInfoBlue />,
      hide: !disabilityDescription,
    },
  ];

  return (
    <ProfileAdditionalDetailsWrapper>
      {additionalDetailsContent?.map(
        (detail, index) =>
          !detail.hide && (
            <SmallProfileItem
              key={index}
              title={detail.title}
              content={detail.content}
              link={detail.link}
              icon={detail.icon}
              placeholder={detail.placeholder}
              className="profile-detail-wrapper"
            />
          )
      )}
    </ProfileAdditionalDetailsWrapper>
  );
};

export default ProfileAdditionalDetails;
