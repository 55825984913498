import styled from "styled-components";
import { colors } from "&assets/constants/colors";
import { device } from "&assets/constants/responsiveness";

export const SkillOptionTagWrapper = styled.div`
  background: ${colors.linearGradientOne} padding-box, ${colors.linearGradientSixth} border-box;
  border-radius: 12px;
  border: 1px solid transparent;
  box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.25);
  width: fit-content;
  height: 46px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  .content {
    display: flex;
    width: 100%;
    gap: 5px;
    justify-content: space-between;
    align-items: center;
    .title {
      font-style: normal;
      font-size: 16px;
      font-weight: 700;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
      color: ${colors.white};
      white-space: nowrap;
    }

    .delete-icon {
      cursor: pointer;
      min-width: 16px;
      height: 16px;
      border-radius: 8px;
      display: flex;
      padding: 0;
      justify-content: center;
      align-items: center;
      border: 1px solid transparent;
      box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
      background: ${colors.linearGradientWhite} padding-box, ${colors.linearGradientSixth} border-box;
    }
  }

  @media ${device.tablet} {
    height: fit-content;
    min-height: 46px;
  }
  @media ${device.mobile} {
    ${(props) => (props.theme.dir === "rtl" ? "margin-left: 0;" : "margin-right: 0;")}
    padding: 0 15px;
    height: fit-content;
    min-height: 46px;

    .title {
      white-space: initial !important;
      font-size: 13px !important;
    }
  }
`;
