import React, { useEffect, useState } from "react";
import { connect, ConnectedProps, useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { RootState } from "&store/store";
import { LearningActivitiesWrapper } from "./learningActvities.styled";
import DashboardListing from "&components/dashboardListing/dashboardListing.component";
import TextLine from "&components/textLine/textLine.component";
import Container from "&components/container/container.component";
import LearningPathwayDashboard from "&components/learningPathwayDashboard/learningPathwayDashboard.component";
import { loginActions } from "&features/login/login.slice";
import DashboardBanner from "&components/dashboardBanner/dashboardBanner.component";
import { courseDetailsActions } from "&features/courseDetails/courseDetails.slice";
import { ERROR_TEXTS } from "&assets/constants/statics";
import UploadCompletionProof from "&components/uploadCompletionProof/uploadCompletionProof.component";
import AddSkillModal from "&components/addSkillModal/addSkillModal.component";
import MessageActionModal, {
  MessageActionModalProps,
} from "&components/modals/messageActionModal/messageActionModal.component";
import { RenderButtonsProps } from "&components/learningPathwayActivity/LearningPathwayActivity.component";
import LearningActivityActionButtonsModal from "&components/dashboard/learningActivityActionButtonsModal/learningActivityActionButtonsModal.component";
import { translationSelector } from "&utils/translationSelector";
import i18n from "&config/i18n";
import { LearningPathItem } from "&features/listingPage/listingPage.type";

type ReduxProps = ConnectedProps<typeof connector>;

const LearningActivities = (props: ReduxProps) => {
  const { t } = useTranslation(["dashboard"]);
  const {
    user,
    //  getProfile
  } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const status = useSelector((state: RootState) => state.courseDetails.status);
  const sponsorshipError = useSelector(
    (state: RootState) => state.courseDetails.sponsorshipError
  );
  const { instructions, voucher, instructionsAr } = useSelector(
    (state: RootState) => state.courseDetails.voucherResponse
  );
  const [applyVoucherOpen, setApplyVoucherOpen] = useState(false);
  const [goToFreeCoursesOpen, setGoToFreeCoursesOpen] = useState(false);
  const [voucherActivity, setVoucherActivity] = useState<any>(undefined);
  const [showFinishEligibility, setShowFinishEligibility] = useState(false);
  const [uploadProofOpen, setUploadProof] = useState(false);
  const [addSkillsOpen, setAddSkillsOpen] = useState(false);
  const [activitySelected, setActivity] = useState<{
    item?: any;
    type: string;
    learningPathId: number;
    reason?: string;
  }>({ item: undefined, type: "", learningPathId: 0, reason: "" });

  useEffect(() => {
    dispatch(loginActions.getUserEligibilityForm());
    dispatch(loginActions.getUserCourses());
    dispatch(loginActions.getUserLearningPaths());
    dispatch(loginActions.getUserSponsorships());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("language")]);

  useEffect(() => {
    if (status === "CLAIM_SPONSORSHIP_SUCCESSFULLY") {
      setApplyVoucherOpen(true);
      dispatch(courseDetailsActions.setCourseDetails({ status: "" }));
      dispatch(loginActions.getProfileThunk({}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    if (state?.learningPath) {
      const element = document.getElementById(state?.learningPath?.toString());
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.userLearningPaths]);

  useEffect(() => {
    if (sponsorshipError === "COMPLETE_AT_LEAST_ONE_COURSE") {
      setGoToFreeCoursesOpen(true);
      dispatch(courseDetailsActions.setCourseDetails({ sponsorshipError: "" }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sponsorshipError]);

  const handleApplyVoucher = (
    event: any,
    activity: any,
    type: string,
    learningPathId?: any
  ) => {
    event?.stopPropagation();
    if (
      !user?.eligibilityForm ||
      (user?.eligibilityForm && !user?.eligibilityForm?.approved)
    ) {
      navigate("/dashboard/profile");
      // setShowFinishEligibility(true);
    } else {
      setVoucherActivity(activity);
      dispatch(
        courseDetailsActions.claimSponsorship({
          id: activity.id,
          type: `${type}s`,
          sponsorshipType: activity?.sponsorshipType,
          learningPathId:
            type === "exam" && learningPathId ? learningPathId : undefined,
        })
      );
    }
  };

  const isArabic = i18n.language === "ar";

  const voucherModalDescription = (instructions: string) => {
    const voucherString = voucher || "";
    const activityName = voucherActivity?.name || "";
    return (
      instructions
        // eslint-disable-next-line no-template-curly-in-string
        .replaceAll("${voucher.code}", voucherString)
        // eslint-disable-next-line no-template-curly-in-string
        .replaceAll("${activity.name}", activityName)
    );
  };

  const hasLearningPathway =
    user?.userLearningPaths && user?.userLearningPaths?.length > 0;

  const hasCourses = user?.userCourses && user?.userCourses?.length > 0;

  const hasCompletedLearningPathway =
    user?.completedLearningPaths && user?.completedLearningPaths?.length > 0;

  const hasCompletedCourses =
    user?.completedCourses && user?.completedCourses?.length > 0;

  const modalsSelector: (
    type: "applyVoucher" | "goToFreeCourses" | "fillEligibility"
  ) => MessageActionModalProps = (
    type: "applyVoucher" | "goToFreeCourses" | "fillEligibility"
  ) => {
    const applyVoucher: MessageActionModalProps = {
      content: voucherModalDescription(
        isArabic && instructionsAr ? instructionsAr : instructions
      ),
      isHTML: true,
    };
    const goToFreeCourses: MessageActionModalProps = {
      content: translationSelector(ERROR_TEXTS, "COMPLETE_AT_LEAST_ONE_COURSE"),
      actionButtonTitle: "View list of our free courses",
      onActionButtonClick: () =>
        navigate("/listing-page?learning_activity=courses&type=free"),
    };

    const fillEligibility: MessageActionModalProps = {
      content: translationSelector(ERROR_TEXTS, "FILL_ELIGIBILITY_FORM"),
      actionButtonTitle: "Go to Eligibility Form",
      onActionButtonClick: () => navigate("/dashboard/profile"),
    };

    const selector = {
      applyVoucher: applyVoucher,
      goToFreeCourses: goToFreeCourses,
      fillEligibility: fillEligibility,
    };
    return selector[type];
  };

  const [openActivityPopup, setOpenActivityPopup] = useState<{
    renderButtonsProps?: RenderButtonsProps;
    open: boolean;
  }>({ renderButtonsProps: undefined, open: false });

  return (
    <LearningActivitiesWrapper>
      <Container className="dashboard-container">
        <DashboardBanner />
        {hasCourses && (
          <DashboardListing
            data={user?.userCourses}
            title={t("REGISTERED_COURSES")}
            isCourse
          />
        )}
        {hasLearningPathway && (
          <TextLine title={t("REGISTERED_LEARNING_PATHWAY")} />
        )}
      </Container>
      {hasLearningPathway &&
        user?.userLearningPaths?.map(
          (item: LearningPathItem, index: number) => {
            return (
              <LearningPathwayDashboard
                id={item?.learningPathId}
                learningPathItem={item}
                key={index}
                learningActivities={item?.learningPaths?.learningActivities}
                learningPath={item?.learningPaths}
                open={state?.learningPath === item?.learningPathId?.toString()}
                progress={item?.progress}
                handleOpenPopup={(renderButtonsProps: RenderButtonsProps) =>
                  setOpenActivityPopup({
                    open: true,
                    renderButtonsProps: renderButtonsProps,
                  })
                }
                handleApplyVoucher={handleApplyVoucher}
                handleCompletionClick={(
                  activity: any,
                  item: any,
                  type: string
                ) => {
                  setActivity({
                    reason:
                      translationSelector(activity, "reason") || undefined,
                    item: item,
                    learningPathId: activity.learningPathId,
                    type,
                  });
                  activity.status === 3
                    ? setAddSkillsOpen(true)
                    : setUploadProof(true);
                }}
              />
            );
          }
        )}
      <Container className="dashboard-container">
        {hasCompletedCourses && (
          <DashboardListing
            data={user?.completedCourses}
            title={t("COMPLETED_COURSES")}
            isCourse
          />
        )}
        {hasCompletedLearningPathway && (
          <TextLine title={t("COMPLETED_LEARNING_PATHWAY")} />
        )}
      </Container>
      {hasCompletedLearningPathway &&
        user?.completedLearningPaths?.map(
          (item: LearningPathItem, index: number) => {
            return (
              <LearningPathwayDashboard
                key={index}
                learningPathItem={item}
                learningActivities={item?.learningPaths?.learningActivities}
                learningPath={item?.learningPaths}
                progress={item?.progress}
                handleApplyVoucher={handleApplyVoucher}
                handleCompletionClick={(
                  activity: any,
                  item: any,
                  type: string
                ) => {
                  setActivity({
                    reason:
                      translationSelector(activity, "reason") || undefined,
                    item: item,
                    learningPathId: activity.learningPathId,
                    type,
                  });
                  setAddSkillsOpen(true);
                }}
              />
            );
          }
        )}
      <MessageActionModal
        open={applyVoucherOpen || goToFreeCoursesOpen || showFinishEligibility}
        handleClose={() => {
          setApplyVoucherOpen(false);
          setGoToFreeCoursesOpen(false);
          setShowFinishEligibility(false);
        }}
        modalClassName={"voucher-modal"}
        {...modalsSelector(
          applyVoucherOpen
            ? "applyVoucher"
            : goToFreeCoursesOpen
            ? "goToFreeCourses"
            : "fillEligibility"
        )}
      />
      <LearningActivityActionButtonsModal
        handleClose={() =>
          setOpenActivityPopup({ open: false, renderButtonsProps: undefined })
        }
        open={openActivityPopup.open}
        renderButtonsProps={openActivityPopup.renderButtonsProps}
      />
      <UploadCompletionProof
        open={uploadProofOpen}
        handleClose={() => {
          setUploadProof(!uploadProofOpen);
        }}
        id={activitySelected?.item?.id}
        type={activitySelected?.type}
        learningPathId={activitySelected?.learningPathId}
        reason={activitySelected?.reason}
      />
      <AddSkillModal
        open={addSkillsOpen}
        handleClose={() => {
          setAddSkillsOpen(!addSkillsOpen);
        }}
        skills={
          activitySelected?.item?.courseSkills ||
          activitySelected?.item?.labSkills ||
          activitySelected?.item?.examSkills
        }
      ></AddSkillModal>
    </LearningActivitiesWrapper>
  );
};

const mapStateToProps = (state: RootState) => ({
  learningPathways: state.listingPage.learningPathways,
  user: state.login.user,
});

const mapDispatchToProps = {
  getProfile: loginActions.getProfileThunk,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const LearningActivitiesRedux = connector(LearningActivities);

export { LearningActivitiesRedux as LearningActivities };
