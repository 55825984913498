import styled from "styled-components";
import { device } from "&assets/constants/responsiveness";
import { colors, levelsBackground } from "&assets/constants/colors";

interface Props {
  level: string;
  index: number;
  short?: boolean;
}

export const CareerLevelsWrapper = styled.div<Props>`
  position: relative;
  min-width: 100%;
  background: ${(props) =>
    props?.level ? levelsBackground[props.index] : levelsBackground[0]};
  box-sizing: border-box;

  .forward-arrow {
    position: absolute;
    ${(props) =>
            props.theme.dir === "rtl"
                    ? "left: 40px;"
                    : "right: 40px;"}
    z-index: 10;
  }

  .back-arrow {
    position: absolute;
    ${(props) =>
            props.theme.dir === "rtl"
                    ? "right: 40px;"
                    : "left: 40px;"}
    z-index: 10;
  }

  .level-big-text {
    position: absolute;
    bottom: -40px;
    ${(props) =>
            props.theme.dir === "rtl"
                    ? "right: -20px;"
                    : "left: -20px;"}
    z-index: 1;
    text-transform: capitalize;
    font-weight: 700;
    font-size: 180px;
    line-height: 120%;
    color: #ffffff;
    opacity: 0.2;
  }

  @media ${device.tablet} {
    padding: 0;
    ${(props) => (props.short ? "min-width: fit-content;" : "")};
    .level-big-text {
      font-size: 100px;
      ${(props) =>
              props.theme.dir === "rtl"
                      ? "right: -5px;"
                      : "left: -5px;"}
      bottom: -22px;
    }
  }

  @media ${device.mobile} {
    padding: 0;
    ${(props) => (props.short ? "min-width: fit-content;" : "")};
    .level-big-text {
      font-size: 65px;
      ${(props) =>
              props.theme.dir === "rtl"
                      ? "right: -5px;"
                      : "left: -5px;"}
      bottom: -15px;
      opacity: 0.15;
    }
  }
`;

interface OptionsProps {
  short?: boolean;
}

export const OptionsWrapper = styled.div<OptionsProps>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  .careers-container{
    max-width: 1025px;
    padding-bottom: 40px;
    justify-content: center;
  }
  .top-border {
    width: 100%;
    border-top: 1px solid ${colors.white};
  }
  .options-container {
    box-sizing: border-box;
    padding: 0 102px;
    position: relative;
  }

  .view-all-wrapper {
    position: absolute;
    top: 16px;
    ${(props) =>
            props.theme.dir === "rtl"
                    ? "left: 102px;"
                    : "right: 102px;"}
    z-index: 3;
  }

  .options-wrapper-cards {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 208px;
    position: relative;
    box-sizing: content-box;
    max-width: 100%;
    z-index: 2;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    @media ${device.tablet} {
      overflow-x: ${(props) => (props.short ? "visible" : "auto")};
      justify-content: initial;
      min-height: 175px;
    }

    @media ${device.mobile} {
      overflow-x: ${(props) => (props.short ? "visible" : "auto")};
      justify-content: initial;
      min-height: 175px;
    }
  }

  @media ${device.tablet}, ${device.mobile} {
    .options-container {
      padding: 0;
    }
    .view-all-wrapper{
      ${(props) =>
              props.theme.dir === "rtl"
                      ? "left: 16px;"
                      : "right: 16px;"}
    }
  }
`;
