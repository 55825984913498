import React, { useState } from "react";
import { FrequentlyAskedQuestionsWrapper } from "./frequentlyAskedQuestions.styled";
import FAQsBackground from "&assets/images/faqsBack.svg";
import FaqCard from "&components/faqCard/faqCard.component";
import Container from "&components/container/container.component";
import {
  PageSection,
  PageSectionContent,
} from "&features/landingPage/landingPage.type";
import { useNavigate } from "react-router-dom";
import SubmitButton from "&components/buttons/submitButton/submitButton.component";
import {translationSelector} from "&utils/translationSelector";
import {useTranslation} from "react-i18next";

interface Props {
  data: PageSection;
  hideTitle?: boolean;
  showViewAll?: boolean;
}

const FrequentlyAskedQuestions = (props: Props) => {
  const { data, hideTitle, showViewAll = false } = props;
  const navigate = useNavigate();
  const faqArray = showViewAll ? data.sectionContents.slice(0, 6) : data.sectionContents ;
  const sortedFaqArray = faqArray.slice().sort((a: any, b: any) => a.order - b.order);
  const { t } = useTranslation(["landingPage"]);

  const [openedQuestion, setOpenedQuestion] = useState<PageSectionContent>({});
  const arrayDivider = () => {
    let firstArray = [];
    let secondArray = [];
    const divider = sortedFaqArray?.length % 2;
    firstArray = sortedFaqArray?.slice(0, (sortedFaqArray?.length + divider) / 2);
    secondArray = sortedFaqArray?.slice(
      (sortedFaqArray?.length + divider) / 2,
      sortedFaqArray?.length
    );
    return {
      firstArray,
      secondArray,
    };
  };

  const renderFAQColumn = (array: PageSectionContent[]) => {
    return array?.map((item: any, index: number) => {
      const selected = item.id === openedQuestion.id;
      return (
        <FaqCard
          key={index}
          question={translationSelector(item, 'title')}
          answer={translationSelector(item, 'content')}
          opened={selected}
          handleClick={() => setOpenedQuestion(selected ? "" : item)}
        />
      );
    });
  };
  return (
    <FrequentlyAskedQuestionsWrapper>
      <Container>
        <div className="content">
          {!hideTitle && <p className="title">{translationSelector(data, 'title')}</p>}
          <div className="circle-back">
            <img src={FAQsBackground} alt="" />
          </div>
          <div className="faqs">
            <div className="faqs-section">
              {renderFAQColumn(arrayDivider().firstArray)}
            </div>
            <div className="faqs-section">
              {renderFAQColumn(arrayDivider().secondArray)}
            </div>
          </div>
        </div>
        {showViewAll && (
          <SubmitButton
            title={t('SHOW_MORE')}
            id={`show-more-button`}
            handleClick={() => {
              navigate("/faq");
            }}
            className="view-all-button"
            titleClassName="view-all-button-title"
          />
        )}
      </Container>
    </FrequentlyAskedQuestionsWrapper>
  );
};

export default FrequentlyAskedQuestions;
