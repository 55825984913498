import React from "react";
import { BuildProfileStepTwoWrapper } from "./buildProfileStepTwo.styled";
import SkillsBuilder from "&components/skillsBuilder/skillsBuilder.component";
import { AssessmentForm } from "&features/assessment/assessment.type";
import { Setting } from "&features/global/global.type";

interface Props {
  formValues: AssessmentForm;
  setAssessmentForm: any;
  loadingAssessment: boolean;
  settings: Setting[];
  hide?: boolean;
}

const BuildProfileStepTwo = (props: Props) => {
  const { formValues, setAssessmentForm, loadingAssessment, settings, hide } = props;
  return (
    <BuildProfileStepTwoWrapper>
      {!hide && <SkillsBuilder
        formValues={formValues}
        setAssessmentForm={setAssessmentForm}
        loadingAssessment={loadingAssessment}
        settings={settings}
      />}
    </BuildProfileStepTwoWrapper>
  );
};

export default BuildProfileStepTwo;
