import styled from "styled-components";
import { Pagination } from "@mui/material";
import { colors } from "&assets/constants/colors";

export const PaginationWrapper = styled.div`
  margin-top: 64px;
  margin-bottom: 53px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PaginationMUI = styled(Pagination)`
  .MuiPaginationItem-previousNext {
    background: transparent !important;
    margin: 0 12px !important;
    svg {
      color: ${colors.primary};
    }
  }

  .MuiPaginationItem-ellipsis {
    padding-top: 8px !important;
  }
  .MuiPaginationItem-root {
    margin: 0 6px;
    width: 44px;
    height: 44px;
    background: rgba(16, 140, 210, 0.1);
    border-radius: 8px;
    font-weight: 700;
    font-size: 14px;
    line-height: 130%;
    color: ${colors.dark};

    &.Mui-selected {
      background: ${colors.linearGradientOne};
      color: ${colors.white};

      &:hover {
        background: ${colors.linearGradientOne};
      }
    }

    &:hover {
      background: ${colors.primaryLight};
    }
  }
`;
