import React, { useEffect, useRef, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { push } from "redux-first-history";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Slide from "@mui/material/Slide";
import { useAuth0 } from "@auth0/auth0-react";

import { loginActions } from "./login.slice";
import { LoginWrapper } from "./login.styled";
import LogoImg from "&assets/images/logo-white.svg";
import { RootState } from "&store/store";
import Container from "&components/container/container.component";
import buildAssessmentBody from "&utils/buildAssessmentObject";
import { useMediaQuery } from "react-responsive";
import { size } from "&assets/constants/responsiveness";
import { VerifyEmailComponent } from "./screens/verifyEmail/verifyEmail.component";
import ReactGA from "react-ga4";
import { TrackGoogleAnalyticsEvent } from "&utils/google-analytics/googleAnalytics";
import VideoModal from "&components/modals/videoModal/videoModal.component";
import { toast } from "react-hot-toast";
import i18n from "i18next";
import CircularLoader from "&components/circularLoader/circularLoader.component";

type ReduxProps = ConnectedProps<typeof connector>;

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

const LoginComponent = (props: ReduxProps) => {
  let navigate = useNavigate();
  const { getAccessTokenSilently, isAuthenticated, loginWithRedirect, isLoading, error } = useAuth0();
  const [showVideo, setShowVideo] = useState<boolean>(false);
  const {
    isLoggedIn,
    push,
    getProfile,
    status,
    setLogin,
    assessmentFormState,
    assessmentStep,
    updateProfile,
    verifyEmail,
    updateProfileStatus,
  } = props;

  const { t } = useTranslation(["login"]);
  const location = useLocation();
  const isMobile = useMediaQuery({ maxWidth: size.mobile });
  const isTablet = useMediaQuery({
    minWidth: size.mobile,
    maxWidth: size.tablet,
  });

  const dir = i18n.dir();
  const ref = useRef<any>(null);

  useEffect(() => {
    ref?.current?.scrollIntoView();
  }, []);

  const isSignup = location?.pathname === "/signup";

  const [
    ,
    // loading

    setLoading,
  ] = useState(false);

  const loadingComponent = (
    <div className="loader-wrapper">
      <CircularLoader />
    </div>
  );

  useEffect(() => {
    if (
      location.search &&
      location.search.includes("verify") &&
      location.search.includes("your") &&
      location.search.includes("email")
    ) {
      setLogin({ verifyEmail: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      location.search &&
      location.search.includes("message") &&
      location.search.includes("verified") &&
      location.search.includes("success")
    ) {
      console.log("coming from verifying email");
      toast.success(t("VERIFY_EMAIL_SUCCESS"));
      loginWithRedirect({
        authorizationParams: {
          redirect_uri: `${process.env.REACT_APP_AUTH0_REDIRECT_URI}/signup`,
        },
      });
    }
    if (isLoggedIn) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, push, navigate]);

  useEffect(() => {
    if (isLoading) {
      return;
    } else if (error) {
      console.log("error auth0", error);
      if (error.message === "Invalid state") {
        loginWithRedirect();
      }
    } else if (isAuthenticated) {
      getToken(isSignup);
    } else {
      // console.log("here");
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isLoading, error]);

  const updateProfileAfterAssessment = () => {
    updateProfile({
      careerId: assessmentFormState?.careerId,
      skills: buildAssessmentBody(assessmentFormState, true),
    });
    TrackGoogleAnalyticsEvent({
      category: "Assessment",
      action: "signed_up_after_assessment",
      label: assessmentFormState?.careerPath || "",
    });
  };

  useEffect(() => {
    if (status === "SIGNUP_SUCCESSFULL") {
      console.log("from SIGNUP_SUCCESSFULL =============");
      ReactGA.event("sign_up");
      if (assessmentFormState?.careerId && assessmentStep === 3) {
        updateProfileAfterAssessment();
      } else {
        getProfile({ setGAUser: true });
      }
    }
    if (status === "LOGIN_SUCCESSFULL") {
      ReactGA.event("login");
      console.log("from LOGIN_SUCCESSFULL =============");
      assessmentFormState?.careerId && assessmentStep === 3
        ? updateProfileAfterAssessment()
        : getProfile({ setGAUser: true });
    }
    if (updateProfileStatus === "UPDATE_PROFILE_SUCCESSFUL") {
      console.log("from UPDATE_PROFILE_SUCCESSFUL ============");
      getProfile({ setGAUser: true });
    }
    setLogin({ status: "", updateProfileStatus: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, updateProfileStatus]);

  useEffect(() => {
    if (status === "GET_PROFILE_SUCCESSFULLY" || status === "GET_PROFILE_FAILED") {
      setLoading(false);
    }
  }, [status]);

  const getToken = async (isSignup?: boolean) => {
    console.log("getting token");
    try {
      setLoading(true);
      const result = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          redirect_uri: `${process.env.REACT_APP_AUTH0_REDIRECT_URI}/login`,
          scope: "openid profile email",
        },
      });
      console.log(result);
      if (result) {
        localStorage.setItem("accessToken", result);
        setLogin({
          status: isSignup ? "SIGNUP_SUCCESSFULL" : "LOGIN_SUCCESSFULL",
        });
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  return (
    <LoginWrapper ref={ref}>
      <Container>
        {!isMobile && !isTablet && (
          <img src={LogoImg} alt="logo" className="login-logo" onClick={() => navigate("/")} />
        )}
        <div className="content">
          <Slide direction={dir === "rtl" ? "right" : "left"} in={true} mountOnEnter unmountOnExit>
            <div className="auth-container">{verifyEmail ? <VerifyEmailComponent /> : loadingComponent}</div>
          </Slide>
        </div>
      </Container>
      <VideoModal
        open={showVideo}
        onClose={() => setShowVideo(false)}
        url={"https://www.youtube.com/watch?v=nbYOitW-pAI"}
      />
    </LoginWrapper>
  );
};

const mapStateToProps = (state: RootState) => ({
  isLoggedIn: state.login.isLoggedIn,
  status: state.login.status,
  updateProfileStatus: state.login.updateProfileStatus,
  assessmentFormState: state.assessment.assessmentFormState,
  assessmentStep: state.assessment.assessmentStep,
  verifyEmail: state.login.verifyEmail,
  socialLoggedIn: state.login.socialLoggedIn,
});

const mapDispatchToProps = {
  signIn: loginActions.loginThunk,
  setLogin: loginActions.setLogin,
  getProfile: loginActions.getProfileThunk,
  updateProfile: loginActions.updateProfile,
  push,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const LoginComponentRedux = connector(LoginComponent);

export { LoginComponentRedux as LoginComponent };
