import React from "react";
import {
  MessageActionModalButtonWrapper,
  MessageActionModalWrapper,
} from "./messageActionModal.styled";
import CustomModal from "&components/custom-modal/custom-modal.component";
import HtmlParser from "&components/htmlParser/htmlParser.component";
import SubmitButton from "&components/buttons/submitButton/submitButton.component";

interface Props {
  handleClose: () => void;
  open: boolean;
  content?: any;
  isHTML?: boolean;
  modalClassName?: string;
  actionButtonTitle?: string;
  onActionButtonClick?: () => void;
}

export interface MessageActionModalProps {
  content?: any;
  isHTML?: boolean;
  modalClassName?: string;
  actionButtonTitle?: string;
  onActionButtonClick?: () => void;
}

const MessageActionModal = (props: Props) => {
  const {
    handleClose,
    open,
    content,
    isHTML,
    modalClassName,
    onActionButtonClick,
    actionButtonTitle,
  } = props;

  const renderContent = () => {
    if (isHTML) {
      return <HtmlParser description={content} />;
    } else if (typeof content === "string") {
      return <p className={"description-error-text"}>{content}</p>;
    } else {
      return content;
    }
  };

  const RenderActionButtonFooter = (props: any) => {
    if (actionButtonTitle) {
      return (
        <MessageActionModalButtonWrapper className="footer-wrapper">
          <SubmitButton
            id={"modal-action-button"}
            title={actionButtonTitle}
            handleClick={onActionButtonClick}
          />
        </MessageActionModalButtonWrapper>
      );
    } else {
      return <></>;
    }
  };

  return (
    <CustomModal
      className={modalClassName}
      handleClose={handleClose}
      open={open}
      footer={<RenderActionButtonFooter />}
    >
      <MessageActionModalWrapper>{renderContent()}</MessageActionModalWrapper>
    </CustomModal>
  );
};

export default MessageActionModal;
