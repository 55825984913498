import React from "react";
import { BenifitCardWrapper } from "./benifitCard.styled";
import {BenefitCardBackground} from "&assets/constants/icons";

interface Props {
  title: string;
  description: string;
  icon: any;
}

const BenefitCard = (props: Props) => {
  const { title, description, icon } = props;
  return (
    <BenifitCardWrapper>
      <div className='background-wrapper'>
        <BenefitCardBackground />
      </div>
      <div className="icon-wrapper">{icon}</div>
      <div className="title-description-wrapper">
        <div className="card-title">{title}</div>
        <div className="card-description">{description}</div>
      </div>
    </BenifitCardWrapper>
  );
};

export default BenefitCard;
