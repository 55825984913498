export const learningPathwayNameSpace = {
  en: {
    COURSES: " Courses",
    BACK: "Back",
    UPON_ENROLLMENT_YOU_CAN_APPLY_FOR_SPONSORSHIP:
      "* Upon enrollment, you can apply for sponsorship and get free access to this course.",
    VIEW_LEARNING_PATHWAY: "View Learning Pathway",
    ENROLL_NOW: "Enroll now",
    ENROLLMENT_FROM: "Enrollment from",
    TO: "to",
    START: "Start",
    CERTIFICATION: "Certification",
    VIEW_ALL_OPPORTUNITIES: "View all opportunities",
    MATCHING_OPPORTUNITIES: "Matching opportunities",
    LAB: "lab",
    EXAM: "exam",
    COURSE: "course",
    COMPLETED: "Completed",
    LINKEDIN: "LinkedIn",
    ASSESMENT_URL_MSG: 'To access the pre-assessment again, please click on the here'
  },
  ar: {
    ASSESMENT_URL_MSG: 'للوصول إلى اختبار التقييم، يرجى النقر على هنا',
    BACK: "السابق",
    UPON_ENROLLMENT_YOU_CAN_APPLY_FOR_SPONSORSHIP:
      "* عند التسجيل، يمكنك التقدم بطلب للحصول على رعاية والحصول على حرية الوصول إلى هذه الدورة.",
    VIEW_LEARNING_PATHWAY: "View Learning Pathway",
    ENROLL_NOW: "تسجل الآن",
    ENROLLMENT_FROM: "Enrollment from",
    TO: "to",
    START: "بدء",
    CERTIFICATION: "شهادة",
    VIEW_ALL_OPPORTUNITIES: "اظهار كل الفرص",
    MATCHING_OPPORTUNITIES: "فرص العمل المطابقة",
    COURSES: " دورات تعليمية",
    COURSE: "الدورة التعليمية",
    EXAM: "الامتحان",
    LAB: "العمل التطبيقي",
    COMPLETED: "منجز",
    LINKEDIN: "لينكد إن",
  },
};
