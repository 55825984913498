import React from "react";
import {
  AssessmentStepsWrapper,
  SingleStepsWrapper,
  StepWrapper,
} from "./assessmentSteps.styled";
import { VectorCheckIcon } from "&assets/constants/icons";
import { useTranslation } from "react-i18next";
interface Props {
  className?: string;
  step: number;
  onStepClick?: (step: number) => void;
  steps: Step[];
}

interface Step {
  icon: any;
  description: string;
}

const AssessmentSteps = (props: Props) => {
  const { step = 1, className, onStepClick, steps } = props;

  const { t } = useTranslation(["landingPage"]);

  const renderStepNumber = (index: number, length: number) => (
    <div className="step-number">{`${t("STEP")} ${index}`}</div>
  );

  const renderDescription = (item?: Step) => (
    <div className="description">{item?.description}</div>
  );

  
  const renderStep = (item: Step, index: number, length: number) => {
    return (
      <div className="step-divider" key={index}>
        <StepWrapper
          onClick={() => onStepClick && onStepClick(index)}
          selected={index === step}
        >
          <div
            className={
              index === step
                ? "step-indecator active-step"
                : index < step
                ? "step-indecator completed-step"
                : "step-indecator pending-step"
            }
          >
            {index < step && <VectorCheckIcon/>}
          </div>
          <SingleStepsWrapper>
            <span>
              <span>{`${t("STEP")} ${index} `}<span className="step-title-seperator"> - </span></span>
              <span>
                {index === step ? (
                  <span className="active-step-text">{t('IN_PROGRESS')}</span>
                ) : index < step ? (
                  <span className="completed-step-text">{t('COMPLETED')}</span>
                ) : (
                  <span className="pending-step-text">{t('PENDING')}</span>
                )}
              </span>
            </span>
            <div className={index === step ? 'active-item-description' : ''}>
              {item.description}
            </div>
          </SingleStepsWrapper>
        </StepWrapper>
        {index !== length && <hr/>}
      </div>
    );
  };

  return (
    <AssessmentStepsWrapper className={className}>
      <div className="step-number-description-mobile">
        {renderStepNumber(step, steps.length)}
        {renderDescription(steps[step - 1])}
      </div>
      <div className="steps-wrapper">
        {steps.map((step: Step, index: number) =>
          renderStep(step, index + 1, steps.length)
        )}
      </div>
    </AssessmentStepsWrapper>
  );
};

export default AssessmentSteps;
