import { map } from "lodash";

const selectValueFromTitleForSlider = (marks: string[], value: string) => {
  let result = 0;

  const sectionValue = 100 / (marks.length - 1);
  map(marks, (mark, index) => {
    const min = sectionValue * index;

    if (value === mark) {
      result = min;
    }
  });
  return result;
};

export default selectValueFromTitleForSlider;
