import React from "react";
import { create } from "jss";
import rtl from "jss-rtl";
import { createTheme, ThemeProvider } from "@mui/material";
import { StylesProvider, jssPreset } from "@mui/styles";

interface Props {
  i18n: any;
  children: React.ReactNode;
}

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const ConfigProvider = (props: Props) => {
  const { i18n, children } = props;

  return (
    <StylesProvider jss={jss}>
      <ThemeProvider
        theme={createTheme({
          direction: i18n.dir(),
        })}
      >
        {children}
      </ThemeProvider>
    </StylesProvider>
  );
};

export default ConfigProvider;
