import styled from "styled-components";
import { colors } from "&assets/constants/colors";
import { device } from "&assets/constants/responsiveness";

export const CompleteProfilePromptWrapper = styled.div`
  width: 100%;
  min-height: 165px;
  background: ${colors.linearGradientThree};
  border-radius: 16px;
  box-sizing: border-box;
  position: relative;
  padding: 23px 41px;

  display: flex;
  align-items: center;

  .percentage-title-text {
    color: ${colors.primaryDark};
  }

  .close-button {
    position: absolute;
    width: 24px;
    height: 24px;
    top: 7px;
    ${(props) => (props.theme.dir === "rtl" ? "left: 15px;" : "right: 15px;")}
    z-index: 1;
  }

  .title {
    max-width: 743px;
    font-weight: 700;
    font-size: 32px;
    line-height: 110%;
    letter-spacing: -0.02em;
    color: ${colors.white};
    margin-bottom: 24px;
  }

  .subtitle {
    max-width: 531px;
    font-weight: 500;
    font-size: 14px;
    line-height: 160%;
    color: ${colors.white};
  }

  .percentage-wrapper {
    position: absolute;
    opacity: 0.3;
    right: 31px;
    height: 133px;
    font-size: 170px;
    font-weight: 700;
    line-height: 133px;
    background-color: red;

    /* Create the gradient. */
    background-image: ${colors.linearGradientOne};

    /* Set the background size and repeat properties. */
    background-size: 100%;
    background-repeat: repeat;

    /* Use the text as a mask for the background. */
    /* This will show the gradient as a text color rather than element bg. */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
  }

  @media ${device.tablet}, ${device.mobile} {
    padding: 30px 16px 16px 16px;
    .title {
      font-size: 25px;
      letter-spacing: -0.02em;
      margin-bottom: 7px;
    }

    .percentage-wrapper {
      ${(props) => (props.theme.dir === "rtl" ? "left: 16px;" : "right: 16px;")}
      font-size: 133px;
      opacity: 0.15;
    }
  }
`;
