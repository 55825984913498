import { colors } from "&assets/constants/colors";
import { device } from "&assets/constants/responsiveness";
import styled from "styled-components";

export const PageHeaderWrapper = styled.div`
  box-sizing: border-box;
  background: ${colors.primaryLightGradient};
  display: flex;
  position: relative;
  height: 380px;
  overflow: hidden;
  border-bottom: 4px solid #9bcfec;

  .title-subtitle-wrapper {
    padding: 64px 288px 0 288px;
    z-index: 2;
    position: relative;
  }

  .header-lines {
    position: absolute;
    z-index: 0;
    top: 0;
    ${(props) => (props.theme.dir === "rtl" ? "right: 0;" : "left: 0;")};
    ${(props) => (props.theme.dir === "rtl" ? "transform: scaleX(-1);" : "")};
  }
  .back-button {
    background: transparent;
    position: absolute;
    top: 18px;
    z-index: 5;
    padding: 0;
    border: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    cursor: pointer;
    .arrow {
      ${(props) =>
        props.theme.dir === "rtl"
          ? "margin-left: 14px;"
          : "margin-right: 14px;"};
      ${(props) =>
        props.theme.dir === "rtl" ? "" : "transform: rotate(180deg);"};
    }

    .button-text {
      font-weight: 800;
      font-size: 18px;
      line-height: 160%;
      text-align: center;
      color: ${colors.white};
    }
  }

  @media ${device.tablet} {
    .back-button {
      top: 14px;
      ${(props) =>
        props.theme.dir === "rtl" ? "right: 18px;" : "left: 18px;"};
    }
    .title-subtitle-wrapper {
      padding: 64px 102px 0 102px;
    }
  }

  @media ${device.mobile} {
    .back-button {
      top: 8px;
      ${(props) =>
        props.theme.dir === "rtl" ? "right: 18px;" : "left: 18px;"};
    }
    height: 355px;
    .title-subtitle-wrapper {
      padding: 40px 38px 0 38px;
    }

    .header-lines {
      width: 265px;
      height: 254px;
    }
  }
`;
