import RadioButton from "&components/buttons/inputButton/inputButton.component";
import React from "react";
import { RadioGroupWrapper } from "./radioGroup.styled";
import { LabelWrapper } from "&components/inputs/input/input.styled";
import {translationSelector} from "&utils/translationSelector";

interface Props {
  id?: string;
  data: any[];
  value?: string | number;
  className?: string;
  onChange: (value: string) => void;
  showAll?: boolean;
  error?: any;
  label?: string;
  disabled?: boolean;
}

const RadioGroup = (props: Props) => {
  const {
    id,
    data,
    value,
    onChange,
    showAll,
    error,
    label,
    className,
    disabled = false,
  } = props;
  return (
    <div className={className}>
      {label && (
        <LabelWrapper title={error} valid={error}>
          {label}
        </LabelWrapper>
      )}
      <RadioGroupWrapper id={id} showAll={showAll}>
        {data?.map((item, index) => {
          return (
            <RadioButton
              id={item.id}
              key={index}
              showAll={showAll}
              title={translationSelector(item,'title')}
              selected={item.id === value}
              disabled={disabled}
              handleClick={() => onChange(item.id)}
            />
          );
        })}
      </RadioGroupWrapper>
    </div>
  );
};

export default RadioGroup;
