import React from "react";
import { CompleteProfilePromptWrapper } from "./completeProfilePrompt.styled";
import CloseIconComponent from "&components/closeIcon/closeIcon.component";
import { XIcon } from "&assets/constants/icons";

interface Props {
  title?: string;
  subtitle?: string;
  className?: string;
  percentage?:number;
  onClose?: () => void;
}

const CompleteProfilePrompt = (props: Props) => {
  const { title, subtitle, className,percentage, onClose = () => {} } = props;

  const titleFirstPart = title?.split(/[0-9]/)[0];
  const titleSecondPart = title?.split("%")[1];

  return (
    <CompleteProfilePromptWrapper className={className}>
      <div>
        <div className="title">
          <span>{titleFirstPart}</span>
          {/* <span className="percentage-title-text">{` ${percentage} `}</span> */}
          <span>{titleSecondPart}</span>
        </div>
        <div className="subtitle">{subtitle}</div>
      </div>
      <CloseIconComponent
        onClick={onClose}
        icon={<XIcon size={10} />}
        className="close-button"
      />
      <div className="percentage-wrapper">{percentage}%</div>
    </CompleteProfilePromptWrapper>
  );
};

export default CompleteProfilePrompt;
