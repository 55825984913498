import React, { useEffect, useState } from "react";
import { isEmpty } from "lodash";

import { EditModalFooter, ProfileEditModelWrapper } from "./profileEditModal.styled";
import { EditSelection, Sections } from "&features/dashboard/screens/profile/profile.type";
import ProfileAddAboutMe from "&components/profile/edit/profileAddAboutMe/profileAddAboutMe.component";
import SubmitButton from "&components/buttons/submitButton/submitButton.component";
import ProfileAddWorkExperience from "&components/profile/edit/profileAddWorkExperience/profileAddWorkExperience.component";
import ProfileAddEducation from "&components/profile/edit/profileAddEducation/profileAddEducation.component";
import ProfileAddAdditionalDetails from "&components/profile/edit/profileAddAdditionalDetails/profileAddAdditionalDetails.component";
import ProfileAddSocialLinks from "&components/profile/edit/profileAddSocialLinks/profileAddSocialLinks.component";
import { useDispatch, useSelector } from "react-redux";
import { UpdateProfileBody } from "&features/login/login.type";
import { loginActions } from "&features/login/login.slice";
import { RootState } from "&store/store";
import ProfileAddPersonalDetails from "&components/profile/edit/profileAddPersonalDetails/profileAddPersonalDetails.component";
import ProfileAddInterests from "&components/profile/edit/profileAddInterests/profileAddInterests.component";
import ProfileAddSkills from "&components/profile/edit/profileAddSkills/profileAddSkills.component";
import CustomModal from "&components/custom-modal/custom-modal.component";
import ProfileAddProfilePicture from "&components/prodile/edit/profileAddProfilePicture/profileAddProfilePicture.component";
import ProfileAddCertification from "&components/profile/edit/profileAddCertification/profileAddCertification.component";
import ProfileAddEligibilityForm from "&components/profile/edit/profileAddEligibilityForm/profileAddEligibilityForm.component";
import { profileActions } from "&features/dashboard/screens/profile/profile.slice";
import { useTranslation } from "react-i18next";
import ProfileFullName from "../profileFullName/profileFullName.component";
import ProfileNationalityForm from "../profileNationalityForm/profileNationalityForm.component";

interface Props {
  selectedSection: EditSelection;
  handleClose: () => void;
  open: boolean;
}

const ProfileEditModal = (props: Props) => {
  const { selectedSection, handleClose, open } = props;
  const [submit, setSubmit] = useState<number>(0);
  const [deleteButton, setDeleteButton] = useState<number>(0);
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const { t } = useTranslation(["profile"]);

  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const dispatch = useDispatch();
  const loadingUpdateProfile = useSelector((state: RootState) => state.login.loadingUpdateProfile);

  const settings = useSelector((state: RootState) => state.global.settings);

  const updateProfileStatus = useSelector((state: RootState) => state.login.updateProfileStatus);
  const updateProfileNationalityStatus = useSelector((state: RootState) => state.login.updateProfileNationalityStatus);
  const loadingProfileNationality = useSelector((state: RootState) => state.login.loadingUpdateProfileNationality);

  const updateProfileError = useSelector((state: RootState) => state.login.updateProfileError);

  const loadingEligibility = useSelector((state: RootState) => state.profile.loadingEligibility);
  const eligibilityStatus = useSelector((state: RootState) => state.profile.eligibilityStatus);

  const eligibilityForm = useSelector((state: RootState) => state.profile.eligibilityForm);
  const setLogin = (body: any) => dispatch(loginActions.setLogin(body));
  const setProfile = (body: any) => dispatch(profileActions.setProfile(body));

  const handleClosing = () => {
    dispatch(loginActions.setLogin({ updateProfileError: "" }));
    handleClose();
  };

  useEffect(() => {
    if (
      (selectedSection?.section === "profilePicture" || selectedSection?.section === "fullname") &&
      updateProfileStatus === "UPDATE_PROFILE_SUCCESSFUL"
    ) {
      handleClosing();
      dispatch(loginActions.setLogin({ updateProfileError: "" }));
      dispatch(loginActions.setLogin({ updateProfileStatus: "" }));
    }

    if (updateProfileStatus === "ERROR_UPDATE_PROFILE") {
      dispatch(loginActions.setLogin({ updateProfileStatus: "" }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateProfileStatus]);

  useEffect(() => {
    if (
      updateProfileNationalityStatus === "UPDATE_PROFILE_NATIONALITY_SUCCESSFUL" ||
      updateProfileNationalityStatus === "ERROR_UPDATE_PROFILE_NATIONALITY"
    ) {
      handleClosing();
      dispatch(loginActions.setLogin({ updateProfileNationalityStatus: "" }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateProfileNationalityStatus]);

  useEffect(() => {
    if (eligibilityStatus === "UPDATE_ELIGIBILITY_SUCCESS" || eligibilityStatus === "ELIGIBILITY_POST_SUCCESS") {
      handleClosing();
      setLogin({
        eligibilityForm: eligibilityForm,
      });
      setProfile({
        eligibilityStatus: "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eligibilityStatus]);

  const updateProfile = (body: UpdateProfileBody) => {
    dispatch(loginActions.updateProfile(body));
  };
  useEffect(() => {
    if (!open) {
      setSubmit(0);
      setDeleteButton(0);
    }
  }, [open]);

  const handleSave = () => {
    setSubmit(submit + 1);
  };

  const handleDelete = () => {
    setShowConfirmation(false);
    setDeleteButton(deleteButton + 1);
  };

  const sectionsSelector: Sections = {
    aboutMe: {
      title: t("ABOUT_ME"),
      content: (
        <ProfileAddAboutMe
          updateProfile={updateProfile}
          initialValues={selectedSection?.initialValues}
          submit={submit}
        />
      ),
    },
    experience: {
      title: t("EXPERIENCE"),
      content: (
        <ProfileAddWorkExperience
          submit={submit}
          deleteButton={deleteButton}
          updateProfile={updateProfile}
          initialValues={selectedSection?.initialValues}
          index={selectedSection?.index}
        />
      ),
    },
    education: {
      title: t("EDUCATION"),
      content: (
        <ProfileAddEducation
          submit={submit}
          deleteButton={deleteButton}
          initialValues={selectedSection?.initialValues}
          updateProfile={updateProfile}
          index={selectedSection?.index}
        />
      ),
    },
    skills: {
      title: t("SKILLS"),
      content: <ProfileAddSkills submit={submit} updateProfile={updateProfile} setDisableEdit={setDisableButton} />,
    },
    certifications: {
      title: t("CERTIFICATIONS"),
      content: (
        <ProfileAddCertification
          submit={submit}
          deleteButton={deleteButton}
          initialValues={selectedSection?.initialValues}
          updateProfile={updateProfile}
          index={selectedSection?.index}
        />
      ),
    },
    interest: {
      title: t("INTERESTS_AND_HOBBIES"),
      content: (
        <ProfileAddInterests
          submit={submit}
          updateProfile={updateProfile}
          initialValues={selectedSection?.initialValues}
        />
      ),
    },
    personalDetails: {
      title: t("PERSONAL_DETAILS"),
      content: (
        <ProfileAddPersonalDetails
          submit={submit}
          updateProfile={updateProfile}
          initialValues={selectedSection?.initialValues}
        />
      ),
    },
    additionalDetails: {
      title: t("ADDITIONAL_DETAILS"),
      content: (
        <ProfileAddAdditionalDetails
          submit={submit}
          settings={settings}
          updateProfile={updateProfile}
          initialValues={selectedSection?.initialValues}
        />
      ),
    },
    socialLinks: {
      title: t("SOCIAL_LINKS"),
      content: (
        <ProfileAddSocialLinks
          submit={submit}
          updateProfile={updateProfile}
          initialValues={selectedSection?.initialValues}
        />
      ),
    },
    profilePicture: {
      title: t("PROFILE_PICTURE"),
      content: (
        <ProfileAddProfilePicture
          submit={submit}
          updateProfile={updateProfile}
          deleteButton={deleteButton}
          initialValues={selectedSection?.initialValues}
        />
      ),
    },
    eligibilityForm: {
      title: t("ELIGIBILITY_FORM"),
      content: <ProfileAddEligibilityForm submit={submit} initialValues={selectedSection?.initialValues} />,
    },
    fullname: {
      title: t("FULL_NAME"),
      content: (
        <ProfileFullName submit={submit} updateProfile={updateProfile} initialValues={selectedSection?.initialValues} />
      ),
    },
    nationality: {
      title: t("PERSONAL_INFORMATION"),
      content: <ProfileNationalityForm submit={submit} initialValues={selectedSection?.initialValues} />,
    },
  };

  const isAwaiting =
    selectedSection.section === "eligibilityForm" &&
    !isEmpty(selectedSection.initialValues) &&
    !selectedSection.initialValues?.approved &&
    selectedSection.initialValues.rejected === 1;

  const isApproved = selectedSection.section === "eligibilityForm" && selectedSection.initialValues?.approved;

  const loadingButton = loadingUpdateProfile || loadingEligibility || loadingProfileNationality;
  const disabledButton = disableButton || isAwaiting || isApproved;
  const hideSave = isApproved;

  const RenderFooter = (props: any) => {
    const deleteText =
      selectedSection.section === "education"
        ? t("EDUCATION")
        : selectedSection.section === "certifications"
        ? t("CERTIFICATION")
        : selectedSection.section === "profilePicture"
        ? t("PICTURE")
        : t("EXPERIENCE");
    const showDelete =
      !!(selectedSection?.index || selectedSection?.index === 0) ||
      (selectedSection.section === "profilePicture" && selectedSection.initialValues);

    return (
      <EditModalFooter showDelete={showDelete}>
        {updateProfileError && <div className="error-message">{updateProfileError}</div>}
        {showDelete && (
          <SubmitButton
            title={`${t("DELETE")} ${deleteText}`}
            id={"delete-update-profile-button"}
            disable={loadingUpdateProfile}
            handleClick={() => setShowConfirmation(true)}
            className="delete-button"
          />
        )}
        {!hideSave && (
          <SubmitButton
            title={t("SAVE")}
            loading={loadingButton}
            id={"save-update-profile-button"}
            disable={disabledButton}
            handleClick={handleSave}
            className="submit-button"
          />
        )}
      </EditModalFooter>
    );
  };

  const ConfirmationFooter = (props: any) => {
    return (
      <EditModalFooter>
        <SubmitButton
          title={t("DELETE")}
          loading={loadingUpdateProfile}
          id={"confirm-delete-button"}
          disable={disableButton}
          handleClick={handleDelete}
          className="delete-confirmation"
        />
      </EditModalFooter>
    );
  };

  return (
    <ProfileEditModelWrapper
      open={open}
      title={selectedSection?.section && sectionsSelector[selectedSection?.section]?.title}
      footer={<RenderFooter />}
      handleClose={handleClosing}
    >
      {selectedSection?.section && sectionsSelector[selectedSection?.section]?.content}
      <CustomModal
        footer={<ConfirmationFooter />}
        handleClose={() => setShowConfirmation(false)}
        open={showConfirmation}
      >
        <div className="confirmation-modal-text">{t("ARE_YOU_SURE_YOU_WANT_TO_DELETE")}</div>
      </CustomModal>
    </ProfileEditModelWrapper>
  );
};

export default ProfileEditModal;
