import { sourceLogos } from "&assets/constants/statics";
import ImageDisplay from "&components/imageDisplay/imageDisplay.component";
import React from "react";
import { SourceLogoWrapper } from "./sourceLogo.styled";

interface Props {
  source?: string;
  className?: string;
}

type Sources = "Linkedin" | "Microsoft" | "nammiskills" | "AWS" | "Simplilearn";

const SourceLogo = (props: Props) => {
  const { source = "", className = "" } = props;
  return (
    <SourceLogoWrapper className={className}>
      {sourceLogos[source] && <ImageDisplay isBackgroundImage={true} className="logo" url={sourceLogos[source]} />}
    </SourceLogoWrapper>
  );
};

export default SourceLogo;
