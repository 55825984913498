import styled from "styled-components";
import { colors } from "&assets/constants/colors";
import { device } from "&assets/constants/responsiveness";

export const BenifitCardWrapper = styled.div`
  display: flex;

  position: relative;
  .background-wrapper {
    position: absolute;
    z-index: -1;
    top: -32px;
  }

  .title-description-wrapper {
    display: flex;
    max-width: 205px;
    flex-direction: column;
  }

  .icon-wrapper {
    box-sizing: border-box;
    display: flex;
    ${(props) => (props.theme.dir === "rtl" ? "margin-left: 16px;" : "margin-right: 16px;")}
    margin-bottom: 9px;
    min-width: 95px;
    max-width: 95px;
    min-height: 95px;
    max-height: 95px;
    justify-content: center;
    align-items: center;
    border-radius: 95px;
    border: 1px solid transparent;
    background: ${colors.linearGradientOne} padding-box, ${colors.linearGradientThree} border-box;
  }

  .card-title {
    font-weight: 700;
    font-size: 24px;
    width: 188px;
    height: 58px;
    line-height: 120%;
    font-feature-settings: "cv11" on;
    color: ${colors.dark};
    margin-bottom: 12px;
  }

  .card-description {
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    display: flex;
    align-items: center;
    color: ${colors.dark};
  }

  @media ${device.tablet} {
    flex-direction: column;

    .card-title {
      font-size: 20px;
    }

    .background-wrapper {
      display: none;
    }
  }

  @media ${device.mobile} {
    flex-direction: column;

    .card-title {
      font-size: 18px;
    }

    .background-wrapper {
      display: none;
    }
  }
`;
