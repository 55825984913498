import { LearningPathwayType } from "&features/listingPage/listingPage.type";

export const hasExam: (lp?: LearningPathwayType) => boolean = (
  learningPath?: LearningPathwayType
) => {
  let examFound = false;
  learningPath?.learningActivities?.forEach((activity) => {
    if ("exams" in activity) {
      examFound = true;
    }
  });
  return examFound;
};

export const getLastExamActivity: (lp?: any) => any | undefined = (
  learningActivities?: any
) => {
  const lastActivity =
    learningActivities && learningActivities?.length > 0
      ? learningActivities[learningActivities?.length - 1]
      : undefined;

  if (lastActivity && "exams" in lastActivity) {
    return lastActivity;
  } else {
    return undefined;
  }
};
