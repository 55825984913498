import styled from "styled-components";
import { device } from "&assets/constants/responsiveness";

interface Props {
  showAll?: boolean;
}
export const RadioGroupWrapper = styled.div<Props>`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  @media ${device.mobile} {
    display: ${(props) => (props.showAll ? "flex" : "grid")};
    ${(props) => (props.showAll ? "flex-direction: column;" : "")}
    ${(props) => (props.showAll ? "" : "grid-gap: 16px;")}
    ${(props) => (props.showAll ? "" : "flex-wrap: nowrap;")}
    ${(props) => (props.showAll ? "" : "grid-template-columns: 47% 47%;")}
  }
`;
