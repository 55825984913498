import { mainTheme } from "&assets/themes/themes";

export const colors = {
  primary: mainTheme.colors.primary,
  primaryDark: mainTheme.colors.primaryDark,
  primaryDark2: mainTheme.colors.primaryDark2,
  primaryLight: mainTheme.colors.primaryLight,
  secondary: mainTheme.colors.secondary,
  secondaryDark: mainTheme.colors.secondaryDark,
  ternary: mainTheme.colors.tirtiary,
  linearGradientOne: `linear-gradient(90deg, ${mainTheme.colors.primaryDark} 54.86%, ${mainTheme.colors.secondary} 100%)`,
  linearGradientTwo: `linear-gradient(90deg, ${mainTheme.colors.secondary} 54.86%, ${mainTheme.colors.fourth} 100%)`,
  linearGradientThree: `linear-gradient(135deg, ${mainTheme.colors.fourth} 30%, ${mainTheme.colors.secondary} 100%)`,
  linearGradientFour: `linear-gradient(90deg, ${mainTheme.colors.tirtiary} 54.86%, ${mainTheme.colors.primary} 100%)`,
  linearGradientFive: `linear-gradient(90deg, ${mainTheme.colors.secondary} 54.86%, ${mainTheme.colors.primary} 100%)`,
  linearGradientSixth: `linear-gradient(90deg, ${mainTheme.colors.primaryDark} 54.86%, ${mainTheme.colors.primary} 100%)`,
  linearGradientSeventh: `linear-gradient(56.4deg, ${mainTheme.colors.primaryDark} 8.08%, ${mainTheme.colors.secondary} 87.11%)`,
  linearGradientEight: `linear-gradient(56.4deg, ${mainTheme.colors.primaryDark2} 8.08%, ${mainTheme.colors.secondary} 87.11%)`,

  linearGradientWhite: `linear-gradient(56.4deg, #fff 8.08%, #fff 87.11%)`,
  colorNeutralWhite: "#FFFFFF",
  colorNeutralLightGray: "#F0F5F7",
  colorNeutralDarkGray: "#B8C8D3",
  colorNeutralBlack: "#1F2631",
  colorSecondaryPurple: "#AB4DE8",
  colorSecondaryFushia: "#EF4DAD",
  colorSecondaryYellow: "#D0DD05",

  dark: "#2C383F",
  white: "#FFFFFF",
  green: "#2fb786",
  // textDark: ${colors.dark},
  errorColor: "#FE512F",
  primaryLightGradient: "linear-gradient(180deg, rgba(207, 232, 246, 0) 0%, #CFE8F6 100%)",
  primaryLighterGradient: "linear-gradient(180deg, #FFFFFF 0%, #E9FAFF 100%)",
  lighterDark: "#4D525B",
  borderLight: "#D6DDEB",
};

export const levelsBackground: any = [
  "linear-gradient(180deg, #CFE8F6 0%, #CFE8F6 100%);",
  "#389fdaa6;",
  `${mainTheme.colors.primary};`,
];

export const careerLevelsBackground: any = [
  `${mainTheme.colors.primaryDark};`,
  `${mainTheme.colors.primary};`,
  `${mainTheme.colors.primaryLight};`,
];
export const skillsBackground: any = ["#84c1e896;", "#389fdaeb;", `${mainTheme.colors.primary};`];
