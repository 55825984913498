import { colors } from "&assets/constants/colors";
import { device } from "&assets/constants/responsiveness";
import styled from "styled-components";

interface Props {
  opened?: boolean;
}

export const LearningHeaderDashboardWrapper = styled.div<Props>`
  box-sizing: border-box;
  background: ${colors.linearGradientOne};
  display: flex;
  position: relative;
  min-height: 328px;
  .progress {
    min-width: 109px;
    height: 29px;
    padding: 10px;
    background: ${colors.white};
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    .percent {
      color: ${colors.primary};
      font-weight: 700;
      font-size: 12px;
      line-height: 120%;
    }
  }

  .title-subtitle-wrapper {
    .title {
      color: #ffffff;
      ${(props) => (props.theme.dir === "rtl" ? "text-align: right;" : "text-align: left;")}
      font-size: 32px;
    }
    .sub-title {
      color: #ffffff;
      ${(props) => (props.theme.dir === "rtl" ? "text-align: right;" : "text-align: left;")}
      opacity: 1;
      margin-bottom: 16px;
      font-size: 14px;
    }
  }
  .header-lines {
    position: absolute;
    z-index: 0;
    top: 0;
    ${(props) => (props.theme.dir === "rtl" ? "right: 0;" : "left: 0;")}
  }

  .card-content {
    display: flex;
    align-items: center;
    .info {
      font-weight: 400;
      font-size: 14px;

      ${(props) => (props.theme.dir === "rtl" ? "margin-right: 10px;" : "margin-left: 10px;")}
      color: #ffffff;
      align-items: center;
      justify-content: center;
      display: flex;
      ${(props) => (props.theme.dir === "rtl" ? "margin-right: 0;" : "margin-left: 0;")}
      ${(props) => (props.theme.dir === "rtl" ? "margin-left: 20px;" : "margin-right: 20px;")}
      .text {
        ${(props) => (props.theme.dir === "rtl" ? "margin-right: 6px;" : "margin-left: 6px;")}
      }
    }
  }
  .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    ${(props) => (props.theme.dir === "rtl" ? "padding: 40px 142px 40px 116px;" : "padding: 40px 116px 40px 142px;")}
    box-sizing: border-box;

    .content {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      position: relative;
      .apply-voucher-button {
        background: ${colors.white};
        width: auto;
        margin-bottom: 20px;
        margin-top: 10px;
        height: 38px;
        border: 1px solid ${colors.primary};
        .MuiCircularProgress-circle {
          color: ${colors.primary};
        }
      }
      .apply-voucher-button-title {
        color: ${colors.primary};
        font-size: 14px;
      }
      .show-details {
        display: flex;
        align-items: center;
        .show-details-text {
          font-weight: 800;
          font-size: 14px;
          line-height: 160%;
          text-align: center;
          cursor: pointer;
          color: ${colors.white};
          width: 100px;
        }
        .plus-icon {
          width: 24px;
          height: 24px;
          ${(props) => (props.theme.dir === "rtl" ? "margin-right: 8px;" : "margin-left: 8px;")}
          background: ${(props) =>
            props.opened
              ? `${colors.linearGradientWhite}
      padding-box,
    ${colors.linearGradientOne} border-box`
              : `linear-gradient(56.4deg, #ffffff 8.08%, #ffffff 87.11%)
          padding-box,
        ${colors.linearGradientOne} border-box`};
        }
      }
      .learning-path-image {
        width: 453px;
        height: 248px;
        ${(props) => (props.theme.dir === "rtl" ? "margin-left: 47px;" : "margin-right: 47px;")}

        border-radius: 16px;
        .background-gradient {
          width: 453px;
        }
      }
    }
  }

  @media ${device.inBetween} {
    .title-subtitle-wrapper {
      .title {
        font-size: 28px;
      }
      .sub-title {
        font-size: 16px;
      }
    }
    .card-content {
      .info {
        font-size: 12px;
      }
    }
  }

  @media ${device.mobile}, ${device.tablet} {
    height: auto;
    .progress {
      margin-bottom: 10px;
    }
    .title-subtitle-wrapper {
      .title {
        margin: 0;
        display: none;
        margin-bottom: 24px;
      }
      .sub-title {
        margin-top: 24px;
      }
    }
    .header-lines {
      width: 265px;
      height: 254px;
    }
    .card-content {
      flex-wrap: wrap;
      .expand {
        width: 100%;
        justify-content: flex-start;
      }
    }

    .container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      max-width: 100%;
      padding: 16px;
      .content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        .title-mobile {
          font-weight: 700;
          font-size: 40px;
          line-height: 110%;
          letter-spacing: -0.02em;
          font-feature-settings: "cv11" on;
          color: #ffffff;
          margin: 0;
        }
        .learning-path-image {
          width: 100%;
          height: 248px;
          ${(props) => (props.theme.dir === "rtl" ? "margin-left: 47px;" : "margin-right: 47px;")}
          border-radius: 16px;
          .background-gradient {
            width: 100%;
          }
        }
      }
    }
  }
`;
