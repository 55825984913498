import { API } from "aws-amplify";
import { RES_AUTH_REPORTS, RES_COURSES } from "&store/resources";
import { SendManualReport } from "./courseDetails.type";
import { definedObjectBuilder } from "&utils/definedObjectBuilder";

const getCourseByIdApi = async (id: string, isLoggedIn: boolean) => {
  let URL = isLoggedIn ? `/auth${RES_COURSES}/${id}` : `${RES_COURSES}/${id}`;
  return API.get(process.env.REACT_APP_NAME || "", URL, {});
};

const getRelatedCourseByIdApi = async (id: string, isLoggedIn: boolean) => {
  let URL = isLoggedIn
    ? `/auth${RES_COURSES}/${id}/related`
    : `${RES_COURSES}/${id}/related`;
  return API.get(process.env.REACT_APP_NAME || "", URL, {});
};

const enrollCourseApi = async (id: string) => {
  let URL = `/auth/user${RES_COURSES}/${id}/enroll`;
  return API.post(process.env.REACT_APP_NAME || "", URL, {});
};

const sendManualReportApi = async (body: SendManualReport) => {
  let URL = `${RES_AUTH_REPORTS}/manual`;
  return API.post(process.env.REACT_APP_NAME || "", URL, { body });
};

const checkInclusionApi = async (id: string) => {
  let URL = `/auth/user${RES_COURSES}/${id}/check-inclusion`;
  return API.get(process.env.REACT_APP_NAME || "", URL, {});
};

const launchActivityApi = async (type: string, id: string) => {
  let URL = `/auth/${type}/${id}/launch`;
  return API.post(process.env.REACT_APP_NAME || "", URL, {});
};

const claimSponsorshipApi = async (
  id: string,
  type: string,
  sponsorshipType: string,
  learningPathId?: any
) => {
  let URL =
    learningPathId && type === "exams"
      ? `/auth/${type}/${id}/learning-paths/${learningPathId}/claim-${sponsorshipType}`
      : `/auth/${type}/${id}/claim-${sponsorshipType}`;
  return API.post(process.env.REACT_APP_NAME || "", URL, {});
};

const getActivityJobsApi = async (
  id: string,
  type: string,
  body: any,
  isLoggedIn: boolean
) => {
  let URL = isLoggedIn ? `/auth/${type}/${id}/jobs` : `/${type}/${id}/jobs`;
  const myInit: any = {
    queryStringParameters: definedObjectBuilder(body),
  };
  return API.get(process.env.REACT_APP_NAME || "", URL, myInit);
};

const getRelatedLearningPathsByCourseIdApi = async (id: string) => {
  let URL = `${RES_COURSES}/${id}/learning-paths`;
  return API.get(process.env.REACT_APP_NAME || "", URL, {});
};

export {
  getCourseByIdApi,
  enrollCourseApi,
  sendManualReportApi,
  checkInclusionApi,
  claimSponsorshipApi,
  getActivityJobsApi,
  getRelatedCourseByIdApi,
  getRelatedLearningPathsByCourseIdApi,
  launchActivityApi,
};
