import React from "react";

import { ImageBackgroundWrapper } from "./imageBackground.styled";
import Container from "&components/container/container.component";
import ImageDisplay from "&components/imageDisplay/imageDisplay.component";
import SectionTitleButton from "&components/sectionTitleButton/sectionTitleButton.component";
import { images } from "&assets/constants/images-urls";

interface Props {
  title: string;
  allButtonText: string;
  children: React.ReactNode;
  onAllButtonClick: () => void;
}

const ImageBackground = (props: Props) => {
  const { title, allButtonText, children, onAllButtonClick = () => {} } = props;

  return (
    <ImageBackgroundWrapper>
      <Container className="container">
        <div className="gradient-layer" />
        <ImageDisplay
          isBackgroundImage={true}
          isFromS3={true}
          url={images.listingPageBackground}
          className={"background-image"}
        />
        <SectionTitleButton
          isWhite={true}
          wrapperClassName="title-button-wrapper"
          buttonText={allButtonText}
          onButtonClick={onAllButtonClick}
          title={title}
        />
        {children}
      </Container>
    </ImageBackgroundWrapper>
  );
};

export default ImageBackground;
