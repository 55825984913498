import React from "react";
import { useMediaQuery } from "react-responsive";

import { ArrowForwardIcon, HeaderLines } from "&assets/constants/icons";
import { size } from "&assets/constants/responsiveness";
import Container from "&components/container/container.component";
import PageTitleSubtitle from "&components/pageTitleSubtitle/pageTitleSubtitle.component";
import { PageHeaderWrapper } from "./pageHeader.styled";
import { colors } from "&assets/constants/colors";
import { useTranslation } from "react-i18next";

interface Props {
  hasBackButton?: boolean;
  onBackClicked?: () => void;
  title?: string;
  subTitle?: any;
  className?: string;
  titleClassName?: string;
  titleSubtitleWrapper?: string;
}

const PageHeader = (props: Props) => {
  const {
    title,
    subTitle,
    className,
    titleClassName,
    titleSubtitleWrapper,
    hasBackButton,
    onBackClicked,
  } = props;
  const isMobile = useMediaQuery({ maxWidth: size.mobile });

  const { t } = useTranslation(["blogs"]);
  return (
    <PageHeaderWrapper className={className}>
      <HeaderLines
        className={"header-lines"}
        width={isMobile ? 265 : 396}
        height={isMobile ? 254 : 380}
        opacity={1}
      />
      <Container className="container">
        {hasBackButton && (
          <button onClick={onBackClicked} className="back-button">
            <div className="arrow">{ArrowForwardIcon(colors.white)}</div>
            <div className="button-text">{t("BACK")}</div>
          </button>
        )}
        <PageTitleSubtitle
          className={`title-subtitle-wrapper ${titleSubtitleWrapper}`}
          title={title}
          subTitle={subTitle}
          titleClassName={titleClassName}
        />
      </Container>
    </PageHeaderWrapper>
  );
};

export default PageHeader;
