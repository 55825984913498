import { colors } from "&assets/constants/colors";
import { device } from "&assets/constants/responsiveness";
import styled from "styled-components";

export const BlogDetailsWrapper = styled.div`
  .header {
    background: ${colors.linearGradientSixth};
    overflow: initial;
    height: 380px;
    border-bottom: 0;
  }
  .loader-wrapper {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .header-title {
    color: ${colors.white};
    opacity: 1;
  }
  .information {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 20px;
    z-index: 2;
    .created-at {
      display: flex;
      align-items: center;
    }
    .created-at-text {
      font-weight: 400;
      font-size: 14px;
      line-height: 200%;
      color: ${colors.white} !important;
      ${(props) => (props.theme.dir === "rtl" ? "margin-right: 6px;" : "margin-left: 6px;")}
      opacity: 1;
    }
  }
  .blog-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .card-blog {
      ${(props) => (props.theme.dir === "rtl" ? "margin-left: 16px;" : "margin-right: 16px;")}
    }
    .blog-description {
      padding: 0 285px 50px 285px;
    }
    .blog-image-back {
      width: 1090px;
      height: 520px;
      border-radius: 16px;
      object-fit: contain;
      margin: auto;
      margin-top: -70px;
      margin-bottom: 0px;
      z-index: 1;
    }
  }
  .benefits-container {
    padding: 48px 65px;
    .benefits-title {
      font-weight: 700;
      font-size: 48px;
      line-height: 110%;
      letter-spacing: -0.02em;
      color: ${colors.primaryDark};
    }
  }

  @media ${device.tablet} {
    .blog-content {
      box-sizing: border-box;
      padding: 0 30px;
      .blog-description {
        padding: 0;
      }
      .blog-image-back {
        width: 100%;
        height: 500px;
        margin-top: -150px;
      }
    }
    .benefits-container {
      padding: 20px 0;
      .benefits-title {
        font-size: 28px;
      }
    }
  }

  @media ${device.mobile} {
    .blog-content {
      box-sizing: border-box;
      padding: 0 16px;
      .blog-description {
        padding: 0px;
      }
      .blog-image-back {
        width: 100%;
        height: 500px;
        margin-top: -150px;
      }
    }
    .benefits-container {
      padding: 20px 0px;
      .benefits-title {
        font-size: 28px;
      }
    }
  }
`;
