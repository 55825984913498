import React from "react";
import { ProfileCheckboxInputWrapper } from "./profileCheckboxInput.styled";
import Checkbox from "&components/checkbox/checkbox.component";

interface Props {
  id?: string;
  value?: boolean;
  className?: string;
  onClick?: () => void;
  label?: string;
}

const ProfileCheckboxInput = (props: Props) => {
  const { id, value, onClick, label, className } = props;
  return (
    <ProfileCheckboxInputWrapper>
      <Checkbox
        id={id}
        selected={value}
        wrapperClassName={className}
        handleClick={() => onClick && onClick()}
        title={<div className="checkbox-text">{label}</div>}
      />
    </ProfileCheckboxInputWrapper>
  );
};

export default ProfileCheckboxInput;
