import ReactGA4 from "react-ga4";

const { REACT_APP_GOOGLE_ANALYTICS_MEASURE_ID } = process.env;

const InitializeGoogleAnalytics = () => {
  // Initialize GA4 - Add your measurement ID
  REACT_APP_GOOGLE_ANALYTICS_MEASURE_ID && ReactGA4.initialize(REACT_APP_GOOGLE_ANALYTICS_MEASURE_ID);
};
type AnalyticsCategories = "Assessment" | "Profile Creation" | "Home Page Events" | "Build Profile" | "Thank You";
type AnalyticsActions =
  | "assessment_started"
  | "assessment_completed"
  | "signed_up_after_assessment"
  | "step_1_completed"
  | "step_2_completed"
  | "eligibility_form_submitted"
  | "view_career_details_clicked"
  | "start_assessment_clicked"
  | "career_video_watched"
  | "thank_you";

const TrackGoogleAnalyticsEvent = (event: {
  category: AnalyticsCategories;
  action: AnalyticsActions;
  label?: string;
  value?: number;
  nonInteraction?: boolean;
}) => {
  const { category, action, label, nonInteraction, value } = event;
  // Send GA4 Event
  ReactGA4.event({
    category: category,
    action: action,
    label: label,
    value: value,
    nonInteraction: nonInteraction,
  });
};

const SetGoogleAnalyticsProperties = (property: { name: string; value: any }) => {
  const { name, value } = property;
  ReactGA4.set({ [name]: value });
};

export default InitializeGoogleAnalytics;
export { InitializeGoogleAnalytics, TrackGoogleAnalyticsEvent, SetGoogleAnalyticsProperties };
