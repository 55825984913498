import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";

import { size } from "&assets/constants/responsiveness";
import Container from "&components/container/container.component";
import PageTitleSubtitle from "&components/pageTitleSubtitle/pageTitleSubtitle.component";
import { LearningHeaderDashboardWrapper } from "./learningHeaderDashboard.styled";
import SourceLogo from "&components/sourceLogo/sourceLogo.component";
import LayeredImage from "&components/layeredImage/layeredImage.component";
import CloseIconButton from "&components/closeIcon/closeIcon.component";
import PlusSmallIcon from "&assets/images/plusSmall.svg";
import MinusSmallIcon from "&assets/images/minusSmall.svg";
import MessageActionModal from "&components/modals/messageActionModal/messageActionModal.component";
import { ERROR_TEXTS } from "&assets/constants/statics";
import { useNavigate } from "react-router-dom";
import IsSponsoredMessage from "&components/messages/isSponsoredMessage/isSponsoredMessage.component";
import { useTranslation } from "react-i18next";
import { translationSelector } from "&utils/translationSelector";
import { LearningPathItem } from "&features/listingPage/listingPage.type";
import {
  assessmentCaseSelector,
  needAssessmentMessageSelector,
} from "&utils/learningPaths/learningPathsSelectors";

interface Informatics {
  icon: any;
  text: string;
}

interface Props {
  id?: number;
  title?: string;
  description?: string;
  learningPathItem: LearningPathItem;
  image?: any;
  providerName?: string;
  level?: string;
  informatics?: Informatics[];
  handleShowDetailsClick?: Function;
  open?: boolean;
  progress?: number;
  sponsorshipType?: string;
  isSponsored?: boolean;
}

const LearningHeaderDashboard = (props: Props) => {
  const {
    // id,
    title,
    description,
    image,
    providerName,
    level,
    informatics,
    handleShowDetailsClick,
    progress,
    isSponsored,
    // sponsorshipType,
    open,
    learningPathItem,
  } = props;

  const { t } = useTranslation(["dashboard"]);

  const [opened, setOpened] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: size.mobile });
  const isTablet = useMediaQuery({
    minWidth: size.mobile,
    maxWidth: size.tablet,
  });
  // const dispatch = useDispatch();
  // const user = useSelector((state: RootState) => state.login.user);
  const [showFinishEligibility, setShowFinishEligibility] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    open && setOpened(open);
  }, [open]);

  // const loadingVoucher = useSelector(
  //   (state: RootState) => state.courseDetails.loadingVoucher
  // );
  // const completedEligibility =
  //   user?.eligibilityForm && user.eligibilityForm.approved;

  // const handleApplyClick = (event: any) => {
  //   event?.stopPropagation();
  //   if (
  //     !user?.eligibilityForm ||
  //     (user?.eligibilityForm && !user?.eligibilityForm?.approved)
  //   ) {
  //     setShowFinishEligibility(true);
  //   } else {
  //     dispatch(
  //       courseDetailsActions.claimSponsorship({
  //         id: id?.toString() || "",
  //         type: "learning-paths",
  //         sponsorshipType: sponsorshipType || "",
  //       })
  //     );
  //   }
  // };

  const assessmentCase = assessmentCaseSelector(learningPathItem);
  const learningPathMessage = needAssessmentMessageSelector(
    t,
    learningPathItem
  );
  const hideShowDetails =
    assessmentCase === "case1" ||
    assessmentCase === "case2" ||
    assessmentCase === "case4";

  return (
    <LearningHeaderDashboardWrapper opened={opened}>
      <Container className="container">
        <div className="content">
          {(isMobile || isTablet) && <p className="title-mobile">{title}</p>}
          <LayeredImage
            src={image}
            level={level}
            wrapperClassName="learning-path-image"
          />
          <div>
            <PageTitleSubtitle
              className={"title-subtitle-wrapper"}
              title={title}
              subTitle={description}
            />
            <div className="card-content">
              {providerName && (
                <div className="card-content info expand">
                  <SourceLogo
                    source={providerName ? providerName.toLowerCase() : ""}
                  />
                  <p className="title">{providerName}</p>
                </div>
              )}
              {informatics?.map((item: Informatics, index: number) => {
                return (
                  <p className="info" key={index}>
                    {item.icon}
                    <span className="text">
                      {translationSelector(item, "text")}
                    </span>
                  </p>
                );
              })}
              <div className="progress">
                <p className="percent">
                  {progress}% {t("COMPLETED")}
                </p>
              </div>
            </div>
            {/* {sponsorshipType && completedEligibility && (
              <SubmitButton
                title={
                  sponsorshipType === "voucher"
                    ? "Apply For Voucher"
                    : "Apply For Sponsorship"
                }
                id={`apply-voucher-button`}
                handleClick={(event: any) => {
                  handleApplyClick(event);
                }}
                loading={loadingVoucher}
                className="apply-voucher-button"
                titleClassName="apply-voucher-button-title"
              />
            )} */}
            {hideShowDetails ? null : (
              <div className="show-details">
                <span
                  className="show-details-text"
                  onClick={() => {
                    handleShowDetailsClick && handleShowDetailsClick(!opened);
                    setOpened(!opened);
                  }}
                >
                  {opened ? t("HIDE_DETAILS") : t("SHOW_DETAILS")}
                </span>
                <CloseIconButton
                  className="plus-icon"
                  onClick={() => {
                    handleShowDetailsClick && handleShowDetailsClick(!opened);
                    setOpened(!opened);
                  }}
                  icon={
                    opened ? (
                      <img src={MinusSmallIcon} alt="plus-icon" />
                    ) : (
                      <img src={PlusSmallIcon} alt="plus-icon" />
                    )
                  }
                />
              </div>
            )}
            {isSponsored && <IsSponsoredMessage />}
            {learningPathMessage && (
              <IsSponsoredMessage otherMessage={learningPathMessage} />
            )}
          </div>
        </div>
      </Container>
      <MessageActionModal
        open={showFinishEligibility}
        handleClose={() => {
          setShowFinishEligibility(false);
        }}
        modalClassName={"voucher-modal"}
        content={translationSelector(ERROR_TEXTS, "FILL_ELIGIBILITY_FORM")}
        actionButtonTitle={t("GO_TO_ELIGIBILITY_FORM")}
        onActionButtonClick={() => navigate("/dashboard/profile")}
      />
    </LearningHeaderDashboardWrapper>
  );
};

export default LearningHeaderDashboard;
