import { RES_POOLS } from "&store/resources";
import { API } from "aws-amplify";

const getbundlesApi = async () => {
  let URL = `/auth${RES_POOLS}`;
  return API.get(process.env.REACT_APP_NAME || "", URL, {});
};

const submitTransactionRequest = async (poolId: number, paymentMethod: string) => {
  const myInit = {
    body: {
      paymentMethod,
    },
  };
  let URL = `/auth/payments/${poolId}/offline`;
  return API.post(process.env.REACT_APP_NAME || "", URL, myInit);
};

const checkoutTransaction = async (poolId: number) => {
  let URL = `/auth/payments/${poolId}/online`;
  return API.post(process.env.REACT_APP_NAME || "", URL, {});
};

const verifyTransaction = async (orderId: number) => {
  let URL = `/auth/payments/verify-order/${orderId}`;
  return API.get(process.env.REACT_APP_NAME || "", URL, {});
};


const cancelTransactionRequest = async (transactionId: number) => {
  let URL = `/auth/payments/${transactionId}/offline`;
  return API.del(process.env.REACT_APP_NAME || "", URL, {});
};

const updateTransactionReceipts = async (
  transactionId: number,
  paymentReceipt: string
) => {
  let URL = `/auth/payments/${transactionId}/offline`;
  const myInit = {
    body: {
      paymentReceipt,
    },
  };
  return API.put(process.env.REACT_APP_NAME || "", URL, myInit);
};

export {
  getbundlesApi,
  submitTransactionRequest,
  cancelTransactionRequest,
  updateTransactionReceipts,
  checkoutTransaction,
  verifyTransaction,
};
