import { WorkExperience } from "&features/dashboard/screens/profile/profile.type";
import { WorkExperienceUpdateProfileBody } from "&features/login/login.type";
import { definedObjectBuilder } from "&utils/definedObjectBuilder";
import moment from "moment";

export const buildWorkExperienceItemBody: (
  experience: WorkExperience
) => WorkExperienceUpdateProfileBody = (experience: WorkExperience) => {
  const experienceBody: WorkExperienceUpdateProfileBody = {
    title: experience.title,
    companyName: experience.companyName,
    startDate: moment(experience.startDate)?.locale("en")?.format("YYYY-MM-DD"),
    endDate: experience.current
      ? undefined
      : moment(experience.endDate)?.locale("en").format("YYYY-MM-DD"),
    location: experience.location,
    description: experience.description,
    employmentType: experience.employmentType?.name,
    current: experience.current,
  };
  return definedObjectBuilder(experienceBody);
};

export const buildWorkExperiencesBody = (workExperiences: WorkExperience[]) => {
  const responseBody: WorkExperienceUpdateProfileBody[] = [];
  workExperiences?.map((experience: WorkExperience) => {
    responseBody.push(buildWorkExperienceItemBody(experience));
  });

  return responseBody;
};
