import styled from "styled-components";
import { colors } from "&assets/constants/colors";
import { device } from "&assets/constants/responsiveness";

interface Props {
  showAll?: boolean;
}

export const DragDropUploadFileWrapper = styled.div<Props>`
  .drag-drop-area {
    ${(props) => (props.showAll ? "min-height: 222px;" : "height: 222px;")}
    width: 100%;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px 42px;
    gap: 10px;
    border: 1px dashed #e7f4fb;
    background: ${colors.linearGradientWhite} padding-box, ${colors.linearGradientOne} border-box;
    border-radius: 8px;
    min-width: 408px;
    .documents {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
    }
    .image-wrapper {
      width: 200px;
      height: 200px;
      border-radius: 100px;
    }
    .icon-wrapper {
    }
    .document-name {
      font-weight: 700;
      font-size: 12px;
      line-height: 160%;
      /* or 19px */
      display: flex;
      align-items: center;
      text-align: center;
      margin-right: 20px;
      margin-top: 10px;
      /* Blue 1 */
      color: ${colors.primary};
    }

    .remove-photo-button {
      display: flex;
      align-items: center;
      margin-left: 10px;
      cursor: pointer;
    }
    .text {
      font-weight: 400;
      font-size: 14px;
      line-height: 160%;
      white-space: nowrap;
      color: ${colors.dark};
      &.or-text {
        opacity: 0.5;
      }
    }

    .line-div {
      width: 100%;
      opacity: 0.1;
      border: 1px solid ${colors.primaryDark};
    }

    .upload-button {
      height: 38px;
      margin-top: 16px;
      .title-button {
        font-weight: 800;
        font-size: 14px;
        line-height: 160%;
      }
    }
    @media ${device.mobile} {
      padding: 16px;
      min-width: 100%;
      .text {
        white-space: initial;
      }
    }
  }

  @media ${device.mobile} {
    .drag-drop-area {
      height: fit-content;
    }
  }
`;
