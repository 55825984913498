export const contactUsNameSpace = {
  en: {
    CONTACT_US: "Contact Us",
    STAY_CONNECTED: "Stay Connected",
    FILL_INFORMATION: "Fill in your information below to stay connected",
    YOUR_NAME: "Your name",
    EMAIL_ADDRESS: "Email address*",
    SUBJECT: "Subject*",
    MOBILE_NUMBER: "Mobile number*",
    ANYTHING_ELSE: "ANYTHING ELSE YOU’D LIKE US TO KNOW?*",
    YOUR_NAME_PLACEHOLDER: "Enter your full name",
    EMAIL_ADDRESS_PLACEHOLDER: "enter your email address*",
    SUBJECT_PLACEHOLDER: "Add the message subject",
    MOBILE_NUMBER_PLACEHOLDER: "ex: +961 71 322 334",
    ANYTHING_ELSE_PLACEHOLDER: "Type anything",
    SUBMIT: "Submit",
  },
  ar: {
    CONTACT_US: "اتصل بنا",
    STAY_CONNECTED: "ابق على اتصال",
    FILL_INFORMATION: "املأ معلوماتك في الاسفل لتبقى على تواصل",
    YOUR_NAME: "اسمك",
    EMAIL_ADDRESS: "عنوان البريد الإلكتروني*",
    SUBJECT: "الموضوع*",
    MOBILE_NUMBER: "رقم الهاتف*",
    ANYTHING_ELSE: "هل هناك أي شيء آخر تود أن نعرفه؟*",
    YOUR_NAME_PLACEHOLDER: "أدخل اسمك الكامل",
    EMAIL_ADDRESS_PLACEHOLDER: "أدخل عنوان بريدك الالكتروني",
    SUBJECT_PLACEHOLDER: "أضف موضوع الرسالة",
    MOBILE_NUMBER_PLACEHOLDER: "ex: +961 71 322 334",
    ANYTHING_ELSE_PLACEHOLDER: "اكتب أي شيء",
    SUBMIT: "تثبيت",
  },
};
