import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "&store/store";
import { UpdateProfileBody } from "&features/login/login.type";
import { loginActions } from "&features/login/login.slice";
import {
  AssessmentForm,
  SkillTypes,
} from "&features/assessment/assessment.type";
import SkillsBuilder from "&components/skillsBuilder/skillsBuilder.component";
import { otherSkillsFilter } from "&utils/profile/profileSelectors";
import { ProfileModalInnerContent } from "&components/profile/edit/profileEditModel/profileEditModal.styled";
import buildAssessmentBody from "&utils/buildAssessmentObject";
import { map } from "lodash";

interface Props {
  submit?: number;
  updateProfile: (body: UpdateProfileBody) => void;
  setDisableEdit?: (value: boolean) => void;
}

const ProfileAddSkills = (props: Props) => {
  // const { t } = useTranslation(["profile"]);

  const { submit, updateProfile, setDisableEdit } = props;

  const dispatch = useDispatch();
  const skills = useSelector((state: RootState) => state.login.skills);
  const formValues: AssessmentForm = useSelector(
    (state: RootState) => state.login.profileFormState
  );

  const initialValues: AssessmentForm = {
    softSkills: skills?.soft_skills,
    hardSkills: skills?.hard_skills,
    otherSkills: otherSkillsFilter(skills?.other_skills),
    otherSkillsList: skills?.other_skills,
  };

  const disableNext = (formState: AssessmentForm) => {
    const skillsToCheck: SkillTypes[] = ["softSkills", "hardSkills"];

    let skillSum = 0;

    map(skillsToCheck, (skillToCheck) => {
      const skillsObject = formState[skillToCheck];

      skillsObject &&
        // eslint-disable-next-line array-callback-return
        Object.keys(skillsObject).map((skillKey) => {
          if (skillsObject[skillKey]?.value?.checked) {
            skillSum = skillSum + 1;
          }
        });
    });

    return skillSum < 3;
  };

  useEffect(() => {
    if (formValues && setDisableEdit) {
      setDisableEdit(disableNext(formValues));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues]);

  const setProfileForm = (value: AssessmentForm) => {
    dispatch(loginActions.setProfileForm(value));
  };

  const handleSkillsUpdate = () => {
    updateProfile({
      skills: buildAssessmentBody(formValues, true),
    });
  };

  useEffect(() => {
    submit && submit > 0 && handleSkillsUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submit]);

  const handleChangeValues = (value: AssessmentForm) => {
    setProfileForm({ ...formValues, ...value });
  };

  useEffect(() => {
    handleChangeValues(initialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ProfileModalInnerContent>
      <div className="skills-wrapper">
        <SkillsBuilder
          formValues={formValues}
          setAssessmentForm={setProfileForm}
          loadingAssessment={!skills}
          settings={[]}
        />
      </div>
    </ProfileModalInnerContent>
  );
};

export default ProfileAddSkills;
