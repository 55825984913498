import React, { useEffect, useRef } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useTranslation } from "react-i18next";

import { RootState } from "&store/store";

import { blogsActions } from "./blogs.slice";
import { BlogsWrapper } from "./blogs.styled";
import BlogCardComponent from "&components/blogCard/blogCard.component";
import { BlogCard } from "./blogs.type";
import Container from "&components/container/container.component";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ArrowForwardIcon } from "&assets/constants/icons";
import { colors } from "&assets/constants/colors";
import SearchSortFilterBar from "&components/searchSortFilterBar/searchSortFilterBar.component";
import { defaultListingPageSearchParams } from "&assets/constants/statics";
import { GetPageListingQuery } from "&features/listingPage/listingPage.type";
import { buildDropDownList, querySelector } from "&utils/searchSortFilter/searchSortFilterUtils";
import PaginationComponent from "&components/pagination/pagination.component";
import CircularLoader from "&components/circularLoader/circularLoader.component";
import { translationSelector } from "&utils/translationSelector";

type ReduxProps = ConnectedProps<typeof connector>;

const BlogsComponent = (props: ReduxProps) => {
  const { t, i18n } = useTranslation(["blogs"]);
  const navigate = useNavigate();
  const { getBlogs, blogs, loading, pathsHistory } = props;
  const [urlSearchParams, setSearchParams] = useSearchParams();
  const ref = useRef<any>(null);

  const isArabic = i18n.language === "ar";

  useEffect(() => {
    setSearchParams({
      ...searchParams,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sortByObject = {
    createdAt: t("LATEST"),
    popular: t("POPULAR"),
    [isArabic ? "titleAr" : "title"]: t("NAME"),
  };

  const searchParams: any = {
    page: urlSearchParams.get("page") || defaultListingPageSearchParams.page,
    query: urlSearchParams.get("query") || defaultListingPageSearchParams.query,
    sort_by: urlSearchParams.get("sort_by") || defaultListingPageSearchParams.sort_by,
  };

  const searchState: any = {
    sort_by: {
      id: "sort_by",
      title: t("SORT_BY"),
      object: sortByObject,
      list: buildDropDownList(sortByObject),
      value: searchParams.sort_by,
      valueItem: querySelector(buildDropDownList(sortByObject), searchParams.sort_by),
    },
  };

  const fetchPageContent = (page: number) => {
    setSearchParams({
      ...searchParams,
      page: page.toString(),
    });

    const requestBody: GetPageListingQuery = {
      page: page || undefined,
      query: searchParams.query || "",
      limit: 8,
      dir: "ASC" || undefined,
      sort: (searchParams.sort_by !== "popular" && searchParams.sort_by) || undefined,
    };

    getBlogs(requestBody);
  };

  useEffect(() => {
    fetchPageContent(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.sort_by, searchParams.query]);

  const loadingComponent = (
    <div className="loader-wrapper">
      <CircularLoader />
    </div>
  );

  const totalPages = blogs?.meta?.totalPages;

  const findPrevious = () => {
    let previousPath = "";
    pathsHistory?.some((path: string) => {
      if (path !== window.location.pathname && !path.includes("blog-details")) {
        previousPath = path;
        return true;
      }
    });
    return previousPath;
  };

  return (
    <BlogsWrapper>
      <div className="blog-header">
        <Container className="listing-container">
          <button onClick={() => navigate(findPrevious())} className="back-button">
            <div className="arrow">{ArrowForwardIcon(colors.white)}</div>
            <div className="button-text">{t("BACK")}</div>
          </button>
          <p className="header-title">{t("BLOG")}</p>
          <SearchSortFilterBar
            searchState={searchState}
            forwardRef={ref}
            placeHolder={t("SEARCH_BY_TITLE_OR_KEYWORDS")}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
        </Container>
      </div>

      <Container className="listing-container">
        {loading ? (
          loadingComponent
        ) : (
          <div className="list-wrapper">
            {blogs?.items?.map((blog: BlogCard, index: number) => {
              return (
                <BlogCardComponent
                  key={index}
                  image={blog?.image}
                  title={translationSelector(blog, "title")}
                  tags={translationSelector(blog, "tags")}
                  date={blog?.createdAt}
                  handleClick={() => navigate(`/blog-details/${blog.id}`)}
                />
              );
            })}
          </div>
        )}
        {totalPages !== 1 && (
          <PaginationComponent
            count={totalPages}
            page={Number(searchParams.page)}
            onChangePage={(page) => fetchPageContent(page)}
          />
        )}
      </Container>
    </BlogsWrapper>
  );
};

const mapStateToProps = (state: RootState) => ({
  blogs: state.blogs.blogs,
  pathsHistory: state.global.pathsHistory,
  loading: state.blogs.loading,
});

const mapDispatchToProps = {
  getBlogs: blogsActions.getBlogs,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const BlogsComponentRedux = connector(BlogsComponent);

export { BlogsComponentRedux as BlogsComponent };
