import React, { useEffect, useRef, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useTranslation } from "react-i18next";
import { Slide } from "@mui/material";

import { RootState } from "&store/store";
import { assessmentActions } from "./assessment.slice";
import { AssessmentWrapper, ContentWrapper } from "./assessment.styled";
import TitleWithSteps from "&components/titleWithSteps/titleWithSteps.component";
import { SkillsComponent } from "&features/assessment/screens/skills/skills.component";
import { SuccessComponent } from "./screens/success/success.component";
import BackNextButton from "&components/buttons/backNextButton/backNextButton.component";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  AssessmentForm,
  SkillTypes,
} from "&features/assessment/assessment.type";
import Container from "&components/container/container.component";
import { careerMatchingActions } from "&features/careerMatching/careerMatching.slice";
import buildAssessmentBody from "&utils/buildAssessmentObject";
import { returnIndex } from "&utils/returnIndex";
import { TrackGoogleAnalyticsEvent } from "&utils/google-analytics/googleAnalytics";
import i18n from "i18next";
import { has, map } from "lodash";

type ReduxProps = ConnectedProps<typeof connector>;

const AssessmentComponent = (props: ReduxProps) => {
  const {
    setAssessmentForm,
    assessmentFormState,
    assessmentStep,
    setAssessmentStep,
    setAssessment,
    skills,
    getSkills,
    status,
    settings,
    loadingAssessment,
    getMatchingCareers,
    careerMatchingStatus,
    setCareerMatching,
  } = props;

  const { t } = useTranslation(["assessment"]);
  const dir = i18n.dir();

  let [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const ref = useRef<any>(null);

  const linkCareerPath: string = searchParams.get("career") || "";
  const careerId: string = searchParams.get("id") || "";

  const [stopCall, setStopCall] = useState<boolean>(false);

  const handleChangeValues = (value: AssessmentForm) => {
    setAssessmentForm({ ...assessmentFormState, ...value });
  };

  useEffect(() => {
    if (assessmentFormState.careerPath !== linkCareerPath) {
      setAssessmentStep(2);
      getSkills({ id: careerId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialValues: AssessmentForm = {
    careerPath: linkCareerPath,
    careerId: Number(careerId),
    age_range: "",
    education_level: "",
    english_proficiency: "",
    softSkills: skills?.soft_skills,
    hardSkills: skills?.hard_skills,
    otherSkills: [],
    otherSkillsList: skills?.other_skills,
  };

  useEffect(() => {
    if (status === "GET_SKILLS_SUCCESS") {
      handleChangeValues(initialValues);
      setAssessment({ status: "" });
      TrackGoogleAnalyticsEvent({
        category: "Assessment",
        action: "assessment_started",
        label: linkCareerPath,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    if (careerMatchingStatus === "GET_MATCHING_CAREERS_SUCCESS") {
      !stopCall && navigate("/career-matching");
      setCareerMatching({ status: "" });
      TrackGoogleAnalyticsEvent({
        category: "Assessment",
        action: "assessment_completed",
        label: linkCareerPath,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [careerMatchingStatus]);

  const handleNext = () => {
    setStopCall(false);
    if (assessmentStep <= 2) {
      ref?.current?.scrollIntoView({ behavior: "smooth", block: "nearest" });
      setAssessmentStep(assessmentStep + 1);
      getMatchingCareers({
        skills: buildAssessmentBody(assessmentFormState),
        careerId: Number(careerId),
        educationalScore: returnIndex(
          Number(assessmentFormState.education_level),
          settings.education_level
        ),
      });
    }
  };

  const handleBack = () => {
    setStopCall(true);
    if (assessmentStep > 2) {
      ref?.current?.scrollIntoView({ behavior: "smooth", block: "nearest" });
      setAssessmentStep(assessmentStep - 1);
    }
    if (assessmentStep === 2) {
      navigate(-1);
    }
  };

  const disableNext = (formState: AssessmentForm) => {
    const skillsToCheck: SkillTypes[] = [
      "softSkills",
      "hardSkills",
      "otherSkills",
    ];

    let skillSum = 0;

    map(skillsToCheck, (skillToCheck) => {
      const skillsObject = formState[skillToCheck];

      skillsObject &&
        // eslint-disable-next-line array-callback-return
        Object.keys(skillsObject).map((skillKey) => {
          if (
            skillsObject[skillKey]?.value?.checked ||
            !has(skillsObject[skillKey]?.value, "checked")
          ) {
            skillSum = skillSum + 1;
          }
        });
    });

    const otherValidation = !formState.age_range || !formState.education_level;
    // || !formState.english_proficiency;

    return skillSum < 3 || otherValidation;
  };

  const handleStepClick = (stepClicked: number) => {
    if (stepClicked === 2) {
      ref?.current?.scrollIntoView({ behavior: "smooth", block: "nearest" });
      setStopCall(true);
      setAssessmentStep(stepClicked);
    }
    if (stepClicked === 1) {
      navigate(-1);
    }
  };

  return (
    <AssessmentWrapper>
      <div className="mobile-container">
        <TitleWithSteps
          title={t("GET_YOUR_CAREER_MATCHING")}
          subTitle={t("BY_FOLLOWING_OUR_STEPS_YOU_WILL_EASILY")}
          onStepClick={handleStepClick}
          forwardRef={ref}
          short={true}
          step={assessmentStep}
        />
      </div>
      <Container className="padding-container">
        <ContentWrapper>
          {assessmentStep === 2 ? (
            <SkillsComponent />
          ) : (
            <Slide
              direction={dir === "rtl" ? "right" : "left"}
              in={true}
              mountOnEnter
              unmountOnExit
            >
              <div>
                <SuccessComponent />
              </div>
            </Slide>
          )}
        </ContentWrapper>
        <div className="buttons-wrapper mobile-container">
          <BackNextButton
            id={"assessment-back-button"}
            onClick={handleBack}
            isNext={false}
          />
          {assessmentStep === 2 && !loadingAssessment && (
            <BackNextButton
              onClick={handleNext}
              id={"assessment-next-button"}
              disabled={disableNext(assessmentFormState)}
              isNext={true}
            />
          )}
        </div>
      </Container>
      <div className="blurred-circle" />
    </AssessmentWrapper>
  );
};

const mapStateToProps = (state: RootState) => ({
  selectedCareerPath: state.landingPage.selectedCareerPath,
  assessmentFormState: state.assessment.assessmentFormState,
  assessmentStep: state.assessment.assessmentStep,
  skills: state.assessment.skills,
  loadingAssessment: state.assessment.loadingAssessment,
  status: state.assessment.status,
  careerMatchingStatus: state.careerMatching.status,
  settings: state.global.settings,
});

const mapDispatchToProps = {
  setAssessmentForm: assessmentActions.setAssessmentForm,
  setAssessmentStep: assessmentActions.setAssessmentStep,
  setAssessment: assessmentActions.setAssessment,
  getSkills: assessmentActions.getSkills,
  getMatchingCareers: careerMatchingActions.getMatchingCareers,
  setCareerMatching: careerMatchingActions.setCareerMatching,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const AssessmentComponentRedux = connector(AssessmentComponent);

export { AssessmentComponentRedux as AssessmentComponent };
