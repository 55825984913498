import React, { useEffect, useState } from "react";
import { connect, ConnectedProps, useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { RootState } from "&store/store";
import MatchingOpportunities from "&components/matchingOpportunities/matchingOpportunities.component";
import LearningHeader from "&components/learningHeader/learningHeader.component";
import Container from "&components/container/container.component";
import CircularLoader from "&components/circularLoader/circularLoader.component";
import secondsToHours from "&utils/secondstoHours";
import { Clock, Document } from "&assets/constants/icons";
import { courseDetailsActions } from "&features/courseDetails/courseDetails.slice";
import { loginActions } from "&features/login/login.slice";
import getCDNProtectedURL from "&utils/getCDNProtectedImageSource";
import { images } from "&assets/constants/images-urls";
import { LearningPathwayWrapper } from "&features/learningPathway/learningPathway.styled";
import { learningPathwayActions } from "&features/learningPathway/learningPathway.slice";
import LearningActivityTracking from "&components/learningActivityTracking/learningActivityTracking.component";
import {
  checkIFEnrolledLP,
  returnLearningPathItem,
} from "&utils/checkIfEnrolled";
import ApplyForVoucher from "&components/applyForVoucher/applyForVoucher.component";
import AddSkillModal from "&components/addSkillModal/addSkillModal.component";
import UploadCompletionProof from "&components/uploadCompletionProof/uploadCompletionProof.component";
import HtmlParser from "&components/htmlParser/htmlParser.component";
import CustomModal from "&components/custom-modal/custom-modal.component";
import { CompleteOneCourseWrapper } from "&components/learningActivityTracking/learningActivityTracking.styled";
import { ERROR_TEXTS } from "&assets/constants/statics";
import SubmitButton from "&components/buttons/submitButton/submitButton.component";
import MessageActionModal from "&components/modals/messageActionModal/messageActionModal.component";
import { useAuth0 } from "@auth0/auth0-react";
import { hasExam } from "&utils/hasExam";
import { RenderButtonsProps } from "&components/learningPathwayActivity/LearningPathwayActivity.component";
import LearningActivityActionButtonsModal from "&components/dashboard/learningActivityActionButtonsModal/learningActivityActionButtonsModal.component";
import { translationSelector } from "&utils/translationSelector";
import { LearningPathItem } from "&features/listingPage/listingPage.type";
import {
  hideActivityButtons,
  needAssessmentMessageSelector,
} from "&utils/learningPaths/learningPathsSelectors";

type ReduxProps = ConnectedProps<typeof connector>;
type Props = {
  auth?: boolean;
};

const LearningPathwayComponent = (props: ReduxProps & Props) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation(["learningPathway"]);
  const {
    selectedLearningPath,
    getLearningPathwayById,
    loading,
    enrollLearningPathway,
    isLoggedIn,
    loadingEnroll,
    status,
    setLearningPathway,
    getProfile,
    setLogin,
    auth,
    getActivityJobs,
    activityJobs,
    loadingJobs,
    selectedCountry,
  } = props;
  let { id } = useParams();
  const user = useSelector((state: RootState) => state.login.user);
  const dispatch = useDispatch();
  const sponsorshipError = useSelector(
    (state: RootState) => state.courseDetails.sponsorshipError
  );
  const { instructions, voucher, instructionsAr } = useSelector(
    (state: RootState) => state.courseDetails.voucherResponse
  );
  const statusCourse = useSelector(
    (state: RootState) => state.courseDetails.status
  );

  const isArabic = i18n.language === "ar";
  const { loginWithRedirect } = useAuth0();

  const [applyVoucherOpen, setApplyVoucherOpen] = useState(false);
  const [goToFreeCoursesOpen, setGoToFreeCoursesOpen] = useState(false);
  const [voucherActivity, setVoucherActivity] = useState<any>(undefined);
  const [showFinishEligibility, setShowFinishEligibility] = useState(false);
  const [uploadProofOpen, setUploadProof] = useState(false);
  const [addSkillsOpen, setAddSkillsOpen] = useState(false);
  const [activitySelected, setActivity] = useState<{
    item?: any;
    type: string;
    learningPathId: number;
    reason?: string;
  }>({ item: undefined, type: "", learningPathId: 0, reason: "" });
  const informatics: any = [
    {
      icon: <Document fill="white" />,
      text: selectedLearningPath?.learningActivities?.length + t("COURSES"),
    },
    {
      icon: <Clock fill="white" />,
      text: secondsToHours(selectedLearningPath?.duration),
    },
    {
      text: (
        <div className="progress">
          <p className="percent">
            {selectedLearningPath?.progress}% {t("COMPLETED")}
          </p>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (id) {
      getLearningPathwayById(id);
      getActivityJobs({
        id,
        type: "learning-paths",
        body: {
          page: 1,
          limit: 9,
          countryId: selectedCountry.value,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, selectedCountry, localStorage.getItem("language")]);

  useEffect(() => {
    if (statusCourse === "SENT_REPORT_SUCCESSFULLY") {
      id && getLearningPathwayById(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusCourse, localStorage.getItem("language")]);

  useEffect(() => {
    if (status === "ENROLLED_LEARNING_PATH_SUCCESSFULLY") {
      navigate("/dashboard/activities");
      setLearningPathway({ status: "" });
      getProfile({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    if (statusCourse === "CLAIM_SPONSORSHIP_SUCCESSFULLY") {
      setApplyVoucherOpen(true);
      dispatch(courseDetailsActions.setCourseDetails({ status: "" }));
      dispatch(loginActions.getProfileThunk({}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusCourse]);

  useEffect(() => {
    if (sponsorshipError === "COMPLETE_AT_LEAST_ONE_COURSE") {
      setGoToFreeCoursesOpen(true);
      dispatch(courseDetailsActions.setCourseDetails({ sponsorshipError: "" }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sponsorshipError]);

  const [openActivityPopup, setOpenActivityPopup] = useState<{
    renderButtonsProps?: RenderButtonsProps;
    open: boolean;
  }>({ renderButtonsProps: undefined, open: false });

  const loadingComponent = (
    <div className="loader-wrapper">
      <CircularLoader />
    </div>
  );

  const handleEnrollLearningPathway = () => {
    if (enroll) {
      window.open(
        translationSelector(selectedLearningPath, "url"),
        "_blank",
        "noreferrer"
      );
      return;
    }
    if (isLoggedIn) id && enrollLearningPathway(id);
    else {
      setLogin({
        actionAfterSignup: "enroll-learningPathway",
        id,
      });
      loginWithRedirect();
    }
  };

  const enroll = checkIFEnrolledLP(selectedLearningPath?.id);
  const learningPathItem: LearningPathItem | null = returnLearningPathItem(
    selectedLearningPath?.id,
    user
  );

  const completedEligibility =
    user?.eligibilityForm && user.eligibilityForm.approved;

  const handleApplyVoucher = (
    event: any,
    activity: any,
    type: string,
    learningPathId?: any
  ) => {
    event?.stopPropagation();
    if (
      !user?.eligibilityForm ||
      (user?.eligibilityForm && !user?.eligibilityForm?.approved)
    ) {
      navigate("/dashboard/profile");
    } else {
      setVoucherActivity(activity);
      dispatch(
        courseDetailsActions.claimSponsorship({
          id: activity.id,
          type: `${type}s`,
          sponsorshipType: activity?.sponsorshipType,
          learningPathId:
            type === "exam" && learningPathId ? learningPathId : undefined,
        })
      );
    }
  };

  const completeOneCourseContent = () => {
    return (
      <CompleteOneCourseWrapper className="description-error-wrapper">
        <p className="description-error-text">
          {translationSelector(ERROR_TEXTS, "COMPLETE_AT_LEAST_ONE_COURSE")}
        </p>
        <SubmitButton
          id={"redirect-to-free-courses-button"}
          title={"View list of our free courses"}
          handleClick={() =>
            navigate("/listing-page?learning_activity=courses&type=free")
          }
        />
      </CompleteOneCourseWrapper>
    );
  };

  const voucherModalDescription = (instructions: string) => {
    const voucherString = voucher || "";
    const activityName = voucherActivity?.name || "";
    return (
      instructions
        // eslint-disable-next-line no-template-curly-in-string
        .replaceAll("${voucher.code}", voucherString)
        // eslint-disable-next-line no-template-curly-in-string
        .replaceAll("${activity.name}", activityName)
    );
  };

  return (
    <LearningPathwayWrapper>
      {loading ? (
        loadingComponent
      ) : (
        <>
          <LearningHeader
            title={selectedLearningPath?.name}
            description={selectedLearningPath?.shortDescription}
            handleBackClick={() => navigate(-1)}
            otherMessage={
              learningPathItem &&
              needAssessmentMessageSelector(t, learningPathItem)
            }
            providerName={selectedLearningPath?.source}
            image={
              selectedLearningPath?.image ||
              getCDNProtectedURL(images.learningPathwayImg)
            }
            level={selectedLearningPath?.level?.alias}
            informatics={informatics}
            hasCertification={hasExam(selectedLearningPath)}
            hasButton={!enroll && !auth}
            handleButtonClick={() => handleEnrollLearningPathway()}
            loading={loadingEnroll}
            buttonText={enroll ? "View Learning Pathway" : "Enroll now"}
            buttonComponent={
              completedEligibility &&
              selectedLearningPath?.sponsorshipType && (
                <ApplyForVoucher
                  sponsored={false}
                  id={selectedLearningPath?.id}
                  sponsorshipType={selectedLearningPath?.sponsorshipType || ""}
                  type="learning-paths"
                />
              )
            }
          />

          <Container className="activities-container">
            <LearningActivityTracking
              handleOpenPopup={(renderButtonsProps: RenderButtonsProps) =>
                setOpenActivityPopup({
                  open: true,
                  renderButtonsProps: renderButtonsProps,
                })
              }
              hideActivityButtons={
                learningPathItem
                  ? hideActivityButtons(learningPathItem)
                  : undefined
              }
              learningPathId={selectedLearningPath?.id}
              learningActivities={selectedLearningPath?.learningActivities}
              handleApplyVoucher={handleApplyVoucher}
              handleCompletionClick={(
                activity: any,
                item: any,
                type: string
              ) => {
                setActivity({
                  reason: translationSelector(activity, "reason") || undefined,
                  item: item,
                  learningPathId: activity.learningPathId,
                  type,
                });
                activity.status === 3
                  ? setAddSkillsOpen(true)
                  : setUploadProof(true);
              }}
            />
          </Container>
          <MatchingOpportunities
            opportunities={activityJobs}
            loading={loadingJobs}
            title={t("MATCHING_OPPORTUNITIES")}
            allButtonText={t("VIEW_ALL_OPPORTUNITIES")}
            onAllButtonClick={() => navigate("/opportunities")}
          />
          <MessageActionModal
            open={showFinishEligibility}
            handleClose={() => {
              setShowFinishEligibility(false);
            }}
            modalClassName={"voucher-modal"}
            content={translationSelector(ERROR_TEXTS, "FILL_ELIGIBILITY_FORM")}
            actionButtonTitle={"Go to Eligibility Form"}
            onActionButtonClick={() => navigate("/dashboard/profile")}
          />
          <LearningActivityActionButtonsModal
            handleClose={() =>
              setOpenActivityPopup({
                open: false,
                renderButtonsProps: undefined,
              })
            }
            open={openActivityPopup.open}
            renderButtonsProps={openActivityPopup.renderButtonsProps}
          />
          <CustomModal
            handleClose={() => {
              setApplyVoucherOpen(false);
              setGoToFreeCoursesOpen(false);
            }}
            open={applyVoucherOpen || goToFreeCoursesOpen}
            className="voucher-modal"
          >
            {applyVoucherOpen ? (
              <HtmlParser
                description={voucherModalDescription(
                  isArabic && instructionsAr ? instructionsAr : instructions
                )}
              ></HtmlParser>
            ) : (
              completeOneCourseContent()
            )}
          </CustomModal>
          <UploadCompletionProof
            open={uploadProofOpen}
            handleClose={() => {
              setUploadProof(!uploadProofOpen);
            }}
            id={activitySelected?.item?.id}
            type={activitySelected?.type}
            learningPathId={activitySelected?.learningPathId}
            reason={activitySelected?.reason}
          />
          <AddSkillModal
            open={addSkillsOpen}
            handleClose={() => {
              setAddSkillsOpen(!addSkillsOpen);
            }}
            skills={
              activitySelected?.item?.courseSkills ||
              activitySelected?.item?.labSkills ||
              activitySelected?.item?.examSkills
            }
          ></AddSkillModal>
        </>
      )}
    </LearningPathwayWrapper>
  );
};

const mapStateToProps = (state: RootState) => ({
  selectedLearningPath: state.learningPathway.selectedLearningPath,
  loading: state.learningPathway.loading,
  loadingEnroll: state.learningPathway.loadingEnroll,
  isLoggedIn: state.login.isLoggedIn,
  user: state.login.user,
  status: state.learningPathway.status,
  activityJobs: state.courseDetails.activityJobs,
  loadingJobs: state.courseDetails.loadingJobs,
  selectedCountry: state.landingPage.selectedCountry,
});

const mapDispatchToProps = {
  getLearningPathwayById: learningPathwayActions.getLearningPathwayById,
  enrollLearningPathway: learningPathwayActions.enrollLearningPathway,
  setLearningPathway: learningPathwayActions.setLearningPathway,
  setCourseDetails: courseDetailsActions.setCourseDetails,
  getProfile: loginActions.getProfileThunk,
  setLogin: loginActions.setLogin,
  getActivityJobs: courseDetailsActions.getActivityJobs,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const LearningPathwayComponentRedux = connector(LearningPathwayComponent);

export { LearningPathwayComponentRedux as LearningPathwayComponent };
