import React from "react";
import { IsSponsoredMessageWrapper } from "./isSponsoredMessage.styled";
import {useTranslation} from "react-i18next";

interface Props {
  otherMessage?: string;
  wrapperClassName?: string;
  textClassName?: string;
  isBlack?: boolean;
}

const IsSponsoredMessage = (props: Props) => {
  const {
    otherMessage,
    wrapperClassName,
    textClassName = "",
    isBlack = false,
  } = props;

  const { t } = useTranslation(["listingPage"]);

  const message = otherMessage
    ? otherMessage
    : t('THIS_LEARNING_PATH_CONTAINS_SPONSORED_ACTIVITIES');

  return (
    <IsSponsoredMessageWrapper className={wrapperClassName}>
      <p className={textClassName + (isBlack ? " black-text" : "")}>{message}</p>
    </IsSponsoredMessageWrapper>
  );
};

export default IsSponsoredMessage;
