import { colors } from "&assets/constants/colors";
import { device } from "&assets/constants/responsiveness";
import styled from "styled-components";

export const FaqCardWrapper = styled.div`
  margin: 12px 0;
  width: 100%;
  cursor: pointer;
  .opened {
    max-height: auto !important;
    background: ${colors.primaryDark2} !important;
  }
  .actions {
    cursor: pointer;
  }
  .faq-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    width: 100%;
    box-sizing: border-box;
    background: rgba(16, 140, 210, 0.4);
    border-radius: 16px;
    .content {
      width: 100%;
      padding-bottom: 0;
      padding: 0;
    }
    .faq-action {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 5px 0;
    }
    .faq-question {
      font-family: "Clash Grotesk";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 120%;
      color: #ffffff;
    }
    .faq-answer {
      font-family: "Mulish";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 160%;
      color: #ffffff;
    }
    .separator {
      background: linear-gradient(326.57deg, #ffffff 14.26%, #f4f1fa 84.73%);
      height: 1px;
      width: 100%;
      //margin: -10px 0px -2px 0px;
    }
  }
  @media ${device.tablet} {
    width: 100%;
    margin: 10px 0;
    .faq-card {
      background: rgba(16, 140, 210, 0.8);
    }
  }
  @media ${device.mobile} {
    width: 100%;
    margin: 10px 0;
    .faq-card {
      background: rgba(16, 140, 210, 0.8);
    }
  }
`;
