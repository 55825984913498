import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
// import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { RootState } from "&store/store";

import { FutureSkillsLandingWrapper } from "./futureSkillsLanding.styled";
import FrequentlyAskedQuestions from "&components/frequentlyAskedQuestions/frequentlyAskedQuestions.component";
import LearningPathway from "&components/learningPathway/learningPathway.component";
import RelatedLearningActivities from "&components/relatedLearningActivities/relatedLearningActivities.component";
import { landingPageActions } from "&features/landingPage/landingPage.slice";
import BenefitsOf from "&components/benifitsOf/benifitsOf.component";
import GettingStartedSteps from "&components/gettingStartSteps/gettingStartSteps.component";
import { learningPathwayActions } from "&features/learningPathway/learningPathway.slice";
import { Course } from "&features/listingPage/listingPage.type";
import getTopLearningActivities from "&utils/getTopLearningActivities";

type ReduxProps = ConnectedProps<typeof connector>;

const FutureSkillsLandingComponent = (props: ReduxProps) => {
  const { homeContent, getPagesContent, getLevels, levels, getFeaturedLearningPathway, featuredLearningPaths } = props;
  const content = homeContent?.sections;
  // const { t } = useTranslation(["futureSkillsLanding"]);
  const [activities, setActivities] = useState<Course[]>([]);

  useEffect(() => {
    !homeContent?.sections && getPagesContent();
    (!levels || levels?.length === 0) && getLevels({ limit: 5 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    levels &&
      getFeaturedLearningPathway({
        params: {
          page: 1,
          limit: 9,
        },
        ids: [levels[0]?.careers[0]?.id, levels[1]?.careers[0]?.id, levels[2]?.careers[0]?.id],
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [levels, localStorage.getItem("language")]);

  useEffect(() => {
    setActivities(getTopLearningActivities(featuredLearningPaths));
  }, [featuredLearningPaths]);

  const navigate = useNavigate();

  return (
    <FutureSkillsLandingWrapper>
      <GettingStartedSteps
        title="Select your career options in 3 steps"
        subTitle="We know that finding the right career is a commitment. That's why we've made it as easy as possible to get started with us! We'll help you choose your career, complete your profile by assessing your skills, and give you a matching scorecard for the career you selected."
        short={true}
      />
      {featuredLearningPaths && (
        <LearningPathway
          pathways={featuredLearningPaths}
          title={content.steps[1].title}
          allButtonText="View all learning pathways"
        />
      )}
      <RelatedLearningActivities
        title={"Top learning activities"}
        allButtonText={"View all activities"}
        activities={activities}
        onAllButtonClick={() => navigate("/listing-page?learning_activity=courses")}
      />
      <BenefitsOf title={"Benefits of nammiskills"} skillsBenefits />
      {content?.faq[0] && <FrequentlyAskedQuestions data={content.faq[0]} showViewAll={true} />}
    </FutureSkillsLandingWrapper>
  );
};

const mapStateToProps = (state: RootState) => ({
  homeContent: state.landingPage.homeContent,
  levels: state.landingPage.levels,
  featuredLearningPaths: state.learningPathway.featuredLearningPaths,
});

const mapDispatchToProps = {
  getPagesContent: landingPageActions.getPagesContent,
  getLevels: landingPageActions.getLevels,
  getFeaturedLearningPathway: learningPathwayActions.getFeaturedLearningPathway,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const FutureSkillsLandingComponentRedux = connector(FutureSkillsLandingComponent);

export { FutureSkillsLandingComponentRedux as FutureSkillsLandingComponent };
