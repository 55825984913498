import { Setting, Settings } from "&features/global/global.type";
import { map } from "lodash";

const buildSettingsObject = (settingsObject: Settings) => {
  const settingSelector = (settingList: Setting[]) => {
    const createId = (name: string, id: number) => {
      return `${name.toLowerCase().replaceAll(" ", "-")}-${id}`;
    };
    const newList: any = [];
    map(settingList, (item: Setting) => {
      newList.push({
        settingId: createId(item.name, item.id),
        title: item.name,
        titleAr: item.nameAr,
        ...item,
      });
    });

    return newList;
  };
  if (!settingsObject) {
    return {};
  }

  return {
    ...settingsObject,
    age_range: settingSelector(settingsObject.age_range),
    education_level: settingSelector(settingsObject.education_level),
    english_proficiency: settingSelector(settingsObject.english_proficiency),
  };
};

export default buildSettingsObject;
