import styled from "styled-components";
import { colors } from "&assets/constants/colors";

interface Props {
  isExam?: boolean;
}

export const ActivityCardWrapper = styled.button<Props>`
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 316px;
  min-height: 354px;
  padding: 24px;
  border-radius: 16px;
  border: 1px solid transparent;
  background: ${colors.linearGradientWhite} padding-box, ${colors.linearGradientOne} border-box;
  transition: all 0.2s ease-in;
  position: relative;

  &:hover {
    background: ${colors.linearGradientWhite} padding-box, ${colors.linearGradientTwo} border-box;
    box-shadow: 4px 4px 24px 0px #00000040;
  }

  .activity-image {
    width: 268px;
    height: 150px;
    border-radius: 16px;
    margin-bottom: 20px;
    .image-wrapper {
      background-size: ${(props) => (props.isExam ? "contain" : "cover")};
    }
  }

  .activity-title {
    font-weight: 700;
    font-size: 18px;
    line-height: 120%;
    color: ${colors.dark};
    margin-bottom: 10px;
    ${(props) => (props.theme.dir === "rtl" ? "text-align: right;" : "text-align: left;")}
    height: 44px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
  }

  .sponsorship {
    position: absolute;
    width: 93px;
    height: 29px;
    background: ${colors.linearGradientOne};

    ${(props) => (props.theme.dir === "rtl" ? "border-radius: 8px 0 0 8px;" : "border-radius: 0 8px 8px 0;")}
    ${(props) => (props.theme.dir === "rtl" ? "right: 0;" : "left: 0;")}
    top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    font-weight: 800;
    font-size: 12px;
    line-height: 120%;
    color: ${colors.white};
    box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.25);
  }
`;

export const ActivityLogoNameWrapper = styled.div`
  display: flex;
  margin-bottom: 11px;
  .logo-wrapper {
    box-sizing: border-box;
    width: 30px;
    height: 30px;
    border: 1px solid ${colors.primary};
    ${(props) => (props.theme.dir === "rtl" ? "margin-left: 10px;" : "margin-right: 10px;")}
    border-radius: 15px;
    padding: 5px;
  }

  .logo {
    width: 100%;
    height: 100%;
  }

  .source-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 200%;
    color: ${colors.lighterDark};
  }
`;

export const SkillsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;

  .skill-chip {
    ${(props) => (props.theme.dir === "rtl" ? "margin-left: 10px;" : "margin-right: 10px;")}
  }
`;

export const ActivitiesWrapper = styled.div`
  display: flex;
  .activity-wrapper {
    display: flex;
    ${(props) => (props.theme.dir === "rtl" ? "margin-left: 20px;" : "margin-right: 20px;")}
    align-items: center;

    &:last-child {
      ${(props) => (props.theme.dir === "rtl" ? "margin-left: 0;" : "margin-right: 0;")}
    }
  }

  .details-text {
    ${(props) => (props.theme.dir === "rtl" ? "margin-right: 5px;" : "margin-left: 5px;")}
    font-weight: 400;
    white-space: nowrap;
    font-size: 14px;
    line-height: 200%;
    color: ${colors.lighterDark};
    text-transform: capitalize;
  }
`;
