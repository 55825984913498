import { CertificationItem } from "&features/dashboard/screens/profile/profile.type";
import { UserCertificatesBody } from "&features/login/login.type";
import { definedObjectBuilder } from "&utils/definedObjectBuilder";
import moment from "moment";

export const certificatesBodySelector: (
  certificate: CertificationItem
) => UserCertificatesBody = (certificate: CertificationItem) => {
  const userCertificateBody: UserCertificatesBody = {
    name: certificate.name,
    instituteName: certificate.instituteName,
    issueDate: moment(certificate.issueDate)?.locale("en")?.format("YYYY-MM-DD"),
    expiryDate: certificate?.expiryDate
      ? moment(certificate.expiryDate)?.locale("en")?.format("YYYY-MM-DD")
      : undefined,
  };
  return definedObjectBuilder(userCertificateBody);
};
