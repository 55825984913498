import { colors } from "&assets/constants/colors";
import { device } from "&assets/constants/responsiveness";
import styled from "styled-components";

export const AddSkillModalWrapper = styled.div`
  padding: 59px 54px;
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 100px;
    border-radius: 16px;
    width: 754px;
  }
  .line-div {
    width: 100%;
    opacity: 0.1;
    border: 1px solid ${colors.primaryDark};
  }
  .description {
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    text-align: center;
    color: ${colors.dark};
  }
  .add-skills-btn {
    height: 38px;
    margin: auto;
    margin-top: 16px;
    .add-skills-btn-text {
      font-weight: 800;
      font-size: 14px;
      line-height: 160%;
    }
  }

  @media ${device.inBetween} {
    .content {
      width: 100%;
      padding: 10px 10px;
    }
    padding: 10px 70px;
  }

  @media ${device.tablet} {
    .content {
      width: 100%;
      padding: 10px 10px;
    }
    padding: 10px 50px;
  }

  @media ${device.mobile} {
    .content {
      width: 100%;
      padding: 0;
    }
    padding: 10px 0px;
  }
`;
