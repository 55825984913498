import { API } from "aws-amplify";
import {
  RES_SETTINGS,
  RES_PAGES,
  RES_TIERS,
  RES_COUNTRIES,
} from "&store/resources";
import axios from "axios";

const getLevelsApi = async (limit: number, countryId: number) => {
  let URL = RES_TIERS;
  const myInit = {
    queryStringParameters: {
      limit: limit,
      countryId,
    },
  };
  return API.get(process.env.REACT_APP_NAME || "", URL, myInit);
};

const getSettingsApi = async () => {
  let URL = RES_SETTINGS;
  return API.get(process.env.REACT_APP_NAME || "", URL, {});
};

const getPagesContentApi = async (page: string) => {
  let URL = RES_PAGES + `/${page}`;
  return API.get(process.env.REACT_APP_NAME || "", URL, {});
};

const getCountriesApi = async () => {
  let URL = `${RES_COUNTRIES}/scrapping`;
  return API.get(process.env.REACT_APP_NAME || "", URL, {});
};

const getFooterCountriesApi = async () => {
  let URL = `${RES_SETTINGS}/countries-selection`;
  return API.get(process.env.REACT_APP_NAME || "", URL, {});
};

const getCurrentCountryApi = async () => {
  let URL = `https://ipapi.co/json/`;
  return axios.get(URL);
};

export {
  getLevelsApi,
  getSettingsApi,
  getPagesContentApi,
  getCountriesApi,
  getCurrentCountryApi,
  getFooterCountriesApi,
};
