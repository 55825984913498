import React from "react";
import { LinearLoaderWrapper } from "./linearLoader.styled";
import { LinearProgress } from "@mui/material";

interface Props {
  className?: string;
}

const LinearLoader = (props: Props) => {
  const { className } = props;
  return (
    <LinearLoaderWrapper className={className}>
      <LinearProgress className='linear-progress-root' color="primary" />
    </LinearLoaderWrapper>
  );
};

export default LinearLoader;
