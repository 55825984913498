import styled from "styled-components";
import { colors } from "&assets/constants/colors";

export const SubmitFormButtonWrapper = styled.button`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border: 0;
  position: relative;
  width: 212px;
  height: 74px;
  background: ${colors.linearGradientOne};
  box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.25);
  border-radius: 80px;

  &:disabled {
    opacity: 0.7;
  }

  .title {
    font-weight: 800;
    font-size: 18px;
    line-height: 160%;
    text-align: center;
    color: ${colors.white};
  }

  .arrow-wrapper {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    ${(props) => (props.theme.dir === "rtl" ? "left: -24px;" : "right: -24px;")}
    ${(props) => (props.theme.dir === "rtl" ? "transform: scaleX(-1);" : "")}
    width: 48px;
    height: 48px;
    background: #ffffff;
    /* Shadow */

    box-shadow: 0px 79px 128px rgba(192, 192, 192, 0.09), 0px 28.8363px 46.7221px rgba(192, 192, 192, 0.0598508),
      0px 13.9995px 22.6827px rgba(192, 192, 192, 0.0475723), 0px 6.86281px 11.1195px rgba(192, 192, 192, 0.0380675),
      0px 2.71357px 4.39666px rgba(192, 192, 192, 0.0270615);

    border-radius: 80px;
  }
`;
