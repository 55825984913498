import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { LearningPathwayWrapper } from "./learningPathway.styled";
import LearningPathCard from "&components/learningPathCard/learningPathCard.component";
import Container from "&components/container/container.component";
import CardsCarousel from "&components/cardsCarousel/cardsCarousel.component";
import SectionTitleButton from "&components/sectionTitleButton/sectionTitleButton.component";
import { listingPageActions } from "&features/listingPage/listingPage.slice";
import { RootState } from "&store/store";
import CircularLoader from "&components/circularLoader/circularLoader.component";
import { LearningPathwayType } from "&features/listingPage/listingPage.type";
import getCDNProtectedURL from "&utils/getCDNProtectedImageSource";
import { images } from "&assets/constants/images-urls";
import { checkIFEnrolledLP } from "&utils/checkIfEnrolled";
import { translationSelector } from "&utils/translationSelector";
import { useTranslation } from "react-i18next";
import ImageBackground from "&components/imageBackground/imageBackground.component";

interface Props {
  data?: LearningPathwayType[];
  title: string;
  hideTier?: boolean;
  allButtonText: string;
  pathways?: LearningPathwayType[];
  imgBg?: boolean;
}

const LearningPathway = (props: Props) => {
  const { title, allButtonText, pathways, hideTier } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const learningPathways = useSelector(
    (state: RootState) => state.listingPage.learningPathways
  );
  const loading = useSelector(
    (state: RootState) => state.listingPage.loadingApi
  );
  const user = useSelector((state: RootState) => state.login.user);
  const { t } = useTranslation(["learningPathway"]);

  useEffect(() => {
    if (pathways && pathways?.length === 0) {
      const requestBody = {
        page: 1,
        query: "",
        levelId: undefined,
        limit: 7,
        sort: undefined,
      };
      dispatch(listingPageActions.getLearningPathways(requestBody));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("language")]);

  const loadingComponent = (
    <div className="loader-wrapper">
      <CircularLoader />
    </div>
  );

  const handleClickLearningPathway = (item: any) => {
    checkIFEnrolledLP(item.id, user)
      ? navigate(`/dashboard/learning-pathway/${item.id}`)
      : navigate(`/learning-pathway/${item.id}`);
  };

  const content = pathways || learningPathways?.items;

  const renderList = () => {
    return (
      <>
        {loading ? (
          loadingComponent
        ) : (
          <CardsCarousel>
            {content.map((item: LearningPathwayType, index: number) => {
              return (
                <div
                  className="cards"
                  key={index}
                  onClick={() => handleClickLearningPathway(item)}
                >
                  <LearningPathCard
                    hideTier={hideTier}
                    hasExam={
                      item?.learningPathExams &&
                      item?.learningPathExams?.length > 0
                    }
                    image={
                      item?.image ||
                      getCDNProtectedURL(images.learningPathwayImg)
                    }
                    startDate={item.startDate}
                    enrollmentEndDate={item.enrollmentEndDate}
                    enrollmentStartDate={item?.enrollmentStartDate}
                    time={item?.duration}
                    modul={
                      item?.learningPathCourses?.length + ` ${t("COURSES")}`
                    }
                    title={translationSelector(item, "name")}
                    subTitle={
                      item?.learningPathExams &&
                      item?.learningPathExams?.length > 0
                        ? item?.learningPathExams[0]?.exams?.source
                        : item?.source
                    }
                    level={translationSelector(item?.level, "alias")}
                  />
                </div>
              );
            })}
          </CardsCarousel>
        )}
      </>
    );
  };
  if (props.imgBg)
    return (
      <ImageBackground
        title={title}
        allButtonText={allButtonText}
        children={renderList()}
        onAllButtonClick={() =>
          navigate("/listing-page?learning_activity=learning_path")
        }
      />
    );
  return (
    <LearningPathwayWrapper>
      <Container>
        <SectionTitleButton
          wrapperClassName="title-button-wrapper"
          buttonText={allButtonText}
          onButtonClick={() =>
            navigate("/listing-page?learning_activity=learning_path")
          }
          title={title}
        />
        {renderList()}
      </Container>
    </LearningPathwayWrapper>
  );
};

export default LearningPathway;
