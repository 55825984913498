import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import toast from "react-hot-toast";

import {
  checkInclusionApi,
  claimSponsorshipApi,
  enrollCourseApi,
  getActivityJobsApi,
  getCourseByIdApi,
  getRelatedCourseByIdApi,
  sendManualReportApi,
  getRelatedLearningPathsByCourseIdApi,
  launchActivityApi,
} from "./courseDetails.api";
import { CourseDetails, SendManualReport } from "./courseDetails.type";
import { ERROR_TEXTS } from "&assets/constants/statics";
import { loginActions } from "&features/login/login.slice";
import { translationSelector } from "&utils/translationSelector";

const initialState: CourseDetails = {
  loading: false,
  loadingRelatedCourses: false,
  loadingVoucher: false,
  loadingEnroll: false,
  loadingJobs: false,
  loadingInclusion: false,
  activityJobs: undefined,
  selectedCourse: undefined,
  relatedCourses: undefined,
  status: undefined,
  sponsorshipError: undefined,
  loadingReport: false,
  showEnroll: true,
  voucherResponse: {
    voucher: "",
    instructions: "",
    instructionsAr: "",
  },
  loadingRelatedLearningPaths: false,
  relatedLearningPaths: undefined,
};

const getCourseById = createAsyncThunk(
  "courseDetails/getCourseById",
  async (id: string, { rejectWithValue, getState, dispatch }: any) => {
    try {
      const isLoggedIn = getState().login.isLoggedIn;
      const course = await getCourseByIdApi(id, isLoggedIn);
      return course?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const getRelatedCoursesById = createAsyncThunk(
  "courseDetails/getRelatedCoursesById",
  async (id: string, { rejectWithValue, getState, dispatch }: any) => {
    try {
      const isLoggedIn = getState().login.isLoggedIn;
      const courses: any = await getRelatedCourseByIdApi(id, isLoggedIn);
      return courses?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const enrollCourse = createAsyncThunk(
  "courseDetails/enrollCourse",
  async (id: string, { rejectWithValue, getState, dispatch }: any) => {
    try {
      const course = await enrollCourseApi(id);
      return course?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const sendManualReport = createAsyncThunk(
  "courseDetails/sendManualReport",
  async (
    body: SendManualReport,
    { rejectWithValue, getState, dispatch }: any
  ) => {
    try {
      const result = await sendManualReportApi(body);
      return result?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const checkInclusion = createAsyncThunk(
  "courseDetails/checkInclusion",
  async (id: string, { rejectWithValue, getState, dispatch }: any) => {
    try {
      const result = await checkInclusionApi(id);
      return result;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const launchActivity = createAsyncThunk(
  "courseDetails/launchActivity",
  async (
    {
      type,
      id,
      fromCourseDetails,
    }: { type: string; id: any; fromCourseDetails?: boolean },
    { rejectWithValue, getState, dispatch }: any
  ) => {
    try {
      const result = await launchActivityApi(type, id);
      if (fromCourseDetails) {
        dispatch(courseDetailsActions.getCourseById(id));
      }
      dispatch(loginActions.getUserLearningPaths());
      return result;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const claimSponsorship = createAsyncThunk(
  "courseDetails/claimSponsorship",
  async (
    {
      id,
      type,
      sponsorshipType,
      learningPathId,
    }: {
      id: string;
      type: string;
      sponsorshipType: string;
      learningPathId?: any;
    },
    { rejectWithValue, getState, dispatch }: any
  ) => {
    try {
      const result = await claimSponsorshipApi(
        id,
        type,
        sponsorshipType,
        learningPathId
      );
      return result?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const getActivityJobs = createAsyncThunk(
  "courseDetails/getActivityJobs",
  async (
    { id, type, body }: { id: string; type: string; body: any },
    { rejectWithValue, getState, dispatch }: any
  ) => {
    try {
      const isLoggedIn = getState().login.isLoggedIn;
      const result = await getActivityJobsApi(id, type, body, isLoggedIn);
      return result?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const getRelatedLearningPathsByCourseId = createAsyncThunk(
  "courseDetails/getRelatedLearningPathsByCourseId",
  async (id: string, { rejectWithValue, getState, dispatch }: any) => {
    try {
      const courses: any = await getRelatedLearningPathsByCourseIdApi(id);
      return courses?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const courseDetailsSlice = createSlice({
  name: "courseDetails",
  initialState: initialState,
  reducers: {
    setCourseDetails: (state, action) => {
      return { ...state, ...action.payload };
    },
    reset: () => initialState,
  },

  extraReducers: (builder) => {
    builder.addCase(getCourseById.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getCourseById.fulfilled, (state, action) => {
      state.loading = false;
      state.selectedCourse = action.payload;
    });
    builder.addCase(getCourseById.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getRelatedCoursesById.pending, (state, action) => {
      state.loadingRelatedCourses = true;
    });
    builder.addCase(getRelatedCoursesById.fulfilled, (state, action) => {
      state.loadingRelatedCourses = false;
      state.relatedCourses = action.payload;
    });
    builder.addCase(getRelatedCoursesById.rejected, (state, action) => {
      state.loadingRelatedCourses = false;
    });
    builder.addCase(enrollCourse.pending, (state, action) => {
      state.loadingEnroll = true;
    });
    builder.addCase(enrollCourse.fulfilled, (state, action) => {
      state.loadingEnroll = false;
      state.status = "ENROLLED_COURSE_SUCCESSFULLY";
    });
    builder.addCase(enrollCourse.rejected, (state, action) => {
      state.loadingEnroll = false;
    });

    builder.addCase(sendManualReport.pending, (state, action) => {
      state.loadingReport = true;
    });
    builder.addCase(sendManualReport.fulfilled, (state, action) => {
      state.loadingReport = false;
      state.status = "SENT_REPORT_SUCCESSFULLY";
    });
    builder.addCase(sendManualReport.rejected, (state, action) => {
      state.loadingReport = false;
    });

    builder.addCase(checkInclusion.pending, (state, action) => {
      state.loadingInclusion = true;
    });
    builder.addCase(checkInclusion.fulfilled, (state, action) => {
      state.loadingInclusion = false;
      state.showEnroll = true;
      state.status = "CHECK_INCLUSION_SUCCESSFULLY";
    });
    builder.addCase(checkInclusion.rejected, (state, action: any) => {
      state.loadingInclusion = false;
      state.status = "COURSE_INCLUDED_IN_ENROLLED_LEARNING_PATH";
      if (
        action.payload.response.data.message ===
        "COURSE_INCLUDED_IN_ENROLLED_LEARNING_PATH"
      ) {
        state.showEnroll = false;
      }
    });

    builder.addCase(claimSponsorship.pending, (state, action) => {
      state.loadingVoucher = true;
    });
    builder.addCase(claimSponsorship.fulfilled, (state, action) => {
      state.loadingVoucher = false;
      state.voucherResponse = action.payload;
      state.status = "CLAIM_SPONSORSHIP_SUCCESSFULLY";
    });
    builder.addCase(claimSponsorship.rejected, (state, action: any) => {
      state.loadingVoucher = false;
      const errorMessage = action?.payload?.response?.data?.message;
      if(errorMessage !== "COMPLETE_AT_LEAST_ONE_COURSE" &&
        errorMessage !== "ERROR_CLAIMING_VOUCHER_EQ"){
          toast.error(translationSelector(ERROR_TEXTS(), errorMessage));
        }
      state.sponsorshipError = errorMessage;
    });

    builder.addCase(getActivityJobs.pending, (state, action) => {
      state.loadingJobs = true;
    });
    builder.addCase(getActivityJobs.fulfilled, (state, action) => {
      state.loadingJobs = false;
      state.activityJobs = action.payload;
      state.status = "GET_ACTIVITY_JOBS_SUCCESSFULLY";
    });
    builder.addCase(getActivityJobs.rejected, (state, action: any) => {
      state.loadingJobs = false;
    });

    builder.addCase(
      getRelatedLearningPathsByCourseId.pending,
      (state, action) => {
        state.loadingRelatedLearningPaths = true;
      }
    );
    builder.addCase(
      getRelatedLearningPathsByCourseId.fulfilled,
      (state, action) => {
        state.loadingRelatedLearningPaths = false;
        state.relatedLearningPaths = action.payload;
      }
    );
    builder.addCase(
      getRelatedLearningPathsByCourseId.rejected,
      (state, action) => {
        state.loadingRelatedLearningPaths = false;
      }
    );
  },
});

export const courseDetailsReducer = courseDetailsSlice.reducer;

export const courseDetailsActions = {
  ...courseDetailsSlice.actions,
  getCourseById,
  enrollCourse,
  sendManualReport,
  checkInclusion,
  claimSponsorship,
  getActivityJobs,
  getRelatedCoursesById,
  getRelatedLearningPathsByCourseId,
  launchActivity,
};
