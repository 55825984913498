import React from "react";
import {
  InputButtonWrapper,
  RadioWrapper,
  CheckBoxWrapper,
} from "./inputButton.styled";

import Check from "&assets/images/check.svg";
import stringToDashedId from "&utils/stringToDashedId";

interface Props {
  id: string;
  title?: string;
  selected?: boolean;
  handleClick?: Function;
  type?: string;
  showAll?: boolean;
  disabled?: boolean;
}

const InputButton = (props: Props) => {
  const {
    id,
    title,
    selected = false,
    handleClick,
    type,
    showAll,
    disabled,
  } = props;
  return (
    <InputButtonWrapper
      id={`button-${stringToDashedId(title || id)}-${id}`}
      selected={selected}
      title={title}
      inputType={type}
      showAll={showAll}
      disabled={disabled}
      onClick={() => (handleClick ? handleClick() : {})}
    >
      <div className="content">
        {type === "checkbox" ? (
          <CheckBoxWrapper selected={selected}>
            {selected && <img src={Check} alt="check-icon" />}
          </CheckBoxWrapper>
        ) : (
          <RadioWrapper selected={selected}>
            {selected && <div className="check-dot" />}
          </RadioWrapper>
        )}

        <div className="title">{title}</div>
      </div>
    </InputButtonWrapper>
  );
};

export default InputButton;
