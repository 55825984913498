import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useTranslation } from "react-i18next";
import { RootState } from "&store/store";
import parse from "html-react-parser";
import { notificationsActions } from "./notifications.slice";
import {
  NotificationPageItem,
  NotificationsWrapper,
} from "./notifications.styled";
import Container from "&components/container/container.component";
import { Notification } from "&features/notifications/notifications.type";
import { translationSelector } from "&utils/translationSelector";
import ListItemsPartially from "&components/listItemsPartially/listItemsPartially.component";
import moment from "moment";
import SubmitButton from "&components/buttons/submitButton/submitButton.component";
import { readPanelNotifications } from "./notifications.api";

type ReduxProps = ConnectedProps<typeof connector>;

const NotificationsComponent = (props: ReduxProps) => {
  const { panelNotifications, getPanelNotifications } = props;
  const [markedAsRead, setMarkedAsRead] = useState(false);
  const { t } = useTranslation(["notifications"]);

  const handleMarkNotificationsAsRead = () => {
    try {
      readPanelNotifications();
    } catch (err) {
      console.log(err);
    } finally {
      setMarkedAsRead(true);
    }
  };
  useEffect(() => {
    setMarkedAsRead(false);
    getPanelNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markedAsRead]);

  return (
    <NotificationsWrapper>
      <Container className="notifications-container">
        <div className="notification-title-wrapper">
          <h1 className="notification-title">{t("NOTIFICATIONS")}</h1>
          <SubmitButton
            id="mark-all-read-button"
            className="mark-all-read-button"
            titleClassName="button-title"
            title={t("MARK_ALL_AS_READ")}
            handleClick={handleMarkNotificationsAsRead}
          />
        </div>
        <div className="notifications-wrapper">
          <ListItemsPartially
            data={panelNotifications}
            showAllOnLoadMore={true}
            cutOffIndex={7}
            loadMoreButtonTitle={"Load older notifications"}
            loadMoreClassName="load-more-button"
            renderItem={(notification: Notification) => {
              const isJobNotification =
                notification?.group?.includes("job_notify_group");
              const regex = /\[(\d+)\]/;

              const match: any = isJobNotification
                ? notification.message.match(regex)
                : [];
              const jobId = match[1];
              const updatedNotification = {
                ...notification,
                message: isJobNotification
                  ? parse(
                      notification.message.replace(
                        /\[(\d+)\]/,
                        `<a href="/opportunity-details/${jobId}">Click here</a>`
                      )
                    )
                  : notification.message,
                messageAr: isJobNotification
                  ? parse(
                      notification.messageAr.replace(
                        /\[(\d+)\]/,
                        `<a href="/opportunity-details/${jobId}">انقر هنا</a>`
                      )
                    )
                  : notification.messageAr,
              };
              return (
                <NotificationPageItem read={updatedNotification.status}>
                  {/* <p className="notification-title">
                    {translationSelector(notification, "title")}
                  </p> */}
                  <p className="date-text">
                    {moment(updatedNotification.createdAt).format(
                      "DD - MM - YYYY"
                    )}
                  </p>
                  <p className="notification-message">
                    {translationSelector(updatedNotification, "message")}
                  </p>
                </NotificationPageItem>
              );
            }}
          />
        </div>
      </Container>
    </NotificationsWrapper>
  );
};

const mapStateToProps = (state: RootState) => ({
  panelNotifications: state.notifications.panelNotifications,
});

const mapDispatchToProps = {
  getPanelNotifications: notificationsActions.getPanelNotifications,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const NotificationsComponentRedux = connector(NotificationsComponent);

export { NotificationsComponentRedux as NotificationsComponent };
