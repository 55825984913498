import styled from "styled-components";
import { device } from "&assets/constants/responsiveness";
import { colors } from "&assets/constants/colors";

interface Props {
  isWhite?: boolean;
}

export const TitleArrowButtonWrapper = styled.button<Props>`
  padding: 0;
  border: 0;
  background: transparent;
  font-weight: 800;
  font-size: 18px;
  line-height: 160%;
  text-align: center;
  color: ${(props) => (props.isWhite ? colors.white : colors.primary)};
  z-index: 1;
  align-items: center;
  display: flex;
  cursor: pointer;
  svg {
    ${(props) =>
            props.theme.dir === "rtl"
                    ? "margin-right: 10px;"
                    : "margin-left: 10px;"}
    ${(props) =>
            props.theme.dir === "rtl"
                    ? "transform: scaleX(-1);"
                    : ""}
  }

  @media ${device.tablet}, ${device.mobile} {
    font-size: 18px;
    font-weight: 600;
  }
`;
