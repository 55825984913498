import React, { useEffect, useRef, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { isEmpty } from "lodash";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { RootState } from "&store/store";
import { courseDetailsActions } from "./courseDetails.slice";
import { CourseDetailsWrapper } from "./courseDetails.styled";
import LearningHeader from "&components/learningHeader/learningHeader.component";
import Chip from "&components/chip/chip.component";
import MatchingOpportunities from "&components/matchingOpportunities/matchingOpportunities.component";
import ActionButton from "&components/buttons/actionButton/actionButton.component";
import { size } from "&assets/constants/responsiveness";
import Container from "&components/container/container.component";
import RelatedLearningActivities from "&components/relatedLearningActivities/relatedLearningActivities.component";
import { CourseSkill } from "&features/listingPage/listingPage.type";
import { Clock, Document } from "&assets/constants/icons";
import secondsToHours from "&utils/secondstoHours";
import CircularLoader from "&components/circularLoader/circularLoader.component";
import HtmlParser from "&components/htmlParser/htmlParser.component";
import { loginActions } from "&features/login/login.slice";
import getCDNProtectedURL from "&utils/getCDNProtectedImageSource";
import { images } from "&assets/constants/images-urls";
import { checkIFEnrolledCourse } from "&utils/checkIfEnrolled";
import ApplyForVoucher from "&components/applyForVoucher/applyForVoucher.component";
import { useTranslation } from "react-i18next";
import { translationSelector } from "&utils/translationSelector";
import { useAuth0 } from "@auth0/auth0-react";
import { courseURLBuilder } from "&utils/courseURLBuilder";
import { getCourseIdFromPath } from "&utils/learningPaths/learningPathsSelectors";

type ReduxProps = ConnectedProps<typeof connector>;

const CourseDetailsComponent = (props: ReduxProps) => {
  const {
    getCourseById,
    loading,
    selectedCourse,
    loadingJobs,
    enrollCourse,
    isLoggedIn,
    loadingEnroll,
    status,
    user,
    setCourseDetails,
    getProfile,
    setLogin,
    selectedCountry,
    checkInclusion,
    showEnroll,
    loadingInclusion,
    getActivityJobs,
    activityJobs,
    getRelatedCoursesById,
    loadingRelatedCourses,
    relatedCourses,
    getRelatedLearningPathsByCourseId,
    loadingRelatedLearningPaths,
    relatedLearningPaths,
  } = props;
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: size.mobile });
  const [readMore, setReadMore] = useState(false);
  const [showReadMore, setShowReadMore] = useState(false);
  const { t } = useTranslation(["courseDetails"]);

  const { idParam } = useParams();
  const location = useLocation();
  const id = getCourseIdFromPath(location.pathname) || idParam;
  const { loginWithRedirect } = useAuth0();
  const descriptionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const descriptionElement = descriptionRef.current;
    if (descriptionElement) {
      const isContentOverflowing =
        descriptionElement.scrollHeight > descriptionElement.clientHeight;
      setShowReadMore(isContentOverflowing);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCourse?.description, isMobile]);

  const informatics: any = [
    {
      icon: <Document fill="white" />,
      text: selectedCourse?.language?.toUpperCase(),
    },
    {
      icon: <Clock fill="white" />,
      text: secondsToHours(selectedCourse?.duration),
    },
  ];

  useEffect(() => {
    if (status === "ENROLLED_COURSE_SUCCESSFULLY") {
      navigate(`/dashboard/course/${id}`);
      setCourseDetails({ status: "" });
      getProfile({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    if (id) {
      getCourseById(id);
      getRelatedCoursesById(id);
      isLoggedIn && checkInclusion(id);
      getActivityJobs({
        id,
        type: "courses",
        body: {
          page: 1,
          limit: 9,
          countryId: selectedCountry.value,
        },
      });
      getRelatedLearningPathsByCourseId(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, selectedCountry, localStorage.getItem("language")]);

  const loadingComponent = (
    <div className="loader-wrapper">
      <CircularLoader />
    </div>
  );

  const handleEnrollCourse = () => {
    if (enrolled || !showEnroll) {
      window.open(
        courseURLBuilder(selectedCourse?.url),
        "_blank",
        "noreferrer"
      );
      return;
    }
    if (isLoggedIn) id && enrollCourse(id);
    else {
      setLogin({
        actionAfterSignup: "enroll-course",
        id,
      });
      loginWithRedirect();
    }
  };

  const enrolled = checkIFEnrolledCourse(selectedCourse?.id, user);

  const showSponsored =
    selectedCourse?.sponsorshipType &&
    selectedCourse?.sponsorshipType === "subscription"
      ? !isEmpty(user?.sponsorships) &&
        !isEmpty(user?.sponsorships[selectedCourse?.source])
      : false;

  const hideLaunchButton =
    selectedCourse?.source?.toLowerCase() === "simplilearn";
  return (
    <CourseDetailsWrapper>
      {loading ? (
        loadingComponent
      ) : (
        <>
          <LearningHeader
            title={translationSelector(selectedCourse, "name")}
            description={translationSelector(
              selectedCourse,
              "shortDescription"
            )}
            handleBackClick={() => navigate(-1)}
            providerName={selectedCourse?.source}
            image={
              selectedCourse?.image ||
              getCDNProtectedURL(images.learningPathwayImg)
            }
            hasButton={!hideLaunchButton}
            handleButtonClick={() => handleEnrollCourse()}
            level={translationSelector(selectedCourse?.level, "alias")}
            informatics={informatics}
            loading={loadingEnroll || loadingInclusion}
            buttonText={
              enrolled || !showEnroll ? t("LAUNCH_COURSE") : t("ENROLL_NOW")
            }
            sponsored={!showEnroll && showSponsored}
            enrollForSponsorship={
              !!selectedCourse?.sponsored && !(enrolled || !showEnroll)
            }
            buttonComponent={
              selectedCourse?.sponsorshipType &&
              !!selectedCourse?.sponsored && (
                <ApplyForVoucher
                  disabled={!(enrolled || !showEnroll)}
                  id={selectedCourse?.id}
                  type="courses"
                  sponsorshipType={selectedCourse?.sponsorshipType}
                  sponsored={showSponsored}
                />
              )
            }
          />
          <Container>
            <div className="course-details">
              <p className="header">{t("COURSE_DESCRIPTION")}</p>
              <div
                className={`content-text ${readMore ? "expand" : ""}`}
                ref={descriptionRef}
              >
                {!readMore && showReadMore && (
                  <div className={`layer ${readMore ? "expand" : ""}`} />
                )}
                <HtmlParser
                  className="description-content"
                  description={translationSelector(
                    selectedCourse,
                    "description"
                  )}
                />
              </div>
              {isMobile && showReadMore && (
                <ActionButton
                  className="read-more"
                  titleClassName="read-more-title"
                  title={readMore ? t("READ_LESS") : t("READ_MORE")}
                  handleClick={() => setReadMore(!readMore)}
                />
              )}
              <p className="header">{t("SKILLS_YOU_WILL_GAIN")}</p>
              <div className="chips">
                {selectedCourse?.courseSkills?.map(
                  (item: CourseSkill, index: number) => {
                    return (
                      <Chip
                        title={translationSelector(item?.skills, "name")}
                        key={index}
                      ></Chip>
                    );
                  }
                )}
              </div>
            </div>
          </Container>
          {loading
            ? loadingRelatedCourses
            : relatedCourses &&
              relatedCourses.length > 0 && (
                <RelatedLearningActivities
                  activities={relatedCourses}
                  allButtonText={t("VIEW_ALL_COURSES")}
                  title={t("RELATED_LEARNING_ACTIVITIES")}
                  onAllButtonClick={() =>
                    navigate("/listing-page?learning_activity=courses")
                  }
                />
              )}
          {loading
            ? loadingRelatedLearningPaths
            : relatedLearningPaths &&
              relatedLearningPaths.length > 0 && (
                <RelatedLearningActivities
                  activities={
                    relatedLearningPaths.map((val: any) => val.learningPaths) ||
                    []
                  }
                  isLearningPaths={true}
                  allButtonText={t("VIEW_ALL_LEARNING_PATHS")}
                  title={t("RELATED_LEARNING_PATHWAYS")}
                  onAllButtonClick={() =>
                    navigate("/listing-page?learning_activity=learning_path")
                  }
                />
              )}
          <MatchingOpportunities
            opportunities={activityJobs}
            loading={loadingJobs}
            onAllButtonClick={() => navigate("/opportunities")}
          />
        </>
      )}
    </CourseDetailsWrapper>
  );
};

const mapStateToProps = (state: RootState) => ({
  selectedCourse: state.courseDetails.selectedCourse,
  showEnroll: state.courseDetails.showEnroll,
  loading: state.courseDetails.loading,
  loadingRelatedCourses: state.courseDetails.loadingRelatedCourses,
  relatedCourses: state.courseDetails.relatedCourses,
  loadingEnroll: state.courseDetails.loadingEnroll,
  loadingInclusion: state.courseDetails.loadingInclusion,
  activityJobs: state.courseDetails.activityJobs,
  loadingJobs: state.courseDetails.loadingJobs,
  isLoggedIn: state.login.isLoggedIn,
  status: state.courseDetails.status,
  user: state.login.user,
  selectedCountry: state.landingPage.selectedCountry,
  loadingRelatedLearningPaths: state.courseDetails.loadingRelatedLearningPaths,
  relatedLearningPaths: state.courseDetails.relatedLearningPaths,
});

const mapDispatchToProps = {
  getCourseById: courseDetailsActions.getCourseById,
  getRelatedCoursesById: courseDetailsActions.getRelatedCoursesById,
  checkInclusion: courseDetailsActions.checkInclusion,
  enrollCourse: courseDetailsActions.enrollCourse,
  setCourseDetails: courseDetailsActions.setCourseDetails,
  getActivityJobs: courseDetailsActions.getActivityJobs,
  getProfile: loginActions.getProfileThunk,
  setLogin: loginActions.setLogin,
  getRelatedLearningPathsByCourseId:
    courseDetailsActions.getRelatedLearningPathsByCourseId,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const CourseDetailsComponentRedux = connector(CourseDetailsComponent);

export { CourseDetailsComponentRedux as CourseDetailsComponent };
