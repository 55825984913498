import { isArray } from "lodash";
import moment from "moment";

import { EducationItem } from "&features/dashboard/screens/profile/profile.type";
import { EducationItemUpdateProfileBody } from "&features/login/login.type";
import { definedObjectBuilder } from "&utils/definedObjectBuilder";

export const educationBodySelector: (
  education: EducationItem
) => EducationItemUpdateProfileBody = (education: EducationItem) => {
  const educationBody: EducationItemUpdateProfileBody = {
    majorId: education.fieldOfStudy?.id,
    instituteId: education.schoolName?.id,
    otherInstitute:
      education.schoolName?.name === "Other"
        ? education.otherSchoolName
        : undefined,
    startDate: moment(education.startDate)?.locale("en")?.format("YYYY-MM-DD"),
    graduationDate: education.current
      ? undefined
      : moment(education.graduationYear)?.locale("en")?.format("YYYY-MM-DD"),
    degree: education.educationDegree?.name,
    description: education.description,
    current: education.current,
  };
  return definedObjectBuilder(educationBody);
};

const buildEducationBody = (educations: EducationItem[] | EducationItem) => {
  const responseBody: EducationItemUpdateProfileBody[] = [];

  if (isArray(educations)) {
    educations?.map((educationItem: EducationItem) => {
      responseBody.push(educationBodySelector(educationItem));
    });
  } else {
    responseBody.push(educationBodySelector(educations));
  }
  return responseBody;
};

export default buildEducationBody;
