import React from "react";
import { ChangeCareerSectionWrapper } from "./changeCareerSection.styled";
import { User } from "&features/login/login.type";
import ImageDisplay from "&components/imageDisplay/imageDisplay.component";
import { BusinessOptionIcon } from "&assets/constants/icons";
import { translationSelector } from "&utils/translationSelector";
import SubmitButton from "&components/buttons/submitButton/submitButton.component";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginActions } from "&features/login/login.slice";

interface Props {
  user?: User;
}

const ChangeCareerSection = (props: Props) => {
  const { user } = props;
  const { t } = useTranslation(["profile"]);
  const icon = user?.career?.icon || <BusinessOptionIcon />;
  const iconSelector =
    typeof icon === "string" ? (
      <ImageDisplay className="icon-image" isFromS3={true} url={icon} />
    ) : (
      icon
    );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChangeCareer = () => {
    dispatch(loginActions.setLogin({ profileStep: 1, changeCareerMode: true }));
    navigate("/build-profile");
  };

  return (
    <ChangeCareerSectionWrapper>
      <div className="career-card">
        <div className="circle-icon-wrapper">
          <div className="circle" />
          <div className="icon">{iconSelector}</div>
        </div>
        <p className="career-title">
          {translationSelector(user?.career, "name")}
        </p>
      </div>
      <SubmitButton
        id={"change-career-button"}
        title={t("CHANGE_CAREER")}
        handleClick={handleChangeCareer}
      />
    </ChangeCareerSectionWrapper>
  );
};

export default ChangeCareerSection;
