import styled from "styled-components";
import { colors } from "&assets/constants/colors";
import { device } from "&assets/constants/responsiveness";

interface Props {
  cvMode?: boolean;
}

export const ProfileWrapper = styled.div<Props>`
  width: 100%;
  .profile-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 140px 25px 140px;
    box-sizing: border-box;
  }

  .capture-wrapper {
    width: 100%;
    padding: ${(props) => (props.cvMode ? "50px" : 0)};
  }

  .prompt-wrapper {
    margin-top: 41px;
    margin-bottom: 44px;
  }

  @media ${device.mobile}, ${device.tablet} {
    .profile-container {
      padding: 16px;
    }
  }
`;

export const EmptyEducationPlaceholderWrapper = styled.div`
  padding: 15px 0;
  font-size: 14px;
`;

export const ProfileGrid = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 70% 30%;
  justify-content: center;
  width: 100%;

  .column {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 40px;
  }

  @media ${device.mobile}, ${device.tablet} {
    display: flex;
    flex-direction: column;

    .column {
      margin-bottom: 0;
      &:last-child {
        margin-bottom: 24px;
      }
    }
  }
`;

interface ProfileContentProps {
  isSmall?: boolean;
  viewMode?: boolean;
  isBanner?: boolean;
  isCentered?: boolean;
}

export const ProfileContentWrapper = styled.div<ProfileContentProps>`
  overflow: hidden;
  position: relative;
  padding: ${(props) => (props.isBanner ? "24px 40px" : props.isSmall ? "33px 16px 24px 16px" : "26px 40px 24px 40px")};
  border: 1px solid ${(props) => (props.viewMode ? colors.primary : "transparent")};
  background: ${(props) =>
    props.viewMode
      ? colors.white
      : props.isBanner
      ? colors.primaryDark
      : ` ${colors.linearGradientWhite}
      padding-box,
    ${colors.linearGradientOne} border-box;`};
  transition: all 0.2s ease-in;
  border-radius: 16px;

  margin-bottom: 24px;

  .line-section {
    height: 1px;
    background: ${colors.borderLight};
    display: flex;
    flex: 1;
    margin: 20px;
  }

  .detail-sections-wrapper {
    display: flex;
    flex-direction: column;
  }

  .show-interests-wrapper {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    .interest-wrapper {
      ${(props) => (props.theme.dir === "rtl" ? "margin-left: 15px;" : "margin-right: 15px;")}
      margin-top: 10px;
    }
  }

  .close-button-wrapper {
    position: absolute;
    width: 24px;
    height: 24px;
    top: 8px;
    ${(props) => (props.theme.dir === "rtl" ? "left: 8px;" : "right: 8px;")}
  }

  @media ${device.mobile}, ${device.tablet} {
    padding: 16px;
    margin-bottom: 16px;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const ProfileInnerContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .edit-button-wrapper {
    ${(props) => (props.theme.dir === "rtl" ? "margin-right: 38px;" : "margin-left: 38px;")}
    min-width: 40px;
  }

  .add-button-wrapper {
    min-width: fit-content;
    height: 40px;
    padding: 0 16px;
    ${(props) => (props.theme.dir === "rtl" ? "margin-right: 31px;" : "margin-left: 31px;")}
    .add-button-title {
      font-size: 14px;
      ${(props) => (props.theme.dir === "rtl" ? "margin-right: 8px;" : "margin-left: 8px;")}
    }
  }

  .secondary-color {
  }

  @media ${device.mobile}, ${device.tablet} {
    .add-button-wrapper {
      max-width: 160px;
      ${(props) => (props.theme.dir === "rtl" ? "margin-right: 10px;" : "margin-left: 10px;")}
      .add-button-title {
        font-size: 14px;
        ${(props) => (props.theme.dir === "rtl" ? "margin-right: 8px;" : "margin-left: 8px;")}
      }
    }
  }
`;

export const ProfileInnerBannerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .banner-title {
    text-align: center;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 120%;
    display: flex;
    align-items: center;
    color: ${colors.white};
    margin-bottom: 20px;
  }

  .content-wrapper {
    padding: 24px 15px;

    background: ${colors.primary};
    border-radius: 16px;
    margin-bottom: 20px;

    .banner-content-text {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 160%;
      color: ${colors.white};
    }
  }

  .action-button-wrapper {
    background: ${colors.white};
    width: 142px;
  }

  .action-button-text {
    color: ${colors.primary};
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 160%;
    text-align: center;
  }
`;

export const AboutMeWrapper = styled.div`
  margin-top: 13px;
  .text {
    max-width: 680px;
    font-weight: 500;
    font-size: 16px;
    line-height: 160%;
    color: ${colors.dark};
  }
`;
