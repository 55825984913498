import React, { useState, useEffect } from "react";
import { CardContent, Collapse } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";

import { LearningPathwayCardWrapper } from "./learningPathwayCard.styled";
import { ArrowForwardIcon, Clock, Document } from "&assets/constants/icons";
import PlusSmallIcon from "&assets/images/plusSmall.svg";
import MinusSmallIcon from "&assets/images/minusSmall.svg";
import SubmitButton from "&components/buttons/submitButton/submitButton.component";
import CloseIconButton from "&components/closeIcon/closeIcon.component";
import { CourseSkill, learningPathCourses } from "&features/listingPage/listingPage.type";
import secondsToHours from "&utils/secondstoHours";
import SourceLogo from "&components/sourceLogo/sourceLogo.component";
import SkillsList from "&components/skillsList/skillsList.component";
import { RootState } from "&store/store";
import { learningPathwayActions } from "&features/learningPathway/learningPathway.slice";
import { images } from "&assets/constants/images-urls";
import IsSponsoredMessage from "&components/messages/isSponsoredMessage/isSponsoredMessage.component";
import ImageDisplay from "&components/imageDisplay/imageDisplay.component";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation } from "react-router-dom";
import LearningPathwayActivity from "&components/learningPathwayActivity/LearningPathwayActivity.component";
import getCDNProtectedURL from "&utils/getCDNProtectedImageSource";

interface Props {
  id?: number;
  title?: string;
  subTitle?: string;
  modul?: string;
  time?: number;
  image?: any;
  description?: string;
  handleClick?: Function;
  hasExam?: boolean;
  skills: CourseSkill[];
  activities?: learningPathCourses[];
  level?: string;
  isSponsored?: boolean;
  showQuickView?: boolean;
  enrollBtn?: boolean;
  enrollClick?: () => void;
}

const LearningPathwayCard = (props: Props) => {
  const {
    id,
    title,
    subTitle,
    modul,
    time,
    image,
    description,
    handleClick,
    hasExam,
    skills,
    isSponsored,
    level = "beginner",
    showQuickView = true,
    enrollBtn,
    enrollClick,
  } = props;
  // const navigate = useNavigate();
  const dispatch = useDispatch();

  const { t } = useTranslation(["listingPage"]);

  const [opened, setOpened] = useState(false);
  // const isLoggedIn = useSelector((state: RootState) => state.login.isLoggedIn);
  const [learningActivities, setLearningActivities] = useState<any>([]);
  const selectedLearningPath = useSelector((state: RootState) => state.learningPathway.selectedLearningPath);
  const status = useSelector((state: RootState) => state.learningPathway.status);
  const { loginWithRedirect } = useAuth0();

  const location = useLocation();

  useEffect(() => {
    console.log("here", selectedLearningPath);
    if (status === "LEARNING_PATH_BY_ID_SUCCESS") {
      setLearningActivities(selectedLearningPath?.learningActivities);
      if (location.pathname !== "/certifications") selectedLearningPath?.id === id ? setOpened(true) : setOpened(false);
      dispatch(learningPathwayActions.setLearningPathway({ status: "" }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const handleQuickViewClick = () => {
    console.log("id", id);

    id && dispatch(learningPathwayActions.getLearningPathwayById(id.toString()));
  };
  return (
    <LearningPathwayCardWrapper opened={opened} showQuickView={showQuickView}>
      <div className="before-collapse">
        <div className="header">
          <div className="image-back">
            <ImageDisplay url={image} isFromS3={true} alt={title} className="card-img" />
            <div className="tier">{level}</div>
          </div>

          {hasExam && <div className="certification">{t("CERTIFICATION")}</div>}
          <div className="card-content">
            <SourceLogo source={subTitle?.toLowerCase()} />
            <p className="title">{subTitle}</p>
          </div>
          <div className="card-content">
            <p className="title info">
              <Document /> <span className="text">{modul}</span>
            </p>
            <p className="title info">
              <Clock /> <span className="text">{secondsToHours(time)}</span>
            </p>
          </div>
        </div>
        <div className="content">
          <p className="pathway-title">{title}</p>
          <p className="description">{description}</p>
          <SkillsList skills={skills || []} isChip />

          <div className="enroll-btn-div">
            {enrollBtn && (
              <SubmitButton
                title={t("ENROLL_NOW")}
                id="explore-learning-path-id"
                handleClick={enrollClick}
                icon={ArrowForwardIcon("#FFFFFF")}
                className="enroll-button"
                titleClassName="explore-button-text"
              />
            )}
            <SubmitButton
              title={t("EXPLORE_LEARNING_PATH")}
              id="explore-learning-path-id"
              handleClick={handleClick}
              icon={ArrowForwardIcon("#FFFFFF")}
              className="explore-button"
              titleClassName="explore-button-text"
            />
          </div>
          {isSponsored && <IsSponsoredMessage isBlack={true} />}
        </div>
        {showQuickView && (
          <div className="collapse-button">
            <span className="collapse-title">{opened ? t("CLOSE") : t("QUICK_VIEW")}</span>
            <CloseIconButton
              className="plus-icon"
              onClick={() => {
                opened ? setOpened(false) : handleQuickViewClick();
              }}
              icon={opened ? <img src={MinusSmallIcon} alt="plus-icon" /> : <img src={PlusSmallIcon} alt="plus-icon" />}
            />
          </div>
        )}
      </div>
      <Collapse in={opened} timeout="auto" unmountOnExit>
        <CardContent className="content">
          <div className="quick-view">
            <div className="separator" />
            <div className="learning-activities">
              {learningActivities?.slice(0, 5)?.map((activity: any, index: number) => {
                const item = activity?.courses || activity?.exams || activity?.labs;
                return !isEmpty(item) ? (
                  <div className="activity">
                    <LearningPathwayActivity
                      image={item.image || getCDNProtectedURL(images.learningPathwayImg)}
                      activity={item}
                      index={index + 1}
                      isExam={!isEmpty(activity?.exams)}
                      disabled={isEmpty(activity?.courses)}
                    />
                  </div>
                ) : (
                  <></>
                );
              })}
            </div>
            <SubmitButton
              title={t("ENROLL_NOW")}
              id="explore-now-id"
              handleClick={() => loginWithRedirect()}
              icon={ArrowForwardIcon("#FFFFFF")}
              className="explore-now"
              titleClassName="explore-now-button-text"
            />
          </div>
        </CardContent>
      </Collapse>
    </LearningPathwayCardWrapper>
  );
};

export default LearningPathwayCard;
