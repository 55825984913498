import React from "react";
import { UserLocationWrapper } from "./userLocation.styled";
import { LocationIcon } from "&assets/constants/icons";
import { colors } from "&assets/constants/colors";

interface Props {
  location?: string;
  className?: string;
  isWhite?: boolean;
}

const UserLocation = (props: Props) => {
  const { location, className, isWhite } = props;
  return (
    <UserLocationWrapper isWhite={isWhite} className={className}>
      <LocationIcon fill={isWhite ? colors.white : undefined} />
      <div className="location-text">{location}</div>
    </UserLocationWrapper>
  );
};

export default UserLocation;
