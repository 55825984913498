import {Notification} from "&features/notifications/notifications.type";

export const checkUnreadNotifications = (notifications?: Notification[]) => {
  return notifications?.some((notification: Notification) => notification.status === false)
}

export const numberOfUnreadNotifications = (notifications?: Notification[]) => {
  return notifications && notifications?.length > 0
    ? notifications?.filter(
      (notification) => notification.status === false
    ).length > 99 ? 99 : notifications?.filter(
      (notification) => notification.status === false
    ).length
    : 0;
}

