import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useTranslation } from "react-i18next";

import { RootState } from "&store/store";

import { privacyPolicyActions } from "./privacyPolicy.slice";
import { PrivacyPolicyWrapper } from "./privacyPolicy.styled";
import PageHeader from "&components/pageHeader/pageHeader.component";
import Container from "&components/container/container.component";
import CircularLoader from "&components/circularLoader/circularLoader.component";
import HtmlParser from "&components/htmlParser/htmlParser.component";
import { translationSelector } from "&utils/translationSelector";

type ReduxProps = ConnectedProps<typeof connector>;

const PrivacyPolicyComponent = (props: ReduxProps) => {
  const { t } = useTranslation(["privacyPolicy"]);

  const { privacyPolicy, getPrivacyPolicy, loadingContent } = props;

  useEffect(() => {
    getPrivacyPolicy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadingComponent = (
    <div className="loader-wrapper">
      <CircularLoader />
    </div>
  );

  return (
    <PrivacyPolicyWrapper>
      <PageHeader
        title={t("PRIVACY_POLICY")}
        subTitle={translationSelector(privacyPolicy, "subTitle") || ""}
        className="header"
        titleClassName="header-title"
        titleSubtitleWrapper="title-subtitle-wrap"
      />
      <Container className="content-container">
        {loadingContent ? (
          loadingComponent
        ) : (
          <HtmlParser
            description={translationSelector(privacyPolicy, "description")}
          />
        )}
      </Container>
    </PrivacyPolicyWrapper>
  );
};

const mapStateToProps = (state: RootState) => ({
  privacyPolicy: state.privacyPolicy.content,
  loadingContent: state.privacyPolicy.loadingContent,
});

const mapDispatchToProps = {
  getPrivacyPolicy: privacyPolicyActions.getPageContent,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const PrivacyPolicyComponentRedux = connector(PrivacyPolicyComponent);

export { PrivacyPolicyComponentRedux as PrivacyPolicyComponent };
