import { device } from "&assets/constants/responsiveness";
import styled from "styled-components";

export const NewCareerLevelWrapper = styled.div`
  padding: 0 0 24px;
  display: flex;
  align-items: center;
  gap: 24px;

  @media ${device.tablet}, ${device.mobile} {
    width: 96vw;
    overflow-x: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;
