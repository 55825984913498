import React from "react";
import {
  NotificationItem,
  NotificationPopoverContent,
  NotificationPopoverWrapper,
} from "./notificationPopover.styled";
import { useTranslation } from "react-i18next";
import { translationSelector } from "&utils/translationSelector";
import { isEmpty } from "lodash";
import { Notification } from "&features/notifications/notifications.type";
import moment from "moment/moment";
import parse from "html-react-parser";

interface Props {
  open?: boolean;
  id?: string;
  anchorEl: HTMLButtonElement | null;
  onClose: any;
  notifications?: Notification[];
  onViewAllClick?: () => void;
}

const NotificationPopover = (props: Props) => {
  const {
    open = false,
    notifications,
    id = "",
    onClose,
    anchorEl,
    onViewAllClick = () => {},
  } = props;
  const { t } = useTranslation(["login"]);

  const notificationsToShow = notifications ? notifications?.slice(0, 5) : [];
  return (
    <NotificationPopoverWrapper
      open={open}
      id={id}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <NotificationPopoverContent>
        {isEmpty(notifications) ? (
          <NotificationItem read={true}>
            <p className="notification-message no-notifications-message">
              {t("NO_NOTIFICATIONS")}
            </p>
          </NotificationItem>
        ) : (
          notificationsToShow?.map((notification, index) => {
            const isJobNotification =
              notification?.group?.includes("job_notify_group");
            const regex = /\[(\d+)\]/;

            const match: any = isJobNotification
              ? notification.message.match(regex)
              : [];
            const jobId = match[1];
            const updatedNotification = {
              ...notification,
              message: isJobNotification
                ? parse(
                    notification.message.replace(
                      /\[(\d+)\]/,
                      `<a href="/opportunity-details/${jobId}">Click here</a>`
                    )
                  )
                : notification.message,
              messageAr: isJobNotification
                ? parse(
                    notification.messageAr.replace(
                      /\[(\d+)\]/,
                      `<a href="/opportunity-details/${jobId}">انقر هنا</a>`
                    )
                  )
                : notification.messageAr,
            };
            return (
              <NotificationItem read={updatedNotification.status} key={index}>
                <p className="date-text">
                  {moment(updatedNotification.createdAt).format(
                    "DD - MM - YYYY"
                  )}
                </p>
                <p className="notification-message">
                  {translationSelector(updatedNotification, "message")}
                </p>
              </NotificationItem>
            );
          })
        )}
        {!isEmpty(notifications) && (
          <button className="view-all-button" onClick={onViewAllClick}>
            <p className="view-all-text">{t("VIEW_ALL")}</p>
          </button>
        )}
      </NotificationPopoverContent>
    </NotificationPopoverWrapper>
  );
};

export default NotificationPopover;
