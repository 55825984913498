import React, {useState} from "react";
import { NewCareerLevelWrapper } from "./newCareerLevel.styled";
import { Career, Level } from "&features/landingPage/landingPage.type";
import OptionCard from "&components/optionCard/optionCard.component";
import { translationSelector } from "&utils/translationSelector";
import { BusinessOptionIcon } from "&assets/constants/icons";
import { landingPageActions } from "&features/landingPage/landingPage.slice";
import { assessmentActions } from "&features/assessment/assessment.slice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import VideoModal from "&components/modals/videoModal/videoModal.component";
import { TrackGoogleAnalyticsEvent } from "&utils/google-analytics/googleAnalytics";

interface Props {
  level?: Level;
  levelIndex: number;
}

const createLinkId = (name: string, tier?: string) => {
  return `${name.toLowerCase().replaceAll(" ", "-")}-${
    tier?.toLowerCase()?.replaceAll(" ", "-") || "tier"
  }`;
};
const NewCareerLevel = (props: Props) => {
  const { level, levelIndex } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const careers: Career[] = level?.careers || [];

  const [showVideo, setShowVideo] = useState<{
    show: boolean;
    career?: Career;
  }>({ show: false, career: undefined });
  const handleClickVideo = (career: Career) => {
    setShowVideo({ show: true, career: career });
  };
  const handleCloseVideo = () => {
    setShowVideo({ show: false, career: undefined });
  };
  const handleStartAssessment = (selectedCareer: string, id: number) => {
    dispatch(
      landingPageActions.setLandingPage({
        selectedCareerPath: selectedCareer,
      })
    );
    dispatch(assessmentActions.reset());
    TrackGoogleAnalyticsEvent({
      category: "Home Page Events",
      action: "start_assessment_clicked",
      label: selectedCareer,
    });
    navigate(`/assessment?career=${selectedCareer}&id=${id}`);
  };

  const handleViewDetails = (selectedCareer: string, id: number) => {
    TrackGoogleAnalyticsEvent({
      category: "Home Page Events",
      action: "view_career_details_clicked",
      label: selectedCareer,
    });
    navigate(`/career-details/${id}`);
  };
  return (
    <NewCareerLevelWrapper>
      {careers.map((career: Career, index: number) => (
        <OptionCard
          index={index}
          onStartAssessmentClick={() =>
            handleStartAssessment(
              createLinkId(career.name, level?.name),
              career.id
            )
          }
          onViewDetailsClick={() =>
            handleViewDetails(createLinkId(career.name, level?.name), career.id)
          }
          level={translationSelector(level, "alias")}
          careerIndex={levelIndex}
          career={career}
          id={createLinkId(career.name, level?.name)}
          key={career.id}
          onClick={() => {}}
          title={translationSelector(career, "name")}
          icon={career.icon || <BusinessOptionIcon />}
          description={translationSelector(career, "shortDescription")}
          jobsNumber={career.jobsCount}
          onVideoClick={() => handleClickVideo(career)}
          thumbnailUrl={career.thumbnail || career.image}
        />
      ))}
      {
        <VideoModal
          onClose={handleCloseVideo}
          open={showVideo?.show}
          career={showVideo?.career}
        />
      }
    </NewCareerLevelWrapper>
  );
};

export default NewCareerLevel;
