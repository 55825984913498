import React, { useState } from "react";
import { DashboardBannerWrapper } from "./dashboardBanner.styled";
import Banner from "&components/banner/banner.component";
import { includes, isEmpty } from "lodash";
import getCDNProtectedURL from "&utils/getCDNProtectedImageSource";
import { images } from "&assets/constants/images-urls";
import { RootState } from "&store/store";
import { useDispatch, useSelector } from "react-redux";
import { Carousel } from "react-responsive-carousel";
import { CarouselIndicator } from "&features/dashboard/screens/dashboardComponent/dashboardComponent.styled";
import RightArrow from "&assets/images/rightArrow.svg";
import LeftArrow from "&assets/images/leftArrow.svg";
import { useMediaQuery } from "react-responsive";
import { size } from "&assets/constants/responsiveness";
import { Notification } from "&features/notifications/notifications.type";
import { notificationsActions } from "&features/notifications/notifications.slice";
import { translationSelector } from "&utils/translationSelector";
import i18n from "&config/i18n";

interface Props {}

const DashboardBanner = (props: Props) => {
  const dispatch = useDispatch();
  const bannerNotifications: Notification[] | undefined = useSelector(
    (state: RootState) => state.notifications.bannerNotifications
  );
  const isMobile = useMediaQuery({ maxWidth: size.mobile });
  const isTablet = useMediaQuery({
    minWidth: size.mobile,
    maxWidth: size.tablet,
  });

  const [selectedSlide, setSelectedSlide] = useState(0);

  const banners = bannerNotifications?.filter((notification) => !notification.status);

  const bannersSelected = banners
    ? banners?.map((banner: any) => {
        let obj: any = {
          id: banner.id,
          title: translationSelector(banner, "title"),
          description: translationSelector(banner, "message"),
          buttonText: undefined,
          handleButtonClick: () => {},
          image: banner?.image ?? getCDNProtectedURL(images.learningPathwayImg),
          closeButtonAction: () => {
            setSelectedSlide(selectedSlide > 0 ? selectedSlide - 1 : 0);
            dispatch(notificationsActions.markBannerAsRead(banner.id));
          },
        };
        if (includes(banner.group, "suggested_user")) {
          obj["descriptionHyperlink"] = `/learning-pathway/${banner.group.split("_")[2]}`;
        }

        return obj;
      })
    : [];

  const notifications = bannersSelected;

  const isArabic = i18n.language === "ar";

  if (isEmpty(notifications)) {
    return null;
  }

  return (
    <DashboardBannerWrapper>
      <Carousel
        swipeable={true}
        showArrows={!isTablet && !isMobile}
        showThumbs={false}
        showStatus={false}
        selectedItem={selectedSlide}
        onChange={(index) => setSelectedSlide(index)}
        className="carousel-banner-wrapper"
        preventMovementUntilSwipeScrollTolerance={true}
        swipeScrollTolerance={50}
        showIndicators={true}
        renderArrowNext={(onClickHandler, hasNext, label) =>
          hasNext && (
            <div onClick={onClickHandler} title={label} className="arrows next">
              <img src={RightArrow} alt="" />
            </div>
          )
        }
        renderArrowPrev={(onClickHandler, hasPrev, label) =>
          hasPrev && (
            <div onClick={onClickHandler} title={label} className="arrows prev">
              <img src={LeftArrow} alt="" />
            </div>
          )
        }
        renderIndicator={(onClickHandler, isSelected, index, label) => {
          return <CarouselIndicator selected={isSelected} onClick={onClickHandler} key={index} />;
        }}
      >
        {notifications?.map((banner, index) => (
          <Banner
            key={index}
            onCloseButtonClick={banner.closeButtonAction}
            title={banner.title}
            description={banner.description}
            image={banner.image}
            buttonText={banner.buttonText}
            handleButtonClick={banner.handleButtonClick}
            direction={isArabic ? "rtl" : ""}
            descriptionHyperlink={banner.descriptionHyperlink}
          />
        ))}
      </Carousel>
    </DashboardBannerWrapper>
  );
};

export default DashboardBanner;
