import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";

import { careerMatchingActions } from "./careerMatching.slice";
import {
  CareerMatchingWrapper,
  PageHeaderWrapper,
} from "./careerMatching.styled";
import Container from "&components/container/container.component";
import PageTitleSubtitle from "&components/pageTitleSubtitle/pageTitleSubtitle.component";
import { HeaderLines } from "&assets/constants/icons";
import { size } from "&assets/constants/responsiveness";
import MatchingCareersCards from "&components/matchingCareersCards/matchingCareersCards.component";
import LearningPathsContainer from "&components/learningPathsContainer/learningPathsContainer.component";
import MatchingOpportunities from "&components/matchingOpportunities/matchingOpportunities.component";
import buildAssessmentBody from "&utils/buildAssessmentObject";
import { RootState } from "&store/store";
import { returnIndex } from "&utils/returnIndex";
import { opportunitiesListingActions } from "&features/opportunitiesListing/opportunitiesListing.slice";

type ReduxProps = ConnectedProps<typeof connector>;

const CareerMatchingComponent = (props: ReduxProps) => {
  const { t } = useTranslation(["careerMatching"]);

  const {
    matchingCareers,
    loadingLearningPaths,
    getCareerLearningPaths,
    assessmentFormState,
    settings,
    learningPaths,
    user,
    getJobs,
    selectedCountry,
  } = props;

  const [selectedId, setSelectedId] = useState(matchingCareers?.career?.id);
  const navigate = useNavigate();

  const [cookieEQ1, setCookieEQ1] = useState("null");

  useEffect(() => {
    const isEq1 = localStorage.getItem("iseq1");
    if (isEq1) {
      setCookieEQ1(isEq1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("iseq1")]);

  useEffect(() => {
    if (!matchingCareers?.career) {
      navigate("/");
    }
    getLearningPaths(selectedId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("language")]);

  useEffect(() => {
    getJobs({
      countryId: selectedCountry.value,
      page: 1,
      limit: 9,
      careerId: selectedId,
      sort: "datePosted",
      dir: "DESC",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedId, selectedCountry, localStorage.getItem("language")]);

  const getLearningPaths = (careerId?: number) => {
    getCareerLearningPaths({
      skills: buildAssessmentBody(assessmentFormState),
      careerId: Number(careerId),
      educationalScore: returnIndex(
        Number(assessmentFormState.education_level),
        settings.education_level
      ),
    });
  };

  const onSelectCareer = (careerId: number, name: string) => {
    careerId && setSelectedId(careerId);
    getLearningPaths(careerId);
  };

  const isMobile = useMediaQuery({ maxWidth: size.mobile });

  const renderPageHeader = () => {
    return (
      <PageHeaderWrapper>
        <HeaderLines
          className={"header-lines"}
          width={isMobile ? 265 : 396}
          height={isMobile ? 254 : 380}
        />
        <Container className="container">
          <PageTitleSubtitle
            className={"title-subtitle-wrapper"}
            title={t("HERE_IS_YOUR_PERSONALIZED_MATCHING_SCORE")}
            subTitle={t("YOUR_PERSONALIZED_ROADMAP")}
          />
        </Container>
      </PageHeaderWrapper>
    );
  };

  return (
    <CareerMatchingWrapper>
      {renderPageHeader()}
      <Container>
        <MatchingCareersCards
          selectedId={selectedId}
          isEQ1={user?.email ? !!user?.isEQ1 : !!(cookieEQ1 === "true")}
          onSelectCareer={onSelectCareer}
          matchingCareers={matchingCareers}
        />
      </Container>
      <LearningPathsContainer
        isCareerMatching={true}
        isLoading={loadingLearningPaths}
        learningPaths={learningPaths}
      />
      <MatchingOpportunities
        title={t("SUGGESTED_JOB_OPPORTUNITIES")}
        allButtonText={t("VIEW_ALL_OPPORTUNITIES")}
        onAllButtonClick={() => navigate("/opportunities")}
      />
    </CareerMatchingWrapper>
  );
};

const mapStateToProps = (state: RootState) => ({
  matchingCareers: state.careerMatching.matchingCareers,
  loadingLearningPaths: state.careerMatching.loadingLearningPaths,
  learningPaths: state.careerMatching.learningPaths,
  assessmentFormState: state.assessment.assessmentFormState,
  settings: state.global.settings,
  user: state.login.user,
  selectedCountry: state.landingPage.selectedCountry,
});

const mapDispatchToProps = {
  getCareerLearningPaths: careerMatchingActions.getCareerLearningPaths,
  getJobs: opportunitiesListingActions.getJobs,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
// @ts-ignore
const CareerMatchingComponentRedux = connector(CareerMatchingComponent);

// @ts-ignore
export { CareerMatchingComponentRedux as CareerMatchingComponent };
