import React from "react";
import { SupportersWrapper } from "./supporters.styled";

import Unicef from "&assets/images/unicef.png";
import WorldBank from "&assets/images/world-bank-skill-up.png";
import ForwardMena from "&assets/images/forwardMena.png";
import Netherlands from "&assets/images/netherlands2.svg";
import Container from "&components/container/container.component";
import { PageSection } from "&features/landingPage/landingPage.type";
import { translationSelector } from "&utils/translationSelector";

interface Props {
  data: PageSection;
}

const Supporters = (props: Props) => {
  const { data } = props;
  const images = [
    {
      title: "unicef",
      image: Unicef,
      isLarge: false,
    },
    {
      title: "worldbank-skill-up",
      image: WorldBank,
      isLarge: false,
      isWide: true,
    },
    {
      title: "netherlands",
      image: Netherlands,
      isLarge: true,
    },
    {
      title: "forwardmena",
      image: ForwardMena,
      isLarge: false,
    },
  ];
  return (
    <SupportersWrapper>
      <Container>
        <div className="content">
          <div className="title-subtitle-wrapper">
            <p className="title">{translationSelector(data, "title")}</p>
            <p className="subtitle">{translationSelector(data, "content")}</p>
          </div>

          <div className="supporters-cards">
            {images.map((item, index) => {
              return (
                <div
                  key={index}
                  style={item.isWide ? { width: "220px" } : {}}
                  className={`image-card ${item.isLarge ? "large" : ""}`}
                >
                  <img style={item.isWide ? { width: "200px" } : {}} src={item.image} alt="" />
                </div>
              );
            })}
          </div>
        </div>
      </Container>
    </SupportersWrapper>
  );
};

export default Supporters;
