import React, { useEffect, useState } from "react";
import { UpdateProfileBody } from "&features/login/login.type";
import inputSelector from "&utils/ui/inputSelector";
import { ProfileModalInnerContent } from "&components/profile/edit/profileEditModel/profileEditModal.styled";
import SkillOptionTag from "&components/skillOptionTag/skillOptionTag.component";
import { AddIcon } from "&assets/constants/icons";
import { useTranslation } from "react-i18next";

interface Props {
  initialValues?: string;
  submit?: number;
  updateProfile: (body: UpdateProfileBody) => void;
}

const ProfileAddInterests = (props: Props) => {
  const { updateProfile, submit, initialValues } = props;

  const [interests, setInterests] = useState<string[]>([]);
  const [interestInput, setInterestInput] = useState<string>("");
  const { t } = useTranslation(["profile"]);

  useEffect(() => {
    const initial: string[] =
      initialValues?.length && initialValues?.length > 0
        ? initialValues?.split(",")
        : [];
    setInterests(initial);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddInterest = (interest: string) => {
    interest?.length > 0 && setInterests([...interests, interest.trim()]);
    setInterestInput("");
  };

  const handleSubmit = () => {
    updateProfile({
      interests: interests.join(","),
    });
  };

  useEffect(() => {
    submit && submit > 0 && handleSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submit]);

  return (
    <ProfileModalInnerContent>
      <div className="input-add-button-wrapper">
        {inputSelector(
          {
            id: "interest-hobbies-input",
            label: t("INTERESTS_AND_HOBBIES"),
            className: "input-width-limit",
            placeHolder: t("TYPE_AN_INTEREST_OR_HOBBY"),
            type: "text",
            value: interestInput,
            onEnter: () => handleAddInterest(interestInput),
            handleChange: (value) => setInterestInput(value),
          },
          0
        )}
        <div
          className="icon-wrapper"
          onClick={() => handleAddInterest(interestInput)}
        >
          <AddIcon size={30} />
        </div>
      </div>

      <div className="interest-tags-wrapper">
        {interests?.map((interest: string, index: number) => (
          <SkillOptionTag
            id={index}
            className="interest-wrapper"
            data-tag-index={index}
            tabIndex={-1}
            title={interest}
            key={index}
            onDelete={() =>
              setInterests(interests.filter((item) => item !== interest))
            }
          />
        ))}
      </div>
    </ProfileModalInnerContent>
  );
};

export default ProfileAddInterests;
