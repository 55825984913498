import styled from "styled-components";

export const LearningActivityTrackingWrapper = styled.div`
  margin-top: 24px;

  .voucher-modal {
    max-height: 80% !important;
    display: block !important;
  }
`;

export const CompleteOneCourseWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  .description-error-text {
    font-weight: 500;
    font-size: 19px;
    line-height: 160%;
  }
`;
