import styled from "styled-components";
import { device } from "&assets/constants/responsiveness";

export const BenefitsOfWrapper = styled.div`
  position: relative;
  .background-frame {
    opacity: 0.3;
    position: absolute;
    left: 0;
    top: 100px;
    z-index: -1;
  }

  .benefits-container {
    padding: 48px 64px;
    box-sizing: border-box;
    width: initial;
  }

  .title-about-button-wrapper {
    margin-bottom: 80px;
  }

  .benefits-grid {
    margin-left: 113px;
    display: grid;
    row-gap: 96px;
    column-gap: 20px;
    grid-template-columns: auto auto auto;
  }

  @media ${device.tablet} {
    .benefits-container {
      padding: 48px 16px;
    }

    .benefits-grid {
      margin-left: 50px;
      row-gap: 40px;
      column-gap: 18px;
      grid-template-columns: auto auto;
    }

    .background-frame {
      display: none;
    }
  }
  @media ${device.mobile} {
    .benefits-container {
      padding: 48px 16px;
    }

    .benefits-grid {
      margin-left: 0;
      row-gap: 40px;
      column-gap: 18px;
      grid-template-columns: auto auto;
    }

    .background-frame {
      display: none;
    }
  }
`;
