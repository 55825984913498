import React, { useEffect, useState } from "react";
import { BundleCardsWrapper, ExamsWrapper, Tab, TableContentWrapper, TabsWrapper } from "./bundles.styled";
import SubmitButton from "&components/buttons/submitButton/submitButton.component";
import { useTranslation } from "react-i18next";
import {
  ABORTED,
  APPROVED,
  AWAITING_ACTIVATION,
  AWAITING_PAYMENT,
  AWAITING_PAYMENT_APPROVAL,
  Bundles,
  CURRENT,
  HISTORY,
  REJECTED,
} from "&features/bundles/bundles.type";
import BundlesModal from "&components/bundlesModal/bundlesModal.component";
import {
  Button,
  CircularProgress,
  Grid,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  ArrowBackIcon,
  ArrowForwardIcon,
  Cancel,
  CardIcon,
  CashIcon,
  Certificate,
  Upload,
} from "&assets/constants/icons";
import getCDNProtectedURL from "&utils/getCDNProtectedImageSource";
import { images } from "&assets/constants/images-urls";
import { BundlesActions } from "&features/bundles/bundles.slice";
import { useDispatch, useSelector } from "react-redux";
import CustomModal from "&components/custom-modal/custom-modal.component";
import moment from "moment";
import DragDropUploadFile from "&components/inputs/dragDropUploadFile/dragDropUploadFile.component";
import uploadImageToS3 from "&utils/uploadImageToS3";
import { RootState } from "&store/store";
import CircularLoader from "&components/circularLoader/circularLoader.component";
import Banner from "&components/banner/banner.component";
import { colors } from "&assets/constants/colors";

const BundleCards = ({ bundles, fetch, showBundlesBanner }: any) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation(["bundles"]);
  const [open, setOpen] = useState<boolean>(false);
  const user = useSelector((state: RootState) => state.login.user);
  const loading = useSelector((state: RootState) => state.bundles.loadingBundles);
  const orderId = useSelector((state: RootState) => state.bundles.orderId);

  const sessionId = useSelector((state: RootState) => state.bundles.sessionId);
  const [chosenTransactionTab, setChosenTransactionTab] = useState<string>("");
  const [clickedBundle, setClickedBundle] = useState<any>({});
  const [openCancelationDialog, setOpenCancelationDialog] = useState(false);
  const [selectedTransactionId, setSelectedTransactionId] = useState(null);
  const [clickedBundleTransactions, setClickBundlesTransactions] = useState([]);
  const [transactionModalOpen, setTransactionModalOpen] = useState(false);
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState("");
  const [paymentMethod, setPaymentMethod] = useState<string | undefined>("Cash");
  const [examModalOpen, setExamModalOpen] = useState(false);

  const handleUploadFile = async (transactionId: number) => {
    if (!selectedFile) {
      setErrorMessage("File is required.");
      return;
    }
    try {
      const paymentReceipt = await uploadImageToS3({
        value: selectedFile,
        userId: user?.auth0Id,
        compress: false,
      });

      const uploadBody = {
        paymentReceipt,
        transactionId,
      };

      const updatedTransactions: any = clickedBundleTransactions.map((transaction: any) => {
        if (transaction.id === transactionId) {
          return { ...transaction, status: AWAITING_PAYMENT_APPROVAL };
        }
        return transaction;
      });

      setClickBundlesTransactions(updatedTransactions);
      setUploadModalOpen(false);
      dispatch(BundlesActions.updateTransactionReceipt(uploadBody));
    } catch (error) {
      setErrorMessage("An error occurred while uploading the file.");
    }
  };

  useEffect(() => {
    if (clickedBundle && clickedBundle.transactions) {
      setClickBundlesTransactions(clickedBundle.transactions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clickedBundle.transactions]);

  const paymentMethods: any = [
    { id: "Card", label: t("CARD"), logo: <CardIcon /> },
    { id: "Cash", label: t("CASH"), logo: <CashIcon /> },
  ];

  const handleCloseCancelationDialog = () => {
    setOpenCancelationDialog(false);
  };

  const handleConfirmCancelation = async (transactionId: number) => {
    await dispatch(BundlesActions.cancelTransaction({ transactionId }));
    fetch();
    setOpenCancelationDialog(false);
    const updatedTransactions: any = clickedBundleTransactions.map((transaction: any) => {
      if (transaction.id === transactionId) {
        return { ...transaction, status: ABORTED };
      }
      return transaction;
    });
    setClickBundlesTransactions(updatedTransactions);
    setSelectedTransactionId(null);
  };

  const handleCloseTransactionModal = () => {
    setTransactionModalOpen(false);
    setChosenTransactionTab("");
  };
  const handleCloseExamModal = () => {
    setExamModalOpen(false);
  };
  const [waitingPayment, setWaitingPayment] = useState(false);
  const [step, setStep] = useState(0);
  const handleOpenModal = (bundle: any) => {
    setClickedBundle(bundle);
    setOpen(true);
  };

  useEffect(() => {
    if (sessionId) {
      setWaitingPayment(true);
      // @ts-ignore
      // eslint-disable-next-line no-use-before-define
      Checkout && Checkout.configure({ session: { id: sessionId } });
      setTimeout(() => {
        // @ts-ignore
        Checkout.showPaymentPage();
        setWaitingPayment(false);
      }, 2000);
    }
  }, [sessionId]);
  useEffect(() => {
    if (orderId) {
      dispatch(BundlesActions.verifyTransactionApiCall({ orderId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const actionsStepper: any = {
    0: () => setStep(step + 1),
    1: async () => {
      if (paymentMethod === "Card") {
        await dispatch(
          BundlesActions.checkoutTransactionApiCall({
            poolId: clickedBundle?.id,
          })
        );
      } else {
        await dispatch(
          BundlesActions.submitTransaction({
            poolId: clickedBundle?.id,
            paymentMethod,
          })
        );
        fetch();
        setStep(0);
        setClickedBundle({});
        setPaymentMethod("");
        setOpen(false);
      }
    },
  };

  const handleTransactionsActions = (step: number) => {
    actionsStepper[step]();
  };

  const handlePaymentMethodChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPaymentMethod(e.target.value);
  };

  const handleGoPrevStep = () => {
    setStep(step - 1);
  };
  const handleStepHeaderContent = (title: string) => {
    return (
      <Grid display="flex" alignItems="center" gap={3}>
        <Typography onClick={handleGoPrevStep}>
          {i18n.language.includes("en") ? (
            <ArrowBackIcon fill={colors.primary} />
          ) : (
            ArrowForwardIcon(colors.primary, 20)
          )}{" "}
        </Typography>{" "}
        <p>{title}</p>
      </Grid>
    );
  };

  const handleCloseUploadModal = () => {
    setUploadModalOpen(false);
  };

  const paymentMethodContents: any = {
    Cash: {
      title: handleStepHeaderContent(t("HOW_TO_PAY_NAMMISKILLS")),
      content: (
        <div>
          <img className="bundle-cash-img" src={getCDNProtectedURL(images.bundlesPayCashImage)} alt="" />
          <ul className="bundle-list">
            <li className="bundle-list-item">
              {t("PAY_CASH_INSTRUCTION")}
              <br />
              {t("PAY_CASH_INSTRUCTION_TWO")}
              <br />
              {t("PAY_CASH_INSTRUCTION_THREE")}
            </li>
          </ul>
        </div>
      ),
    },
    Card: {
      title: handleStepHeaderContent(t("PAY_CARD_INSTRUCTION")),
      content: (
        <div>
          <img className="bundle-cash-img" src={getCDNProtectedURL(images.bundlesPayCashImage)} alt="" />
          <ul className="bundle-list">
            <li className="bundle-list-item">{t("PAY_CARD_INSTRUCTION_TWO")}</li>
          </ul>
        </div>
      ),
    },
  };
  const modalContents: any = [
    {
      title: t("SELECT_PAYMENT_OPTION"),
      content: (
        <div>
          <RadioGroup>
            <div className="radio-group">
              {paymentMethods.map((method: any) => (
                <div key={method.id}>
                  <label className="custom-radio">
                    <input
                      type="radio"
                      value={method.id}
                      onChange={handlePaymentMethodChange}
                      checked={paymentMethod === method.id}
                    />
                    <span className="radio-icon">{method.logo}</span>
                  </label>
                  <center className="radio-label">
                    <strong>{method.label}</strong>
                  </center>
                </div>
              ))}
            </div>
          </RadioGroup>
        </div>
      ),
    },
    {
      title: paymentMethod ? paymentMethodContents[paymentMethod].title : null,
      content: paymentMethod ? paymentMethodContents[paymentMethod].content : null,
    },
  ];

  const handleClickTransactionTab = (chosenTab: string) => {
    setChosenTransactionTab(chosenTab);
  };

  const loadingComponent = (
    <div className="loader-wrapper">
      <CircularLoader />
    </div>
  );

  const historyValues = [REJECTED, ABORTED, APPROVED];
  const rejectedValues = [REJECTED, ABORTED];
  const awaitingValues = [AWAITING_PAYMENT, AWAITING_PAYMENT_APPROVAL, AWAITING_ACTIVATION];
  const transactionsTableContent = (data: any) => {
    return (
      <TableContentWrapper>
        <Grid display="flex" justifyContent="center">
          {loading ? (
            loadingComponent
          ) : (
            <TableContainer className="table-wrapper" style={{ maxHeight: 600, overflow: "auto" }}>
              <Table sx={{ minWidth: 650, maxHeight: 300, overflow: "auto" }} size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    {[
                      "ID",
                      "Status",
                      "Amount",
                      "Active",
                      "Reason",
                      ...(chosenTransactionTab === CURRENT ? ["Action"] : []),
                    ].map((value: string) => (
                      <TableCell width={"fit-content"} align="center">
                        {t(value.toLocaleUpperCase())}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.length > 0 ? (
                    data.map((row: any) => {
                      const isAwaitingPayment = row.status === AWAITING_PAYMENT;
                      const hasHistoryValue = historyValues.includes(row.status);
                      return (
                        <TableRow
                          key={row.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="center" component="th" scope="row">
                            {row.transactionId}
                          </TableCell>
                          <TableCell align="center" component="th" scope="row">
                            {row.status?.replaceAll("_", " ")}
                          </TableCell>
                          <TableCell align="center">{row.amount}$</TableCell>
                          <TableCell align="center">{row.active ? "Yes" : "No"}</TableCell>
                          <TableCell align="center">{row.reason}</TableCell>
                          {!hasHistoryValue && (
                            <TableCell align="center">
                              <Grid display="flex" alignItems="center" justifyContent="flex-end" gap={2}>
                                {row?.status === AWAITING_PAYMENT && (
                                  <Tooltip title={t("UPLOAD_RECEIPT")}>
                                    <span
                                      onClick={() => {
                                        setUploadModalOpen(true);
                                        setSelectedTransactionId(row.id);
                                      }}
                                    >
                                      <Upload />
                                    </span>
                                  </Tooltip>
                                )}
                                {isAwaitingPayment && (
                                  <Tooltip title={t("CANCEL_TRANSACTION")}>
                                    <span
                                      onClick={() => {
                                        setOpenCancelationDialog(true);
                                        setSelectedTransactionId(row.id);
                                      }}
                                    >
                                      <Cancel />
                                    </span>
                                  </Tooltip>
                                )}
                              </Grid>
                            </TableCell>
                          )}
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6}>
                        <Grid display="flex" justifyContent="center" alignItems="center" padding={2}>
                          <Typography>{t("NO_TRANSACTIONS_FOUND")}</Typography>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>
      </TableContentWrapper>
    );
  };
  const transactionsTabsContent: any = {
    current: transactionsTableContent(
      clickedBundleTransactions?.filter((transaction: any) => !historyValues.includes(transaction.status))
    ),
    history: transactionsTableContent(
      clickedBundleTransactions?.filter((transaction: any) => !awaitingValues.includes(transaction.status))
    ),
  };
  return (
    <BundleCardsWrapper showBanner={showBundlesBanner}>
      {showBundlesBanner && (
        <Banner
          width="95%"
          title={t("WARNING")}
          description={t("ATTENTION")}
          image={images.warningBundle}
          buttonText={""}
          handleButtonClick={() => {}}
        />
      )}
      {bundles?.map((bundle: Bundles, index: number) => {
        const { status, image, name, discountedPrice, price, exams, type, instructions, expiryDate, paymentStatus } =
          bundle;
        const isSubscription = type === "subscription";
        const isVoucher = type === "voucher";
        const isAwaiting = paymentStatus === "awaiting";
        const linkedInHasActive = bundle?.transactions?.filter((trans: any) => !rejectedValues.includes(trans.status));
        const voucherHasHistory = bundle?.transactions?.filter((trans: any) => {
          return awaitingValues.includes(trans.status);
        });
        const showPurchaseButtonForLinkedIn = linkedInHasActive.length === 0 && isSubscription;
        const showPurchaseButtonForVoucher = isVoucher && voucherHasHistory.length === 0;
        const showPurchaseButton = showPurchaseButtonForLinkedIn || showPurchaseButtonForVoucher;

        const isPending = status === "not approved" && isSubscription && !showPurchaseButton;
        const isActive = status === "approved" && isSubscription;

        const cardClassName = `bundle-card
         ${isPending ? "bundle-card-pending" : isActive ? "bundle-card-active" : "bundle-card-not-active"}`;
        const priceDurationClassName = `${
          isActive ? "bundle-price-duration-active" : isPending ? "bundle-price-duration-pending" : ""
        }`;
        const paymentStatusClassName = `${
          isActive
            ? "payment-status-wrapper-active"
            : isPending
            ? "payment-status-wrapper-pending"
            : "payment-status-wrapper-not-active"
        }`;
        const bodyLine = isActive ? "active" : isPending ? "pending" : "";
        return (
          <Grid onClick={() => setClickedBundle(bundle)} position="relative" className={cardClassName} key={index}>
            <div className="card-image-wrapper">
              <img src={image} alt={"Bundle Img"} />
              {exams && exams.length > 0 && (
                <span className="info">
                  <Tooltip className="logo-info-wrapper" color="" title={t("VIEW_RELATED_CERTIFICATES")}>
                    <Typography
                      color="#fff"
                      position="absolute"
                      right={10}
                      bottom={2}
                      top={2}
                      margin="auto"
                      fontSize={12}
                      onClick={() => setExamModalOpen(true)}
                    >
                      <span>{t("CERTIFICATION")}</span>
                      <Certificate />
                    </Typography>
                  </Tooltip>
                </span>
              )}
            </div>
            <div className="bundle-card-conent">
              <div className="bundle-price-payment-status">
                <p className="bundle-price">
                  <p className="original-price">
                    {price}
                    <hr />
                  </p>
                  <div className={priceDurationClassName}>
                    <p>{discountedPrice}</p>
                  </div>
                </p>
                {expiryDate && type === "subscription" && (
                  <div className={paymentStatusClassName}>
                    <span>
                      {isActive ? t("ACTIVE_TILL") : t("EXPIRES_AT")} {moment(expiryDate).format("DD/MM/YYYY")}
                    </span>
                  </div>
                )}
              </div>
              <hr className={bodyLine} />
              <Grid
                display="flex"
                alignItems="center"
                justifyContent="center"
                gap={2}
                position="relative"
                minHeight={60}
              >
                <p className="bundle-level">{name}</p>
              </Grid>
              <hr className={bodyLine} />
              <div className="instruction-wrapper">
                {instructions && <div dangerouslySetInnerHTML={{ __html: instructions }} />}
              </div>
              <div className="card-buttons-wrapper">
                {showPurchaseButton && (
                  <SubmitButton
                    id={"purchase-bundle"}
                    className="purchase-bundle-button"
                    disable={waitingPayment || loading}
                    title={waitingPayment ? <CircularProgress size={20} /> : t("PURCHASE_BUNDLE")}
                    handleClick={() => handleOpenModal(bundle)}
                  />
                )}
                {bundle?.transactions && bundle?.transactions.length > 0 && (
                  <SubmitButton
                    id={"view-transaction-now-button"}
                    className="purchase-bundle-button"
                    title={isAwaiting ? t("PAYMENT_STATUS") : t("PAYMENT_HISTORY")}
                    handleClick={async () => {
                      fetch();
                      setChosenTransactionTab(isAwaiting ? CURRENT : HISTORY);
                      setTransactionModalOpen(true);
                    }}
                  />
                )}
              </div>
            </div>
          </Grid>
        );
      })}
      <CustomModal
        handleClose={handleCloseExamModal}
        open={examModalOpen}
        title={t("RELATED_CERTIFICATES")}
        titleClassName={"title"}
      >
        <ExamsWrapper>
          <ul>
            {clickedBundle?.exams?.map(({ name }: { name: string }) => (
              <li>{name}</li>
            ))}
          </ul>
        </ExamsWrapper>
      </CustomModal>
      <CustomModal
        handleClose={handleCloseTransactionModal}
        open={transactionModalOpen}
        title={t("TRANSACTIONS")}
        titleClassName={"title"}
      >
        <TabsWrapper>
          <Tab
            className={`${chosenTransactionTab === CURRENT ? "active" : ""}`}
            onClick={() => handleClickTransactionTab(CURRENT)}
          >
            <span className="shadow"></span>
            <span className="edge"></span>
            <span className="front text">{t("CURRENT")}</span>
          </Tab>
          <Tab
            className={`${chosenTransactionTab === HISTORY ? "active" : ""}`}
            onClick={() => handleClickTransactionTab(HISTORY)}
          >
            <span className="shadow"></span>
            <span className="edge"></span>
            <span className="front text">{t("HISTORY")}</span>
          </Tab>
        </TabsWrapper>
        {transactionsTabsContent[chosenTransactionTab]}
      </CustomModal>
      <CustomModal
        handleClose={handleCloseUploadModal}
        open={uploadModalOpen}
        title={t("UPLOAD_RECEIPT")}
        titleClassName={"title"}
      >
        <DragDropUploadFile
          showImage={false}
          label={""}
          description={t("DRAG_AND_DROP_YOUR_RECEIPT_HERE")}
          value={selectedFile}
          handleChange={(file: any) => {
            console.log("file", file);
            setSelectedFile(file);
            setErrorMessage("");
          }}
          removePhoto={() => {
            setSelectedFile(undefined);
          }}
          error={errorMessage}
        />
        <Grid display="flex" paddingTop={2} justifyContent="center">
          <SubmitButton
            id={"sign-up-now-button"}
            loading={loading}
            className="purchase-bundle-button"
            title={t("SUBMIT")}
            handleClick={async () => {
              selectedTransactionId && (await handleUploadFile(selectedTransactionId));
              setSelectedFile(undefined);
            }}
          />
        </Grid>
      </CustomModal>
      <BundlesModal
        open={open}
        step={step}
        loading={loading || waitingPayment}
        disableButton={!paymentMethod}
        paymentMethod={paymentMethod ?? ""}
        handleClose={(event: any) => {
          event.stopPropagation();
          setOpen(false);
          setStep(0);
          setPaymentMethod("");
          setClickedBundle({});
        }}
        action={() => handleTransactionsActions(step)}
        bundle={clickedBundle}
        content={modalContents[step]?.content}
        title={modalContents[step]?.title}
      />
      <CustomModal
        handleClose={handleCloseCancelationDialog}
        open={openCancelationDialog}
        title={t("TRANSACTION_CANCELATION")}
        titleClassName={"title"}
      >
        <Typography id="alert-dialog-slide-description">{t("ARE_YOU_SURE")}</Typography>
        <Grid display="flex" width="100%" justifyContent="flex-end" gap={3} paddingTop={2}>
          <Button onClick={handleCloseCancelationDialog}>{t("DISAGREE")}</Button>
          <Button onClick={() => selectedTransactionId && handleConfirmCancelation(selectedTransactionId)}>
            {t("AGREE")}
          </Button>
        </Grid>
      </CustomModal>
    </BundleCardsWrapper>
  );
};

export default BundleCards;
