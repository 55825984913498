import styled from "styled-components";
import { device } from "&assets/constants/responsiveness";
import { colors } from "&assets/constants/colors";

interface Props {
  selected?: boolean;
}

export const CarouselWrapper = styled.div`
  width: 100%;
  .dashboard-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 140px 25px 140px;
    box-sizing: border-box;
  }
  .alice-carousel{
    .alice-carousel__dots{
      margin: 0 !important;
    }
  }
  @media ${device.mobile}, ${device.tablet} {
      width: 95vw;
      .carousel-banner-wrapper .slider {
        display: flex !important;
        gap: 20px;
      }
      .carousel-banner-wrapper .slide {
        min-width: 96% !important;
      }
    }
  .carousel-banner-wrapper {
    direction: initial;
    width: 100%;

    .carousel * {
      box-sizing: initial;
    }
   
    .arrows {
      ${(props) =>
        props.theme.dir === "rtl" ? "transform: rotate(180deg);" : ""}
      position: absolute;
      z-index: 2;
      top: calc(40% - 15px);
      width: 36px;
      height: 36px;
      cursor: pointer;
    }

    .next {
      ${(props) =>
        props.theme.dir === "rtl" ? "left: 0;" : "right: 0;"}
    }
    .prev {
      ${(props) =>
        props.theme.dir === "rtl" ? "right: 0;" : "left: 0"}
    }
    @media ${device.inBetween} {
      margin: 0 20px;
    }
    @media ${device.mobile}, ${device.tablet} {
      width: 95vw;
    }
  }
`;
export const CarouselIndicator = styled.div<Props>`
  width: 6px;
  height: 6px;
  background: ${colors.primary};
  border-radius: 50%;
  margin: -37px 5px 0 5px;
  opacity: ${(props) => (props.selected ? 1 : 0.3)};

  @media ${device.mobile}, ${device.tablet} {
    margin: -20px 5px 0 5px;
  }
`;
