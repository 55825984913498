import { API } from "aws-amplify";
import { RES_COURSES, RES_LEARNING_PATHWAY, RES_EXAMS } from "&store/resources";
import { GetPageListingQuery } from "./listingPage.type";
import { definedObjectBuilder } from "&utils/definedObjectBuilder";

const getAllCoursesApi = async (
  body: GetPageListingQuery,
  isLoggedIn: boolean
) => {
  let URL = isLoggedIn ? `/auth${RES_COURSES}` : `${RES_COURSES}`;
  const myInit: any = {
    queryStringParameters: definedObjectBuilder(body),
  };
  return API.get(process.env.REACT_APP_NAME || "", URL, myInit);
};

const getAllLearningPathwaysApi = async (
  body: GetPageListingQuery,
  isLoggedIn: boolean
) => {
  let URL = isLoggedIn
    ? `/auth${RES_LEARNING_PATHWAY}`
    : `${RES_LEARNING_PATHWAY}`;
  const myInit: any = {
    queryStringParameters: definedObjectBuilder(body),
  };
  return API.get(process.env.REACT_APP_NAME || "", URL, myInit);
};

const getAllExamsApi = async (
  body: GetPageListingQuery,
  isLoggedIn: boolean
) => {
  let URL = isLoggedIn ? `/auth${RES_EXAMS}` : `${RES_EXAMS}`;
  const myInit: any = {
    queryStringParameters: definedObjectBuilder(body),
  };
  return API.get(process.env.REACT_APP_NAME || "", URL, myInit);
};

export { getAllCoursesApi, getAllLearningPathwaysApi, getAllExamsApi };
