import { getPagesContentApi } from "&features/landingPage/landingPage.api";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-hot-toast";

import { sendEmailApi } from "./contactUs.api";
import { ContactUs, ContactUsForm } from "./contactUs.type";
import { translationSelector } from "&utils/translationSelector";

const initialState: ContactUs = {
  loading: false,
  status: "",
  loadingContent: false,
  content: undefined,
};

const sendEmail = createAsyncThunk(
  "contactUs/sendEmail",
  async (body: ContactUsForm, { rejectWithValue, getState, dispatch }: any) => {
    try {
      const result = await sendEmailApi(body);
      return result;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const getPageContent = createAsyncThunk(
  "contactUs/getPageContent",
  async (_, { rejectWithValue, getState, dispatch }: any) => {
    try {
      const content = await getPagesContentApi("contact");
      return content?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const contactUsSlice = createSlice({
  name: "contactUs",
  initialState: initialState,
  reducers: {
    setContactUs: (state, action) => {
      return { ...state, ...action.payload };
    },
    reset: () => initialState,
  },

  extraReducers: (builder) => {
    builder.addCase(sendEmail.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(sendEmail.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "EMAIL_SENT_SUCCESSFULLY";
      toast.success(
        translationSelector(
          {
            name: "Thank you for reaching out to our team. We are currently reviewing your request",
            nameAr: "شكرا لتواصلك مع فريقنا. نحن نقوم حاليًا بمراجعة طلبك",
          },
          "name"
        )
      );
    });
    builder.addCase(sendEmail.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(getPageContent.pending, (state, action) => {
      state.loadingContent = true;
    });
    builder.addCase(getPageContent.fulfilled, (state, action) => {
      state.loadingContent = false;
      state.content = action.payload;
    });
    builder.addCase(getPageContent.rejected, (state, action) => {
      state.loadingContent = false;
    });
  },
});

export const contactUsReducer = contactUsSlice.reducer;

export const contactUsActions = {
  ...contactUsSlice.actions,
  sendEmail,
  getPageContent,
};
