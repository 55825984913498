import { Box } from "@mui/material";
import React, { useEffect, useRef } from "react";

import RightArrowImg from "&assets/images/rightArrow.svg";
import LeftArrowImg from "&assets/images/leftArrow.svg";
import { CardsCarouselWrapper } from "./cardsCarousel.styled";
import { useIsOverflow } from "&utils/overflowHook";
import i18n from "&config/i18n";

interface Props {
  children: React.ReactNode;
}

const CardsCarousel = (props: Props) => {
  const { children } = props;
  const carouselRef = useRef<any>(null);
  const nextBtnRef = useRef(null);
  const prevBtnRef = useRef(null);
  const isOverflow = useIsOverflow(carouselRef, () => {});

  const isArabic = i18n.language === "ar";

  useEffect(() => {
    const carousel: any = carouselRef.current;

    const nextBtn: any = nextBtnRef?.current;
    const prevBtn: any = prevBtnRef?.current;

    nextBtn.addEventListener("click", () => {
      let firstItemWidth = carousel.firstChild.clientWidth + 16;
      carousel.scrollLeft += firstItemWidth;
    });
    prevBtn.addEventListener("click", () => {
      let firstItemWidth = carousel.firstChild.clientWidth + 16;
      carousel.scrollLeft -= firstItemWidth;
    });
  }, []);

  return (
    <CardsCarouselWrapper>
      <Box className="product-slider-con">
        <img
          src={LeftArrowImg}
          alt=""
          ref={isArabic ? nextBtnRef : prevBtnRef}
          className={"back-arrow"}
          style={{ display: isOverflow ? "block" : "none" }}
        />

        <img
          src={RightArrowImg}
          alt=""
          ref={isArabic ? prevBtnRef : nextBtnRef}
          className={"forward-arrow"}
          style={{ display: isOverflow ? "block" : "none" }}
        />

        <Box className="page-slider">
          <Box className="wrapper">
            <div className="carousel-cards" ref={carouselRef}>
              {children}
            </div>
          </Box>
        </Box>
      </Box>
    </CardsCarouselWrapper>
  );
};

export default CardsCarousel;
