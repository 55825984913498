import { Setting } from "&features/global/global.type";

export const educationDegrees = [
  { id: 1, name: "Brevet", nameAr: "شهادة المتوسط" },
  { id: 2, name: "Baccalaureate", nameAr: "شهادة الثانوية" },
  { id: 3, name: "Undergraduate", nameAr: "البكالوريوس" },
  { id: 4, name: "Post-Graduates", nameAr: "الدراسات العليا" },
  { id: 5, name: "Vocational and Technical", nameAr: "التعليم المهني والتقني" },
  { id: 10, name: "N/A" },
];

export const fullTimePartTime = [
  { id: 1, name: "Full-Time", nameAr: "دوام كامل" },
  { id: 2, name: "Part-Time", nameAr: "دوام جزئي" },
];

export const incomeRanges = [
  {
    id: 1,
    name: "$0 - $499 USD per month",
    nameAr: "0-499 دولار اميريكي شهريًا",
    value: "0 - 499",
  },
  {
    id: 2,
    name: "$500 - $999USD per month",
    nameAr: "500-999 دولار اميريكي شهريًا",
    value: "500 - 999",
  },
  {
    id: 3,
    name: "$1,000 - $1,499 USD per month",
    nameAr: "1,000-1,499 دولار اميريكي شهريًا",
    value: "1000 - 1499",
  },
  {
    id: 4,
    name: "More than $1,500",
    nameAr: "أكثر من 1,500 دولار اميريكي شهريًا",
    value: "more than 1500",
  },
];

export const disabilityOptions: Setting[] = [
  {
    settingId: "disability-yes",
    title: "Yes",
    titleAr: "نعم",
    createdAt: "2023-01-16T16:31:58.441Z",
    updatedAt: "2023-01-26T09:26:35.000Z",
    deletedAt: "null",
    id: 1,
    name: "Yes",
    nameAr: "نعم",
    type: "disability",
    typeAr: "disability",
    value: "",
    valueAr: "",
    url: "",
    image: "",
  },
  {
    settingId: "disability-no",
    title: "No",
    titleAr: "لا",
    createdAt: "2023-01-16T16:31:58.441Z",
    updatedAt: "2023-01-26T09:26:35.000Z",
    deletedAt: "null",
    id: 2,
    name: "No",
    nameAr: "لا",
    type: "disability",
    typeAr: "disability",
    value: "",
    valueAr: "",
    url: "",
    image: "",
  },
];

export const residenceTypeOptions: Setting[] = [
  {
    settingId: "resident",
    title: "Resident",
    titleAr: "مقيم",
    createdAt: "2023-01-16T16:31:58.441Z",
    updatedAt: "2023-01-26T09:26:35.000Z",
    deletedAt: "null",
    id: 1,
    name: "Resident",
    nameAr: "مقيم",
    type: "residence-type",
    typeAr: "residence-type",
    value: "",
    valueAr: "",
    url: "",
    image: "",
  },
  {
    settingId: "refugee",
    title: "Refugee",
    titleAr: "لاجئ",
    createdAt: "2023-01-16T16:31:58.441Z",
    updatedAt: "2023-01-26T09:26:35.000Z",
    deletedAt: "null",
    id: 2,
    name: "Refugee",
    nameAr: "لاجئ",
    type: "residence-type",
    typeAr: "residence-type",
    value: "",
    valueAr: "",
    url: "",
    image: "",
  },
];

export const genderOptions: Setting[] = [
  {
    settingId: "male",
    title: "Male",
    titleAr: "ذكر",
    createdAt: "2023-01-16T16:31:58.441Z",
    updatedAt: "2023-01-26T09:26:35.000Z",
    deletedAt: "null",
    id: 1,
    name: "Male",
    nameAr: "ذكر",
    type: "gender-option",
    typeAr: "gender-option",
    value: "",
    valueAr: "",
    url: "",
    image: "",
  },
  {
    settingId: "female",
    title: "Female",
    titleAr: "انثى",
    createdAt: "2023-01-16T16:31:58.441Z",
    updatedAt: "2023-01-26T09:26:35.000Z",
    deletedAt: "null",
    id: 2,
    name: "Female",
    nameAr: "انثى",
    type: "gender-option",
    typeAr: "gender-option",
    value: "",
    valueAr: "",
    url: "",
    image: "",
  },
];
