import styled from "styled-components";
import { colors } from "&assets/constants/colors";

interface Props {
  height?: string;
}

export const SkillsListWrapper = styled.div<Props>`
  display: flex;
  margin-top: 16px;
  flex-wrap: wrap;
  max-height: ${(props) => (props.height ? props.height : "75px")};
  min-height: ${(props) => (props.height ? props.height : "75px")};
  .more-skills {
    cursor: pointer;
  }

  .white-text {
    p {
      color: ${colors.white};
    }
  }
`;
