import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { LearningPathsContainerWrapper } from "./learningPathsContainer.styled";
import LearningPathwayCard from "&components/learningPathwayCard/learningPathwayCard.component";
import Container from "&components/container/container.component";
import { LearningPathwayType } from "&features/listingPage/listingPage.type";
import { learningPathwayActions } from "&features/learningPathway/learningPathway.slice";
import CircularLoader from "&components/circularLoader/circularLoader.component";
import getCDNProtectedURL from "&utils/getCDNProtectedImageSource";
import { images } from "&assets/constants/images-urls";
import { checkIFEnrolledLP } from "&utils/checkIfEnrolled";
import { RootState } from "&store/store";
import { useTranslation } from "react-i18next";
import ListItems from "&components/listItems/listItems.component";

interface Props {
  learningPaths: LearningPathwayType[];
  isLoading?: boolean;
  isCareerMatching?: boolean;
}

const LearningPathsContainer = (props: Props) => {
  const { learningPaths, isLoading, isCareerMatching } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.login.user);
  const { t } = useTranslation(["careerMatching"]);

  const handleClickLearningPathway = (item: LearningPathwayType) => {
    dispatch(learningPathwayActions.setLearningPathway({ selectedLearningPath: item }));
    checkIFEnrolledLP(item.id, user)
      ? navigate(`/dashboard/learning-pathway/${item.id}`)
      : navigate(`/learning-pathway/${item.id}`);
  };

  const loadingComponent = (
    <div className="loader-wrapper">
      <CircularLoader />
    </div>
  );

  return (
    <LearningPathsContainerWrapper>
      <Container>
        {learningPaths.length > 0 && <p className="learning-title">{t("RECOMMENDED_LEARNING_PATHWAYS")}</p>}
        <div className="learning-content">
          {isLoading ? (
            loadingComponent
          ) : (
            <ListItems
              data={learningPaths}
              isBlur={!user?.name && isCareerMatching}
              renderItem={(learningPath, index) => {
                return (
                  <LearningPathwayCard
                    hasExam={learningPath.learningPathExams && learningPath.learningPathExams.length > 0}
                    id={learningPath?.id}
                    key={index}
                    title={learningPath?.name}
                    description={learningPath?.shortDescription}
                    time={learningPath?.duration}
                    subTitle={learningPath?.source}
                    skills={learningPath?.learningPathSkills}
                    modul={learningPath?.learningPathCourses?.length + " Courses"}
                    image={learningPath?.image || getCDNProtectedURL(images.learningPathwayImg)}
                    activities={
                      learningPath?.learningPathCourses?.length > 4
                        ? learningPath?.learningPathCourses?.slice(0, 4)
                        : learningPath?.learningPathCourses
                    }
                    handleClick={() => handleClickLearningPathway(learningPath)}
                    level={learningPath?.level?.alias}
                  />
                );
              }}
            />
          )}
        </div>
      </Container>
    </LearningPathsContainerWrapper>
  );
};

export default LearningPathsContainer;
