import React from "react";
import { SubmitFormButtonWrapper } from "./submitFormButton.styled";
import { ArrowForwardIcon } from "&assets/constants/icons";
import { colors } from "&assets/constants/colors";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";

interface Props {
  id: string;
  title?: string;
  onClick: () => void;
  className?: string;
  withArrow?: boolean;
  titleClassName?: string;
  disabled?: boolean;
  loading?: boolean;
}

const SubmitFormButton = (props: Props) => {
  const {
    title,
    onClick,
    className,
    withArrow,
    titleClassName,
    id,
    disabled,
    loading,
  } = props;
  return (
    <SubmitFormButtonWrapper
      disabled={disabled || loading}
      id={id}
      className={className}
      onClick={onClick}
    >
      {loading ? (
        <CircularProgress size={24} color={"info"} />
      ) : (
        <div className={`title ${titleClassName}`}>{title}</div>
      )}
      {withArrow && (
        <div className="arrow-wrapper">
          {ArrowForwardIcon(colors.primary, 26)}
        </div>
      )}
    </SubmitFormButtonWrapper>
  );
};

export default SubmitFormButton;
