import React from "react";
import { ChipWrapper } from "./chip.styled";

interface Props {
  title?: string;
  className?: string;
  handleClick?: Function;
}

const Chip = (props: Props) => {
  const { title, className, handleClick } = props;
  return (
    <ChipWrapper
      className={className}
      onClick={(event: any) => handleClick && handleClick(event)}
    >
      <p className="chip-title">{title}</p>
    </ChipWrapper>
  );
};

export default Chip;
