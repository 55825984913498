import { RootState } from "&store/store";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Navigate, useLocation, Outlet } from "react-router-dom";

type ReduxProps = ConnectedProps<typeof connector>;

type ProtectedRouteProps = {
  roles?: string[];
};

const UnAuthRoute = ({ isAuthenticated }: ProtectedRouteProps & ReduxProps) => {
  let location = useLocation();

  if (isAuthenticated) {
    return <Navigate to="/dashboard" state={{ from: location }} />;
  }
  return <Outlet />;
};

const mapStateToProps = (state: RootState) => ({
  // TODO change this to your real auth validator
  isAuthenticated: state.login.isLoggedIn,
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);
const AppRedux = connector(UnAuthRoute);

export { AppRedux as UnAuthRoute };
