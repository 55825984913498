import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  enrollLearningPathwayApi,
  getAvailableSimplilearnLearningPaths,
  getFeaturedLearningPathwayApi,
  getLearningPathwayByIdApi,
} from "./learningPathway.api";

import { LearningPath } from "./learningPathway.type";

const initialState: LearningPath = {
  selectedLearningPath: undefined,
  loading: false,
  loadingEnroll: false,
  status: undefined,
  simplilearnAvailable: false,
  featuredLearningPaths: undefined,
};

const getLearningPathwayById = createAsyncThunk(
  "learningPathway/getLearningPathwayById",
  async (id: string, { rejectWithValue, getState, dispatch }: any) => {
    try {
      const isLoggedIn = getState().login.isLoggedIn;
      const learningPathway = await getLearningPathwayByIdApi(id, isLoggedIn);
      return learningPathway?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const getAvailableSimplilearnLps = createAsyncThunk(
  "learningPathway/getAvailableSimplilearnLps",
  async (_, { rejectWithValue, getState, dispatch }: any) => {
    try {
      const learningPathway = await getAvailableSimplilearnLearningPaths();
      return learningPathway?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const enrollLearningPathway = createAsyncThunk(
  "learningPathway/enrollLearningPathway",
  async (id: string, { rejectWithValue, getState, dispatch }: any) => {
    try {
      const learningPathway = await enrollLearningPathwayApi(id);
      return learningPathway?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const getFeaturedLearningPathway = createAsyncThunk(
  "learningPathway/getFeaturedLearningPathway",
  async (body: any, { rejectWithValue, getState, dispatch }: any) => {
    try {
      const isLoggedIn = getState().login.isLoggedIn;
      body.isLoggedIn = isLoggedIn
      const jobs = await getFeaturedLearningPathwayApi(body);
      return jobs?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const learningPathwaySlice = createSlice({
  name: "learningPathway",
  initialState: initialState,
  reducers: {
    setLearningPathway: (state, action) => {
      return { ...state, ...action.payload };
    },
    reset: () => initialState,
  },

  extraReducers: (builder) => {
    builder.addCase(getLearningPathwayById.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getLearningPathwayById.fulfilled, (state, action) => {
      state.loading = false;
      state.selectedLearningPath = action.payload;
      state.status = "LEARNING_PATH_BY_ID_SUCCESS";
    });
    builder.addCase(getLearningPathwayById.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(enrollLearningPathway.pending, (state, action) => {
      state.loadingEnroll = true;
    });
    builder.addCase(enrollLearningPathway.fulfilled, (state, action) => {
      state.loadingEnroll = false;
      state.status = "ENROLLED_LEARNING_PATH_SUCCESSFULLY";
    });
    builder.addCase(enrollLearningPathway.rejected, (state, action) => {
      state.loadingEnroll = false;
      state.status = "USER_ALREADY_ENROLLED";
    });
    
    builder.addCase(getFeaturedLearningPathway.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getFeaturedLearningPathway.fulfilled, (state, action) => {
      state.loading = false;
      state.featuredLearningPaths = action.payload;
      state.status = "GET_FEATURED_JOBS_SUCCESS";
    });
    builder.addCase(getFeaturedLearningPathway.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getAvailableSimplilearnLps.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getAvailableSimplilearnLps.fulfilled, (state, action) => {
      state.loading = false;
      state.simplilearnAvailable = action.payload;
      state.status = "GET_AVAILABLE_SIMPLILEARN_LEARNING_PATHS";
    });
    builder.addCase(getAvailableSimplilearnLps.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const learningPathwayReducer = learningPathwaySlice.reducer;

export const learningPathwayActions = {
  ...learningPathwaySlice.actions,
  getLearningPathwayById,
  enrollLearningPathway,
  getFeaturedLearningPathway,
  getAvailableSimplilearnLps
};
