import { colors } from "&assets/constants/colors";
import { device } from "&assets/constants/responsiveness";
import styled from "styled-components";

interface Props {
  activeLine: number;
}

export const CertificationHeaderWrapper = styled.div<Props>`
  box-sizing: border-box;
  ${(props) => (props.theme.dir === "rtl" ? "padding: 65px 40px 48px 0px;" : "padding: 65px 0px 48px 40px;")}
  height: 400px;
  max-height: 520px;
  background: ${colors.white};
  position: relative;

  .header-wrapper {
    max-width: 1355px;
    margin: auto;
    display: flex;
    position: relative;
  }

  .certification-title {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 110%;
    letter-spacing: -0.96px;
    color: ${colors.dark};
    margin: 0;
    z-index: 3;
    position: relative;
    width: 536px;
  }

  .description {
    width: 439px;
    //height: 58px;
    font-family: "Mulish";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 160%;
    color: ${colors.dark};
    margin-top: 24px;
    margin-bottom: 24px;
    position: relative;
    z-index: 3;
    opacity: 0.7 !important;
  }

  .content-div {
    width: 100%;

    .content-background {
      width: 100%;
      height: 200px;
      border-radius: 40px;
      background: ${colors.primary};
      ${(props) => (props.theme.dir === "rtl" ? "transform: translate(-20%, 7%);" : "transform: translate(20%, 14%);")}
    }
    .carousel-wrapper {
      position: absolute;
      width: 50rem;
      height: 100%;
      top: -40px;

      border-radius: 40px;
      ${(props) => (props.theme.dir === "rtl" ? "right: 38%;" : "left: 40%;")}

      .carousel {
        border-radius: 16px;
      }

      .carousel-content {
        height: 340px;
        width: 90%;
        border-radius: 16px;
      }
      .carousel-image {
        height: 100%;
        border-radius: 16px;
      }
    }
  }

  @media ${device.tablet} {
    max-height: initial;
    height: fit-content;
    padding: 30px;
    background: ${colors.primary};

    .header-wrapper {
      display: block;
    }
    .certification-title {
      font-size: 40px;
      width: 60%;
      ${(props) => (props.theme.dir === "rtl" ? "text-align: right;" : "text-align: left;")}
      color: ${colors.white};
    }

    .description {
      color: ${colors.white};
    }
    .content-background {
      display: none;
    }
    .carousel-wrapper {
      position: relative !important;
      width: 100% !important;
      height: 100% !important;
      top: 0px !important;
      ${(props) => (props.theme.dir === "rtl" ? "right: 0% !important;" : "left: 0% !important;")}
      margin: auto;
    }
  }

  @media ${device.mobile} {
    max-height: initial;
    height: fit-content;
    padding: 30px;
    background: ${colors.primary};

    .header-wrapper {
      display: block;
    }
    .certification-title {
      width: 100%;
      font-size: 40px;
      ${(props) => (props.theme.dir === "rtl" ? "text-align: right;" : "text-align: left;")}
      color: ${colors.white};
    }
    .description {
      font-weight: 500;
      font-size: 16px;
      width: 100%;
      color: ${colors.white};
    }

    .content-div {
      height: 100%;
      width: 100%;
      .content-background {
        display: none;
      }

      .carousel {
        position: relative;
        width: 100% !important;
        top: initial;
        ${(props) => (props.theme.dir === "rtl" ? "left: initial;" : "right: initial;")}
        .slider {
          .slide {
            &.selected {
              opacity: 1 !important;
              min-width: 95%;
              margin: 0px 2% 0px 0px;
            }
          }
        }
      }
      .carousel-content {
        position: relative;
        height: fit-content !important;
      }
      .carousel-image {
        object-fit: cover;
        height: fit-content !important;
      }
    }
    .carousel-wrapper {
      position: relative !important;
      width: 100% !important;
      height: 100% !important;
      top: 0px !important;
      ${(props) => (props.theme.dir === "rtl" ? "right: 0% !important;" : "left: 0% !important;")}
      margin: auto;
    }
  }
`;
