import { colors } from "&assets/constants/colors";
import styled from "styled-components";

interface Props {
  step: number;
}

export const BundleModalWrapper = styled.div<Props>`
  position: relative;
  ${(props: any) => props.step > 0 && `border-top: 1px ${colors.primary} solid;`};
  padding: 24px;
  width: 700px;
  max-width: 100%;
  min-height: fit-content;
  ${(props: any) => props.step < 1 && "padding-bottom: 80px"};
  .bundle-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .bundle-cash-img {
    width: 100%;
    height: 215px;
  }
  .bundle-omt-footer {
    display: flex;
    justify-content: flex-start;
    gap: 20px;
    align-items: center;
  }
  .bundle-list-item {
    font-size: 16px;
    font-weight: 500;
    line-height: 25.6px;
    color: ${colors.dark};
  }
  .view-branches-button {
    background: ${colors.white};
    border: 1px solid ${colors.primary};
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
  }
  .view-branches-button p {
    color: ${colors.primary};
    font-size: 14px;
    font-weight: 800;
    line-height: 22.4px;
  }
  .proceed-button {
    position: absolute;
    bottom: 0;
    left: 40px;
    right: 40px;
    margin: auto;
    width: fit-content;
    .title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  .radio-group {
    display: flex;
    justify-content: center;
    gap: 50px;
    align-items: center;
  }
  input[type="radio"] {
    position: absolute;
    right: 10px;
    height: 20px;
    width: 20px;
    top: 10px;
    border: transparent;
    background: ${colors.primary};
  }
  input[type="radio"]:checked {
    color: red;
    background-color: red;
    border: 1px red solid;
  }
  .radios-footer {
    margin-top: 20px;
  }
  .radio-icon {
    text-align: center;
    margin: 0 auto;
  }
  .radio-label {
    margin-top: 10px;
  }
  .custom-radio {
    position: relative;
    width: 124px;
    height: 124px;
    cursor: pointer;
    background-color: #108cd210;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;
