import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Route, Routes } from "react-router-dom";

import { RootState } from "&store/store";
import { DashboardWrapper } from "./dashboard.styled";
import DashboardMenu from "&components/dashboardMenu/dashboardMenu.component";
import Container from "&components/container/container.component";
import { DashboardComponent } from "./screens/dashboardComponent/dashboardComponent";
import { ProfileComponent } from "&features/dashboard/screens/profile/profile.component";
import { LearningActivities } from "./screens/learningActivities/learningActvities";
import { OpportunitiesListComponent } from "./screens/opportunitiesList/opportunitiesList";
import { CourseDetailsComponent } from "./screens/courseDetails/courseDetails";
import { LearningPathwayComponent } from "./screens/learningPathway/learningPathway";
import { opportunitiesListingActions } from "&features/opportunitiesListing/opportunitiesListing.slice";
import { loginActions } from "&features/login/login.slice";
import { courseDetailsActions } from "&features/courseDetails/courseDetails.slice";
import { learningPathwayActions } from "&features/learningPathway/learningPathway.slice";
import { assessmentActions } from "&features/assessment/assessment.slice";
import { LabDetailsComponent } from "&features/labDetails/labDetails.component";
import { ExamDetailsComponent } from "&features/examDetails/examDetails.component";
import { notificationsActions } from "&features/notifications/notifications.slice";
import { NotificationsComponent } from "&features/notifications/notifications.component";

type ReduxProps = ConnectedProps<typeof connector>;

const Dashboard = (props: ReduxProps) => {
  // const { t } = useTranslation(["dashboard"]);
  const {
    getJobs,
    getProfile,
    setLogin,
    actionAfterSignup,
    enrollCourse,
    enrollLearningPathway,
    id,
    resetAssessment,
    selectedCountry,
    enrollCoursestatus,
    enrollLearningPathwaystatus,
    setCourseDetails,
    setLearningPathway,
    getBannerNotifications,
  } = props;

  const afterSignup: any = {
    "enroll-course": () => {
      id && enrollCourse(id);
    },
    "enroll-learningPathway": () => {
      id && enrollLearningPathway(id);
    },
  };

  useEffect(() => {
    if (actionAfterSignup) {
      afterSignup[actionAfterSignup]();
      setLogin({ actionAfterSignup: undefined });
    }
    getProfile({});
    resetAssessment();
    getBannerNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      enrollCoursestatus === "ENROLLED_COURSE_SUCCESSFULLY" ||
      enrollLearningPathwaystatus === "ENROLLED_LEARNING_PATH_SUCCESSFULLY"
    ) {
      setCourseDetails({ status: "" });
      setLearningPathway({ status: "" });
      getProfile({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enrollCoursestatus, enrollLearningPathwaystatus]);

  useEffect(() => {
    getJobs({ countryId: selectedCountry.value, page: 1, limit: 9 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountry, localStorage.getItem("language")]);

  return (
    <DashboardWrapper>
      <Container className="dashboard-container">
        <DashboardMenu />
      </Container>
      <Routes>
        <Route path="/" element={<DashboardComponent />} />
        <Route path="/activities" element={<LearningActivities />} />
        <Route path="/opportunities" element={<OpportunitiesListComponent />} />
        <Route path="/course/:id" element={<CourseDetailsComponent />} />
        <Route
          path="/course/:id/learning-path/:learningPathId"
          element={<CourseDetailsComponent />}
        />
        <Route
          path="/lab/:id/learning-path/:learningPathId"
          element={<LabDetailsComponent auth={true} />}
        />
        <Route
          path="/exam/:id/learning-path/:learningPathId"
          element={<ExamDetailsComponent auth={true} />}
        />
        <Route path="/profile" element={<ProfileComponent />} />
        <Route path="/notifications" element={<NotificationsComponent />} />
        <Route path="/lab/:id" element={<LabDetailsComponent auth={true} />} />
        <Route
          path="/exam/:id"
          element={<ExamDetailsComponent auth={true} />}
        />
        <Route
          path="learning-pathway/:id"
          element={<LearningPathwayComponent auth={true} />}
        />
      </Routes>
    </DashboardWrapper>
  );
};

const mapStateToProps = (state: RootState) => ({
  learningPathways: state.listingPage.learningPathways,
  courses: state.listingPage.courses,
  actionAfterSignup: state.login.actionAfterSignup,
  id: state.login.id,
  selectedCountry: state.landingPage.selectedCountry,
  enrollCoursestatus: state.courseDetails.status,
  enrollLearningPathwaystatus: state.learningPathway.status,
  user: state.login.user,
});

const mapDispatchToProps = {
  enrollCourse: courseDetailsActions.enrollCourse,
  enrollLearningPathway: learningPathwayActions.enrollLearningPathway,
  getJobs: opportunitiesListingActions.getJobs,
  getProfile: loginActions.getProfileThunk,
  setLogin: loginActions.setLogin,
  resetAssessment: assessmentActions.reset,
  setCourseDetails: courseDetailsActions.setCourseDetails,
  setLearningPathway: learningPathwayActions.setLearningPathway,
  getBannerNotifications: notificationsActions.getBannerNotifications,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const DashboardRedux = connector(Dashboard);

export { DashboardRedux as Dashboard };
