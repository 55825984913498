import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { I18nextProvider } from "react-i18next";
import { Auth0Provider } from "@auth0/auth0-react";

import "./index.css";
import { App } from "./App";
import { store, persistor } from "./store/store";
import * as serviceWorker from "./serviceWorker";
import i18n from "&config/i18n";

const container = document.getElementById("root");
const root = createRoot(container!);

if (process.env.REACT_APP_ENV === "PROD") {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <I18nextProvider i18n={i18n}>
        <Auth0Provider
          domain={String(process.env.REACT_APP_AUTH0_DOMAIN)}
          clientId={String(process.env.REACT_APP_AUTH0_CLIENT_ID)}
          cacheLocation="localstorage"
          authorizationParams={{
            redirect_uri: `${process.env.REACT_APP_AUTH0_REDIRECT_URI}/login`,
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          }}
        >
          <App />
        </Auth0Provider>
      </I18nextProvider>
    </PersistGate>
  </Provider>
  // </React.StrictMode>
);

serviceWorker.unregister();
