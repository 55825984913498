import { colors } from "&assets/constants/colors";
import { device } from "&assets/constants/responsiveness";
import styled from "styled-components";

interface Props {
  backgroundColor?: string;
  color?: string;
  direction?: string;
  width?: string;
}
export const BannerWrapper = styled.div<Props>`
  width: ${(props) => props.width ?? "100%"}!important;
  background-color: ${(props) => (props.backgroundColor !== undefined ? props.backgroundColor : colors.primaryDark)};
  background: ${(props) =>
    props.backgroundColor !== undefined
      ? `linear-gradient(180deg,${props.backgroundColor} 0%,${props.backgroundColor} 100%) padding-box, linear-gradient(56.4deg, ${colors.primaryDark} 8.08%,${colors.primary} 87.11%) border-box;`
      : ""};
  border-radius: 16px;
  min-height: 270px;
  border: ${(props) => (props.backgroundColor ? "1px solid transparent" : "")};
  display: flex;
  justify-content: space-between;
  justify-content: ${(props) => (props.direction && props.direction === "rtl" ? "flex-end" : "space-between")};
  flex-direction: ${(props) => (props.direction && props.direction === "rtl" ? "row-reverse" : "row")};
  margin: 40px auto;
  ${(props) => (props.theme.dir === "rtl" ? "text-align: right;" : "text-align: left;")}
  transition: all 0.3s ease-in-out;
  .banner-info {
    width: 648px;
    padding: 32px;
    direction: ${(props) => props.theme.dir};
    .see-more-less {
      cursor: pointer;
    }
  }
  .banner-title {
    font-weight: 700;
    font-size: 32px;
    line-height: 110%;
    letter-spacing: -0.02em;
    color: ${(props) => (props.color !== undefined ? props.color : colors.white)};
    margin: 0;
  }
  .banner-description {
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    color: ${(props) => (props.color !== undefined ? props.color : colors.white)};
    margin: 0;
    &.wrapped {
      overflow: hidden;
      height: 90px;
      max-height: 90px;
    }
  }
  .explore-nammiskills-btn {
    background: ${colors.linearGradientThree};
    min-width: 156px;
    height: 38px;
    margin-top: 15px;
    box-sizing: border-box !important;
  }
  .explore-nammiskills-btn-text {
    font-weight: 800;
    font-size: 14px;
  }

  .banner-background {
    position: relative;
    margin-top: -20px;
    width: fit-content;
    height: fit-content;
    .banner-image {
      width: 422px;
      height: 225px;

      border-radius: 16px;
      .background-gradient {
        width: 422px;
      }
    }
    .close-button {
      position: absolute;
      top: 20px;
      ${(props) => (props.theme.dir === "rtl" ? "left: 20px;" : "right: 20px;")}
      z-index: 2;
      .close-circle {
        width: 24px;
        height: 24px;
      }
    }
  }
  @media ${device.inBetween} {
    width: 100%;
  }
  @media ${device.mobile}, ${device.tablet} {
    width: 100%;
    border-radius: 24px;
    flex-direction: column-reverse;
    margin-bottom: 24px;
    min-height: 300px;
    transition: all 0.3s ease-in-out;
    .banner-info {
      width: 95%;
      padding: 23px 16px;
    }
    .banner-title {
      font-weight: 700;
      font-size: 28px;
    }
    .banner-description {
      font-weight: 500;
      font-size: 14px;
      line-height: 160%;
      margin: 16px 0;
    }
    .explore-nammiskills-btn {
      min-width: 110px;
    }
    .banner-background {
      position: relative;
      margin-top: 10px;
      width: 100%;
      height: fit-content;
      .banner-image {
        width: 100%;
        height: 150px;
        .background-gradient {
          width: 100%;
        }
      }
      .close-button {
        top: 10px;
      }
    }
  }
`;
