import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";

import { RootState } from "&store/store";

import { globalActions } from "./global.slice";
import { GlobalWrapper } from "./global.styled";

type ReduxProps = ConnectedProps<typeof connector>;

const GlobalComponent = (props: ReduxProps | any) => {
  // const { t } = useTranslation(["global"]); // Make sure namespace is added to locales

  const { children, getSettings } = props;

  useEffect(() => {
    // Write your side-effects here
    getSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <GlobalWrapper>{children}</GlobalWrapper>;
};

const mapStateToProps = (state: RootState) => ({
  // Map your redux state to your props here
});

const mapDispatchToProps = {
  getSettings: globalActions.getSettings,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const GlobalComponentRedux = connector(GlobalComponent);

export { GlobalComponentRedux as GlobalComponent };
