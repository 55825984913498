import { colors } from "&assets/constants/colors";
import { device } from "&assets/constants/responsiveness";
import styled from "styled-components";

export const CourseDetailsWrapper = styled.div`
  background: rgba(16, 140, 210, 0.1);
  .buttonComponents {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .course-details {
    padding: 94px 115px;
    background-color: ${colors.white};
    margin: 0 175px;
    .apply-button {
      width: 134px;
      height: 45px;
      background: ${colors.secondary};
      border-radius: 30px;
      margin: 36px auto;
    }
    .apply-button-text {
      font-weight: 800;
      font-size: 18px;
    }
    .apply-sponsorship {
      padding: 0;
      .title-line {
        display: flex;
        .title {
          font-weight: 700;
          font-size: 24px;
          line-height: 120%;
          color: ${colors.dark};
          white-space: nowrap;
          margin-top: 50px;
        }

        .divider-line {
          display: none;
        }
      }
    }
    .loader-wrapper {
      height: 100vh;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .header {
      font-family: "Mulish";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 120%;
      color: ${colors.dark};
      margin-top: 38px;
    }
    .description-content {
      font-family: "Mulish" !important;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 160%;
      display: flex;
      color: ${colors.dark};
    }
    .learning-outcomes {
      margin: 48px 0;
      .description-content {
        margin: 5px 0;
      }
    }
    .chips {
      display: flex;
      margin-top: 20px;
      flex-wrap: wrap;
    }
  }

  @media ${device.inBetween} {
    .course-details {
      padding: 70px 170px;
      margin: 0 125px;
    }
  }

  @media ${device.tablet} {
    .course-details {
      padding: 32px 30px;
      margin: 0 75px;
    }
    .chips {
      margin-top: 0;
    }
  }
  @media ${device.mobile} {
    .course-details {
      padding: 32px 16px;
      justify-content: center;
      flex-direction: column;
      display: flex;
      margin: 0;
      .learning-outcomes {
        margin: 5px 0 48px 0;
      }
      .expand {
        height: auto !important;
        background: transparent;
      }
      .content-text {
        height: 370px;
        overflow: hidden;
        position: relative;
        .layer {
          position: absolute;
          width: 100%;
          height: 126px;
          background: linear-gradient(0deg, #fff -7.08%, transparent 60.11%);
          bottom: 0;
          ${(props) => (props.theme.dir === "rtl" ? "right: 0;" : "left: 0;")}
        }
      }
      .chips {
        margin-top: 0;
      }
      .read-more-title {
        font-size: 14px;
      }
    }
  }
`;
