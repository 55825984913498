import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useTranslation } from "react-i18next";

import { RootState } from "&store/store";
import { SuccessWrapper } from "./success.styled";
import LinearLoader from "&components/loaders/linearLoader/linearLoader.component";
import ImageDisplay from "&components/imageDisplay/imageDisplay.component";
import LogoIcon from "../../../../assets/images/logo-icon.svg";

type ReduxProps = ConnectedProps<typeof connector>;

const SuccessComponent = (props: ReduxProps) => {
  const { t } = useTranslation(["assessment"]);

  useEffect(() => {}, []);

  return (
    <SuccessWrapper className="content-padding">
      <LinearLoader className="linear-progress" />
      <p className="title">
        {t("CONGRATULATIONS")}
        {/* <span className="highlight">nammiskills</span> */}
      </p>
      <ImageDisplay isFromS3={false} url={LogoIcon} alt="success" className="success-image" />
      <p className="info">{t("YOUR_PERSONALIZED_LEARNING_ACTIVITIES_ARE_LOADING")}</p>
    </SuccessWrapper>
  );
};

const mapStateToProps = (state: RootState) => ({
  selectedCareerPath: state.landingPage.selectedCareerPath,
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);
const SuccessComponentRedux = connector(SuccessComponent);

export { SuccessComponentRedux as SuccessComponent };
