import styled from "styled-components";
import { colors } from "&assets/constants/colors";

export const LinearLoaderWrapper = styled.div`
  width: 100%;
  
  .linear-progress-root {
    span.MuiLinearProgress-bar1 {
      background-color: ${colors.primary} !important;

    }
    span.MuiLinearProgress-bar2 {
      background-color: ${colors.primary} !important;
    }
  }
`;
