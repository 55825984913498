import React, { useEffect, useRef, useState } from "react";
import { connect, ConnectedProps, useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { RootState } from "&store/store";

import { LandingPageWrapper } from "./landingPage.styled";
import HeaderLanding from "&components/headerLanding/headerLanding.component";
import Supporters from "&components/supporters/supporters.component";
import { landingPageActions } from "&features/landingPage/landingPage.slice";
import Testimonial from "&components/testimonial/testimonial.component";
import { isEmpty } from "@aws-amplify/core";
import { learningPathwayActions } from "&features/learningPathway/learningPathway.slice";
import InCollaborationWith from "&components/landing/inCollaborationWith/inCollaborationWith.component";
import FourEasySteps from "&components/landing/fourEasySteps/fourEasySteps.component";
import Container from "&components/container/container.component";
import { fourEasyStepsContent } from "&assets/constants/statics";
import NewCareerLevels from "&components/landing/newCareerLevels/newCareerLevels.component";
import { globalActions } from "&features/global/global.slice";
import CarouselBanner from "&components/carouselBanner/carouselBanner.component";
import CircularLoader from "&components/circularLoader/circularLoader.component";
import { listingPageActions } from "&features/listingPage/listingPage.slice";
import ExamCards from "&components/examsCards/examCards.component";

type ReduxProps = ConnectedProps<typeof connector>;

const LandingPageComponent = (props: ReduxProps) => {
  const {
    getExams,
    getLevels,
    getPagesContent,
    getFeaturedLearningPathway,
    homeContent,
    status,
    setLandingPage,
    levels,
    // featuredLearningPaths,
    user,
    exams,
  } = props;
  const { t, i18n } = useTranslation(["landingPage"]);
  const [bannerType, setBannerType] = useState("");
  const dispatch = useDispatch();
  const pages = useSelector((state: any) => state.global.pages);
  const simplilearnAvailable = useSelector((state: any) => state.learningPathway.simplilearnAvailable);
  const loading = useSelector((state: any) => state.learningPathway.loading);
  const isArabic = i18n.language === "ar";
  useEffect(() => {
    dispatch(learningPathwayActions.getAvailableSimplilearnLps());
    dispatch(globalActions.getPages("landing_page"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user && user?.name) {
      setBannerType("banner-auth");
    } else {
      setBannerType("banner");
    }
  }, [user]);

  const content = homeContent?.sections;
  const ref = useRef<any>(null);
  const selectedCountry = useSelector((state: RootState) => state.landingPage.selectedCountry);

  useEffect(() => {
    getLevels({ limit: user?.name ? 5 : 4 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountry]);

  useEffect(() => {
    getPagesContent();
    getExams({
      page: 1,
      limit: 12,
    });

    ref?.current?.continuousStart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    levels &&
      selectedCountry.value &&
      getFeaturedLearningPathway({
        params: {
          page: 1,
          countryId: selectedCountry.value,
          limit: 9,
        },
        ids: levels?.slice(0, 3)?.map((level: any) => level?.careers[0]?.id),
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [levels, localStorage.getItem("language")]);

  useEffect(() => {
    if (status === "GET_PAGES_CONTENT_SUCCESS") {
      setLandingPage({ status: "" });
      ref?.current?.complete();
      return;
    }
    setTimeout(() => {
      ref?.current?.complete();
    }, 2000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const handleClickScroll = () => {
    const element = document.getElementById("getting-started-section");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const [bannersData, setBannersData] = useState([]);

  useEffect(() => {
    const fetchedBanners = pages?.sections?.landing_page?.filter((val: any) =>
      val.sectionContents.some((item: any) => item.type === bannerType)
    );
    const banners: any = [];
    if (fetchedBanners) {
      fetchedBanners.map((banner: any) => banners.push(banner.sectionContents[0]));
      const newBanners: any = [
        ...banners.filter((val: any) => val.subTitle === "softskill"),
        ...banners.filter((val: any) => val.subTitle === "simplilearn" && simplilearnAvailable),
      ];
      setBannersData(newBanners);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pages?.sections?.landing_page]);

  const loadingComponent = (
    <div className="loader-wrapper">
      <CircularLoader />
    </div>
  );
  if (loading) {
    return loadingComponent;
  }

  return (
    <LandingPageWrapper>
      {!isEmpty(homeContent) ? (
        <>
          <HeaderLanding
            data={content.landing[0]}
            exploreMoreHandleClick={() => {
              handleClickScroll();
            }}
          />
          <div className="landing-background">
            <Container className="landing-container">
              <InCollaborationWith
                title={t("IN_COLLABORATION_WITH")}
                description={t("UNLOCK_YOUR_POTENTIAL_WITH_ACCESS")}
              />
              <FourEasySteps
                backgroundImage={content?.landing[0]?.sectionContents[0]?.image}
                title={t("FOUR_EASY_STEPS_TO_GUIDE_YOU")}
                data={fourEasyStepsContent}
              />
              <NewCareerLevels
                content={content.steps[0]}
                title={t("SELECT_ONE_OF_THE_MOST_IN_DEMAND_CAREERS")}
                description={t("WE_LL_HELP_YOU_CHOOSE_THE_SPONSORED_LEARNINGS")}
              />
              <div className="landing-banner-wrapper">
                {!loading
                  ? bannerType &&
                    bannersData && (
                      <CarouselBanner
                        banners={
                          !isArabic
                            ? bannersData.filter((val: any) => val.title)
                            : bannersData.filter((val: any) => val.titleAr)
                        }
                        backgroundColor="#fff"
                        color="#000000"
                        direction="rtl"
                        authenticated={bannerType === "banner-auth"}
                      />
                    )
                  : loadingComponent}
              </div>
            </Container>
          </div>
          {exams && exams.items.length > 0 && (
            <ExamCards exams={exams.items} title={t("SPONSORED_CERTIFICATIONS")} allButtonText={t("VIEW_ALL")} />
          )}
          {/* {featuredLearningPaths && featuredLearningPaths.length > 0 && (
            <LearningPathway
              pathways={featuredLearningPaths}
              title={translationSelector(content.steps[1], "title")}
              allButtonText={t("VIEW_ALL")}
              hideTier={true}
            />
          )} */}
          <Testimonial data={content.members[0]} />
          <Supporters data={content.contributors[0]} />
        </>
      ) : (
        <div style={{ height: "100vh" }}></div>
      )}
    </LandingPageWrapper>
  );
};

const mapStateToProps = (state: RootState) => ({
  homeContent: state.landingPage.homeContent,
  loadingContent: state.landingPage.loadingContent,
  status: state.landingPage.status,
  levels: state.landingPage.levels,
  featuredLearningPaths: state.learningPathway.featuredLearningPaths,
  user: state.login.user,
  exams: state.listingPage.exams,
});

const mapDispatchToProps = {
  getLevels: landingPageActions.getLevels,
  getPagesContent: landingPageActions.getPagesContent,
  setLandingPage: landingPageActions.setLandingPage,
  getFeaturedLearningPathway: learningPathwayActions.getFeaturedLearningPathway,
  getExams: listingPageActions.getExams,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const LandingPageComponentRedux = connector(LandingPageComponent);

export { LandingPageComponentRedux as LandingPageComponent };
