import { API } from "aws-amplify";
import {RES_CAREERS, RES_TIERS} from "&store/resources";
import {definedObjectBuilder} from "&utils/definedObjectBuilder";


const getAllCareersByCountryId = async (body: any) => {
  let URL = `${RES_CAREERS}`;
  const myInit: any = {
    queryStringParameters: definedObjectBuilder(body),
  };
  return API.get(process.env.REACT_APP_NAME || "", URL, myInit);
};

const getAllLevelsApi = async (body: any) => {
  let URL = `${RES_TIERS}`;
  const myInit: any = {
    queryStringParameters: definedObjectBuilder(body),
  };
  return API.get(process.env.REACT_APP_NAME || "", URL, myInit);
};

export { getAllLevelsApi, getAllCareersByCountryId };
