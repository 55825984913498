import React from "react";
import { useSelector } from "react-redux";

import { PageSection } from "&features/landingPage/landingPage.type";
import OpportunitiesCarousel from "&components/opportunitiesCarousel/opportunitiesCarousel.component";
import GradientBackground from "&components/gradientBackground/gradientBackground.component";
import CircularLoader from "&components/circularLoader/circularLoader.component";
import { RootState } from "&store/store";
import {useTranslation} from "react-i18next";

interface Props {
  data?: PageSection;
  title?: string;
  allButtonText?: string;
  onAllButtonClick?: () => void;
  loading?: boolean;
  opportunities?: any;
}

const MatchingOpportunities = (props: Props) => {
  const { t } = useTranslation(["careerMatching"]);

  const {
    title = t('MATCHING_OPPORTUNITIES'),
    allButtonText = t('VIEW_ALL_OPPORTUNITIES'),
    onAllButtonClick = () => {},
    loading,
    opportunities,
  } = props;
  const jobs = useSelector(
    (state: RootState) => state.opportunitiesListing.jobs
  );

  const content = opportunities
    ? opportunities?.items
      ? opportunities?.items
      : opportunities
    : jobs?.items || jobs;

  return content?.length > 0 ? (
    <GradientBackground
      title={title}
      allButtonText={allButtonText}
      onAllButtonClick={onAllButtonClick}
    >
      {loading ? <CircularLoader /> : <OpportunitiesCarousel data={content} />}
    </GradientBackground>
  ) : null;
};

export default MatchingOpportunities;
