import React, { useEffect } from "react";
import moment from "moment/moment";
import { getIn, useFormik } from "formik";
import * as Yup from "yup";
import { WorkExperience } from "&features/dashboard/screens/profile/profile.type";
import { ProfileModalInnerContent } from "&components/profile/edit/profileEditModel/profileEditModal.styled";
import { FormInputContent, UpdateProfileBody, WorkExperienceUpdateProfileBody } from "&features/login/login.type";
import inputSelector from "&utils/ui/inputSelector";
import InputComponent from "&components/inputs/input/input.component";
import { fullTimePartTime } from "&assets/constants/profile";
import { useSelector } from "react-redux";
import { RootState } from "&store/store";
import { buildWorkExperienceItemBody } from "&utils/profile/buildWorkExperiencesBody";
import { useTranslation } from "react-i18next";

interface Props {
  initialValues?: WorkExperience;
  submit?: number;
  deleteButton?: number;
  updateProfile: (body: UpdateProfileBody) => void;
  index?: number;
}

const ProfileAddWorkExperience = (props: Props) => {
  const { initialValues, submit, updateProfile, index, deleteButton } = props;
  const { t } = useTranslation(["profile"]);

  const user = useSelector((state: RootState) => state.login.user);

  const isEdit = !!initialValues;

  const values: WorkExperience = {
    title: initialValues?.title || undefined,
    companyName: initialValues?.companyName || undefined,
    startDate: initialValues?.startDate || undefined,
    endDate: initialValues?.endDate || undefined,
    current: initialValues?.current || false,
    description: initialValues?.description || undefined,
    location: initialValues?.location || undefined,
    employmentType: initialValues?.employmentType || fullTimePartTime[0],
  };

  const validationSchema = Yup.object().shape({
    current: Yup.boolean().notRequired(),
    title: Yup.string()?.trim().required(t("REQUIRED")),
    companyName: Yup.string()?.trim().required(t("REQUIRED")),
    startDate: Yup.date()
      .when("current", {
        is: (current: boolean) => !current,
        then: Yup.date().max(Yup.ref("endDate"), t("START_DATE_MUST_BE_LESS_THAN_END_DATE")),
      })
      .required(t("REQUIRED")),
    endDate: Yup.date().when("current", {
      is: (current: boolean) => !current,
      then: Yup.date()
        .max(moment(), t("END_DATE_CAN_NOT_BE_IN_THE_FUTURE"))
        .min(Yup.ref("startDate"), t("END_DATE_CAN_NOT_BE_GREATER_THAN_START_DATE"))
        .required(t("REQUIRED")),
    }),
  });

  const handleUpdateAdd = () => {
    if (isEdit) {
      let experience: WorkExperienceUpdateProfileBody[] = user?.workExperience ? [...user?.workExperience] : [];
      experience[index ?? -1] = buildWorkExperienceItemBody(formik.values);
      updateProfile({ workExperiences: experience });
    } else {
      let experience: WorkExperienceUpdateProfileBody[] = user?.workExperience
        ? [buildWorkExperienceItemBody(formik.values), ...user.workExperience]
        : [];
      updateProfile({ workExperiences: experience });
    }
  };

  const handleDelete = () => {
    let experience: WorkExperienceUpdateProfileBody[] = user?.workExperience
      ? user?.workExperience?.filter((_experience, indexExperience) => index !== indexExperience)
      : [];

    if (typeof index === "number") {
      updateProfile({ workExperiences: experience });
    }
  };

  const formik: any = useFormik({
    initialValues: values,
    validationSchema: validationSchema,
    onSubmit: handleUpdateAdd,
  });

  const workExperienceInputGrid: FormInputContent[] = [
    {
      id: `job-title-text-input`,
      label: t("JOB_TITLE"),
      placeHolder: t("EX_SENIOR_GRAPHIC_DESIGNER"),
      type: "text",
      value: formik.values?.title || undefined,
      handleChange: (value) => formik.setFieldValue(`title`, value),
      error: getIn(formik.touched, `title`) && getIn(formik.errors, `title`),
    },
    {
      id: `company-name-text-input`,
      label: t("COMPANY_NAME"),
      placeHolder: t("TYPE_YOUR_COMPANY_NAME"),
      type: "text",
      value: formik.values?.companyName || undefined,
      handleChange: (value) => formik.setFieldValue(`companyName`, value),
      error: getIn(formik.touched, `companyName`) && getIn(formik.errors, `companyName`),
    },
    {
      id: `company-location-text-input`,
      label: t("LOCATION"),
      placeHolder: t("TYPE_YOUR_COMPANY_LOCATION"),
      type: "text",
      value: formik.values?.location || undefined,
      handleChange: (value) => formik.setFieldValue(`location`, value),
      error: getIn(formik.touched, `location`) && getIn(formik.errors, `location`),
    },
    {
      id: "employment-type-select",
      label: t("EMPLOYMENT_TYPE"),
      placeHolder: t("SELECT_FROM_THE_LIST"),
      type: "select",
      options: fullTimePartTime,
      value: formik.values?.employmentType,
      handleChange: (value) => formik.setFieldValue("employmentType", value),
      error: formik.touched.employmentType && formik.errors.employmentType,
    },
    {
      id: `start-date-date-input`,
      label: t("START_DATE"),
      placeHolder: t("SELECT_FROM_CALENDAR"),
      type: "date",
      max: formik.values?.endDate || moment(),
      value: formik.values?.startDate || undefined,
      handleChange: (value) => formik.setFieldValue(`startDate`, value),
      error: getIn(formik.touched, `startDate`) && getIn(formik.errors, `startDate`),
    },
    {
      id: `end-date-date-input`,
      label: t("END_DATE"),
      placeHolder: t("SELECT_FROM_CALENDAR"),
      type: "date",
      min: formik.values?.startDate || undefined,
      max: moment(),
      hide: formik.values?.current,
      value: formik.values?.endDate || undefined,
      handleChange: (value) => formik.setFieldValue(`endDate`, value),
      error: getIn(formik.touched, `endDate`) && getIn(formik.errors, `endDate`),
    },
  ];

  useEffect(() => {
    submit && submit > 0 && formik.handleSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submit]);

  useEffect(() => {
    deleteButton && deleteButton > 0 && handleDelete();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteButton]);

  return (
    <ProfileModalInnerContent>
      <div className="input-grid">
        {workExperienceInputGrid?.map(
          (item: FormInputContent, index: number) => !item.hide && inputSelector(item, index)
        )}
      </div>
      {inputSelector(
        {
          id: "current-job-checkbox",
          label: t("IM_CURRENTLY_WORKING_HERE"),
          value: formik?.values?.current,
          handleChange: () => {},
          onClick: () => formik.setFieldValue(`current`, !formik?.values?.current),
          type: "checkbox",
        },
        0
      )}
      <InputComponent
        multiline={true}
        label={t("DESCRIPTION")}
        placeholder={t("DESCRIBE_YOUR_JOB_POSITION")}
        value={formik.values?.description}
        wrapperClassName="description-wrapper"
        onChange={(value) => formik.setFieldValue("description", value.target.value)}
        valid={formik.touched.description && formik.errors.description}
      />
    </ProfileModalInnerContent>
  );
};

export default ProfileAddWorkExperience;
