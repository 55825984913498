import styled from "styled-components";

export const FutureCareersWrapper = styled.div`
  .future-careers-container-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .future-careers-title-wrapper {
    max-width: 862px;
  }
  .mobile-tabs-careers-wrapper{
    overflow-x: scroll;
    overflow-y: hidden;
    padding: 0 10px;
    height: fit-content;
  }
  `;

