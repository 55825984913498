import React from "react";

import { GettingStartedStepsWrapper } from "./gettingStartSteps.styled";
import CareerLevels from "&components/careerLevels/careerLevels.component";
import TitleWithSteps from "&components/titleWithSteps/titleWithSteps.component";
import { useSelector } from "react-redux";
import { RootState } from "&store/store";
import CircularLoader from "&components/circularLoader/circularLoader.component";
import { PageSection } from "&features/landingPage/landingPage.type";
import { translationSelector } from "&utils/translationSelector";

interface Props {
  id?: string;
  short?: boolean;
  data?: PageSection;
  title?: string;
  subTitle?: string;
  titleSubtitleWrapper?: string;
  containerWrapper?: string;
  showSignupText?: boolean;
}

const GettingStartedSteps = (props: Props) => {
  const {
    id,
    data,
    short,
    title,
    subTitle,
    titleSubtitleWrapper,
    containerWrapper,
    showSignupText,
  } = props;
  const loadingCareers = useSelector(
    (state: RootState) => state.landingPage.loadingLevels
  );

  const careerLevels = useSelector(
    (state: RootState) => state.landingPage.levels
  );

  return (
    <GettingStartedStepsWrapper short={short} id={id}>
      <TitleWithSteps
        title={translationSelector(data, "title") || title}
        subTitle={translationSelector(data, "content") || subTitle}
        short={short}
        step={1}
        showSignupText={showSignupText}
        className={`title-with-steps`}
        containerWrapper={containerWrapper}
        titleSubtitleWrapper={titleSubtitleWrapper}
      />
      {!loadingCareers ? (
        <CareerLevels
          careerLevels={careerLevels}
          short={short}
          hideJobs={false}
          isAssessment={true}
        />
      ) : (
        <CircularLoader className="loader" />
      )}
    </GettingStartedStepsWrapper>
  );
};

export default GettingStartedSteps;
