import styled from "styled-components";
import { device } from "&assets/constants/responsiveness";
import { colors } from "&assets/constants/colors";

export const BuildProfileStepThreeWrapper = styled.div`
  height: fit-content;
  padding: 48px 75px 175px 75px;
  * {
    direction: ${(props) => props.theme.dir} !important;
  }
  .reject-reason {
    margin: 0;
    color: ${colors.errorColor};
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    margin-top: -20px;
    margin-bottom: 10px;
  }
  .approved-reason {
    margin: 0;
    color: ${colors.green};
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    margin-top: -20px;
    margin-bottom: 10px;
  }
  .loader-wrapper {
    height: 400px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .sponsorship-input-column {
    display: grid;
    width: 100%;
    height: fit-content;
    grid-gap: 16px;
    grid-template-columns: auto;
  }

  @media ${device.tablet}, ${device.mobile} {
    padding: 0 16px;

    .sponsorship-input-column {
      display: flex;
      flex-direction: column;
    }
  }
`;
