import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { CareerListing } from "./careerListing.type";
import { GetPageListingQuery } from "&features/listingPage/listingPage.type";
import { getAllCareersByCountryId, getAllLevelsApi } from "&features/careerListing/careerListing.api";

const initialState: CareerListing = {
  levels: [],
  loadingLevels: false,
  loadingCareers: false,
  status: "",
  careers: [],
  totalCareers: 0,
};

const getAllCareers = createAsyncThunk(
  "careerListing/getAllCareers",
  async (
    body: GetPageListingQuery,
    { rejectWithValue, getState, dispatch }: any
  ) => {
    try {
      const country = getState().landingPage.selectedCountry;
      body.countryId = country.value;
      const careers = await getAllCareersByCountryId(body);
      return careers?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const getLevels = createAsyncThunk(
  "careerListing/getLevels",
  async (
    body: GetPageListingQuery,
    { rejectWithValue, getState, dispatch }: any
  ) => {
    try {
      const country = getState().landingPage.selectedCountry;
      body.countryId = country.value;
      const levels = await getAllLevelsApi(body);
      return levels?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const careerListingSlice = createSlice({
  name: "careerListing",
  initialState: initialState,
  reducers: {
    setCareerListing: (state, action) => {
      return { ...state, ...action.payload };
    },
    reset: () => initialState,
  },

  extraReducers: (builder) => {
    builder.addCase(getLevels.pending, (state, action) => {
      state.loadingLevels = true;
    });
    builder.addCase(getLevels.fulfilled, (state, action) => {
      state.loadingLevels = false;
      state.levels = action.payload;
      state.status = "GET_LEVELS_SUCCESS";
    });
    builder.addCase(getLevels.rejected, (state, action) => {
      state.loadingLevels = false;
    });
    builder.addCase(getAllCareers.pending, (state, action) => {
      state.loadingCareers = true;
    });
    builder.addCase(getAllCareers.fulfilled, (state, action) => {
      state.loadingCareers = false;
      state.careers = action.payload.items ? action.payload.items : action.payload;
      state.totalCareers = action.payload.meta?.totalItems
      state.status = "GET_LEVELS_SUCCESS";
    });
    builder.addCase(getAllCareers.rejected, (state, action) => {
      state.loadingCareers = false;
    });
  },
});

export const careerListingReducer = careerListingSlice.reducer;

export const careerListingActions = {
  ...careerListingSlice.actions,
  getAllCareers,
  getLevels,
};
