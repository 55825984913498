import React, { useState } from "react";
import { ListItemsPartiallyWrapper } from "./listItemsPartially.styled";
import SubmitButton from "&components/buttons/submitButton/submitButton.component";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import { getLastExamActivity } from "&utils/hasExam";

interface Props {
  data?: any[];
  renderItem: (item: any, index: number) => any;
  className?: string;
  cutOffIndex?: number;
  stepNumber?: number;
  showAllOnLoadMore?: boolean;
  loadMoreClassName?: string;
  loadMoreButtonTitle?: string;
  isBlur?: boolean;
  stickyExam?: boolean;
}

function chunk(items: any[], size: number) {
  const chunks = [];
  items = [].concat(...items);

  while (items.length) {
    chunks.push(items.splice(0, size));
  }

  return chunks;
}

const ListItemsPartially = (props: Props) => {
  const {
    data = [],
    renderItem,
    className,
    cutOffIndex = 1,
    stepNumber = 3,
    showAllOnLoadMore = false,
    loadMoreClassName = "",
    isBlur = false,
    loadMoreButtonTitle,
    stickyExam,
  } = props;

  const { loginWithRedirect } = useAuth0();

  const { t } = useTranslation(["listingPage"]);

  // const navigate = useNavigate();
  const modifiedData =
    data && data?.length > 0
      ? data?.map((item, index) => {
          return { ...item, index: index };
        })
      : [];

  const newCutOff =
    data?.length - 1 <= cutOffIndex ? data?.length - 1 : cutOffIndex;

  const firstPart = modifiedData?.slice(0, newCutOff + 1);

  const secondPart = modifiedData?.slice(newCutOff + 1);
  const secondPartArrays = chunk(
    secondPart,
    showAllOnLoadMore ? modifiedData?.length : stepNumber
  );

  const [appearIndex, setAppearIndex] = useState(
    isBlur ? secondPartArrays?.length : 0
  );

  const handleLoadMore = () => {
    isBlur ? loginWithRedirect() : setAppearIndex(appearIndex + 1);
  };

  const lastExam = getLastExamActivity(data);

  return (
    <ListItemsPartiallyWrapper className={className} isBlur={isBlur}>
      {firstPart.map((item) => renderItem(item, item.index))}
      <div className="second-part-wrapper">
        {isBlur && <div className="blur" />}
        {secondPartArrays?.map(
          (items, index) =>
            index < appearIndex &&
            items.map((item) => renderItem(item, item.index))
        )}
        {data.length > 0 &&
          (isBlur || appearIndex <= secondPartArrays.length - 1) && (
            <div
              className={`load-more-background-wrapper ${loadMoreClassName}`}
            >
              <SubmitButton
                titleClassName="load-more-title"
                className={"button-wrapper"}
                id={"load-more-button"}
                title={
                  isBlur ? t("SIGNUP") : loadMoreButtonTitle || t("LOAD_MORE")
                }
                handleClick={handleLoadMore}
              />
            </div>
          )}
        {stickyExam && appearIndex <= secondPartArrays.length - 1 && lastExam
          ? renderItem(lastExam, data && data?.length - 1)
          : null}
      </div>
    </ListItemsPartiallyWrapper>
  );
};

export default ListItemsPartially;
