import React, { useEffect, useRef, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

import { RootState } from "&store/store";
import { opportunitiesListingActions } from "./opportunitiesListing.slice";
import { OpportunitiesListingWrapper } from "./opportunitiesListing.styled";
import ListingPageHeader from "&components/headers/listingPageHeader/listingPageHeader.component";
import SearchSortFilterBar from "&components/searchSortFilterBar/searchSortFilterBar.component";
import Container from "&components/container/container.component";
import CircularLoader from "&components/circularLoader/circularLoader.component";
import PaginationComponent from "&components/pagination/pagination.component";
import { defaultOpportunitiesSearchParams } from "&assets/constants/statics";
import MatchingOpportunitiesCard from "&components/matchingOpportunitiesCard/matchingOpportunitiesCard.component";
import {
  appliedFilter,
  buildCareerList,
  buildDropDownList,
  buildJobSourcesList,
  querySelector,
} from "&utils/searchSortFilter/searchSortFilterUtils";
import { careerListingActions } from "&features/careerListing/careerListing.slice";
import { translationSelector } from "&utils/translationSelector";

type ReduxProps = ConnectedProps<typeof connector>;

const OpportunitiesListingComponent = (props: ReduxProps) => {
  const navigate = useNavigate();
  const {
    t,
    // i18n
  } = useTranslation(["opportunitiesListing"]); // Make sure namespace is added to locales

  const ref = useRef<any>(null);

  const {
    jobs,
    status,
    loading,
    getJobs,
    selectedCountry,
    pathsHistory,
    settings,
    user,
    careers,
    getAllCareers,
  } = props;

  const [urlSearchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    getAllCareers({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("language")]);

  const appliedStatusFormatter: any = {
    Applied: "APPLIED",
  };

  useEffect(() => {
    if (status && status === "GET_JOBS_SUCCESS") {
      ref?.current?.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  }, [status]);

  const searchParams: any = {
    page: urlSearchParams.get("page") || defaultOpportunitiesSearchParams.page,
    query:
      urlSearchParams.get("query") || defaultOpportunitiesSearchParams.query,
    dir: "DESC" || undefined,
    countryId: selectedCountry.value,
    applied: urlSearchParams.get("applied") || "all",
    source:
      urlSearchParams.get("source") ||
      defaultOpportunitiesSearchParams.job_source,
    career:
      urlSearchParams.get("career") || defaultOpportunitiesSearchParams.career,
  };

  // const isArabic = i18n.language === "ar";
  // const sortByObject = {
  //   datePosted: t("LATEST"),
  //   popular: t("POPULAR"),
  //   [isArabic ? "titleAr" : "title"]: t("NAME"),
  // };

  useEffect(() => {
    fetchPageContent(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchParams.query,
    searchParams.source,
    searchParams.applied,
    searchParams.career,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    localStorage.getItem("language"),
  ]);

  useEffect(() => {
    setSearchParams({
      ...searchParams,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [searchState, setSearchState] = useState({});

  useEffect(() => {
    const newSearchState: any = {
      source: {
        id: "source",
        title: t("PROVIDER"),
        object: buildJobSourcesList(settings?.job_source),
        list: buildDropDownList(buildJobSourcesList(settings?.job_source)),
        value: searchParams.source,
        valueItem: querySelector(
          buildDropDownList(buildJobSourcesList(settings?.job_source)),
          searchParams.source
        ),
      },
      career: {
        id: "career",
        title: t("CAREER"),
        object: buildCareerList(careers),
        list: buildDropDownList(buildCareerList(careers)),
        value: searchParams.career,
        isAutoComplete: true,
        valueItem: querySelector(
          buildDropDownList(buildCareerList(careers)),
          searchParams.career
        ),
      },
    };
    if (user) {
      newSearchState.applied = {
        id: "applied",
        title: t("JOB_APPLIED"),
        object: buildJobSourcesList(appliedFilter),
        list: buildDropDownList(buildJobSourcesList(appliedFilter)),
        value: appliedStatusFormatter[searchParams.applied],
        valueItem: querySelector(
          buildDropDownList(buildJobSourcesList(appliedFilter)),
          searchParams.applied
        ),
      };
      setSearchState(newSearchState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    user?.email,
    searchParams.applied,
    searchParams.source,
    searchParams.career,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    localStorage.getItem("language"),
  ]);

  useEffect(() => {
    fetchPageContent(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountry, localStorage.getItem("language")]);

  const headerTitle = t("FIND_OPPORTUNITY");
  const headerDescription = t("FIND_OPPORTUNITY_DESCRIPTION");
  const loadingComponent = (
    <div className="loader-wrapper">
      <CircularLoader />
    </div>
  );

  const fetchPageContent = (page: number) => {
    setSearchParams({
      ...searchParams,
      page: page.toString(),
    });

    const requestBody = {
      page: page || undefined,
      query: searchParams.query || "",
      limit: 9,
      dir: "DESC",
      countryId: selectedCountry.value,
      showApplied:
        (searchParams.applied !== "all" &&
          appliedStatusFormatter[searchParams.applied]) ||
        undefined,
      source:
        (searchParams.source !== "all" && searchParams.source) || undefined,
      careerId:
        (searchParams.career !== "all" && searchParams.career) || undefined,
    };

    getJobs(requestBody);
  };

  const totalPageNumber = jobs?.meta?.totalPages;

  const content = jobs?.items || jobs;

  const findPrevious = () => {
    let previousPath = "";
    pathsHistory?.some((path: string) => {
      if (path !== window.location.pathname) {
        previousPath = path;
        return true;
      }
    });
    return previousPath;
  };

  return (
    <OpportunitiesListingWrapper>
      <ListingPageHeader
        title={headerTitle}
        description={headerDescription}
        onBackClick={() => navigate(findPrevious())}
      />

      <Container className="listing-container">
        <SearchSortFilterBar
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          searchState={searchState}
          forwardRef={ref}
          placeHolder={t("SEARCH_JOB_OPPORTUNITIES")}
        />
        {loading ? (
          loadingComponent
        ) : (
          <div className="list-wrapper">
            {content && content?.length === 0 ? (
              <p className="no-jobs-wrapper">
                {t("NO_JOB_OPPORTUNITIES_REMAINING")}
              </p>
            ) : (
              content?.map((item: any, index: number) => (
                <div className="card" key={index}>
                  <MatchingOpportunitiesCard
                    level={item?.level?.alias}
                    title={item?.title}
                    description={item?.shortDescription}
                    provider={item?.source}
                    skills={item?.jobSkills}
                    location={item?.location}
                    id={item?.id}
                    company={item?.company ?? item?.internalCompany?.name}
                    date={item?.datePosted ?? item?.createdAt}
                    external={item?.external && item?.external === 1}
                    url={translationSelector(item, "url")}
                    buttonText={t("MORE_DETIALS")}
                  />
                </div>
              ))
            )}
          </div>
        )}

        {totalPageNumber !== 1 && (
          <PaginationComponent
            count={totalPageNumber}
            page={Number(searchParams.page)}
            onChangePage={(page) => fetchPageContent(page)}
          />
        )}
      </Container>
    </OpportunitiesListingWrapper>
  );
};

const mapStateToProps = (state: RootState) => ({
  status: state.opportunitiesListing.status,
  loading: state.opportunitiesListing.loading,
  isLoggedIn: state.login.isLoggedIn,
  jobs: state.opportunitiesListing.jobs,
  selectedCountry: state.landingPage.selectedCountry,
  user: state.login.user,
  pathsHistory: state.global.pathsHistory,
  settings: state.global.settings,
  careers: state.careerListing.careers,
});

const mapDispatchToProps = {
  getJobs: opportunitiesListingActions.getJobs,
  getJobsExternal: opportunitiesListingActions.getJobsExternal,
  getAllCareers: careerListingActions.getAllCareers,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const OpportunitiesListingComponentRedux = connector(
  OpportunitiesListingComponent
);

export { OpportunitiesListingComponentRedux as OpportunitiesListingComponent };
