import React, { useEffect, forwardRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { CertificationsCardsWrapper } from "./certificationsCards.styled";
import Container from "&components/container/container.component";
import CardsCarousel from "&components/cardsCarousel/cardsCarousel.component";
import SectionTitleButton from "&components/sectionTitleButton/sectionTitleButton.component";
import RightArrow from "&assets/images/rightArrow.svg";
import { RootState } from "&store/store";
import CircularLoader from "&components/circularLoader/circularLoader.component";
import { Exam } from "&features/listingPage/listingPage.type";
import ImageBackground from "&components/imageBackground/imageBackground.component";
import ActivityCard from "&components/cards/activityCard/activityCard.component";
import SelectDropDown from "&components/selectDropDown/selectDropDown.component";
import { useTranslation } from "react-i18next";
import { buildDropDownList, buildSourcesList, querySelector } from "&utils/searchSortFilter/searchSortFilterUtils";
import { certificationsPageActions } from "&features/certifications/certifications.slice";

interface Props {
  title: string;
  description: string;
  exams?: Exam[];
  imgBg?: boolean;
  settings: any;
  setSelectedCertificate: (item: Exam | null) => void;
  selectedCertificate: Exam | null;
  className?: string;
}

const CertificationsCards = ({
  title,
  description,
  exams,
  setSelectedCertificate,
  selectedCertificate,
  settings,
  imgBg,
  className,
}: Props) => {
  const { t } = useTranslation(["certifications"]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [urlSearchParams, setSearchParams] = useSearchParams();

  const examsRedux = useSelector((state: RootState) => state.listingPage.exams);
  const loading = useSelector((state: RootState) => state.listingPage.loadingApi);
  const user = useSelector((state: RootState) => state.login.user);
  const searchParams = {
    source: urlSearchParams.get("source") || "0",
  };

  const searchState: any = {
    id: "source",
    title: t("PROVIDERS"),
    object: buildSourcesList(settings?.source),
    list: buildDropDownList(buildSourcesList(settings?.source)),
    value: urlSearchParams.get("source") || "0",
    valueItem: querySelector(buildDropDownList(buildSourcesList(settings?.source)), searchParams.source),
  };
  useEffect(() => {
    if (exams && exams?.length === 0) {
      const requestBody = {
        source: (searchParams.source !== "0" && searchParams.source) || undefined,
      };
      dispatch(certificationsPageActions.getCertifications(requestBody));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("language")]);

  const handleSelect = (id: string, selectedItem: any) => {
    setSearchParams({
      ...searchParams,
      [id]: selectedItem?.id,
    });
  };

  const loadingComponent = (
    <div className="loader-wrapper">
      <CircularLoader />
    </div>
  );

  // const handleClickExams = (item: any) => {
  //   checkIFEnrolledLP(item.id, user)
  //     ? navigate(`/dashboard/exam-details/${item.id}`)
  //     : navigate(`/exam-details/${item.id}`);
  // };

  const handleClickCertificate = (item: Exam) => {
    setSelectedCertificate(item);
  };
  const content = exams || examsRedux?.items;

  const renderList = () => {
    return (
      <>
        {loading ? (
          loadingComponent
        ) : (
          <CardsCarousel>
            {content.map((item: Exam, index: number) => {
              return (
                <div
                  className="cards"
                  key={index}
                  style={{
                    position: "relative",
                    overflow: "visible",
                  }}
                >
                  <ActivityCard
                    key={index}
                    type="exam"
                    exam={item}
                    handleClick={() => handleClickCertificate(item)}
                    wrapperClassName={selectedCertificate?.id === item.id ? "selected-certificate" : ""}
                    hasSponsorship={item.sponsorshipType === "voucher"}
                  />
                  {selectedCertificate?.id === item.id && (
                    <img
                      src={RightArrow}
                      alt="right-arrow"
                      className="arrow-down"
                      onClick={() => handleClickCertificate(item)}
                    />
                  )}
                </div>
              );
            })}
          </CardsCarousel>
        )}
      </>
    );
  };
  if (imgBg)
    return (
      <ImageBackground
        title={title}
        allButtonText={description}
        children={
          content.length === 0 ? (
            <div className="no-learning-type-wrapper">{t("NO_CERTIFICATES_FOUND")}</div>
          ) : (
            renderList()
          )
        }
        onAllButtonClick={() => navigate("/listing-page?learning_activity=exams")}
      />
    );
  return (
    <CertificationsCardsWrapper className={className}>
      <Container className="certification-card-wrapper">
        <SectionTitleButton
          wrapperClassName="title-button-wrapper"
          buttonText={description}
          onButtonClick={() => navigate("/listing-page?learning_activity=exams")}
          title={title}
          isWhite={true}
          hideButton={true}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "20px",
          }}
        >
          <div>
            <div className="certificate-description">{t("DESCRIPTION_CERTIFICATION")} </div>
            <div className="certificate-description">{t("DESCRIPTION_CERTIFICATION_NOTE")} </div>
          </div>

          <SelectDropDown
            value={searchState?.valueItem}
            onSelect={(item: any) => handleSelect(searchState?.id, item)}
            list={searchState?.list}
            title={searchState?.title}
            listWrapperClassName={searchState?.listWrapperClassName}
            wrapperClassName={searchState?.wrapperClassName}
          />
        </div>
        {content.length === 0 ? (
          <div className="no-learning-type-wrapper">{t("NO_CERTIFICATES_FOUND")}</div>
        ) : (
          renderList()
        )}
      </Container>
    </CertificationsCardsWrapper>
  );
};
export default CertificationsCards;
