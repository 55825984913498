import { colors } from "&assets/constants/colors";
import { device } from "&assets/constants/responsiveness";
import styled from "styled-components";

interface GridProps {
  gridTemplate: string;
  dir: string;
  textBackground: string;
  textColor: string;
}
export const AboutGridWrapper = styled.div<GridProps>`
  display: grid;
  justify-content: center;
  grid-template-columns: ${(props) => props.gridTemplate};
  direction: ${(props) => props.dir};
  padding: 0 174px;
  height: 241px;
  gap: 16px;
  margin-top: 24px;

  .group-logo-wrapper {
    background-color: white;
    border-radius: 50%;
    width: 124px;
    height: 124px;
    flex-shrink: 0;
    border: 1px solid transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${colors.linearGradientWhite} padding-box,
      linear-gradient(56.4deg, ${colors.secondary} 8.08%, ${colors.secondary} 87.11%) border-box;
    position: absolute;
    left: ${(props) => (props.dir === "ltr" ? "calc(100% - 54px)" : 0)};
    right: ${(props) => (props.dir === "rtl" ? "calc(100% - 54px)" : 0)};
    z-index: 5;
    box-shadow: 1px 5px 22px gray;
  }
  .about-nammiskills-grid-image {
    border-radius: 40px;
    height: 241px;
  }
  .about-group-logo {
    width: 75px;
    height: 75px;
  }
  .about-grid-text-wrapper {
    position: relative;
    flex-shrink: 0;
    ${(props) => (props.theme.dir === "rtl" ? "text-align: right;" : "text-align: left;")}
    background: ${(props) => props.textBackground};
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
    border: 1px solid transparent;
  }
  .about-grid-text-wrapper p {
    color: ${(props) => props.textColor};
    font-feature-settings: "cv11" on;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    letter-spacing: -0.48px;
    width: 83%;
  }
  @media ${device.tablet} {
    grid-template-columns: 100%;
    margin-top: 72px;
    padding: 0 15px;
    height: unset;
    .group-logo-wrapper {
      width: 100px;
      height: 100px;
      left: 50px;
      right: 50px;
      top: -50px;
      margin: auto;
    }
    .about-group-logo {
      width: 55px;
      height: 55px;
    }
    .about-grid-text-wrapper {
      height: 145px;
    }
  }
  @media ${device.mobile} {
    grid-template-columns: 100%;
    margin-top: 72px;
    padding: 0 15px;
    height: unset;
    .group-logo-wrapper {
      left: 50px;
      width: 100px;
      height: 100px;
      right: 50px;
      top: -50px;
      margin: auto;
    }
    .about-grid-text-wrapper {
      height: 230px;
    }
    .about-group-logo {
      width: 55px;
      height: 55px;
    }
  }
`;
