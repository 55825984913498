import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export const downloadCV = async (props: {
  cvName: string;
  text: any;
  onFinish: () => void;
}) => {
  const { onFinish, text, cvName } = props;

  const doc = new jsPDF("p", "mm", "a4");
  const canvas = await html2canvas(text);
  const imgData = canvas.toDataURL("image/png");
  const imgWidth = 200;
  const pageHeight = 295;
  const imgHeight = (canvas.height * imgWidth) / canvas.width;
  let heightLeft = imgHeight;

  let position = 10;

  doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
  heightLeft -= pageHeight;

  while (heightLeft >= 0) {
    position += heightLeft - imgHeight; // top padding for other pages
    doc.addPage();
    doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;
  }
  doc.save(cvName);
  onFinish();
};
