import { colors } from "&assets/constants/colors";
import { device } from "&assets/constants/responsiveness";
import styled from "styled-components";

interface Props {
  isSmallImage?: boolean;
}

export const LearningActivityActionButtonsModalWrapper = styled.div<Props>`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 26px 0;
  width: 862px;
  box-sizing: border-box;

  .image-wrapper {
    display: flex;
    width: 100%;
    height: 282px;
    padding: 24px 42px;
    flex-direction: column;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    margin: 0 36px;
    gap: 10px;
    border-radius: 16px;
    background: rgba(16, 140, 210, 0.1);

    .popup-image {
      width: ${(props) => (props.isSmallImage ? "59px" : "328px")};
    }
  }

  .loader-wrapper {
    height: 500px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .popup-title {
    color: ${colors.dark};
    text-align: center;
    font-feature-settings: "cv11" on;
    font-family: Mulish;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%; /* 52.8px */
    letter-spacing: -0.96px;
    padding: 0;
    margin: 0;
  }

  .description-error-text {
    color: ${colors.dark};
    text-align: center;
    font-family: Mulish;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 28.8px */
    padding: 0;
  }
  .action-buttons {
    display: flex;
    margin-top: 10px;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
  }
  .apply-voucher-button {
    background: transparent;
    border: 1px solid ${colors.primary};
    width: auto;
    height: 38px;
    margin-top: 10px;
    ${(props) =>
      props.theme.dir === "rtl" ? "margin-left: 10px;" : "margin-right: 10px;"}
    justify-content: center;
    .MuiCircularProgress-circle {
      color: ${colors.primary};
    }
  }
  .launch-course-button-title {
    color: ${colors.white};
  }
  ,
  .apply-voucher-button-title {
    font-weight: 800;
    font-size: 14px;
    line-height: 160%;
    text-align: center;
    color: ${colors.primary};
  }
  .upload-completion-btn {
    width: auto;
    margin-top: 10px;
    height: 38px;
    justify-content: center;
    ${(props) =>
      props.theme.dir === "rtl" ? "margin-left: 10px;" : "margin-right: 10px;"}
  }
  .upload-completion-btn-text {
    font-size: 14px;
  }

  @media ${device.mobile}, ${device.tablet} {
    padding: 0;
    width: 100%;
    .image-wrapper {
      height: 200px;
      padding: 0;
      margin: 0;
      .popup-image {
        width: 200px !important;
      }
    }

    .loader-wrapper {
      height: 300px;
    }

    .popup-title {
      font-size: 20px;
    }

    .description-error-text {
      font-size: 14px;
      line-height: 120%; /* 28.8px */
    }
  }
`;
