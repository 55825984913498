
import styled from "styled-components";
import {colors} from "&assets/constants/colors";

export const SmallProfileItemWrapper = styled.div`
  display: flex;
  width: 100%;

  
  .icon-wrapper {
    ${(props) =>
            props.theme.dir === "rtl"
                    ? "margin-left: 16px;"
                    : "margin-right: 16px;"}
  }
  
  .title-detail-wrapper {
    display: flex;
    flex-direction: column;
  }
  
  .side-detail-title {
    font-weight: 700;
    font-size: 10px;
    line-height: 160%;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: ${colors.dark};
    overflow: hidden;
  }
  
  .side-detail-content-text {
    font-weight: 400;
    text-decoration: none;
    font-size: 16px;
    line-height: 160%;
    color: ${colors.primary};
  }
  
  .placeholder-text {
    color: ${colors.dark};
    opacity: 0.6;
  }
  `;

