import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {getAuthJobByIdApi, getJobByIdApi, getRelatedJobByIdApi} from "./opportunitiesDetails.api";

import { OpportunitiesDetails } from "./opportunitiesDetails.type";

const initialState: OpportunitiesDetails = {
  loading: false,
  loadingRelatedJobs: false,
  selectedJob: undefined,
  authSelectedJob: undefined,
  relatedJobs: undefined,
};

const getJobsById = createAsyncThunk(
  "opportunitiesDetails/getJobsById",
  async (id: string, { rejectWithValue, getState, dispatch }: any) => {
    try {
      const job = await getJobByIdApi(id);
      return job?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const getAuthJobsByIdApiCall = createAsyncThunk(
  "opportunitiesDetails/getAuthJobsByIdApiCall",
  async (id: string, { rejectWithValue, getState, dispatch }: any) => {
    try {
      const job = await getAuthJobByIdApi(id);
      return job?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const getRelatedJobsById = createAsyncThunk(
  "opportunitiesDetails/getRelatedJobsById",
  async (id: string, { rejectWithValue, getState, dispatch }: any) => {
    try {
      const job = await getRelatedJobByIdApi(id);
      return job?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);


const opportunitiesDetailsSlice = createSlice({
  name: "opportunitiesDetails",
  initialState: initialState,
  reducers: {
    setOpportunitiesDetails: (state, action) => {
      return { ...state, ...action.payload };
    },
    reset: () => initialState,
  },

  extraReducers: (builder) => {
    
    builder.addCase(getAuthJobsByIdApiCall.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getAuthJobsByIdApiCall.fulfilled, (state, action) => {
      state.loading = false;
      state.authSelectedJob = action.payload;
    });
    builder.addCase(getAuthJobsByIdApiCall.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(getJobsById.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getJobsById.fulfilled, (state, action) => {
      state.loading = false;
      state.selectedJob = action.payload;
    });
    builder.addCase(getJobsById.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getRelatedJobsById.pending, (state, action) => {
      state.loadingRelatedJobs = true;
    });
    builder.addCase(getRelatedJobsById.fulfilled, (state, action) => {
      state.loadingRelatedJobs = false;
      state.relatedJobs = action.payload;
    });
    builder.addCase(getRelatedJobsById.rejected, (state, action) => {
      state.loadingRelatedJobs = false;
    });
  },
});

export const opportunitiesDetailsReducer = opportunitiesDetailsSlice.reducer;

export const opportunitiesDetailsActions = {
  ...opportunitiesDetailsSlice.actions,
  getJobsById,
  getRelatedJobsById,
  getAuthJobsByIdApiCall
};
