import toHoursAndMinutes from "./fromSecondsToHoursMinutes";
import i18n from "i18next";

const secondsToHours = (seconds?: number) => {
  const dir = i18n.dir()
  const formattedDuration = seconds
    ? `${toHoursAndMinutes(seconds).h}${dir === 'rtl' ? 'س' : 'h'} ${toHoursAndMinutes(seconds).m}${dir === 'rtl' ? 'د' : 'm'}`
    : "";
  return formattedDuration;
};

export default secondsToHours;
