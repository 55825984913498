import React from "react";
import { EditRoundButtonWrapper } from "./editRoundButton.styled";
import { EditIcon } from "&assets/constants/icons";

interface Props {
  className?: string;
  onClick: () => void;
  icon?: any;
}

const EditRoundButton = (props: Props) => {
  const { className, onClick, icon } = props;
  return (
    <EditRoundButtonWrapper onClick={onClick} className={className}>
      {icon ? icon : <EditIcon />}
    </EditRoundButtonWrapper>
  );
};

export default EditRoundButton;
