import React from "react";
import { BorderlessButtonWrapper } from "./borderlessButton.styled";
import { CircularProgress } from "@mui/material";

interface Props {
  title?: string;
  className?: string;
  classNameText?: string;
  icon?: any;
  handleClick?: Function;
  loading?: boolean;
}

const BorderlessButton = (props: Props) => {
  const { title, className, classNameText, icon, handleClick, loading } = props;
  return (
    <BorderlessButtonWrapper
      className={className}
      onClick={() => handleClick && handleClick()}
    >
      {loading ? (
        <CircularProgress size={24} color={"inherit"} />
      ) : (
        <p className={`btn-title ${classNameText ? classNameText : ""}`}>
          {title}
        </p>
      )}
      {icon && icon}
    </BorderlessButtonWrapper>
  );
};

export default BorderlessButton;
