import { colors } from "&assets/constants/colors";
import { device } from "&assets/constants/responsiveness";
import styled from "styled-components";

export const LearningPathsContainerWrapper = styled.div`
  padding: 24px 175px 64px 175px;

  .loader-wrapper {
    min-height: 300px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .learning-content {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .load-button {
    background: ${colors.linearGradientWhite} padding-box, ${colors.linearGradientOne} border-box;
    border: 1px solid transparent;
    height: 57px;
    margin: auto;
    margin-top: 26px;
  }
  .load-button-text {
    font-size: 18px;
    color: ${colors.primary};
  }
  .learning-title {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 110%;
    letter-spacing: -0.02em;
    font-feature-settings: "cv11" on;
    color: ${colors.dark};
    margin-bottom: 48px;
    margin-top: 0;
  }
  @media ${device.inBetween} {
    ${(props) => (props.theme.dir === "rtl" ? "padding: 24px 40px 64px 100px;" : "padding: 24px 100px 64px 40px;")}
  }
  @media ${device.tablet} {
    ${(props) => (props.theme.dir === "rtl" ? "padding: 24px 30px 64px 70px;" : "padding: 24px 70px 64px 30px;")}
    .learning-title {
      font-size: 28px;
    }
  }
  @media ${device.mobile} {
    padding: 24px 16px 24px 16px;
    .learning-title {
      font-size: 28px;
    }
  }
`;
