export const opportunitiesListingNameSpace = {
  en: {
    FIND_OPPORTUNITY: "Find the right opportunity for you",
    FIND_OPPORTUNITY_DESCRIPTION:
      "nammiskills connects learners with career opportunities through digital skills training, empowering them to unlock their full potential. Search for available opportunities below.",
    LATEST: "Latest",
    SORT_BY: "Sort by",
    POPULAR: "Popular",
    NAME: "Name",
    SEARCH_JOB_OPPORTUNITIES: "Search for opportunities",
    PROVIDER: "Provider",
    CAREER: "Career",
    JOB_APPLIED: "Job Listing",
    MORE_DETAILS: "More details",
    NO_JOB_OPPORTUNITIES_REMAINING: "No job opportunities found",
  },
  ar: {
    FIND_OPPORTUNITY: "اعثر على الفرصة المناسبة لك",
    FIND_OPPORTUNITY_DESCRIPTION:
      'تُقرّب منصة "نمّي سكيلز" المتعلمين من الفرص الوظيفية الحالية من خلال تدريبهم على المهارات الرقمية وتمكينهم من اكتشاف وتطوير امكانياتهم. ابحث عن الفرص المتاحة أدناه',
    LATEST: "الاحدث",
    SORT_BY: "الترتيب بحسب",
    JOB_APPLIED: "قائمة الوظائف",
    NAME: "الاسم",
    POPULAR: "الشعبية",
    SEARCH_JOB_OPPORTUNITIES: "ابحث عن فرص العمل",
    PROVIDER: "مقدم الخدمة",
    CAREER: "المهنة",
    MORE_DETAILS: "المزيد من التفاصيل",
    NO_JOB_OPPORTUNITIES_REMAINING: "لا توجد فرص عمل",
  },
};
