import styled from "styled-components";
import { colors } from "&assets/constants/colors";

export const ProfileSkillsWrapper = styled.div`
  padding: 10px 0 0;
  .skill-type-wrapper {
  }

  .skill-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 160%;
    color: ${colors.dark};
    margin-top: 20px;
  }

  .skills-wrapper {
    padding: 8px 0 12px 0;
    display: flex;
    flex-wrap: wrap;
  }

  .skill-chip {
    width: fit-content;

    .skill-name-text {
      font-weight: 800;
    }
  }
`;
