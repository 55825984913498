import Card from "@mui/material/Card/Card";
import CardActions from "@mui/material/CardActions/CardActions";
import CardContent from "@mui/material/CardContent/CardContent";
import Collapse from "@mui/material/Collapse/Collapse";
import React from "react";

import { FaqCardWrapper } from "./faqCard.styled";
import PlusIcon from "&assets/images/plus.svg";
import MinusIcon from "&assets/images/minus.svg";

interface Props {
  question?: string;
  answer?: string;
  handleClick?: any;
  opened?: boolean;
}

const FaqCard = (props: Props) => {
  const { question, answer, handleClick, opened } = props;

  return (
    <FaqCardWrapper onClick={handleClick}>
      <Card className={`faq-card  ${opened ? "opened" : ""}`}>
        <CardContent className="content">
          <CardActions disableSpacing className="faq-action">
            <p className="faq-question">{question}</p>
            <div onClick={handleClick} className="actions">
              {opened ? (
                <img src={MinusIcon} alt="minus-icon" />
              ) : (
                <img src={PlusIcon} alt="plus-icon" />
              )}
            </div>
          </CardActions>
          {opened && <div className="separator" />}
        </CardContent>
        <Collapse in={opened} timeout="auto" unmountOnExit>
          <CardContent className="content">
            <p className="faq-answer">{answer}</p>
          </CardContent>
        </Collapse>
      </Card>
    </FaqCardWrapper>
  );
};

export default FaqCard;
