import styled from "styled-components";

export const CertificationsDetailsWrapper = styled.div`
  background-color: rgba(16, 140, 210, 0.1);

  .certf-details-wrapper {
    max-width: 1089px;
    margin: auto;
  }
  .certification-description {
    line-height: 160%;
  }
`;
