import React from "react";

import { OpportunitiesOrCourseWrapper } from "./opportunitiesOrCourse.styled";
import ImageBackground from "&components/imageBackground/imageBackground.component";
import GradientBackground from "&components/gradientBackground/gradientBackground.component";
import OpportunitiesCarousel from "&components/opportunitiesCarousel/opportunitiesCarousel.component";
import ActivitiesCarousel from "&components/activitiesCarousel/activitiesCarousel.component";

interface Props {
  data?: any;
  title?: string;
  allButtonText?: string;
  onAllButtonClick?: () => void;
  isCourse?: boolean;
  haveImageBackground?: boolean;
}

const OpportunitiesOrCourse = (props: Props) => {
  const {
    title = "Matching opportunities",
    allButtonText = "View all opportunities",
    onAllButtonClick = () => {},
    data,
    isCourse,
    haveImageBackground,
  } = props;

  const cardsCarousel = () => {
    return !isCourse ? (
      <OpportunitiesCarousel data={data} />
    ) : (
      <ActivitiesCarousel data={data} />
    );
  };

  return (
    data && data.length > 0 && <OpportunitiesOrCourseWrapper>
      {haveImageBackground ? (
        <ImageBackground
          title={title}
          allButtonText={allButtonText}
          onAllButtonClick={onAllButtonClick}
        >
          {cardsCarousel()}
        </ImageBackground>
      ) : (
        <GradientBackground
          title={title}
          allButtonText={allButtonText}
          onAllButtonClick={onAllButtonClick}
        >
          {cardsCarousel()}
        </GradientBackground>
      )}
    </OpportunitiesOrCourseWrapper>
  );
};

export default OpportunitiesOrCourse;
