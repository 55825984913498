export const careerMatchingNameSpace = {
  en: {
    HERE_IS_YOUR_PERSONALIZED_MATCHING_SCORE: "Getting started with nammiskills",
    YOUR_PERSONALIZED_ROADMAP:
      "Get your personalized report, including your matching scorecard and recommended learning activities, to achieve your career goals.",
    YOUR_SELECTED_CAREER: "YOUR SELECTED CAREER",
    OUR_RECOMMENDED_CAREERS: "OUR RECOMMENDED CAREERS",
    CAREER_MATCHING: "Career Matching",
    HERE_ARE_SOME_MISSING_SKILLS_FOR_YOU:
      "Here are some missing skills for you. Discover the learning pathways from nammiskills and get hired faster.",
    ENROLL_NOW: "Enroll now",
    MATCHING_OPPORTUNITIES: "Matching opportunities",
    RECOMMENDED_LEARNING_PATHWAYS: "Recommended Learning Pathways",
    SUGGESTED_JOB_OPPORTUNITIES: "Suggested Job opportunities",
    VIEW_ALL_OPPORTUNITIES: "View all opportunities",
    VIEW_CAREER: "View career",
  },
  ar: {
    HERE_IS_YOUR_PERSONALIZED_MATCHING_SCORE: "البدء مع منصة نمّي سكيلز",
    YOUR_PERSONALIZED_ROADMAP:
      "احصل على تقريرك الشخصي، بما في ذلك درجة المطابقة وأنشطة التعلم الموصى بها، وذلك لتحقيق أهدافك المهنية",
    YOUR_SELECTED_CAREER: "المهنة التي اخترتها",
    OUR_RECOMMENDED_CAREERS: "المهنة الموصى بها من جهتنا",
    CAREER_MATCHING: "مطابقة المهنة",
    MATCHING_OPPORTUNITIES: "فرص العمل المطابقة",
    HERE_ARE_SOME_MISSING_SKILLS_FOR_YOU:
      'في ما يلي بعض المهارات الناقصة لك. اكتشف المسارات التعليمية من "نمّي سكيلز" واحصل على وظيفة بشكل أسرع',
    ENROLL_NOW: "تسجل الآن",
    RECOMMENDED_LEARNING_PATHWAYS: "مسارات التعلم الموصى بها",
    SUGGESTED_JOB_OPPORTUNITIES: "فرص العمل المقترحة",
    VIEW_ALL_OPPORTUNITIES: "اظهار كل الفرص",
    VIEW_CAREER: "عرض المهنة",
  },
};
