import React, { useEffect, useState } from "react";
import { Fade } from "@mui/material";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

import { CarouselLines, Facebook, Instagram, LinkedIn, Twitter } from "&assets/constants/icons";
import BorderlessButton from "&components/buttons/borderlessButton/borderlessButton.component";
import { HeaderLandingWrapper } from "./headerLanding.styled";
import RightArrow from "&assets/images/rightArrow.svg";
import LeftArrow from "&assets/images/leftArrow.svg";
import Container from "&components/container/container.component";
import { PageSection, PageSectionContent } from "&features/landingPage/landingPage.type";
import ImageDisplay from "&components/imageDisplay/imageDisplay.component";
import { socialMediaLinks } from "&assets/constants/statics";
import { translationSelector } from "&utils/translationSelector";
import { useNavigate } from "react-router-dom";

interface Props {
  data: PageSection;
  exploreMoreHandleClick?: Function;
}

const HeaderLanding = (props: Props) => {
  const {
    data,
    // exploreMoreHandleClick
  } = props;
  const navigate = useNavigate();
  // const { loginWithRedirect } = useAuth0();
  const carouselArray = data.sectionContents;
  let [activeLine, setActiveLine] = useState(0);
  let [selectedContent, setSelectedContent] = useState<PageSectionContent | undefined>(carouselArray[0]);
  let [checked, setChecked] = useState<boolean>(true);

  useEffect(() => {
    setTimeout(() => {
      setChecked(true);
      setSelectedContent(carouselArray[activeLine]);
    }, 200);
  }, [activeLine, carouselArray]);

  const renderCarouselLines = (data: Array<PageSectionContent>) => {
    const minimumData = data?.length === 1 ? [1, 2, 3] : data;

    return minimumData.map((content, index) => {
      return (
        <div
          key={index}
          className={`line line-${index} ${activeLine === index ? "active" : ""}`}
          style={{ left: `${50 * index}px` }}
          onClick={
            carouselArray?.length > 1 && activeLine !== index
              ? () => {
                  setChecked(false);
                  setActiveLine(index);
                }
              : () => {}
          }
        >
          <CarouselLines />
          {data?.length > 1 && (
            <div className="carousel-steps">
              <p className="index-line">0{index + 1}</p>
              <div className="activation-line"></div>
            </div>
          )}
        </div>
      );
    });
  };

  return (
    <HeaderLandingWrapper activeLine={activeLine}>
      <Container className="header-wrapper">
        <Fade in={checked}>
          <p className="carousel-title carousel-title-desktop">{translationSelector(selectedContent, "title")}</p>
        </Fade>
        <Fade in={checked}>
          <p className="description">{translationSelector(selectedContent, "content")}</p>
        </Fade>
        <BorderlessButton
          title={translationSelector(selectedContent, "subTitle")}
          className="explore-carousel explore"
          handleClick={() => navigate("/about-nammiskills")}
        />

        <div className="lines">
          <Carousel
            // autoPlay={!isMobile ? true : false}
            swipeable={true}
            showStatus={false}
            showThumbs={false}
            showIndicators={false}
            showArrows={carouselArray?.length > 1}
            className="carousel-wrapper"
            selectedItem={activeLine}
            preventMovementUntilSwipeScrollTolerance={true}
            swipeScrollTolerance={50}
            renderArrowNext={(onClickHandler, hasNext, label) =>
              hasNext && (
                <div onClick={onClickHandler} title={label} className="arrows next">
                  <img src={RightArrow} alt="" />
                </div>
              )
            }
            onChange={(value) => {
              setActiveLine(value);
            }}
            renderArrowPrev={(onClickHandler, hasPrev, label) =>
              hasPrev && (
                <div onClick={onClickHandler} title={label} className="arrows prev">
                  <img src={LeftArrow} alt="" />
                </div>
              )
            }
          >
            {carouselArray.map((item, index) => {
              return (
                <div key={index} className="carousel-content">
                  <ImageDisplay
                    showOverlay={true}
                    isFromS3={true}
                    className={`carousel-image ${item.mediaType === "video" && "carousel-video"}`}
                    url={translationSelector(item, "image")}
                    mediaType={item.mediaType}
                    alt=""
                  />
                </div>
              );
            })}
          </Carousel>

          {renderCarouselLines(carouselArray)}
        </div>
        <div className="socials">
          <a href={socialMediaLinks.instagram} target="_blank" rel="noopener noreferrer" className="icon">
            <Instagram />
          </a>
          <a href={socialMediaLinks.linkedin} target="_blank" rel="noopener noreferrer" className="icon">
            <LinkedIn />
          </a>
          <a href={socialMediaLinks.twitter} target="_blank" rel="noopener noreferrer" className="icon">
            <Twitter />
          </a>
          <a href={socialMediaLinks.facebook} target="_blank" rel="noopener noreferrer" className="icon">
            <Facebook />
          </a>
        </div>
      </Container>
    </HeaderLandingWrapper>
  );
};

export default HeaderLanding;
