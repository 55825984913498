import { colors } from "&assets/constants/colors";
import { device } from "&assets/constants/responsiveness";
import styled from "styled-components";

export const InformativeCardWrapper = styled.div`
  background: ${colors.linearGradientWhite} padding-box, ${colors.linearGradientSixth} border-box;
  min-width: 400px;
  height: 280px;
  border: 1px solid transparent;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 34px;
  box-sizing: border-box;
  margin: 0 10px;
  .informative-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${colors.linearGradientSixth} padding-box, ${colors.linearGradientSeventh} border-box;
    border: 2px solid transparent;
    min-width: 76px;
    min-height: 76px;
    border-radius: 50px;
  }
  .informative-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;
    color: ${colors.dark};
    margin-top: 30px;
  }
  .informative-description {
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    color: ${colors.dark};
    margin-top: 16px;
    text-align: center;
    white-space: pre-line;
    margin-bottom: 24px;
    padding: 0 60px;
  }
  .informative-card-btn {
    width: 291px;
    height: 57px;
    margin-top: 24px;
    justify-content: center;
  }

  @media ${device.tablet} {
    min-width: 358px;
    margin: 8px 0;
    .informative-title {
      font-size: 18px;
    }
  }
  @media ${device.mobile} {
    min-width: auto;
    width: 100%;
    max-width: 400px;
    margin: 8px 0;
    .informative-title {
      font-size: 18px;
    }
    .informative-description {
      font-size: 15px;
      padding: 0;
    }
  }
`;
