import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`

*{
    font-family: 'Mulish' !important;
}

html,body{
  height:100vh;
  line-height: 17px;
  direction: ${(props) => props.theme.dir} !important;
}

.h100{
  height:100%;
}

`;

export default GlobalStyles;
