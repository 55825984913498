import React from "react";
import { ProfileSectionTitleWrapper } from "./profileSectionTitle.styled";
import {useMediaQuery} from "react-responsive";
import {size} from "&assets/constants/responsiveness";

interface Props {
  title?: string;
  rightButton?: any;
  isSmall?: boolean;
  viewMode?: boolean;
  isCentered?: boolean;
}

const ProfileSectionTitle = (props: Props) => {
  const { title, rightButton, isSmall, isCentered, viewMode } = props;

  const isMobile = useMediaQuery({ maxWidth: size.mobile });
  const isTablet = useMediaQuery({
    minWidth: size.mobile,
    maxWidth: size.tablet,
  });

  const isDesktop = !isMobile && !isTablet;

  return (
    <ProfileSectionTitleWrapper isCentered={isCentered} viewMode={viewMode} isSmall={isSmall}>
      <div className={isSmall ? "small-title-section" : "title-section"}>
        {title}
        {!isDesktop && !isSmall && !viewMode && rightButton}
      </div>
      {(isSmall || isDesktop) && !viewMode && rightButton}
    </ProfileSectionTitleWrapper>
  );
};

export default ProfileSectionTitle;
