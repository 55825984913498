import { API } from "aws-amplify";
import {
  RES_COUNTRIES,
  RES_ELIGIBILITY,
  RES_INSTITUTES,
  RES_MAJORS,
} from "&store/resources";
import { EligibilityRequest } from "&features/dashboard/screens/profile/profile.type";

const getFieldsOfStudyApi = async () => {
  let URL = RES_MAJORS;
  return API.get(process.env.REACT_APP_NAME || "", URL, {});
};

const getInstitutesApi = async () => {
  let URL = RES_INSTITUTES;
  return API.get(process.env.REACT_APP_NAME || "", URL, {});
};

const getCountriesApi = async () => {
  let URL = RES_COUNTRIES;
  return API.get(process.env.REACT_APP_NAME || "", URL, {});
};

const postEligibilityApi = async (body: EligibilityRequest) => {
  let URL = `${RES_ELIGIBILITY}`;
  const myInit = {
    body: body, // replace this with attributes you need
  };
  return API.post(process.env.REACT_APP_NAME || "", URL, myInit);
};

const updateEligibilityApi = async (body: EligibilityRequest) => {
  let URL = `${RES_ELIGIBILITY}`;
  const myInit = {
    body: body, // replace this with attributes you need
  };
  return API.put(process.env.REACT_APP_NAME || "", URL, myInit);
};

export {
  getFieldsOfStudyApi,
  postEligibilityApi,
  getInstitutesApi,
  updateEligibilityApi,
  getCountriesApi,
};
