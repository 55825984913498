export const definedObjectBuilder = (object: any) => {
  const objectReturn: any = {};
  // eslint-disable-next-line array-callback-return
  Object.keys(object).map((key: string) => {
    if (object[key]) {
      objectReturn[key] = object[key];
    }
  });
  return objectReturn;
};
