import { API } from "aws-amplify";
import {RES_PAGES, RES_SETTINGS} from "&store/resources";

const getSettingsApi = async () => {
  let URL = RES_SETTINGS;
  return API.get(process.env.REACT_APP_NAME || "", URL, {});
};

const getPageContentApi = async (page: string) => {
  let URL = `${RES_PAGES}/${page}`;
  return API.get(process.env.REACT_APP_NAME || "", URL, {});
};

export { getSettingsApi, getPageContentApi };
