import React from "react";
import { SkillChipWrapper } from "./skillChip.styled";
import { useMediaQuery } from "react-responsive";
import { size } from "&assets/constants/responsiveness";

interface Props {
  title?: string;
  className?: string;
  handleClick?: Function;
  titleClassName?: string;
  level?: number;
  sliceTitle?: boolean;
}

const SkillChip = (props: Props) => {
  const isMobile = useMediaQuery({ maxWidth: size.mobile });

  const {
    title = "",
    className,
    handleClick,
    titleClassName,
    level,
    sliceTitle = true,
  } = props;

  const titleLengthLimit = !sliceTitle
    ? title.length
    : isMobile
    ? 12
    : 30;
  const slicedTitle =
    title?.length > titleLengthLimit
      ? title?.slice(0, titleLengthLimit) + "..."
      : title;

  return (
    <SkillChipWrapper
      className={className}
      level={level}
      title={title}
      onClick={(event: any) => handleClick && handleClick(event)}
    >
      <p className={`chip-title ${titleClassName}`}>{slicedTitle}</p>
    </SkillChipWrapper>
  );
};

export default SkillChip;
