import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { GetPageListingQuery, ListingPage } from "./listingPage.type";
import { getAllCoursesApi, getAllExamsApi, getAllLearningPathwaysApi } from "&features/listingPage/listingPage.api";

const initialState: ListingPage = {
  courses: { items: [], meta: {} },
  learningPathways: { items: [], meta: {} },
  exams: { items: [], meta: {} },
  loadingApi: false,
  status: "",
  page: 1,
};

const getCourses = createAsyncThunk(
  "listingPage/getCourses",
  async (body: GetPageListingQuery, { rejectWithValue, getState }: any) => {
    try {
      const isLoggedIn = getState().login.isLoggedIn;
      const courses = await getAllCoursesApi(body, isLoggedIn);
      return courses?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const getLearningPathways = createAsyncThunk(
  "listingPage/getLearningPathways",
  async (body: GetPageListingQuery, { rejectWithValue, getState }: any) => {
    try {
      const isLoggedIn = getState().login.isLoggedIn;
      const learningPaths = await getAllLearningPathwaysApi(body, isLoggedIn);
      return learningPaths?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const getExams = createAsyncThunk(
  "listingPage/getExams",
  async (body: GetPageListingQuery, { rejectWithValue, getState }: any) => {
    try {
      const isLoggedIn = getState().login.isLoggedIn;
      const exams = await getAllExamsApi(body, isLoggedIn);
      return exams?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const listingPageSlice = createSlice({
  name: "listingPage",
  initialState: initialState,
  reducers: {
    setListingPage: (state, action) => {
      return { ...state, ...action.payload };
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    reset: () => initialState,
  },

  extraReducers: (builder) => {
    builder
      .addCase(getCourses.pending, (state, action) => {
        state.loadingApi = true;
      })
      .addCase(getCourses.fulfilled, (state, action) => {
        state.loadingApi = false;
        state.courses = action.payload;
        state.status = "GET_COURSES_SUCCESS";
      })
      .addCase(getCourses.rejected, (state, action) => {
        state.loadingApi = false;
      });
    builder
      .addCase(getLearningPathways.pending, (state, action: any) => {
        state.loadingApi = true;
      })
      .addCase(getLearningPathways.fulfilled, (state, action) => {
        state.loadingApi = false;
        state.learningPathways = action.payload;
        state.status = "GET_LEARNING_PATHWAYS_SUCCESS";
      })
      .addCase(getLearningPathways.rejected, (state, action) => {
        state.loadingApi = false;
      });

    builder
      .addCase(getExams.pending, (state, action) => {
        state.loadingApi = true;
      })
      .addCase(getExams.fulfilled, (state, action) => {
        state.loadingApi = false;
        state.exams = action.payload;
        state.status = "GET_EXAMS_SUCCESS";
      })
      .addCase(getExams.rejected, (state, action) => {
        state.loadingApi = false;
      });
  },
});

export const listingPageReducer = listingPageSlice.reducer;

export const listingPageActions = {
  ...listingPageSlice.actions,
  getCourses,
  getLearningPathways,
  getExams,
};
