import React from "react";
import { CertificationItem } from "&features/dashboard/screens/profile/profile.type";
import { ProfileEducationWrapper } from "&components/profile/profileEducation/profileEducation.styled";
import EditRoundButton from "&components/buttons/editRoundButton/editRoundButton.component";

interface Props {
  editValue?: () => void;
}
const ProfileCertificate = (props: CertificationItem & Props) => {
  const { issueDate, expiryDate, editValue, instituteName, name } = props;

  const issueDateFormatted = issueDate?.format("YYYY") || undefined;
  const expiryDateFormatted = expiryDate?.format("YYYY") || undefined;

  return (
    <ProfileEducationWrapper>
      <div className="education-title-edit-button">
        <div className="school-name-title-text">{name}</div>
        {editValue && <EditRoundButton onClick={editValue} />}
      </div>
      <div className="education-degree-text">{instituteName}</div>
      <div className="date-period-text">{`${issueDateFormatted}${
        expiryDateFormatted ? " - " + expiryDateFormatted : ""
      }`}</div>
    </ProfileEducationWrapper>
  );
};

export default ProfileCertificate;
