import { User } from "&features/login/login.type";
import { isEmpty } from "lodash";

export const checkIFEnrolledCourse = (id?: number, user?: User) => {
  const result =
    user?.userCourses && user?.completedCourses
      ? user?.userCourses
          ?.concat(user?.completedCourses)
          ?.find((course: any) => course.courseId === id)
      : {};
  if (!isEmpty(result)) {
    return true;
  }
  return false;
};

export const checkIFEnrolledLP = (id?: number, user?: User) => {
  const result = user?.userLearningPaths
    ? user?.userLearningPaths?.find((item: any) => item.learningPathId === id)
    : {};
  if (!isEmpty(result)) {
    return true;
  }
  return false;
};

export const returnLearningPathItem = (id?: number, user?: User) => {
  const result = user?.userLearningPaths
    ? user?.userLearningPaths?.find((item: any) => item.learningPathId === id)
    : {};
  if (!isEmpty(result)) {
    return result;
  }
  return null;
};

export const checkIFAnyLASponsored = (id?: number, user?: User) => {
  const userLearningPath = user?.userLearningPaths
    ? user?.userLearningPaths?.find((item: any) => item.learningPathId === id)
    : {};
  if (userLearningPath) {
    const { learningPaths } = userLearningPath;
    const result = learningPaths?.learningActivities.find((item: any) =>
      item.courses?.sponsorshipType &&
      item.courses?.sponsorshipType === "subscription" &&
      !!item.courses?.sponsored
        ? !isEmpty(user?.sponsorships) &&
          !isEmpty(user?.sponsorships[item.courses?.source])
        : false
    );
    if (!isEmpty(result)) {
      return true;
    }
  }
  return false;
};

export const checkIFLPCompleted = (id?: number, user?: User) => {
  const userLearningPath = user?.userLearningPaths
    ? user?.userLearningPaths?.find((item: any) => item.learningPathId === id)
    : {};
  if (userLearningPath) {
    const { learningPaths } = userLearningPath;
    const result = learningPaths?.learningActivities.find(
      (item: any) => !item.status && !item.exams
    );
    if (isEmpty(result)) {
      return true;
    }
  }
  return false;
};
