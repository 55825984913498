import React from "react";
import { AboutGridWrapper } from "./aboutGrid.styled";
import ImageDisplay from "&components/imageDisplay/imageDisplay.component";
import GroupLogo from "../../assets/images/about-group-logo.png";
import { GridData } from "&features/about/about.component";

const AboutGrid = ({ gridTemplate, text, imageUrl, dir, textBackground, textColor, fromS3 }: GridData) => {
  return (
    <AboutGridWrapper textColor={textColor} textBackground={textBackground} gridTemplate={gridTemplate} dir={dir}>
      <div className="about-grid-text-wrapper">
        <p>{text}</p>
        <div className="group-logo-wrapper">
          <ImageDisplay url={GroupLogo} isBackgroundImage={true} className="about-group-logo" />
        </div>
      </div>

      <ImageDisplay
        url={imageUrl}
        isBackgroundImage={true}
        isFromS3={fromS3}
        className="about-nammiskills-grid-image"
      />
    </AboutGridWrapper>
  );
};

export default AboutGrid;
