import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import {
  DashboardMenuWrapper,
  MenuButtonWrapper,
} from "./dashboardMenu.styled";
import { Book, Dashboard, SearchDocument } from "&assets/constants/icons";
import UserCircle from "&components/userCircle/userCircle.component";
import { size } from "&assets/constants/responsiveness";
import { useTranslation } from "react-i18next";

interface Props {
  viewMode?: boolean;
}

const DashboardMenu = (props: Props) => {
  const { t } = useTranslation(["dashboard"]);

  const { viewMode = false } = props;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isMobile = useMediaQuery({ maxWidth: size.mobile });
  const isTablet = useMediaQuery({
    minWidth: size.mobile,
    maxWidth: size.tablet,
  });

  const actionList = [
    {
      text: t("DASHBOARD"),
      icon: Dashboard,
      handleClick: () => {
        navigate("/dashboard");
      },
      url: ["/dashboard/main"],
    },
    {
      text: t("MY_LEARNING_ACTIVITIES"),
      icon: Book,
      handleClick: () => {
        navigate("/dashboard/activities");
      },
      url: [
        "/dashboard/activities",
        "/dashboard/learning-pathway",
        "/dashboard/course",
        "/dashboard/lab",
        "/dashboard/exam",
      ],
    },
    {
      text: t("AVAILABLE_OPPORTUNITIES"),
      icon: SearchDocument,
      handleClick: () => {
        navigate("/dashboard/opportunities");
      },
      url: ["/dashboard/opportunities"],
    },
  ];

  const checkUrl = (urls: string[]) => {
    return urls.some((url: string) =>
      url?.includes(pathname.split("/")[2] || "main")
    );
  };

  const actionbuttons = (item: any, index: number) => {
    const containUrl = checkUrl(item.url);
    return (
      <MenuButtonWrapper
        key={index}
        selected={containUrl}
        onClick={() => item?.handleClick()}
      >
        {item?.icon(containUrl ? "#FFF" : "#DADADA")}
        <span className="action-text">{item?.text}</span>
      </MenuButtonWrapper>
    );
  };
  return (
    <DashboardMenuWrapper viewMode={viewMode}>
      {!isMobile && !isTablet && (
        <UserCircle viewMode={viewMode} onClick={() => navigate("profile")} />
      )}
      {!viewMode && (
        <div className="actions-list">
          {actionList.map((item, index: number) => {
            return actionbuttons(item, index);
          })}
        </div>
      )}
    </DashboardMenuWrapper>
  );
};

export default DashboardMenu;
