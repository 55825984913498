import React, { useEffect } from "react";
import { connect, ConnectedProps, useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";
import { Helmet } from "react-helmet";

import { RootState } from "&store/store";
import { FrequentlyAskedQuestionsWrapper } from "./frequentlyAskedQuestions.styled";
import PageHeader from "&components/pageHeader/pageHeader.component";
import Container from "&components/container/container.component";
import FrequentlyAskedQuestions from "&components/frequentlyAskedQuestions/frequentlyAskedQuestions.component";
import InformativeSection from "&components/informativeSection/informativeSection.component";
import { landingPageActions } from "&features/landingPage/landingPage.slice";
import { informativeData } from "&assets/constants/statics";
import { translationSelector } from "&utils/translationSelector";

type ReduxProps = ConnectedProps<typeof connector>;

const FrequentlyAskedQuestionsComponent = (props: ReduxProps) => {
  const { t } = useTranslation(["frequentlyAskedQuestions"]);
  const dispatch = useDispatch();
  const faqs = useSelector((state: RootState) => state.landingPage?.homeContent?.sections?.faq[0]);
  useEffect(() => {
    dispatch(landingPageActions.getPagesContent());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FrequentlyAskedQuestionsWrapper>
      <Helmet>
        <title>{translationSelector(faqs, "title")}</title>
        <meta name="description" content="Frequently Asked Questions Reach out with your questions!" />
      </Helmet>
      {!isEmpty(faqs) ? (
        <>
          <PageHeader
            title={translationSelector(faqs, "title")}
            subTitle={translationSelector(faqs, "subTitle")}
            className="header"
            titleClassName="header-title"
            titleSubtitleWrapper="title-subtitle-wrap"
          />

          <FrequentlyAskedQuestions data={faqs} hideTitle={true} />
          <Container>
            <div className="question">
              <span className="title">{t("STILL_CURIOUS_TO_LEARN_MORE")}</span>
              <span className="sub-title">{t("OUR_TEAM_IS_READY_TO_SUPPORT_YOU_FURTHER")}</span>
            </div>
          </Container>
          <InformativeSection data={informativeData} />
        </>
      ) : (
        <div style={{ height: "100vh" }}></div>
      )}
    </FrequentlyAskedQuestionsWrapper>
  );
};

const mapStateToProps = (state: RootState) => ({
  homeContent: state.landingPage.homeContent,
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);
const FrequentlyAskedQuestionsComponentRedux = connector(FrequentlyAskedQuestionsComponent);

export { FrequentlyAskedQuestionsComponentRedux as FrequentlyAskedQuestionsComponent };
