import { FormInputContent, InputTypesSelector } from "&features/login/login.type";
import AutoCompleteSelectWithLabel from "&components/inputs/select/autoCompleteSelectWithTitle/autoCompleteSelectWithTitle.component";
import { ChevronDown } from "&assets/constants/icons";
import React from "react";
import InputComponent from "&components/inputs/input/input.component";
import CalendarInput from "&components/calendarInput/calendarInput.component";
import RadioGroup from "&components/radioGroup/radioGroup.component";
import ProfileCheckboxInput from "&components/inputs/profileCheckboxInput/profileCheckboxInput.component";
import PhoneNumberInput from "&components/inputs/phoneNumber/phoneNumberInput.component";

const inputSelector = (item: FormInputContent, index: number) => {
  const {
    id,
    value,
    options,
    handleChange,
    onClick,
    onEnter,
    placeHolder,
    className,
    inputType,
    disabled,
    max,
    min,
    type,
    showAll,
    error,
    multiple,
    label,
    labelIcon,
  } = item;

  const renderAutoCompleteSelect = (
    <AutoCompleteSelectWithLabel
      key={index}
      label={label}
      placeholder={placeHolder}
      disabled={disabled}
      handleChange={(event, options) => handleChange(options)}
      id={id}
      multiple={multiple}
      className={`input-width ${className}`}
      options={options || []}
      value={value}
      error={error}
      rightIcon={<ChevronDown />}
      labelIcon={labelIcon}
    />
  );
  const renderInput = (
    <InputComponent
      key={index}
      isGradient={true}
      disabled={disabled}
      type={inputType}
      value={value}
      onEnterKey={onEnter}
      wrapperClassName={`input-width ${className}`}
      onChange={(event) => handleChange(event.target.value)}
      id={id}
      label={label}
      placeholder={placeHolder}
      valid={error}
      labelIcon={labelIcon}
    />
  );

  const renderDatePicker = (
    <CalendarInput
      key={index}
      id={id}
      className={`input-width ${className}`}
      handleChange={(value) => handleChange(value)}
      isYear={type === "dateYear"}
      value={value}
      max={max}
      min={min}
      placeholder={placeHolder}
      valid={error}
      label={label}
    />
  );

  const renderRadioOptions = (
    <RadioGroup
      key={index}
      id={id}
      value={value}
      showAll={showAll}
      error={error}
      label={label}
      className={className}
      disabled={disabled}
      onChange={(value) => handleChange(value)}
      data={options || []}
    />
  );

  const renderCheckbox = (
    <ProfileCheckboxInput
      id={id}
      className={className}
      onClick={() => onClick && onClick()}
      value={value}
      label={label}
    />
  );

  const renderPhoneNumber = (
    <PhoneNumberInput id={id} className={className} onChange={handleChange} value={value} label={label} key={index} />
  );

  const selector: InputTypesSelector = {
    select: renderAutoCompleteSelect,
    text: renderInput,
    date: renderDatePicker,
    dateYear: renderDatePicker,
    radio: renderRadioOptions,
    checkbox: renderCheckbox,
    phoneNumber: renderPhoneNumber,
  };

  return type ? selector[type] : null;
};

export default inputSelector;
