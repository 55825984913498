import SubmitButton from "&components/buttons/submitButton/submitButton.component";
import React from "react";

import { InformativeCardWrapper } from "./informativeCard.styled";

interface Props {
  title?: string;
  description?: string;
  icon?: any;
  className?: string;
  buttonTitle?: string;
  handleButtonClick?: () => void;
}

const InformativeCard = (props: Props) => {
  const {
    title,
    description,
    icon,
    className = "",
    buttonTitle,
    handleButtonClick,
  } = props;
  return (
    <InformativeCardWrapper className={className}>
      <div className="informative-icon">
        <img src={icon} alt="" />
      </div>
      <span className="informative-title">{title}</span>
      <span className="informative-description">{description}</span>
      {buttonTitle && (
        <SubmitButton
          id={`${title}-btn`}
          title={buttonTitle}
          handleClick={handleButtonClick}
          className="informative-card-btn"
        ></SubmitButton>
      )}
    </InformativeCardWrapper>
  );
};

export default InformativeCard;
