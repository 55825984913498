import { colors } from "&assets/constants/colors";
import { device } from "&assets/constants/responsiveness";
import styled from "styled-components";

export const MatchingOpportunitiesCardWrapper = styled.div`
  background: ${colors.linearGradientWhite} padding-box, ${colors.linearGradientOne} border-box;
  border-radius: 16px;
  border: 1px solid transparent;
  width: 354px;
  min-height: 300px;
  box-sizing: border-box;
  padding: 20px 30px 20px;
  display: flex;
  flex-direction: column;
  align-content: center;
  position: relative;
  transition: all 0.2s ease-in;
  height: 100%;
  cursor: pointer;
  &:hover {
    background: ${colors.linearGradientWhite} padding-box, ${colors.linearGradientOne} border-box;
    box-shadow: 4px 4px 24px 0px #00000040;
    .more-details {
      background: ${colors.linearGradientThree};
    }
  }

  .details-wrapper {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    height: 125px;
    justify-content: space-between;
  }
  .card-row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 7px 0;
  }
  .content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .card-content {
    display: flex;
    align-items: center;
    .title {
      font-weight: 400;
      font-size: 14px;
      ${(props) => (props.theme.dir === "rtl" ? "margin-right: 10px;" : "margin-left: 10px;")}
      color: #4d525b;
      align-items: center;
      justify-content: center;
      display: flex;
      white-space: nowrap;
      text-transform: capitalize;
      .text {
        ${(props) => (props.theme.dir === "rtl" ? "margin-right: 6px;" : "margin-left: 6px;")}
      }
    }
    .info {
      ${(props) => (props.theme.dir === "rtl" ? "margin-right: 0;" : "margin-left: 0;")}
      ${(props) => (props.theme.dir === "rtl" ? "margin-left: 20px;" : "margin-right: 20px;")}
    }
  }
  .content {
    .title-wrapper {
      height: 65px;
      margin-top: 24px;
      margin-bottom: 10px;
      .pathway-title {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 120%;
        color: #2d2a35;
        margin: 0;
        max-height: 65px;
        overflow: hidden;
        display: -webkit-box !important;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: normal;
      }
    }

    .source-text {
    }
    .provider-content {
      display: flex;
      align-items: center;
      margin-top: 20px;
      .title {
        font-weight: 400;
        font-size: 14px;
        line-height: 28px;
        color: ${colors.lighterDark};
        margin: 10px 0;
      }
    }
    .description {
      font-family: "Mulish";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 160%;
      color: ${colors.dark};
      margin: 0px 0 10px 0;
      text-overflow: ellipsis;
      height: 80px;
      overflow: hidden;
      // Addition lines for 2 line or multiline ellipsis
      display: -webkit-box !important;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      white-space: normal;
    }

    .description-external {
      font-family: "Mulish";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 160%;
      color: ${colors.dark};
      margin: 0;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box !important;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      white-space: normal;
    }
    .info-tag {
      font-weight: 700;
      ${(props) => (props.theme.dir === "rtl" ? "margin-left: 8px;" : "margin-right: 8px;")}
      display: flex;
      justify-content: center;
    }

    td {
      height: 50px;
    }

    .popups {
      position: absolute;
      top: -38px;
      display: flex;
      .logo-icon {
        width: 70px;
        height: 70px;
        border-radius: 50px;
        box-shadow: none;
        background: ${colors.linearGradientWhite} padding-box, ${colors.linearGradientOne} border-box;
        .logo {
          width: 58px;
          height: 43px;
        }
      }
      .score-matching {
        width: 70px;
        height: 70px;
        border-radius: 50px;
        ${(props) => (props.theme.dir === "rtl" ? "margin-right: 10px;" : "margin-left: 10px;")}
        background: ${colors.linearGradientOne};
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .percentage {
          font-weight: 800;
          font-size: 16px;
          line-height: 120%;
          display: flex;
          align-items: center;
          color: ${colors.white};
        }
        .text {
          font-family: "Clash Grotesk";
          font-style: normal;
          font-weight: 600;
          font-size: 9px;
          line-height: 150%;
          letter-spacing: 0.1em;
          text-transform: uppercase;
          color: ${colors.white};
        }
      }
    }

    .chips {
      display: flex;
      margin-top: 16px;
      flex-wrap: wrap;
      max-height: 75px;
      .more-skills {
        cursor: pointer;
      }
    }
  }
  .more-details {
    width: 150px;
    height: 45px;
    margin-top: 10px;
    padding: 16px;
    transition: all 0.2s ease-in;
    .more-details-text {
      font-size: 14px;
    }
  }
  @media ${device.tablet} {
    height: auto;
    padding: 48px 16px 16px;
    width: 324px;

    .more-details {
      display: none;
    }
    .before-collapse {
      display: flex;
      flex-direction: column;
      align-content: center;
    }
    .header {
      display: flex;
      ${(props) => (props.theme.dir === "rtl" ? "margin-left: 0;" : "margin-right: 0;")}
      flex-direction: column;
      justify-content: center;
    }
    .content {
      .chips {
        display: flex;
        margin-bottom: 16px;
        margin-top: 16px;
      }
      .explore-button {
        width: 250px;
        height: 42px;
      }
      .explore-button-text {
        font-size: 16px;
      }
    }
  }
  @media ${device.mobile} {
    height: auto;
    padding: 48px 16px 16px;
    width: 324px;

    .more-details {
      display: none;
    }
    .before-collapse {
      display: flex;
      flex-direction: column;
      align-content: center;
    }
    .header {
      display: flex;
      ${(props) => (props.theme.dir === "rtl" ? "margin-left: 0;" : "margin-right: 0;")}
      flex-direction: column;
      justify-content: center;
    }
    .content {
      .chips {
        display: flex;
        margin-bottom: 16px;
        margin-top: 16px;
      }
      .explore-button {
        width: 250px;
        height: 42px;
      }
      .explore-button-text {
        font-size: 16px;
      }
    }
  }
`;
