const {
  REACT_APP_IDENTITY_POOL_ID,
  // REACT_APP_USER_POOL_ID,
  // REACT_APP_USER_POOL_WEB_CLIENT_ID,
  REACT_APP_NAME,
  REACT_APP_ENDPOINT,
  REACT_APP_BUCKET,
  REACT_APP_REGION,
  REACT_APP_CDN,
  REACT_APP_ENV,
} = process.env;

if (REACT_APP_ENV !== "PROD") {
  console.log("REACT_APP_ENV", REACT_APP_ENV);
  console.log("REACT_APP_NAME", REACT_APP_NAME);
  console.log("REACT_APP_ENDPOINT", REACT_APP_ENDPOINT);
  console.log("REACT_APP_IDENTITY_POOL_ID", REACT_APP_IDENTITY_POOL_ID);
}

const awsConfig = {
  Auth: {
    identityPoolId: REACT_APP_IDENTITY_POOL_ID,
    region: REACT_APP_REGION,
    // userPoolId: REACT_APP_USER_POOL_ID,
    // userPoolWebClientId: REACT_APP_USER_POOL_WEB_CLIENT_ID,
  },
  API: {
    endpoints: [
      {
        name: REACT_APP_NAME,
        endpoint: REACT_APP_ENDPOINT,
        custom_header: async () => {
          return {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            language: localStorage.getItem("language") ?? "en",
            iseq1: localStorage.getItem("iseq1") ?? null,
          };
        },
        // region: REACT_APP_REGION,
      },
    ],
  },
  cdnUrl: REACT_APP_CDN,
  Storage: {
    AWSS3: {
      bucket: REACT_APP_BUCKET,
      region: REACT_APP_REGION,
    },
  },
};

export default awsConfig;
