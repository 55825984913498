import { combineReducers, Reducer } from "redux";

import { loginReducer } from "&features/login/login.slice";
import { landingPageReducer } from "&features/landingPage/landingPage.slice";
import { assessmentReducer } from "&features/assessment/assessment.slice";
import { globalReducer } from "&features/global/global.slice";
import { listingPageReducer } from "&features/listingPage/listingPage.slice";
import { learningPathwayReducer } from "&features/learningPathway/learningPathway.slice";
import { careerMatchingReducer } from "&features/careerMatching/careerMatching.slice";
import { courseDetailsReducer } from "&features/courseDetails/courseDetails.slice";
import { futureCareersReducer } from "&features/futureCareers/futureCareers.slice";
import { futureSkillsLandingReducer } from "&features/futureSkillsLanding/futureSkillsLanding.slice";
import { careerListingReducer } from "&features/careerListing/careerListing.slice";
import { blogsReducer } from "&features/blogs/blogs.slice";
import { blogDetailsReducer } from "&features/blogDetails/blogDetails.slice";
import { privacyPolicyReducer } from "&features/privacyPolicy/privacyPolicy.slice";
import { termsConditionsReducer } from "&features/termsConditions/termsConditions.slice";
import { careerDetailsReducer } from "&features/careerDetails/careerDetails.slice";
import { opportunitiesDetailsReducer } from "&features/opportunitiesDetails/opportunitiesDetails.slice";
import { opportunitiesListingReducer } from "&features/opportunitiesListing/opportunitiesListing.slice";
import { contactUsReducer } from "&features/contactUs/contactUs.slice";
import { profileReducer } from "&features/dashboard/screens/profile/profile.slice";
import { dashboardReducer } from "&features/dashboard/dashboard.slice";
import { labDetailsReducer } from "&features/labDetails/labDetails.slice";
import { examDetailsReducer } from "&features/examDetails/examDetails.slice";
import { notificationsReducer } from "&features/notifications/notifications.slice";
import { certificationsPageReducer } from "&features/certifications/certifications.slice";

import { bundlesReducer } from "&features/bundles/bundles.slice";
import storage from "redux-persist/lib/storage";
import persistReducer from "redux-persist/es/persistReducer";

const bundlesPersistConfig = {
  key: "bundlesReducer",
  storage,
  whitelist: ["orderId"],
};

const createRootReducer = (routerReducer: Reducer) =>
  combineReducers({
    router: routerReducer,
    login: loginReducer,
    landingPage: landingPageReducer,
    assessment: assessmentReducer,
    global: globalReducer,
    listingPage: listingPageReducer,
    learningPathway: learningPathwayReducer,
    careerMatching: careerMatchingReducer,
    courseDetails: courseDetailsReducer,
    futureCareers: futureCareersReducer,
    futureSkills: futureSkillsLandingReducer,
    careerListing: careerListingReducer,
    blogs: blogsReducer,
    blogDetails: blogDetailsReducer,
    privacyPolicy: privacyPolicyReducer,
    termsConditions: termsConditionsReducer,
    careerDetails: careerDetailsReducer,
    opportunitiesDetails: opportunitiesDetailsReducer,
    opportunitiesListing: opportunitiesListingReducer,
    contactUs: contactUsReducer,
    profile: profileReducer,
    dashboard: dashboardReducer,
    labDetails: labDetailsReducer,
    examDetails: examDetailsReducer,
    notifications: notificationsReducer,
    certifications: certificationsPageReducer,
    bundles: persistReducer(bundlesPersistConfig, bundlesReducer),
  });
export default createRootReducer;
