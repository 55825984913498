import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import toast from "react-hot-toast";

import {
  applyForJob,
  autoAssignCareerApi,
  changePasswordApi,
  getProfileApi,
  getSkillsWithoutCareerApi,
  resendVerificationApi,
  signInApi,
  signUpApi,
  updateProfileApi,
} from "./login.api";
import { Login, LoginParams, SignUpParams, UpdateProfileBody, UpdateProfileLanguageBody } from "./login.type";
import { getSkillsByCareerIdApi } from "&features/assessment/assessment.api";
import buildSkillsObject from "&utils/buildSkillsObject";
import { GetPageListingQuery } from "&features/listingPage/listingPage.type";
import { getAllLevelsApi } from "&features/careerListing/careerListing.api";
import careersSelector from "&utils/careersSelector";
import { ERROR_TEXTS } from "&assets/constants/statics";
import { userSelector } from "&utils/profile/profileSelectors";
import {
  getUserCertificates,
  getUserCourses,
  getUserEducation,
  getUserEligibilityForm,
  getUserLearningPaths,
  getUserSponsorships,
  getUserWorkExperience,
  userExtraReducers,
} from "&features/login/user.thunks";
import { SetGoogleAnalyticsProperties } from "&utils/google-analytics/googleAnalytics";
import moment from "moment";
import { AssessmentRequest } from "&features/assessment/assessment.type";
import { translationSelector } from "&utils/translationSelector";

const initialState: Login = {
  isLoggedIn: false,
  accessToken: "",
  loading: false,
  loadingUserInfo: false,
  loadingSocial: false,
  socialLoggedIn: false,
  user: undefined,
  careers: [],
  profileFormState: {},
  loadingSkills: false,
  loadingCareers: false,
  loadingUpdateProfile: false,
  loadingUpdateProfileLanguage: false,
  updateProfileLanguageStatus: "",
  updateProfileLanguageError: "",
  updateProfileError: "",
  stepOneFormik: undefined,
  profileStep: 1,
  skills: {
    soft_skills: undefined,
    other_skills: undefined,
    hard_skills: undefined,
  },
  skillsStatus: "",
  updateProfileStatus: "",
  error: "",
  status: "",
  actionAfterSignup: undefined,
  verifyEmail: false,
  signupEmail: "",
  eligibilityStatus: "initial",
  showBanner: true,
  changeCareerMode: false,
  loadingUpdateProfileNationality: false,
  updateProfileNationalityStatus: "",
  updateProfileNationalityError: "",
};

const loginThunk = createAsyncThunk(
  "login/signInAuth",
  async (body: LoginParams, { rejectWithValue, dispatch, getState }: any) => {
    try {
      const result = await signInApi(body);
      return result?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const signUpThunk = createAsyncThunk(
  "login/signUpAuth",
  async (body: SignUpParams, { rejectWithValue, getState, dispatch }: any) => {
    try {
      dispatch(loginActions.setLogin({ signupEmail: body.email }));
      const result = await signUpApi(body);
      return result;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const getProfileThunk = createAsyncThunk(
  "login/getProfile",
  async (body: { setGAUser?: boolean }, { rejectWithValue, getState, dispatch }: any) => {
    try {
      const result = await getProfileApi();
      const profile = result.data;
      // eslint-disable-next-line no-lone-blocks
      {
        body.setGAUser &&
          SetGoogleAnalyticsProperties({
            name: "user_properties",
            value: {
              id: profile?.id?.toString(),
              gender: profile?.gender?.toLowerCase(),
              career: profile?.career?.name,
              name: profile?.name + " " + profile?.lastName,
              email: profile?.email,
              phoneNumber: profile?.phoneNumber,
              lastActive: moment().format(),
            },
          });
      }
      dispatch(loginActions.getUserEligibilityForm());
      dispatch(loginActions.getUserEducation());
      dispatch(loginActions.getUserWorkExperience());
      dispatch(loginActions.getUserCourses());
      dispatch(loginActions.getUserLearningPaths());
      dispatch(loginActions.getUserCertificates());
      dispatch(loginActions.getUserSponsorships());
      return result?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const logoutThunk = createAsyncThunk(
  "login/signOutAuth",

  async (_, { rejectWithValue, dispatch }) => {
    try {
      // return await signOutApi();
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const getCareers = createAsyncThunk(
  "login/getCareers",
  async (body: GetPageListingQuery, { rejectWithValue, getState, dispatch }: any) => {
    try {
      const country = getState().landingPage.selectedCountry;
      body.countryId = country.value;
      const levels = await getAllLevelsApi(body);
      return levels?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const getSkills = createAsyncThunk(
  "login/getSkills",
  async (body: { id?: string }, { rejectWithValue, getState, dispatch }: any) => {
    try {
      const skills = body.id ? await getSkillsByCareerIdApi(body.id) : await getSkillsWithoutCareerApi();
      return {
        skills: body.id ? skills?.data?.skills : skills?.data,
        isProfile: !body.id,
      };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const updateProfileLanguage = createAsyncThunk(
  "login/updateProfileLanguage",
  async (body: UpdateProfileLanguageBody, { rejectWithValue, getState, dispatch }: any) => {
    try {
      const user = await updateProfileApi(body);
      return user?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
const updateProfileNationality = createAsyncThunk(
  "login/updateProfileNationality",
  async (body: UpdateProfileBody, { rejectWithValue, getState, dispatch }: any) => {
    try {
      const user = await updateProfileApi(body);
      return user?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
const updateProfile = createAsyncThunk(
  "login/updateProfile",
  async (body: UpdateProfileBody, { rejectWithValue, getState, dispatch }: any) => {
    const dispatches: any = {
      workExperiences: () => dispatch(loginActions.getUserWorkExperience()),
      userCertificates: () => dispatch(loginActions.getUserCertificates()),
    };

    try {
      const user = await updateProfileApi(body);
      dispatch(loginActions.getUserEducation());
      Object.keys(body)?.map((key: any) => {
        dispatches[key] && dispatches[key]();
      });
      return user?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const resendVerification = createAsyncThunk(
  "login/resendVerification",
  async (body: { email: string }, { rejectWithValue, getState, dispatch }: any) => {
    try {
      const result = await resendVerificationApi(body);
      return result;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const changePassword = createAsyncThunk(
  "login/changePassword",
  async (email: string, { rejectWithValue, getState, dispatch }: any) => {
    try {
      const result = await changePasswordApi(email);
      return result;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const applyForJobApiCall = createAsyncThunk(
  "login/applyForJobApiCall",
  async (body: { jobId: number }, { rejectWithValue, getState, dispatch }: any) => {
    try {
      const result = await applyForJob(body);
      return result;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const autoAssignCareer = createAsyncThunk(
  "login/autoAssignCareer",
  async (body: AssessmentRequest, { rejectWithValue, getState, dispatch }: any) => {
    try {
      const matchingCareers = await autoAssignCareerApi(body);
      return matchingCareers?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const loginSlice = createSlice({
  name: "login",

  initialState: initialState,

  reducers: {
    setLogin: (state, action) => {
      return { ...state, ...action.payload };
    },
    setProfileForm: (state, action) => {
      return { ...state, profileFormState: action.payload };
    },
    setShowBanner: (state, action: PayloadAction<boolean>) => {
      return { ...state, showBanner: action.payload };
    },
    setUser: (state, action) => {
      return { ...state, user: { ...state.user, ...action.payload } };
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginThunk.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(loginThunk.fulfilled, (state, action) => {
        state.loading = false;
        localStorage.setItem("accessToken", action.payload.accessToken);
        state.error = "";
        state.status = "LOGIN_SUCCESSFULL";
      })
      .addCase(loginThunk.rejected, (state, action: any) => {
        state.isLoggedIn = false;
        state.loading = false;
        state.error = ERROR_TEXTS[action.payload.response.data.message];
        if (action.payload.response.data.message === "EMAIL_NOT_VERIFIED") state.verifyEmail = true;
      })
      .addCase(signUpThunk.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(signUpThunk.fulfilled, (state, action) => {
        localStorage.setItem("accessToken", action.payload.data.accessToken);
        state.loading = false;
        state.status = "SIGNUP_SUCCESSFULL";
      })
      .addCase(signUpThunk.rejected, (state, action: any) => {
        state.isLoggedIn = false;
        state.loading = false;
        state.error = ERROR_TEXTS[action.payload.response.data.message.replaceAll(" ", "_")];
      })

      .addCase(logoutThunk.pending, (state, action) => {
        console.log(action);
      })
      .addCase(logoutThunk.fulfilled, (state, action) => {
        state.isLoggedIn = false;
        state.accessToken = "";
        state.user = undefined;
        console.log("fulfilled", action);
      })
      .addCase(logoutThunk.rejected, (state, action) => {
        console.log("rejected", action);
      })

      .addCase(getProfileThunk.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getProfileThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.loadingSocial = false;
        state.user = userSelector(action.payload, state.user);
        state.skills = buildSkillsObject(action.payload?.userSkills, true);
        state.isLoggedIn = true;
        state.status = "GET_PROFILE_SUCCESSFULLY";
      })
      .addCase(getProfileThunk.rejected, (state, action) => {
        state.loading = false;
        state.loadingSocial = false;
        state.status = "GET_PROFILE_FAILED";
      })

      .addCase(applyForJobApiCall.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(applyForJobApiCall.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(applyForJobApiCall.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(resendVerification.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(resendVerification.fulfilled, (state, action) => {
        state.loading = false;
        console.log("fulfilled", action.payload);
      })
      .addCase(resendVerification.rejected, (state, action) => {
        state.loading = false;
        console.log("rejected", action);
      });

    userExtraReducers(builder);
    builder.addCase(getSkills.pending, (state, action) => {
      state.loadingSkills = true;
    });
    builder.addCase(getSkills.fulfilled, (state, action) => {
      state.loadingSkills = false;
      state.skills = buildSkillsObject(action.payload.skills, action.payload.isProfile);
      state.skillsStatus = "GET_SKILLS_SUCCESS";
    });
    builder.addCase(getSkills.rejected, (state, action) => {
      state.loadingSkills = false;
    });

    builder.addCase(changePassword.pending, (state, action) => {
      state.loadingUpdateProfile = true;
    });
    builder.addCase(changePassword.fulfilled, (state, action) => {
      state.loadingUpdateProfile = false;
      toast.success(
        translationSelector(
          {
            name: "Verification email link sent",
            nameAr: "تم إرسال رابط التحقق بالبريد الإلكتروني",
          },
          "name"
        )
      );
    });
    builder.addCase(changePassword.rejected, (state, action) => {
      state.loadingUpdateProfile = false;
    });

    builder.addCase(getCareers.pending, (state, action) => {
      state.loadingCareers = true;
    });
    builder.addCase(getCareers.fulfilled, (state, action) => {
      state.loadingCareers = false;
      state.careers = careersSelector(action.payload);
    });
    builder.addCase(getCareers.rejected, (state, action) => {
      state.loadingCareers = false;
    });
    builder.addCase(autoAssignCareer.pending, (state, action) => {
      state.loadingUpdateProfile = true;
    });
    builder.addCase(autoAssignCareer.fulfilled, (state, action) => {
      state.loadingUpdateProfile = false;
      state.updateProfileStatus = "UPDATE_PROFILE_SUCCESSFUL";
    });
    builder.addCase(autoAssignCareer.rejected, (state, action) => {
      state.loadingUpdateProfile = false;
    });

    builder.addCase(updateProfileNationality.pending, (state, action) => {
      state.loadingUpdateProfileNationality = true;
    });
    builder.addCase(updateProfileNationality.fulfilled, (state, action) => {
      state.loadingUpdateProfileNationality = false;
      state.updateProfileNationalityStatus = "UPDATE_PROFILE_NATIONALITY_SUCCESSFUL";
      state.updateProfileNationalityError = "";
    });
    builder.addCase(updateProfileNationality.rejected, (state, action: any) => {
      state.loadingUpdateProfileNationality = false;
      state.updateProfileNationalityStatus = "ERROR_UPDATE_PROFILE_NATIONALITY";
    });

    builder.addCase(updateProfileLanguage.pending, (state, action) => {
      state.loadingUpdateProfileLanguage = true;
    });
    builder.addCase(updateProfileLanguage.fulfilled, (state, action) => {
      state.loadingUpdateProfileLanguage = false;
      state.updateProfileLanguageStatus = "UPDATE_PROFILE_LANGUAGE_SUCCESSFUL";
      state.updateProfileLanguageError = "";
    });
    builder.addCase(updateProfileLanguage.rejected, (state, action: any) => {
      state.loadingUpdateProfileLanguage = false;
      state.updateProfileLanguageStatus = "ERROR_UPDATE_PROFILE_LANGUAGE";
    });

    builder.addCase(updateProfile.pending, (state, action) => {
      state.loadingUpdateProfile = true;
    });
    builder.addCase(updateProfile.fulfilled, (state, action) => {
      state.loadingUpdateProfile = false;
      state.user = userSelector(action.payload, state.user);
      state.skills = buildSkillsObject(action.payload?.userSkills, true);
      state.updateProfileStatus = "UPDATE_PROFILE_SUCCESSFUL";
      state.updateProfileError = "";
    });
    builder.addCase(updateProfile.rejected, (state, action: any) => {
      state.loadingUpdateProfile = false;
      state.updateProfileStatus = "ERROR_UPDATE_PROFILE";
      state.updateProfileError =
        action.payload?.response?.data?.message[0] === "phoneNumber must be a valid phone number"
          ? "Must be a valid phone number"
          : "Something went wrong";
    });
  },
});

export const loginReducer = loginSlice.reducer;
export const loginActions = {
  ...loginSlice.actions,
  loginThunk,
  logoutThunk,
  getSkills,
  getCareers,
  signUpThunk,
  getProfileThunk,
  updateProfile,
  resendVerification,
  changePassword,
  getUserEligibilityForm,
  getUserEducation,
  getUserWorkExperience,
  getUserCourses,
  getUserLearningPaths,
  applyForJobApiCall,
  getUserCertificates,
  getUserSponsorships,
  autoAssignCareer,
  updateProfileLanguage,
  updateProfileNationality,
};
