import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { Assessment } from "./assessment.type";
import { getSkillsByCareerIdApi } from "&features/assessment/assessment.api";
import buildSkillsObject from "&utils/buildSkillsObject";

const initialState: Assessment = {
  assessmentStep: 2,
  assessmentFormState: {},
  loadingAssessment: false,
  skills: {
    soft_skills: undefined,
    other_skills: undefined,
    hard_skills: undefined,
  },
  status: "",
};

const getSkills = createAsyncThunk(
  "assessment/getSkills",
  async (body: {id: string}, { rejectWithValue, getState, dispatch }: any) => {
    try {
      const skills = await getSkillsByCareerIdApi(body.id);
      return skills?.data?.skills;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const assessmentSlice = createSlice({
  name: "assessment",
  initialState: initialState,
  reducers: {
    setAssessment: (state, action) => {
      return { ...state, ...action.payload };
    },
    setAssessmentForm: (state, action) => {
      return { ...state, assessmentFormState: action.payload };
    },
    setAssessmentStep: (state, action) => {
      return { ...state, assessmentStep: action.payload };
    },
    reset: () => initialState,
  },

  extraReducers: (builder) => {
    builder.addCase(getSkills.pending, (state, action) => {
      // Write pending logic here
      state.loadingAssessment = true;
    });
    builder.addCase(getSkills.fulfilled, (state, action) => {
      // Write success logic here
      state.loadingAssessment = false;
      state.skills = buildSkillsObject(action.payload);
      state.status = "GET_SKILLS_SUCCESS";
    });
    builder.addCase(getSkills.rejected, (state, action) => {
      // Write failure logic here
      state.loadingAssessment = false;
    });
  },
});

export const assessmentReducer = assessmentSlice.reducer;

export const assessmentActions = { ...assessmentSlice.actions, getSkills };
