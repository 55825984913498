import styled from "styled-components";
import { colors } from "&assets/constants/colors";
import { device } from "&assets/constants/responsiveness";

export const NotificationsWrapper = styled.div`
  padding-bottom: 56px;
  .notifications-container {
    box-sizing: border-box;
    background: ${colors.white};
    max-width: 805px;
    min-height: 600px;
    border-radius: 24px;
    overflow: hidden;
    border: 1px solid transparent;
    background: ${colors.linearGradientWhite} padding-box, ${colors.linearGradientOne} border-box;
    padding: 24px 0;
  }
  .notification-title-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 40px;
    border-bottom: 1px solid ${colors.primary};
  }
  .notification-title {
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 120%;
    color: ${colors.dark};
  }

  .mark-all-read-button {
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 30px;
    align-self: center;
    background: ${colors.white};
    border: 1px solid ${colors.primary};
  }

  .button-title {
    color: ${colors.primary};
    text-align: center;
    font-family: Mulish;
    font-size: 12px;
    font-style: normal;
    font-weight: 800;
    line-height: 160%; /* 19.2px */
  }

  .notifications-wrapper {
  }

  .load-more-button {
    margin-top: 20px;
    .button-wrapper {
      z-index: 4;
      width: fit-content;
    }
  }

  @media ${device.mobile}, ${device.tablet} {
    .notifications-container {
      border-radius: 0;
      padding: 24px 24px;
    }

    .notifications-container {
      padding-bottom: 20px;
    }
  }
`;

interface ItemProps {
  read?: boolean;
}

export const NotificationPageItem = styled.div<ItemProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  align-items: flex-start;
  padding: 20px 40px 24px 40px;
  border-bottom: 1px solid rgba(38, 50, 56, 0.1);
  background: ${(props) => (!props.read ? "rgba(16, 140, 210, 0.10)" : "transparent")};

  .notification-title {
    color: ${colors.dark};
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
  }

  .notification-message {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
    color: ${colors.dark};
    margin: 0;

    @media ${device.mobile}, ${device.tablet} {
      font-size: 13px;
    }
  }

  .date-text {
    color: ${colors.primary};
    font-size: 9px;
    font-style: normal;
    font-weight: 800;
    line-height: 150%; /* 13.5px */
    letter-spacing: 1.8px;
    text-transform: uppercase;
    margin: 0 0 6px 0;
  }
`;
