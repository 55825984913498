
import React from "react";
import { LevelTagWrapper } from "./levelTag.styled";

interface Props {
  title: string;
  className?: string;
}

const LevelTag = (props: Props) => {
  const { title, className } = props;
  return (
    <LevelTagWrapper className={className}>
      <p>{title}</p>
    </LevelTagWrapper>
  );
};

export default LevelTag;
