import { colors } from "&assets/constants/colors";
import { device } from "&assets/constants/responsiveness";
import styled from "styled-components";

export const ContactUsWrapper = styled.div`
  background: ${colors.primaryDark};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px 247px;
  .contact-title {
    font-weight: 700;
    font-size: 48px;
    line-height: 110%;
    text-align: center;
    letter-spacing: -0.02em;
    color: ${colors.white};
  }
  .contact-description {
    font-weight: 500;
    font-size: 18px;
    line-height: 160%;
    text-align: center;
    color: ${colors.white};
    margin-top: 30px;
    padding: 0 185px;
  }
  .contact-form {
    width: 100%;
    padding: 30px;
    background: rgba(12, 103, 155, 0.4);
    border-radius: 16px;
    margin-top: 40px;
    margin-bottom: 26px;
    box-sizing: border-box;
    .input-flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
    }

    .textarea {
      height: 180px;
    }
    .MuiInputBase-inputMultiline {
      height: 100% !important;
      padding: 18px 16px;
    }
    .agreement {
      font-weight: 400;
      font-size: 12px;
      line-height: 160%;
      color: ${colors.white};
    }
    .contact-us-button {
      margin-top: 16px;
      width: 220px;
      height: 57px;
      justify-content: center;
    }
  }

  @media ${device.inBetween} {
    padding: 40px 200px;
    .contact-description {
      padding: 0 100px;
    }
  }

  @media ${device.tablet} {
    padding: 40px 30px;
    .contact-title {
      font-size: 28px;
      text-align: left;
    }
    .contact-description {
      font-size: 16px;
      text-align: left;
      padding: 0;
    }
    .contact-form {
      .input-flex {
        flex-direction: column;
      }
    }
  }
  @media ${device.mobile} {
    padding: 40px 16px;
    .contact-title {
      font-size: 28px;
      text-align: left;
    }
    .contact-description {
      font-size: 16px;
      text-align: left;
      padding: 0;
      margin-top: 20px;
    }
    .contact-form {
      padding: 16px;
      margin-top: 30px;
      .input-flex {
        flex-direction: column;
      }
    }
  }
`;
