import { Skill, Skills } from "&features/assessment/assessment.type";
import { levelMarks } from "&assets/constants/levelMarks";
const buildSkillsObject = (skillsObject?: Skills, isProfile?: boolean) => {
  const groupBySkill = (skillList: Skill[]) => {
    let skillsObject: any = {};

    const createId = (name: string, id: number) => {
      return `${name.toLowerCase().replaceAll(" ", "-")}-${id}`;
    };
    skillList?.map((skill) => {
      const skillId = createId(skill.name, skill.id);

      skillsObject[skillId] = {
        ...skill,
        skillId: skillId,
        value: {
          checked: (isProfile && !!skill?.weight) || false,
          level:
            isProfile && !!skill?.weight
              ? levelMarks[skill?.weight - 1]
              : levelMarks[0],
        },
      };
    });
    return skillsObject;
  };

  const buildOtherSkills = (skillList: Skill[]) => {
    const skillArray: Skill[] = [];

    skillList?.map((skill: Skill) => {
      const skillObject: Skill = {
        ...skill,
        value: {
          checked: (isProfile && !!skill?.weight) || false,
          level:
            isProfile && !!skill?.weight
              ? levelMarks[skill?.weight - 1]
              : levelMarks[0],
        },
      };
      skillArray.push(skillObject)
    });
    return skillArray;
  };

  if (!skillsObject) {
    return {};
  }

  return {
    hard_skills: skillsObject.hard_skills
      ? groupBySkill(skillsObject.hard_skills)
      : {},
    soft_skills: skillsObject.soft_skills
      ? groupBySkill(skillsObject.soft_skills)
      : {},
    other_skills: skillsObject.other_skills
      ? buildOtherSkills(skillsObject.other_skills)
      : [],
  };
};

export default buildSkillsObject;
