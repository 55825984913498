import { colors } from "&assets/constants/colors";
import { device } from "&assets/constants/responsiveness";
import styled from "styled-components";

export const UploadCompletionComponentWrapper = styled.div`
  .upload-completion-btn {
    background: ${colors.white};
    border: 1px solid ${colors.primary};
    width: auto;
    height: 45px;
    ${(props) =>
      props.theme.dir === "rtl"
        ? "margin-left: 10px;"
        : "margin-right: 10px;"}
    margin-top: 10px;
  }
  .upload-completion-btn-text {
    color: ${colors.primary};
  }
  @media ${device.mobile}, ${device.tablet} {
    .upload-completion-btn {
      width: fit-content;
      height: 38px;
      margin: 10px 0;
      ${(props) =>
        props.theme.dir === "rtl"
          ? "margin-left: 10px;"
          : "margin-right: 10px;"}
    }
  }
`;
