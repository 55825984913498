import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import { EnvironmentTag, NavbarWrapper } from "./navbar.styled";
import { LogutArrow, NotificationIcon, ProfilePicPlaceholder } from "&assets/constants/icons";
import LogoImg from "&assets/images/logo.svg";
import ListCaption from "&assets/images/list-caption.svg";
import Close from "&assets/images/close.svg";
import SubmitButton from "../buttons/submitButton/submitButton.component";
import ActionButton from "../buttons/actionButton/actionButton.component";
import { size } from "&assets/constants/responsiveness";
import { RootState } from "&store/store";
import LanguagePicker from "&components/languagePicker/languagePicker.component";
import { Setting } from "&features/global/global.type";
import { loginActions } from "&features/login/login.slice";
import ImageDisplay from "&components/imageDisplay/imageDisplay.component";
import { translationSelector } from "&utils/translationSelector";
import { useTranslation } from "react-i18next";
import NotificationPopover from "&components/popovers/notificationPopover/notificationPopover.component";
import { colors } from "&assets/constants/colors";
import { numberOfUnreadNotifications, checkUnreadNotifications } from "&utils/profile/checkUnreadNotifications";
import { notificationsActions } from "&features/notifications/notifications.slice";
// import { readPanelNotifications } from "&features/notifications/notifications.api";
import { Notification } from "&features/notifications/notifications.type";

interface Props {}

const Navbar = (props: Props) => {
  const showTranslation = true;
  const showNotifications = true;

  const isMobile = useMediaQuery({ maxWidth: size.mobile });
  const { logout, loginWithRedirect } = useAuth0();
  const [showNavbar, setShowNavbar] = useState(false);
  const dispatch = useDispatch();
  const headerMenu = useSelector((state: RootState) => state.global?.settings?.header_menu);
  const isLoggedIn = useSelector((state: RootState) => state.login.isLoggedIn);

  const getNotifications = () => dispatch(notificationsActions.getPanelNotifications());
  const panelNotifications: Notification[] | undefined = useSelector(
    (state: RootState) => state.notifications.panelNotifications
  );

  const { t } = useTranslation(["login"]);

  const user = useSelector((state: RootState) => state.login.user);
  const verifyEmail = useSelector((state: RootState) => state.login.verifyEmail);

  // const [ searchText, setSearchText] = useState("");

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  useEffect(() => {
    showNotifications && getNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickNotifications = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseNotifications = () => {
    showNotifications && getNotifications();
    setAnchorEl(null);
  };

  const notificationOpen = Boolean(anchorEl);
  const notificationId = notificationOpen ? "notification-popover" : undefined;

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  // const onChangeHandler = (e: any) => {
  //   setSearchText(e.target.value);
  // };

  const handleLogout = async () => {
    await logout({
      logoutParams: {
        returnTo: `${process.env.REACT_APP_AUTH0_REDIRECT_URI}`,
      },
    });
    localStorage.clear();
    dispatch(loginActions.reset());
    navigate("/");
  };

  const handleAuth0Login = async (isSignup?: boolean) => {
    if (verifyEmail) {
      await handleLogout();
    } else {
      if (typeof window != "undefined") {
        const windoW: any = window;
        windoW?.lintrk("track", { conversion_id: 14192722 });
      }
      await loginWithRedirect({
        authorizationParams: {
          screen_hint: isSignup ? "signup" : "login",
          redirect_uri: `${process.env.REACT_APP_AUTH0_REDIRECT_URI}/login`,
        },
      });
    }
  };

  const navigate = useNavigate();

  const scrollExceptions = ["/listing-page", "/certifications"];

  // I am the hook that makes your screen scroll up!!
  const location = useLocation();
  useEffect(() => {
    if (!scrollExceptions.includes(location.pathname)) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const path = location.pathname.slice(1);

  const renderNotificationButton = () => {
    const hasNotification = checkUnreadNotifications(panelNotifications);
    const numberOfNotification = numberOfUnreadNotifications(panelNotifications);

    return (
      <div>
        <button
          className="user-image-wrapper"
          aria-describedby={notificationId}
          style={{ background: notificationOpen ? colors.primaryDark : "" }}
          onClick={handleClickNotifications}
        >
          {hasNotification && (
            <div
              className="notification-dot"
              style={{
                borderColor: notificationOpen ? colors.white : colors.primary,
              }}
            >
              <p className="number-of-unread">{numberOfNotification}</p>
            </div>
          )}
          <NotificationIcon stroke={notificationOpen ? colors.white : colors.primary} />
        </button>
        <NotificationPopover
          onClose={handleCloseNotifications}
          anchorEl={anchorEl}
          id={notificationId}
          open={notificationOpen}
          notifications={panelNotifications}
          onViewAllClick={() => {
            setAnchorEl(null);
            navigate("/dashboard/notifications");
          }}
        />
      </div>
    );
  };

  const actionsMobile = () => {
    return (
      <div className="actions-responsive">
        {showTranslation && <LanguagePicker />}
        <div />
        {!isLoggedIn ? (
          <>
            <ActionButton title={t("LOGIN")} handleClick={() => handleAuth0Login()} />
            <SubmitButton
              id="redirect-to-sign-up-page"
              title={t("SIGNUP")}
              handleClick={() => handleAuth0Login(true)}
            />
          </>
        ) : (
          <SubmitButton
            id="logout-btn"
            title={t("LOGOUT")}
            handleClick={() => handleLogout()}
            className="logout-btn"
            icon={LogutArrow()}
            titleClassName="logout-btn-text"
          />
        )}
      </div>
    );
  };

  const envSelector: any = {
    DEV: "DEV",
    QA: "UAT",
    PRE_PROD: "PREPROD",
    PROD: "BETA",
  };

  const env: "DEV" | "QA" | "PROD" | any = process.env.REACT_APP_ENV || "DEV";
  const renderEnvTag = () => {
    return env !== "PROD" ? (
      <EnvironmentTag>
        <div className="env-text">{envSelector[env]}</div>
      </EnvironmentTag>
    ) : null;
  };

  return (
    <NavbarWrapper showNavbar={showNavbar}>
      <div className="container">
        <button onClick={() => navigate("/")} className="logo">
          {isMobile ? (
            <img src={LogoImg} alt="logo" width="128px" height="33px" />
          ) : (
            <img src={LogoImg} alt="logo" width="173px" height="45.27px" />
          )}
          {renderEnvTag()}
        </button>
        <div className="mobile-menu">
          {isLoggedIn && (
            <>
              {showNotifications && <div className="action-item">{renderNotificationButton()}</div>}
              <div className="action-item" onClick={() => navigate("/dashboard")}>
                <div className="user-image-wrapper">
                  {user?.image ? (
                    <ImageDisplay url={user?.image} isFromS3={true} isBackgroundImage={true} className="user-image" />
                  ) : (
                    <ProfilePicPlaceholder />
                  )}
                </div>
              </div>
            </>
          )}
          <div className="menu-icon" onClick={handleShowNavbar}>
            <img src={showNavbar ? Close : ListCaption} alt="menu-list" id="menu-list" className="burger-menu" />
          </div>
        </div>
        <div className={`nav-elements  ${showNavbar && "active"}`}>
          <div className="tabs">
            {isMobile && actionsMobile()}

            {/*<div className="search-input">*/}
            {/*  <CustomInput*/}
            {/*    value={searchText}*/}
            {/*    icon={<SearchIcon />}*/}
            {/*    placeholder="Search course, learning path or skill"*/}
            {/*    onChange={onChangeHandler}*/}
            {/*  />*/}
            {/*</div>*/}

            {isMobile && isLoggedIn && (
              <div
                onClick={() => {
                  isMobile && handleShowNavbar();
                  navigate("/dashboard/profile");
                }}
                className={`menu ${path === "dashboard/profile" ? "selected" : ""}`}
              >
                <p className="links">{t("PROFILE")}</p>
              </div>
            )}

            {headerMenu?.map((menu: Setting, index: number) => {
              return (
                // <div
                //   key={index}
                //   onClick={() => {
                //     isMobile && handleShowNavbar();
                //     if (menu.value === "external") {
                //       window.open(menu.url);
                //     } else {
                //       navigate(menu.url || "");
                //     }
                //   }}
                //   className={`menu ${path === menu.url ? "selected" : ""}`}
                // >
                //   <p className="links">{translationSelector(menu, "name")}</p>
                // </div>
                <a
                  key={index}
                  href={menu.url.includes("https://") ? menu.url : window.location.origin + "/" + menu.url}
                  className={`menu ${path === menu.url ? "selected" : ""}`}
                >
                  <p className="links">{translationSelector(menu, "name")}</p>
                </a>
              );
            })}
            {!isMobile && actionsMobile()}
          </div>
        </div>
        <div className="actions">
          {/*<div className="search action-item">*/}
          {/*  <Search />*/}
          {/*</div>*/}
          {isLoggedIn ? (
            <>
              {showNotifications && <div className="action-item">{renderNotificationButton()}</div>}
              <div className="action-item" onClick={() => navigate("/dashboard")}>
                <div className="user-image-wrapper">
                  {user?.image ? (
                    <ImageDisplay url={user?.image} isFromS3={true} isBackgroundImage={true} className="user-image" />
                  ) : (
                    <ProfilePicPlaceholder />
                  )}
                </div>
              </div>
              <div className="action-item">
                <SubmitButton
                  id="logout-btn"
                  title={t("LOGOUT")}
                  handleClick={() => handleLogout()}
                  className="logout-btn"
                  icon={LogutArrow()}
                  titleClassName="logout-btn-text"
                />
              </div>
            </>
          ) : (
            <>
              <div className="action-item">
                <ActionButton title={t("LOGIN")} handleClick={() => handleAuth0Login()} />
              </div>
              <div className="action-item">
                <SubmitButton
                  id="redirect-to-login-page"
                  title={t("SIGNUP")}
                  handleClick={() => handleAuth0Login(true)}
                />
              </div>
            </>
          )}
          {showTranslation && <LanguagePicker />}
        </div>
      </div>
    </NavbarWrapper>
  );
};

export default Navbar;
