import { colors } from "&assets/constants/colors";
import styled from "styled-components";

export const UserCircleWrapper = styled.div`
  display: flex;
  align-self: center;
  cursor: pointer;
  .progress-circle-wrapper {
    position: relative;
    display: flex;
    width: 95px;
    height: 95px;
    align-items: center;
    justify-content: center;

    .change-picture-wrapper {
      position: absolute;
      cursor: pointer;
      z-index: 1;
      display: flex;
      box-sizing: border-box;
      padding-top: 2px;
      ${(props) => (props.theme.dir === "rtl" ? "padding-right: 1px;" : "padding-left: 1px;")}
      align-items: center;
      justify-content: center;
      height: 24px;
      width: 24px;
      ${(props) => (props.theme.dir === "rtl" ? "left: -12px;" : "right: -12px;")}
      background: ${colors.linearGradientFive};
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 40px;
    }

    .progress-background {
      position: absolute;
      width: 95px !important;
      height: 95px !important;
      background: transparent;
      border: 2px solid #86bfdf;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 100px;
    }

    .MuiCircularProgress-root {
      width: 104px !important;
      height: 105px !important;
      padding: 0 !important;
      background: transparent;
      position: absolute;
      color: #10a4d2;

      svg {
        overflow: initial;
      }
      circle {
        stroke-width: 2.5px;
        stroke-linecap: round;
      }
    }

    .percentage-circle {
      box-sizing: border-box;
      width: 121.85px;
      height: 121.85px;
      border-radius: 100px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      position: relative;

      .user-profile {
        width: 81px;
        height: 81px;
        border-radius: 50px;
      }
    }
  }
  .user-information {
    display: flex;
    flex-direction: column;
    ${(props) => (props.theme.dir === "rtl" ? "margin-right: 24px;" : "margin-left: 24px;")}
    justify-content: space-around;

    .company-name {
      color: ${colors.secondaryDark};
    }

    .name-edit-section {
      display: flex;
      align-items: center;
      gap: 10px;
      .edit-button-wrapper {
        transform: scale(0.9);
      }
    }
    .user-name {
      font-weight: 700;
      font-size: 20px;
      line-height: 120%;
      color: ${colors.white};
    }
    .user-title {
      font-family: "Clash Grotesk";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 150%;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      color: ${colors.white};
    }
    .profile-completeness {
      display: flex;
      align-items: center;
      .first-text {
        font-weight: 800;
        font-size: 12px;
        line-height: 120%;
        display: flex;
        align-items: center;
        color: ${colors.white};
      }
      .percent-text {
        font-weight: 800;
        font-size: 12px;
        line-height: 120%;
        display: flex;
        align-items: center;
        color: ${colors.secondaryDark};
        margin: 0 5px;
      }

      span {
        svg {
          ${(props) => (props.theme.dir === "rtl" ? "transform: rotate(-180deg);" : "")}
        }
      }
    }
  }
`;
