import React from "react";
import { CareerLevelsWrapper, OptionsWrapper } from "./careerLevel.styled";
import { Career } from "&features/landingPage/landingPage.type";
import Container from "&components/container/container.component";
import ListCareers from "&components/listCareers/listCareers.component";
import LevelTag from "&components/tags/levelTag/levelTag.component";
import TextArrowButton from "&components/buttons/textArrowButton/textArrowButton.component";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface Props {
  level: string;
  index: number;
  careers: Career[];
  short?: boolean;
  forwardedRef?: any;
  isAssessment?: boolean;
  showAll?: boolean;
  hideJobs?: boolean;
}

const CareerLevel = (props: Props) => {
  const {
    level,
    index,
    careers,
    short,
    forwardedRef,
    isAssessment,
    showAll,
    hideJobs,
  } = props;

  const navigate = useNavigate();
  const { t } = useTranslation(["landingPage"]);

  return (
    <CareerLevelsWrapper
      short={short}
      level={level}
      index={index}
      ref={forwardedRef}
    >
      <OptionsWrapper short={short}>
        <Container className="options-container">
          {index === 0 && <div className="top-border" />}
          <LevelTag title={level} />
          {!showAll && (
            <div className="view-all-wrapper">
              <TextArrowButton
                buttonText={t("VIEW_ALL")}
                onButtonClick={() => navigate(`/careers?level=${index + 1}`)}
                isWhite={index === 2}
              />
            </div>
          )}
          <ListCareers
            short={short}
            careers={careers}
            careerIndex={index}
            level={level}
            hideJobs={hideJobs}
            showAll={showAll}
            isAssessment={isAssessment}
          />
        </Container>
      </OptionsWrapper>
      <div className="level-big-text">{level}</div>
    </CareerLevelsWrapper>
  );
};

export default CareerLevel;
