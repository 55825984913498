import styled from "styled-components";
import { colors } from "&assets/constants/colors";
import { Input } from "@mui/material";
import { device } from "&assets/constants/responsiveness";

export const SearchInputWrapper = styled(Input)`
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  padding: 14.5px 20px;
  border-radius: 10px;
  border: 1px solid ${colors.primary};

  &:before {
    border: 0 !important;
  }
  &:after {
    border: 0 !important;
  }

  input {
    &::placeholder {
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      color: ${colors.dark};
      opacity: 0.4;
    }
  }

  @media ${device.tablet} {
    padding: 14.5px 8px;
  }

  @media ${device.mobile} {
    padding: 14.5px 8px;
  }
`;
