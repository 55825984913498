import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import SubmitButton from "&components/buttons/submitButton/submitButton.component";
import CustomModal from "&components/custom-modal/custom-modal.component";
import DragDropUploadFile from "&components/inputs/dragDropUploadFile/dragDropUploadFile.component";
import { RootState } from "&store/store";
import { UploadCompletionProofWrapper } from "./uploadCompletionProof.styled";
import uploadImageToS3 from "&utils/uploadImageToS3";
import { courseDetailsActions } from "&features/courseDetails/courseDetails.slice";
import { SendManualReport } from "&features/courseDetails/courseDetails.type";
import { loginActions } from "&features/login/login.slice";
import { useTranslation } from "react-i18next";

interface Props {
  handleClose: Function;
  open: boolean;
  id: number;
  type: string;
  learningPathId?: number;
  reason?: string;
}

const UploadCompletionProof = (props: Props) => {
  const { open, handleClose, id, type, learningPathId, reason } = props;
  const user = useSelector((state: RootState) => state.login.user);
  const loadingReport = useSelector(
    (state: RootState) => state.courseDetails.loadingReport
  );
  const status = useSelector((state: RootState) => state.courseDetails.status);
  const dispatch = useDispatch();
  const [value, setValue] = useState<any>(undefined);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation(["landingPage"]);

  useEffect(() => {
    if (status === "SENT_REPORT_SUCCESSFULLY") {
      handleClose();
      dispatch(courseDetailsActions.setCourseDetails({ status: "" }));
      dispatch(loginActions.getProfileThunk({}));
      setValue(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const handleSendApproval = async () => {
    setLoading(true);
    let imageUrls: any = [];
    for (let i = 0; i < Object.keys(value).length; i = i + 1) {
      const imageKey: any = await uploadImageToS3({
        value: value[i],
        userId: user?.auth0Id,
        compress: true,
      });
      imageUrls.push(imageKey);
    }

    const body: SendManualReport = {
      type,
      imageUrls,
      learningPathId,
    };
    type === "course"
      ? (body.courseId = id)
      : type === "exam"
      ? (body.examId = id)
      : (body.labId = id);
    dispatch(courseDetailsActions.sendManualReport(body));
    setLoading(false);
  };

  return (
    <CustomModal
      handleClose={() => {
        setValue(undefined);
        handleClose();
      }}
      open={open}
    >
      <UploadCompletionProofWrapper>
        <p className="upload-title">
          {reason ? "Sorry " : `${t("GOOD_NEWS")} `} {user?.name}!
        </p>
        <p className="upload-subtitle">
          {reason ? t("COMPLETION_PROOF_REJECTED") : t("DESCRIPTION_MESSAGE")}
        </p>
        <DragDropUploadFile
          label=""
          multiple={true}
          description={t("DRAG_DROP")}
          value={value}
          handleChange={(file) => {
            setValue(file);
          }}
          removePhoto={(key?: string) => {
            const newValues = { ...value };
            key && delete newValues[key];
            setValue(isEmpty(newValues) ? undefined : newValues);
          }}
          dragDropClassName="upload-proof"
        />
        {!isEmpty(value) && (
          <SubmitButton
            loading={loading || loadingReport}
            title={t("SEND_FOR_APPROVAL")}
            id="send-approval-id"
            handleClick={handleSendApproval}
            className="send-approval-button"
            titleClassName="send-approval-title"
          />
        )}
      </UploadCompletionProofWrapper>
    </CustomModal>
  );
};

export default UploadCompletionProof;
