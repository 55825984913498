import React from "react";
import { useNavigate } from "react-router-dom";

import { BenefitsOfWrapper } from "./benifitsOf.styled";
import BenefitCard from "&components/cards/benifitCard/benifitCard.component";
import SectionTitleButton from "&components/sectionTitleButton/sectionTitleButton.component";
import Container from "&components/container/container.component";
import {
  AccessibleBenefitIcon,
  BenefitsBackgroundFrame,
  ConfidentialityBenefitIcon,
  ExpeditesBenefitIcon,
  FlexibilityBenefitIcon,
  HiringBenefitIcon,
  PersonalizedBenefitIcon,
} from "&assets/constants/icons";
import { useTranslation } from "react-i18next";
import { translationSelector } from "&utils/translationSelector";

interface Props {
  title?: string;
  hideAboutButton?: boolean;
  skillsBenefits?: boolean;
}

const benefitsListFutureCareers = [
  {
    title: "Flexible",
    titleAr: "مرن",
    description:
      "nammiskills highlights and customizes job opportunities that match with your skills set and career interests.",
    descriptionAr: 'تقوم منصة "نمّي سكيلز" بتسليط الضوء على فرص العمل التي تتماشى مع مهاراتك واهتماماتك المهنية',
    icon: <FlexibilityBenefitIcon />,
  },
  {
    title: "Accessible",
    titleAr: "سهل الوصول",
    description:
      "Earns industry-relevant certifications from global tech leaders. Set yourself apart in the job market and make your profile accessible to recruiters.",
    descriptionAr:
      "احصل على الشهادات المطلوبة حاليًا من سوق العمل والمقدمة من قبل الشركات الرائدة في التكنولوجيا. تميّز في سوق العمل واجعل ملفك الشخصي في متناول مسؤولي التوظيف",
    icon: <AccessibleBenefitIcon />,
  },
  {
    title: "Career Booster",
    titleAr: "التعزيز الوظيفي",
    description: "Boost your career by acquiring the skills and knowledge to propel you to the next level.",
    descriptionAr: "عزز حياتك المهنية من خلال اكتساب المهارات والمعرفة للتقدم في مسارك الوظيفي",
    icon: <ExpeditesBenefitIcon />,
  },
  {
    title: "Convenient",
    titleAr: "مناسب",
    description: "Learn at your own pace; anytime, anywhere.",
    descriptionAr: "تعلم بالسرعة التي تناسبك، في أي وقت وفي أي مكان",
    icon: <ConfidentialityBenefitIcon />,
  },
  {
    title: "Boost your edge",
    titleAr: "حسّن مستواك",
    description:
      "nammiskills offers world-class certificates which significantly increase your chances of being employed.",
    descriptionAr: 'تقدم منصة "نمّي سكيلز" شهادات عالمية من شأنها أن تزيد بشكل كبير فرصك في الحصول على وظيفة',
    icon: <HiringBenefitIcon />,
  },
  {
    title: "Customized approach",
    titleAr: "مقاربة شخصية",
    description:
      "nammiskills's algorithms deliver tailored training plans and personalized job recommendations, fitting your profile",
    descriptionAr: 'تُقدم منصة "نمّي سكيلز" خطط تدريب محددة وتوصيات وظيفية شخصية تتناسب مع ملفك الشخصي ',
    icon: <PersonalizedBenefitIcon />,
  },
];

const benefitsListFutureSkills = [
  {
    title: "Flexible",
    description:
      "With nammiskills, students learn at their own pace and based on their schedule, making it easy to fit education into a busy lifestyle.",
    icon: <FlexibilityBenefitIcon />,
  },
  {
    title: "Accessible",
    description:
      "nammiskills's curriculum emphasizes in practical skills with a hands-on experience, so students can apply what they've learned to real-world situations.",
    icon: <ConfidentialityBenefitIcon />,
  },
  {
    title: "Career jump",
    description:
      "nammiskills's courses help learners acquire the skills and knowledge they need to advance in their careers.",
    icon: <AccessibleBenefitIcon />,
  },
  {
    title: "High return on investment",
    description:
      "nammiskills's online courses are more affordable and accessible than traditional education, making it easier for learners to invest in their education.",
    icon: <HiringBenefitIcon />,
  },
  {
    title: "Up-to-date curriculum",
    description:
      "nammiskills's curriculum is constantly updated to ensure students learn the most current and relevant information in their field of study and/or interest.  ",
    icon: <ExpeditesBenefitIcon />,
  },
  {
    title: "Quality of learning content",
    description:
      "nammiskills offers training and education from leading global technology providers, including Amazon, Microsoft and LinkedIn. ",
    icon: <PersonalizedBenefitIcon />,
  },
];

const BenefitsOf = (props: Props) => {
  const { hideAboutButton = false, title, skillsBenefits = false } = props;
  const navigate = useNavigate();

  const benefitsList = skillsBenefits ? benefitsListFutureSkills : benefitsListFutureCareers;

  const { t } = useTranslation(["futureCareers"]);

  return (
    <BenefitsOfWrapper>
      <div className="background-frame">
        <BenefitsBackgroundFrame />
      </div>
      <Container className="benefits-container">
        <SectionTitleButton
          wrapperClassName="title-about-button-wrapper"
          title={title || t("BENEFITS_OF_NAMMISKILLS")}
          buttonText={t("ABOUT_NAMMISKILLS")}
          hideButton={hideAboutButton}
          onButtonClick={() => navigate("/about-nammiskills")}
        />
        <div className="benefits-grid">
          {benefitsList?.map((benefit, index: number) => (
            <BenefitCard
              key={index}
              title={translationSelector(benefit, "title")}
              description={translationSelector(benefit, "description")}
              icon={benefit?.icon}
            />
          ))}
        </div>
      </Container>
    </BenefitsOfWrapper>
  );
};

export default BenefitsOf;
