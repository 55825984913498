import { colors } from "&assets/constants/colors";
import { device } from "&assets/constants/responsiveness";
import styled from "styled-components";

export const TableContentWrapper = styled.div`
  .table-wrapper {
    ::-webkit-scrollbar {
      display: unset !important;
      width: 2px !important;
      height: 2px !important;
    }
    ::-webkit-scrollbar-track {
      background-color: #f1f1f1 !important;
    }
    ::-webkit-scrollbar-thumb {
      background-color: lightgray !important;
      border-radius: 4px !important;
    }
    ::-webkit-scrollbar-thumb:hover {
      background-color: #555 !important;
    }
  }
`;
export const ExamsWrapper = styled.div`
  overflow: hidden;
  &:hover {
    overflow: auto;
  }
  max-height: 400px;
  ::-webkit-scrollbar {
    display: unset !important;
    width: 2px !important;
    height: 2px !important;
  }
  ::-webkit-scrollbar-track {
    background-color: #f1f1f1 !important;
  }
  ::-webkit-scrollbar-thumb {
    background-color: lightgray !important;
    border-radius: 4px !important;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: #555 !important;
  }
  ul {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  ul li {
    height: 30px;
    min-width: 500px;
  }

  @media ${device.mobile}, ${device.tablet} {
    overflow: auto;
  }
`;
interface WithBannerProps {
  showBanner?: boolean;
}
export const BundleCardsWrapper = styled.div<WithBannerProps>`
  display: flex;
  transition: all 0.2s ease-in-out;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
  margin-top: ${(props) => (props.showBanner ? "" : "-185px")};
  z-index: 2;
  max-width: 100%;
  .bundle-card-active {
    border: 4px solid ${colors.green} !important;
    box-shadow: 4px 4px 24px 0px rgba(0, 0, 0, 0.25) !important;
  }
  .bundle-card-pending {
    border: 4px solid ${colors.secondary} !important;
    box-shadow: 4px 4px 24px 0px rgba(0, 0, 0, 0.25) !important;
  }
  .bundle-card:hover {
    transition: all 0.3s ease-in-out;
    box-shadow: 4px 4px 24px 0px rgba(0, 0, 0, 0.45);
  }
  .bundle-card {
    padding: 15px;
    transition: all 0.2s ease-in-out;
    width: 30%;
    min-width: 368px;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    background-color: white;
    border-radius: 16px;
    border: 1px solid transparent;
    min-height: 668px;
    background: ${colors.linearGradientWhite} padding-box, ${colors.linearGradientSixth} border-box;
    .bundle-price-payment-status {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      .payment-status-wrapper-active {
        border-radius: 4px;
        background-color: ${colors.green};
        padding: 1.5px 4px;
        width: fit-content;
        span {
          color: white;
          font-size: 9px;
          font-weight: 700;
          letter-spacing: 0.45px;
        }
      }

      .payment-status-wrapper-pending {
        border-radius: 4px;
        background: ${colors.linearGradientThree};
        padding: 1.5px 4px;
        width: fit-content;
        span {
          color: white;
          font-size: 9px;
          font-weight: 700;
          letter-spacing: 0.45px;
        }
      }

      .payment-status-wrapper-not-active {
        border-radius: 4px;
        background: ${colors.linearGradientSixth}!important;
        padding: 1.5px 4px;
        width: fit-content;
        span {
          color: white;
          font-size: 9px;
          font-weight: 700;
          letter-spacing: 0.45px;
        }
      }
    }
    .card-image-wrapper {
      width: 100%;
      margin: 0 auto;
      position: relative;
      .info {
        height: 23px;
        cursor: pointer;
        .logo-info-wrapper {
          display: flex;
          gap: 5px;
          align-items: center;
        }
        position: absolute;
        ${(props) => (props.theme.dir === "rtl" ? "width: 70px;" : "width: 105px;")}
        min-width: fit-content;
        background: ${colors.linearGradientOne};

        ${(props) => (props.theme.dir === "rtl" ? "border-radius: 0 8px 8px 0;" : "border-radius: 8px 0 0 8px;")}
        ${(props) => (props.theme.dir === "rtl" ? "left: 0;" : "right: 0;")}
                top: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;
        font-weight: 800;
        font-size: 12px;
        line-height: 120%;
        color: ${colors.white};
        box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.25);
      }
    }
    div img {
      width: 100%;
      margin: 0 auto;
    }
    .bundle-price-duration-pending {
      display: flex;
      align-items: center;
      color: ${colors.secondaryDark};
      gap: 5px;
    }
    .bundle-price-duration-active {
      display: flex;
      align-items: center;
      color: ${colors.green};
      gap: 5px;
    }
    .bundle-duration {
      font-size: 12px;
      margin-top: 10px;
    }

    .purchase-bundle-button {
      width: fit-content;
      min-width: fit-content;
      max-width: fit-content;
      p {
        font-size: 14px;
        font-weight: 800;
        line-height: 22.4px;
        min-width: fit-content;
        min-height: 22px;
      }
    }
    .card-buttons-wrapper {
      display: flex;
      justify-content: center;
      gap: 30px;
      position: absolute;
      bottom: 40px;
      left: 15%;
      right: 15%;
      margin: auto;
    }
    .instruction-wrapper {
      ul li {
        ${(props) => (props.theme.dir === "rtl" ? "text-align: right;" : "text-align: left;")}
      }
      height: 215px;
      overflow-y: auto;
      ::-webkit-scrollbar {
        display: none;
      }
      ::-webkit-scrollbar-track {
        background-color: #f1f1f1;
      }
      ::-webkit-scrollbar-thumb {
        background-color: lightgray;
        border-radius: 4px;
      }
      ::-webkit-scrollbar-thumb:hover {
        background-color: #555;
      }
    }
    .instruction-wrapper:hover {
      ::-webkit-scrollbar {
        display: block;
        width: 2px;
      }
      ::-webkit-scrollbar-track {
        background-color: #f1f1f1;
      }
      ::-webkit-scrollbar-thumb {
        background-color: lightgray;
        border-radius: 4px;
      }
      ::-webkit-scrollbar-thumb:hover {
        background-color: #555;
      }
    }
    .bundle-level {
      text-align: center;
      margin: 0;
      color: #2d2a35;
      max-width: 275px;
      font-size: 24px;
      font-weight: 700;
      line-height: 28.8px;
    }
    .bundle-price {
      text-align: center;
      margin: 0;
      color: ${colors.secondaryDark};
      font-size: 24px;
      font-weight: 700;
      line-height: 0;
      display: flex;
      align-items: center;
    }
    .original-price hr {
      border: 1px ${colors.secondary} solid;
      position: absolute;
      top: 8px;
      bottom: 8px;
      left: -1px;
      right: 0px;
      margin: auto;
      width: 100%;
    }
    .original-price {
      position: relative;
      color: ${colors.dark};
      font-size: 14px;
      font-weight: 700;
      line-height: 16.8px;
      text-align: center;
      margin: 0 10px;
      width: fit-content;
    }
    .bundle-price p span {
      font-size: 12px;
    }
    hr {
      width: 100%;
      border: 1px ${colors.primary} solid;
      margin: 0;
    }
    hr.active {
      border: 1px ${colors.green} solid;
    }
    hr.pending {
      border: 1px ${colors.secondary} solid;
    }
    .bundle-card-conent {
      padding: 0 16px 110px 16px;
      width: calc(100% - 32px);
      display: flex;
      flex-direction: column;
      gap: 8px;

      .purchase-bundle-button:hover {
        background: ${colors.linearGradientThree};
        border: ${colors.secondary};
      }
      .purchase-bundle-button p {
        color: white;
      }
      .purchase-bundle-button {
        background: ${colors.linearGradientOne};
        border: ${colors.secondary};
        .title {
          color: white;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      }
    }
    ul {
      line-height: 25.6px;
      font-weight: 500;
      font-size: 16px;
      color: #2c383f90;
      padding: 0 20px;
      margin: 0;
    }
  }
  @media ${device.mobile}, ${device.tablet} {
    .instruction-wrapper,
    .instruction-wrapper:hover {
      overflow-y: auto;
      ::-webkit-scrollbar {
        display: unset !important;
        width: 2px;
      }
      ::-webkit-scrollbar-track {
        background-color: #f1f1f1;
      }
      ::-webkit-scrollbar-thumb {
        background-color: lightgray;
        border-radius: 4px;
      }
      ::-webkit-scrollbar-thumb:hover {
        background-color: #555;
      }
    }
  }
`;

export const TabsWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  padding-bottom: 15px;
`;

export const Tab = styled.button`
  position: relative;
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
  outline-offset: 4px;
  transition: filter 250ms;
  user-select: none;
  touch-action: manipulation;

  .shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background: hsl(0deg 0% 0% / 0.25);
    will-change: transform;
    transform: translateY(2px);
    transition: transform 600ms cubic-bezier(0.3, 0.7, 0.4, 1);
  }

  .edge {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background: gray;
  }

  .front {
    display: block;
    position: relative;
    padding: 8px 14px;
    border-radius: 12px;
    font-size: 12px;
    color: #000;
    background: #f0f0f0;
    will-change: transform;
    transform: translateY(-4px);
    transition: transform 600ms cubic-bezier(0.3, 0.7, 0.4, 1);
  }

  &:hover {
    filter: brightness(80%);
    color: white;
  }

  &:hover .front {
    transform: translateY(-6px);
    transition: transform 250ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
  }

  &.active .front {
    transform: translateY(0px);
    transition: transform 0.3s ease-in-out;
  }

  &:hover .shadow {
    transform: translateY(4px);
    transition: transform 250ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
  }

  &.active .shadow {
    transform: translateY(1px);
    transition: transform 34ms;
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }
`;
