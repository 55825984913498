import styled from "styled-components";
import { colors } from "&assets/constants/colors";
import { device } from "&assets/constants/responsiveness";

interface Props{
  isSmall?:boolean
}
export const MatchingCareersCardsWrapper = styled.div<Props>`
  display: flex;
  width: 100%;
  height: fit-content;
  padding-bottom: 40px;
  justify-content: center;
  margin-top: ${(props) => (props.isSmall ? "10px" : "-132px")};

  .selected-career-wrapper {
    ${(props) =>
            props.theme.dir === "rtl" ? "margin-left: 16px;" : "margin-right: 16px;"}
    width: fit-content;
  }

  .recommended-careers-text-wrapper {
    display: flex;
    flex-direction: column;
    width: fit-content;
  }

  .recommended-careers-wrapper {
    display: flex;
    width: fit-content;
  }

  .text-line-wrapper {
    display: flex;
    width: 100%;
    margin-bottom: 18px;
    align-items: center;

    .line {
      border: 1px solid ${colors.primary};
      width: 100%;
      height: 0;
    }
    .text {
      z-index: 1;
      ${(props) =>
              props.theme.dir === "rtl" ? "margin-left: 21px;" : "margin-right: 21px;"}
      white-space: nowrap;
      font-weight: 800;
      font-size: 12px;
      line-height: 160%;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: ${colors.primary};
    }
  }

  .career-card-margin {
    ${(props) =>
            props.theme.dir === "rtl" ? "margin-left: 16px;" : "margin-right: 16px;"}
    &:last-child {
      ${(props) =>
              props.theme.dir === "rtl" ? "margin-left: 0;" : "margin-right: 0;"}
    }
  }

  @media ${device.tablet} {
    overflow-x: auto;
    overflow-y: visible;
    justify-content: flex-start;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .selected-career-wrapper {
      ${(props) =>
              props.theme.dir === "rtl" ? "margin-right: 16px;" : "margin-left: 16px;"}
    }

    .recommended-careers-text-wrapper{
      ${(props) =>
              props.theme.dir === "rtl" ? "margin-left: 16px;" : "margin-right: 16px;"}
    }
  }

  @media ${device.mobile} {
    overflow-x: auto;
    overflow-y: visible;
    justify-content: flex-start;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .selected-career-wrapper {
      ${(props) =>
              props.theme.dir === "rtl" ? "margin-right: 16px;" : "margin-left: 16px;"}
    }

    .recommended-careers-text-wrapper{
      ${(props) =>
              props.theme.dir === "rtl" ? "margin-left: 16px;" : "margin-right: 16px;"}
    }
  }
`;
