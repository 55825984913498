export const futureCareersNameSpace = {
  en: {
    // Write english key-mapping here
    MOST_IN_DEMAND_CAREERS: "Most in-demand careers",
    VIEW_ALL_CAREERS: "View all careers",
    VIEW_ALL: "View all",
    ASSESS_YOURSELF_AND_GET_YOUR_CAREER: "Assess yourself and get your career Matching Scorecard in 3 simple steps!",
    FEATURED_JOB_OPPORTUNITIES: "Featured Job Opportunities",
    APPLY: "Apply",
    THE_MAIN_ATTRIBUTES_OF_NAMMISKILLS: "The main attributes of nammiskills",
    BENEFITS_OF_NAMMISKILLS: "Benefits of nammiskills",
    ABOUT_NAMMISKILLS: "About nammiskills",
    FIND_CAREER: "Find the career of your dreams",
    FIND_CAREER_DESCRIPTION:
      "Whether you are an experienced professional or someone just starting to explore their options, nammiskills can help you achieve your career goals",
    SORT_BY: "Sort by",
    ALL: "All",
    VACANCIES: "Vacancies",
    POPULAR: "Popular",
    NAME: "Name",
    DATE: "Date",
    LEVEL: "Level",
    SEARCH_CAREERS: "Search careers",
    MORE_DETAILS: "More details",
  },
  ar: {
    ASSESS_YOURSELF_AND_GET_YOUR_CAREER: "قيّم نفسك واحصل على بطاقة النقاط المطابقة ب٣ خطوات بسيطة!",
    MOST_IN_DEMAND_CAREERS: "المهن الأكثر طلبًا",
    VIEW_ALL_CAREERS: "اظهار كل المهن",
    VIEW_ALL: "اظهار الكل",
    FEATURED_JOB_OPPORTUNITIES: "فرص عمل متميّزة",
    APPLY: "تقدم",
    THE_MAIN_ATTRIBUTES_OF_NAMMISKILLS: 'أهمّ ميزات "نمّي سكيلز"',
    BENEFITS_OF_NAMMISKILLS: 'أهمّ ميزات "نمّي سكيلز"',
    ABOUT_NAMMISKILLS: 'عن "نمّي سكيلز"',
    FIND_CAREER: "ابحث عن مهنة أحلامك",
    FIND_CAREER_DESCRIPTION:
      "سواء كنت محترفًا ذا خبرة أو شخصًا بدأ للتو في استكشاف خياراته المهنية، سوف تساعدك منصة نمّي سكيلز في تحقيق أهدافك",
    SORT_BY: "الترتيب بحسب",
    ALL: "الكل",
    VACANCIES: "الشواغر",
    POPULAR: "الشعبية",
    NAME: "الاسم",
    DATE: "التاريخ",
    LEVEL: "المستوى",
    SEARCH_CAREERS: "البحث عن مهن",
    MORE_DETAILS: "المزيد من التفاصيل",
  },
};
