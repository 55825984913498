import React from "react";
import { ContainerWrapper } from "./container.styled";

interface Props {
  children?: React.ReactNode;
  className?: string;
}

const Container = (props: Props) => {
  const { children, className } = props;
  return (
    <ContainerWrapper className={className ? className : ""}>
      {children}
    </ContainerWrapper>
  );
};

export default Container;
