import { device } from "&assets/constants/responsiveness";
import styled from "styled-components";

export const CardsCarouselWrapper = styled.div`
  position: relative;
  z-index: 2;
  .wrapper {
    display: flex;
    position: relative;
  }
  .page-slider {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .page-slider .wrapper {
    display: flex;
    overflow: hidden;
    width: 100%;
    justify-content: flex-start;
  }

  .forward-arrow {
    position: absolute;
    top: 50%;
    ${(props) => (props.theme.dir === "rtl" ? "left: -50px;" : "right: -50px;")}
    ${(props) =>
      props.theme.dir === "rtl"
        ? "transform: translateY(-50%) scaleX(-1);"
        : "transform: translateY(-50%);"}
    z-index: 10;
    cursor: pointer;
  }

  .back-arrow {
    position: absolute;
    top: 50%;
    ${(props) => (props.theme.dir === "rtl" ? "right: -50px;" : "left: -50px;")}
    ${(props) =>
      props.theme.dir === "rtl"
        ? "transform: translateY(-50%) scaleX(-1);"
        : "transform: translateY(-50%);"}
    z-index: 10;
    cursor: pointer;
  }
  .carousel-cards {
    font-size: 0px;
    cursor: pointer;
    overflow: hidden;
    scroll-behavior: smooth;
    display: flex;
    position: relative;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .cards {
    width: 96%;
    ${(props) =>
      props.theme.dir === "rtl" ? "margin-left: 16px;" : "margin-right: 16px;"}
  }
  @media ${device.tablet} {
    .cards {
      margin: 0;
      ${(props) =>
        props.theme.dir === "rtl"
          ? "margin-left: 16px;"
          : "margin-right: 16px;"}
    }
  }

  @media ${device.mobile} {
    .cards {
      margin: 0;
      width: initial;
      ${(props) =>
        props.theme.dir === "rtl"
          ? "margin-left: 16px;"
          : "margin-right: 16px;"}
    }
  }

  @media ${device.mobile}, ${device.tablet} {
    .carousel-cards {
      overflow: scroll;
    }
    .forward-arrow {
      display: none !important;
    }
    .back-arrow {
      display: none !important;
    }
  }
`;
