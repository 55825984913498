import React from "react";
import { TextArrowButtonWrapper } from "./textArrowButton.styled";
import { ArrowForwardIcon } from "&assets/constants/icons";
import { colors } from "&assets/constants/colors";
import stringToDashedLowercase from "&utils/stringToDashedId";

interface Props {
  buttonText: string;
  onButtonClick: () => void;
  isWhite?: boolean;
  className?: string;
}

const TextArrowButton = (props: Props) => {
  const { onButtonClick, buttonText, isWhite, className } = props;
  return (
    <TextArrowButtonWrapper
      isWhite={isWhite}
      onClick={onButtonClick}
      id={`${stringToDashedLowercase(buttonText)}-button`}
      className={className}
    >
      {buttonText} {ArrowForwardIcon(isWhite ? colors.white : colors.primary)}
    </TextArrowButtonWrapper>
  );
};

export default TextArrowButton;
