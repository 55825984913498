import React, { ChangeEvent } from "react";
import { SearchInputWrapper } from "&components/inputs/searchInput/searchInput.styled";

interface Props {
  className?: string;
  value: string;
  onChange: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  placeholder?: string;
  icon?: any;
}

const SearchInput = (props: Props) => {
  const { className, onChange, value, placeholder, icon } = props;

  return (
    <SearchInputWrapper
      value={value}
      type="text"
      endAdornment={icon}
      placeholder={placeholder}
      onChange={(event) => onChange(event)}
      className={className}
    />
  );
};

export default SearchInput;
