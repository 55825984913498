import React, { useState } from "react";
import { Box, ClickAwayListener } from "@mui/material";

import {
  LabelWrapper,
  Listbox,
  SelectDropDownWrapper,
} from "./selectDropDown.styled";
import ArrowDownOutlined from "&assets/images/arrow-down-outlined.svg";
import { translationSelector } from "&utils/translationSelector";

interface Props {
  list?: any[];
  title?: string;
  wrapperClassName?: string;
  inputClassName?: string;
  listWrapperClassName?: string;
  onSelect: (item: ListItem) => void;
  value: any;
  labelAbove?: boolean;
  disabled?: boolean;
  type?: string;
}

interface ListItem {
  id: string;
  title: string;
  flag?: any;
}

const SelectDropDown = (props: Props) => {
  const {
    list,
    title,
    wrapperClassName,
    inputClassName,
    listWrapperClassName,
    onSelect,
    value,
    labelAbove = false,
    disabled = false,
    type,
  } = props;
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    !disabled && setOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const styles = {
    position: "absolute",
    top: 49,
    right: 0,
    left: 0,
    zIndex: 6,
    bgcolor: "background.paper",
  };

  return (
    <SelectDropDownWrapper className={wrapperClassName}>
      <ClickAwayListener
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
        onClickAway={handleClickAway}
      >
        <Box sx={{ position: "relative" }}>
          {labelAbove && <LabelWrapper>{title}</LabelWrapper>}
          <div
            className={`input-wrapper ${inputClassName} ${
              open ? "opened" : ""
            }`}
          >
            <div className="item-content" onClick={handleClick}>
              {!labelAbove && <p className="title">{title}: </p>}
              {value?.flag && (
                <div className="country-flag-wrapper">
                  <img src={value?.flag} alt="country" width={21} height={20} />
                </div>
              )}

              <p
                className={`selected-title ${
                  !value?.flag && "margin-left-title"
                }`}
                title={value?.title}
              >
                {type === "country"
                  ? translationSelector(value, "name")
                  : value?.title}
              </p>
              {!disabled && (
                <img
                  src={ArrowDownOutlined}
                  alt="country"
                  className={`arrow-icon ${open && "rotate"}`}
                  width={12}
                  height={7}
                />
              )}
            </div>
          </div>
          {open ? (
            <Box sx={styles}>
              <Listbox className={listWrapperClassName}>
                {list?.map((option: ListItem, index: number) =>
                  option.title !== value?.title ? (
                    <li
                      key={index}
                      id={option.id}
                      onClick={() => {
                        setOpen(false);
                        onSelect && onSelect(option);
                      }}
                    >
                      {option?.flag && (
                        <img
                          src={option?.flag}
                          alt="country"
                          width={21}
                          height={20}
                          style={{ marginRight: 10 }}
                        />
                      )}
                      <p
                        className="list-item-name"
                        id={option.id}
                        title={option?.title}
                      >
                        {type === "country"
                          ? translationSelector(option, "name")
                          : option?.title}
                      </p>
                    </li>
                  ) : (
                    ""
                  )
                )}
              </Listbox>
            </Box>
          ) : null}
        </Box>
      </ClickAwayListener>
    </SelectDropDownWrapper>
  );
};

export default SelectDropDown;
