import { colors } from "&assets/constants/colors";
import { device } from "&assets/constants/responsiveness";
import styled from "styled-components";

export const CertificationsWrapper = styled.div`
  background: linear-gradient(
    180deg,
    rgba(207, 232, 246, 0.4) 0%,
    rgba(255, 255, 255, 1) 52%,
    rgba(255, 255, 255, 1) 88%,
    rgba(207, 232, 246, 0.4) 100%
  );

  .certification-bg {
    height: 343px;
    background: ${colors.linearGradientOne};
    position: absolute;
    top: 0;
    width: 100%;
  }
  .learning-paths {
    background-color: rgba(16, 140, 210, 0.1);
  }
  .learning-paths,
  .certificate-details {
    padding: 25px 0px;
  }

  .certification-title {
    font-size: 24px;
    font-weight: 700;
  }

  .certification-description {
    font-size: 16px;
    font-weight: normal;
  }
  .title-button-wrapper {
    .section-title {
      font-size: 32px !important;
    }
  }
  .skill-div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 2px 8px;
    width: fit-content;
    background: rgba(16, 140, 210, 0.1);
    border-radius: 8px;

    flex: none;
    order: 0;
    flex-grow: 0;
  }
  @media ${device.tablet} {
    .learning-paths,
    .certificate-details {
      padding: 40px 0px 86px 0px;
    }
  }
  @media ${device.mobile} {
    .learning-paths,
    .certificate-details {
      padding: 40px 0px 86px 0px;
    }

    .certification-bg {
      height: 100%;
      background: ${colors.linearGradientOne};
      position: relative;

      width: 100%;
    }
  }
`;
