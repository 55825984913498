import styled from "styled-components";
import { colors } from "&assets/constants/colors";

export const ProfileCheckboxInputWrapper = styled.div`
  width: fit-content;
  .checkbox-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    color: ${colors.dark};
    cursor: pointer;
    opacity: 0.7;
  }
`;
