import { colors } from "&assets/constants/colors";
import { device } from "&assets/constants/responsiveness";
import styled from "styled-components";

export const LanguagePickerWrapper = styled.div`
  ${(props) => (props.theme.dir === "ltr" ? "margin-left: 28px;" : "margin-right: 28px;")}

  .MuiSelect-icon {
    top: auto;
  }

  .select-component {
    border-bottom: 0 !important;
    &::before {
      content: none;
    }
    &::after {
      content: none;
    }
  }
  .MuiSelect-select {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 160%;
    color: ${colors.dark};
  }
  .Mui-disabled {
    color: ${colors.dark} !important;
    -webkit-text-fill-color: ${colors.dark} !important;
  }

  @media (max-width: 1550px) {
    ${(props) => (props.theme.dir === "ltr" ? "margin-left: 0;" : "margin-right: 0;")}
  }

  @media ${device.mobile} {
    ${(props) => (props.theme.dir === "ltr" ? "margin-left: 0;" : "margin-right: 0;")}
  }
`;
