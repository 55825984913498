import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAssessmentApi,
  getAssessmentLearningPathApi,
} from "./dashboard.api";

import { Dashboard } from "./dashboard.type";

const initialState: Dashboard = {
  loading: false,
  loadingLearningPath: false,
  matchingCareers: {
    career: undefined,
    suggestions: undefined,
  },
  learningPathways: [],
};

const getAssessment = createAsyncThunk(
  "dashboard/getAssessment",
  async (_, { rejectWithValue, getState, dispatch }: any) => {
    try {
      const result = await getAssessmentApi();
      return result?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const getAssessmentLearningPath = createAsyncThunk(
  "dashboard/getAssessmentLearningPath",
  async (id: string, { rejectWithValue, getState, dispatch }: any) => {
    try {
      const result = await getAssessmentLearningPathApi(id);
      return result?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: initialState,
  reducers: {
    setDashboard: (state, action) => {
      return { ...state, ...action.payload };
    },
    reset: () => initialState,
  },

  extraReducers: (builder) => {
    builder.addCase(getAssessment.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getAssessment.fulfilled, (state, action) => {
      state.loading = false;
      state.matchingCareers = action.payload;
    });
    builder.addCase(getAssessment.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getAssessmentLearningPath.pending, (state, action) => {
      state.loadingLearningPath = true;
    });
    builder.addCase(getAssessmentLearningPath.fulfilled, (state, action) => {
      state.loadingLearningPath = false;
      state.learningPathways = action.payload;
    });
    builder.addCase(getAssessmentLearningPath.rejected, (state, action) => {
      state.loadingLearningPath = false;
    });
  },
});

export const dashboardReducer = dashboardSlice.reducer;

export const dashboardActions = {
  ...dashboardSlice.actions,
  getAssessment,
  getAssessmentLearningPath,
};
