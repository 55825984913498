import React, { useEffect, useRef, useState } from "react";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SwipeableViews from "react-swipeable-views";

import { BuildProfileWrapper } from "./buildProfile.styled";
import { RootState } from "&store/store";
import TitleWithSteps from "&components/titleWithSteps/titleWithSteps.component";
import LogoImg from "&assets/images/logo-white.svg";
import Container from "&components/container/container.component";
import { ContentWrapper } from "&features/assessment/assessment.styled";
import BuildProfileStepOne from "&components/profile/buildProfileStepOne/buildProfileStepOne.component";
import { useMediaQuery } from "react-responsive";
import { size } from "&assets/constants/responsiveness";
import SubmitFormButtonComponent from "&components/buttons/submitFormButton/submitFormButton.component";
import TitleArrowButton from "&components/buttons/titleArrowButton/titleArrowButton.component";
import BuildProfileStepTwo from "&components/profile/buildProfileStepTwo/buildProfileStepTwo.component";
import { loginActions } from "&features/login/login.slice";
import { AssessmentForm, SkillTypes } from "&features/assessment/assessment.type";
import ApplyForSponsorshipForm from "&components/profile/buildProfileStepThree/buildProfileStepThree.component";
import { UpdateProfileBody } from "&features/login/login.type";
import { profileActions } from "&features/dashboard/screens/profile/profile.slice";
import buildAssessmentBody from "&utils/buildAssessmentObject";
import { eligibilitySelector, otherSkillsFilter } from "&utils/profile/profileSelectors";
import { SetGoogleAnalyticsProperties, TrackGoogleAnalyticsEvent } from "&utils/google-analytics/googleAnalytics";
import { map } from "lodash";

type ReduxProps = ConnectedProps<typeof connector>;

const BuildProfileComponent = (props: ReduxProps) => {
  const {
    settings,
    loadingSkills,
    skillsStatus,
    formValues,
    loadingUpdateProfile,
    updateProfileStatus,
    setProfileForm,
    getSkills,
    getCareers,
    loadingCareers,
    loadingUser,
    user,
    careers,
    loadingFieldsOfStudy,
    fieldsOfStudy,
    loadingInstitutes,
    institutes,
    countries,
    loadingCountries,
    getCountries,
    loadingEligibility,
    eligibilityStatus,
    postEligibility,
    getFieldsOfStudy,
    getInstitutes,
    setLogin,
    profileStep,
    getUser,
    skills,
    setProfile,
    changeCareerMode,
    autoAssignCareer,
  } = props;

  let navigate = useNavigate();
  const { t } = useTranslation(["profile"]);

  const ref = useRef<any>(null);
  const isMobile = useMediaQuery({ maxWidth: size.mobile });
  const isTablet = useMediaQuery({
    minWidth: size.mobile,
    maxWidth: size.tablet,
  });

  const dispatch = useDispatch();

  const updateProfile = (body: UpdateProfileBody) => {
    dispatch(loginActions.updateProfile(body));
  };

  const handleChangeValues = (value: AssessmentForm) => {
    setProfileForm({ ...formValues, ...value });
  };

  const [noCareerMode, setNoCareerMode] = useState<boolean>(false);

  const disableNext = (formState: AssessmentForm) => {
    const skillsToCheck: SkillTypes[] = ["softSkills", "hardSkills", "otherSkills"];

    let skillSum = 0;

    map(skillsToCheck, (skillToCheck) => {
      const skillsObject = formState[skillToCheck];
      skillsObject && skillsObject.length
        ? (skillSum = skillSum + skillsObject.length)
        : skillsObject &&
          // eslint-disable-next-line array-callback-return
          Object.keys(skillsObject).map((skillKey) => {
            if (skillsObject[skillKey]?.value?.checked) {
              skillSum = skillSum + 1;
            }
          });
    });

    return profileStep === 2 && skillSum < 3;
  };

  useEffect(() => {
    getCareers({});
    getFieldsOfStudy();
    getInstitutes();
    getUser({});
    getCountries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [submit, setSubmit] = useState<number>(0);

  const isMobileOrTable = isMobile || isTablet;

  const handleStepClick = (stepClicked: number) => {
    ref?.current?.scrollIntoView({ behavior: "smooth", block: "nearest" });
    if (stepClicked < profileStep) {
      setLogin({ profileStep: stepClicked });
    }
  };

  const isAwaiting = user?.eligibilityForm?.status === "pending";
  const isApproved = user?.eligibilityForm?.status === "accepted";

  const initialValues: AssessmentForm = {
    // careerPath: ,
    softSkills: skills?.soft_skills,
    hardSkills: skills?.hard_skills,
    otherSkills: otherSkillsFilter(skills?.other_skills),
    otherSkillsList: skills?.other_skills,
  };

  const scrollToTop = () => {
    ref?.current?.scrollIntoView({ behavior: "smooth", block: "nearest" });
  };
  const handleContinue = () => {
    if (profileStep === 3 && (isApproved || isAwaiting || changeCareerMode)) {
      if (changeCareerMode) {
        setLogin({ changeCareerMode: false });
      }
      navigate("/thank-you");
    } else {
      scrollToTop();
      setSubmit(submit + 1);
      if (profileStep === 2) {
        setSubmit(0);
        handleSkillsUpdate();
      }
    }
  };

  const handleSkillsUpdate = () => {
    if (noCareerMode) {
      autoAssignCareer({
        skills: buildAssessmentBody(formValues, false),
        careerId: Number(-1),
        educationalScore: 3,
      });
    } else {
      updateProfile({
        skills: buildAssessmentBody(formValues, true),
      });
    }
  };

  useEffect(() => {
    if (skillsStatus === "GET_SKILLS_SUCCESS") {
      handleChangeValues(initialValues);
      setSubmit(0);
      scrollToTop();
      setLogin({
        skillsStatus: "",
        profileStep: 2,
        updateProfileStatus: "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skillsStatus]);

  useEffect(() => {
    if (updateProfileStatus === "UPDATE_PROFILE_SUCCESSFUL" && profileStep === 1) {
      TrackGoogleAnalyticsEvent({
        category: "Profile Creation",
        action: "step_1_completed",
        label: "profile_creation",
      });
      SetGoogleAnalyticsProperties({
        name: "user_properties",
        value: {
          id: user?.id?.toString(),
          gender: user?.gender?.toLowerCase(),
          career: user?.career?.name,
        },
      });

      if (noCareerMode) {
        getSkills({});
      } else {
        handleChangeValues(initialValues);
        setSubmit(0);
        scrollToTop();
        setLogin({
          skillsStatus: "",
          profileStep: 2,
          updateProfileStatus: "",
        });
      }
    }
    if (updateProfileStatus === "UPDATE_PROFILE_SUCCESSFUL" && profileStep === 2) {
      scrollToTop();
      setSubmit(0);
      TrackGoogleAnalyticsEvent({
        category: "Profile Creation",
        action: "step_2_completed",
        label: "profile_creation",
      });

      if (changeCareerMode || user?.eligibilityForm?.typeOfResidence) {
        setLogin({ changeCareerMode: false });
        navigate("/dashboard");
      }
      if (noCareerMode) {
        getUser({});
      }

      setLogin({
        profileStep: 3,
        updateProfileStatus: "",
      });
    }
    if (eligibilityStatus === "ELIGIBILITY_POST_SUCCESS" && profileStep === 3) {
      scrollToTop();
      TrackGoogleAnalyticsEvent({
        category: "Profile Creation",
        action: "eligibility_form_submitted",
        label: "profile_creation",
      });
      setSubmit(0);
      navigate("/thank-you");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skillsStatus, updateProfileStatus, eligibilityStatus]);

  useEffect(() => {
    if (user && user?.education && user?.education.length === 0) {
      dispatch(loginActions.setLogin({ profileStep: 1, changeCareerMode: false }));
    }
  }, [user]);

  return (
    <BuildProfileWrapper>
      <Container className="logo-container">
        <div className="blurred-circle" />
        <img
          onClick={() => navigate("/")}
          src={LogoImg}
          alt="logo"
          width={isMobileOrTable ? "128px" : "170px"}
          height={isMobileOrTable ? "33px" : "45px"}
        />
      </Container>
      <div className="mobile-container">
        <TitleWithSteps
          onStepClick={handleStepClick}
          forwardRef={ref}
          title={t("FOLLOW_OUR_STEPS_TO_GET_STARTED")}
          subTitle={t("BY_FOLLOWING_OUR_STEPS_YOU_WILL_EASILY")}
          hasBackButton={true}
          onBackButtonClick={() => navigate("/")}
          step={profileStep}
          isBuildProfile={true}
        />
      </div>
      <Container className="padding-container">
        <ContentWrapper isWhite={true}>
          <SwipeableViews disabled={true} ignoreNativeScroll={true} enableMouseEvents={false} index={profileStep - 1}>
            <BuildProfileStepOne
              updateProfile={updateProfile}
              getSkills={getSkills}
              user={user}
              setNoCareerMode={setNoCareerMode}
              submit={profileStep === 1 ? submit : 0}
              loading={loadingCareers || loadingSkills || loadingFieldsOfStudy || loadingInstitutes || loadingUser}
              careers={careers}
              settings={settings}
              fieldsOfStudy={fieldsOfStudy}
              institutes={institutes}
              hide={profileStep !== 1}
              countries={countries}
            />
            <BuildProfileStepTwo
              settings={settings}
              loadingAssessment={loadingSkills || loadingUpdateProfile || loadingCountries}
              setAssessmentForm={setProfileForm}
              formValues={formValues}
              hide={profileStep !== 2}
            />
            <ApplyForSponsorshipForm
              countries={countries}
              setProfile={setProfile}
              error={eligibilityStatus === "FORM_ALREADY_SUBMITTED" ? t("ELIGIBILITY_FORM_ALREADY_SUBMITTED") : ""}
              loading={loadingEligibility}
              initialValues={(user?.eligibilityForm && eligibilitySelector(user?.eligibilityForm)) || {}}
              postEligibility={postEligibility}
              isAwaiting={isAwaiting}
              isApproved={isApproved}
              submit={profileStep === 3 ? submit : 0}
              hide={profileStep !== 3}
            />
          </SwipeableViews>
        </ContentWrapper>
        <div className="continue-complete-buttons-wrapper">
          <div />
          <SubmitFormButtonComponent
            id={"build-profile-continue-submit-button"}
            className={profileStep === 2 && !changeCareerMode ? "submit-button-width" : "submit-button"}
            disabled={disableNext(formValues)}
            loading={loadingUpdateProfile}
            withArrow={true}
            onClick={handleContinue}
            title={
              profileStep === 3 && !isApproved && !isAwaiting && !changeCareerMode
                ? t("CREATE_ACCOUNT")
                : profileStep === 2 && !changeCareerMode
                ? t("CONTINUE_TO_SIGNUP")
                : t("CONTINUE")
            }
          />
          {profileStep === 3 && !changeCareerMode ? (
            <TitleArrowButton
              isWhite={true}
              id={"complete-later-button"}
              title={t("COMPLETE_LATER")}
              onClick={() => {
                navigate("/thank-you");
              }}
            />
          ) : (
            <div />
          )}
        </div>
      </Container>
    </BuildProfileWrapper>
  );
};

const mapStateToProps = (state: RootState) => ({
  settings: state.global.settings,
  formValues: state.login.profileFormState,
  changeCareerMode: state.login.changeCareerMode,
  profileStep: state.login.profileStep,
  loadingSkills: state.login.loadingSkills,
  loadingCareers: state.login.loadingCareers,
  loadingUpdateProfile: state.login.loadingUpdateProfile,
  updateProfileStatus: state.login.updateProfileStatus,
  skillsStatus: state.login.skillsStatus,
  careers: state.login.careers,
  skills: state.login.skills,
  loadingFieldsOfStudy: state.profile.loadingFieldsOfStudy,
  fieldsOfStudy: state.profile.fieldsOfStudy,
  loadingInstitutes: state.profile.loadingInstitutes,
  institutes: state.profile.institutes,
  user: state.login.user,
  loadingUser: state.login.loading,
  countries: state.profile.countries,
  loadingCountries: state.profile.loadingCountries,
  loadingEligibility: state.profile.loadingEligibility,
  eligibilityStatus: state.profile.eligibilityStatus,
});

const mapDispatchToProps = {
  setProfileForm: loginActions.setProfileForm,
  getSkills: loginActions.getSkills,
  getCareers: loginActions.getCareers,
  setLogin: loginActions.setLogin,
  getUser: loginActions.getProfileThunk,
  autoAssignCareer: loginActions.autoAssignCareer,
  getFieldsOfStudy: profileActions.getFieldsOfStudy,
  getInstitutes: profileActions.getInstitutes,
  getCountries: profileActions.getCountries,
  postEligibility: profileActions.postEligibility,
  setProfile: profileActions.setProfile,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const SignupComponentRedux = connector(BuildProfileComponent);

export { SignupComponentRedux as BuildProfileComponent };
