import { LearningPathwayType } from "&features/listingPage/listingPage.type";

const getTopLearningActivities = (
  featuredLearningPaths?: LearningPathwayType[]
) => {
  const courses: any = [];
  const addedIds: any = {};
  if (featuredLearningPaths && featuredLearningPaths?.length > 0) {
    featuredLearningPaths.forEach((item: LearningPathwayType) => {
      item.learningPathCourses.some((course: any, index: number) => {
        if (!addedIds[course.courses.id]) {
          course.courses && courses.push(course.courses);
          addedIds[course.courses.id] = true;
          return true;
        } else {
          return false;
        }
      });
    });
  }
  return courses;
};

export default getTopLearningActivities;
