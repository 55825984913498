import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { RootState } from "&store/store";
import { blogDetailsActions } from "./blogDetails.slice";
import { BlogDetailsWrapper } from "./blogDetails.styled";
import PageHeader from "&components/pageHeader/pageHeader.component";
import { useNavigate, useParams } from "react-router-dom";
import { Clock } from "&assets/constants/icons";
import { colors } from "&assets/constants/colors";
import SkillsList from "&components/skillsList/skillsList.component";
import CircularLoader from "&components/circularLoader/circularLoader.component";
import Container from "&components/container/container.component";
import CardsCarousel from "&components/cardsCarousel/cardsCarousel.component";
import { blogsActions } from "&features/blogs/blogs.slice";
import BlogCardComponent from "&components/blogCard/blogCard.component";
import HtmlParser from "&components/htmlParser/htmlParser.component";
import { translationSelector } from "&utils/translationSelector";
import getCDNProtectedURL from "&utils/getCDNProtectedImageSource";

type ReduxProps = ConnectedProps<typeof connector>;

const BlogDetailsComponent = (props: ReduxProps) => {
  const { getBlogById, selectedBlog, loading, loadingBlogs, getBlogs, blogs } = props;
  const { t } = useTranslation(["futureCareers"]);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    id && getBlogById(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    const requestBody: any = {};
    getBlogs(requestBody);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadingComponent = (
    <div className="loader-wrapper">
      <CircularLoader />
    </div>
  );

  const blogsArray = blogs?.items || blogs;

  return (
    <BlogDetailsWrapper>
      {loading ? (
        loadingComponent
      ) : (
        <>
          <PageHeader
            title={translationSelector(selectedBlog, "title")}
            hasBackButton={true}
            onBackClicked={() => navigate("/blog")}
            subTitle={
              <div className="information">
                <div className="created-at">
                  <Clock fill={colors.secondary} />
                  <p className="created-at-text">{moment(selectedBlog?.createdAt).format("DD MMM yy")}</p>
                </div>
                {selectedBlog?.tags && <SkillsList skills={translationSelector(selectedBlog, "tags")?.split(",")} />}
              </div>
            }
            className="header"
            titleClassName="header-title"
          />
          <Container className="blog-content">
            <img src={getCDNProtectedURL(selectedBlog?.image)} alt="" className="blog-image-back" />
            <HtmlParser className="blog-description" description={translationSelector(selectedBlog, "description")} />
            {blogsArray?.some((blog: any) => blog.id !== Number(id)) && (
              <div className="benefits-container">
                <p className="benefits-title">{t("BENEFITS_OF_NAMMISKILLS")}</p>
                {loadingBlogs ? (
                  loadingComponent
                ) : (
                  <CardsCarousel>
                    {blogsArray?.map((blog: any, index: number) => {
                      return (
                        blog.id !== Number(id) && (
                          <div className="card-blog" key={index}>
                            <BlogCardComponent
                              image={blog.image}
                              title={blog.title}
                              tags={blog.tags}
                              date={blog.createdAt}
                              handleClick={() => navigate(`/blog-details/${blog.id}`)}
                            />
                          </div>
                        )
                      );
                    })}
                  </CardsCarousel>
                )}
              </div>
            )}
          </Container>
        </>
      )}
    </BlogDetailsWrapper>
  );
};

const mapStateToProps = (state: RootState) => ({
  loading: state.blogDetails.loading,
  loadingBlogs: state.blogs.loading,
  selectedBlog: state.blogDetails.selectedBlog,
  blogs: state.blogs.blogs,
});

const mapDispatchToProps = {
  getBlogById: blogDetailsActions.getBlogById,
  getBlogs: blogsActions.getBlogs,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const BlogDetailsComponentRedux = connector(BlogDetailsComponent);

export { BlogDetailsComponentRedux as BlogDetailsComponent };
