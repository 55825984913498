import styled from "styled-components";
import { colors } from "&assets/constants/colors";
import { device } from "&assets/constants/responsiveness";

interface Props {
  isBlur?: boolean;
}

export const ListItemsPartiallyWrapper = styled.div<Props>`
  position: relative;

  .second-part-wrapper {
    position: relative;
    ${(props) => (props.isBlur ? "overflow-x: hidden;" : "")}
    ${(props) => (props.isBlur ? "padding-bottom: 10px;" : "")}
    
    .blur {
      position: absolute;
      width: 100%;
      height: 100%;
      //padding-top: 80px;
      background: linear-gradient(180deg, #ffffff 8.08%, ${colors.primaryDark} 100%);
      opacity: 0.7;
      z-index: 4;
      border-radius: 12px;
    }
  }
  .load-more-background-wrapper {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-bottom: 1cqb;

    .button-wrapper {
      width: 142px;
      height: 57px;
      background: ${colors.white};
      border: 1px solid ${colors.primary};
      border-radius: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 5;

      @media ${device.mobile}, ${device.tablet} {
        width: 120px;
        height: 50px;
      }
    }
    .load-more-title {
      color: ${colors.primary} !important;
    }
  }
  @media ${device.mobile}, ${device.tablet} {
    .load-more-background-wrapper {
      margin-bottom: 50px !important;
    }
  }
`;
