import styled from "styled-components";
import { colors } from "&assets/constants/colors";
import {device} from "&assets/constants/responsiveness";

interface Props {
  isWhite?: boolean;
}

export const PageTitleSubtitleWrapper = styled.div<Props>`
  .title {
    font-weight: 700;
    font-size: 48px;
    line-height: 110%;
    letter-spacing: -0.02em;
    font-feature-settings: "cv11" on;
    color: ${props => props.isWhite ? colors.white : colors.dark};
    margin-bottom: 8px;
    text-align: center;
  }

  .sub-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 160%;
    color: ${props => props.isWhite ? colors.white : colors.dark};
    opacity: 0.7;
    margin-bottom: 48px;
    text-align: center;
  }

  @media ${device.tablet} {
    .title {
      font-size: 34px;
    }

    .sub-title {
      font-size: 17px;
      margin-bottom: 34px;
    }
  }

  @media ${device.mobile} {
    min-height: auto;

    .title {
      font-size: 28px;
    }

    .sub-title {
      font-size: 16px;
      margin-bottom: 24px;
    }
  }
`;
