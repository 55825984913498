
import React from "react";
import { InCollaborationWithWrapper } from "./inCollaborationWith.styled";
import aws from "&assets/images/aws-collaboration.png";
import linkedin from "&assets/images/linkedin-collaboration.png";
import microsoft from "&assets/images/microsoft-collaboration.png";
import dotLogo from "&assets/images/dotlogo.png";
import simplilearn from "&assets/images/Simplilearn-Logo.png";
interface Props {
  title?: string;
  description?: string;
}

const InCollaborationWith = (props: Props) => {
  const { title, description } = props;
  return (
    <InCollaborationWithWrapper>
      <p className='collaboration-title'>{title}</p>
      <div className='logos-wrapper'>
        <img src={aws} className='aws' alt={'aws-logo'}/>
        <img src={linkedin} className='linkedin' alt={'linkedin-logo'}/>
        <img src={microsoft} className='microsoft' alt={'microsoft-logo'}/>
        <img src={dotLogo} className='dot' alt={'dot-logo'}/>
        <img src={simplilearn} className='simplilearn' alt={'simplilearn-logo'}/>
      </div>
      <p className='description'>{description}</p>
    </InCollaborationWithWrapper>
  );
};

export default InCollaborationWith;
