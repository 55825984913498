import React from "react";
import { useMediaQuery } from "react-responsive";
import { AboutNumbersWrapper, StatisticsBlock } from "./aboutNumbers.styled";
import { size } from "&assets/constants/responsiveness";
import { PageSection, PageSectionContent } from "&features/landingPage/landingPage.type";
import { translationSelector } from "&utils/translationSelector";
import i18n from "i18next";

interface Props {
  data: PageSection;
  backgroundImages: any;
}

const AboutNumbers = (props: Props) => {
  const { data } = props;
  const numbersArray = data?.sectionContents;

  const dir = i18n.dir();

  const isMobile = useMediaQuery({ maxWidth: size.mobile });
  const isTablet = useMediaQuery({
    minWidth: size.mobile,
    maxWidth: size.tablet,
  });
  const statistics = (index: number, number?: string, title?: string) => {
    return (
      <StatisticsBlock backgroundImages={props.backgroundImages} index={index} key={index}>
        <span
          style={{
            transition: "0.8s ease-out",
            transitionProperty: "background-color, color, opacity",
            color: "#FFFFFF",
            fontSize: isMobile ? "40px" : isTablet ? "60px" : "80px",
            fontWeight: "900",
            textAlign: dir === "rtl" ? "right" : "left",
          }}
        >
          {number}
        </span>
        <span className="statistics-title">{title}</span>
      </StatisticsBlock>
    );
  };

  return (
    <AboutNumbersWrapper>
      <p className="title">{translationSelector(data, "title")}</p>
      <div className="statistics">
        {numbersArray.map((item: PageSectionContent, index: number) => {
          return statistics(index, translationSelector(item, "subTitle"), translationSelector(item, "title"));
        })}
      </div>
    </AboutNumbersWrapper>
  );
};

export default AboutNumbers;
