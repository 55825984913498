import React from "react";
import parse from "html-react-parser";
import {isEmpty} from "lodash"

import { HtmlParserWrapper } from "./htmlParser.styled";

interface Props {
  description?: string;
  className?: string;
}

const HtmlParser = (props: Props) => {
  const { description, className } = props;

  const options = {
    replace: (domNode:any) => {
      if (domNode.attribs && domNode.name === 'p') {
        if(isEmpty(domNode.children[0])){
          return <span/>
        }
      }
    }
  };
  return (
    <HtmlParserWrapper className={className}>
      {parse(description || "",options)}
    </HtmlParserWrapper>
  );
};

export default HtmlParser;
