import styled from "styled-components";
import { colors } from "&assets/constants/colors";
import { device } from "&assets/constants/responsiveness";

interface Props {
  openCard: boolean;
  selected: boolean;
  isSmall?: boolean;
  width?: any;
}

export const MatchingCareerCardWrapper = styled.div<Props>`
  box-sizing: border-box;
  position: relative;
  cursor: ${(props) => (props.openCard ? "initial" : "pointer")};
  border: 1px solid transparent;
  background: ${(props) =>
        props.openCard || props.selected ? colors.linearGradientOne : colors.primaryLighterGradient}
      padding-box,
    ${colors.linearGradientOne} border-box;
  width: ${(props) => (props.openCard ? (props.isSmall ? "571px" : "647px") : props.isSmall ? "178px" : "205px")};
  ${(props) => (props.width !== null ? `width: 100%!important` : "")};

  transition: width 0.2s ease-in-out;
  height: 252px;
  display: flex;
  //overflow: hidden;
  border-radius: 16px;
  padding: ${(props) => (props.isSmall ? "18px 15px" : "20px 30px")};

  .arrow-icon-desktop {
    position: absolute;
    bottom: -18px;
    ${(props) => (props.theme.dir === "rtl" ? "right: 220px;" : "left: 220px;")}
    min-width: 36px;
    min-height: 36px;
    transform: rotate(90deg);
  }

  .arrow-icon-mobile {
    position: absolute;
    bottom: -18px;
    min-width: 36px;
    min-height: 36px;
    transform: rotate(90deg);
  }

  .enroll-button {
    background: ${colors.linearGradientThree};
    width: 139px;
    height: 40px;
    margin-top: 23px;
    padding: 9px 16px;

    .button-title {
      font-weight: 800;
      font-size: 14px;
      line-height: 160%;
    }
  }

  .desktop {
    display: flex;
  }

  .mobile {
    display: none;
  }

  .circle-text-button-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  }

  .career-name-text {
    margin-top: 18px;
    font-weight: 700;
    font-size: 18px;
    width: 150px;
    line-height: 120%;
    text-align: center;
    color: ${colors.dark};
  }

  .progress-circle-wrapper {
    position: relative;
    display: flex;
    width: 144px;
    height: 144px;
    align-items: center;
    justify-content: center;

    .progress-background {
      position: absolute;
      width: 144px !important;
      height: 144px !important;
      background: transparent;
      border: 2px solid ${(props) => (props.openCard || props.selected ? "#86BFDF" : "#86C4E7")};
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 100px;
    }

    .MuiCircularProgress-root {
      color: ${(props) => (props.openCard || props.selected ? colors.secondary : colors.primary)};
      width: 158px !important;
      height: 158px !important;
      padding: 0 !important;
      background: transparent;
      position: absolute;

      svg {
        overflow: initial;
      }
      circle {
        stroke-width: 1.5px;
        //r: 22.3;
        stroke-linecap: round;
      }
    }

    .percentage-circle {
      box-sizing: border-box;
      width: 121.85px;
      height: 121.85px;
      border-radius: 100px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      position: relative;
      box-shadow: ${(props) =>
        props.openCard || props.selected ? "0 2px 13px rgba(72, 26, 17, 0.56)" : " 0 4px 4px rgba(0, 0, 0, 0.25)"};

      .background {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 0;
        background: ${(props) =>
          props.openCard || props.selected ? colors.linearGradientTwo : colors.linearGradientOne};
        opacity: ${(props) => (props.openCard || props.selected ? 1 : 0.5)};
      }
      .background-graphic {
        position: absolute;
        bottom: 0;
        z-index: 0;
        ${(props) => (props.theme.dir === "rtl" ? "transform: scaleX(-1);" : "")}
      }

      .percentage-text {
        z-index: 1;
        width: 70.62px;
        height: 36.69px;
        font-weight: 700;
        font-size: 26px;
        line-height: 110%;
        text-align: center;
        letter-spacing: -0.02em;
        color: ${colors.white};
      }

      .text {
        width: 71px;
        height: 24px;
        z-index: 1;
        font-weight: 700;
        font-size: 14px;
        line-height: 120%;
        text-align: center;
        color: ${colors.white};
      }
    }
  }

  @media ${device.tablet} {
    width: ${(props) => (props.openCard ? "300px" : "205px")};
    min-height: ${(props) => (props.openCard ? "484px" : "252px")};
    height: auto;
    max-height: ${(props) => (props.openCard ? "auto" : "fit-content")};
    align-items: center;
    flex-direction: column;
    transition: all 0.2s linear;

    .desktop {
      display: none;
    }

    .mobile {
      display: flex;
    }
  }

  @media ${device.mobile} {
    min-width: ${(props) => (props.openCard ? "267px" : "205px")};
    min-height: ${(props) => (props.openCard ? "484px" : "252px")};
    height: auto;
    width: auto;
    max-height: ${(props) => (props.openCard ? "auto" : "fit-content")};
    align-items: center;
    flex-direction: column;
    transition: all 0.2s linear;
    padding: 16px;

    .desktop {
      display: none;
    }

    .mobile {
      display: flex;
    }
  }
`;

export const TitleDescriptionSkillsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${(props) => (props.theme.dir === "rtl" ? "margin-right: 48px;" : "margin-left: 48px;")}
  justify-content: center;
  .dashboard-actions {
    display: flex;
    margin-bottom: 5px;
    .view-button {
      background: transparent;
      border: 1px solid ${colors.white};
      min-width: 78px;
      height: 38px;
      justify-content: center;
      align-items: center;
      border-radius: 30px;
    }
    .view-button-title {
      font-size: 14px;
      color: ${colors.white};
    }
  }
  .skills-chip {
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.1);
    width: fit-content;
    padding: 2px 8px;
    ${(props) => (props.theme.dir === "rtl" ? "margin-left: 8px;" : "margin-right: 8px;")}
    p {
      white-space: nowrap;
      font-weight: 700;
      font-size: 12px;
      line-height: 160%;
      text-align: center;
      color: ${colors.white};
    }
  }

  .card-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;
    ${(props) => (props.theme.dir === "rtl" ? "text-align: right;" : "text-align: left;")}
    color: ${colors.white};
    margin-bottom: 8px;
  }

  .description {
    font-weight: 500;
    font-size: 16px;
    line-height: 160%;
    color: ${colors.white};
    opacity: 0.7;
  }

  .skills-wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  @media ${device.tablet} {
    align-items: center;
    ${(props) => (props.theme.dir === "rtl" ? "margin-right: 0;" : "margin-left: 0;")}
    margin-top: 19px;

    .description {
      text-align: center;
    }

    .card-title {
      text-align: center;
      font-size: 18px;
    }
  }
  @media ${device.mobile} {
    align-items: center;
    ${(props) => (props.theme.dir === "rtl" ? "margin-right: 0;" : "margin-left: 0;")}
    margin-top: 19px;
    margin-bottom: 15px;

    .description {
      text-align: center;
    }

    .card-title {
      text-align: center;
      font-size: 18px;
    }
  }
`;

export const SkillTagWrapper = styled.div`
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.1);
  width: fit-content;
  padding: 2px 8px;
  margin-top: 16px;
  ${(props) => (props.theme.dir === "rtl" ? "margin-left: 8px;" : "margin-right: 8px;")}
  .text {
    white-space: nowrap;
    font-weight: 700;
    font-size: 12px;
    line-height: 160%;
    text-align: center;
    color: ${colors.white};
  }
`;
