import React, { useEffect, useState } from "react";
import { isEmpty, isUndefined } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import { LearningPathwayActivityWrapper } from "./LearningPathwayActivity.styled";
import { Clock, User } from "&assets/constants/icons";
import secondsToHours from "&utils/secondstoHours";
import SourceLogo from "&components/sourceLogo/sourceLogo.component";
import LayeredImage from "&components/layeredImage/layeredImage.component";
import { colors } from "&assets/constants/colors";
import SubmitButton from "&components/buttons/submitButton/submitButton.component";
import { courseDetailsActions } from "&features/courseDetails/courseDetails.slice";
import { RootState } from "&store/store";
import capitalizeFirstLetter from "&utils/capitalizeFirstLetter";
import { LINKEDIN_URL } from "&assets/constants/statics";
import { buildAssessmentBodyForAdd } from "&utils/buildAssessmentObject";
import { addedSkillsSelector } from "&utils/addedSkillsSelector";
import { translationSelector } from "&utils/translationSelector";
import { useTranslation } from "react-i18next";
import { checkIFEnrolledLP, checkIFAnyLASponsored } from "&utils/checkIfEnrolled";
import { courseURLBuilder } from "&utils/courseURLBuilder";
import i18n from "&config/i18n";
import { simplilearnURLBuilder } from "&utils/simplilearnUrlBuilder";

export interface RenderButtonsProps {
  activity: any;
  shouldShowSponsorshipButton: boolean;
  shouldShowBundles: boolean;
  type?: string;
  typeAr?: string;
  loadingVoucher?: boolean;
  handleApplyForVoucher?: (event: any) => void;
  status?: number;
  onGoing?: boolean;
  isLinkedInTracked: boolean;
  completedEligibility?: boolean;
  started?: boolean;
  launchActivityTracker?: () => void;
}

interface Props {
  image?: any;
  index?: number;
  handleClick?: Function;
  isExam?: boolean;
  disabled?: boolean;
  hasCompletionButton?: boolean;
  activity: any;
  onGoing?: boolean;
  status?: number;
  handleCompletionClick?: Function;
  type?: string;
  reason?: string;
  isLocked?: boolean;
  handleApplyForVoucher?: (event: any) => void;
  handleOpenPopup?: (props: RenderButtonsProps) => void;
  typeAr?: string;
  started?: boolean;
  isClaimed?: boolean;
  hideActivityButtons?: boolean;
}

const LearningPathwayActivity = (props: Props) => {
  const {
    image,
    index,
    handleClick,
    isExam,
    disabled,
    hasCompletionButton,
    activity,
    onGoing,
    status,
    handleCompletionClick,
    handleApplyForVoucher,
    type,
    reason,
    isLocked,
    typeAr,
    handleOpenPopup,
    started,
    isClaimed,
    hideActivityButtons,
  } = props;

  const dispatch = useDispatch();
  const loadingVoucher = useSelector((state: RootState) => state.courseDetails.loadingVoucher);
  const { t } = useTranslation(["listingPage"]);
  const isArabic = i18n.language === "ar";
  const user = useSelector((state: RootState) => state.login.user);

  const launchActivityTracker = () =>
    dispatch(
      courseDetailsActions.launchActivity({
        type: `${type}s` || "courses",
        id: activity.id,
      })
    );

  const statusContent: any = {
    1: t("COMPLETION_PROOF_VALIDATION"),
    2: t("COMPLETION_PROOF_REJECTED"),
    3: t("COMPLETION_PROOF_ACCEPTED"),
    4: t("COMPLETION_PROOF_FAILED"),
  };

  const [enrolled, setEnrolled] = useState(false);
  useEffect(() => {
    if (isExam) setEnrolled(checkIFEnrolledLP(activity?.learningPathId, user));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const [isAnyLASponsored, setIsAnyLASponsored] = useState(false);

  useEffect(() => {
    if (isExam) setIsAnyLASponsored(checkIFAnyLASponsored(activity?.learningPathId, user));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  let userSkills = buildAssessmentBodyForAdd(
    {
      hardSkills: user?.userSkills?.hard_skills,
      softSkills: user?.userSkills?.soft_skills,
      otherSkills: user?.userSkills?.other_skills,
    },
    true
  );

  const { disableAddSkills } = addedSkillsSelector(
    userSkills,
    activity.courseSkills || activity.labSkills || activity.examSkills
  );

  const showSponsored =
    activity?.sponsorshipType && activity?.sponsorshipType === "subscription" && !!activity?.sponsored
      ? !isEmpty(user?.sponsorships) && !isEmpty(user?.sponsorships[activity?.source])
      : false;

  const cantUploadCompletion = !hasCompletionButton || activity?.status === 3 || activity?.status === 1;
  // || (activity?.source === "LinkedIn" &&
  //   !!activity?.sponsored &&
  //   !isEmpty(user?.sponsorships) &&
  //   !isEmpty(user?.sponsorships["LinkedIn"]));

  const isLinkedInTracked =
    activity?.source === "LinkedIn" && !isEmpty(user?.sponsorships) && !isEmpty(user?.sponsorships["LinkedIn"]);

  const completedEligibility = user?.eligibilityForm && user.eligibilityForm.approved;

  const rejectedEligibility =
    user?.eligibilityForm && !user.eligibilityForm.approved && user.eligibilityForm?.reason?.length > 0;

  const showReason = status === 2 && !isEmpty(reason);

  const shouldShowSponsorshipButton =
    onGoing &&
    !showSponsored &&
    activity?.sponsorshipType &&
    ((activity?.sponsorshipType === "voucher" &&
      !isClaimed &&
      (activity?.userVoucherHistory ? activity?.userVoucherHistory?.length === 0 : true) &&
      !!activity?.sponsored &&
      (type === "exam" || type === "lab")) ||
      (activity?.sponsorshipType === "subscription" && type === "course"));

  const showBundles = !!(activity?.sponsored && rejectedEligibility) || !!(activity?.sponsored && user?.isEQ2);

  const showPopup =
    (shouldShowSponsorshipButton && completedEligibility) ||
    (!completedEligibility && activity?.sponsored) ||
    showBundles;

  const openPopup = () => {
    handleOpenPopup &&
      handleOpenPopup({
        activity: activity,
        isLinkedInTracked: isLinkedInTracked,
        loadingVoucher: loadingVoucher,
        shouldShowBundles: showBundles,
        shouldShowSponsorshipButton: shouldShowSponsorshipButton,
        status: status,
        type: type,
        handleApplyForVoucher: handleApplyForVoucher,
        onGoing: onGoing,
        completedEligibility: completedEligibility,
        started: started,
        launchActivityTracker: launchActivityTracker,
        typeAr: typeAr,
      });
  };

  return (
    <LearningPathwayActivityWrapper
      isExam={isExam}
      disabled={disabled}
      hasCompletionButton={hasCompletionButton}
      onClick={(event: any) => (disabled ? () => {} : handleClick && handleClick())}
      status={status}
      type={type}
    >
      <LayeredImage
        src={image}
        right={true}
        wrapperClassName="card-img"
        imageClassName="course-img"
        index={index}
        inProgress={onGoing}
        completed={!onGoing && !isUndefined(status) && status !== 0}
        unCompleted={!onGoing && !status}
        sponsored={showSponsored}
      />

      <div className="content">
        <p className="pathway-title">{translationSelector(activity, "name")}</p>
        <p className="description">{translationSelector(activity, "shortDescription")}</p>
        <p className="disclaimer">
          {statusContent[status || ""]} {showReason ? `${t("FOR_THE_FOLLOWING_REASON")}: ${reason}` : ""}
        </p>
        <div className="card-content">
          <div className="card-content info expand">
            <SourceLogo source={activity?.source?.toLowerCase()} className="source" />
            <p className="title text ">{translationSelector(activity, "source")}</p>
          </div>
          <p className="info">
            <User fill={isExam ? colors.white : ""} /> <span className="text">{activity?.instructor}</span>
          </p>
          <p className="info">
            <Clock fill={isExam ? colors.white : ""} />{" "}
            <span className="text">{secondsToHours(activity?.duration)}</span>
          </p>
        </div>
        {hideActivityButtons ? null : enrolled && isExam && isLocked ? (
          <p className="disclaimer">
            {isAnyLASponsored ? t("EXAM_LOCKED_AUTO_MESSAGE") : t("EXAM_LOCKED_MANUAL_MESSAGE")}
          </p>
        ) : (
          <div className="action-buttons">
            {started ? (
              <>
                {!cantUploadCompletion || status === 3 || status === 4 ? (
                  <SubmitButton
                    id="upload-completion-btn"
                    title={
                      status === 2 || status === 4
                        ? t("UPLOAD_COMPLETION_AGAIN")
                        : status === 3
                        ? disableAddSkills
                          ? t("VIEW_GAINED_SKILLS")
                          : t("UPDATE_SKILLS_IN_PROFILE")
                        : t("UPLOAD_COMPLETION_PROOF")
                    }
                    handleClick={(event: any) => {
                      event?.stopPropagation();
                      handleCompletionClick && handleCompletionClick();
                    }}
                    className={isExam ? "upload-completion-btn upload-completion-white-btn" : "upload-completion-btn"}
                    titleClassName="upload-completion-btn-text"
                  />
                ) : null}
                {onGoing || status ? (
                  <SubmitButton
                    title={`${t("LAUNCH")} ${isArabic ? typeAr : capitalizeFirstLetter(type)}`}
                    id={`launch-course-button`}
                    handleClick={(event: any) => {
                      event?.stopPropagation();
                      if (showPopup) {
                        openPopup();
                      } else {
                        launchActivityTracker();
                        window.open(
                          isLinkedInTracked
                            ? LINKEDIN_URL + translationSelector(activity, "url")
                            : activity?.source?.toLowerCase() === "simplilearn"
                            ? simplilearnURLBuilder(translationSelector(activity, "url"))
                            : courseURLBuilder(translationSelector(activity, "url")),
                          "_blank",
                          "noreferrer"
                        );
                      }
                    }}
                    className="launch-course-button"
                    titleClassName="launch-course-button-title"
                  />
                ) : null}
                {status === 4 ? (
                  <SubmitButton
                    id="upload-completion-btn"
                    title={t("APPLY_FOR_VOUCHER")}
                    handleClick={(event: any) => {
                      event?.stopPropagation();
                      handleApplyForVoucher && handleApplyForVoucher(event);
                    }}
                    className={isExam ? "upload-completion-btn upload-completion-white-btn" : "upload-completion-btn"}
                    titleClassName="upload-completion-btn-text"
                  />
                ) : null}
              </>
            ) : null}
            {!started && (onGoing || status) ? (
              <SubmitButton
                title={`${t("LAUNCH")} ${isArabic ? typeAr : capitalizeFirstLetter(type)}`}
                id={`launch-course-button`}
                handleClick={(event: any) => {
                  event?.stopPropagation();
                  if (showPopup) {
                    openPopup();
                  } else {
                    launchActivityTracker();
                    window.open(
                      isLinkedInTracked
                        ? LINKEDIN_URL + translationSelector(activity, "url")
                        : activity?.source?.toLowerCase() === "simplilearn"
                        ? simplilearnURLBuilder(translationSelector(activity, "url"))
                        : courseURLBuilder(translationSelector(activity, "url")),
                      "_blank",
                      "noreferrer"
                    );
                  }
                }}
                className="upload-completion-btn"
                titleClassName="upload-completion-btn-text"
              />
            ) : null}
          </div>
        )}
      </div>
    </LearningPathwayActivityWrapper>
  );
};

export default LearningPathwayActivity;
