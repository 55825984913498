import { colors } from "&assets/constants/colors";
import { device } from "&assets/constants/responsiveness";
import styled from "styled-components";

export const LearningPathwayWrapper = styled.div`
  .activities-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .progress {
    min-width: 109px;
    height: 29px;
    padding: 10px;
    background: ${colors.white};
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    .percent {
      color: ${colors.primary};
      font-weight: 700;
      font-size: 12px;
      line-height: 120%;
    }
  }
  .learning-activities {
    padding: 98px 0;
  }
  .loader-wrapper {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .certificate {
    padding: 0px 67px;
    margin-bottom: 64px;
    .enroll-mobile-button {
      justify-content: center;
      margin: auto;
      width: 141px;
      height: 57px;
      background: ${colors.linearGradientThree};
      border-radius: 30px;
      margin-bottom: 43px;
    }
    .title-enroll-mobile-button {
      font-family: "Mulish";
      font-style: normal;
      font-weight: 800;
      font-size: 18px;
      line-height: 160%;
      text-align: center;
      color: #ffffff;
    }
  }
  @media ${device.inBetween} {
    .learning-activities {
      ${(props) => (props.theme.dir === "rtl" ? "padding: 96px 220px 10px 150px;" : "padding: 96px 150px 10px 220px;")}
      overflow: hidden;
    }
    .certificate {
      padding: 0px 67px;
    }
  }

  @media ${device.tablet} {
    .learning-activities {
      padding: 62px 30px 0px 30px;
      overflow: hidden;
    }
    .certificate {
      padding: 0px 30px;
    }
  }
  @media ${device.mobile} {
    .learning-activities {
      padding: 62px 16px 0px 16px;
      overflow: hidden;
    }
    .certificate {
      padding: 0px 16px;
      margin-bottom: 0;
    }
  }
`;
