import Storage from "@aws-amplify/storage";
import imageCompression from "browser-image-compression";


const options = {
  maxSizeMB: 0.5,
  maxWidthOrHeight: 1000,
  useWebWorker: true,
}
const uploadImageToS3 = async (props: {value: any; userId?: string; compress?: boolean; prefix?: string}) => {

  const {value, userId = '', prefix= '', compress} = props;

  let imageFile = value

  if(value && compress) {
    try {
      imageFile = await imageCompression(value, options);
    } catch (error) {
      console.log(error);
    }
  }
  try {
    if (imageFile) {
      const name = `${userId ? userId + '/' : ''}${prefix}${new Date().getTime()}.${value?.type.split('/')[1]}`;
      const s3Response = await Storage.put(name, imageFile, {
        contentType: value?.type,
        level: "public",
      });
      return s3Response.key;
    }
  } catch (error) {
    console.error("error uploading photo", error);
  } finally {
  }
};

export default uploadImageToS3;
