import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getBlogByIdApi } from "./blogDetails.api";

import { BlogDetails } from "./blogDetails.type";

const initialState: BlogDetails = {
  loading: false,
  selectedBlog: {},
};

const getBlogById = createAsyncThunk(
  "blogs/getBlogById",
  async (id: string, { rejectWithValue, getState, dispatch }: any) => {
    try {
      const blog = await getBlogByIdApi(id);
      return blog?.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const blogDetailsSlice = createSlice({
  name: "blogDetails",
  initialState: initialState,
  reducers: {
    setBlogDetails: (state, action) => {
      return { ...state, ...action.payload };
    },
    reset: () => initialState,
  },

  extraReducers: (builder) => {
    builder.addCase(getBlogById.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getBlogById.fulfilled, (state, action) => {
      state.loading = false;
      state.selectedBlog = action.payload;
    });
    builder.addCase(getBlogById.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const blogDetailsReducer = blogDetailsSlice.reducer;

export const blogDetailsActions = {
  ...blogDetailsSlice.actions,
  getBlogById,
};
