import React, { ChangeEvent, useCallback, useState } from "react";
// @ts-ignore
import debounce from "lodash.debounce";
import { FilterButton, SearchSortFilterBarWrapper } from "./searchSortFilterBar.styled";
import CustomInput from "&components/inputs/searchInput/searchInput.component";
import { FilterIcon, SearchIcon, XIconBig } from "&assets/constants/icons";
import SelectDropDown from "&components/selectDropDown/selectDropDown.component";
import { ListingPageSearchParams } from "&features/listingPage/listingPage.type";
import { Collapse } from "@mui/material";
import { OpportunitiesSearchParams } from "&features/opportunitiesListing/opportunitiesListing.type";
import { CareersSearchParams } from "&features/careerListing/careerListing.type";
import AutoCompleteSelect from "&components/inputs/select/autoCompleteSelect/autoCompleteSelect.component";
import SearchOffIcon from "@mui/icons-material/SearchOff";
interface Props {
  forwardRef?: any;
  searchParams: ListingPageSearchParams | OpportunitiesSearchParams | CareersSearchParams;
  setSearchParams: (params: any) => void;
  searchState: any;
  placeHolder?: string;
  className?: string;
  restoreBtn?: any;
}

const SearchSortFilterBar = (props: Props) => {
  const { forwardRef, searchParams, setSearchParams, searchState, placeHolder = "", className = "" } = props;

  const [openFilter, setOpenFilter] = useState(false);

  const [searchText, setSearchText] = useState(searchParams.query);

  const onChangeHandler = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSearchText(e.target.value);
    debouncedFilter(e.target.value, searchParams);
  };

  const debouncedFilter = useCallback(
    debounce((e: any, params: ListingPageSearchParams) => {
      setSearchParams({
        ...params,
        query: e,
      });
    }, 500),
    []
  );

  const handleSelect = (id: string, selectedItem: any) => {
    setSearchParams({
      ...searchParams,
      [id]: selectedItem?.id,
    });
  };

  const renderCollapseFilter = () => {
    return (
      <Collapse className="collapse-container" in={openFilter} timeout="auto" unmountOnExit>
        {Object.keys(searchState)?.map(
          (key, index) =>
            !searchState[key]?.hide &&
            (searchState[key]?.isAutoComplete ? (
              <AutoCompleteSelect
                id={`select-${searchState[key]?.title}`}
                options={searchState[key]?.list}
                value={searchState[key]?.valueItem}
                className="auto-complete-mobile-wrapper"
                handleChange={(event, option) => handleSelect(searchState[key]?.id, option?.id ? option : { id: "" })}
                placeholder={searchState[key]?.title}
              />
            ) : (
              <SelectDropDown
                value={searchState[key]?.valueItem}
                onSelect={(item: any) => handleSelect(searchState[key]?.id, item)}
                list={searchState[key]?.list}
                title={searchState[key]?.title}
                listWrapperClassName={searchState[key]?.listWrapperClassName}
              />
            ))
        )}
      </Collapse>
    );
  };

  return (
    <SearchSortFilterBarWrapper ref={forwardRef} className={className}>
      <div className="input-dropdowns-wrapper">
        {props.restoreBtn && (
          <div className="restore-btn" onClick={props.restoreBtn}>
            <SearchOffIcon />
          </div>
        )}
        <CustomInput value={searchText} icon={<SearchIcon />} placeholder={placeHolder} onChange={onChangeHandler} />
        {Object.keys(searchState)?.map((key, index) => {
          return (
            !searchState[key]?.hide && (
              <div className="select-wrapper" key={index}>
                {searchState[key]?.isAutoComplete ? (
                  <AutoCompleteSelect
                    id={`select-${searchState[key]?.title}`}
                    options={searchState[key]?.list}
                    value={searchState[key]?.valueItem}
                    handleChange={(event, option) =>
                      handleSelect(searchState[key]?.id, option?.id ? option : { id: "" })
                    }
                    placeholder={searchState[key]?.title}
                  />
                ) : (
                  <SelectDropDown
                    value={searchState[key]?.valueItem}
                    onSelect={(item: any) => handleSelect(searchState[key]?.id, item)}
                    list={searchState[key]?.list}
                    title={searchState[key]?.title}
                    listWrapperClassName={searchState[key]?.listWrapperClassName}
                  />
                )}
              </div>
            )
          );
        })}
        <FilterButton onClick={() => setOpenFilter(!openFilter)}>
          {openFilter ? XIconBig(20) : <FilterIcon />}
        </FilterButton>
      </div>
      {renderCollapseFilter()}
    </SearchSortFilterBarWrapper>
  );
};

export default SearchSortFilterBar;
