import React from "react";

import { LayeredImageWrapper } from "./layeredImage.styled";
import { CheckMark, PlayButtonIcon } from "&assets/constants/icons";
import { colors } from "&assets/constants/colors";
import ImageDisplay from "&components/imageDisplay/imageDisplay.component";
import { useTranslation } from "react-i18next";
import { Career } from "&features/landingPage/landingPage.type";
import { isEmpty } from "lodash";
interface Props {
  src: any;
  level?: string;
  wrapperClassName?: string;
  imageClassName?: string;
  right?: boolean;
  index?: number;
  showOverlay?: boolean;
  inProgress?: boolean;
  completed?: boolean;
  unCompleted?: boolean;
  sponsored?: boolean;
  onVideoClick?: any;
  selectedCareer?: Career;
}

const LayeredImage = (props: Props) => {
  const {
    src,
    wrapperClassName = "",
    imageClassName = "",
    level,
    right = false,
    index,
    showOverlay,
    inProgress = false,
    completed = false,
    unCompleted = false,
    sponsored = false,
    onVideoClick = undefined,
    selectedCareer,
  } = props;
  const { t } = useTranslation(["listingPage"]);
  const hasVideo = !isEmpty(selectedCareer?.videoUrl);

  return (
    <LayeredImageWrapper
      className={wrapperClassName}
      right={right}
      inProgress={inProgress}
      completed={completed}
      unCompleted={unCompleted}
    >
      {hasVideo ? (
        <ImageDisplay
          isFromS3={true}
          url={src}
          isBackgroundImage={hasVideo}
          showOverlay={showOverlay}
          alt="No Picture Available"
          className={`learning-path-image ${imageClassName}`}
        >
          <div>
            <button
              onClick={() => onVideoClick(selectedCareer)}
              className="image-button-wrapper"
            >
              {selectedCareer?.videoUrl && <>{PlayButtonIcon()}</>}
            </button>
          </div>
        </ImageDisplay>
      ) : (
        <ImageDisplay
          isFromS3={true}
          url={src}
          showOverlay={showOverlay}
          alt="No Picture Available"
          className={`learning-path-image ${imageClassName}`}
        />
      )}
      <div className="background-gradient" />
      {level && <div className="tier">{level}</div>}
      {index && (
        <div className="learning-index">
          {completed ? CheckMark(colors.white) : index}
        </div>
      )}
      {sponsored && <div className="sponsored">{t("SPONSORED")}</div>}
    </LayeredImageWrapper>
  );
};

export default LayeredImage;
