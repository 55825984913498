import React, { useEffect, useState } from "react";
import { LearningActivityActionButtonsModalWrapper } from "./learningActivityActionButtonsModal.styled";
import { RenderButtonsProps } from "&components/learningPathwayActivity/LearningPathwayActivity.component";
import SubmitButton from "&components/buttons/submitButton/submitButton.component";
import capitalizeFirstLetter from "&utils/capitalizeFirstLetter";
import { LINKEDIN_URL } from "&assets/constants/statics";
import { courseURLBuilder } from "&utils/courseURLBuilder";
import { useTranslation } from "react-i18next";
import CustomModal from "&components/custom-modal/custom-modal.component";
import ActivityPopupImage from "&assets/images/activity-popup-image.png";
import BundlesPopupImage from "&assets/images/bundles-popup-image.png";
import NoSeatsLeftImage from "&assets/images/no-seats-left.svg";
import i18n from "&config/i18n";
import ApplyForVoucher from "&components/applyForVoucher/applyForVoucher.component";
import { useNavigate } from "react-router-dom";
import CircularLoader from "&components/circularLoader/circularLoader.component";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "&store/store";
import { courseDetailsActions } from "&features/courseDetails/courseDetails.slice";
import { translationSelector } from "&utils/translationSelector";
import { simplilearnURLBuilder } from "&utils/simplilearnUrlBuilder";
interface Props {
  renderButtonsProps?: RenderButtonsProps;
  open: boolean;
  handleClose: () => void;
  fromCourseDetails?: boolean;
}

interface PopupContent {
  title: string;
  message: string;
  image: any;
}
interface PopupSelector {
  voucher: PopupContent;
  eligibility: PopupContent;
  bundles: PopupContent;
  sponsorship: PopupContent;
  noSeatsLeft: PopupContent;
}

const LearningActivityActionButtonsModal = (props: Props) => {
  const { renderButtonsProps, open, handleClose, fromCourseDetails } = props;
  const dispatch = useDispatch();
  const sponsorshipError = useSelector(
    (state: RootState) => state.courseDetails.sponsorshipError
  );

  const loading = useSelector(
    (state: RootState) => state.courseDetails.loadingVoucher
  );

  const sponsorshipStatus = useSelector(
    (state: RootState) => state.courseDetails.status
  );

  const [isNoSeatsAvailable, setIsNoSeatsAvailable] = useState<boolean>(false);
  useEffect(() => {
    if (sponsorshipError === "ERROR_CLAIMING_VOUCHER_EQ") {
      setIsNoSeatsAvailable(true);
      dispatch(
        courseDetailsActions.setCourseDetails({
          sponsorshipError: undefined,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sponsorshipError]);

  useEffect(() => {
    if (
      (sponsorshipStatus === "CLAIM_SPONSORSHIP_SUCCESSFULLY" ||
        sponsorshipError) &&
      sponsorshipError !== "ERROR_CLAIMING_VOUCHER_EQ"
    ) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sponsorshipStatus]);
  const { t } = useTranslation(["listingPage"]);
  const navigate = useNavigate();
  const renderButtons = ({
    activity,
    shouldShowSponsorshipButton,
    type,
    loadingVoucher,
    handleApplyForVoucher,
    shouldShowBundles,
    status,
    onGoing,
    isLinkedInTracked,
    completedEligibility,
    started,
    typeAr,
    launchActivityTracker,
  }: RenderButtonsProps) => {
    const isArabic = i18n.language === "ar";

    return (
      <div className="action-buttons">
        {!shouldShowBundles &&
        fromCourseDetails &&
        shouldShowSponsorshipButton &&
        completedEligibility &&
        !isNoSeatsAvailable ? (
          <ApplyForVoucher
            id={activity.id}
            type="courses"
            handleCloseIntructions={() => handleClose()}
            sponsorshipType={activity?.sponsorshipType}
            sponsored={false}
          />
        ) : (
          (shouldShowSponsorshipButton || !completedEligibility || shouldShowBundles || isNoSeatsAvailable) && (
            <SubmitButton
              title={
                shouldShowBundles || isNoSeatsAvailable
                  ? t("VIEW_BUNDLES")
                  : completedEligibility
                  ? activity?.sponsorshipType === "voucher" && (type === "exam" || type === "lab")
                    ? t("APPLY_FOR_VOUCHER")
                    : t("APPLY_FOR_SPONSORSHIP")
                  : t("APPLY_FOR_ELIGIBILITY")
              }
              id={`apply-voucher-button`}
              handleClick={(event: any) => {
                event?.stopPropagation();
                // handleClose();
                if (shouldShowBundles || isNoSeatsAvailable) {
                  navigate("/bundles");
                } else if (fromCourseDetails && !completedEligibility) {
                  navigate("/dashboard/profile");
                } else {
                  handleApplyForVoucher && handleApplyForVoucher(event);
                }
              }}
              loading={loadingVoucher}
              className="upload-completion-btn"
              titleClassName="upload-completion-btn-text"
            />
          )
        )}
        {onGoing || status ? (
          <SubmitButton
            title={`${t("LAUNCH")} ${isArabic && typeAr ? typeAr : capitalizeFirstLetter(type)}`}
            id={`launch-course-button`}
            handleClick={(event: any) => {
              event?.stopPropagation();
              handleClose();
              !started && launchActivityTracker && launchActivityTracker();
              window.open(
                isLinkedInTracked
                  ? LINKEDIN_URL + translationSelector(activity, "url")
                  : activity?.source?.toLowerCase() === "simplilearn"
                  ? simplilearnURLBuilder(translationSelector(activity, "url"))
                  : courseURLBuilder(translationSelector(activity, "url")),
                "_blank",
                "noreferrer"
              );
            }}
            className="apply-voucher-button"
            titleClassName="apply-voucher-button-title"
          />
        ) : null}
      </div>
    );
  };

  const caseSelector = isNoSeatsAvailable
    ? "noSeatsLeft"
    : renderButtonsProps?.shouldShowBundles
    ? "bundles"
    : renderButtonsProps?.completedEligibility
    ? renderButtonsProps?.activity?.sponsorshipType === "voucher" &&
      (renderButtonsProps?.type === "exam" || renderButtonsProps?.type === "lab")
      ? "voucher"
      : "sponsorship"
    : "eligibility";

  const popupSelector: PopupSelector = {
    voucher: {
      title: t("YOU_ARE_ONE_STEP_AWAY_FROM_WINNING_YOUR_VOUCHER"),
      message: t("YOURE_READY_TO_USE_YOUR_EXAM_VOUCHER"),
      image: ActivityPopupImage,
    },
    eligibility: {
      title: t("CHECK_YOUR_ELIGIBILITY_TO_GET_SPONSORED"),
      message: t("CLICK_ON_APPLY_FOR_ELIGIBILITY_IF_YOU_ARE_INTERESTED"),
      image: ActivityPopupImage,
    },
    bundles: {
      title: t("VIEW_PAID_LEARNING_BUNDLES"),
      message: t("YOU_ARE_LAUNCHING_A_PAID_LEARNING_ACTIVITY"),
      image: BundlesPopupImage,
    },
    sponsorship: {
      title: t("YOU_ARE_ONE_STEP_AWAY_FROM_GETTING_SPONSORED"),
      message: t("CONGRATULATIONS_YOUR_PROFILE_MEETS_THE_REQUIREMENTS"),
      image: ActivityPopupImage,
    },
    noSeatsLeft: {
      title: t("SORRY_NO_SEATS_AVAILABLE"),
      message: t("WE_ARE_SORRY_TO_INFORM_YOU_THAT_NO_MORE_SPONSORED"),
      image: NoSeatsLeftImage,
    },
  };

  const loadingComponent = (
    <div className="loader-wrapper">
      <CircularLoader />
    </div>
  );

  return (
    <CustomModal open={open} handleClose={handleClose}>
      <LearningActivityActionButtonsModalWrapper isSmallImage={isNoSeatsAvailable}>
        {loading ? (
          loadingComponent
        ) : (
          <>
            <p className="popup-title">{popupSelector[caseSelector].title}</p>
            <p className="description-error-text ">{popupSelector[caseSelector].message}</p>
            <div className="image-wrapper">
              <img src={popupSelector[caseSelector].image} alt="popup-img" className="popup-image" />
            </div>
            {renderButtonsProps && renderButtons(renderButtonsProps)}
          </>
        )}
      </LearningActivityActionButtonsModalWrapper>
    </CustomModal>
  );
};

export default LearningActivityActionButtonsModal;
